import { Grid, Stack } from "@mui/material";
import { ETextField } from "../../BaseComponents/ETextField";
import { Form, FormikProvider, useFormik } from "formik";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { EditDocumentValidation } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { UpdateFreelancerDocsAction } from "../../action/FreelancerAction";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { ADHAAR_DOC_ID, DRIVING_LICENCE_DOC_ID, PAN_DOC_ID, PASSPORT_DOC_ID } from "../../constants";
import { EFormHelperText } from "../../BaseComponents/ETypography";

const FreelancerEditModalDocumentDetail = (props) => {
    const { freelancerLoading, auth, freelancerData, closeDocumentDetailModal, selectedDoccument } = props;
    const dispatch = useDispatch();
    const additionalSetting = useSelector((state) => state.CompanySettingReducer);

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            document_id: selectedDoccument.docType,

            aadhar_no: freelancerData?.aadhar_no ? freelancerData?.aadhar_no : '',
            aadhar_file_name: null,

            pan_no: freelancerData?.pan_no ? freelancerData?.pan_no : '',
            pan_file_name: null,

            driving_lic_no: freelancerData?.driving_lic_no ? freelancerData?.employeeInfo?.driving_lic_no : '',
            driving_lic_file: '',

            passport_no: freelancerData?.passport_no ? freelancerData?.passport_no : '',
            passport_file: '',

            freelancer_id: freelancerData.id,
            requiredDocuments: [],
            documents_check: true,
        },
        validationSchema: EditDocumentValidation,
        onSubmit: (data) => {
            if (data) {
                data = { ...data, company_id: auth?.authData?.company_id, updated_by: auth?.authData?.id }
                dispatch(UpdateFreelancerDocsAction(auth.authtoken, data))
            }
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    // INFO: here, we are filling the permissionArray with all the required document ids in the additional settings
    if (additionalSetting?.companySettingData?.doccumentSetting && formik?.values?.requiredDocuments?.length == 0) {
        const permissionArray = additionalSetting?.companySettingData?.doccumentSetting?.map((row, index) => {
            if (row?.is_required) { return row?.document_id }
            else { return 0 }
        })
        setFieldValue('requiredDocuments', permissionArray)
    }

    //Function for all file uploads
    const SetFileFunction = (e, varName) => {
        if (e.target.files[0]) {
            setFieldValue(varName, e.target.files[0])
        }
    }

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container>

                     {/* AADHAR CARD */}
                    {selectedDoccument?.docType == ADHAAR_DOC_ID &&
                        <Grid container spacing={2}>
                            <Grid item md={12} sm={12} lg={6} xl={6} xs={12} className='align-items-start '>
                                <ELabelInputMainGrid label={'Document Number'}>
                                    <ETextField
                                        fullWidth
                                        {...getFieldProps('aadhar_no')}
                                        error={Boolean(touched.aadhar_no && errors.aadhar_no)}
                                        helperText={touched.aadhar_no && errors.aadhar_no}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item md={12} sm={12} lg={6} xl={6} xs={12} className='input-file-selector'>
                                <ETextField fullWidth type="file"
                                    inputProps={{
                                        accept: 'image/jpg, image/jpeg, application/pdf'
                                    }}
                                    onChange={(e) => { SetFileFunction(e, "aadhar_file_name") }}
                                    error={Boolean(touched.aadhar_file_name && errors.aadhar_file_name)}
                                    helperText={touched.aadhar_file_name && errors.aadhar_file_name}
                                />
                                <EFormHelperText className="greyColor4-color">* Allowed format is pdf or jpeg/jpg</EFormHelperText>
                            </Grid>
                        </Grid>
                    }

                     {/* PAN CARD */}
                    {selectedDoccument.docType == PAN_DOC_ID &&
                        <Grid container spacing={2} className='align-items-start'>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                <ELabelInputMainGrid label={'Document Number'}>
                                    <ETextField
                                        fullWidth
                                        autoComplete="pan_no"
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        {...getFieldProps('pan_no')}
                                        error={Boolean(touched.pan_no && errors.pan_no)}
                                        helperText={touched.pan_no && errors.pan_no}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item lg={6} md={12} sm={12} xs={12} className='input-file-selector'>
                                <ETextField fullWidth type="file"
                                    inputProps={{
                                        accept: 'image/jpg, image/jpeg, application/pdf'
                                    }}
                                    onChange={(e) => { SetFileFunction(e, 'pan_file_name') }}
                                    error={Boolean(touched.pan_file_name && errors.pan_file_name)}
                                    helperText={touched.pan_file_name && errors.pan_file_name}
                                />
                                <EFormHelperText className="greyColor4-color">* Allowed format is pdf or jpeg/jpg</EFormHelperText>
                            </Grid>
                        </Grid>
                    }

                     {/* PASSPORT NUMBER */}
                    {selectedDoccument.docType == PASSPORT_DOC_ID &&
                        <Grid container spacing={2} className='align-items-start' >
                            <Grid item lg={6} md={12} sm={12} xs={12} >
                                <ELabelInputMainGrid label={'Document Number'}>
                                    <ETextField
                                        fullWidth
                                        placeholder="Document Number"
                                        onKeyUp={(e) => { setFieldValue('passport_no', e?.target?.value ? e?.target?.value?.toUpperCase() : '') }}
                                        {...getFieldProps('passport_no')}
                                        error={Boolean(touched.passport_no && errors.passport_no)}
                                        helperText={touched.passport_no && errors.passport_no}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item lg={6} md={12} sm={12} xs={12} className='input-file-selector'>
                                <ETextField fullWidth type="file"
                                    inputProps={{
                                        accept: 'image/jpg, image/jpeg, application/pdf'
                                    }}
                                    onChange={(e) => { SetFileFunction(e, "passport_file") }}
                                    error={Boolean(touched.passport_file && errors.passport_file)}
                                    helperText={touched.passport_file && errors.passport_file}
                                />
                                <EFormHelperText className="greyColor4-color">* Allowed format is pdf or jpeg/jpg</EFormHelperText>
                            </Grid>
                        </Grid>
                    }

                    {/* Driving Licence */}
                    {selectedDoccument.docType == DRIVING_LICENCE_DOC_ID &&
                        <Grid container spacing={2} className='align-items-start'>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                <ELabelInputMainGrid label={'Document Number'}>
                                    <ETextField
                                        fullWidth
                                        placeholder="Document Number"
                                        onKeyUp={(e) => { setFieldValue('driving_lic_no', e?.target?.value ? e?.target?.value?.toUpperCase() : '') }}
                                        {...getFieldProps('driving_lic_no')}
                                        error={Boolean(touched.driving_lic_no && errors.driving_lic_no)}
                                        helperText={touched.driving_lic_no && errors.driving_lic_no}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item lg={6} md={12} sm={12} xs={12} className='input-file-selector'>
                                <ETextField fullWidth type="file"
                                    inputProps={{
                                        accept: 'image/jpg, image/jpeg, application/pdf'
                                    }}
                                    onChange={(e) => { SetFileFunction(e, 'driving_lic_file') }}
                                    error={Boolean(touched.driving_lic_file && errors.driving_lic_file)}
                                    helperText={touched.driving_lic_file && errors.driving_lic_file}
                                />
                                <EFormHelperText className="greyColor4-color">* Allowed format is pdf or jpeg/jpg</EFormHelperText>
                            </Grid>
                        </Grid>
                    }
                </Grid>

                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end' className='modal1-buttons-stick-bottom'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => closeDocumentDetailModal({ docModalIsOpen: false, docType: null })}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton size="large" variant="outlined" type="submit" loading={freelancerLoading} >
                        Update
                    </ELoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    )
};

export default FreelancerEditModalDocumentDetail;