import React, { useEffect, useMemo } from "react";
import Page from "../../BaseComponents/EPage";
import { FormControl, FormControlLabel, Grid, Stack } from "@mui/material";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import EHidden from "../../BaseComponents/EHidden";
import { EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from "../../BaseComponents/EButtons";
import { useNavigate } from "react-router-dom";
import { EHelperText, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { EIcon } from "../../BaseComponents/EIcon";
import ForwardArrow from "@iconify/icons-material-symbols/chevron-left-rounded";
import { EBoxPage } from "../../BaseComponents/EBox";
import { ECardBasic, ECardDashboard } from "../../BaseComponents/ECard";
import { ERadio, ERadioGroup } from "../../BaseComponents/ERadio";
import { ECheckbox } from "../../BaseComponents/ECheckbox";
import { Form, FormikProvider, useFormik } from "formik";
import { PERMISSIONS, SET_PAYMENT_SETTING_API, XL } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { LoadingGrid } from "../../BaseComponents/EGrid";
import { PaymentSettingValidation } from "../../utils/validation";
import { ReturnBoolean } from "../../utils/CommonFunctions";
import ESwitch from "../../BaseComponents/ESwitch";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
import { SetPaymentSetting } from "../../action/SettingAction";
import { CompanyDetail } from "../../action/CompanyAction";
import { useCheckPermission } from "../../hooks";

/**
 * [2023-04-24]
 * Changes By: Purva Sharma
 * Description:- The payment setting added are
 *              - Enable reiumburesement: this setting allows user to enable/disable reiumburesement payment option (inner setting impacts payment request modal)
 *              - Allow Advance salary request:  allows user to allow/disable advance payment.
 * 
 *               Both settings have impact on 
 *                 - dropdown options in payment section i.e payment request, payment log, talent payment log , my profile payment details
 * [19th may 2023]
 * Changes by - Aanchal Sahu
 * Description: The code has been optimized as per the new setting dynamics (Also, renamed formik variables to remove the use of extra objects).
 **/

function PaymentSetting() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authReducer)
  const dispatch = useDispatch();
  const settingData = useSelector((state) => state.SettingReducer);
  const settingsContext = useCompanySettingProvider(); //INFO: getting the setting data from the context.
  const paymentSettings= settingsContext?.setting?.paymentSettingData ?? {}
  const company = useSelector(state => state.CompanyReducer)


  const formik = useFormik({
    initialValues: {
      is_default_setting: false,
      // isAtleastOneDocument: false, // commented by Aanchal since it was not used anywhere.
      enable_reimbursement: false,
      reimbursement_attachment_required: false,
      enable_reimbursement_reason: false,
      reimbursement_with_payroll: false,
      enable_advance_salary: false,
      reimbursementReason: []
    },
    validationSchema: PaymentSettingValidation,
    onSubmit: (data) => {
      let formdata = {...data,is_default_setting:false}
      dispatch(SetPaymentSetting(auth.authtoken, formdata, SET_PAYMENT_SETTING_API, navigate));
      }
  });
  const { errors, setFieldValue, handleSubmit, values} = formik;

  const allowEdit = useCheckPermission([PERMISSIONS.ViewEditPaymentSettings])
  const disableFields = useMemo(() => (!allowEdit || values?.is_default_setting), [allowEdit, values?.is_default_setting])

  // INFO: This useEffect is for initializing the values coming from the API; the if condition is checking for loading status & data in the object.
  useEffect(() => {
    if (settingsContext?.setting?.settingLoading == false
      && Object.keys(paymentSettings).length != 0) {
      // INFO: setting the values according to is_default_setting
      const data = paymentSettings.is_default_setting == true ? paymentSettings?.companyDefaultSetting :
        paymentSettings?.is_default_setting == false ? paymentSettings?.companySetting : {};
        ValueSettingFunction(data, null)
    }


  }, [!settingsContext?.setting?.settingLoading])

  //Info: This function will be called to set the values in the formik
  const ValueSettingFunction = (data, isSubmit) => {
    if (data) {

      const { enable_reimbursement, reimbursement_attachment_required, reimbursementReason,
        enable_reimbursement_reason, reimbursement_with_payroll, enable_advance_salary } = data

      setFieldValue('enable_reimbursement', enable_reimbursement ? true : false)
      setFieldValue("reimbursement_attachment_required", reimbursement_attachment_required ? true : false)
      setFieldValue('reimbursementReason', reimbursementReason ? reimbursementReason : [])
      setFieldValue('enable_reimbursement_reason', enable_reimbursement_reason ? true : false)
      setFieldValue('reimbursement_with_payroll', reimbursement_with_payroll ? true : false)
      setFieldValue('enable_advance_salary', enable_advance_salary ? true : false)
      if (isSubmit == null) { // isSubmit==null means it got called from useEffect for initializing
        setFieldValue('is_default_setting', paymentSettings.is_default_setting !== null ? paymentSettings.is_default_setting : null)
      }
    }
  }

  const updateReimbursementReason = (data, value) => {
    let array = values?.reimbursementReason
    let updateArray = array.map((item, index) => {

      if (item.reimbursement_reason == data.reimbursement_reason) {
        return Object.assign({}, item, {
          is_active: value
        })
      }
      return item
    })
    setFieldValue("reimbursementReason", updateArray)
  }

  //Info: Handle setting toggle
  const handleDefaultSetting = (value) => {
    setFieldValue('is_default_setting', value)
    if (value == true) {
      const data = { is_default_setting: value, ...paymentSettings?.companyDefaultSetting }
      dispatch(SetPaymentSetting(auth.authtoken, data, SET_PAYMENT_SETTING_API, navigate));
    } else {
      ValueSettingFunction(paymentSettings?.companySetting, false)
    }
  }

  const handleReasonChange = (value) => {
    setFieldValue('enable_reimbursement_reason', value);
    if(paymentSettings?.companySetting && 
      paymentSettings?.companySetting.reimbursementReason.length == 0){
      setFieldValue('reimbursementReason', paymentSettings?.companyDefaultSetting.reimbursementReason); 
    }
  }

  return (
    <Page title="Payment Setting">
      {/* Header */}
      <Grid container display="row" justifyContent="space-between">
        <Grid item>
          <EHeaderBreadcrumbs
            heading="Payment Settings"
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Company Settings", href: "" },
              {
                name: "Payment",
              },
            ]}
          />
        </Grid>
        <EHidden width="mdDown">
          <Grid item>
            <EButtonOutlined variant="outlined" onClick={() => navigate("/")}>
              {" "}
              Back{" "}
            </EButtonOutlined>
          </Grid>
        </EHidden>
      </Grid>

      {/* Form */}
      <Grid container display="row" justifyContent="space-between" spacing={2}>

         {settingData?.settingLoading ?
            <LoadingGrid size={XL} isPage={true} />
            : <>
        <Grid
          item
          xs={12}
          sm={12}
          className="display-flex justify-content-space-between"
        >
          <ETypographyPageHeading> Payment </ETypographyPageHeading>

          {/* If onBoarding is completed, show switch, else don't */}
          {
            company?.companyData?.onBoarding?.onboarding_is_completed 
            ?
              settingData.settingLoading ?<></>:
              <ESwitch 
                check={values?.is_default_setting} 
                handleToggle={() => { handleDefaultSetting(!values?.is_default_setting) }} 
                label={values?.is_default_setting ? "Default" : "Custom"}
                disabled={!allowEdit}
              />
            :
            null
          }

          
          <EHidden width="mdUp">
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => navigate("/")}
              className="button-left-margin"
            >
              <EIcon icon={ForwardArrow} className="height-width-15px" />{" "}
            </EButtonOutlinedIcon>
          </EHidden>
        </Grid>

        <Grid item xs={12} sm={12}>
         

            <EBoxPage className="p0">
              <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                  {/* CARD */}
                  <Grid container className="justify-content-center">
                    {/* Enable Reimbursement */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginTop={2}>
                      <ECardBasic className="mb-0px border-05px-border6 ">
                        <Grid container>
                          <Grid
                            item
                            xl={8}
                            lg={8}
                            md={8}
                            sm={6}
                            xs={6}
                            className="align-center"
                          >
                            <span className="bold-600 font-size-18px">
                              Enable Reimbursement{" "}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={4}
                            sm={6}
                            xs={6}
                            className="py0 text-align-right"
                          >
                            <FormControl component="fieldset">
                              <ERadioGroup
                                row={true}
                                aria-label="enable_reimbursement"
                                name="enable_reimbursement"
                                value={values?.enable_reimbursement}
                              >
                                <FormControlLabel
                                  value={
                                    true
                                  }
                                  disabled={disableFields ? true : false}
                                  control={
                                    <ERadio
                                      className="py-2px px-6px"
                                      onChange={(e, value) => {
                                        setFieldValue("enable_reimbursement", true);
                                      }}
                                    />
                                  }
                                  label={<span className="font-size-16px">Yes</span>}
                                />
                                <FormControlLabel
                                  value={
                                    false
                                  }
                                  disabled={disableFields ? true : false}
                                  control={
                                    <ERadio
                                      className="py-2px px-6px"
                                      onChange={(e, value) => {
                                        setFieldValue("enable_reimbursement", false);
                                        setFieldValue("reimbursement_attachment_required", false);
                                        setFieldValue("enable_reimbursement_reason", false);
                                        setFieldValue("reimbursement_with_payroll", false);
                                      }}
                                    />
                                  }
                                  label={<span className="font-size-16px">No</span>}
                                  className=" mr-0px"
                                />
                              </ERadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="pt-0"
                          >
                            <span className="greyColor4-color">
                              Allow the talents to claim for reimbursement
                            </span>
                          </Grid>

                          {/* IF YES */}
                          {values?.enable_reimbursement && ReturnBoolean(values?.enable_reimbursement) && (
                            <>
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="pt-16px"
                              >
                                <ECardDashboard
                                  className="Bg-card5 border-none shadow-none mb-0px"
                                >
                                  <Grid container>
                                    {/* Make attachment compulsary with reimbursements  */}
                                    <CustomizedGrid label="Make attachment compulsary with reimbursements">
                                      <Grid
                                        item
                                        xl={4}
                                        lg={4}
                                        md={4}
                                        sm={6}
                                        xs={6}
                                        className="py0 text-align-right"
                                      >
                                        <FormControl component="fieldset">
                                          <ERadioGroup
                                            row={true}
                                            aria-label="reimbursement_attachment_required"
                                            name="reimbursement_attachment_required"
                                            value={
                                              values?.reimbursement_attachment_required

                                            }
                                          >
                                            <FormControlLabel
                                              value={
                                                true
                                              }
                                              disabled={disableFields ? true : false}
                                              control={
                                                <ERadio
                                                  className="px-6px py0"
                                                  onChange={(e, value) => {
                                                    setFieldValue(
                                                      "reimbursement_attachment_required",
                                                      "true"
                                                    );
                                                  }}
                                                />
                                              }
                                              label={
                                                <span className="font-size-16px">
                                                  Yes
                                                </span>
                                              }
                                            />
                                            <FormControlLabel
                                              value={
                                                false

                                              }
                                              disabled={disableFields ? true : false}
                                              control={
                                                <ERadio
                                                  className="px-6px py0"
                                                  onChange={(e, value) => {
                                                    setFieldValue(
                                                      "reimbursement_attachment_required",
                                                      false
                                                    );
                                                  }}
                                                />
                                              }
                                              label={
                                                <span className="font-size-16px">
                                                  No
                                                </span>
                                              }
                                              className=" mr-0px"
                                            />
                                          </ERadioGroup>
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="pt-0"
                                        marginBottom={"36px"}
                                      >
                                        <span className="greyColor4-color">
                                          By enabling this setting you make it
                                          mandatory for the talent to upload proofs for
                                          all the reimbursement claims
                                        </span>
                                      </Grid>
                                    </CustomizedGrid>
                                  </Grid>

                                  {/* Select reason for reimbursement */}

                                  <CustomizedGrid label="Select reason for reimbursement">
                                    <Grid
                                      item
                                      xl={4}
                                      lg={4}
                                      md={4}
                                      sm={6}
                                      xs={6}
                                      className="text-align-right"
                                    >
                                      <FormControl component="fieldset">
                                        <ERadioGroup
                                          row={true}
                                          aria-label="enable_reimbursement_reason"
                                          name="enable_reimbursement_reason"
                                          value={
                                            values?.enable_reimbursement_reason

                                          }
                                        >
                                          <FormControlLabel
                                            value={
                                              true
                                            }
                                            disabled={disableFields ? true : false}
                                            control={
                                              <ERadio
                                                className="px-6px py0"
                                                // onChange={(e, value) => {
                                                //   setFieldValue(
                                                //     "enable_reimbursement_reason",
                                                //     true
                                                //   );
                                                // }}

                                                onChange={(e, value) => handleReasonChange(true)}
                                              />
                                            }
                                            label={
                                              <span className="font-size-16px">
                                                Yes
                                              </span>
                                            }
                                          />
                                          <FormControlLabel
                                            value={
                                              false
                                            }
                                            disabled={disableFields ? true : false}
                                            control={
                                              <ERadio
                                                className="px-6px py0"
                                                onChange={(e, value) => handleReasonChange(false)}
                                                // onChange={(e, value) => {
                                                //   setFieldValue(
                                                //     "enable_reimbursement_reason",
                                                //     false
                                                //   );
                                                // }}
                                              />
                                            }
                                            label={
                                              <span className="font-size-16px">
                                                No
                                              </span>
                                            }
                                            className="mr-0px"
                                          />
                                        </ERadioGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="pt-0"
                                      marginBottom={values?.enable_reimbursement_reason ==
                                        true ? 1 : 2}
                                    >
                                      <span className="greyColor4-color">
                                        By enabling this setting you make it mandatory for the talent to state the reason against reimbursement claims
                                      </span>
                                    </Grid>

                                    {values?.enable_reimbursement_reason
                                      && ReturnBoolean(values?.enable_reimbursement_reason) && (
                                        <>
                                          <ECardBasic sx={{ width: "100%" }} className="mb-0px">
                                            <Grid container direction={"column"} >
                                              {values?.reimbursementReason?.map((item, index) => {
                                                return (


                                                  <FormControlLabel
                                                    key={index}
                                                    sx={{ m: 0 }}
                                                    control={
                                                      <ECheckbox
                                                        checked={item.is_active ? true : false}
                                                        disabled={disableFields ? true : false}
                                                        sx={{ marginBottom: "0" }}
                                                        onChange={(e, value) => {
                                                          updateReimbursementReason(item, !item.is_active)
                                                        }}
                                                      />
                                                    }
                                                    label={item.reimbursement_reason}
                                                  />



                                                )
                                              })
                                              }
                                              <EHelperText className="color-text-error-red px-16px">{values?.reimbursementReason.some(item => item.is_active) !== true ? "*" + errors?.reimbursementReason : ''}</EHelperText>
                                            </Grid>
                                          </ECardBasic>
                                        </>
                                      )}
                                  </CustomizedGrid>

                                  {/*
                                  Commenting now as settings have no impact  on the product as of now.
                                  Sending 'false' as default in the post request for 'reimbursement_with_payroll'
                                  
                                  Include reimbursement with payroll 
                                  <CustomizedGrid label="Include reimbursement with payroll">
                                    <Grid
                                      item
                                      xl={4}
                                      lg={4}
                                      md={4}
                                      sm={6}
                                      xs={6}
                                      className="py0 text-align-right"
                                    >
                                      <FormControl component="fieldset">
                                        <ERadioGroup
                                          row={true}
                                          aria-label="reimbursement_with_payroll"
                                          name="reimbursement_with_payroll"
                                          value={
                                            values?.reimbursement_with_payroll
                                             
                                          }
                                        >
                                          <FormControlLabel
                                            value={
                                              true
                                            }
                                            control={
                                              <ERadio
                                                className="py-2px px-6px"
                                                onChange={(e, value) => {
                                                  setFieldValue(
                                                    "reimbursement_with_payroll",
                                                   true
                                                  );
                                                }}
                                              />
                                            }
                                            label={
                                              <span className="font-size-16px">
                                                Yes
                                              </span>
                                            }
                                          />
                                          <FormControlLabel
                                            value={
                                             false   
                                            }
                                            control={
                                              <ERadio
                                                className="py-2px px-6px"
                                                onChange={(e, value) => {
                                                  setFieldValue(
                                                    "reimbursement_with_payroll",
                                                   false
                                                  );
                                                }}
                                              />
                                            }
                                            label={
                                              <span className="font-size-16px">
                                                No
                                              </span>
                                            }
                                            className=" mr-0px"
                                          />
                                        </ERadioGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      item
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="pt-0"
                                      marginBottom={2}
                                    >
                                      <span className="greyColor4-color">
                                        Do you wish to disburse the reimbursement claims along with payroll?
                                      </span>
                                    </Grid>
                                  </CustomizedGrid> */}
                                </ECardDashboard>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </ECardBasic>
                    </Grid>

                    {/* Allow advance salary request */}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginTop={2}>
                      <ECardBasic className="mb-0px border-05px-border6 ">
                        <Grid container>
                          <Grid
                            item
                            xl={8}
                            lg={8}
                            md={8}
                            sm={6}
                            xs={6}
                            className="align-center display-flex"
                          >
                            <span className="bold-600 font-size-18px">
                              Allow advance salary request
                            </span>
                          </Grid>
                          <Grid
                            item
                            xl={4}
                            lg={4}
                            md={4}
                            sm={6}
                            xs={6}
                            className="py0 text-align-right"
                          >
                            <FormControl component="fieldset">
                              <ERadioGroup
                                row={true}
                                aria-label="enable_advance_salary"
                                name="enable_advance_salary"
                                value={values?.enable_advance_salary}
                              >
                                <FormControlLabel
                                  value={true}
                                  disabled={disableFields ? true : false}
                                  control={
                                    <ERadio
                                      className="px-6px py0"
                                      onChange={(e, value) => {
                                        setFieldValue(
                                          "enable_advance_salary",
                                          true
                                        );
                                      }}
                                    />
                                  }
                                  label={<span className="font-size-16px">Yes</span>}
                                />
                                <FormControlLabel
                                  disabled={disableFields ? true : false}
                                  value={false}
                                  control={
                                    <ERadio
                                      className="px-6px py0"
                                      onChange={(e, value) => {
                                        setFieldValue(
                                          "enable_advance_salary",
                                          false
                                        );
                                      }}
                                    />
                                  }
                                  label={<span className="font-size-16px">No</span>}
                                  className=" mr-0px"
                                />
                              </ERadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="pt-0"
                          >
                            <span className="greyColor4-color">
                              By enabling this setting you will allow the talent to request for advance payment.
                            </span>
                          </Grid>
                        </Grid>
                      </ECardBasic>
                    </Grid>
                  </Grid>
                  <Stack direction="row" className="justify-content-flex-end mt-16px">
                      {
                       (allowEdit && !values?.is_default_setting) && <ELoadingButton
                          type="submit"
                          variant="contained"
                          size="large"
                          className="button-left-margin font-size-14px"
                        >
                         { company?.companyData?.onBoarding?.onboarding_is_completed ? "Save" : "Save & Next"}
                        </ELoadingButton>
                      }

                  </Stack>

                </Form>
              </FormikProvider>
            </EBoxPage>
           
        </Grid>
        </> }
      </Grid>
    </Page>
  );
}

export default PaymentSetting;

// INFO: This provides grid for Leave Label-child pair
const CustomizedGrid = (props) => {
  const { label, children } = props;
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="m0">
        <ECardDashboard className="Bg-card5 border-none shadow-none p0">
          <Grid container>
            <Grid
              item
              xl={8}
              lg={8}
              md={8}
              sm={6}
              xs={6}
              className="align-center display-flex"
            >
              <span className="bold-600 font-size-16px">{label}</span>
            </Grid>
            {children}
          </Grid>
        </ECardDashboard>
      </Grid>
    </>
  );
};
