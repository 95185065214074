import { Grid, IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { BULK, CANCELLED_STATUS, PAID_STATUS, PENDING_STATUS, HOLD_STATUS, COMPLETE_STATUS } from "../../constants";
import { ETextFieldSearch } from "../../BaseComponents/ETextField";
import { EButton } from "../../BaseComponents/EButtons";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import SearchIcon from '@iconify/icons-material-symbols/search-rounded';
import { EIcon } from "../../BaseComponents/EIcon";
import arrowBack from '@iconify/icons-material-symbols/arrow-back-rounded';
import moment from "moment";
import { ETooltip } from "../../BaseComponents/ETooltip";

const status = [
    { id: COMPLETE_STATUS, label: PAID_STATUS },
    { id: CANCELLED_STATUS, label: CANCELLED_STATUS },
]

const SalaryPayrollDetailListSearchBar = (props) => {

    const { searchParam, setSearchParam, getFilterData, tabvalue, setShowDetailPage, bulkData, setBulkPaymentModal, payrollData } = props;

    const [employeeName, setEmployeeName] = useState('')

    const setSearch = (key, value) => {
        const sParams = { ...searchParam, [key]: value };
        setSearchParam(sParams);
        getFilterData(sParams)
    }

    const setFullnameInparam = (empName) => {
        const sParams = { ...searchParam, fullname: empName && empName !== '' ? empName : '' };
        setSearchParam(sParams);
        getFilterData(sParams)
    }

    const setEmployeeNameSearch = (fullname) => {
        setEmployeeName(fullname && fullname !== '' ? fullname : '');
        fullname == '' && setFullnameInparam(fullname);
    }

    return (
        <Grid container>
                <Grid item xs={tabvalue == PENDING_STATUS?5:11} sm={tabvalue == PENDING_STATUS?6:11} md={tabvalue == PENDING_STATUS?8:9}  lg={tabvalue == PENDING_STATUS?8:9} xl={tabvalue == PENDING_STATUS?8:10} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >

                        {(tabvalue == HOLD_STATUS || tabvalue == CANCELLED_STATUS) &&
                             <Grid item xs={5} sm={4} md={4.5} lg={4.5} xl={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                        <EDatePicker
                                            // openTo="year_month"
                                           
                                            views={['year', 'month']}
                                            inputFormat="MMM yyyy"
                                            minDate={moment().subtract(5,'years').toDate()}
                                            maxDate={new Date()}
                                            value={searchParam.year_month}
                                            onChange={(newValue) => setSearch('year_month', newValue ? newValue : null)}
                                            renderInput={({ inputProps, ...params }) => <ETextFieldSearch
                                                fullWidth   
                                                size='small'
                                                {...params}
                                                inputProps={{
                                                    ...inputProps,
                                                    placeholder: "Search by month",
                                                }}
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                        }

                        {/* {tabvalue == PENDING_STATUS && */}
                        <Grid item xs={5} sm={tabvalue==PENDING_STATUS ? 8 : 4} md={4.5} lg={4.5} xl={3}>
                            <ETextFieldSearch
                                name="fullname"
                                fullWidth
                                placeholder="Search By Name"
                                size='small'
                                onChange={(e) => setEmployeeNameSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setFullnameInparam(employeeName)}
                                                edge="end">
                                                <EIcon icon={SearchIcon} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        {/* } */}
                        {tabvalue == PAID_STATUS && 
                        <Grid item xs={5} sm={4} md={4.5} lg={4.5} xl={3}>
                                    <EAutocomplete
                                        name="status"
                                        fullWidth
                                        options={status}
                                        getOptionLabel={(option) => option.label ? option.label.toString() : ""}
                                        onChange={(e, value) => setSearch('transaction_status', value?.id ? value?.id : '')}
                                        renderInput={params => (
                                            <ETextFieldSearch
                                                fullWidth
                                                {...params}
                                                placeholder="Status"
                                                name='status'
                                                size='small'
                                            />
                                        )}
                                    />
                                </Grid>
                        }


                        {/* <ItemBox>
                            <EAutocomplete
                                name="year"
                                sx={{ minWidth: 230 }}
                                options={rangeOfYears()}
                                getOptionLabel={(option) => option.label ? option.label.toString() : ""}
                                onChange={(e, value) => setSearch('year', value?.id ? value?.id : '')}
                                renderInput={params => (
                                    <ETextField
                                        fullWidth
                                        {...params}
                                        placeholder="Search By Year"
                                        name='year'
                                        size='small'
                                    />
                                )}
                            />
                        </ItemBox> */}
                    </Grid>
                </Grid>
                <Grid item xs={tabvalue == PENDING_STATUS?7:1} sm={tabvalue == PENDING_STATUS?6:1}  md={tabvalue == PENDING_STATUS?4:3}  lg={tabvalue == PENDING_STATUS?4:3} xl={tabvalue == PENDING_STATUS?4:2} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >



                        {tabvalue == PENDING_STATUS &&
                            <EButton
                                variant="contained"
                                onClick={() => setBulkPaymentModal({ modalData: bulkData, modalCategory: BULK })}
                                disabled={!(bulkData?.payment_ids.length)}>  Bulk Payment </EButton>
                        }

                        <ETooltip title={'Sort'} arrow>
                            <IconButton onClick={() => setSearch('sort', !searchParam.sort)}>
                                <SortByAlphaIcon />
                            </IconButton>
                        </ETooltip>

                        {setShowDetailPage &&
                            <ETooltip title={'Previous'} arrow>
                            <IconButton onClick={() => setShowDetailPage(false)}>
                                <EIcon icon={arrowBack} />
                            </IconButton>
                            </ETooltip>

                            // <EButtonOutlined
                            //     variant="outlined"
                            //     onClick={() => setShowDetailPage(false)}
                            // >  <EIcon icon={arrowBack} /> </EButtonOutlined>
                        }
                    </Grid>
                </Grid>
            </Grid>
    );
};

export default SalaryPayrollDetailListSearchBar;