import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import FilePDF from "@iconify/icons-mdi/file-pdf-box";
import FileCSV from "@iconify/icons-mdi/file-excel";
import {
  EInputAdornment,
  ETextField,
  ETextFieldSearch,
} from "../../BaseComponents/ETextField";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import EMenuPopover, {
  EMenuIcon,
  EMenuItem,
} from "../../BaseComponents/EMenuPopover";
import { DownLoadFile } from "../../action/AdminAction";
import { EIcon, EIconInputAdornment } from "../../BaseComponents/EIcon";
import { EIconButton } from "../../BaseComponents/EButtons";
import { ETooltip } from "../../BaseComponents/ETooltip";
import SortByAlphaIcon from "@iconify/icons-material-symbols/sort-by-alpha";
import FileDownloadIcon from "@iconify/icons-material-symbols/sim-card-download-outline-rounded";
import CancelIcon from "@iconify/icons-material-symbols/cancel-outline-rounded";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { Box } from "@mui/system";
import { ATTENDANCE_REGULARIZATION_REQUEST_DOWNLOAD_URL, DOWNLOAD_CSV, DOWNLOAD_PDF } from "../../constants";
import { useSelector } from "react-redux";


/**
 * [2022-11-25]
 * changes by:-Purva Sharma
 * Description:- search fields for regularization request list
 **/

function  RegularizationSearchFields(props) {
  const {
    setSearchParam,searchParam,
    employeeListData,
    auth
  } = props;
  const [yearmonthValue, setyearmonthValue] = useState(
   moment().format("YYYY-MM-DD") 
  ); 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const company = useSelector(state => state.CompanyReducer);


 
    

  // Option array for status field
  const statusOption = [
    {
      label:'Pending',id:1
    },
    {
      label:'Approved',id:2
    },
    {
      label:'Rejected',id:3
    }
  ]


  // setSearch : creates an object with all key,value and sets to searchParams 
  const setSearch = (key, value) => {
    const sParams = { ...searchParam, [key]: value };
    setSearchParam(sParams);
  };


  const handleClick = (event,value) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const open = Boolean(anchorEl);

    const DownloadReport = (value) => {

        let url = '';
        let searchData = {};
        let file_name = '';
            url += ATTENDANCE_REGULARIZATION_REQUEST_DOWNLOAD_URL;
            searchData = searchParam;
            file_name += 'Regularization_request'
    
        if (value != null) { url += `&download_format=${value}`; }
        if (searchData?.year_month != null) {
            file_name += "_" + moment(yearmonthValue).format("MMM-YYYY");
            url += `&year_month=${searchData?.yearMonth}`;
        }
        if(searchData?.sort){
          url += `&sort=${searchData?.sort}`
        }
        if(searchData?.page){
          url += `&page=${searchData?.page}`
        }
        if(searchData?.per_page){
          url += `&per_page=${searchData?.per_page}`
        }
        DownLoadFile(auth?.authtoken, url, file_name)
        setAnchorEl(null);
    }


  return (
    <>
      <Grid container className="mb-16px">
        <Grid item xs={10} sm={10} lg={10.5} md={10.5} xl={11}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={5} sm={4} md={3.5} lg={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <EDatePicker
                  openTo="month"
                  views={["month", "year"]}
                  inputFormat="MMM yyyy"
                  value={yearmonthValue}
                  minDate={
                    new Date(
                      company?.companyData?.CompanyActivePlan?.plan_valid_from
                    )
                  }
                  maxDate={
                    new Date(
                          company?.companyData?.CompanyActivePlan?.plan_valid_to
                        )
                     
                  }
                  onChange={(newValue) => {
                   
                    setSearch("year_month",newValue && newValue != 'Invalid Date' ? moment(newValue).format('YYYYMM') : null);
                    setyearmonthValue(newValue && newValue != 'Invalid Date' ? newValue : null)
                  }}
                  renderInput={(params) => (
                    <div className="date-picker-div">
                      {/*this div contains text field and clear icon*/}
                      <ETextFieldSearch
                        size="small"
                        fullWidth
                        {...params}
                        onKeyDown={onKeyDown}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Select Month",
                        }}
                      />
                      {yearmonthValue && (
                        <EInputAdornment
                          position="end"
                          onClick={() => {
                            setyearmonthValue(null);
                          }}
                          className="clear-icon"
                        >
                          <EIconInputAdornment
                            icon={CancelIcon}
                            color="primary.main"
                          />
                        </EInputAdornment>
                      )}
                    </div>
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {/* // SELECT TALENT*/}
            <Grid item xs={5} sm={4} md={3.5} lg={3} xl={3}>
              <EAutocomplete
                name="talent_name"
                fullWidth
                options={employeeListData?.employeeData?.rows && employeeListData?.employeeData?.rows.length > 0 ? employeeListData?.employeeData?.rows : [] }
                isOptionEqualToValue={(option, value) => option.id == value.id}
                onChange={(e, value) => {
                  if(value === null){
                    setSearch("employee_id","");
                  }else{
                    setSearch("employee_id",value.id !== undefined ? value.id : "");
                  }
                   
                }}
                renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
                <Box component="li" {...props} key={option.id}>
                  {option.label}
                </Box>
              )}
                renderInput={(params) => (
                  <ETextFieldSearch
                    {...params}
                    name="talent_name"
                    placeholder="Select Talent"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>

            {/* // SELECT STATUS */}
            <Grid item xs={5} sm={4} md={3.5} lg={3} xl={3}>
              <EAutocomplete
                name="status_id"
                fullWidth
                options={statusOption ? statusOption : []}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                onChange={(e, value) => {
                  if(value===null){
                    setSearch('status',"")
                  }else{
                    setSearch("status", value.id ? value.id : "")
                  }
                 
                }}
                renderInput={(params) => (
                  <ETextFieldSearch
                    {...params}
                    name="status"
                    placeholder="Select Status"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* DOWNLOAD AND SORT */}
        <Grid item xs={2} sm={2} md={1.5} lg={1.5} xl={1}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <ETooltip title={"Download"} arrow>
              <EIconButton size={"large"} onClick={handleClick} className="py0">
                <EIcon icon={FileDownloadIcon} className="height-width-22px" />
              </EIconButton>
            </ETooltip>
            <ETooltip title={"Sort by date"} arrow>
              <EIconButton
                onClick={() => {
                  setSearch("sort",!searchParam.sort)
                }}
              >
                <EIcon icon={SortByAlphaIcon} className="height-width-22px" />
              </EIconButton>
            </ETooltip>
          </Grid>
        </Grid>
      </Grid>

      {/* DOWNLOAD POPOVER */}
      <EMenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        sx={{ width: 180, top: 40 }}
      >
        <EMenuItem
          sx={{ typography: "body2", py: 1, px: 2.5 }}
          onClick={() => DownloadReport(DOWNLOAD_PDF)}
        >
          <EMenuIcon icon={FilePDF} /> PDF
        </EMenuItem>
        <EMenuItem
          sx={{ typography: "body2", py: 1, px: 2.5 }}
          onClick={() => DownloadReport(DOWNLOAD_CSV)}
        >
          <EMenuIcon icon={FileCSV} /> CSV 
        </EMenuItem>
      </EMenuPopover>
    </>
  );
}

export default RegularizationSearchFields;
