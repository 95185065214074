import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  FormControl,
  Stack,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { ETextField } from "../../BaseComponents/ETextField";
// import MUIRichTextEditor from 'mui-rte'
// import { stateToHTML } from 'draft-js-export-html'
// import {convertFromHTML, ContentState, convertToRaw} from 'draft-js'
import {
  EFormHelperText,
  ETypography,
} from "../../BaseComponents/ETypography";
import { TaCCreateUpdateDelete } from "../../action/ContractAction";
import { AddTaCValidation } from "../../utils/validation";
import { ERadio, ERadioGroup } from "../../BaseComponents/ERadio";
import {  EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { CHARACTER_LIMIT } from "../../constants";

function TandCForm(props) {
  const dispatch = useDispatch();
  const { row, close, auth, tacList } = props;
  const [ErrorMsg, setErrorMsg] = useState("");


  const formik = useFormik({
    initialValues: {
      terms_title: row ? row.terms_title : "", //this is for storing html content
      status: row ? row.status : true,
      wordcount: "", //work count is for testing the count of words through formik
    },
    validationSchema: AddTaCValidation,
    onSubmit: (data) => {
      data = {
        ...data,
        created_by: auth?.authData?.id,
        updated_by: auth?.authData?.id,
      };
      if (row) {
        data = { ...data, tncid: row?.id };
        dispatch(TaCCreateUpdateDelete(auth.authtoken, data, "update", close));
      } else {
        dispatch(TaCCreateUpdateDelete(auth.authtoken, data, "create", close));
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const HandleClose = () => {
    close();
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {ErrorMsg && <Alert severity="error">{ErrorMsg}</Alert>}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} xl={12}>
              {row && row?.is_editable == true ? (
                row?.terms_title ? (
                  <ELabelInputMainGrid label={"Description"} isfullgrid={true}>
                    <ETypography
                      component={"span"}
                      dangerouslySetInnerHTML={{ __html: row?.terms_title }}
                    ></ETypography>
                  </ELabelInputMainGrid>
                ) : (
                  "-"
                )
              ) : (
                <ELabelInputMainGrid label={"Description"} isfullgrid={true}>
                  <ETextField
                    fullWidth
                    multiline
                    rows={3}
                    inputProps={{
                      maxLength: CHARACTER_LIMIT
                  }}
                    placeholder="Add Terms and Conditions"
                    {...getFieldProps('terms_title')}
                    error={Boolean(touched.terms_title && errors.terms_title ||
                     (formik?.values?.terms_title? formik?.values?.terms_title?.substring(0,1)==' ':false))}
                    helperText={touched.terms_title && errors.terms_title ||
                     (formik?.values?.terms_title && formik?.values?.terms_title?.substring(0,1)==' '?'Please avoid starting the Description with a space.' :false)}
                  />
                  <span className="font-size-14px greyColor4-color">{`${formik.values?.terms_title?.length}/${250}`} </span>
                </ELabelInputMainGrid>
              )}
            </Grid>
            {/* {row && ( */}
              <Grid item xs={12} xl={12}>
                <ELabelInputMainGrid label={"Status"} isfullgrid={true} labelPT='0.5rem'>
                  <FormControl error={Boolean(touched.status && errors.status)}>
                    <ERadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      row
                      {...getFieldProps("status")}
                      name="radio-buttons-group"
                      onChange={(e, value) => {
                        setFieldValue("status", value);
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={
                          <ERadio
                          // disabled={formik.values.is_editable == true ? false : true} // to disable the active button-previous logic
                          />
                        }
                        label={"Enable"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<ERadio />}
                        label={"Disable"}
                      />
                    </ERadioGroup>
                    {touched.status && (
                      <EFormHelperText> {errors.status}</EFormHelperText>
                    )}
                  </FormControl>
                </ELabelInputMainGrid>
              </Grid>
            {/* )} */}
            <Grid
              item
              xs={12}
              xl={12}
              className="modal1-buttons-stick-bottom  pt-24px"
            >
              <Stack direction="row" spacing={2}>
                <EButtonOutlined
                  onClick={() => HandleClose()}
                  variant="outlined"
                  size="large"
                >
                  {" "}
                  Cancel
                </EButtonOutlined>
                <ELoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={tacList?.tacLoading}
                >
                  {row == null ? "Save" : "Update"}
                </ELoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}

export default TandCForm;
