import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import React,{ forwardRef } from 'react';
import styled from '@emotion/styled';

// ----------------------------------------------------------------------


/**
 * [2023-04-5]
 * changes by: Purva Sharma
 * Description:-Added AuthPageBox Styled component as per new design
 **/
const AuthPageBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.authDefault 
}));

const AuthPage = forwardRef(({ children, title = '', ...other }, ref) => (
  <AuthPageBox ref={ref} {...other}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </AuthPageBox>
));

AuthPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default AuthPage;
