import { Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EBoxPage } from "../../BaseComponents/EBox";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButtonOutlined } from "../../BaseComponents/EButtons";
import { EContainer } from "../../BaseComponents/EContainer";
import { EDivider } from "../../BaseComponents/EDivider";
import Page from "../../BaseComponents/EPage";
import { ETab, ETabContext, ETabList, ETabPanel } from "../../BaseComponents/ETabs";
import { ETypographyPageHeading } from "../../BaseComponents/ETypography";
// import SalaryDue from "../../PageComponents/Payment/SalaryDue";
import SalaryDueMainPage from "../../PageComponents/Payment/SalaryDueMainPage";
import SalaryPayrollDetail from "../../PageComponents/Payment/SalaryPayrollDetail";


const Salary = (props) => {
    const { auth } = props;

    const navigate = useNavigate();
    // const dispatch = useDispatch();


    const paymentList = useSelector((state) => state.PayrollReducer);

    // const location = useLocation();

    const [tabvalue, setTabValue] = useState("PENDING");

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };


    return (
        <Page title={'Salary Payment'}>
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Salary Payment'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Payment', href: '' },
                            { name: 'Salary Payment' }
                        ]}
                    />
                </Grid>
                <Grid item>
                    <EButtonOutlined
                        variant="outlined"
                        onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                </Grid>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
            >
                <Grid item>
                    <ETypographyPageHeading variant='h5'>
                        Salary Payment
                    </ETypographyPageHeading>
                </Grid>
            </Grid>

            <EDivider className='margin-top-05' />


            {/* <EContainer maxWidth='lg' > */}
            <EBoxPage>
                <ETabContext value={tabvalue}>
                    <EContainer maxWidth='lg' >
                        <ETabList onChange={handleChange} aria-label="lab API tabs example" allowScrollButtonsMobile variant="scrollable">
                            <ETab label="Salary List" value="PENDING" />
                            <ETab label="Salary Paid" value="PAID" />
                            <ETab label="Salary On-Hold " value="HOLD" />
                            <ETab label="Salary Cancelled" value="CANCELLED" />
                        </ETabList>
                    </EContainer>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        py={3}
                    >
                        <Grid item xs={12} sm={12} md={11}>
                            <ETabPanel value="PENDING" sx={{ padding: '0px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <SalaryDueMainPage
                                            setTabValue ={setTabValue}
                                            tabvalue={tabvalue}
                                            auth={auth}
                                            paymentList={paymentList}
                                        />
                                    </Grid>
                                </Grid>
                            </ETabPanel>
                            <ETabPanel value="PAID" sx={{ padding: '0px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <SalaryDueMainPage
                                            setTabValue ={setTabValue}
                                            tabvalue={tabvalue}
                                            auth={auth}
                                            paymentList={paymentList}
                                        />
                                    </Grid>
                                </Grid>
                            </ETabPanel>
                            <ETabPanel value="HOLD" sx={{ padding: '0px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <SalaryPayrollDetail
                                            tabvalue={tabvalue}
                                            auth={auth}
                                        />
                                    </Grid>
                                </Grid>
                            </ETabPanel>
                            <ETabPanel value="CANCELLED" sx={{ padding: '0px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <SalaryPayrollDetail
                                            tabvalue={tabvalue}
                                            auth={auth}
                                        />
                                    </Grid>
                                </Grid>
                            </ETabPanel>
                        </Grid>
                    </Grid>
                </ETabContext>
            </EBoxPage>
            {/* </EContainer> */}

        </Page>
    );
};

export default Salary;