import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Card, Container, Grid } from "@mui/material";
import AuthSignUp from "../../PageComponents/Auth/AuthSignUp";
import { useDispatch, useSelector } from "react-redux";
import AuthPage from "../../BaseComponents/EAuthPage";
import { ETypography } from "../../BaseComponents/ETypography";
import { getPlan } from "../../action/OnBoardingAction";
import { AuthNavbar } from "../../layouts/auth-navbar";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../constants";

const PLANID = 1;

const RootStyle = styled(Container)(({ theme }) => ({
  paddingTop: "28px",
  paddingBottom: "28px",
}));

const ContentInner = styled(Card)(({ theme }) => ({
  display: "flex",
  // maxHeight: "740px !important",
  boxShadow: 'none !important',
  maxWidth: "430px",
  flexDirection: "column",
  justifyContent: "space-between",
  border: `0.5px solid ${theme.palette.border.border7}`,
  padding: "30px 40px 40px 40px",
  marginBottom: "10px",
}));

const ContentStyle = styled(Grid)(({ theme }) => ({
  // padding: theme.spacing(5, 3)
  maxWidth: "430px",
  margin: "auto",
}));

const SectionStyle = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "18px 18px 0",
    zIndex: "-1",
  }));

/**
 * [2023-04-6]
 * changes by: Purva Sharma
 * Description:- Added Google re-capctcha
 * Referenece: - Docs: https://developers.google.com/recaptcha/docs/v3
 *             - Blog: https://medium.com/@alexjamesdunlop/unnecessary-packages-b3623219d86
 *               PS: no third party package is used.
 **/

const SingnUp = () => {
  const dispatch = useDispatch();

  const auth = useSelector(state => state.authReducer);
  useEffect(() => {
    dispatch(getPlan());
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, [PLANID]);

  return (
    <AuthPage title="Sign Up">
      <AuthNavbar />
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle elevation={0}>
            <ContentInner>
              <AuthSignUp planId={PLANID} auth={auth} />
            </ContentInner>
            <SectionStyle>
            <ETypography className="font-size-12px bold-400 text-align">
              This site is protected by reCAPTCHA and the
              <a
                href="https://policies.google.com/privacy"
                className="link-color"
              >
                {" "}
                Google Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://policies.google.com/terms"
                className="link-color"
              >
                Terms of Service{" "}
              </a>{" "}
              apply.
            </ETypography>
            </SectionStyle>
           
          </ContentStyle>
        </Container>
      </RootStyle>
    </AuthPage>
  );
};

export default SingnUp;
