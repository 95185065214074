import {Grid } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import {  ETypographyCardHeading, } from "../../BaseComponents/ETypography";
import { EInputAdornment, ETextField } from "../../BaseComponents/ETextField";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { CityList } from "../../action/StateCityAction";
import { useDispatch, useSelector } from "react-redux";
import { EBox } from "../../BaseComponents/EBox";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";

const AddFreelancerBasicDetail = (props) => {

    const { formikFreelancer, stateList, auth } = props;
    const dispatch = useDispatch();
    const city = useSelector(state => state.CityReducer);
    const { errors, touched, getFieldProps, setFieldValue } = formikFreelancer;
    const StateListData = stateList?.stateListData?.rows && stateList?.stateListData?.rows?.map(({ id: id, state_name: label }) => ({ id, label }));
    const CityListData = city?.cityListData?.rows && city?.cityListData?.rows.map(({ id: id, city_name: label }) => ({ id, label }));
    return (

        <ECard className='card_design_1'>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="mb-8px"
            >
                <ETypographyCardHeading variant='h5'>
                    Basic Details*
                </ETypographyCardHeading>
            </Grid>

            <EDivider className="mb-16px" />

            <EBox>
                <Grid container spacing={2} className='px-16px'>
                    <Grid item md={6} sm={6} xs={12}  >
                        <ELabelInputMainGrid label={'First Name'}>
                            <ETextField
                                fullWidth
                                autoComplete="freelancer_fname"
                                {...getFieldProps('freelancer_fname')}
                                error={Boolean(touched.freelancer_fname && errors.freelancer_fname)}
                                helperText={touched.freelancer_fname && errors.freelancer_fname}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'Last Name'}>
                            <ETextField
                                fullWidth
                                autoComplete="freelancer_lname"
                                {...getFieldProps('freelancer_lname')}
                                error={Boolean(touched.freelancer_lname && errors.freelancer_lname)}
                                helperText={touched.freelancer_lname && errors.freelancer_lname}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'Email ID'}>
                            <ETextField
                                fullWidth
                                {...getFieldProps('freelancer_email')}
                                error={Boolean(touched.freelancer_email && errors.freelancer_email)}
                                helperText={touched.freelancer_email && errors.freelancer_email}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'Contact Number'}>
                        <ETextField
                                fullWidth
                                autoComplete="freelancer_contact_no"
                                {...getFieldProps('freelancer_contact_no')}
                                error={Boolean(touched.freelancer_contact_no && errors.freelancer_contact_no)}
                                helperText={touched.freelancer_contact_no && errors.freelancer_contact_no}
                                InputProps={{
                                    startAdornment: <EInputAdornment position="start"><span className="color-text-primary">+91</span></EInputAdornment>
                                }}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                  
                    <Grid item md={12} sm={12} xs={12} >
                        <ELabelInputMainGrid label={'Address'} isfullgrid={true}>
                        <ETextField
                            fullWidth
                            placeholder='Address'
                            name="address_one"
                            autoComplete="address_one"
                            {...getFieldProps('address_one')}
                            error={Boolean(
                              touched.address_one && errors.address_one
                            )}
                            helperText={touched.address_one && errors.address_one}
                          />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Landmark'} >
                                <ETextField
                                    fullWidth
                                    name='landmark'
                                    placeholder="Landmark "
                                    {...getFieldProps('landmark')}
                                    error={Boolean(touched.landmark && errors.landmark)}
                                    helperText={touched.landmark && errors.landmark}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'State'} >
                        <EAutocomplete
                            name="state"
                            label="State"
                            fullWidth
                            options={
                              StateListData && StateListData?.length > 0 ? StateListData : []
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label || ''}
                            {...getFieldProps('state')}
                            onChange={(e, value) => {
                              if (value) {
                                dispatch(CityList(auth.authtoken, value.id))
                              }
                              setFieldValue('state', value);
                              setFieldValue("city", null)
                            }}
                            noOptionsText={stateList?.stateListLoading?'Loading... please wait.':'No Options'}
                            renderInput={(params) => (
                              <ETextField
                                {...params}
                                name="state"
                                placeholder={!stateList?.stateListLoading?"Select State":'loading...'}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(
                                  touched.state && errors.state
                                )}
                                helperText={touched.state && errors.state}
                              />
                            )}
                          />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'City'} >
                        <EAutocomplete
                            name="city"
                            fullWidth
                            options={CityListData && CityListData?.length > 0 ? CityListData : []}
                            {...getFieldProps('city')}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.label || ''}
                            onChange={(e, value) => {
                              setFieldValue('city', value);
                            }}
                            noOptionsText={city?.cityListLoading?'Loading... please wait.':'No Options'}
                            renderInput={(params) => (
                              <ETextField
                                {...params}
                                name="city"
                                placeholder={!city?.cityListLoading?"Select City":'Loading... please wait.'}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(
                                  touched.city && errors.city
                                )}
                                helperText={
                                  touched.city && errors.city
                                }
                              />
                            )}
                          />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'Pincode'} >
                        <ETextField
                            fullWidth
                            autoComplete="pincode"
                            placeholder="Pincode "
                            {...getFieldProps('pincode')}
                            error={Boolean(touched.pincode && errors.pincode)}
                            helperText={touched.pincode && errors.pincode}
                          />
                        </ELabelInputMainGrid>
                    </Grid>


                </Grid>
            </EBox>

           
        </ECard>

    )

};

export default AddFreelancerBasicDetail;