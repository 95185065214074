import { EIconSideNavbar } from "../BaseComponents/EIcon";
import MastersIcon from '@iconify/icons-material-symbols/database' 
import ReportIcon from '@iconify/icons-material-symbols/analytics-outline-rounded'
import UserSettingIcon from '@iconify/icons-material-symbols/manage-accounts-rounded'
import SupportIcon from '@iconify/icons-material-symbols/support-agent-rounded'
import DashboardIcon from '@iconify/icons-material-symbols/dashboard-outline-rounded'
import TalentIcon from '@iconify/icons-material-symbols/account-circle-outline'
import AttendanceIcon from '@iconify/icons-material-symbols/calendar-month-outline-rounded'
import ContractIcon from '@iconify/icons-material-symbols/assignment-outline-rounded'
import PayrollIcon from '@iconify/icons-material-symbols/currency-rupee-rounded'
import PaymentIcon from '@iconify/icons-material-symbols/payments-outline-rounded'
import AccountIcon from '@iconify/icons-material-symbols/settings-account-box-outline'
import HistoryIcon from '@iconify/icons-material-symbols/manage-history-rounded'
import RupeeIcon from '@iconify/icons-material-symbols/currency-rupee-rounded'
import DateRangeIcon from '@iconify/icons-material-symbols/date-range-outline'
import RoomIcon from '@iconify/icons-material-symbols/room-preferences-outline'
import { PERMISSIONS, SUPER_ADMIN } from "../constants";
import { isPermissionsAllowed } from "../utils/CommonFunctions";

export function SidebarConfig(Permission, auth = null, settingsContext) {

  const TalentSetting = settingsContext?.setting?.talentSettingData?.companySetting

  const getIcon = (name) => <EIconSideNavbar icon={name}/>;
  return [
    {
      items: [
        {
          title: 'Dashboard',
          path: '/dashboard',
          icon: getIcon(DashboardIcon),
          show: true,
        },
        {
          title: 'Talent',
          path: '/talent',
          show: (isPermissionsAllowed(Permission, [PERMISSIONS.SideBarTalents, PERMISSIONS.OnlyViewTalentList, PERMISSIONS.ViewAddTalent, PERMISSIONS.ViewEditTalent, PERMISSIONS.ViewReleaseTalent]) || TalentSetting?.enable_talent_directory),
          icon: getIcon(TalentIcon),
          children: [
            {
              title: 'Talent List',
              path: '/talent/talent-list',
              show: ( isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewTalentList, PERMISSIONS.ViewAddTalent, PERMISSIONS.ViewEditTalent, PERMISSIONS.ViewReleaseTalent]) || TalentSetting?.enable_talent_directory) ? "yes" : "no",
            },
            {
              title: 'Released List',
              path: '/talent/released-talent-list',
              show: isPermissionsAllowed(Permission, [PERMISSIONS.ViewReleaseTalent]) ? "yes" : "no",
            },
            {
              title: 'Archived List',
              path: '/talent/trashed-talent-list',
              show: isPermissionsAllowed(Permission, [PERMISSIONS.ViewReleaseTalent]) ? "yes" : "no",
            }, 
          ]

        },
        {
          title: 'Freelancer Contract ',
          path: '/freelancer-contract',
          show: true,
          icon: getIcon(ContractIcon),
          children: [
            {
              title: 'Freelancer List',
              path: '/freelancer-contract/freelancer-list',
              show: ( isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewFreelancerList, PERMISSIONS.ViewAddFreelancer, PERMISSIONS.ViewEditFreelancer])) ? "yes" : "no",
            },
            {
              title: 'Contract List',
              path: '/freelancer-contract/contract-list',
              show: ( isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewContractList, PERMISSIONS.ViewAddEditDeleteContract, PERMISSIONS.ViewTerminateContract])) ? "yes" : "no",
            },
            {
              title: 'Standard T&C',
              path: '/freelancer-contract/terms-and-conditions',
              show: ( isPermissionsAllowed(Permission, [PERMISSIONS.ViewAddEditDeleteTnC])) ? "yes" : "no",

            },
            {
              title: 'Incharge',
              path: '/freelancer-contract/incharge',
            },

          ]
        },

        {
          title: 'Attendance',
          path: '/attendance',
          icon: getIcon(AttendanceIcon),
          show: true,
          children: [
            {
              title: 'Attendance Log',
              path: '/attendance/attendance-log',
            },
            {
              title: 'Regularization Request',
              path: '/attendance/regularize-request',
               role: 'ADMIN',
              show: isPermissionsAllowed(Permission, [PERMISSIONS.VerifyRegularization]) ? "yes" : "no",
            },
            {
              title: 'Leave Log',
              path: '/attendance/leave-log',
            },
            {
              title: 'Attendance List',
              path: '/attendance/attendance-lists',
              role: 'ADMIN',
              show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewAttendanceList, PERMISSIONS.ViewEditVerifyAttendanceList, PERMISSIONS.ViewVerifyAttendanceList]) ? "yes" : "no",
            },
          ]
        },
        {
          title: 'Payroll',
          path: '/payroll/payroll-lists',
          icon: getIcon(PayrollIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.SideBarPayroll, PERMISSIONS.OnlyViewPayroll, PERMISSIONS.ViewEditFinalizePayroll, PERMISSIONS.ViewFinalizePayroll]),
        },
        {
          title: 'Payment',
          path: '/payment',
          role: 'ADMIN',
          show: true,
          icon: getIcon(PaymentIcon),
          children: [
            {
              title: 'Salary Payment',
              path: '/payment/salary-payment',
              show: isPermissionsAllowed(Permission, [PERMISSIONS.SideBarSalaryPayment, PERMISSIONS.DownloadPaySlip]) ? "yes" : "no",
            },
            {
              title: 'Payment Request',
              path: '/payment/payment-request',
              // show: auth?.show_as_talent ? "yes" : "no",
              // show: "yes",
            },
            {
              title: 'Talent Payment',
              path: '/payment/talent-payment',
              show: isPermissionsAllowed(Permission, [PERMISSIONS.SideBarTalentPayment]) ? "yes" : "no",
            },
            {
              title: 'Freelancer Payment',
              path: '/payment/freelancer-payment',
              show: isPermissionsAllowed(Permission, [PERMISSIONS.SideBarFreelancerPayment]) ? "yes" : "no",
            },
            {
              title: 'Payment Log',
              path: '/payment/payment-logs',
              show: isPermissionsAllowed(Permission, [PERMISSIONS.SideBarPaymentLog]) ? "yes" : "no",
            },
            {
              title: 'Performance Payment',
              path: '/payment/performance-payment',
              show: isPermissionsAllowed(Permission, [PERMISSIONS.SideBarPerformancePayment]) ? "yes" : "no",
            },
            
          ]
        },
        {
          title: 'Report',
          path: '/report',
          icon: getIcon(ReportIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.ViewReport]),
          children: [
            {
              title: 'Attendance Log Report',
              path: '/report/attendance-log-report',
            },
            {
              title: 'Payroll Report',
              path: '/report/payroll-report',
            },
          ]
        },
        {
          title: 'Master',
          path: '/master',
          icon: getIcon(MastersIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewMaster, PERMISSIONS.ViewAddEditDeleteMaster]),
          children: [
            {
              title: 'Department Master',
              path: '/master/department',
            },
            {
              title: 'Designation Master',
              path: '/master/designation',
            },
            {
              title: 'Leave Master',
              path: '/master/leave'
            }
          ]
        },
        {
          title: 'User Management',
          show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewUserManagement, PERMISSIONS.ViewAddEditCredentials, PERMISSIONS.AddSuperAdmin, PERMISSIONS.ChangeEmployeePassword]),
          path: '/user-management',
          icon: getIcon(UserSettingIcon),
        },
        {
          title: 'Support',
          path: '/support',
          show: true,
          icon: getIcon(SupportIcon),
          children: [
            {
              title: 'FAQ',
              path: '/support/faqs',
            },
            {
              title: 'Tickets',
              path: '/support/tickets',
            },
            {
              title:'Personalised Support',
              path:'/support/onboarding-options'
            }
            
          ]
        },
      ],
    },

  ];
} 

export function SettingSidebarConfig(authData) {

  const Permission = authData?.permission

  const getIcon = (name) => <EIconSideNavbar icon={name}/>;
  return [
    {
      items: [
        {
          title: 'Account',
          path: '/setting/account',
          icon: getIcon(AccountIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewAccountSettings, PERMISSIONS.ViewEditAccountSettings]),
        },
        {
          title: 'Talent Data',
          path: '/setting/talent-setting',
          icon: getIcon(AccountIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewTalentDataSettings, PERMISSIONS.ViewEditTalentDataSettings]),
        },
        {
          title: 'Attendance',
          path: '/setting/attendance-setting',
          icon: getIcon(HistoryIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewAttendanceSettings, PERMISSIONS.ViewEditAttendanceSettings]),
        },
        {
          title: 'Leave',
          path: '/setting/leave-setting',
          icon: getIcon(DateRangeIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewLeaveSettings, PERMISSIONS.ViewEditLeaveSettings]),
        },
        {
          title: 'Payroll',
          path: '/setting/payroll-setting',
          icon: getIcon(RupeeIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewPayrollSettings, PERMISSIONS.ViewEditPayrollSettings]),
        },
        {
          title: 'Payment',
          path: '/setting/payment-setting',
          icon: getIcon(PaymentIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewPaymentSettings, PERMISSIONS.ViewEditPaymentSettings]),
        },
        {
          title: 'Custom User Role',
          path: '/setting/custom-user-role',
          icon: getIcon(UserSettingIcon),
          show: authData?.is_primary,
        },
        {
          title: 'Additional',
          path: '/setting/additional-setting',
          icon: getIcon(RoomIcon),
          show: isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewAdditionalSettings, PERMISSIONS.ViewEditAdditionalSettings]),
        },

      ]
    },

  ];
}

