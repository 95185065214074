import {
  PAYROLL_LIST_FAILURE,
  PAYROLL_LIST_SUCCESS,
  PAYROLL_LIST_LOADING,
  PAYROLL_DETAIL_FAILURE,
  PAYROLL_DETAIL_SUCCESS,
  PAYROLL_DETAIL_LOADING
} from '../constants';


const INITIAL_STATE = {
  payrollListData: {},
  payrollListSuccess: false,
  payrollListLoading: false,
  payrollListMessage: null,

  payrollDetailData: {},
  payrollDetailSuccess: false,
  payrollDetailLoading: false,
  payrollDetailMessage: null,

};

const PayrollReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case PAYROLL_LIST_LOADING:
      return {
        ...state,
        payrollListLoading: true,
        payrollListData: {}
      };

    case PAYROLL_LIST_SUCCESS:
      return {
        ...state,
        payrollListLoading: false,
        payrollListSuccess: true,
        payrollListData: action.payload.data,
        payrollListMessage: action.payload.message,
      };

    case PAYROLL_LIST_FAILURE:
      return {
        ...state,
        payrollListLoading: false,
        payrollListSuccess: false,
        payrollListMessage: action.payload.message,
        payrollListData: {}
      };

    case PAYROLL_DETAIL_LOADING:
      return {
        ...state,
        payrollDetailLoading: true,
        payrollDetailData: {}
      };

    case PAYROLL_DETAIL_SUCCESS:
      return {
        ...state,
        payrollDetailLoading: false,
        payrollDetailSuccess: true,
        payrollDetailData: action.payload.data,
        payrollDetailMessage: action.payload.message,
      };

    case PAYROLL_DETAIL_FAILURE:
      return {
        ...state,
        payrollDetailLoading: false,
        payrollDetailSuccess: false,
        payrollDetailMessage: action.payload.message,
        payrollDetailData: {}
      };
    default:
      return state;
  }
};

export default PayrollReducer;
