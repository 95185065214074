import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { EmployeeListData } from "../../action/EmployeeAction";
import { EBoxPage } from "../../BaseComponents/EBox";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon } from "../../BaseComponents/EButtons";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import { EDivider } from "../../BaseComponents/EDivider";
import EHidden from "../../BaseComponents/EHidden";
import Page from "../../BaseComponents/EPage";
import { ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { ACTIVE_USER, EMPLOYEE_CARD_LIST, EMPLOYEE_GRID_LIST, RELEASED_USER, INACTIVE_USER, PAGES, SideBarTalents, PAYROLL_VERIFIED, PERMISSIONS } from "../../constants";
import TalentListCard from "../../PageComponents/Talents/TalentListCard";
import TalentListGrid from "../../PageComponents/Talents/TalentListGrid";
import TalentSearchBar from "../../PageComponents/Talents/TalentSearchBar";
import AddBoxIcon from '@iconify/icons-material-symbols/add-box-outline-rounded';
import { EIcon } from "../../BaseComponents/EIcon";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import UploadIcon from '@iconify/icons-material-symbols/upload-rounded';
import { ETooltip } from "../../BaseComponents/ETooltip";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
import moment from "moment";
import { useCheckPermission } from "../../hooks";

const TalentList = (props) => {
    const { auth, userStatus } = props;
    const { setting } = useCompanySettingProvider(); 
    const [listToggle, setListToggle] = useState(true);
    const [searchParam, setSearchParam] = useState({ name: '', department: '', designation: '', sort: true, year_month: null });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(EMPLOYEE_CARD_LIST);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const employeeListData = useSelector(state => state.EmployeeReducer);
    const companyDetail = useSelector(state => state.CompanyReducer);
    //Setting data
    const companyAccountSetting = setting?.accountSettingData?.companySetting;
    // const TalentSettingContext = setting?.talentSettingData?.companySetting ?? {};
    const allowed_talent = companyAccountSetting?.company_data?.CompanyActivePlan?.allowed_talent;
    const active_employee_count = companyAccountSetting?.active_employee_count;
    const lastPayrollStatus = companyAccountSetting?.company_data?.lastPayroll?.payroll_status;

    const allowAddTalent = useCheckPermission([PERMISSIONS.ViewAddTalent])
    const allowBulkUpload = allowAddTalent &&  userStatus == ACTIVE_USER && (employeeListData?.employeeData?.count == 0 || (active_employee_count != allowed_talent) && (lastPayrollStatus === null))
    const allowViewTalentDetail = useCheckPermission([PERMISSIONS.ViewEditTalent, PERMISSIONS.ViewReleaseTalent])
    
    const getEmployeeData = (searchData) => {
        callApi(searchData, 0, rowsPerPage);
        setPage(0);
    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ sort: searchParam.sort }, PAGES, EMPLOYEE_CARD_LIST);
            setPage(PAGES)
            setRowsPerPage(EMPLOYEE_CARD_LIST)
            setListToggle(true)
            setSearchParam({ name: '', department: '', designation: '', sort: true, year_month: null })
        }
    }, [userStatus])

    const handleChangePage = (event, newPage) => {
        callApi(searchParam, (newPage), rowsPerPage); //changed newPage-1 to newPage to fix issue on 18-01-23
        setPage(newPage); //changed newPage-1 to newPage to fix issue on 18-01-23
    }

    const handleChangePageCard = (event, newPage) => {
        callApi(searchParam, (newPage - 1), rowsPerPage);
        setPage((newPage - 1));
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, EMPLOYEE_GRID_LIST);
        setRowsPerPage(rowPerPageCount);
        callApi(searchParam, 0, rowPerPageCount);
        setPage(0);
    };

    const listToggleMasterFn = (toggledData) => {
        const rowPerPageToggleCount = toggledData ? EMPLOYEE_CARD_LIST : EMPLOYEE_GRID_LIST;
        setListToggle(toggledData);
        setRowsPerPage(rowPerPageToggleCount)
        callApi(searchParam, 0, rowPerPageToggleCount);
        setPage(0);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(EmployeeListData(auth.authtoken, userStatus, { ...fnParams, sort: !fnParams.sort ? ' fullname ASC ' : ' fullname DESC ' }, fnPage, fnRowPerPage));
    }

    const preTitle = `${userStatus === RELEASED_USER ? 'Released' : userStatus === INACTIVE_USER ? 'Archived' : ' Talent'}`

    return (

        <Page title={`${preTitle} List`} >
            <Grid container display="row" justifyContent="space-between" className="align-center">

                <Grid item>
                    <EHeaderBreadcrumbs
                        heading={`${preTitle} List`}
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Talent', href: '' },
                            {
                                name: `${preTitle} List`,
                            }
                        ]}
                    />
                </Grid>
                {/* INFO: This complonent will be hidden wgen screen size goes below md */}
                <EHidden width="mdDown">
                    <Grid item>
                        {
                            allowBulkUpload
                                ?
                                <ETooltip arrow title="Bulk Talent Upload">
                                    <EButton
                                        to={'/talent/bulk-talent-upload'}
                                        component={Link}
                                        variant="contained"
                                    >
                                        Import
                                    </EButton>
                                </ETooltip>
                                :
                                ''
                        }
                        {
                            allowAddTalent && userStatus === ACTIVE_USER &&
                            <EButton
                                to={'/talent/add-talent'}
                                component={Link}
                                variant="contained"
                                disabled={active_employee_count >= allowed_talent}
                                className='button-left-margin'
                            >
                                New Talent
                            </EButton>
                        }
                        <EButtonOutlined 
                            variant="outlined" 
                            onClick={() => navigate(-1)} 
                            className='button-left-margin'>  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
                className='margin-top-20px '
            >
                <Grid item>
                    <ETypographyPageHeading variant='h5'>
                        {`${preTitle} List`}
                    </ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        {
                            allowBulkUpload
                                ?
                                <EButtonIcon to={'/talent/bulk-talent-upload'} component={Link} variant="contained" >
                                    <EIcon icon={UploadIcon} className='height-width-15px' />
                                </EButtonIcon>
                                :
                                ''
                        }
                        {
                            allowAddTalent && userStatus === ACTIVE_USER && //AddBoxIcon
                            <EButtonIcon 
                                to={'/talent/add-talent'} 
                                className='button-left-margin' 
                                component={Link} 
                                variant="contained" 
                                disabled={active_employee_count >= allowed_talent}
                            >
                                <EIcon icon={AddBoxIcon} className='height-width-15px '/>
                            </EButtonIcon>
                        }
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  
                            <EIcon icon={ForwardArrow} className='height-width-15px'/> 
                        </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
            </Grid>
            <EHidden width="mdDown">
                <EDivider className='mt-8px' />
            </EHidden>
            <EBoxPage>
                <Grid container className='mb-16px'>
                    <TalentSearchBar
                        setListToggle={listToggleMasterFn}
                        listToggle={listToggle}
                        setSearchParam={setSearchParam}
                        searchParam={searchParam}
                        getEmployeeData={getEmployeeData}
                        auth={auth}
                        userStatus={userStatus}
                        employeeListData={employeeListData}
                        companyDetail={companyDetail}
                    />
                </Grid>

                {!employeeListData?.employeeLoading ? <>
                    {listToggle ? (
                        <Grid container>
                            <TalentListCard
                                employeeListData={employeeListData?.employeeData}
                                handleChangePage={handleChangePageCard}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                userStatus={userStatus}
                                authPermission={allowViewTalentDetail}
                            />
                        </Grid>
                    ) : (

                        <TalentListGrid
                            employeeListData={employeeListData?.employeeData}
                            page={page}
                            userStatus={userStatus}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            token={auth?.authtoken}
                            authPermission={allowViewTalentDetail}
                            setting={setting}
                        />

                    )}
                </> : <>
                    <Grid container my={0} className='justify-content-center'>
                        <ECircularProgress color="primary" />
                    </Grid>
                </>}

            </EBoxPage>
        </Page>
    )

};

export default TalentList;
