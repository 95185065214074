import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Grid } from '@mui/material';
import { ECardBorderLess } from '../../../BaseComponents/ECard';
import { ELabelInputMainGrid } from '../../../BaseComponents/EGrid';
import { ETextFieldSmall } from '../../../BaseComponents/ETextField';
import { ReturnBoolean } from '../../../utils/CommonFunctions';
import { ECustomizedRadioWithTitle } from '../../../BaseComponents';
import { OPTIONS } from '../../../pages/Settings/AttendanceSettings';
import { ERadio, ERadioGroup } from '../../../BaseComponents/ERadio';
import { EFormHelperText, ETypography } from '../../../BaseComponents/ETypography';



export const AttendanceRegularizationSettings = ({ formik, disableFields }) => {
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  return (
    <>
      <ECustomizedRadioWithTitle
        label="Attendance regularization"
        extraLabel="Attendance Regularization is an option given to Talents to rectify and amend their incorrect attendance entries, to get paid for their working hours."
        options={OPTIONS}
        value={values?.attendance_regularization_required}
        name="attendance_regularization_required"
        onChange={(value) => setFieldValue('attendance_regularization_required', value)}
        error={errors?.attendance_regularization_required || ""}
        disabled={disableFields}
      />
      {
        values?.attendance_regularization_required
        &&
        <ECardBorderLess sx={{ padding: "20px 26px", mt: 2 }}>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}  >
              <FormControl fullWidth component="fieldset">
                <ERadioGroup
                  row={true}
                  aria-label="attendance_regularization_request"
                  name="attendance_regularization_request"
                  className='align-items-start'
                  value={values?.attendance_regularization_request}
                >
                  <FormControlLabel
                    value={false}
                    sx={{ alignItems: 'flex-start', margin: '0', width: '100%' }}
                    disabled={disableFields}
                    control={
                      <>
                        <ERadio
                          className='p0'
                          checked={values?.attendance_regularization_request === false}
                          onChange={() => setFieldValue('attendance_regularization_request', false)}
                          disabled={disableFields}
                        />
                        <FormLabel sx={{ pl: 1.25, width: '70%' }} >
                          <ETypography onClick={() => !disableFields && setFieldValue('attendance_regularization_request', false)} sx={{ cursor: 'pointer', display: 'inline' }} >Self Regularization</ETypography>
                          <ETypography className='font-size-14px  greyColor4-color' sx={{ mb: 0.5 }}>
                            The user can rectify & amend their incorrect attendance without permission.
                          </ETypography>

                          {values?.attendance_regularization_request === false &&
                            <ELabelInputMainGrid
                              label={"Total Regularization"}
                              small_text={"(per month)"}
                              labelClassname={'color-text-primary '}
                              alignlabel={'align-center'}
                              labelPT={'0px'}
                            >
                              <ETextFieldSmall
                                size='small'
                                autoComplete="off"
                                placeholder="Total Regularization"
                                {...getFieldProps("attendance_regularization_count")}
                                value={values?.attendance_regularization_count || ""}
                                error={Boolean(touched.attendance_regularization_count && errors.attendance_regularization_count)}
                                helperText={touched.attendance_regularization_count && errors.attendance_regularization_count}
                                disabled={disableFields}
                              />
                            </ELabelInputMainGrid>
                          }
                        </FormLabel>
                      </>
                    }
                  />
                </ERadioGroup>
                <ERadioGroup
                  row={true}
                  aria-label="attendance_regularization_request"
                  name="attendance_regularization_request"
                  className='align-items-start'
                  value={values?.attendance_regularization_request}
                  sx={{ mt: 2 }}
                >
                  <FormControlLabel
                    value={true}
                    sx={{ alignItems: 'flex-start', margin: '0' }}
                    control={
                      <>
                        <ERadio
                          className='p0'
                          checked={values?.attendance_regularization_request === true}
                          onChange={() => setFieldValue('attendance_regularization_request', true)}
                          disabled={disableFields}
                        />
                        <FormLabel sx={{ pl: 1.25 }} >
                          <ETypography onClick={() => !disableFields && setFieldValue('attendance_regularization_request', true)} sx={{ cursor: 'pointer', display: 'inline' }} >Get Approval from HR</ETypography>
                          <ETypography className='font-size-14px  greyColor4-color'>
                            The user needs to seek approval from HR to get their attendance regularized.
                          </ETypography>
                        </FormLabel>
                      </>
                    } />
                </ERadioGroup>
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='py0'>
              {errors?.attendance_regularization_request && <EFormHelperText className='color-text-error-red m0px'>
                {errors?.attendance_regularization_request}</EFormHelperText>}
            </Grid>
          </Grid>
        </ECardBorderLess>
      }
    </>
  );
};
