import { Avatar, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { ECardBorderLess } from "../../BaseComponents/ECard";
import { ETypography } from "../../BaseComponents/ETypography";
import SearchNotFound from "../SearchNotFound";
import { ECardPagination } from "../../BaseComponents/ETablePagination";
import base64 from 'base-64'
import { getInitials, HandlePhoto } from "../../utils/CommonFunctions";

function FreelancerListCard(props) {
    const { freelanceListData, handleChangePage, page, allowViewFreelancerDetail } = props;
    const totalNoOfPages = (freelanceListData?.count / 12) ? Math.ceil(freelanceListData?.count / 12) : 0;
    let thisPage = (page + 1);

    return (
        <>
            <Grid container spacing={2}>
                {
                    (freelanceListData?.rows && freelanceListData?.rows?.length > 0 ? (

                        freelanceListData?.rows && freelanceListData.rows.map((row, index) => {
                            const { id, freelancer_name, freelancer_contact_no, freelancer_photo, freelancer_gender } = row;

                            return (
                                <Grid item xs={12} sm={6} md={4} xl={3} key={index+id+freelancer_name} >
                                    <ECardBorderLess className="person-card-css pt-16px height-220px" >
                                        <Grid
                                            to={allowViewFreelancerDetail ? `/freelancer-contract/freelancer/${base64.encode(row.id)}` : ''}
                                            component={Link}
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Avatar
                                                title={freelancer_name} alt={freelancer_name}
                                                src={(freelancer_photo || freelancer_gender) ? HandlePhoto(freelancer_photo, freelancer_gender) : '  '}
                                                key={index}
                                                className='cursor-pointer card-view-avatar borderRadius-20px'
                                            >
                                                {!freelancer_gender && <span className="font-size-20px theme-color-static">{getInitials(freelancer_name)}</span>}
                                            </Avatar>
                                            <ETypography className="card-name-text-style pt-16px">
                                                {freelancer_name}
                                            </ETypography>
                                            <ETypography className="card-name-text-style-2">
                                                {freelancer_contact_no ? freelancer_contact_no : '-'}
                                            </ETypography>
                                        </Grid>
                                    </ECardBorderLess>
                                </Grid>
                            )
                        })
                    ) : (<SearchNotFound />))

                }
            </Grid>
            {freelanceListData?.rows && freelanceListData?.rows?.length > 0 &&
                <Grid container my={3}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <ECardPagination
                        size="large"
                        count={totalNoOfPages}
                        page={thisPage}
                        onChange={handleChangePage}
                        boundaryCount={0}
                    />
                </Grid>
            }
        </>
    );
}

export default FreelancerListCard;
