import { Box, Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import { PAYMENT_CATEGORY, SUPER_ADMIN, ROLE_ADMIN, ROLE_HR, PAYMENT_LIST_DOWNLOAD_URL, DOWNLOAD_PDF, DOWNLOAD_CSV, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_ADVANCE } from "../../constants";
import { ETextFieldSearch } from "../../BaseComponents/ETextField";
import { EIcon } from "../../BaseComponents/EIcon";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import EMenuPopover, { EMenuItem } from "../../BaseComponents/EMenuPopover";
import moment from "moment";
import { DownLoadFile } from "../../action/AdminAction";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { ETooltip } from "../../BaseComponents/ETooltip";
import { getPaymentCategory } from "../../utils/getPaymentCategory";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
// import { EAutocomplete } from "../../BaseComponents/EAutocomplete";

// const status = [
//     { id: PAID_STATUS, label: PAID_STATUS },
//     { id: CANCELLED_STATUS, label: CANCELLED_STATUS },
// ]

const PaymentRequestSearchBar = (props) => {
    // const settingds = useCompanySettingProvider();
    const { searchParam, setSearchParam, getFilterData, employeeList, companyData, auth, setting } = props;

    // const PAYMENT_CATEGORY_NEW = setting?.paymentSettingData?.enable_reimbursement ? PAYMENT_CATEGORY : PAYMENT_CATEGORY.filter((item)=>item.id !== PAYMENT_CATEGORY_REIMBURSEMENT)

    const paymentSettingData = setting?.paymentSettingData?.companySetting;
    const ENABLE_REMBURSEMENT = paymentSettingData?.enable_reimbursement;
    const ENABLE_ADVANCE_SALARY_SETTING = paymentSettingData?.enable_advance_salary;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // console.log(settingds);

    const setSearch = (key, value) => {
        const sParams = { ...searchParam, [key]: value };
        setSearchParam(sParams);
        getFilterData(sParams)
    }


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const DownloadReport = (value) => {
        let url = '';

        let file_name = 'Payment_Request_List';

        url += PAYMENT_LIST_DOWNLOAD_URL + '?page_name=request_employee&';

        if (value != null) { url += `download_format=${value}&`; }
        if (searchParam?.employee_id?.id != null) { url += `employee_id=${searchParam?.employee_id?.id}&`; }
        if (searchParam?.particular?.id != null) { url += `payment_category=${searchParam?.particular?.id}&`; }
        if (searchParam?.start_date != null) { url += `start_date=${moment(searchParam?.start_date).format('YYYY-MM-DD')}&`; }
        if (searchParam?.end_date != null) { url += `end_date=${moment(searchParam?.end_date).format('YYYY-MM-DD')}&`; }
        if (searchParam?.sort != null) { url += `sort=${searchParam?.sort ? ' ASC ' : ' DESC'}`; }




        DownLoadFile(auth.authtoken, url, file_name);
        handleClose();



    };

    return (
        <Grid container className="mb-8px"  >
            <Grid className="pl-0" item xs={10.5} sm={10} md={9} lg={9} xl={10} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={5} sm={4} md={4} lg={4} xl={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}  >
                            <EDatePicker
                                inputFormat="dd/MM/yyyy"
                                fullWidth
                                value={searchParam.start_date ? new Date(searchParam.start_date) : null}
                                minDate={new Date(companyData?.companyData?.CompanyActivePlan?.plan_valid_from)}
                                maxDate={searchParam.end_date ? new Date(searchParam.end_date) : new Date()}
                                clearable
                                onChange={(newValue) => setSearch('start_date', newValue ? newValue : null)}
                                renderInput={({ inputProps, ...params }) => <ETextFieldSearch
                                    size='small' fullWidth
                                    {...params}
                                    inputProps={{
                                        ...inputProps,
                                        placeholder: "Start Date",
                                    }}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={5} sm={4} md={4} lg={4} xl={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}  >
                            <EDatePicker
                                inputFormat="dd/MM/yyyy"
                                value={searchParam.end_date ? new Date(searchParam.end_date) : null}
                                minDate={
                                    searchParam.start_date
                                        ? new Date(searchParam.start_date)
                                        : new Date(companyData?.companyData?.CompanyActivePlan?.plan_valid_from)
                                }
                                maxDate={new Date()}
                                clearable
                                fullWidth
                                onChange={(newValue) => setSearch('end_date', newValue ? newValue : null)}
                                renderInput={({ inputProps, ...params }) => <ETextFieldSearch
                                    size='small'
                                    fullWidth
                                    {...params}
                                    inputProps={{
                                        ...inputProps,
                                        placeholder: "End Date",
                                    }}
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {(auth?.authData?.role == ROLE_HR ||
                        auth?.authData?.role == ROLE_ADMIN ||
                        auth?.authData?.role == SUPER_ADMIN) &&
                        <Grid item xs={5} sm={4} md={4} lg={4} xl={3}>
                            <EAutocomplete
                                name="talent"
                                label="Talent Name"
                                fullWidth
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                options={employeeList && employeeList?.employeeData?.count > 0 ? employeeList?.employeeData?.rows : []}
                                value={searchParam?.employee_id}
                                getOptionLabel={(option) => option.label || ''}
                                onChange={(e, value) => {
                                    setSearch('employee_id', value ? value : null);
                                }}
                                renderInput={(params) => (
                                    <ETextFieldSearch
                                        {...params}
                                        name="talent"
                                        size="small"
                                        placeholder="Select Talent"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>}

                    <Grid item xs={5} sm={4} md={4} lg={4} xl={3}>
                        <EAutocomplete
                            name="particular"
                            options={
                                ENABLE_REMBURSEMENT && ENABLE_ADVANCE_SALARY_SETTING ?
                                    getPaymentCategory('ENABLE_BOTH', PAYMENT_CATEGORY) :
                                    ENABLE_REMBURSEMENT ?
                                        getPaymentCategory('ENABLE_REIMBURSEMENT', PAYMENT_CATEGORY)
                                        : ENABLE_ADVANCE_SALARY_SETTING ?
                                            getPaymentCategory('ENABLE_ADVANCE_SALARY', PAYMENT_CATEGORY) : getPaymentCategory('DISABLE_BOTH', PAYMENT_CATEGORY)

                            }
                            value={searchParam?.particular}
                            onChange={(e, value) => {
                                setSearch('particular', value ? value : null);
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <ETextFieldSearch
                                    {...params}
                                    name="particular"
                                    fullWidth
                                    placeholder="Select Particular"
                                    size="small"
                                />
                            )}
                        />
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={1} sm={2} md={3} lg={3} xl={2}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <ETooltip title={'Download'} arrow>
                        <IconButton onClick={handleClick}>
                            <EIcon icon={FileDownloadIcon} />
                        </IconButton>
                    </ETooltip>
                    {/* // EMenuPopover */}
                    <EMenuPopover
                        open={open}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                        sx={{ width: 180, top: 40 }}
                    >
                        <EMenuItem
                            sx={{ typography: 'body2', py: 1, px: 2.5 }}
                            onClick={() => DownloadReport(DOWNLOAD_PDF)}
                        >
                            <Box
                                sx={{ mr: 2, width: 24, height: 24 }}>
                                <PictureAsPdfIcon />
                            </Box>
                            PDF
                        </EMenuItem>
                        <EMenuItem
                            sx={{ typography: 'body2', py: 1, px: 2.5 }}
                            onClick={() => DownloadReport(DOWNLOAD_CSV)}
                        >
                            <Box sx={{ mr: 2, width: 24, height: 24 }} >
                                <InsertDriveFileIcon />
                            </Box>
                            CSV
                        </EMenuItem>
                    </EMenuPopover>
                    <ETooltip title={'Sort By Date'} arrow>
                        <IconButton onClick={() => setSearch('sort', !searchParam.sort)}>
                            <SortByAlphaIcon />
                        </IconButton>
                    </ETooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PaymentRequestSearchBar;