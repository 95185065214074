
import { Grid, Stack } from "@mui/material";
import { useFormik, Form, FormikProvider, Field } from 'formik';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { NewSuperAdminCreate } from "../../action/EmployeeAuthAction";
import { ECheckbox } from "../../BaseComponents/ECheckbox";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { EInputAdornment, ETextField } from "../../BaseComponents/ETextField";
import { EMAIL, MOBILE_NO, MOBILE_NUMBER_LIMIT, SUPER_ADMIN, WORK_MODE } from "../../constants";
import { AddSuperAdminValidation } from "../../utils/validation";
import moment from 'moment';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
/**
 * [2022-21-11]
 * Admin: Aanchal Sahu
 * [2023-01-10]
 * Changes by: Aanchal Sahu
 * Description: 
 * 1. removed (const [isNext, setIsNext] = useState(0);) to optimize code
 * 2. changed DepartmentListData  with  dept?.departmentListData?.rows
 * 3. changed DesignationListData with designation?.designationListData?.rows
 * 4. made Select Username as a autocomplete from radio
 * 5. removed calling of DepartmentList & DesignationList
 * 6. removed all console errors
 **/
function AddSuperAdmin(props) {
  const dispatch = useDispatch();
  const { search, empAuth, company, dept, designation, auth } = props;
  const [openDateDOJ, setOpenDateDOJ] = useState(false);
  const { close } = props;

  // INFO: getting company settings
  const settingsContext = useCompanySettingProvider()
  const LastPayrollDataToDate = settingsContext?.setting?.accountSettingData?.company_data?.lastPayroll?.to_date// fetching payroll data here from settings

  // Info: As per discussion with @Dharam Sir & @sunil Sir on 16th May 23, The only validation in the DOJ will be the last Payroll locked date (to_date+1) else it will be open.
  const minDojValidation = LastPayrollDataToDate ? moment(LastPayrollDataToDate).add(1, 'day').toDate() : null;

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      fname: '',
      lname: '',
      mobile: '',
      email: '',
      // username: '',
      // username_type: null,
      is_primary: false,
      show_as_talent: false,
      doj: null,
      department: null,
      designation: null,
      payroll_basic: '',
      work_mode: null,
      isNext: 0
    },
    validationSchema: AddSuperAdminValidation(auth.authtoken),
    onSubmit: (data) => {
      data = {
        ...data,
        updated_by: auth?.authData?.id,
        search: {...search,role:search?.role_object?.id},
        department_id: data.department ? data.department.id : null,
        designation_id: data.designation ? data.designation.id : null,
        work_mode_id: data.work_mode ? data.work_mode.id : null
      };

      dispatch(NewSuperAdminCreate(auth.authtoken, data, close));
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  // INFO: isSaveAndNext is tracking the validation errors of 1st page to block proceeding without filling them all.
  const isSaveAndNext = (errors?.fname || errors?.email || errors?.lname || errors?.mobile || errors?.username || errors?.username_type) ? false : true;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={{ xs: 0, sm: 1, md: 2 }} mt={1}>

            {formik?.values?.isNext == 0 ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'First Name'} isfullgrid={true} isModal={true}>
                    <ETextField
                      fullWidth
                      placeholder="First Name"
                      {...getFieldProps('fname')}
                      error={Boolean(touched.fname && errors.fname)}
                      helperText={touched.fname && errors.fname}
                    />
                  </ELabelInputMainGrid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Last Name'} isfullgrid={true} isModal={true}>
                    <ETextField
                      fullWidth
                      placeholder="Last Name"
                      {...getFieldProps('lname')}
                      error={Boolean(touched.lname && errors.lname)}
                      helperText={touched.lname && errors.lname}
                    />
                  </ELabelInputMainGrid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Email ID'} isfullgrid={true} isModal={true}>
                    <ETextField
                      fullWidth
                      placeholder="youremailid@domain.com"
                      {...getFieldProps('email')}
                      onChange={(e) => {
                        setFieldValue('email', e.target.value ? e.target.value : '');
                        if (formik?.values?.username_type?.id == EMAIL) {
                          setFieldValue('username', e.target.value);
                        }
                      }}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </ELabelInputMainGrid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Contact Number'} isfullgrid={true} isModal={true}>
                    <ETextField
                      fullWidth
                      inputProps={{ maxLength: MOBILE_NUMBER_LIMIT }}
                      {...getFieldProps('mobile')}
                      onChange={(e) => {
                        setFieldValue('mobile', e.target.value ? e.target.value : '');
                        if (formik?.values?.username_type?.id == MOBILE_NO) {
                          setFieldValue('username', e.target.value);
                        }
                      }}
                      error={Boolean(touched.mobile && errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      InputProps={{
                        startAdornment: <EInputAdornment position="start" ><span className="color-text-primary">+91</span></EInputAdornment>
                      }}
                    />
                  </ELabelInputMainGrid>
                </Grid>
                {
                  /* 
                  * commenting username because we are no longer allowing user's to add or update the username
                  */
                }
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Select Username'} isfullgrid={true} isModal={true}>
                    <EAutocomplete
                      name="username_type"
                      // disableClearable
                      forcePopupIcon={formik?.values?.username_type ? false : true}
                      fullWidth
                      options={
                        USERNAME_INITIAL_OPTIONS && USERNAME_INITIAL_OPTIONS?.length > 0 ? USERNAME_INITIAL_OPTIONS : []
                      }
                      getOptionLabel={(option) => option.label || ""}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      {...getFieldProps('username_type')}
                      onChange={(e, value) => {
                        setFieldValue('username_type', value ? value : null);
                        setFieldValue('username',
                          value && value.id == EMAIL ? (formik?.values?.email ? formik?.values?.email : '') //if email is selected as an option
                            : value && value.id == MOBILE_NO ? (formik?.values?.mobile ? formik?.values?.mobile : '') //if mobile no is selected as an option
                              : '' //if other is selected as an option
                        );
                      }}
                      renderInput={(params) => (
                        <ETextField
                          {...params}
                          name="username_type"
                          fullWidth
                          placeholder="Select Username"
                          error={Boolean(touched.username_type && errors.username_type)}
                          helperText={touched.username_type && errors.username_type}
                        />
                      )}
                    />
                  </ELabelInputMainGrid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Username'} isfullgrid={true} isModal={true}>
                    <ETextField
                      name="username"
                      {...getFieldProps('username')}
                      fullWidth
                      error={Boolean(touched.username && errors.username)}
                      helperText={touched.username && errors.username}
                      InputProps={{ readOnly: formik?.values?.username_type && formik?.values?.username_type?.id == OTHER ? false : true }}
                      disable={formik?.values?.username_type && formik?.values?.username_type?.id == OTHER ? 'false' : 'true'}
                    />
                  </ELabelInputMainGrid>
                </Grid> */}

                {auth?.authData?.role == SUPER_ADMIN && auth?.authData?.is_primary == true && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ELabelInputMainGrid label={''} disableColon={true} isfullgrid={true} isModal={true}>
                      <ECheckbox name="is_primary" {...getFieldProps('is_primary')} className='pl0' />
                      Make user primary
                    </ELabelInputMainGrid>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} className='pt-0'>
                  <ELabelInputMainGrid label={''} disableColon={true} isfullgrid={true} isModal={true}>
                    <ECheckbox
                      name="show_as_talent"
                      checked={formik?.values?.show_as_talent ? formik?.values?.show_as_talent : false}
                      {...getFieldProps('show_as_talent')}
                      onChange={(e, value) => {
                        setFieldValue('show_as_talent', value);
                      }}
                      className='pl0'

                    />
                    Show in payroll
                  </ELabelInputMainGrid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Date of Joining'} isfullgrid={true} isModal={true}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <EDatePicker
                        name="doj"
                        {...getFieldProps('doj')}
                        minDate={minDojValidation}
                        maxDate={new Date()}
                        open={openDateDOJ}
                        onOpen={() => setOpenDateDOJ(true)}
                        onClose={() => setOpenDateDOJ(false)}
                        inputFormat="dd/MM/yyyy"
                        onChange={(selectedDate) => {
                          setFieldValue('doj', selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null);
                        }}
                        renderInput={(params) => (
                          <Field
                            component={ETextField}
                            {...params}
                            onClick={(e) => { setOpenDateDOJ(true) }}
                            fullWidth
                            name="doj"
                            error={Boolean(touched.doj && errors.doj)}
                            helperText={touched.doj && errors.doj}
                          />
                        )}

                      />
                    </LocalizationProvider>
                  </ELabelInputMainGrid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Department'} isfullgrid={true} isModal={true}>
                    <EAutocomplete
                      name="department"
                      forcePopupIcon={formik?.values?.department ? false : true}
                      fullWidth
                      options={
                        dept?.departmentListData?.rows && dept?.departmentListData?.rows?.length > 0 ? dept?.departmentListData?.rows : []
                      }
                      getOptionLabel={(option) => option.label || ""}
                      isOptionEqualToValue={(option, value) => option ? (option.id === value.id) : true}
                      {...getFieldProps('department')}
                      onChange={(e, value) => {
                        setFieldValue('department', value ? value : null);
                      }}
                      renderInput={(params) => (
                        <ETextField
                          {...params}
                          name="department"
                          fullWidth
                          placeholder="Select department"
                          error={Boolean(touched.department && errors.department)}
                          helperText={touched.department && errors.department}
                        />
                      )}
                    />
                  </ELabelInputMainGrid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Designation'} isfullgrid={true} isModal={true}>
                    <EAutocomplete
                      name="designation"
                      forcePopupIcon={formik?.values?.designation ? false : true}
                      fullWidth
                      options={
                        designation && designation?.designationListData?.rows?.length > 0
                          ? designation?.designationListData?.rows
                          : []
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.label || ""}
                      {...getFieldProps('designation')}
                      onChange={(e, value) => {
                        setFieldValue('designation', value ? value : null);
                      }}
                      renderInput={(params) => (
                        <ETextField
                          {...params}
                          name="designation"
                          placeholder="Select designation"
                          error={Boolean(touched.designation && errors.designation)}
                          helperText={touched.designation && errors.designation}
                        />
                      )}
                    />
                  </ELabelInputMainGrid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Salary'} small_text='(CTC/Month)' isfullgrid={true} isModal={true}>
                    <ETextField
                      fullWidth
                      autoComplete="payroll_basic"
                      placeholder="Salary"
                      {...getFieldProps('payroll_basic')}
                      error={Boolean(touched.payroll_basic && errors.payroll_basic)}
                      helperText={touched.payroll_basic && errors.payroll_basic}
                    />
                  </ELabelInputMainGrid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Work Mode'} isfullgrid={true} isModal={true}>
                    <EAutocomplete
                      name="work_mode"
                      forcePopupIcon={formik?.values?.work_mode ? false : true}
                      fullWidth
                      options={WORK_MODE && WORK_MODE.length > 0 ? WORK_MODE : []}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.label || ""}
                      {...getFieldProps('work_mode')}
                      onChange={(e, value) => {
                        setFieldValue("work_mode", value ? value : null)
                      }}
                      renderInput={params => (
                        <ETextField
                          {...params}
                          name='work_mode'
                          placeholder='Select Work Mode'
                          fullWidth
                          error={Boolean(touched.work_mode && errors.work_mode)}
                          helperText={touched.work_mode && errors.work_mode}
                        />
                      )}
                    />
                  </ELabelInputMainGrid>
                </Grid>
              </>
            )}
            <Grid item xs={12} xl={12} className="modal1-buttons-stick-bottom">
              <Stack direction="row" spacing={2} justifyContent='flex-end'>
                {formik?.values?.show_as_talent && formik?.values?.isNext == 1 &&
                  <EButtonOutlined onClick={() => { setFieldValue("isNext", 0); }} variant="outlined" size="large"> Back </EButtonOutlined> //back button
                }
                <EButtonOutlined color="secondary" onClick={() => close()} variant="outlined" size="large"  > Cancel </EButtonOutlined>{/* back button */}

                <ELoadingButton type="submit" variant="contained" size="large" loading={empAuth?.empAuthLoading}    // Save & Next and Submit button
                  onClick={() => { formik?.values?.show_as_talent && formik?.values?.isNext == 0 && isSaveAndNext && setFieldValue("isNext", 1) }}>
                  {!formik?.values?.show_as_talent ? 'Save'
                    : formik?.values?.show_as_talent && formik?.values?.isNext == 0 ? 'Save & Next'
                      : 'Submit'}
                </ELoadingButton>

              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}

export default AddSuperAdmin;
