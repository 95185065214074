import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Grid, IconButton, useTheme } from '@mui/material';
import base64 from 'base-64'

import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline-rounded'
import AddBoxIcon from '@iconify/icons-material-symbols/add-box-outline-rounded';

import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../../BaseComponents/ETable';
import { DEFAULT_ROWS_PERPAGE, DELETE, PAGES, ROW_SIZE_PER_PAGE, USER_ROLE_FETCH_TYPE } from '../../../constants';
import { EIcon } from '../../../BaseComponents/EIcon';
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon, EIconButton, ELoadingButton } from '../../../BaseComponents/EButtons';
import { ETypography } from '../../../BaseComponents/ETypography';
import { EBoxPage } from '../../../BaseComponents/EBox';
import { ETablePagination } from '../../../BaseComponents/ETablePagination';
import EModal from '../../../BaseComponents/EModal';
import EScrollbar from '../../../BaseComponents/EScrollbar';
import { ECircularProgress } from '../../../BaseComponents/ECircularProgress';
import SearchNotFound from '../../../PageComponents/SearchNotFound';
import { LeaveMasterList, CreateUpdateDelLeaveMaster } from '../../../action/LeaveAction';
import EHidden from '../../../BaseComponents/EHidden';
import { EPageWithBreadCrumbs } from '../../../BaseComponents';
import { getCustomUserRoleList, saveCustomUserRole } from '../../../action/CustomUserRole';

const TABLE_HEAD = [
  { id: 'sno', label: 'S.No.', alignRight: 'left' },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'action', label: 'Action', alignRight: 'center' },
];

const TITLE = 'Custom User Role'
const breadcrumbsLinks = [
  { name: 'Dashboard', href: '/' },
  { name: 'Company Settings', href: '' },
  { name: TITLE },
]

const initialModalData = { open: false, data: null }

export const CustomUserRoleList = () => {

  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const auth = useSelector((state) => state.authReducer)

  const { customRoleList: data = [], customRoleListLoading: loading, saveCustomUserRoleLoading } = useSelector((state) => state.CustomUserRole);


  const [isModal, SetIsModal] = useState(initialModalData)

  /**
   * To fetch the table data for custom user role
   */
  const fetchData = () => {
    const params = {
      type: USER_ROLE_FETCH_TYPE.ONLY_CUSTOM
    }
    dispatch(getCustomUserRoleList({ params }))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleClose = () => {
    SetIsModal(initialModalData)
  }

  //INFO: This function contains action for delete of leave reason
  const handleDelete = () => {
    const data = { id: isModal?.data?.id };
    dispatch(saveCustomUserRole({ data, url: 'setting/delete-custom-user-role' }, () => {
      /**
       * After deleting the custom user role refetch the data and close the modal
       */
      fetchData()
      handleClose()
    }))
  }

  return (
    <EPageWithBreadCrumbs
      title={TITLE}
      pageHeading={TITLE}
      breadcrumbsTitle={TITLE}
      breadcrumbsLinks={breadcrumbsLinks}
      hideDivider={true}
      loading={loading}
      rightComponent={
        <>
          <EHidden width="mdDown">
            <Grid>
              <EButton
                variant="contained"
                to={`add`}
                component={Link}
                mr={2}
                sx={{ mr: 2 }}
              >
                Add Custom Role
              </EButton>
              <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
            </Grid>
          </EHidden>
          <EHidden width="mdUp">
            <Grid>
              <EButtonIcon
                to={`add`}
                className='button-left-margin'
                component={Link}
                variant="contained"
              >
                <EIcon icon={AddBoxIcon} className='height-width-15px ' />
              </EButtonIcon>
              <EButtonOutlinedIcon
                variant="outlined"
                onClick={() => navigate(-1)}
                className='button-left-margin'>
                <EIcon icon={ForwardArrow} className='height-width-15px' />
              </EButtonOutlinedIcon>
            </Grid>
          </EHidden>
        </>
      }
    >
      <EBoxPage>
        <EScrollbar>
          <ETableContainer >
            <ETable>
              {
                data?.length > 0 &&
                <>
                  <EListHead
                    headLabel={TABLE_HEAD}
                    onRequestSort={() => null}
                    authvar={auth}
                  />
                  <ETableBody>
                    {data?.map((row, index) => {
                      const { role_display_name, id } = row;
                      return (
                        <ETableRow hover key={index + role_display_name}>
                          <ETableCell align="left" width={80} >{index + 1}</ETableCell>
                          <ETableCell align="left" className='text-transform-capitalize' >{role_display_name}</ETableCell>
                          <ETableCell align="center" width={100} >
                            <EIconButton
                              className="ml-4px p0"
                              to={`${base64.encode(id)}`}
                              component={Link}>
                              <EIcon icon={EditIcon} sx={{ width: 24, height: 24 }} />
                            </EIconButton>
                            <EIconButton
                              className="ml-16px p0"
                              onClick={() => SetIsModal({
                                open: true,
                                data: { role_display_name: row?.role_display_name, parent_role_name: row?.parent_role_name, id: id }
                              })}
                            >
                              <EIcon icon={DeleteIcon} sx={{ width: 24, height: 24, color: theme.palette.chip.errorText }} />
                            </EIconButton>
                          </ETableCell>
                        </ETableRow>
                      )
                    })}
                  </ETableBody>
                </>
              }
            </ETable>
          </ETableContainer>
        </EScrollbar>
        {data?.length === 0 && <SearchNotFound />}
      </EBoxPage>

      {/* INFO: This is the delete modal */}
      {isModal.open && (
        <EModal
          open={isModal.open}
          close={handleClose}
          headervalue={"Custom User Role: Delete Confirmation"}
          parentClassname='delete-confirmation-modal'
        >
          <Grid container spacing={2} >
            <Grid item>
              <ETypography className="font-size-18px "> On deleting
                <span className="theme-main-text-color-bold"> {isModal?.data?.role_display_name}</span>,
                the employee will have access to only
                <span className="theme-main-text-color-bold"> {isModal?.data?.parent_role_name} </span>
                related permissions and will no longer be able to continue with custom permissions.
                <br />
                Are you sure you want to delete <span className="theme-main-text-color-bold"> {isModal?.data?.role_display_name}</span>?
              </ETypography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
              <EButtonOutlined size="large" variant="outlined" onClick={handleClose} > No </EButtonOutlined>
              <ELoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={handleDelete}
                className='button-left-margin'
                loading={saveCustomUserRoleLoading}
              >
                Yes
              </ELoadingButton>
            </Grid>
          </Grid>
        </EModal>
      )}
    </EPageWithBreadCrumbs>
  )
}
