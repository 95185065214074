
import { Grid, useTheme } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import { ETable, ETableBody, ETableCell, ETableContainer, ETableRowXSNewBorder } from "../../BaseComponents/ETable";
import { ETypography } from "../../BaseComponents/ETypography";
import { DEFAULT_ROWS_PERPAGE_4, PAGES, } from "../../constants";
import { ELinkHRDAshMUI, ELinkMUI } from "../../BaseComponents/ELink";
import { useNavigate } from "react-router-dom";
import base64 from 'base-64'
import { AttendanceLeaveList } from "../../action/EmployeeLeaveAction";
import EChip from "../../BaseComponents/EChip";
import LeaveChart from "./LeaveChart";
import { EButton } from "../../BaseComponents/EButtons";
import { useState } from "react";
import { EBox } from "../../BaseComponents/EBox";

function LeavesDashboard(props) {
    const theme = useTheme()
    const { auth, show_as_talent, LeaveRequestCardArray } = props
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const empleave = useSelector(state => state.LeaveEmployeeReducer);

    const [ApplyLeave, setApplyLeave] = useState(false);

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(AttendanceLeaveList(auth.authtoken, { employee_id: auth?.authData?.id }, PAGES, DEFAULT_ROWS_PERPAGE_4));
        }
    }, [auth?.authtoken])

    return (
        <Grid container spacing={1}  sx={{height:'100%'}}>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <EBox sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:'5px'}}>
                    <ETypography className='font-size-20px bold-600'>My leaves</ETypography>
                    <EButton onClick={(e) => setApplyLeave(true)} className='' disabled={show_as_talent ? false : true}>Apply Leave</EButton>
                </EBox>
            </Grid>

            {/* <Grid className='justify-content-space-between' > */}

                <Grid item xs={6} md={6} lg={6} xl={6} xxl={6} className='pt-0' sx={{ display:'flex', alignItems:'center', width:'100% !important'}}>
                    <LeaveChart auth={auth} show_as_talent={show_as_talent} loading={empleave?.empLeaveLoading} ApplyLeave={ApplyLeave} setApplyLeave={setApplyLeave}/>                  
                </Grid> 

                <Grid item xs={6} md={6} lg={6} xl={6} xxl={6}>
                    <Grid container spacing={2} className='justify-content-space-between' >
                        {
                        empleave?.empLeaveListLoading 
                        ?
                            <ECircularProgress color="primary" />
                        : 
                            empleave && empleave?.empLeaveListData?.rows?.length > 0 ?
                                <Grid item xs={12} className='minHeight-262px pt-0'>
                                    <ETableContainer className='dashboard-table-container  p0 overflow-hidden' >
                                        <ETable>
                                            <ETableBody>
                                                {empleave?.empLeaveListData?.rows?.map((row, index) => {
                                                    const { final_approval_status, leave_status, reason, reporting_manager_status, from_date, to_date, final_approval_by } = row;
                                                    const to = moment(to_date).format('YYYY-MM-DD')
                                                    const from = moment(from_date).format('YYYY-MM-DD')
                                                    const diff = to && from ? ' (' + (Math.floor((moment(to)).diff((moment(from)), 'days', true)) + 1) + ' days leave)' : ''
                                                    return (
                                                        <ETableRowXSNewBorder hover key={index} tabIndex={-1} sx={{ margin: '10px 0px', height: '65px' }} >
                                                            <ETableCell align="left" sx={{}} >
                                                                {from_date ? <>
                                                                    <ETypography className='font-size-14px' sx={{fontWeight:'600', color: theme.palette.text.blackGrey}}>
                                                                        {from_date ? moment(from_date).format('DD MMM') : ""}
                                                                        {to_date && from_date && from_date != to_date ? ' - ' + moment(to_date).format('DD MMM') : ""}
                                                                    </ETypography>

                                                                    <ETypography className='font-size-12px' sx={{fontWeight:'400', color:theme.palette.text.greyColor7}}>
                                                                        {to_date ? diff : ""}
                                                                    </ETypography>

                                                                </>
                                                                    : '-'}
                                                            </ETableCell>


                                                            {/* <ETableCell align="left" sx={{ padding: '10px 15px' }}>
                                                                <ETooltip title={reason}><EIcon icon={InfoIcon} width={23} height={23}></EIcon></ETooltip>
                                                            </ETableCell> */}

                                                            {/* chip */}
                                                            <ETableCell align="center">
                                                                <EChip width='5.375'
                                                                    label={
                                                                        reporting_manager_status == '4' || leave_status == '4' //case 2 case 4
                                                                            ? 'Rejected'
                                                                            : leave_status == '3'//case 3
                                                                                ? 'Approved' :
                                                                                leave_status == '2' ? 'Partial' :
                                                                                    // (reporting_manager_status == '3' || reporting_manager_status == '2') && row?.reporting_manager_id == auth?.authData?.id ?//case 1
                                                                                    //     "HR Pending" :
                                                                                    reporting_manager_status == '3' || reporting_manager_status == '1' || (reporting_manager_status == '2') ?//case 1 
                                                                                        "Pending"
                                                                                        : '-'
                                                                    }
                                                                />
                                                            </ETableCell>
                                                        </ETableRowXSNewBorder>
                                                    )
                                                })}
                                            </ETableBody>
                                        </ETable>
                                    </ETableContainer>
                                </Grid>

                                :
                                <Grid item xs={12} className='' sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <img alt='' src="/assets/images/leave_nodata.svg" style={{width:'100%', height:'70%'}}/>
                                    <ETypography className='font-size-14px' align="center">You don’t have any scheduled leave.</ETypography>
                                    {/* <ETypography className='font-size-14px theme-color-static'>Previous Month</ETypography> */}
                                </Grid>
                        }

                    </Grid>

                    {empleave?.empLeaveListData?.rows?.length > 0
                     &&
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" className="pt12px">
                        <Grid item>
                            <ELinkHRDAshMUI disabled={empleave?.empLeaveListData?.rows?.length > 0 ? false : true}
                                onClick={() => navigate('/attendance/leave-log')} //Removed-> { state: { name: `${base64.encode(auth?.authData?.fullname)}` } } due to no need of thi in updated leave log design
                                className={'font-size-14px bold-600 cursor-pointer'}>
                                Show All
                            </ELinkHRDAshMUI>
                        </Grid>
                    </Grid>}
                </Grid>

            {/* </Grid> */}
        </Grid>
    );
}

export default React.memo(LeavesDashboard)
