import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useSelector } from "react-redux";
import { LEAVE_HISTORY_TAB, LEAVE_REQUEST_TAB, LEAVE_STATUS_NON_PENDING_OPTIONS, LEAVE_STATUS_PENDING_OPTIONS, MY_LEAVE_TAB, maxDateWithoutTenure } from '../../constants'
import { DOWNLOAD_PDF, DOWNLOAD_CSV, LEAVE_LOG_DOWNLOAD_URL } from '../../constants'
import FilePDF from '@iconify/icons-mdi/file-pdf-box';
import FileCSV from '@iconify/icons-mdi/file-excel';
import { EInputAdornment, ETextFieldSearch } from '../../BaseComponents/ETextField';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import EMenuPopover, { EMenuIcon, EMenuItem } from "../../BaseComponents/EMenuPopover";
import { DownLoadFile } from '../../action/AdminAction';
import { EIcon, EIconInputAdornment } from '../../BaseComponents/EIcon';
import { EIconButton } from '../../BaseComponents/EButtons';
import { ETooltip } from '../../BaseComponents/ETooltip';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import CancelIcon from '@iconify/icons-material-symbols/cancel-outline-rounded';
import SortRounded from '@iconify/icons-material-symbols/sort-rounded';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { useMemo } from 'react';

/**
 * INFO: This component is leave log page which is also reused in view talent( talent_id= talent's id) and my profile (isMyProfile= true & talent_id= talent's id). 
 * STATUS INFO: 1=> PENDING, 2=> PARTIALLY, 3=> APPROVED, 4 => REJECTED
 * 
 * @param {state} LeaveSearch: This is an object that contains the search data.
 * @param {setting state function} setLeaveSearch : This is the function to set the searched data value to LeaveSearch.
//  * @param {boolean} isFromOtherPage :  specifying if this component is called to page other than leave log( like my profile or view talent).
 * @param {string} childTab :  specifying the tab value (my tab, request or history)
 * @param {object} leaveSettings :  we will get leave setting from setting context in here.
 **/

function LeaveSearchComponent(props) {
    const { LeaveSearch, setLeaveSearch, childTab, leaveSettings } = props //no use of isFromOtherPage as of now
    const [yearMonthValue, setYearMonthValue] = useState(null);
    const auth = useSelector(state => state.authReducer);
    const company = useSelector(state => state.CompanyReducer);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const employeeListData = useSelector(state => state.EmployeeReducer);

    // minimum date for date filter
    const minDAteForDateFilter = company?.companyData?.created_at ? company?.companyData?.created_at : null;

    const LeaveStatusSearchOptions = useMemo(() => getLeaveOptions(childTab), [childTab]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const onKeyDown = (e) => {
        e.preventDefault();
    };

    const open = Boolean(anchorEl);

    //INFO: This function will handle downloading functionality
    const DownloadReport = (value) => {
        let url = LEAVE_LOG_DOWNLOAD_URL;
        let searchData = LeaveSearch;
        let file_name = 'Leave_log'
        if (value != null) { url += `?download_format=${value}`; }

        if (value != null) { url += `&tab=${childTab}`; }

        if (searchData?.approval_status) { url += `&approval_status=${searchData?.approval_status}`; }


        if (searchData?.employee_id) { url += `&employee_id=${searchData?.employee_id}`; }

        if (searchData?.yearMonth != null) {
            file_name += "_" + moment(yearMonthValue).format("MMM-YYYY");
            url += `&year_month=${searchData?.yearMonth}`;
        }
        if (searchData?.sortBy != null) { url += `&sort=${searchData?.sortBy}`; }
        DownLoadFile(auth.authtoken, url, file_name)
        setAnchorEl(null);
    }


    return (
        <>
            <Grid container className={'mb-16px'}>
                <Grid item xs={10} sm={10} lg={10.5} md={10.5} xl={10.5} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={5} sm={4} md={3.5} lg={3} xl={3}>
                            {/* Date search */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <EDatePicker
                                    openTo="month"
                                    views={['month', 'year']}
                                    inputFormat="MMM yyyy"
                                    value={yearMonthValue}
                                    minDate={minDAteForDateFilter}
                                    maxDate={leaveSettings?.leave_to_month ? new Date(leaveSettings?.leave_to_month) : maxDateWithoutTenure}
                                    onChange={(newValue) => {
                                        setLeaveSearch({ ...LeaveSearch, yearMonth: newValue && newValue != 'Invalid Date' ? moment(newValue).format('YYYYMM') : null })
                                        setYearMonthValue(newValue && newValue != 'Invalid Date' ? newValue : null)
                                    }}
                                    renderInput={(params) =>
                                        <div className='date-picker-div'>{/*this div contains text field and clear icon*/}
                                            <ETextFieldSearch
                                                size="small"
                                                fullWidth
                                                {...params}
                                                onKeyDown={onKeyDown}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: 'Select Month'
                                                }}
                                            />
                                            {yearMonthValue &&
                                                <EInputAdornment
                                                    position='end'
                                                    onClick={() => {
                                                        setLeaveSearch({ ...LeaveSearch, yearMonth: null })
                                                        setYearMonthValue(null)
                                                    }}
                                                    className='clear-icon'
                                                >
                                                    <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                                                </EInputAdornment>
                                            }
                                        </div>
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/* // show/hide according to the tabs in leave log */}
                        {(childTab ? childTab != MY_LEAVE_TAB : true) &&
                            <Grid item xs={5} sm={5} md={3.5} lg={3} xl={3}>
                                <EAutocomplete
                                    name="name"
                                    fullWidth
                                    value={LeaveSearch?.talentFilterObject ? LeaveSearch?.talentFilterObject : null}
                                    forcePopupIcon={LeaveSearch?.employee_id ? false : true}
                                    options={employeeListData?.allEmployeeData?.rows && employeeListData?.allEmployeeData?.rows?.length > 0 ? employeeListData?.allEmployeeData?.rows : []}
                                    onChange={(e, value) => {
                                        setLeaveSearch({ ...LeaveSearch, employee_id: value ? value?.id : '', talentFilterObject: value ? value : null })
                                    }}
                                    renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
                                        <Box component="li" {...props} key={option.id}>
                                            {option.label}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <ETextFieldSearch
                                            {...params}
                                            placeholder="Select Name"
                                            name="name"
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>
                        }
                        
                        {/* // show/hide according to the tabs in leave log */}
                        <Grid item xs={5} sm={5} md={3.5} lg={3} xl={3}>
                            {childTab != LEAVE_REQUEST_TAB &&
                                <EAutocomplete
                                    name="name"
                                    fullWidth
                                    value={LeaveSearch?.statusFilterObject ? LeaveSearch?.statusFilterObject : null}
                                    forcePopupIcon={LeaveSearch?.statusFilterObject ? false : true}
                                    options={LeaveStatusSearchOptions}
                                    onChange={(e, value) => {
                                        setLeaveSearch({ ...LeaveSearch, approval_status: value ? value?.id : '', statusFilterObject: value ? value : null })
                                    }}
                                    renderInput={(params) => (
                                        <ETextFieldSearch
                                            {...params}
                                            placeholder="Select Status"
                                            name="name"
                                            size="small"
                                        />
                                    )}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} sm={2} md={1.5} lg={1.5} xl={1.5} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <ETooltip title={'Download'} arrow>
                            <EIconButton size={"large"} onClick={handleClick} className='pr-16px'>
                                <EIcon icon={FileDownloadIcon} className='height-width-22px' />
                            </EIconButton>
                        </ETooltip>
                        <ETooltip title={'Sort by date'} arrow>
                            <EIconButton className='pl0'
                                onClick={() => {
                                    setLeaveSearch({ ...LeaveSearch, sortBy: LeaveSearch.sortBy == null ? 'ASC' : LeaveSearch.sortBy == 'ASC' ? 'DESC' : 'ASC' })
                                }}
                            >
                                <EIcon icon={SortRounded} className='height-width-22px' />
                            </EIconButton>
                        </ETooltip>
                    </Grid>
                </Grid>
            </Grid>
            <EMenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                sx={{ width: 180, top: 40 }}
            >
                <EMenuItem
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    onClick={() => DownloadReport(DOWNLOAD_PDF)}
                >
                    <EMenuIcon icon={FilePDF} />  PDF
                </EMenuItem>
                <EMenuItem
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    onClick={() => DownloadReport(DOWNLOAD_CSV)}
                >
                    <EMenuIcon icon={FileCSV} /> CSV
                </EMenuItem>
            </EMenuPopover>
        </>
    );
}

export default React.memo(LeaveSearchComponent);

// returns the options for leave status filter according to the tab selected
// if MY_LEAVE_TAB: all status options will be there in the dropdown
// if LEAVE_HISTORY_TAB: only approved & rejected status will show
const getLeaveOptions = (childTab) => {

    const LeaveStatusSearchOptions = childTab == MY_LEAVE_TAB ? [...LEAVE_STATUS_PENDING_OPTIONS, ...LEAVE_STATUS_NON_PENDING_OPTIONS] :
    childTab == LEAVE_HISTORY_TAB ? LEAVE_STATUS_NON_PENDING_OPTIONS : [];

    return LeaveStatusSearchOptions;
}