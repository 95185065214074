import { Grid, IconButton, Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import { EBox } from "../../BaseComponents/EBox";
import EMenuPopover, { EMenuItem } from "../../BaseComponents/EMenuPopover";
import { ETooltip } from '../../BaseComponents/ETooltip';
import { DownLoadFile } from "../../action/AdminAction";
import { DOWNLOAD_CSV, DOWNLOAD_PDF, FREELANCER_LIST_DOWNLOAD_URL } from "../../constants";
import { ETextFieldSearch } from "../../BaseComponents/ETextField";
import { EIcon } from "../../BaseComponents/EIcon";
//icons
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import viewList from '@iconify/icons-material-symbols/view-list-rounded';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import viewGrid from '@iconify/icons-material-symbols/grid-view-outline-rounded';
import SortByAlphaIcon from '@iconify/icons-material-symbols/sort-by-alpha';
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import EHidden from "../../BaseComponents/EHidden";

const FreelancerSearchBar = (props) => {
    const { setListToggle, listToggle, getEmployeeData, searchParam, setSearchParam, auth, freelancer } = props;
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const setSearch = (key, value) => {
        const sParams = { ...searchParam, [key]: value };
        setSearchParam(sParams);
        getEmployeeData(sParams)
    }

    const FreelancerDownload = (value) => {
        let url = '';
        let searchData = {};
        let file_name = '';
        url += FREELANCER_LIST_DOWNLOAD_URL;
        searchData = searchParam;
        file_name += 'Freelancer_list'
        if (value != null) { url += `?download_format=${value}`; }
        if (searchData?.name && searchData?.name != null) { url += `&freelancee_name=${searchData?.name}`; }
        if (searchData?.sortBy && searchData?.sortBy != null) { url += `&sort=${searchData?.sortBy}`; }
        DownLoadFile(auth.authtoken, url, file_name)
        setAnchorEl(null);
    }

    return (
        <>
            <Grid container >
                <Grid item xs={11} sm={11} lg={9} md={9} xl={10} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item sm={4} md={4} lg={4} xl={3}>
                            <EAutocomplete
                                name="name"
                                fullWidth
                                forcePopupIcon={searchParam?.name ? false : true}
                                options={
                                    freelancer?.allFreelancerData?.rows && freelancer?.allFreelancerData?.rows?.length > 0
                                        ? freelancer?.allFreelancerData?.rows
                                        : []
                                }
                                onChange={(e, value) => {
                                    setSearch("name", value?.label ? value?.label : "");
                                }}
                                renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
                                    <Box component="li" {...props} key={option.id+option.label}>
                                        {option.label}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <ETextFieldSearch
                                        {...params}
                                        placeholder="Select Name"
                                        name="name"
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} sm={1} md={3} lg={3} xl={2} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <EBox className="display-flex align-center">
                            <EHidden width="mdDown" >
                                <IconButton onClick={handleClick}>
                                    <ETooltip title={"Download"} arrow>
                                        <EIcon icon={FileDownloadIcon} className='height-width-22px' />
                                    </ETooltip>
                                </IconButton>
                            </EHidden>
                            <IconButton onClick={() => setSearch('sort', !searchParam.sort)}>
                                <ETooltip title={"Sort By Name"} arrow>
                                    <EIcon icon={SortByAlphaIcon} className='height-width-22px' />
                                </ETooltip>
                            </IconButton>

                            <EHidden width="mdDown" >
                                <IconButton onClick={() => setListToggle(true)}>
                                    <ETooltip title={"Card View"} arrow>
                                        <EIcon icon={viewGrid} className='height-width-22px' color={listToggle ? `${theme.palette.primary.main}` : `${theme.palette.action.active}`} />
                                    </ETooltip>
                                </IconButton>

                                <IconButton onClick={() => setListToggle(false)}>
                                    <ETooltip title={"List View"} arrow>
                                        <EIcon icon={viewList} className='height-width-22px' color={listToggle ? `${theme.palette.action.active}` : `${theme.palette.primary.main}`} />
                                    </ETooltip>
                                </IconButton>
                            </EHidden>
                        </EBox>

                        <EMenuPopover
                            open={open}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            sx={{ width: 180, top: 40 }}
                        >
                            <EMenuItem
                                sx={{ typography: 'body2', py: 1, px: 2.5 }}
                                onClick={() => FreelancerDownload(DOWNLOAD_PDF)}
                            >
                                <Box
                                    sx={{ mr: 2, width: 24, height: 24 }}>
                                    <PictureAsPdfIcon />
                                </Box>
                                PDF
                            </EMenuItem>
                            <EMenuItem
                                sx={{ typography: 'body2', py: 1, px: 2.5 }}
                                onClick={() => FreelancerDownload(DOWNLOAD_CSV)}
                            >
                                <Box
                                    sx={{ mr: 2, width: 24, height: 24 }} >
                                    <InsertDriveFileIcon />
                                </Box>
                                CSV
                            </EMenuItem>
                        </EMenuPopover>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
};

export default FreelancerSearchBar;