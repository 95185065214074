import { Avatar, Badge, Box, Button, Grid, List } from "@mui/material";
import { EIcon, EIconInputAdornment } from "../../BaseComponents/EIcon";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationList } from "../../action/NotificationAction";
import { EIconButton } from "../../BaseComponents/EButtons";
import EMenuPopover from "../../BaseComponents/EMenuPopover";
import BellIcon from "@iconify/icons-material-symbols/notifications-outline-rounded";
import AdditionalSettingIcon from "@iconify/icons-material-symbols/tune-rounded";
import { EDivider } from "../../BaseComponents/EDivider";
import { useNavigate } from "react-router-dom";
import NotificationItem from "../../PageComponents/Notification/NotificationItem";
import { LoadingGrid } from "../../BaseComponents/EGrid";
import { ETypography } from "../../BaseComponents/ETypography";
import { ETooltip } from "../../BaseComponents/ETooltip";

export default function NotificationPopover(props) {
    const anchorRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const data = useSelector((state) => state.NotificationReducer);
    const notificationsSettings = useSelector((state) => state.UserSettingReducer);
    const totalUnRead = notificationsSettings?.globalUserSettingData?.notification_is_checked?
     (data?.notificationListData?.unreadCount ? data?.notificationListData?.unreadCount : 0):0;

    const { auth } = props;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (auth?.authtoken && open) {
            dispatch(NotificationList(auth.authtoken));
        }
    }, [open]);

    return (
        <>
        <ETooltip arrow title={notificationsSettings?.globalUserSettingData?.notification_is_checked?"Notifications":"Notifications Disabled"}>
            <EIconButton
                // disabled={!notificationsSettings?.globalUserSettingData?.notification_is_checked} commented as per @Dharam Sir
                ref={anchorRef}
                size="large"
                color={open ? 'primary' : 'default'}
                onClick={handleOpen}
            >
                <Badge badgeContent={totalUnRead} color="error" >
                    <EIcon icon={BellIcon} width={34530} height={40} className="p0" />
                </Badge>
            </EIconButton>
            </ETooltip>

            <EMenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: { sm: 398, md: 419 } }}
                // popoverClassName='borderRadius-4px arrow-notification-popup'
            >
                <Box sx={{ display: 'flex', alignItems: 'center', pt: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <span className="font-size-24px bold-600">Notifications</span>
                    </Box>
                    <EIconButton color="primary" onClick={() => { setOpen(false); navigate("/notification-setting") }}>
                        <EIcon icon={AdditionalSettingIcon} width={20} height={20} />
                    </EIconButton>
                </Box>

                <Box className="maxheight-80per overflow-auto" >{data?.notificationListLoading ?
                    <LoadingGrid isPage={true} />
                    :
                    <List>
                        {data?.notificationListData?.count > 0 ?
                            data?.notificationListData?.rows?.map((data, index) => (
                                <div key={index} >
                                    <NotificationItem isPopup={true} data={data} handleClose={handleClose} />
                                </div>
                            ))
                            :
                            <Grid container direction={'column'} align='center' className="align-center pt40px pb50px">
                                <Avatar className="height-width-80px bg2-bgcolor-static ">
                                    <Badge badgeContent={'0'} color="error" sx={{
                                        '& .MuiBadge-badge': { top: '10px', right: '7px' }
                                    }}>
                                        <EIconInputAdornment icon={BellIcon} className="p0 color-text-default-static height-width-40px" />
                                    </Badge>
                                </Avatar>
                                <ETypography className="bold-600 font-size-20px pt-16px">No Notifications</ETypography>
                                <ETypography>Entera has no notifications for you.</ETypography>
                            </Grid>
                        }
                    </List>
                }
                </Box>
                {/* <Divider /> */}
                {!data?.notificationListData?.count > 0 && !data?.notificationListLoading ? '' :
                    <>
                        <EDivider className="borderColor-staticBorder7" />
                        <Box >
                            <Button fullWidth disableRipple to="#" onClick={() => { setOpen(false); navigate("/notification-detail") }} className="bold-400 font-size-12px">{/*component={RouterLink} */}
                                Show More
                            </Button>
                        </Box>
                    </>
                }
            </EMenuPopover>

        </>
    );
}