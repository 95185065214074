
import React, { useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import { ADHAAR_DOC_ID, DRIVING_LICENCE_DOC_ID, FEMALE, FREELANCER_DOC_DELETE_API, MALE, NIL, PAN_DOC_ID, PASSPORT_DOC_ID } from '../../constants';
import editFill from '@iconify/icons-material-symbols/edit-outline-rounded'
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline-rounded'
import FileIcon from '@iconify/icons-material-symbols/file-present-outline-rounded'
import { ECard } from '../../BaseComponents/ECard';
import { ETypography, ETypographyCardHeading } from '../../BaseComponents/ETypography';
import { EDividerCard } from '../../BaseComponents/EDivider';
import { ETable, ETableBody, ETableCell, ETableContainer, ETableRowSmall } from '../../BaseComponents/ETable';
import EScrollbar from '../../BaseComponents/EScrollbar'
import { EIcon } from '../../BaseComponents/EIcon';
import { EIconButton, EButtonProfile, EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons'
import { EAvatarProfile } from '../../BaseComponents/EAvatar';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import EModal from '../../BaseComponents/EModal'
import EListHeadSmall from '../../BaseComponents/ETable';
import moment from 'moment';
import { capitalizeCapitalString } from '../../utils/formatText';
import FreelancerEditModalBasicDetail from './FreelancerEditModalBasicDetail';
import FreelancerEditModalPersonalDetail from './FreelancerEditModalPersonalDetail';
import FreelancerEditModalDocumentDetail from './FreelancerEditModalDocumentDetail';
import FreelancerEditModalBankDetails from './FreelancerEditModalBankDetails';
import { ELinkMUI } from '../../BaseComponents/ELink';
import { useDispatch } from 'react-redux';
import { UpdateFreelancerDocsAction } from '../../action/FreelancerAction';


const getTableHead = (allowAction) => {
    const arrayHead = [
        { id: 'Document Name', label: 'Document Name', alignRight: false },
        { id: 'Document Number', label: 'Document Number', alignRight: false },
        { id: 'File', label: 'File', alignRight: 'center' },
    ]
    if (allowAction) {
        arrayHead.push({ id: 'Action', label: 'Action', alignRight: 'center' })
    }
    return arrayHead
}

function FreelancerEditProfile(props) {
    const { freelancer, freelancerLoading, auth, allowEdit = false } = props;
    const dispatch= useDispatch()
    //INFO: This state is managing all four modals and forms regarding their open/close actions
    const [isEdit, SetEdit] = useState({
        isBasicDetails: false,
        isPersonalDetails: false,
        isDocumentsDetails: { open: false, docType: null,delOpen:false},
        isBankDetails: false,
    });

    const HandleEdit = (varName, action, data = null, childAction=null) => {
        SetEdit(
            {
                isBasicDetails: varName == 'isBasicDetails' ? action : false,
                isPersonalDetails: varName == 'isPersonalDetails' ? action : false,
                isDocumentsDetails: varName == 'isDocumentsDetails' ? { open: childAction==null?action:false, docType: data, delOpen: childAction!=null?action : false }
                : { open: false, docType: null, delOpen:false},
                isBankDetails: varName == 'isBankDetails' ? action : false,
            }
        )
    }

    const getPhotoUrl = (url, gender) => {
        if (url && url != 'undefined') {
            return url;
        }
        else {
            const defaultImage = gender == FEMALE ? '/assets/images/female.png' : gender == MALE ? '/assets/images/male.png' : ' '
            return defaultImage;
        }
    }

    const HandleDelete=(doc_id)=>{
        const data={freelancer_id: freelancer?.id, document_id: doc_id }
        dispatch(UpdateFreelancerDocsAction(auth.authtoken, data, FREELANCER_DOC_DELETE_API))
    }

    const TABLE_HEAD = useMemo(() => getTableHead(allowEdit), [])

    return (
        <>
            <ECard className='card_design_1 border-05px-border6'>
                <Grid container alignItems='align-start' >
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }} >

                        <Grid container display="row" justifyContent="space-between" alignItems='center' >
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>{isEdit?.isBasicDetails ? 'Update Basic Details' : ' Basic Details'}</ETypographyCardHeading>
                            </Grid>
                            {allowEdit && !isEdit?.isBasicDetails ?
                                <Grid item >
                                    <EIconButton onClick={() => HandleEdit('isBasicDetails',true)} sx={{ p: 0, m: 0, textAlign: 'right', color: 'dark.0' }}>
                                        <EIcon icon={editFill} width={23} height={23} />
                                    </EIconButton>
                                </Grid> : ''}

                            <Grid xs={12} lg={12} item>
                                <EDividerCard className='mt-8px borderColor-staticBorder7' />
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isEdit?.isBasicDetails ?
                        <>
                            <Grid item xs={12} sm={6} md={5} lg={4} xl={4} className='mt-24px'>
                                <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0, textAlign: 'center' }}>
                                    <EButtonProfile aria-label="upload picture" component="span" id="ePhoto">
                                        <EAvatarProfile alt={freelancer?.freelancer_name}
                                            src={getPhotoUrl(freelancer?.freelancer_photo, freelancer?.freelancer_gender)}
                                            className='theme-color-border-1px' />
                                    </EButtonProfile>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={7} lg={7} xl={6} className='mt-24px'>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} lg={12} xl={12} >
                                        <ELabelInputMainGrid label={'Name'} isNotForm={true}>
                                            {freelancer?.freelancer_name ? freelancer?.freelancer_name : NIL}
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12} >
                                        <ELabelInputMainGrid label={'Email ID'} isNotForm={true}>
                                            {freelancer?.freelancer_email ? freelancer?.freelancer_email : NIL}
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12} >
                                        <ELabelInputMainGrid label={'Contact Number'} isNotForm={true}>
                                            {freelancer?.freelancer_contact_no ? '+91 ' + freelancer?.freelancer_contact_no : NIL}
                                        </ELabelInputMainGrid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='mt-24px'>
                            <FreelancerEditModalBasicDetail
                                auth={auth}
                                freelancerData={freelancer}
                                closeBasicDetailModal={() => HandleEdit('isBasicDetails', false)}
                                freelancerLoading={freelancerLoading}
                            />
                        </Grid>
                    }

                </Grid>
            </ECard>
            {/* //personal Detail */}
            <ECard className='card_design_1 border-05px-border6' >
                <Grid container spacing={2}  >
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }}>

                        <Grid container display="row" justifyContent="space-between" >
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>{isEdit?.isPersonalDetails ? 'Update Personal Details' : 'Personal Details'}</ETypographyCardHeading>
                            </Grid>
                            {allowEdit && !isEdit?.isPersonalDetails ?
                                <Grid item >
                                    <EIconButton varient='link' onClick={() => HandleEdit('isPersonalDetails', true)} sx={{ p: 0, m: 0, textAlign: 'right', color: 'dark.0' }}>
                                        <EIcon icon={editFill} width={23} height={23} />
                                    </EIconButton>
                                </Grid> : ''}

                            {!freelancer?.freelancer_gender && !freelancer?.freelancer_dob && !freelancer?.address_one ? '' :
                                <Grid xs={12} lg={12} item>
                                    <EDividerCard className='mt-8px borderColor-staticBorder7' />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {isEdit?.isPersonalDetails ?
                        <Grid xs={12} lg={12} item>
                            <FreelancerEditModalPersonalDetail
                                auth={auth}
                                freelancerData={freelancer}
                                closePersonalDetailModal={() => HandleEdit('isPersonalDetails', false)}
                                freelancerLoading={freelancerLoading}
                            />
                        </Grid>
                        :
                        !freelancer?.freelancer_gender && !freelancer?.freelancer_dob && !freelancer?.address_one ? '' :
                            <><Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ELabelInputMainGrid label={'Date of Birth'} isNotForm={true}>
                                    {freelancer?.freelancer_dob ? moment(freelancer?.freelancer_dob).format('DD/MM/YYYY') : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ELabelInputMainGrid label={'Gender'} isNotForm={true}>
                                        {freelancer?.freelancer_gender ? capitalizeCapitalString(freelancer?.freelancer_gender) : NIL}
                                    </ELabelInputMainGrid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ELabelInputMainGrid label={"Marital Status"} isNotForm={true}>
                                        {freelancer?.freelancer_marital_status ? capitalizeCapitalString(freelancer?.freelancer_marital_status) : '-'}
                                    </ELabelInputMainGrid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ELabelInputMainGrid label={"Blood Group"} isNotForm={true}>
                                        {freelancer?.freelancer_blood_group ? freelancer?.freelancer_blood_group : NIL}
                                    </ELabelInputMainGrid>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12} >
                                    <ELabelInputMainGrid label={'Address'} isNotForm={true} alignlabel={'flex-start'} isfullgrid={true}>
                                        {freelancer?.address_one ? freelancer?.address_one : NIL}
                                        {freelancer?.address_two ? ", " + freelancer?.address_two : ''}
                                        {freelancer?.landmark ? ", " + freelancer?.landmark : ''}
                                        {freelancer?.freelancerCity?.city_name ? ", " + freelancer?.freelancerCity?.city_name : ''}
                                        {freelancer?.freelancerState?.state_name ? ", " + freelancer?.freelancerState?.state_name : ''}
                                        {freelancer?.pincode ? ", " + freelancer?.pincode : ''}
                                    </ELabelInputMainGrid>
                                </Grid>
                            </>}
                </Grid>

            </ECard>

            <ECard className='card_design_1 border-05px-border6' >
                <Grid container  >
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }}>

                        <Grid container display="row" justifyContent="space-between" >
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>Documents</ETypographyCardHeading>
                            </Grid>

                            <Grid xs={12} lg={12} item>
                                <EDividerCard className='mt-8px borderColor-staticBorder7' />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} xl={12} className='mt-16px'>
                        <EScrollbar >
                            <ETableContainer >
                                <ETable>
                                    <EListHeadSmall
                                        headLabel={TABLE_HEAD}
                                        rowCount={2}
                                        onRequestSort={() => null}
                                        authvar={'auth'}
                                    />
                                    <ETableBody>
                                        <ETableRowSmall hover>
                                            <ETableCell align="left">PAN Card</ETableCell>
                                            <ETableCell align="left" >
                                            {freelancer?.pan_no ? freelancer?.pan_no : ''}
                                            </ETableCell>
                                            <ETableCell align="center" >
                                                {freelancer?.pan_file_name ?
                                                    <EIconButton >
                                                        <ELinkMUI
                                                            target="_blank"
                                                            href={freelancer?.pan_file_name}
                                                        >
                                                            <EIcon icon={FileIcon} /></ELinkMUI>
                                                    </EIconButton>
                                                    : ''}
                                            </ETableCell>
                                            {allowEdit && TABLE_HEAD.length === 4 &&
                                                <ETableCell align="right" >
                                                    <EIconButton onClick={() => HandleEdit('isDocumentsDetails', true, PAN_DOC_ID)}>
                                                        <EIcon icon={editFill} />
                                                    </EIconButton>
                                                    {freelancer?.pan_no ?
                                                        <EIconButton className='px0' onClick={() => HandleEdit('isDocumentsDetails', true, PAN_DOC_ID, 'Delete')}>
                                                            <EIcon icon={DeleteIcon} className='color-text-error-red' />
                                                        </EIconButton>
                                                        : ''}
                                                </ETableCell>
                                            }

                                        </ETableRowSmall>

                                        <ETableRowSmall hover>
                                            <ETableCell align="left" className='width-200px'>Aadhar Card</ETableCell>
                                            <ETableCell align="left" className='width-200px'>
                                                {freelancer?.aadhar_no ? freelancer?.aadhar_no : ''}
                                            </ETableCell>
                                            <ETableCell align="center" className='width-150px'>
                                                {freelancer?.aadhar_file_name ?
                                                    <EIconButton >
                                                        <ELinkMUI
                                                            target="_blank"
                                                            href={freelancer?.aadhar_file_name}
                                                        >
                                                            <EIcon icon={FileIcon} /></ELinkMUI>
                                                    </EIconButton>
                                                    : ''}

                                            </ETableCell>
                                            {
                                                allowEdit &&
                                                <ETableCell align="right" >
                                                    <EIconButton onClick={() => HandleEdit('isDocumentsDetails', true, ADHAAR_DOC_ID)}>
                                                        <EIcon icon={editFill} />
                                                    </EIconButton>
                                                    {freelancer?.aadhar_no ?
                                                        <EIconButton className='px0' onClick={() => HandleEdit('isDocumentsDetails', true, ADHAAR_DOC_ID, 'Delete')}>
                                                            <EIcon icon={DeleteIcon} className='color-text-error-red' />
                                                        </EIconButton>
                                                        : ''}
                                                </ETableCell>
                                            }
                                        </ETableRowSmall>
                                        <ETableRowSmall hover>
                                            <ETableCell align="left">Passport</ETableCell>
                                            <ETableCell align="left"> {freelancer?.passport_no}</ETableCell>
                                            <ETableCell align="center" >
                                                {freelancer?.passport_file &&
                                                    <EIconButton>
                                                        <ELinkMUI target="_blank"
                                                            href={freelancer?.passport_file}
                                                        >
                                                            <EIcon icon={FileIcon} />
                                                        </ELinkMUI>
                                                    </EIconButton>}

                                            </ETableCell>
                                            { allowEdit && TABLE_HEAD.length === 4 &&
                                                <ETableCell align="right">
                                                    <EIconButton onClick={() => HandleEdit('isDocumentsDetails', true, PASSPORT_DOC_ID)}>
                                                        <EIcon icon={editFill} />
                                                    </EIconButton>
                                                    {freelancer?.passport_file ?
                                                        <EIconButton className='px0' onClick={() => HandleEdit('isDocumentsDetails', true, PASSPORT_DOC_ID, 'Delete')}>
                                                            <EIcon icon={DeleteIcon} className='color-text-error-red' />
                                                        </EIconButton>
                                                        : ''}
                                                </ETableCell>}
                                        </ETableRowSmall>
                                        <ETableRowSmall hover>
                                            <ETableCell align="left">Driving Licence</ETableCell>
                                            <ETableCell align="left"> {freelancer?.driving_lic_no}</ETableCell>
                                            <ETableCell align="center" >
                                                {freelancer?.driving_lic_file &&
                                                    <EIconButton>
                                                        <ELinkMUI target="_blank"
                                                            href={freelancer?.driving_lic_file}
                                                        >
                                                            <EIcon icon={FileIcon} />
                                                        </ELinkMUI>
                                                    </EIconButton>}

                                            </ETableCell>
                                            { allowEdit && TABLE_HEAD.length === 4 &&
                                                <ETableCell align="right" >
                                                    <EIconButton onClick={() => HandleEdit('isDocumentsDetails', true, DRIVING_LICENCE_DOC_ID)}>
                                                        <EIcon icon={editFill} />
                                                    </EIconButton>
                                                    {freelancer?.driving_lic_no ?
                                                    <EIconButton className='px0' onClick={() => HandleEdit('isDocumentsDetails', true, DRIVING_LICENCE_DOC_ID, 'Delete')}>
                                                        <EIcon icon={DeleteIcon} className='color-text-error-red' />
                                                    </EIconButton>
                                                    : ''}
                                                </ETableCell>}
                                        </ETableRowSmall>
                                    </ETableBody>
                                </ETable>
                            </ETableContainer>
                        </EScrollbar>
                    </Grid>
                </Grid>

                {/*INFO: Document Detail Modal */}
                {isEdit?.isDocumentsDetails?.open &&
                    <EModal open={isEdit?.isDocumentsDetails?.open}
                        newMaxWidth={'md'}
                        close={() => HandleEdit('isDocumentsDetails',false)}
                        headervalue={"Update "+(isEdit?.isDocumentsDetails?.docType == PAN_DOC_ID ? " Pan Card" :
                        isEdit?.isDocumentsDetails?.docType == ADHAAR_DOC_ID? 'Aadhar Card': isEdit?.isDocumentsDetails?.docType ==PASSPORT_DOC_ID?'Passport':'Driving License')+' Details'}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} lg={12} xl={12} >
                                {isEdit?.isDocumentsDetails?.docType &&
                                    <FreelancerEditModalDocumentDetail
                                        auth={auth}
                                        freelancerData={freelancer}
                                        closeDocumentDetailModal={()=>{HandleEdit('isDocumentsDetails',false)}}
                                        selectedDoccument={isEdit?.isDocumentsDetails}
                                        freelancerLoading={freelancerLoading}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </EModal>}

                {isEdit?.isDocumentsDetails?.delOpen &&
                    <EModal open={isEdit?.isDocumentsDetails?.delOpen}
                        close={() => HandleEdit('isDocumentsDetails', isEdit?.isEdit?.isDocumentsDetails?.docType, false)}
                        headervalue={"Delete: Confirmation"}>
                        <Grid container>
                            <Grid item>
                                <ETypography variant="h5" className="font-size-18px">
                                    Are you sure you want to delete the
                                    <span className="theme-main-text-color-bold px-6px">
                                        {isEdit?.isDocumentsDetails?.docType == PAN_DOC_ID ? " Pan Card" :
                                            isEdit?.isDocumentsDetails?.docType == ADHAAR_DOC_ID ? 'Aadhar Card' :
                                                isEdit?.isDocumentsDetails?.docType == PASSPORT_DOC_ID ? 'Passport' : 'Driving License'}
                                    </span>
                                    details ?
                                </ETypography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12} className="modal1-buttons-stick-bottom pt-16px">
                                <EButtonOutlined size="large" variant="outlined" onClick={() => { HandleEdit('isDocumentsDetails', isEdit?.isEdit?.isDocumentsDetails?.docType, false) }} >  
                                    No
                                </EButtonOutlined>
                                <ELoadingButton
                                    size="large"
                                    variant="contained"
                                    onClick={() => HandleDelete(isEdit?.isDocumentsDetails?.docType)}
                                    className="button-left-margin"
                                > Yes
                                </ELoadingButton>
                            </Grid>
                        </Grid>
                    </EModal>}
            </ECard>


            <ECard className='card_design_1 border-05px-border6' >
                <Grid container spacing={3}  >
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }}>

                        <Grid container display="row" justifyContent="space-between">
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>{!isEdit?.isBankDetails?'Bank Details':'Update Bank Details'}</ETypographyCardHeading>
                            </Grid>
                            {allowEdit && !isEdit?.isBankDetails?
                            <Grid item >
                                <EIconButton varient='link' onClick={() => HandleEdit('isBankDetails',true)} sx={{ p: 0, m: 0, textAlign: 'right', color: 'dark.0' }}>
                                    <EIcon icon={editFill} width={23} height={23} />
                                </EIconButton>
                            </Grid>
                            :''}
                            <Grid xs={12} lg={12} item>
                                {!freelancer?.bank_name && !freelancer?.ifsc_code && !freelancer?.branch_name && !freelancer?.account_holder_name && !freelancer?.account_no ? '' :
                                    <EDividerCard className='mt-8px borderColor-staticBorder7' />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {isEdit?.isBankDetails ?
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                            <FreelancerEditModalBankDetails
                                auth={auth}
                                freelancerData={freelancer}
                                closeModalEditBankDetail={()=>{HandleEdit('isBankDetails',false)}}
                                freelancerLoading={freelancerLoading}
                            />
                        </Grid>
                        :
                        !freelancer?.bank_name && !freelancer?.ifsc_code && !freelancer?.branch_name && !freelancer?.account_holder_name && !freelancer?.account_no ? '' :
                            <>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ELabelInputMainGrid label={"Bank Name"} isNotForm={true}>
                                        {freelancer?.bank_name ? freelancer?.bank_name : NIL}
                                    </ELabelInputMainGrid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                    <ELabelInputMainGrid label={"IFSC Code"} isNotForm={true}>
                                        {freelancer?.ifsc_code ? freelancer?.ifsc_code : NIL}
                                    </ELabelInputMainGrid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={0}>
                                    <ELabelInputMainGrid label={"Branch Name"} isNotForm={true}>
                                        {freelancer?.branch_name ? freelancer?.branch_name : NIL} 
                                    </ELabelInputMainGrid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={0}>
                                    <ELabelInputMainGrid label={"Cardholder's Name"} isNotForm={true}>
                                        {freelancer?.account_holder_name ? freelancer?.account_holder_name : NIL} 
                                    </ELabelInputMainGrid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={0}>
                                <ELabelInputMainGrid label={"Account Number"} isNotForm={true}>
                                        {freelancer?.account_no ? freelancer?.account_no : NIL} 
                                        </ELabelInputMainGrid>
                                </Grid>
                            </>}
                </Grid>
            </ECard>

        </>
    );
}

export default FreelancerEditProfile