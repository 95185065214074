import axios from "axios";
import { Success, Errors } from "../BaseComponents/EToast";
import { SERVER_URL } from "../constants";
import { CompanyDetail } from "./CompanyAction";

export function BulkTalentUploadAction(token, Data,navigate) {
  const data = new FormData();
  data.append("data", JSON.stringify(Data));
 
  return (dispatch) => {
    axios({
      method: "post",
      url: `${SERVER_URL}/employee/bulk-import`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          });
          navigate('/talent/talent-list')
          dispatch(CompanyDetail(token))
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          });

        }
      });
  };
}
