import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider, Field } from 'formik';
import { EFieldLabel } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid } from "@mui/material";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { RequestDetailValidation } from "../../utils/validation";
import { useDispatch } from "react-redux";
import { RequestDetailFormAction } from "../../action/PaymentAction";
import { PAYMENT_CATEGORY, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_REIMBURSEMENT, ROLE_ADMIN, ROLE_HR, SUPER_ADMIN } from "../../constants";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from "moment";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { getPaymentCategory } from "../../utils/getPaymentCategory";

const PaymentRequestModal = (props) => {
    const { setModalState, auth, setting, getPaymentFilterData, employeeList, fromPage } = props;

    const PAYMENT_CATEGORY_ROLE = [ROLE_HR, SUPER_ADMIN, ROLE_ADMIN].includes(auth?.authData?.role) ? PAYMENT_CATEGORY : PAYMENT_CATEGORY?.filter((item) => item?.id != PAYMENT_CATEGORY_OTHER);
    const paymentSettingData = setting?.paymentSettingData?.companySetting;
    // Setting the payment category as per settings
    const PAYMENT_CATEGORY_NEW = paymentSettingData?.enable_reimbursement ? PAYMENT_CATEGORY : PAYMENT_CATEGORY?.filter((item) => item.id !== PAYMENT_CATEGORY_REIMBURSEMENT)
    const ENABLE_REMBURSEMENT = paymentSettingData?.enable_reimbursement;
    const ATTACHMENT_REQUIRED = paymentSettingData?.reimbursement_attachment_required;
    const ENABLE_REIMBURSEMENT_REASON = paymentSettingData?.enable_reimbursement_reason;
    const REIMBURSEMENT_REASON_LIST = paymentSettingData?.reimbursementReason.filter((item) => item.is_active)
    const ENABLE_ADVANCE_SALARY_SETTING = paymentSettingData?.enable_advance_salary;


    // console.log(PAYMENT_CATEGORY_NEW, "===============");

    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            is_attachment_required: ATTACHMENT_REQUIRED,
            transaction_category: null,
            request_amount: '',
            remark: null,
            reimbursement_bill_date: null,
            reimbursement_attachment: null,
            reimbursement_reason_category:null,
            employee_id: null,
            // other: '',
            // reimbursement_reason: true
            // previous_amount: ''
        },
        validationSchema: RequestDetailValidation(ENABLE_REIMBURSEMENT_REASON),
        onSubmit: (data) => {

            if (data?.transaction_category?.id == PAYMENT_CATEGORY_OTHER) {
                data.employee_id = data?.employee_id?.id;
            } else {
                data.employee_id = auth?.authData?.id
            }
            if (data?.transaction_category?.id == PAYMENT_CATEGORY_REIMBURSEMENT && data.reimbursement_bill_date !== null) {
                data.reimbursement_bill_date = moment(data.reimbursement_bill_date).format('YYYY-MM-DD');
            }
           
            data.transaction_category = data?.transaction_category?.id;
            data = { ...data, created_by: auth?.authData?.id };
            dispatch(RequestDetailFormAction(auth?.authtoken, data, getPaymentFilterData, fromPage ? fromPage : null));
            setModalState({ modalData: null, modalName: null })

        }
    });

    // If other category then remove self employee

    const filteredEmployee = employeeList?.employeeData?.rows.filter(object => {
        return object.id !== auth?.authData?.id;
    });


    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    const AttachmentFileFunction = (e) => {
        if (e.target.files[0]) {
            setFieldValue('reimbursement_attachment', e.target.files[0]);
        }
    };


    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

                <Grid container spacing={2} sx={{ paddingTop: "10px" }}  >

                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                            className="align-center"
                        >
                            <EFieldLabel>Particular</EFieldLabel>
                            <EAutocomplete
                                name="transaction_category"
                                fullWidth
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                {...getFieldProps('transaction_category')}
                                // defaultValue={[{ id: 0, label: 'Advance' }]}
                                // options={setting?.paymentSetting?.enable_reimbursement ? PAYMENT_CATEGORY_NEW : PAYMENT_CATEGORY.filter((item)=>item.id !== PAYMENT_CATEGORY_REIMBURSEMENT ) }
                                // options={PAYMENT_CATEGORY_NEW}  
                                options={
                                    ENABLE_REMBURSEMENT && ENABLE_ADVANCE_SALARY_SETTING ?
                                        getPaymentCategory('ENABLE_BOTH', PAYMENT_CATEGORY_ROLE) :
                                        ENABLE_REMBURSEMENT ?
                                            getPaymentCategory('ENABLE_REIMBURSEMENT', PAYMENT_CATEGORY_ROLE)
                                            : ENABLE_ADVANCE_SALARY_SETTING ?
                                                getPaymentCategory('ENABLE_ADVANCE_SALARY', PAYMENT_CATEGORY_ROLE) : 
                                                getPaymentCategory('DISABLE_BOTH', PAYMENT_CATEGORY_ROLE)

                                }
                                getOptionLabel={(option) => option.label || ''}
                                onChange={(e, value) => {
                                    setFieldValue('transaction_category', value ? value : null);
                                    setFieldValue('reimbursement_reason_category', null);
                                }}
                                renderInput={(params) => (
                                    <ETextField
                                        {...params}
                                        name="transaction_category"
                                        placeholder="Select Particular"
                                        fullWidth
                                        error={Boolean(touched.transaction_category && errors.transaction_category)}
                                        helperText={touched.transaction_category && errors.transaction_category}
                                    />
                                )}
                            />
                        </FieldContainerCustom>
                    </Grid>
                    {formik?.values?.transaction_category?.id == PAYMENT_CATEGORY_REIMBURSEMENT &&
                        <>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                    className="align-center"
                                >
                                    <EFieldLabel>Bill Date</EFieldLabel>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <EDatePicker
                                            name="reimbursement_bill_date"
                                            {...getFieldProps('reimbursement_bill_date')}
                                            inputFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            // minDate={x}
                                            // value={dates?.start}
                                            onChange={(selectedDate) => {
                                                setFieldValue(
                                                    'reimbursement_bill_date',
                                                    selectedDate ? selectedDate : new Date()
                                                );
                                                // setDates({ ...dates, start: selectedDate ? selectedDate : null });
                                            }}
                                            renderInput={(params) => (
                                                <Field
                                                    component={ETextField}
                                                    {...params}
                                                    fullWidth
                                                    name="reimbursement_bill_date"
                                                    error={Boolean(touched.reimbursement_bill_date && errors.reimbursement_bill_date)}
                                                    helperText={touched.reimbursement_bill_date && errors.reimbursement_bill_date}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </FieldContainerCustom>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                    className="align-center"
                                >
                                    <EFieldLabel>{`Attachment ${ATTACHMENT_REQUIRED ? '*' : ''} `}</EFieldLabel>
                                    <ETextField
                                        fullWidth
                                        type="file"
                                        onChange={(e) => {
                                            AttachmentFileFunction(e);
                                        }}
                                        error={Boolean(touched.reimbursement_attachment && errors.reimbursement_attachment)}
                                        helperText={touched.reimbursement_attachment && errors.reimbursement_attachment}
                                    />

                                </FieldContainerCustom>
                                <span className="greyColor4-color font-size-12px ml-33per">* Allowed formats are pdf or jpeg or jpg</span>
                            </Grid>
                        </>
                    }

                    {/* {formik?.values?.transaction_category?.id == PAYMENT_CATEGORY_ADVANCE &&
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={50}
                            >
                                <EFieldLabel>Previous Advance</EFieldLabel>
                                <ETextField
                                    fullWidth
                                    disabled
                                    autoComplete="previous_amount"
                                    {...getFieldProps('previous_amount')}
                                    error={Boolean(touched.previous_amount && errors.previous_amount)}
                                    helperText={touched.previous_amount && errors.previous_amount}
                                />
                            </FieldContainerCustom>
                        </Grid>
                    } */}

                    {formik?.values?.transaction_category?.id == PAYMENT_CATEGORY_OTHER &&

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                className="align-center"
                                labelwidth={50}
                            >
                                <EFieldLabel>Talent Name</EFieldLabel>
                                <EAutocomplete
                                    name="employee_id"
                                    fullWidth
                                    // label="Talent Name"
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    {...getFieldProps('employee_id')}
                                    options={filteredEmployee && filteredEmployee?.length > 0 ? filteredEmployee : []}
                                    getOptionLabel={(option) => option.label || ''}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option.label} ({option.employee_code})
                                        </li>
                                    )}
                                    onChange={(e, value) => {
                                        setFieldValue('employee_id', value ? value : null);
                                    }}
                                    renderInput={(params) => (
                                        <ETextField
                                            {...params}
                                            name="employee_id"
                                            placeholder="Select Talent"
                                            error={Boolean(touched.employee_id && errors.employee_id)}
                                            helperText={touched.employee_id && errors.employee_id}
                                        />
                                    )}
                                />
                            </FieldContainerCustom>
                        </Grid>

                    }


                    {/* <Autocomplete
                name="talent"
                label="Talent Name"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                {...getFieldProps('talent')}
                options={
                  EmployeeList && EmployeeList?.length > 0 ? EmployeeList : []
                }
                getOptionLabel={(option) => option.label || ''}
                onChange={(e, value) => {
                  setFieldValue('talent', value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="talent"
                    label="Talent Name"
                    placeholder="Select"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(touched.talent && errors.talent)}
                    helperText={touched.talent && errors.talent}
                  />
                )}
              /> */}

                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                            className="align-center"
                        >
                            <EFieldLabel>Request Amount</EFieldLabel>
                            <ETextField
                                fullWidth
                                autoComplete="request_amount"
                                {...getFieldProps('request_amount')}
                                error={Boolean(touched.request_amount && errors.request_amount)}
                                helperText={touched.request_amount && errors.request_amount}
                                placeholder='Request Amount'
                            />
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* Check setting reimbursement reseon is enabled 
                        and from particular dropdown category is reimbursment  */}
                        {
                            ENABLE_REIMBURSEMENT_REASON && 
                            formik?.values?.transaction_category?.id == PAYMENT_CATEGORY_REIMBURSEMENT && (
                                <FieldContainerCustom
                                    labelwidth={50}
                                    className="align-center"
                                >
                                    <EFieldLabel>Reason </EFieldLabel>
                                    <EAutocomplete
                                        name="reimbursement_reason_category"
                                        {...getFieldProps('reimbursement_reason_category')}
                                        fullWidth
                                        options={REIMBURSEMENT_REASON_LIST ? REIMBURSEMENT_REASON_LIST : []}
                                        getOptionLabel={(option) => option['reimbursement_reason'] || option}
                                        isOptionEqualToValue={(option, value) => option.reimbursement_reason == value.reimbursement_reason}
                                        onChange={(e, value) => {
                                            // setFieldValue("remark", value && formik.values.remark != "Other" ? value.reimbursement_reason : null);
                                            setFieldValue("reimbursement_reason_category", value);
                                            setFieldValue("remark", !value.is_other ? value.reimbursement_reason : null);
                                        }}
                                        renderInput={(params) => (
                                            <ETextField
                                                {...params}
                                                name="reimbursement_reason_category"
                                                placeholder="Select Reason"
                                                fullWidth
                                                error={Boolean(touched.reimbursement_reason_category && errors.reimbursement_reason_category)}
                                                helperText={touched.reimbursement_reason_category && errors.reimbursement_reason_category}
                                            />
                                        )}
                                    />
                                </FieldContainerCustom>
                            )}
                    </Grid>

                    {/* 
                    Below are the points when the reason field will display
                    -If The particular is selected by other|advance|default
                    -IF reimbursement is enable and reason is selected as 'Other' show the textfield */}
                    <Grid item className="pt-0" xs={12} sm={12} md={12} lg={12} xl={12}>
                        {(  
                            //if selected particular is remburment with reason is other then 
                            ((!formik.values.transaction_category?.id) || (!ENABLE_REIMBURSEMENT_REASON) ||
                            [PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_ADVANCE].includes(formik.values.transaction_category?.id) ||
                            (formik.values.reimbursement_reason_category && formik.values.reimbursement_reason_category?.is_other)) &&
                            <FieldContainerCustom
                                    labelwidth={50}
                                    hidecolon={(formik.values?.reimbursement_reason_category && formik.values?.reimbursement_reason_category?.is_other) ? 'true' : 'false'}
                                >
                                    <EFieldLabel>{(formik.values?.reimbursement_reason_category && formik.values?.reimbursement_reason_category?.is_other) ? '' : 'Reason'}</EFieldLabel>
                                    <ETextField
                                        fullWidth
                                        multiline
                                        placeholder="Reason"
                                        rows={3}
                                        className={((formik.values?.reimbursement_reason_category && formik.values?.reimbursement_reason_category?.is_other)) && 'mt-16px'}
                                        // {...getFieldProps('remark')}
                                        onChange={(e) => {
                                            setFieldValue("remark", e.target.value)
                                        }}
                                        // inputProps={{ maxLength: REMARK_LIMIT }}
                                        error={Boolean(touched.remark && errors.remark)}
                                        helperText={touched.remark && errors.remark}
                                    />
                                </FieldContainerCustom>)}
                      
                    </Grid>
                    {/* {`${formik?.values?.remark?.length}/${REMARK_LIMIT}`} */}





                </Grid>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end' className="pb-24px">
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalName: null })}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton
                        size="large" variant="outlined" type="submit" >Submit</ELoadingButton>
                </Stack>
            </Form>
        </FormikProvider >
    );
};

export default PaymentRequestModal