import { Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButtonOutlined } from "../../BaseComponents/EButtons";
import { EContainer } from "../../BaseComponents/EContainer";
import { EDivider } from "../../BaseComponents/EDivider";
import Page from "../../BaseComponents/EPage";
import { ETab, ETabContext, ETabList, ETabPanel } from "../../BaseComponents/ETabs";
import { ETypographyPageHeading } from "../../BaseComponents/ETypography";
import PaymentLogFreelancer from "../../PageComponents/Payment/PaymentLogFreelancer";
import PaymentLogTalent from "../../PageComponents/Payment/PaymentLogTalent";
// import SalaryDue from "../../PageComponents/Payment/SalaryDue";


const PaymentLog = (props) => {
    const { auth } = props;

    const navigate = useNavigate();

    // const location = useLocation();

    const [tabvalue, setTabValue] = useState("TALENT");

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Page title={'Payment Logs'}>
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Payment Logs'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Payment', href: '' },
                            { name: 'Payment Logs' }
                        ]}
                    />
                </Grid>
                <Grid item>
                    <EButtonOutlined
                        variant="outlined"
                        onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                </Grid>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
            >
                <Grid item>
                    <ETypographyPageHeading variant='h5'>
                        Payment Log
                    </ETypographyPageHeading>
                </Grid>
            </Grid>

            <EDivider className='margin-top-05' />
            {/* <EContainer maxWidth='lg' > */}
            <Grid>
                <ETabContext value={tabvalue}>
                    <EContainer maxWidth='lg' >
                        <ETabList onChange={handleChange} alignitems={'space-evenly'} allowScrollButtonsMobile variant="scrollable">
                            <ETab label="Talents" value="TALENT" />
                            <ETab label="Freelancers" value="FREELANCER" />
                        </ETabList>
                    </EContainer>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className="pt-16px"
                    >
                        <Grid item xs={12} sm={12} md={12} >
                            <ETabPanel value="TALENT" sx={{ padding: '0px' }}>
                                <Grid container spacing={3} px={2}>
                                    <Grid item xs={12} lg={12}>
                                        <PaymentLogTalent auth={auth} tabvalue={'talent_log'} />
                                    </Grid>
                                </Grid>
                            </ETabPanel>
                            <ETabPanel value="FREELANCER" sx={{ padding: '0px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                     <PaymentLogFreelancer auth={auth} tabvalue={'freelancer_log'} />
                                    </Grid>
                                </Grid>
                            </ETabPanel>

                        </Grid>
                    </Grid>
                </ETabContext>
            </Grid>
            {/* </EContainer> */}

        </Page>
    );
};

export default PaymentLog;