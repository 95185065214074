import React, { useState, useEffect } from 'react';
import { Grid, useTheme } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep'

import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import EditFill from "@iconify/icons-material-symbols/edit-outline-rounded";
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline-rounded'
import RemoveIcon from '@iconify/icons-material-symbols/cancel-outline-rounded'

import EModal from '../../../BaseComponents/EModal';
import { ETextFieldSmall } from '../../../BaseComponents/ETextField';
import { EDataGrid } from '../../../BaseComponents';
import { DAY_OPTIONS, WEEK_OPTIONS } from '../../../constants';
import { ETypography } from '../../../BaseComponents/ETypography';
import { EIcon, EIconStaticColor } from '../../../BaseComponents/EIcon';
import { EButtonOutlined, EIconButton, ELoadingButton } from '../../../BaseComponents/EButtons';
import { ELabelInputMainGrid } from '../../../BaseComponents/EGrid';
import { EAutocomplete } from '../../../BaseComponents/EAutocomplete';
import { WeekAndDayDropDown } from './WeekAndDayDropDown';


const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const initialDeleteModalData = {
  open: false,
  id: "",
  name: "",
  week_no: "",
  day_name: "",
}


export const WeeklyOffSettings = ({ formik, disableFields }) => {

  const { values, errors, touched, getFieldProps, setFieldValue } = formik
  const { weekly_off_object, default_weekly_off_day, tempWeeklyOff = [], is_default_setting } = values || {}

  const theme = useTheme()

  const [deleteModalData, setDeleteModalData] = useState(initialDeleteModalData)

  const { open, id, name, week_no, day_name } = deleteModalData

  const [availableDays, setAvailableDays] = useState(DAY_OPTIONS.filter(day => day.value !== default_weekly_off_day))
  const [availableWeeks, setAvailableWeeks] = useState(WEEK_OPTIONS)
  const [allowAdd, setAllowAdd] = useState(true)

  const handleAdd = () => {
    const tempWeeklyOff = [...values?.tempWeeklyOff || []]
    tempWeeklyOff.push({
      new: true,
      addMode: true,
      id: Date.now(),
      day_name: "",
      is_check: 1,
      week_no: "",
    })
    setFieldValue("tempWeeklyOff", tempWeeklyOff)
    updateAvailableDays()
    updateAvailableWeeks()
    setAllowAdd(false)
  }

  const handleEdit = (index) => {
    const tempWeeklyOff = [...values?.tempWeeklyOff || []]
    tempWeeklyOff[index].editMode = true
    // setting the old_day_name and old_week_no to uncheck previous week and day data
    tempWeeklyOff[index].old_day_name = tempWeeklyOff[index].day_name
    tempWeeklyOff[index].old_week_no = tempWeeklyOff[index].week_no
    setFieldValue("tempWeeklyOff", tempWeeklyOff)
    updateAvailableDays()
    updateAvailableWeeks()
    setAllowAdd(false)
  }

  const handleDelete = (data) => {
    setDeleteModalData({
      open: true,
      id: data?.id,
      week_no: data?.week_no,
      day_name: data?.day_name,
      name: `Week ${data?.week_no} - ${data?.day_name}`,
    })
  }

  /**
   * This function is used to confirm the deletion of a weekly off and update the state.
   */
  const confirmWeeklyOffDelete = () => {
    const _tempWeeklyOff = [...values?.tempWeeklyOff] || []
    const deletingWeeklyOffIndex = _tempWeeklyOff.findIndex((weeklyOff) => weeklyOff.id === id)

    // Remove the weekly off object from tempWeeklyOff array
    _tempWeeklyOff.splice(deletingWeeklyOffIndex, 1)

    // Clone the weekly_off_object and update the day array to reflect the deleted weekly off
    let _weekly_off_object = cloneDeep(weekly_off_object)
    let dayArray = _weekly_off_object[week_no]
    const index = dayArray.findIndex((weeklyOff) => weeklyOff.day_name === day_name)
    dayArray[index].is_check = 0
    _weekly_off_object[week_no] = dayArray

    // Update the state with the new values
    setFieldValue("weekly_off_object", _weekly_off_object)
    setFieldValue("tempWeeklyOff", _tempWeeklyOff)
    handleClose()
  }

  /**
   * This function is used to handle the closing of the delete modal and reset the deleteModalData state.
   */
  const handleClose = () => {
    setDeleteModalData(initialDeleteModalData)
  }

  /**
   * Removes a weekly off from the temporary weekly off array or disables edit mode if in edit mode.
   * @param {Object} obj - Object containing the ID of the weekly off to be removed and a boolean value indicating whether the weekly off is in edit mode or not.
   */
  const removeWeeklyOff = ({ id, editMode }) => {
    const _tempWeeklyOff = [...values?.tempWeeklyOff] || []; // make a copy of tempWeeklyOff array
    const deletingWeeklyOffIndex = _tempWeeklyOff.findIndex((weeklyOff) => weeklyOff.id === id); // find the index of weekly off to be removed
    if (editMode) {
      _tempWeeklyOff[deletingWeeklyOffIndex].editMode = false; // disable edit mode if in edit mode
      _tempWeeklyOff[deletingWeeklyOffIndex].day_name = _tempWeeklyOff[deletingWeeklyOffIndex].old_day_name; // disable edit mode if in edit mode
      _tempWeeklyOff[deletingWeeklyOffIndex].week_no = _tempWeeklyOff[deletingWeeklyOffIndex].old_week_no; // disable edit mode if in edit mode
      delete _tempWeeklyOff[deletingWeeklyOffIndex].old_day_name
      delete _tempWeeklyOff[deletingWeeklyOffIndex].old_week_no
      updateWeeklyOffData(_tempWeeklyOff[deletingWeeklyOffIndex], false);
    } else {
      _tempWeeklyOff.splice(deletingWeeklyOffIndex, 1); // remove the weekly off from the array if not in edit mode
    }
    setFieldValue("tempWeeklyOff", _tempWeeklyOff); // update the state with the updated temporary weekly off array
    setAllowAdd(true)
  }

  useEffect(() => {
    updateAvailableDays()
    updateAvailableWeeks()
    // eslint-disable-next-line
  }, [])

  /**
   * This function updates available days based on weekly off object and _default_weekly_off_day
   * if _default_weekly_off_day is null then _default_weekly_off_day = default_weekly_off_day
   */
  const updateAvailableDays = (_default_weekly_off_day = default_weekly_off_day) => {
    // Count the number of occurrences of each day in tempWeeklyOff array
    const count = [...tempWeeklyOff].filter(week => week.day_name !== '').reduce((acc, { day_name }) => {
      acc[day_name] = (acc[day_name] ?? 0) + 1; // increment count for each occurrence of a day
      return acc;
    }, {})

    // Filter DAY_OPTIONS array to get available days
    const days = DAY_OPTIONS.filter(({ value }) => {
      // Allow the day if it's not the default weekly off day and count is less than 5
      const allow = !(count[value] >= 5 || value === _default_weekly_off_day);
      return allow;
    })
    setAvailableDays(days); // set available days state
  }

  /**
   * This function updates available weeks based on weekly off object and selected day name
   * @param {string} [dayName] - Selected day name
   */
  const updateAvailableWeeks = (dayName) => {
    const _weekly_off_object = cloneDeep(values?.weekly_off_object); // clone weekly off object to avoid modifying original
    // Filter WEEK_OPTIONS array to get available weeks
    const weeks = WEEK_OPTIONS.filter(({ value }) => {
      // Count the number of days selected for the week
      const daysSelected = _weekly_off_object[value]?.filter(({ is_check }) => is_check === 1)?.length ?? 0;
      // Allow the week if days selected is less than 7 and the selected day is not already in the week
      return daysSelected < 7 && !_weekly_off_object[value]?.some(({ day_name, is_check }) => day_name === dayName && is_check === 1);
    });
    setAvailableWeeks(weeks); // set available weeks state
  }

  /**
   * Handles dropdown change for weekly_no and day_name selection.
   * @param {string} id - Id of the dropdown.
   * @param {string} field - Field name of the dropdown.
   * @param {object} _value - Selected value from the dropdown.
   * @param {Boolean} editMode - true when updating the old values otherwise false.
   */
  const handleDropDownChange = (id, field, _value, editMode = false) => {
    let value = _value?.value;
    const _tempWeeklyOff = [...values?.tempWeeklyOff] || [];

    // Find index of the weekly off day object being edited
    const weeklyOffIndex = _tempWeeklyOff.findIndex((weeklyOff) => weeklyOff.id === id);
    _tempWeeklyOff[weeklyOffIndex][field] = value;

    const day_name = _tempWeeklyOff[weeklyOffIndex]?.day_name || "";
    const week_no = _tempWeeklyOff[weeklyOffIndex]?.week_no || null;
    if (editMode) {
      updateWeeklyOffData(_tempWeeklyOff[weeklyOffIndex], editMode);
    }
    // Update the weekly off data if both week number and day name are selected
    if (day_name && week_no) {
      _tempWeeklyOff[weeklyOffIndex].addMode = false;
      _tempWeeklyOff[weeklyOffIndex].editMode = false;
      updateWeeklyOffData(_tempWeeklyOff[weeklyOffIndex], editMode);
      setAllowAdd(true)
    }
    setFieldValue("tempWeeklyOff", _tempWeeklyOff);

    // If week number is changed, update available days for the selected week
    if (field === "week_no") {
      updateAvailableDaysOfWeek(value);
    }

    // If day name is changed and week number is not selected, update available weeks for the selected day
    if (field === "day_name" && !week_no) {
      updateAvailableWeeks(value);
    }
  }

  /**
   * Updates available days of the week based on the selected week number.
   * @param {string} weekValue - Selected week number.
   */
  const updateAvailableDaysOfWeek = (weekValue) => {
    if (!weekValue) {
      return updateAvailableDays();
    }
    const _weekly_off_object = cloneDeep(values?.weekly_off_object);
    const weeklyData = _weekly_off_object[weekValue];
    // Filter days that are not already selected or the default weekly off day
    const availableDaysOfWeek = weeklyData
      ?.filter((i) => i?.is_check === 0 && i?.day_name !== values?.default_weekly_off_day)
      ?.map((i) => ({ label: i?.day_name, value: i?.day_name }));

    setAvailableDays(availableDaysOfWeek);
  }

  /**
   * Updates the weekly off data for a specific week and day with the is_check 1.
   * And if editMode is true then set is_check to 0 for the previous week and day
   * @param {object} weeklyOff - The weekly off object containing week number and day name.
   * @param {number} editMode - The editMode of the selected week and day.
   */
  const updateWeeklyOffData = (weeklyOff, editMode = false) => {
    // Clone the weekly off object to avoid modifying the original
    const _weekly_off_object = cloneDeep(values?.weekly_off_object);
    // Get the weekly off data for the selected week
    const weeklyData = _weekly_off_object[weeklyOff.week_no];
    let isSameWeek = false
    if (editMode) {
      isSameWeek = weeklyOff.week_no === weeklyOff.old_week_no
    }
    // Update the is_check property for the selected day and if isSameWeek is true then set is_check to 0 for previous week and day
    const updatedWeeklyData = weeklyData?.map(i => {
      if (i.day_name === weeklyOff.day_name) {
        i.is_check = 1;
      }
      if (isSameWeek && i.day_name === weeklyOff.old_day_name && weeklyOff.day_name  !== weeklyOff.old_day_name) {
        i.is_check = 0;
      }
      return i;
    });
    // Update the is_check property for the previously selected day
    if (editMode && !isSameWeek) {
      // Get the weekly off data for the previously selected week
      const oldWeeklyData = _weekly_off_object[weeklyOff.old_week_no];
      const updatedOldWeeklyData = oldWeeklyData?.map(i => {
        if (i.day_name === weeklyOff.old_day_name) {
          i.is_check = 0;
        }
        return i;
      });
      _weekly_off_object[weeklyOff.old_week_no] = updatedOldWeeklyData;
    }
    // Update the weekly off object with the new data
    _weekly_off_object[weeklyOff.week_no] = updatedWeeklyData;
    // Set the updated weekly off object as the form field value
    setFieldValue("weekly_off_object", _weekly_off_object);
    // Update the available days and weeks based on the updated data
    updateAvailableDays()
    updateAvailableWeeks()
  }

  /**
   * Updates weekly off object to unset for oldDay and set for newDay for every week.
   * @param {string} oldDay - Day of the week to unset as weekly off.
   * @param {string} newDay - Day of the week to set as weekly off.
   */
  const updateEveryWeekByDay = (oldDay, newDay) => {
    let newWeeklyOffObj = {};
    const _weekly_off_object = cloneDeep(values?.weekly_off_object);
    // Loop through each week in the weekly off object
    for (const key in _weekly_off_object) {
      let weeklyOffDays = [];
      // Loop through each day in the week and set/unset the given day as weekly off
      for (const inData of _weekly_off_object[key]) {
        weeklyOffDays.push({
          ...inData,
          is_check: inData.day_name === oldDay ? 0 : inData.day_name === newDay ? 1 : inData.is_check,
        });
      }
      newWeeklyOffObj[key] = weeklyOffDays;
    }
    setFieldValue('weekly_off_object', newWeeklyOffObj); // update the weekly off object in form state
  };

  /**
   * Handles change of default weekly off day.
   * @param {object} e - Change event object.
   * @param {string} value - Value of the selected default weekly off day.
   */
  const handleDefaultWeekOffChange = (e, value) => {
    setFieldValue('default_weekly_off_day', value ? value : null) // update default weekly off day in form state
    updateEveryWeekByDay(default_weekly_off_day, value)
    updateAvailableDays(value)
    updateAvailableWeeks()
  };

  const columns = [
    {
      field: 'sno',
      headerName: 'SNo.',
      flex: 1,
      width: 50,
      minWidth: 50,
      maxWidth: 80,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <>
          {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id) + 1}
        </>
      ),
    },
    {
      field: 'week_no',
      headerName: 'Week',
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <>
          {
            (!params?.row?.editMode && !params?.row?.addMode)
              ?
              `Week ${params?.row?.week_no}`
              :
              <WeekAndDayDropDown
                options={availableWeeks}
                placeholder='Select Week'
                value={params?.row?.week_no ? { value: params?.row?.week_no, label: `Week ${params?.row?.week_no}` } : null}
                forcePopupIcon={params?.row?.week_no && Number(params?.row?.week_no) > 0 ? false : true}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(e, value) => handleDropDownChange(params?.row?.id, 'week_no', value, params?.row?.editMode)}
                error={Boolean(touched.tempWeeklyOff?.[params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id)]?.week_no && errors.tempWeeklyOff?.[params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id)]?.week_no)}
                helperText={touched.tempWeeklyOff?.[params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id)]?.week_no && errors.tempWeeklyOff?.[params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id)]?.week_no}
              />
          }
        </>
      ),
    },
    {
      field: 'day_name',
      headerName: 'Day',
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => (
        <>
          {
            (!params?.row?.editMode && !params?.row?.addMode)
              ?
              `${params?.row?.day_name}`
              :
              <WeekAndDayDropDown
                options={availableDays}
                placeholder='Select Day'
                value={params?.row?.day_name || null}
                forcePopupIcon={params?.row?.day_name && params?.row?.day_name?.length > 0 ? false : true}
                isOptionEqualToValue={(option, value) => option.value === value}
                onChange={(e, value) => handleDropDownChange(params?.row?.id, 'day_name', value, params?.row?.editMode)}
                error={Boolean(touched.tempWeeklyOff?.[params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id)]?.day_name && errors.tempWeeklyOff?.[params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id)]?.day_name)}
                helperText={touched.tempWeeklyOff?.[params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id)]?.day_name && errors.tempWeeklyOff?.[params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id)]?.day_name}
              />
          }
        </>
      ),
    },
    ...(!disableFields 
      ? 
      [
        {
          field: 'action',
          headerName: 'Action',
          flex: 1,
          width: 80,
          minWidth: 80,
          maxWidth: 80,
          sortable: false,
          editable: false,
          type: 'actions',
          renderCell: (params) => {
            if (disableFields) return <></>
            return (
              <>
                {
                  params?.row?.addMode || params?.row?.editMode
                    ?
                    <EIconButton className="ml-16px p0" onClick={() => removeWeeklyOff(params?.row)}>
                      <EIcon icon={RemoveIcon} sx={{ width: 24, height: 24, color: theme.palette.error.red }} />
                    </EIconButton>
                    :
                    <>
                      <EIconButton className="ml-4px p0" onClick={() => handleEdit(params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id))} >
                        <EIcon icon={EditFill} sx={{ width: 24, height: 24 }} />
                      </EIconButton>
                      {
                        !params?.row?.is_default_shift &&
                        <EIconButton className="ml-16px p0" onClick={() => handleDelete(params?.row)}>
                          <EIcon icon={DeleteIcon} sx={{ width: 24, height: 24, color: theme.palette.error.red }} />
                        </EIconButton>
                      }
                    </>
                }
              </>
            );
          }
        },
      ]
      :
      []
    )
  ]

  return (
    <>
      <Grid container columnSpacing={2} >
        <Grid item xs={6} className="align-center">
          <ELabelInputMainGrid
            label={"Week Off"}
            small_text={'(Default)'}
            alignlabel={'align-center'}
            labelPT={'0px'}
          >
            <EAutocomplete
              {...getFieldProps('default_weekly_off_day')}
              value={values?.default_weekly_off_day || null}
              options={DAYS}
              forcePopupIcon={values?.default_weekly_off_day && values?.default_weekly_off_day.length > 0 ? false : true}
              isOptionEqualToValue={(option, value) => option === value}
              className='maxWidth-300px'
              onChange={handleDefaultWeekOffChange}
              disabled={disableFields}
              renderInput={params => (
                <ETextFieldSmall
                  {...params}
                  size='small'
                  name='default_weekly_off_day'
                  placeholder="Select Default Weekly Off"
                  error={Boolean(touched.default_weekly_off_day && errors.default_weekly_off_day)}
                  helperText={touched.default_weekly_off_day && errors.default_weekly_off_day}
                />
              )}
            />
          </ELabelInputMainGrid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <ETypography className={`bold-600 font-size-16px`}>
            Additional Company-wide Leave
          </ETypography>
          <EIconButton
            onClick={handleAdd}
            disabled={disableFields || !allowAdd}
            sx={{
              borderRadius: 0,
              p: 0,
            }}
          >
            <ETypography
              role={"heading"}
              variant="h6"
              sx={{
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'initial',
                fontSize: {
                  lg: '14px !important',
                  xs: '14px !important'
                },
                color: `${(allowAdd && !disableFields) ? theme.palette.primary.main : theme.palette.text.disabled} !important`
              }}
            >
              <EIconStaticColor icon={plusBox} sx={{ width: 15, height: 15, mr: 1.475 }} />
              Add
            </ETypography>
          </EIconButton>
        </Grid>
        <Grid item md={12} sm={12} xs={12} className="align-center" mb={1}>
          <ETypography className='greyColor4-color font-size-14px'>
            The following leaves will be granted to all the talents every upcoming month.
          </ETypography>
        </Grid>

        <Grid item xs={12}>
          <EDataGrid columns={columns} rows={values?.tempWeeklyOff || []} getRowHeight={() => 'auto'} />
        </Grid>
      </Grid>

      {/* Shift Delete Confirmation Modal */}
      <EModal
        open={open}
        headervalue={"Additional Leave: Delete Confirmation"}
        parentClassname='delete-confirmation-modal'
      >
        <Grid container rowSpacing={2}>
          <Grid item>
            <ETypography className="font-size-16px ">
              Are you sure you want to delete additional leave
              <span className="theme-main-text-color-bold"> {name} </span> ?
            </ETypography>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
            <EButtonOutlined size="large" variant="outlined" onClick={handleClose} > No </EButtonOutlined>
            <ELoadingButton size="large" type="button" variant="contained" onClick={confirmWeeklyOffDelete} className='button-left-margin'> Yes </ELoadingButton>
          </Grid>
        </Grid>
      </EModal>
    </>
  )
}
