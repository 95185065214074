import { styled } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";

export const ETimePicker = styled(DesktopTimePicker)(({ theme }) => ({

    /**
 * [2022-01-10]
 * changes by: Purva Sharma
 * Description:- Style the color for input icons
 **/
  "& .MuiOutlinedInput-root": {
    "&": {
      svg: {
        color: theme.palette.input.inputIcon,
        zIndex: 1,
      },
    },

    "&.Mui-focused svg ": {
      color: theme.palette.primary.main,
    },
  },
}));
