import axios from 'axios';
import {
  PAYROLL_LIST_FAILURE,
  PAYROLL_LIST_SUCCESS,
  PAYROLL_LIST_LOADING,

  PAY_SALARY_LOADING,
  PAY_SALARY_SUCCESS,
  PAY_SALARY_FAILURE,

  PAY_SALARY_LIST_LOADING,
  PAY_SALARY_LIST_SUCCESS,
  PAY_SALARY_LIST_FAILURE,

  PAY_MODAL_LIST_LOADING,
  PAY_MODAL_LIST_SUCCESS,
  PAY_MODAL_LIST_FAILURE,
  SERVER_URL,

  EMPLOYEE_SALARY_SLIP_FAILURE,
  EMPLOYEE_SALARY_SLIP_SUCCESS,
  EMPLOYEE_SALARY_SLIP_LOADING,
  EMPLOYEE_PREV_ADVANCE_LOADING,
  EMPLOYEE_PREV_ADVANCE_SUCCESS,
  EMPLOYEE_PREV_ADVANCE_FAILURE,

  HOLD_STATUS,
  DASHBOARD,
  DEFAULT_ROWS_PERPAGE_4,
  PAGES,
  COMPLETE_STATUS

} from '../constants';
import { Success, Errors } from "../BaseComponents/EToast";
import { Logout } from './AuthAction';
import moment from 'moment';
import { BULK_PAYMENT_URL,BULK_SALARY_PAYMENT_URL } from '../constants';

export function payrollModalListLoading() {
  return {
    type: PAY_MODAL_LIST_LOADING
  };
}
export function payrollModalListSuccess(payload) {
  return {
    type: PAY_MODAL_LIST_SUCCESS,
    payload
  };
}
export function payrollModalListFailure(payload) {
  return {
    type: PAY_MODAL_LIST_FAILURE,
    payload
  };
}

export function payrollListLoading() {
  return {
    type: PAYROLL_LIST_LOADING
  };
}
export function payrollListSuccess(payload) {
  return {
    type: PAYROLL_LIST_SUCCESS,
    payload
  };
}

export function payrollListFailure(payload) {
  return {
    type: PAYROLL_LIST_FAILURE,
    payload
  };
}

export function SalaryList(token, data, page = null, pageSize = null) {

  let queryString = '';
  if (data && data?.list_type) {
    queryString += `list_type=${data?.list_type}&`;
  }
  if (page !== null && pageSize !== null) {
    queryString += `page=${page}&per_page=${pageSize}&`;
  }
  if (data && data?.sort) {
    queryString += `sort=${data?.sort}&`;
  }
  if (data && data?.payment_status) {
    queryString += `payment_status=${data?.payment_status}&`;
  }
  if (data && data?.month) {
    queryString += `month=${data?.month}&`;
  }
  if (data && data?.year) {
    queryString += `year=${data?.year}&`;
  }

  if (data && data?.year_month) {
    queryString += `year_month=${data?.year_month}&`;
  }

  return (dispatch) => {
    dispatch(payrollListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/payment/payroll-payment-list?${queryString}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(payrollListSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(payrollListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function paymentListLoading() {
  return {
    type: PAY_SALARY_LIST_LOADING
  };
}
export function paymentListSuccess(payload) {
  return {
    type: PAY_SALARY_LIST_SUCCESS,
    payload
  };
}

export function paymentListFailure(payload) {
  return {
    type: PAY_SALARY_LIST_FAILURE,
    payload
  };
}
export function SalaryDetailedList(token, data, page = null, pageSize = null) {
  let queryString = '';
  if (data && data?.payroll_id) {
    queryString += `payroll_id=${data?.payroll_id}&`;
  }
  if (data && data?.sort) {
    queryString += `sort=${data?.sort}&`;
  }
  if (data && data?.employee_id) {
    queryString += `employee_id=${data?.employee_id}&`;
  }
  if (data && data?.fullname) {
    queryString += `fullname=${data?.fullname}&`;
  }
  if (data && data?.year_month) {
    const yearmonth = moment(data?.year_month).format('YYYYMM');
    queryString += `year_month=${yearmonth}&`;
  }

  if (data && data?.transaction_status) {
    queryString += `transaction_status=${data?.transaction_status}&`;
  }
  if (page !== null && pageSize !== null) {
    queryString += `page=${page}&per_page=${pageSize}`;
  }

  return (dispatch) => {
    dispatch(paymentListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/payment/payroll-payment-detail-list?${queryString}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(paymentListSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(paymentListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function MakeTalentPayment(token, Data, getPaymentFilterData = null) {
  const data = new FormData();
  data.append('payment_mode', Data?.payment_mode?.id);
  if (Data?.payment_detail) {
    data.append('payment_detail', Data?.payment_detail);
  }
  data.append('created_by', Data?.created_by);
  data.append('amount', Data?.amount);
  data.append('remark', Data?.remark);
  data.append('transaction_id', Data?.transaction_id);
  data.append('employee_id', Data?.employee_id);
  data.append('payroll_id', Data?.payroll_id);
  return (dispatch) => {
    dispatch(paymentLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/payment/make-employee-payment`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(paymentSuccess(res.data));

          Success.fire({
            text: 'html',
            html: `<span style="color:white">${res.data.message}</span>`
          });

          getPaymentFilterData && getPaymentFilterData();
          // if(list_param_data){dispatch(SalaryDetailedList(token, list_param_data, list_param_data?.page, list_param_data?.rowsPerPage));}
          // if(close){close();}
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(paymentFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function paymentLoading() {
  return {
    type: PAY_SALARY_LOADING
  };
}
export function paymentSuccess(payload) {
  return {
    type: PAY_SALARY_SUCCESS,
    payload
  };
}
export function paymentFailure(payload) {
  return {
    type: PAY_SALARY_FAILURE,
    payload
  };
}

export function HoldTalentPayment(token, Data, getPaymentFilterData) {
  const data = new FormData();
  data.append('remark', Data?.remark);
  data.append('transaction_id', Data?.transaction_id);
  data.append('employee_id', Data?.employee_id);
  data.append('payroll_id', Data?.payroll_id);
  data.append('created_by', Data?.created_by);

  return (dispatch) => {
    dispatch(paymentLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/payment/hold-employee-payment`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: 'html',
            html: `<span style="color:white">${res.data.message}</span>`
          });
          dispatch(paymentSuccess(res.data));
          getPaymentFilterData && getPaymentFilterData();
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(paymentFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

/**
 * [2023-03-07]
 * changes by:-Aanchal Sahu
 * Description:- Commenting PendingTabBulkPayment due to no use
 */
// export function PendingTabBulkPayment(token, Data, close = null, list_param_data = null) {
//   const data = new FormData();
//   let convertedString = JSON.stringify(Data?.payment_id)
//   data.append('payroll_id', Data?.payroll_id);
//   data.append('payment_id', convertedString);
//   if (Data?.payment_medium && Data?.transaction_status != HOLD_STATUS) { data.append('payment_medium', Data?.payment_medium && Data?.payment_medium?.id ? Data?.payment_medium?.id : null); }
//   data.append('transaction_status', Data?.transaction_status);
//   data.append('accountant_remark', Data?.accountant_remark);

//   return (dispatch) => {
//     dispatch(paymentLoading());
//     axios({
//       method: 'post',
//       url: `${SERVER_URL}/payment/bulk-salary-payment`,
//       headers: {
//         Authorization: 'Bearer ' + token
//       },
//       data
//     })
//       .then((res) => {
//         if (res.status === 200) {
//           Success.fire({
//             text: 'html',
//             html: `<span style="color:white">${res.data.message}</span>`
//           });
//           dispatch(paymentSuccess(res.data));

//           if (close) { close(); }
//           if (list_param_data) { dispatch(SalaryDetailedList(token, list_param_data, list_param_data?.page, list_param_data?.rowsPerPage)); }
//         }
//       })
//       .catch((error) => {
//         if (error?.response) {
//           Errors.fire({
//             text: 'html',
//             html: `<span style="color:white">${error.response.data.message}</span>`
//           });
//           dispatch(paymentFailure(error.response.data));
//           if (error?.response?.status == 401) {
//             dispatch(Logout())
//           }
//         }
//       });
//   };
// }

export function CancelTalentPayment(token, Data, getPaymentFilterData) {
  const data = new FormData();
  data.append('remark', Data?.remark);
  data.append('transaction_id', Data?.transaction_id);
  data.append('employee_id', Data?.employee_id);
  data.append('payroll_id', Data?.payroll_id);
  data.append('created_by', Data?.created_by);

  return (dispatch) => {
    dispatch(paymentLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/payment/cancel-employee-payment`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: 'html',
            html: `<span style="color:white">${res.data.message}</span>`
          });
          dispatch(paymentSuccess(res.data));
          getPaymentFilterData && getPaymentFilterData();
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(paymentFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function TalentTransactionList(token, data, page = null, pageSize = null) {
  let queryString = '';
  if (data && data?.payroll_id) {
    queryString += `&payroll_id=${data?.payroll_id}`;
  }
  if (data && data?.transaction_id) {
    queryString += `&transaction_id=${data?.transaction_id}`;
  }
  if (data && data?.employee_id) {
    queryString += `&employee_id=${data?.employee_id}`;
  }
  if (page !== null && pageSize !== null) {
    queryString += `&page=${page}&per_page=${pageSize}`;
  }

  return (dispatch) => {
    dispatch(payrollModalListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/payment/employee-transaction-detail?${queryString}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(payrollModalListSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(payrollModalListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


/**
 * [2023-03-07]
 * changes by:-Aanchal Sahu
 * Description:- Commenting GetEmployeeSalarySlip due to no use
 */
//PaySlip
// export function GetEmployeeSalarySlip(token, data) {
//   let queryString = '';
//   if (data && data?.employee_id) {
//     queryString += `&employee_id=${data?.employee_id}`;
//   }
//   if (data && data?.transaction_id) {
//     queryString += `&transaction_id=${data?.transaction_id}`;
//   }
//   if (data && data?.payroll_id) {
//     queryString += `&payroll_id=${data?.payroll_id}`;
//   }
//   return (dispatch) => {
//     dispatch(employeeSalarySlipLoading());
//     axios({
//       method: 'get',
//       url: `${SERVER_URL}/payment/employee-salary-slip?${queryString}`,
//       crossDomain: true,
//       headers: {
//         Authorization: 'Bearer ' + token
//       }
//     })
//       .then((res) => {
//         if (res.status === 200) {
//           dispatch(employeeSalarySlipSuccess(res));
//         }
//       })
//       .catch((error) => {
//         if (error?.response) {
//           dispatch(employeeSalarySlipFailure(error.response.data));
//           if (error?.response?.status == 401) {
//             dispatch(Logout())
//           }
//         }
//       });
//   };
// }

export function employeeSalarySlipLoading() {
  return {
    type: EMPLOYEE_SALARY_SLIP_LOADING
  };
}
export function employeeSalarySlipSuccess(payload) {
  return {
    type: EMPLOYEE_SALARY_SLIP_SUCCESS,
    payload
  };
}
export function employeeSalarySlipFailure(payload) {
  return {
    type: EMPLOYEE_SALARY_SLIP_FAILURE,
    payload
  };
}

// Dashboard payment list Action
/*
 * [2022-05-24]
 * Author: Santosh Mahule
 * Description: Fetch Latest Payment List
*/
export function TalentLatestPaymentList(token, employee_id, page_name = null, page = null, pageSize = null, status = null) {
  let queryString = '';

  if (employee_id) {
    queryString += `&employee_id=${employee_id}`;
  }
  if (page !== null && pageSize !== null) {
    queryString += `&page=${page}&per_page=${pageSize}`;
  }
  if (page_name) {
    queryString += `&page_name=${page_name}`;
  }
  if (status) {
    queryString += `&transaction_status=${status}`;
  }
  return (dispatch) => {
    dispatch(paymentLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/payment/payment-list?${queryString}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(paymentSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(paymentFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


/*
 * [2022-07-18]
 * Author: Aanchal Sahu
 * Description: Fetch Employee Payment List
 * [2022-08-25]
 * Author: Aanchal Sahu
 * Description: Freelancer Payment List
 */
export function EmployeeRequestPaymentList(token, page_name = null, Search = null, page = null, pageSize = null, ) {
  let queryString = '';
  if (page_name) {
    queryString += `page_name=${page_name}`;
  }
  if (page !== null && pageSize !== null) {
    queryString += `&page=${page}&per_page=${pageSize}`;
  }
  if (Search) {
    let startDate = Search?.start_date ? moment(Search?.start_date).format('YYYY-MM-DD') : null;
    let endDate = Search?.end_date ? moment(Search?.end_date).format('YYYY-MM-DD') : null;
    if (startDate && startDate != 'Invalid date') { queryString += `&start_date=${startDate}`; }
    if (endDate && endDate != 'Invalid date') { queryString += `&end_date=${endDate}`; }
    if (Search?.particular && Search?.particular != null) { queryString += `&payment_category=${Search?.particular?.id}`; }
    
    if (Search?.yearMonth && Search?.yearMonth != '') { queryString += `&year_month=${Search?.yearMonth}`; }
    if (Search?.contract_no && Search?.contract_no != '') { queryString += `&contract_no=${Search?.contract_no}`; }
    if (Search?.employee && Search?.employee != '') {
      if (page_name == 'request_talent') { queryString += `&talent_name=${Search?.employee}`; }
      else { queryString += `&freelancer_name=${Search?.employee}`; }
    }
    if (Search?.employee_id?.id && Search?.employee_id != null) {
      queryString += `&employee_id=${Search?.employee_id?.id}`;
    }
    if (Search?.contract_id && Search?.contract_id?.id) { queryString += `&contract_id=${Search?.contract_id?.id}`; }

    // deprecated Search?.freelancer_id?.id
    if (Search?.freelancer_id && Search?.freelancer_id?.id) { queryString += `&freelancer_id=${Search?.freelancer_id?.id}`; }
    // Added && `!Search?.freelancer_id?.id` below since this if condition was letting in Search?.freelancer_id?.id as well.
    if (Search?.freelancer_id && Search?.freelancer_id !== '' && !Search?.freelancer_id?.id) { queryString += `&freelancer_id=${Search?.freelancer_id}`; }

     // deprecated Search?.sortBy?.id
    if (Search?.sortBy && Search?.sortBy != null) { queryString += `&sort=${Search?.sortBy?.id}`; }
    if (Search?.sort && Search?.sort != null) { queryString += `&sort=${Search?.sort}`; }

    if (Search?.withoutRoleCheck && Search?.withoutRoleCheck != null) { queryString += `&withoutRoleCheck=${Search?.withoutRoleCheck}`; }
  }
  return (dispatch) => {
    dispatch(paymentListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/payment/payment-list?${queryString}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(paymentListSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(paymentListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function RequestDetailFormAction(token, Data, close = null,fromPage=null) {
  const data = new FormData();
  data.append('transaction_category', Data?.transaction_category);
  data.append('request_amount', Data?.request_amount);

  data.append('created_by', Data?.created_by);
  data.append('remark', Data?.remark);
  if (Data?.employee_id) {
    data.append('employee_id', Data?.employee_id);
  }
  else if (Data?.freelancer_id) {
    data.append('freelancer_id', Data?.freelancer_id);
  }
  if (Data?.transaction_category == 3) {
    data.append('reimbursement_bill_date', Data?.reimbursement_bill_date);
    data.append('reimbursement_attachment', Data?.reimbursement_attachment);
  }
  if (Data?.transaction_category == 2 && Data?.previous_amount) {
    data.append('previous_amount', Data?.previous_amount);
  }
  if (Data?.is_extra_payment) { data.append('is_extra_payment', Data?.is_extra_payment); }
  return (dispatch) => {
    dispatch(paymentLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/payment/payment-request`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: 'html',
            html: `<span style="color:white">${res.data.message}</span>`
          });
          dispatch(paymentSuccess(res.data));
          if (close) { close(); }
          if(fromPage==DASHBOARD){ 
            dispatch(TalentLatestPaymentList(token, data.employee_id, "request_self", PAGES, DEFAULT_ROWS_PERPAGE_4, COMPLETE_STATUS));
          }
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(paymentFailure(error.response.data));
          if(fromPage==DASHBOARD){ 
            dispatch(TalentLatestPaymentList(token, data.employee_id, "request_self", PAGES, DEFAULT_ROWS_PERPAGE_4, COMPLETE_STATUS));
          }
          else{dispatch(EmployeeRequestPaymentList(token, 'request'));}
          if (close) { close(); }
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


// for approve/reject payment request by HR and the payment by Accountant & direct payment  by super admin & Bulk Payment in talent payment
export function HRAccountantPaymentAction(token, Data, close, action = null) {
  const data = new FormData();
  let url = 'payment/'
  if (action == 'request_status') {
    url += 'payment-action';
    data.append('payment_id', Data?.contract_id);
    if (Data?.remark) { data.append('transaction_status_remark', Data?.remark); }
    data.append('transaction_status', Data?.status);
  }
  else if (action == 'final-action') {
    url += 'final-payment-action';
    data.append('payment_id', Data?.payment_id);
    data.append('accountant_remark', Data?.accountant_remark);
    data.append('payment_medium', Data?.payment_medium);
    if (Data?.payment_detail) {
      data.append('payment_detail', Data?.payment_detail);
    }
  }
  else if (action == 'delete_request') {
    url += 'delete-payment-detail';
    data.append('payment_id', Data?.payment_id);
  }
  if (action == 'direct-payment')//for directPayment
  {
    url += 'payment-request';
    data.append('employee_id', Data?.talent?.id);
    data.append('remark', Data?.remark);
    data.append('payment_mode', Data?.payment_mode?.id);
    data.append('request_amount', Data?.amount);
    data.append('created_by', Data?.created_by);
    data.append('transaction_category', Data?.transaction_category);
    data.append('is_extra_payment', Data?.is_extra_payment);
    
    if (Data?.payment_detail) {
      data.append('payment_detail', Data?.payment_detail);
    }
  }
  else if (action == BULK_PAYMENT_URL) {
    url += BULK_PAYMENT_URL;
    let ids = JSON.stringify(Data?.payment_id)
    data.append('payment_id', ids);
    data.append('accountant_remark', Data?.accountant_remark);
    data.append('payment_medium', Data?.payment_medium?.id);
  }
  else if (action == BULK_SALARY_PAYMENT_URL) {
    url += BULK_SALARY_PAYMENT_URL;
    let ids = JSON.stringify(Data?.payment_id)
    data.append('payment_id', ids);
    data.append('accountant_remark', Data?.accountant_remark);
    data.append('payment_medium', Data?.payment_medium?.id);
  }

  

  return (dispatch) => {
    dispatch(paymentLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/${url}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: 'html',
            html: `<span style="color:white">${res.data.message}</span>`
          });
          dispatch(paymentSuccess(res.data));
          if (close) { close(); }
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(paymentFailure(error.response.data));
          if (close) { close(); }
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function GetAdvanceBalanceAction(token, id) {
  const data = new FormData();
  data.append('employee_id', id);

  return (dispatch) => {
    dispatch(prevAdvanceLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/payment/get-advance-balance`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(prevAdvanceSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(prevAdvanceFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}
export function prevAdvanceLoading() {
  return {
    type: EMPLOYEE_PREV_ADVANCE_LOADING
  };
}
export function prevAdvanceSuccess(payload) {
  return {
    type: EMPLOYEE_PREV_ADVANCE_SUCCESS,
    payload
  };
}
export function prevAdvanceFailure(payload) {
  return {
    type: EMPLOYEE_PREV_ADVANCE_FAILURE,
    payload
  };
}


export function TalentPaymentLogList(token, page_name = null, Search = null, page = null, pageSize = null ) {
  let queryString = '';
  if (page_name) {
    queryString += `page_name=${page_name}`;
  }
  if (page !== null && pageSize !== null) {
    queryString += `&page=${page}&per_page=${pageSize}`;
  }
  if (Search) {
    let startDate = Search?.start_date ? moment(Search?.start_date).format('YYYY-MM-DD') : null;
    let endDate = Search?.end_date ? moment(Search?.end_date).format('YYYY-MM-DD') : null;
    if (startDate && startDate != 'Invalid date') { queryString += `&start_date=${startDate}`; }
    if (endDate && endDate != 'Invalid date') { queryString += `&end_date=${endDate}`; }
    if (Search?.particular && Search?.particular != '') { queryString += `&transaction_category=${Search?.particular?.id}`; }
    if (Search?.sortBy && Search?.sortBy != '') { queryString += `&sort=${Search?.sortBy?.id}`; }
    if (Search?.contract_id && Search?.contract_id != '') { queryString += `&contract_id=${Search?.contract_id?.id}`; }
    if (Search?.employee_id && Search?.employee_id != '') {
      queryString += `&user_id=${Search?.employee_id?.id}`;
    }
    if (Search?.freelancer_id && Search?.freelancer_id != '') {
      queryString += `&user_id=${Search?.freelancer_id?.id}`;
    }
    if (Search?.user_id && Search?.user_id != '') { queryString += `&user_id=${Search?.user_id}`; }

  }
  return (dispatch) => {
    dispatch(paymentListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/payment/company-payment-log?${queryString}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(paymentListSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(paymentListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}