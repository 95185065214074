
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { ETable, ETableBody, ETableCell, ETableContainer, ETableRowSmall } from '../../BaseComponents/ETable';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import base64 from 'base-64'
import { PAYMENT_CATEGORY, PAYMENT_CATEGORY_SALARY } from '../../constants';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import EScrollbar from '../../BaseComponents/EScrollbar';
import EListHeadSmall from '../../BaseComponents/ETable';
import SearchNotFound from '../SearchNotFound';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import { ECard } from '../../BaseComponents/ECard';
import { EDividerCard } from '../../BaseComponents/EDivider';
import { ETypographyCardHeading } from '../../BaseComponents/ETypography';
import EChip from '../../BaseComponents/EChip';
import { FreelancerContractList } from '../../action/FreelancerAction';
import { capitalizeCapitalString } from '../../utils/formatText';
import { numberWithCommas } from '../../utils/formatNumber';


const TABLE_HEAD = [
    { id: 'Contract Name', label: ' Contract Name', alignRight: false },
    { id: 'Incharge', label: 'Incharge', alignRight: false },
    { id: 'Tenure', label: 'Tenure', alignRight: 'center' },
    { id: 'Total Amount', label: 'Total Amount', alignRight: true },
    { id: 'Status', label: 'Status', alignRight: 'center' },
];

/**
 * [2022-11-24]
 * Created by:- Aanchal Sahu
 * Description:- designed this component according to view profile
 **/
function FreelancerViewContractDetails(props) {
    const { auth, freelancer } = props;
    const Theme = useSelector((state) => state.ThemeReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    let PAYMENT_CATEGORY_WITH_SALARY = [];

    if (PAYMENT_CATEGORY_WITH_SALARY?.length == 0) {
        PAYMENT_CATEGORY_WITH_SALARY.push(...PAYMENT_CATEGORY);
        PAYMENT_CATEGORY_WITH_SALARY[3] = { id: PAYMENT_CATEGORY_SALARY, label: 'Salary' };
    }
    const HandleViewClick = (id) => {
        navigate(`/freelancer-contract/view-contract/${base64.encode(id)}`)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(FreelancerContractList(auth?.authtoken, { freelancer_id: freelancer?.freelanceData?.id }, page, rowsPerPage));
        }
    }, [page, rowsPerPage]);

    return (
        <>
            <ECard sx={{ padding: '20px', height: 'auto', textAlign: 'left', mx: "auto" }}>
                <Grid container display="row" justifyContent="space-between" alignItems='center'>
                    <Grid item>
                        <ETypographyCardHeading variant='h5'>Contract Details</ETypographyCardHeading>
                    </Grid>
                    <Grid xs={12} lg={12} item className='pb-1rem '>
                        <EDividerCard className='margin-top-05' />
                    </Grid>
                </Grid>
                <EScrollbar>
                    <ETableContainer >
                        <ETable>
                            <EListHeadSmall
                                headLabel={TABLE_HEAD}
                                onRequestSort={() => null}
                                Theme={Theme}
                            />
                            {freelancer?.employeePaymentLoading ?
                                <ETableBody>
                                    <ETableRowSmall>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRowSmall>
                                </ETableBody> :
                                <ETableBody>
                                    {freelancer?.freelanceListData && freelancer?.freelanceListData?.rows?.map((row, index) => {
                                        const {
                                            contract_code,
                                            contract_title,
                                            contract_amount,
                                            contract_start_date,
                                            contract_end_date, id, status,Incharge
                                        } = row;
                                        return (
                                            <ETableRowSmall
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                                role="checkbox"
                                                onClick={() => { HandleViewClick(id) }} className='cursor-pointer' >
                                                <ETableCell align='left'  className='minWidth-160px'>
                                                    <span className='bold-700'>{contract_title ? contract_title : '-'}</span>
                                                    <br />
                                                    {contract_code ? contract_code: ''}
                                                </ETableCell>
                                                <ETableCell align='left' className='minWidth-160px'>
                                                    <span className='bold-700'>{Incharge?.fullname ? Incharge?.fullname : '-'}</span>
                                                    <br />
                                                    {Incharge?.employee_code ? Incharge?.employee_code: ''}
                                                </ETableCell>
                                                <ETableCell align='center' className='minWidth-160px'>
                                                    {contract_start_date && contract_end_date ? moment(contract_start_date).format('DD/MM/YYYY') + ' to ' + moment(contract_end_date).format('DD/MM/YYYY') : '-'}
                                                </ETableCell>
                                                <ETableCell align="right" className='minWidth-150'>
                                                    {numberWithCommas(contract_amount)}
                                                </ETableCell>
                                                <ETableCell align="center">
                                                    {status ? <EChip  label={capitalizeCapitalString(status)} width={"110px"}></EChip>
                                                        : '-'}
                                                </ETableCell>

                                            </ETableRowSmall>
                                        );
                                    })}
                                </ETableBody>
                            }
                            {freelancer?.freelanceListData?.count == 0 && !freelancer.employeePaymentLoading && (
                                <ETableBody>
                                    <ETableRowSmall>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <SearchNotFound />
                                        </ETableCell>
                                    </ETableRowSmall>
                                </ETableBody>
                            )}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {freelancer?.freelanceListData && freelancer?.freelanceListData?.count > 10 &&
                    <ETablePagination
                        rowsPerPageOptions={[10, 25]}
                        component="div"
                        count={freelancer?.freelanceListData?.count ? freelancer?.freelanceListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </ECard>
        </>
    );
}

export default FreelancerViewContractDetails