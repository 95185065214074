import React, { useState } from 'react'
import { EBoxHRDash } from '../../BaseComponents/EBox'
import { ETypography } from '../../BaseComponents/ETypography'
import { EAutocomplete } from '../../BaseComponents/EAutocomplete'
import { ETextField } from '../../BaseComponents/ETextField'
import { Line } from 'react-chartjs-2';
import { EBox } from '../../BaseComponents/EBox';
import { EChipHrStats } from '../../BaseComponents/EChip';
import { useTheme } from '@mui/material';
// import StatisticsChart from './StatisticsChart'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
//   Filler //used to fill bottom
} from 'chart.js';
import moment from 'moment'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
//   Filler
);

const AttendanceStats = ({attendanceStatsArray, showImage}) => {

    const theme = useTheme();


    // Initializing the state variables for selectedShift, labels, and backgroundColors
    const [selectedShift, setSelectedShift] = useState(
      attendanceStatsArray?.length > 0 ? attendanceStatsArray[0]?.shift_name : ""
    );

    // Finding the shiftData that matches the selectedShift
    const shiftData = attendanceStatsArray?.find(
      (item) => item?.shift_name === selectedShift
    );

    const formattedLabels = shiftData?.shift_labels?.map(dateStr => {
      const date = moment(dateStr);
      return date.format("DD MMM");
    });


    const data = {
      labels:  formattedLabels || [],
      datasets : [
          {   
              label:'',
              data: shiftData?.datasets[0]?.desktopCheckinData || [],
              backgroundColor:'#A6775B', //Point background color
              borderColor:'#D9B88F', //Line background color

              // tension:0.4, //for smoothness of the line in graph
              // fill:true //to fill the bottom of the line
              // pointStyle:'rect', //Rectangle instead of circle in graph
          },
          {   
              label:'',
              data: shiftData?.datasets[0]?.mobileCheckinData || [],
              backgroundColor:'#2C4A73', //Point background color
              borderColor:'#049DBF', //Line background color
          }
      ]
  }

  const options = {

      responsive: true,
      height:150,

      plugins: {
        legend: {
          display:false,
          position: 'bottom',
          align:'start',
          reverse: false, //put True if you want to reverse the legend order
          labels: {
              boxWidth: 90, // The width of the colored boxes in the legend
              boxHeight:30, //Height of the colored boxes in the legend
              fontSize: 12, // The font size of the legend labels
              fontStyle: "normal", // The font style of the legend labels (normal, italic, oblique)
              fontColor: "#666", // The font color of the legend labels
              // fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif", // The font family of the legend labels
              padding: 10, // The padding between the legend items and the legend container
              usePointStyle: false, // Whether to use a circle or square for the colored boxes in the legend
              },
        },
        
        title: {
          display: false,
          text: 'HR dashboard Statistics Chart',
        },
      },
    }


  return (
    <>
        <EBoxHRDash className="font-size-20px bold-400">
            <ETypography className="font-size-20px bold-600">Attendance Statistics</ETypography>

          {/* If the attendanceStatsArray contains more than one chartData object, display an Autocomplete component that allows users to select a specific shift*/}
            {
              attendanceStatsArray?.length > 1   && 
                <EAutocomplete
                clearIcon={false}
                options={attendanceStatsArray?.map((item) => item.shift_name)}
                value={selectedShift}
                onChange={(event, value) => setSelectedShift(value)}
                sx={{width: '154px'}}
                renderInput={(params) => <ETextField {...params} placeholder='Shift'/>}
                  />
            }

        </EBoxHRDash>

        <EBoxStatsDiv>

        <EBox 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: '0px',
            width: '127px',
            height: '67px',
            borderRadius: '2px',
          }}>
            <ETypography sx={{color:'#808080', fontSize:'16px', lineHeight :'22.4px'}}>{shiftData?.today_data?.shift_name || ""} </ETypography>
            <ETypography sx={{color:'#808080', fontSize:'12px'}}>Average Check in Time</ETypography>
            <ETypography sx={{fontSize:'20px', fontWeight:'600'}}>{shiftData?.today_data?.check_in_average_time || "00 : 00"}</ETypography>
        </EBox>

        <EBox 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: '0px',
            borderRadius: '2px',
          }}>

            <ETypography sx={{color:'#808080', fontSize:'16px', lineHeight :'22.4px'}}>Today</ETypography>

            <EChipHrStats 
              label={
                <>  
                  <span
                  style={{
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '140%',
                      marginRight:'4px'
                      }}
                    >
                        {shiftData?.today_data?.checkin_is_desktop || 0}
                  </span>
                  <span style={{fontWeight:'400', fontSize:'10px'}}>Desktop Checkin</span>
                </>
                } 
              sx={{backgroundColor:theme.palette.background.darkOrangeBrown, color:'white', textAlign:'left'}}
            />

            <EChipHrStats 
              label={
                <>  
                  <span
                  style={{
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '140%',
                      marginRight:'4px'
                      }}
                    >
                        {shiftData?.today_data?.checkin_is_mobile || 0}
                  </span>
                  <span style={{fontWeight:'400', fontSize:'10px'}}>Mobile Checkin</span>
                </>
                } 
              sx={{backgroundColor:theme.palette.background.midnightBlue, color:'white', textAlign:'left'}}
            />

        </EBox>
  
</EBoxStatsDiv>

{showImage == true ? (
        <>
        <EBox sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <img className="no-data-image" src="/assets/images/hrDashboardNodata/attendance statistc.svg" alt="No data" style={{width:'70%', marginBottom:'4px'}}/>
          <ETypography sx={{fontSize:'16px', fontWeight:'500'}}>No Data Found</ETypography>
          </EBox>
        </>
      ) : (       
          <Line data={data} options={options} />
      )}
    </>
  )
}

export default AttendanceStats

const EBoxStatsDiv = (props) =>{
  const {children} = props
  return( 
      <EBox 
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '8px 16px',
          gap: '17px',
        }}
      >
          {children}
      </EBox>
  )
}