import { Avatar, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import { ROW_SIZE_PER_PAGE } from "../../constants";
import base64 from 'base-64'
import SearchNotFound from "../SearchNotFound";
import moment from "moment";
import { getInitials, HandlePhoto } from "../../utils/CommonFunctions";
import EHidden from "../../BaseComponents/EHidden";
import { EIconButton } from "../../BaseComponents/EButtons";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { EIcon } from "../../BaseComponents/EIcon";
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline-rounded';
import { useMemo } from "react";


const getTableHead = (allowAction) => {
    const arrayHead = [
        { id: 'Photo', label: '', alignRight: false },
        { id: 'Freelancer Code', label: 'Freelancer Code', alignRight: false, hideLabelCriteria: 'lgDown' },
        { id: 'Freelancer  Name', label: 'Freelancer  Name', alignRight: false },
        { id: 'Contact Details', label: 'Contact Details', alignRight: false },
        { id: 'Enrolled On', label: 'Enrolled On', alignRight: false },
    ]
    if (allowAction) {
        arrayHead.push({ id: 'Action', label: 'Action', alignRight: 'center' });
    }
    return arrayHead
}


function FreelancerListGrid(props) {
    const navigate = useNavigate();

    const { freelanceListData, page, rowsPerPage, onPageChange, onRowsPerPageChange, allowViewFreelancerDetail } = props;
    const pgCount = (page > 0 && (freelanceListData && freelanceListData?.count ? freelanceListData?.count : 0) < rowsPerPage) ? 0 : page;

    const setSelectedRow = (row) => {
        navigate(`/freelancer-contract/freelancer/${base64.encode(row.id)}`)
    }

    const TABLE_HEAD = useMemo(() => getTableHead(allowViewFreelancerDetail), [])

    return (
        <Grid container my={0} >
            <Grid item xs={12}>
                <EScrollbar>
                    <ETableContainer>
                        <ETable>
                            {freelanceListData?.rows && freelanceListData?.rows?.length > 0 ?
                                <>
                                    <EListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={freelanceListData?.rows ? freelanceListData?.rows?.length : 0}
                                        onRequestSort={() => null}
                                    />


                                    <ETableBody>
                                        {freelanceListData?.rows && freelanceListData.rows.map((row, index) => {
                                            const { id, freelancer_gender, freelancer_photo, freelancer_code, freelancer_contact_no, freelancer_email, freelancer_name, created_at } = row;
                                            return (
                                                <ETableRow
                                                    hover
                                                    key={index+freelancer_code+id}
                                                    tabIndex={-1}
                                                >
                                                    <ETableCell align="center" sx={{ padding: '10px' }} className='width-20'>
                                                        <Avatar
                                                            title={freelancer_name} alt={freelancer_name}
                                                            src={(freelancer_photo || freelancer_gender) ? HandlePhoto(freelancer_photo, freelancer_gender) : '  '}
                                                            key={index}
                                                            className='cursor-pointer list-avatar-css'
                                                        >
                                                            {!freelancer_gender &&
                                                                <span className="font-size-14px theme-color-static">{getInitials(freelancer_name)}</span>}
                                                        </Avatar>
                                                    </ETableCell>
                                                    <EHidden width="lgDown">
                                                        <ETableCell align="left" >
                                                            {freelancer_code}
                                                        </ETableCell>
                                                    </EHidden>
                                                    <ETableCell align="left" >
                                                        {freelancer_name}
                                                    </ETableCell>
                                                    <ETableCell align="left">
                                                        {freelancer_email}<br />{freelancer_contact_no?'+91-'+freelancer_contact_no:''}
                                                    </ETableCell>
                                                    <ETableCell align="left" className='width-130px' >
                                                        {created_at ? moment(created_at).format('DD/MM/YYYY') : ''}
                                                    </ETableCell>
                                                    {
                                                        allowViewFreelancerDetail &&
                                                        <ETableCell align="center" className="minWidth-80">
                                                            <ETooltip title="View" arrow>
                                                                    <EIconButton className="p0" onClick={() => setSelectedRow(row)} >
                                                                        <EIcon icon={VisibilityIcon} />{/* View profile button for all three list */}
                                                                    </EIconButton>
                                                                </ETooltip>
                                                        </ETableCell>
                                                    }
                                                </ETableRow>
                                            );
                                        })}
                                    </ETableBody></> :
                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                            <SearchNotFound />
                                        </ETableCell>
                                    </ETableRow>    
                                </ETableBody>}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
            </Grid>
            {freelanceListData?.rows && freelanceListData?.rows?.length > 0 &&
                <Grid item xs={12}
                    justifyContent="flex-end"
                    alignItems="center"
                    className="display-flex"
                >
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={freelanceListData?.count ? freelanceListData?.count : 10}
                        rowsPerPage={rowsPerPage}
                        page={pgCount}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Grid>
            }
        </Grid>
    );
}

export default FreelancerListGrid;
