import { Grid, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkingHourList } from "../../action/DashboardAction";
import Page from "../../BaseComponents/EPage";
import { ETypography } from "../../BaseComponents/ETypography";
import { ECardDashboard } from "../../BaseComponents/ECard";
import { useTheme } from "@emotion/react";
import LeaveRequestHR from "../../PageComponents/HrDashboard/LeaveRequestHR";
import BirthdayComp from "../../PageComponents/HrDashboard/BirthdayComp";
import AttendanceStats from "../../PageComponents/HrDashboard/AttendanceStats";
import StaffingSummary from "../../PageComponents/HrDashboard/StaffingSummary";
import Calendar from "../../PageComponents/HrDashboard/Calendar";
import { getBirthdayAnniversary } from "../../action/HRDashboardAction";
import CheckInStats from "../../PageComponents/HrDashboard/CheckInStats";
import DashboardPayments from "../../PageComponents/HrDashboard/DashboardPayments";
import moment from "moment";
import CheckinCheckout from "../../PageComponents/HrDashboard/CheckinCheckout";
import LeavesDashboard from "../../PageComponents/HrDashboard/LeavesDashboard";
import PaymentREquestHR from "../../PageComponents/HrDashboard/PaymentREquestHR";
import { ROLE_HR, SUPER_ADMIN } from "../../constants";
import BirthdayTalent from "../../PageComponents/HrDashboard/BirthdayTalent";
import AnniversaryTalent from "../../PageComponents/HrDashboard/AnniversaryTalent";
import { ROLE_ADMIN } from "../../constants";
import { EButton } from "../../BaseComponents/EButtons";
import EModal from "../../BaseComponents/EModal";
import { InviteHR } from "./Onboarding/invite-hr-form";
import { useGreetings } from "../../hooks/useGreetings";

function HrDashboard(props) {
  const { auth, notDisplayGreet = false } = props;
  const dispatch = useDispatch();
  const company = useSelector((state) => state.CompanyReducer);
  const {greetMsg} = useGreetings()
  const [openInvite, setOpenInvite] = useState(false)
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const attendanceDay = useSelector(
    (state) => state.AttendanceDayEmployeeReducer
  );

  const login_time = auth?.authData?.login_at ? auth?.authData?.login_at : null;

  let AttendanceArray = {
    minimum_hrs: attendanceDay?.present_min_hour
      ? attendanceDay?.present_min_hour
      : null,
    checkin_login_diff: moment().diff(login_time, "minutes")
      ? moment().diff(login_time, "minutes")
      : 0,
    checkin_time: attendanceDay?.attendanceData?.check_in_time
      ? attendanceDay?.attendanceData?.check_in_time
      : null,
    checkout_time: attendanceDay?.attendanceData?.check_out_time
      ? attendanceDay?.attendanceData?.check_out_time
      : null,
    login_time: auth?.authData?.login_at ? auth?.authData?.login_at : null,
    office_open_time: attendanceDay?.attendanceData?.office_open_time,
    min_hour_for_present: attendanceDay?.attendanceData?.present_min_hour,
    show_as_talent: auth?.authData?.show_as_talent,
  };

  const [isMinimunHoursComplete, setIsMinimunHoursComplete] = useState(false);

  // value is true if screen size is xl else value is false
  // used to render number of avatars in BirthdayComp
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  // initial api call
  useEffect(() => {
    if (auth?.authtoken) {
      dispatch(WorkingHourList(auth.authtoken));
      dispatch(getBirthdayAnniversary(auth.authtoken, setLoading));
    }
  }, [auth?.authtoken]);

  const { birthdayAnniversaryLoading, birthdayAnniversaryData } = useSelector(
    (state) => state.HRDashboardReducer
  );

  const bdayCompArray = birthdayAnniversaryData?.dashBoardBirthDayCard || [];
  const workAnniversaryCompArray = birthdayAnniversaryData?.dashboardWorkAnniversaryCard || [];
  const staffingSummaryCompArray = birthdayAnniversaryData?.dashboardEmployeeSummaryCard || [];
  const attendanceStatsArray = birthdayAnniversaryData?.dashboardAttendanceStatisticsCard || [];
  const CheckinStatsArray = birthdayAnniversaryData?.dashboardCheckinLogCard || [];
  const LeaveRequestCardArray = birthdayAnniversaryData?.dashboardLeaveRequestsCard || []
  const PaymentRequestCardArray = birthdayAnniversaryData?.dashboardPaymentRequestsCard || []


  const isHR = (auth?.authData?.role == SUPER_ADMIN || auth?.authData?.role == ROLE_ADMIN || auth?.authData?.role == ROLE_HR) ? true : false;

  if (loading) return null;


  return (
    <Page title="Dashboard" className="pt-16px">
      
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="display-flex justify-content-space-between align-center "
        >
          {!notDisplayGreet 
          &&          
            <ETypography className="font-size-32px">
              {greetMsg? greetMsg + ", ":null}
              <span className="bold-700">
                {auth?.authData?.fname ? auth?.authData?.fname + "!" : null}
              </span>
            </ETypography>
          }
          {
             company?.companyData?.onBoarding?.invite_hr_completed == false && 
             <EButton
             onClick={() => setOpenInvite(!openInvite)}

           >
             Invite HR
           </EButton>
          
          }
           
        </Grid>

        {/* Employee attendance summary */}
        <HRGrid
          heading="Working hours"
          className="font-size-20px bold-600 mb-8px mt-16px"
          sx={{ lineHeight: "28px" }}
        >
          <CheckinCheckout
            setIsMinimunHoursComplete={setIsMinimunHoursComplete}
            isMinimunHoursComplete={isMinimunHoursComplete}
            AttendanceArray={AttendanceArray}
          />
        </HRGrid>

        {/* Employee attendance summary */}
          { isHR &&       
          <HRGrid
            heading="Today's Attendance"
            className="font-size-20px bold-600 mb-8px mt-16px"
            sx={{ lineHeight: "28px" }}
          >
            <StaffingSummary value={staffingSummaryCompArray} />
          </HRGrid>}

        {/* Calendar Component */}
        <HRGrid
          heading="Calendar"
          className="font-size-20px bold-600 mb-8px mt-16px"
          sx={{ lineHeight: "28px" }}
        >
          <Calendar />
        </HRGrid>

        {/* Checkin stats Chart Component */}
        {isHR &&        
          <HRGrid
            className="font-size-20px bold-600 mb-8px mt-16px"
            sx={{ lineHeight: "28px" }}
          >
            <CheckInStats CheckinStatsArray={CheckinStatsArray} />
          </HRGrid>}

        {/* Attenadance stats Chart Component */}
        {isHR &&         
          <HRGrid
            className="font-size-20px bold-600 mb-8px mt-16px"
            sx={{ lineHeight: "28px" }}
          >
            <AttendanceStats
              attendanceStatsArray={attendanceStatsArray}
              showImage={attendanceStatsArray?.length > 0 ? false : true}
            />
          </HRGrid>}

        {/* Birthday component */}   
        {isHR 
        ? 
          <HRGrid
          heading="Birthday"
          calssName="font-size-20px bold-600 mb-8px mt-16px"
          sx={{ lineHeight: "28px" }}
        >
            <BirthdayComp
              today={bdayCompArray?.DashboardBirthDayToday}
              afterToday={bdayCompArray?.DashboardBirthDayAfterToday}
              matchesXL={matchesXL}
              type={1}
              isHr = {isHR}
            />
          </HRGrid>
        :
        <>
          <HRGrid
            heading="Birthday"
            calssName="font-size-20px bold-600 mb-8px mt-16px"
            sx={{ lineHeight: "28px" }}
          >
            <BirthdayTalent 
              today={bdayCompArray?.DashboardBirthDayToday}  
              matchesXL={matchesXL}
              type={1}
            />
          </HRGrid>
        </>

        }    

        {/* Leave request Component */}
        {isHR &&        
          <HRGrid2Tile heading="">
            <LeaveRequestHR LeaveRequestCardArray={LeaveRequestCardArray}/>
          </HRGrid2Tile>}

        {/* Payment request Component */}
        {isHR &&         
          <HRGrid heading="">
            <PaymentREquestHR PaymentRequestCardArray={PaymentRequestCardArray}/>
          </HRGrid>}

        {/* Work anniversary component */}      
        {isHR 
        ?
          <HRGrid
            heading="Work Anniversary"
            className="font-size-20px bold-600 mb-8px mt-16px"
            sx={{ lineHeight: "28px" }}
          >
            <BirthdayComp
              today={workAnniversaryCompArray?.DashboardWorkAnniversaryToday}
              afterToday={
                workAnniversaryCompArray?.DashboardWorkAnniversaryDayAfterToday
              }
              matchesXL={matchesXL}
              type={2}
              isHr = {isHR}
            />
          </HRGrid>
          :
          <HRGrid
          heading="Work Anniversary"
          className="font-size-20px bold-600 mb-8px mt-16px"
          sx={{ lineHeight: "28px" }}
          >
            <AnniversaryTalent
              today={workAnniversaryCompArray?.DashboardWorkAnniversaryToday}
              matchesXL={matchesXL}
              type={2}
            />
          </HRGrid>
        }

        {/* My Leaves Cmponent */}
        <HRGrid>
          <LeavesDashboard
            auth={auth}
            show_as_talent={auth?.authData?.show_as_talent}
          />
        </HRGrid>

        {/* My Payments Component */}
        <HRGrid>
          <DashboardPayments auth={auth} />
        </HRGrid>
      </Grid>

      {
            <EModal open={openInvite} headervalue="Invite HR to Entera Payroll">
              <InviteHR auth={auth} open={openInvite} setOpen={setOpenInvite} />
            </EModal>
          }
    </Page>
  );
}

export default HrDashboard;

const HRGrid = (props) => {
  const { children, heading = "" } = props;
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
      <ECardDashboard
        className="height-100per position-relative"
        sx={{ padding: "10px 16px", height: "100%"}}
      >
        <ETypographyHeading heading={heading} />
        {children}
      </ECardDashboard>
    </Grid>
  );
};

const HRGrid2Tile = (props) => {
  const { children, heading = "" } = props;
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
      <ECardDashboard
        className="height-100per position-relative"
        sx={{ padding: "10px 16px", height: "100%"}}
      >
        <ETypographyHeading heading={heading} />
        {children}
      </ECardDashboard>
    </Grid>
  );
};

const ETypographyHeading = (props) => {
  const { heading = "" } = props;
  return (
    <ETypography className="font-size-20px bold-600">{heading}</ETypography>
  );
};
