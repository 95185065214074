

import { Grid, Link } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { EButtonOutlined } from '../../BaseComponents/EButtons';
import { FieldContainerCustom } from '../../BaseComponents/EFieldContainer';
import { EFieldLabelBold, ETypography } from '../../BaseComponents/ETypography';
import { numberWithCommas } from '../../utils/formatNumber';
import { EIcon } from '../../BaseComponents/EIcon';
import AttachFile from '@iconify/icons-material-symbols/attach-file';
import { APPROVED_STATUS, CANCELLED_STATUS, IMAGE_URL, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT, PENDING_STATUS } from '../../constants';

const PaymentRequestView = (props) => {
    const { setModalState, auth, selectedData, getPaymentFilterData } = props;

    return (

        <Grid container spacing={2} >
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold>Talent Name </EFieldLabelBold>
                    <ETypography>{selectedData.modalData?.employeeData?.fullname}</ETypography>
                </FieldContainerCustom>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold>Request Amount</EFieldLabelBold>
                    <ETypography>{numberWithCommas(selectedData.modalData?.pay_amount)}</ETypography>
                </FieldContainerCustom>
            </Grid>

            {[PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_ADVANCE].includes(selectedData.modalData?.transaction_category) &&
                         <Grid item xs={12} sm={12} lg={12} xl={12}>
                         <FieldContainerCustom
                             labelwidth={40}
                         >
                             <EFieldLabelBold>Reason</EFieldLabelBold>
                             <ETypography>{selectedData.modalData.remark}</ETypography>
                         </FieldContainerCustom>
                     </Grid>
                    }
           
            {selectedData.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT && <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom className="justify-content-center"
                    labelwidth={40}
                >
                    <EFieldLabelBold>Attachment</EFieldLabelBold>
                    <div>
                    {selectedData.modalData?.reimbursement_attachment ? (
                        <Link target={'_blank'} href={IMAGE_URL +
                            '/' +
                            auth?.authData?.company_id +
                            '/reimbursement/' +
                            selectedData.modalData?.employee_id +
                            '/' +
                            selectedData.modalData?.reimbursement_attachment}>
                            <EIcon icon={AttachFile} />
                        </Link>) : <></>}
                    </div>
                   
                </FieldContainerCustom>
            </Grid>}

            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold>Status</EFieldLabelBold>
                    <ETypography>{
                        selectedData.modalData?.transaction_status == PENDING_STATUS
                            ? 'Pending'
                            : selectedData.modalData?.transaction_status == APPROVED_STATUS
                                ? 'Approved'
                                : selectedData.modalData?.transaction_status == CANCELLED_STATUS ? 'Reject' : 'Paid'
                    }
                    </ETypography>
                </FieldContainerCustom>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                
                >
                    <EFieldLabelBold>Remark</EFieldLabelBold>
                    <ETypography>{selectedData.modalData?.transaction_status_remark ? selectedData.modalData?.transaction_status_remark : '-'}</ETypography>
                </FieldContainerCustom>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalName: null })}
                    > Cancel </EButtonOutlined>


                </Stack>
            </Grid>
        </Grid>

    );
};
export default PaymentRequestView;
