
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DepartmentList } from "../action/DepartmentAction";
import { DesignationList } from "../action/DesignationAction";
import { EmployeeAuthListData } from "../action/EmployeeAuthAction";
import EHeaderBreadcrumbs from "../BaseComponents/EBreadcrum";
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon, EIconButton } from "../BaseComponents/EButtons";
import { EIcon, EIconInputAdornment } from "../BaseComponents/EIcon";
import EModal from "../BaseComponents/EModal";
import Page from "../BaseComponents/EPage";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../BaseComponents/ETable";
import { ACTIVE_USER, DEFAULT_ROWS_PERPAGE, PAGES, PERMISSIONS, ROW_SIZE_PER_PAGE, SUPER_ADMIN, USER_ROLE_FETCH_TYPE } from "../constants";
import editOutlineRounded from '@iconify/icons-material-symbols/edit-outline-rounded'
import checkCircleOutlineRounded from '@iconify/icons-material-symbols/check-circle-outline-rounded'
import cancelOutlineRounded from '@iconify/icons-material-symbols/cancel-outline-rounded'
import { EBoxPage } from "../BaseComponents/EBox";
import EScrollbar from "../BaseComponents/EScrollbar";
import { ECircularProgress } from "../BaseComponents/ECircularProgress";
import SearchNotFound from "../PageComponents/SearchNotFound";
import EChip from "../BaseComponents/EChip";
import { ETablePagination } from "../BaseComponents/ETablePagination";
import { ETypography, ETypographyPageHeading } from "../BaseComponents/ETypography";
import { EDivider } from "../BaseComponents/EDivider";
import AddSuperAdmin from "../PageComponents/userManagement/AddSuperAdmin";
import AddNewUserForm from "../PageComponents/userManagement/AddNewUserForm";
import UserSettingSearchForm from "../PageComponents/userManagement/UserSettingSearchForm";
import { capitalizeCapitalString } from "../utils/formatText";
import EHidden from "../BaseComponents/EHidden";
import { Link } from "react-router-dom";
import base64 from 'base-64'
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import postAddRounded from '@iconify/icons-material-symbols/post-add-rounded';
import personAddOutlineRounded from '@iconify/icons-material-symbols/person-add-outline-rounded';
import passwordResetIcon from '@iconify/icons-material-symbols/lock-reset-rounded';
import KeyIcon from '@iconify/icons-material-symbols/key-rounded';
import UserPasswordChangeForm from "../PageComponents/userManagement/UserPasswordChangeForm";
import { ETooltip } from "../BaseComponents/ETooltip";
import ChangePrimarySuperAdmin from "../PageComponents/userManagement/ChangePrimarySuperAdmin";
import { useCompanySettingProvider } from "../context/CompanySettingContext";
import { useCheckPermission } from "../hooks";
import { getCustomUserRoleData } from "../action/CustomUserRole";

const ActiveLoginCredentials=1

const TABLE_HEAD = [
    { id: 'Name', label: 'Talent Name', alignRight: false },
    { id: 'Role', label: 'Role', alignRight: false, hideLabelCriteria: 'lgDown' },
    { id: 'Reporting Manager', label: 'Reporting Manager', alignRight: false },
    { id: 'Username', label: 'Username', alignRight: false },
    { id: 'Show in Payroll', label: 'Show in Payroll', alignRight: 'center' },
    { id: 'Status', label: 'Status', alignRight: 'center' },
    { id: 'Action', label: 'Action', alignRight: 'center'},
];
/**
 * [2022-11-25]
 * Author: Aanchal Sahu
 **/
function UserManagement(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setting } = useCompanySettingProvider();
    const auth = useSelector(state => state.authReducer);
    const Theme = useSelector(state => state.ThemeReducer)
    const empAuth = useSelector(state => state.EmployeeAuthReducer)

    const [page, setPage] = useState(PAGES);
    const [IsNewUser, setIsNewUser] = useState(false);
    const [IsNewSuperAdmin, setIsNewSuperAdmin] = useState(false);
    const [IsEdit, setIsEdit] = useState(null);
    const [resetPwd, setResetPwd] = useState({open:false,data:null}); //INFO: for change reset password modal 
    const [changePrimarySA, setChangePrimarySA] = useState({open:false,data:null}); //INFO: for change super admin modal
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const [SearchedValues, setSearchedValues] = useState({ name: "", role_object: null, sort: 'id DESC' });



    const company = useSelector(state => state.CompanyReducer);
    const dept = useSelector((state) => state.DepartmentReducer);
    const designation = useSelector((state) => state.DesignationReducer);

    //Setting data
    const companyAccountSetting = setting?.accountSettingData?.companySetting;
    const allowed_talent = companyAccountSetting?.company_data?.CompanyActivePlan?.allowed_talent;
    const active_employee_count = companyAccountSetting?.active_employee_count;


    const allowAddSuperAdmin = useCheckPermission([PERMISSIONS.AddSuperAdmin])
    const allowAddEditCredentials = useCheckPermission([PERMISSIONS.ViewAddEditCredentials])
    const allowChangeEmployeePassword = useCheckPermission([PERMISSIONS.ChangeEmployeePassword])

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(EmployeeAuthListData(auth?.authtoken, ACTIVE_USER, page, rowsPerPage, { ...SearchedValues,role:SearchedValues?.role_object?.value }, navigate))
        }
    }, [page, rowsPerPage, SearchedValues])

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(DepartmentList(auth.authtoken));
            dispatch(DesignationList(auth.authtoken));
            /**
            * INFO: If the logged in user is SUPER ADMIN then fetch all Role otherwise exclude SUPER ADMIN Role
            */
            const params = {
                type: auth?.authData?.role == SUPER_ADMIN ? USER_ROLE_FETCH_TYPE.ALL : USER_ROLE_FETCH_TYPE.WITHOUT_SUPER_ADMIN
            }
            /**
             * INFO: To fetch User Roles
             */
            dispatch(getCustomUserRoleData({ params }))
        }
    }, [auth])

    const handleChangePage = (event, newPage) => {
        setPage(newPage, page, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0, page, rowsPerPage);
    };

    const onSearch = (value) => {
        setSearchedValues(value)
        setPage(0, page, rowsPerPage);
    }

    const setEnvForNewUser = () => {
        setIsEdit(null);
        setIsNewUser(true);

    }

    return (
        <>
            <Page title="User Management">

            <Grid container display="column" justifyContent="space-between" >
                
                <Grid item sm={12} md={'auto'} lg={'auto'} xl={'auto'}>
                    <EHeaderBreadcrumbs
                        heading='User Management'
                        links={[

                            { name: 'Dashboard', href: '/' },
                            {
                                name: 'User Management',
                            }
                        ]}
                    />
                </Grid>
                
                {/* Add super admin, allocate credentials and back button */}
                <EHidden width="mdDown">
                    <Grid item sm={12} md={'auto'} lg={'auto'} xl={'auto'}>
                        {
                            allowAddSuperAdmin &&
                            <EButton variant="contained" onClick={() => { setIsNewSuperAdmin(true) }} disabled={active_employee_count >= allowed_talent} > 
                                Add Super Admin
                            </EButton> 
                        }
                        {
                            allowAddEditCredentials &&
                            <EButton variant="contained" className="button-left-margin" onClick={() => setEnvForNewUser()} > Allocate Credentials </EButton>
                        }
                        <EButtonOutlined className="button-left-margin" variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>

            </Grid>

            <Grid container display="row" justifyContent="space-between" className='pb-2rem margin-top-20px'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>User Management</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        {auth?.authData?.role == SUPER_ADMIN ?
                            <EButtonIcon variant="contained" onClick={() => { setIsNewSuperAdmin(true) }} >
                                <EIcon icon={personAddOutlineRounded} className='height-width-15px' />
                            </EButtonIcon> : ''}
                        <EButtonIcon  onClick={() => setEnvForNewUser()} variant="contained" className='button-left-margin'>
                            <EIcon icon={postAddRounded} className='height-width-15px' />
                        </EButtonIcon>
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
                <EHidden width="smDown">
                    <Grid lg={12} xs={12} item><EDivider className='margin-top-05' /></Grid>
                </EHidden>
            </Grid>
            
            <EBoxPage>

                <UserSettingSearchForm search={SearchedValues} setSearch={onSearch} auth={auth} />

                <EScrollbar>
                    <ETableContainer >
                        <ETable>

                            {empAuth?.empAuthListLoading && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={dept?.departmentListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                    Theme={Theme}
                                />

                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            </>
                            }
                            {!empAuth?.employeeLoading && empAuth?.empAuthListData?.rows?.length > 0 && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={dept?.departmentListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                    Theme={Theme}

                                />
                                <ETableBody>
                                    {empAuth && empAuth?.empAuthListData?.rows?.map((row, index) => {
                                        const { is_active, is_primary, fullname, id, show_as_talent, reportingManager, employee_code, username, role, customRole = null } = row;
                                        return (
                                            <ETableRow hover key={index + fullname} className='icons-on-hover'>
                                                <ETableCell align="left" className="text-transform-capitalize minWidth-210">
                                                    {!is_primary || (is_primary && auth?.authData?.is_primary) ?
                                                        <ETypography className='bold-700 text-transform-capitalize font-size-14px' to={`/talent/${base64.encode(row.id)}`}
                                                            component={Link}>
                                                            {fullname ? fullname : '-'}
                                                        </ETypography> :
                                                        <span> {fullname ? fullname : '-'}</span>
                                                    }
                                                    <br />
                                                    {employee_code ? employee_code : '-'}
                                                </ETableCell>
                                                <EHidden width="lgDown">
                                                    <ETableCell align="left" className="text-transform-capitalize" >
                                                    <span className="display-flex align-center">
                                                    {is_primary ?<EIcon icon={KeyIcon} className="height-width-15px theme-color-static"/>:''}
                                                    {
                                                        customRole?.role_display_name || capitalizeCapitalString(role) || "-"
                                                    }
                                                    </span>
                                                    </ETableCell>
                                                </EHidden>
                                                <ETableCell align="left" className='width-160px text-transform-capitalize'>
                                                    {reportingManager?.fullname ? reportingManager?.fullname : '-'}</ETableCell>
                                                <ETableCell align="left"  >{username ? username : "-"}</ETableCell>
                                                <ETableCell align="center" className="minWidth-100">
                                                    {show_as_talent && show_as_talent == 1 ?
                                                        <EIconInputAdornment icon={checkCircleOutlineRounded} className='icon-green' />
                                                        : <EIconInputAdornment icon={cancelOutlineRounded} className='icon-light-red' />
                                                    }
                                                </ETableCell>
                                                <ETableCell align="center" >
                                                    <EChip label={is_active == 1 ? "Enable" : "Disable"} />
                                                </ETableCell>
                                                <ETableCell align="center" className="minWidth-80">
                                                    {
                                                        allowAddEditCredentials &&
                                                        <ETooltip title='Edit' arrow>
                                                            <span> <EIconButton variant="" onClick={() => { setIsEdit(row); setIsNewUser(true) }}
                                                                disabled={(id != auth?.authData?.id && role == SUPER_ADMIN && is_primary != 1) ||
                                                                    (id != auth?.authData?.id && role != SUPER_ADMIN) ||
                                                                    (role == SUPER_ADMIN && is_primary == 1 && auth?.authData?.role == SUPER_ADMIN && auth?.authData?.is_primary == 1) ?
                                                                    false : true}
                                                            >
                                                                <EIcon icon={editOutlineRounded} />
                                                            </EIconButton> </span>
                                                        </ETooltip>
                                                    }
                                                    {/* Change password icon */}
                                                    {
                                                        allowChangeEmployeePassword &&
                                                        <ETooltip title='Change Password' arrow>
                                                            <span>
                                                                <EIconButton variant="" 
                                                                
                                                                
                                                                onClick={() => { setResetPwd({ open: true, data: { fullname: fullname, id: id ,employee_code: employee_code} }) }}
                                                                    disabled={is_active == ActiveLoginCredentials // when use has no active login password
                                                                    && (!(id != auth?.authData?.id && role == SUPER_ADMIN && is_primary == 1) ||(id == auth?.authData?.id && is_primary == 1 ))
                                                                    
                                                                    ? false : true}
                                                                >
                                                                    <EIcon icon={passwordResetIcon} />
                                                                </EIconButton>
                                                            </span>
                                                        </ETooltip>
                                                    }

                                                    
                                                    {id == auth?.authData?.id && role == SUPER_ADMIN && is_primary &&
                                                        <ETooltip title={<span align='center'>Switch Primary <br />Super Admin</span>} arrow>
                                                            <span>
                                                                <EIconButton variant="" onClick={() => { setChangePrimarySA({ open: true, data: { fullname: fullname, id: id } }) }}>
                                                                    <EIcon icon={KeyIcon} />
                                                                </EIconButton>
                                                            </span>
                                                        </ETooltip>
                                                    }
                                                </ETableCell>
                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            </>}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                
                {/* Rows per page and paging functionality */}
                {empAuth?.empAuthListData?.count && empAuth?.empAuthListData?.count > DEFAULT_ROWS_PERPAGE ?

                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={empAuth?.empAuthListData?.count ? empAuth?.empAuthListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : <></>}
                {!empAuth?.employeeLoading && empAuth?.empAuthListData?.rows?.length == 0 && (
                    <SearchNotFound />
                )}
            </EBoxPage>

            {/* Modal for Update User Credentials & Allocate Credentials */}
            {IsNewUser &&
                <EModal open={IsNewUser} close={() => setIsNewUser(false)} headervalue={IsEdit ? "Update User Credentials" : "Allocate Credentials"}>
                    <AddNewUserForm search={SearchedValues} open={IsNewUser} close={() => setIsNewUser(false)} isEdit={IsEdit}
                        auth={auth} loadingButton={empAuth?.empAuthLoading} dept={dept} designation={designation} />
                </EModal>
            }

            {/* Modal for Add Super Admin */}
            {IsNewSuperAdmin &&
                <EModal open={IsNewSuperAdmin} close={() => setIsNewSuperAdmin(false)} headervalue="Add Super Admin" >
                    <AddSuperAdmin empAuth={empAuth} search={SearchedValues} open={IsNewUser}
                        close={() => { setIsNewSuperAdmin(false); }} isEdit={IsEdit} company={company} dept={dept} designation={designation} auth={auth} />
                </EModal>
            }

            {/* Modal for Change password */}
            {resetPwd.open &&
                <EModal open={resetPwd.open} close={() => setResetPwd({open:false,data:null})} headervalue="Confirmaton : Change Password" >
                    <UserPasswordChangeForm 
                    loading={empAuth?.empAuthLoading} 
                    search={SearchedValues}
                    data={resetPwd.data}
                    close={() => { setResetPwd({open:false,data:null}) }} 
                    auth={auth}
                    />
                </EModal>
            }

            {/* Modal for Change Primary Super Admin*/}
            {changePrimarySA.open &&
                <EModal open={changePrimarySA.open} close={() => setChangePrimarySA({open:false,data:null})} headervalue="Change Primary Super Admin" >
                    <ChangePrimarySuperAdmin 
                    loading={empAuth?.empAuthLoading} 
                    search={SearchedValues} 
                    data={changePrimarySA.data}
                    close={() => { setChangePrimarySA({open:false,data:null}) }} 
                    auth={auth}
                    />
                </EModal>
            }
            
        </Page>
        </>
    );
}


export default UserManagement
