import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useFormik, Form, FormikProvider, Field } from 'formik';
import { UpdateContractDetailsValidation } from "../../../utils/validation";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useDispatch } from "react-redux";
import moment from "moment";
import { GST_SLAB_OPTIONS, GST_TYPE_OPTIONS } from "../../../constants";
//actions
import { PaymentTermsCreateUpdate } from "../../../action/ContractAction";
//base components
import { EButtonOutlined, ELoadingButton } from "../../../BaseComponents/EButtons";
import { ELabelInputMainGrid } from "../../../BaseComponents/EGrid";
import { ETextField } from "../../../BaseComponents/ETextField";
import { EDatePicker } from "../../../BaseComponents/EDatePicker";

const EditContractDetails = (props) => {
    const { auth, close,contract } = props;
    const dispatch = useDispatch();
    const contractDetails=contract?.contractData
    const [dates, setDates] = useState({ start: contractDetails?.contract_start_date ? contractDetails?.contract_start_date : null, end: contractDetails?.contract_end_date ? contractDetails?.contract_end_date : null })

    const formik = useFormik({
        initialValues: {
            contract_title: contractDetails?.contract_title ? contractDetails?.contract_title : '',
            contract_start_date: contractDetails?.contract_start_date ? contractDetails?.contract_start_date : null,
            contract_end_date: contractDetails?.contract_end_date ? contractDetails?.contract_end_date : null,
            },
        validationSchema: UpdateContractDetailsValidation,
        onSubmit: (data) => {
            data = { ...data, contract_id: contractDetails?.id, total_amount: data.contract_with_gst }
            dispatch(PaymentTermsCreateUpdate(auth?.authtoken, data, close))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;

    useEffect(() => {
        if (contractDetails) {
            if (contractDetails?.gst) {
                let find_gst = GST_SLAB_OPTIONS?.find(element => element.id == Number(contractDetails?.gst));
                setFieldValue('gst', { id: find_gst?.id, label: find_gst?.label })
            } else {
                setFieldValue('gst', null)
            }
            if (contractDetails?.is_igst!=null) {

                let find_gst = GST_TYPE_OPTIONS?.find(element => element.id == Number(contractDetails?.is_igst));
                setFieldValue('is_igst', { id: find_gst?.id, label: find_gst?.label })
            } else {
                setFieldValue('is_igst', null)
            }
        }
    }, [contractDetails]);

    const onKeyDown = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <ELabelInputMainGrid label={'Contract Name'} isfullgrid={true}>
                                    <ETextField
                                        fullWidth
                                        autoComplete="contract_title"
                                        {...getFieldProps('contract_title')}
                                        error={Boolean(touched.contract_title && errors.contract_title)}
                                        helperText={touched.contract_title && errors.contract_title}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} xl={6}>
                                <ELabelInputMainGrid label={'Start Date'} >
                                    <EDatePicker
                                        name="contract_start_date"
                                        {...getFieldProps('contract_start_date')}
                                        maxDate={new Date(moment().add(1, 'months').calendar())}  //start date must not be more that a month
                                        inputFormat="dd/MM/yyyy"
                                        disablePast
                                        value={dates?.start}
                                        onChange={(selectedDate) => {
                                            setFieldValue("contract_start_date", selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null)
                                            setDates({ ...dates, start: selectedDate ? selectedDate : null })
                                        }}
                                        renderInput={(params) => (
                                            <Field component={ETextField}
                                                {...params} fullWidth name="contract_start_date"
                                                onKeyDown={onKeyDown}  
                                                error={Boolean(touched.contract_start_date && errors.contract_start_date)}
                                                helperText={touched.contract_start_date && errors.contract_start_date}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} xl={6}>
                                <ELabelInputMainGrid label={'End Date'} >
                                    <EDatePicker
                                        name="contract_end_date"
                                        {...getFieldProps('contract_end_date')}
                                        minDate={formik?.values?.contract_start_date ? moment(formik?.values?.contract_start_date).add(1, 'days').toDate() : new Date()}
                                        inputFormat="dd/MM/yyyy"
                                        value={dates?.end}
                                        onChange={(selectedDate) => {
                                            setFieldValue("contract_end_date", selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null)
                                            setDates({ ...dates, end: selectedDate ? selectedDate : null })
                                        }}
                                        renderInput={(params) => (
                                            <Field component={ETextField} {...params} fullWidth name="contract_end_date"
                                            onKeyDown={onKeyDown}  
                                                error={Boolean(touched.contract_end_date && errors.contract_end_date)}
                                                helperText={touched.contract_end_date && errors.contract_end_date}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12} className="modal1-buttons-stick-bottom">
                                <Stack direction="row" spacing={2}>
                                    <EButtonOutlined
                                        size="large"
                                        variant="outlined"
                                        onClick={() => {
                                            close();
                                            resetForm();
                                        }}
                                    >
                                        {' '}
                                        Cancel{' '}
                                    </EButtonOutlined>
                                    <ELoadingButton
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    loading={contract?.contractLoading}
                                    >
                                        Update
                                    </ELoadingButton>

                                </Stack>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Form>
            </FormikProvider>
        </>
    )
};

export default EditContractDetails;
