import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButtonOutlined, EButtonOutlinedIcon } from "../../BaseComponents/EButtons";
import Page from "../../BaseComponents/EPage";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { EIcon } from '../../BaseComponents/EIcon';
import { DEFAULT_ROWS_PERPAGE, PAGES, PENDING_STATUS, ROW_SIZE_PER_PAGE, TERMINATED_STATUS } from "../../constants";
import { EBoxPage } from "../../BaseComponents/EBox";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import EChip from "../../BaseComponents/EChip";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import { ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { EDivider } from "../../BaseComponents/EDivider";
import { ContractViewData } from "../../action/ContractAction";
import moment from "moment";
import base64 from 'base-64'
import { capitalizeCapitalString } from "../../utils/formatText";
import ViewInchargeCard from "../../PageComponents/FreelancerContracts/Contracts/ViewInchargeCard";
import EModal from "../../BaseComponents/EModal";
import ModalInchargeDeliverableDetails from "./ModalInchargeDeliverableDetails";
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import { ETooltip } from "../../BaseComponents/ETooltip";
import EHidden from "../../BaseComponents/EHidden";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';

const TABLE_HEAD = [
    { id: 'SNo', label: 'SNo.', alignRight: 'center' },
    { id: 'Deliverable', label: 'Deliverable', alignRight: false },
    { id: 'Description', label: 'Description', alignRight: false },
    { id: 'Submitted On ', label: 'Submitted On', alignRight: 'center' },
    { id: 'Status', label: 'Status', alignRight: 'center' },
];

function InchargeView() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authReducer);
    const [deliverableDetails, setDeliverableDetails] = useState({ open: false, data: {} });
    const [page, setPage] = useState(PAGES);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const contract = useSelector(state => state.ContractReducer)
    const contractData = contract?.contractData
    const params = useParams();
    const id = params && params.id ? base64.decode(params.id) : '';

    let currDate = moment().format().split('T')[0];
    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(ContractViewData(auth?.authtoken, id))
        }
    }, [page, rowsPerPage])

    const handleChangePage = (event,newPage) => {
        setPage(newPage, page, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0, page, rowsPerPage);
    };
    const HandleViewClick = (row) => {
        setDeliverableDetails({ open: true, data: row })
    }
    return (
        <Page title="Incharge View">
            <Grid container display="row" justifyContent="space-between" >
                <EHeaderBreadcrumbs
                    heading={contractData?.contract_code? contractData?.contract_code:''}
                    links={[
                        { name: 'Dashboard', href: '/' },
                        { name: 'Freelancer Contract', href: '/freelancer-contract/incharge' },
                        { name: 'Incharge', href: '/freelancer-contract/incharge' },
                        { name: contractData?.contract_code? contractData?.contract_code:''}
                    ]}
                />
                <EHidden width="mdDown">
                <Grid>
                    <EButtonOutlined className="button-left-margin" variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                </Grid>
                </EHidden>
            </Grid>
            <Grid container display="row" justifyContent="space-between" className='pb-2rem margin-top-20px'>
            <EHidden width="mdUp">
            <Grid item>
                    <ETypographyPageHeading variant='h5'>Incharge</ETypographyPageHeading>
                </Grid>
                </EHidden>
               
                <EHidden width="mdDown">
                <ETypographyPageHeading variant='h5'>Contract Number - {contractData?.contract_code}</ETypographyPageHeading>
                </EHidden>
                <EHidden width="mdUp">
                <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                </EHidden>
                <EHidden width="mdDown">
                <Grid lg={12} xs={12} item><EDivider className='margin-top-05' /></Grid>
                </EHidden>
            </Grid>
            <EBoxPage>
                <ViewInchargeCard contractData={contractData} />
                <EScrollbar>
                    <ETableContainer >
                        <ETable>

                            {contract?.contractListLoading && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={contract?.contractListData?.rows?.length?contract?.contractListData?.rows?.length:0}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                />

                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            </>
                            }
                            {!contract?.contractListLoading && contractData?.WorkDetail?.length > 0 &&
                                <>
                                    <EListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={contractData?.WorkDetail?.length}
                                        onRequestSort={() => null}
                                        authvar={auth}
                                    />
                                    <ETableBody>

                                        {contractData?.WorkDetail && contractData?.WorkDetail?.map((row, index) => {
                                            const { id, status, work_detail, work_title, updated_at, incharge_remark } = row;
                                            let dateCheck = moment(currDate).isSameOrAfter(contractData?.contract_start_date)
                                            return (
                                                <ETableRow hover key={`${index} - ${id}`} className='cursor-pointer' 
                                                onClick={() => {dateCheck &&  contractData.status != TERMINATED_STATUS  && HandleViewClick(row)}}>
                                                    <ETableCell align="center" className="width-10px"> {index + 1}</ETableCell>
                                                    <ETableCell align="left" sx={{ minWidth: '150px' }}>
                                                        {work_title ? work_title : '-'}
                                                    </ETableCell>
                                                    <ETableCell >
                                                        {work_detail ? work_detail : '-'}
                                                    </ETableCell>
                                                    <ETableCell align="center" sx={{ minWidth: '110px' }}>
                                                         {status != PENDING_STATUS ? moment(updated_at).format('DD/MM/YYYY') : '-'} 
                                                    </ETableCell>
                                                    <ETableCell align="center" className="width-10px" >
                                                        <span className="display-flex justify-content-center">
                                                            <EChip label={capitalizeCapitalString(status)} width={"110px"} />
                                                            {status != PENDING_STATUS && incharge_remark ?
                                                                <ETooltip title={incharge_remark} arrow>
                                                                    <EIcon icon={InfoIcon} className='grey-color' /> 
                                                                </ETooltip>
                                                                :'' }
                                                        </span>
                                                    </ETableCell>
                                                </ETableRow>
                                            )
                                        })}
                                    </ETableBody>
                                </>}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {!contract?.contractListLoading && contractData?.WorkDetail?.length > DEFAULT_ROWS_PERPAGE ?
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={contractData?.WorkDetail?.length ? contractData?.WorkDetail?.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : <></>}
                {!contract?.contractListLoading && contractData?.WorkDetail?.length == 0 && (
                    <SearchNotFound />
                )}
                {deliverableDetails.open &&
                    <EModal
                        open={deliverableDetails.open}
                        close={() => setDeliverableDetails({ ...deliverableDetails, open: false })}
                        headervalue='Deliverable Details'
                    >
                        <ModalInchargeDeliverableDetails close={() => setDeliverableDetails({ ...deliverableDetails, open: false })} row={deliverableDetails?.data} auth={auth} />
                    </EModal>
                }
            </EBoxPage>
        </Page>
    );
}


export default InchargeView