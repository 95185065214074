

import { Grid } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { EButtonOutlined } from '../../BaseComponents/EButtons';
import { FieldContainerCustom } from '../../BaseComponents/EFieldContainer';
import { EFieldLabelBold, ETypography } from '../../BaseComponents/ETypography';
import { numberWithCommas } from '../../utils/formatNumber';
import { ECard } from '../../BaseComponents/ECard';
import moment from 'moment';

const AppraisalViewModal = (props) => {
    const { setModalState, auth, modalState } = props;


    return (

        <Grid container spacing={2} >
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <ECard className='modal-info-box width-100'>
                    {['DESIGNATION', 'BOTH'].includes(modalState.modalData?.appraisal_type) &&
                        <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>
                            Promoted To - {modalState.modalData?.designationInfo?.designation_name}
                        </ETypography>}
                    {['SALARY', 'BOTH'].includes(modalState.modalData?.appraisal_type) &&
                        <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>
                            Updated CTC - {numberWithCommas(modalState.modalData?.amount_after_appraisal)}
                        </ETypography>}
                    <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>
                        Scheduled Date - {moment(modalState.modalData?.from_date).format('DD/MM/YYYY')}
                    </ETypography>
                </ECard>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold> Name </EFieldLabelBold>
                    <ETypography>{modalState.modalData?.Employee?.fullname}</ETypography>
                </FieldContainerCustom>
            </Grid>

            {['DESIGNATION', 'BOTH'].includes(modalState.modalData?.appraisal_type) && <>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <FieldContainerCustom
                        labelwidth={40}
                    >
                        <EFieldLabelBold> Current Department </EFieldLabelBold>
                        <ETypography>{modalState.modalData?.departmentInfo?.department_name}</ETypography>
                    </FieldContainerCustom>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <FieldContainerCustom
                        labelwidth={40}
                    >
                        <EFieldLabelBold> Current Designation </EFieldLabelBold>
                        <ETypography>{modalState.modalData?.designationInfo?.designation_name}</ETypography>
                    </FieldContainerCustom>
                </Grid>
            </>}
            {['SALARY', 'BOTH'].includes(modalState.modalData?.appraisal_type) && <>

                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <FieldContainerCustom
                        labelwidth={40}
                    >
                        <EFieldLabelBold> Increment Amount <br /><small className='small-text'>(Per Month)</small> </EFieldLabelBold>
                        <ETypography>{numberWithCommas(modalState.modalData?.appraisal_amount)}</ETypography>
                    </FieldContainerCustom>
                </Grid>

                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <FieldContainerCustom
                        labelwidth={40}
                    >
                        <EFieldLabelBold> Updated CTC <br /><small className='small-text'>(Per Month)</small> </EFieldLabelBold>
                        <ETypography>{numberWithCommas(modalState.modalData?.amount_after_appraisal)}</ETypography>
                    </FieldContainerCustom>
                </Grid>
            </>}




            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold> Remark </EFieldLabelBold>
                    <ETypography>{modalState.modalData?.remark}</ETypography>
                </FieldContainerCustom>
            </Grid>
            {/* <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold>Talent Name </EFieldLabelBold>
                    <ETypography>{selectedData.modalData?.employeeData?.fullname}</ETypography>
                </FieldContainerCustom>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold>Request Amount</EFieldLabelBold>
                    <ETypography>{numberWithCommas(selectedData.modalData?.pay_amount)}</ETypography>
                </FieldContainerCustom>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold>Reason</EFieldLabelBold>
                    <ETypography>{selectedData.modalData.remark}</ETypography>
                </FieldContainerCustom>
            </Grid>
            {selectedData.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT && <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold>Attachment</EFieldLabelBold>
                    {selectedData.modalData?.reimbursement_attachment ? (
                        <Link target={'_blank'} href={IMAGE_URL +
                            '/' +
                            auth?.authData?.company_id +
                            '/reimbursement/' +
                            selectedData.modalData?.employee_id +
                            '/' +
                            selectedData.modalData?.reimbursement_attachment}>
                            <EIcon icon={AttachFile} />
                        </Link>) : <></>}
                </FieldContainerCustom>
            </Grid>}

            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold>Status</EFieldLabelBold>
                    <ETypography>{
                        selectedData.modalData?.transaction_status == PENDING_STATUS
                            ? 'Pending'
                            : selectedData.modalData?.transaction_status == APPROVED_STATUS
                                ? 'Approved'
                                : selectedData.modalData?.transaction_status == CANCELLED_STATUS ? 'Reject' : 'Paid'
                    }
                    </ETypography>
                </FieldContainerCustom>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <FieldContainerCustom
                    labelwidth={40}
                >
                    <EFieldLabelBold>Remark</EFieldLabelBold>
                    <ETypography>{selectedData.modalData?.transaction_status_remark ? selectedData.modalData?.transaction_status_remark : '-'}</ETypography>
                </FieldContainerCustom>
            </Grid>
            */}
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalName: null })}
                    > Cancel </EButtonOutlined>


                </Stack>
            </Grid>
        </Grid>

    );
};
export default AppraisalViewModal;
