import {Grid, IconButton } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { ETypographyCardHeading } from "../../BaseComponents/ETypography";
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded';
import { ETextField } from "../../BaseComponents/ETextField";
import { EIcon } from "../../BaseComponents/EIcon";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";


const AddFreelancerFormDocuments = (props) => {
    const { formikFreelancer } = props;
    
    const SetPanFileFunction = (e) => {
        if (e.target.files[0]) {
            setFieldValue("pan_file_name", e.target.files[0])
        }
    }

    const AdhaarFileFunction = (e) => {
        if (e.target.files[0]) {
            setFieldValue("aadhar_file_name", e.target.files[0])
        }
    }

    //Function for passport file upload
    const SetPassportFunction = (e) => {
        if (e.target.files[0]) {
            setFieldValue("passport_file", e.target.files[0])
        }
    }

    //Function for driving liscence file upload
    const SetDrivingLicenceFunction = (e) => {
        if (e.target.files[0]) {
            setFieldValue("driving_lic_file", e.target.files[0])
        }
    }

    const { errors, touched, getFieldProps, setFieldValue } = formikFreelancer;

    return (

        <ECard className={formikFreelancer?.values?.document_checked ? 'card_design_1' : 'card_design_2'}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ETypographyCardHeading variant='h5'>
                    Documents
                </ETypographyCardHeading>
                <IconButton
                    onClick={() => setFieldValue('document_checked', !getFieldProps('document_checked').value)}>
                    <EIcon icon={formikFreelancer?.values?.document_checked ? minusBox : plusBox} />
                </IconButton>
            </Grid>

            {getFieldProps('document_checked').value && <>
                <EDivider className="mb-16px" />
                <Grid container className='px-16px' spacing={2} >
                  
                     {/* //aadhar_no pan_no passport_no driving_lic_no  */}
                     <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={' Aadhar Card'}>
                            <ETextField
                                fullWidth
                                placeholder="Document Number"
                                {...getFieldProps('aadhar_no')}
                                error={Boolean(touched.aadhar_no && errors.aadhar_no)}
                                helperText={touched.aadhar_no && errors.aadhar_no}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} className='docs-responsive-display display-flex'>
                        <ETextField fullWidth type="file"
                            inputProps={{
                                accept: 'image/jpg, image/jpeg'
                            }}
                            className="input-file-selector"
                            onChange={(e) => { AdhaarFileFunction(e) }}
                            error={Boolean(touched.aadhar_file_name && errors.aadhar_file_name)}
                            helperText={touched.aadhar_file_name && errors.aadhar_file_name}
                        />
                    </Grid>

                    {/* Pan Card */}
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Pan Card'}>
                            <ETextField
                                fullWidth
                                placeholder="Document Number"
                                onKeyUp={(e)=>{setFieldValue('pan_no',e?.target?.value? e?.target?.value?.toUpperCase():'')}}
                                {...getFieldProps('pan_no')} 
                                error={Boolean(touched.pan_no && errors.pan_no)}
                                helperText={touched.pan_no && errors.pan_no}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} className='docs-responsive-display display-flex'>
                        <ETextField fullWidth type="file"
                            inputProps={{
                                accept: 'image/jpg, image/jpeg'
                            }}
                            className="input-file-selector"
                            onChange={(e) => { SetPanFileFunction(e) }}
                            error={Boolean(touched.pan_file_name && errors.pan_file_name)}
                            helperText={touched.pan_file_name && errors.pan_file_name}
                        />
                    </Grid>

                    {/* Passport */}
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Passport'}>
                            <ETextField
                                fullWidth
                                placeholder="Document Number"
                                onKeyUp={(e)=>{setFieldValue('passport_no',e?.target?.value? e?.target?.value?.toUpperCase():'')}}
                                {...getFieldProps('passport_no')}
                                error={Boolean(touched.passport_no && errors.passport_no)}
                                helperText={touched.passport_no && errors.passport_no}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} className='docs-responsive-display display-flex'>
                        <ETextField fullWidth type="file"
                            inputProps={{
                                accept: 'image/jpg, image/jpeg'
                            }}
                            className="input-file-selector"
                            onChange={(e) => { SetPassportFunction(e) }}
                            error={Boolean(touched.passport_file && errors.passport_file)}
                            helperText={touched.passport_file && errors.passport_file}
                        />
                    </Grid>

                    {/* Driving Licence */}
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Driving Licence'}>
                            <ETextField
                                fullWidth
                                placeholder="Document Number"
                                onKeyUp={(e)=>{setFieldValue('driving_lic_no',e?.target?.value? e?.target?.value?.toUpperCase():'')}}
                                {...getFieldProps('driving_lic_no')}
                                error={Boolean(touched.driving_lic_no && errors.driving_lic_no)}
                                helperText={touched.driving_lic_no && errors.driving_lic_no}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} className='docs-responsive-display display-flex'>
                        <ETextField fullWidth type="file"
                            inputProps={{
                                accept: 'image/jpg, image/jpeg'
                            }}
                            className="input-file-selector"
                            onChange={(e) => { SetDrivingLicenceFunction(e) }}
                            error={Boolean(touched.driving_lic_file && errors.driving_lic_file)}
                            helperText={touched.driving_lic_file && errors.driving_lic_file}
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} className='small-text-label greyColor4-color'>
                        * Allowed format is pdf or jpeg/jpg
                    </Grid>
                </Grid>
            </>}
        </ECard>
    )

};

export default AddFreelancerFormDocuments;