import React, { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard-layout';

import AuthLayout from './layouts/auth-layout';
import Department from './pages/Master/Department';
import Login from './pages/auth/login';
import ChangePassword from './pages/auth/change-password';
import ResetPassword from './pages/auth/reset-password';
import SingnUp from './pages/auth/sign-up';
import EmailVerificationSuccess from './pages/email-verification-success';


import Designation from './pages/Master/Designation';
// import Dashboard from './pages/dashboard';
// import CompanySetting from './pages/company/company-setting'; //INFO(14-3-2023)=> commenting because it was not getting used
import UserManagement from './pages/UserManagement';
import FAQs from './pages/FAQs';
import Holiday from './pages/company/holiday';
import CompanyProfile from './pages/company/company-profile';
import AccountSetting from './pages/Settings/account-settings';
import ViewTalent from './pages/Talent/view-talent';
import Attendance_attendanceLogs from './pages/Attendance/Attendance_attendanceLogs';
import TalentList from './pages/Talent/talent-list';
import AddTalent from './pages/Talent/add-talent';
import { ACTIVE_USER, RELEASED_USER, INACTIVE_USER, REPORT_BY_DATE, REPORT_BY_TALENT, REPORT_BY_TIME, SUPER_ADMIN, USER_LOGIN_STATUS, PERMISSIONS, ROLE_HR } from './constants';
import Attendance_leaveLogs from './pages/Attendance/Attendance_leaveLogs';
import AttendanceList from './pages/Attendance/AttendanceList';
import AttendanceSheet from './pages/Attendance/AttendanceSheet';
import Reports from './pages/Report/Reports';
import ReportsResults from './pages/Report/ReportsResults';
import Salary from './pages/Payment/Salary';
import PaymentRequest from './pages/Payment/PaymentRequest';
import TalentPayment from './pages/Payment/TalentPayment';
import PerformancePayment from './pages/Payment/PerformancePayment';
import FreelancerList from './pages/Freelancer/FreelancerList';
import AddFreelancer from './pages/Freelancer/add-freelancer';
import ViewFreelancer from './pages/Freelancer/view-freelancer'
import ContractList from './pages/Contract/ContractList';
import AddNewContract from './pages/Contract/AddNewContract';
import PaymentLog from './pages/Payment/PaymentLog';
import ContractView from './pages/Contract/ContractView';
import SupportTickets from './pages/Support/SupportTickets';
import SupportTicketDetail from './pages/Support/SupportTicketDetail';
import UserChangePassword from './pages/Profile/UserChangePassword';
// import Blogs from './pages/Blogs'; //INFO(14-3-2023)=> commenting because it was not getting used

import ContractTerminatePage from './pages/Contract/ContractTerminatePage';
import InchargeList from './pages/Contract/InchargeList';
import FreelancerPayment from './pages/Payment/FreelancerPayment';
import InchargeView from './pages/Contract/InchargeView';
import TnCList from './pages/TnC/TnCList';
import Subscription from './pages/auth/subscription';
import CompanySetup from './pages/Dashboard/CompanySetup';
import { RegularizationRequest } from './pages/Attendance/Regularization_request';
import LeaveMaster from './pages/Master/LeaveMaster';
import ReleaseTalent from './pages/Talent/release-talent';
import CompanyOnboardingProfile from './pages/company/company-onboarding-profile';
import AdditionalSettings from './pages/Settings/additional-settings';
import LeaveSettings from './pages/Settings/leave-settings';
import PayrollSettings from './pages/Settings/payroll-settings';
import AttendanceSettings from './pages/Settings/AttendanceSettings';
import { initiateSocket } from './utils/socket';
import NotificationDetail from './pages/Notification/notfication_detail';
import NotificationSetting from './pages/Notification/notification-setting';
import TermsAndCondition from './pages/terms-and-condition';
import PrivacyPolicy from './pages/privacy-policy';
import Birthday from './pages/birthday'
import BulkTalentUpload from './PageComponents/Talents/BulkTalentUpload';
import PayrollReport from './pages/Report/Payroll';
import TalentSettings from './pages/Settings/talent-settings';
import { useCompanySettingProvider } from './context/CompanySettingContext';
import PaymentSetting from './pages/Settings/payment-settings';
import UpdateCredentials from './pages/auth/UpdateCredentials';
// import HrDashboard from './pages/HrDashboard/HrDashboard';
import HrDashboard from './pages/Dashboard/HrDashboard';
import { AddCustomUserRole, CustomUserRoleList } from './pages/Settings/CustomUserRole';
import { isPermissionsAllowed } from './utils/CommonFunctions';
import { useCallback } from 'react';

import OnboardingWelcome from './pages/Dashboard/Onboarding/onboarding-welcome';
import OnboardingOptions from './pages/Dashboard/Onboarding/onboarding-options';
import WatchDemo from './pages/Dashboard/Onboarding/WatchDemo';
import ScheduleACall from './pages/Dashboard/Onboarding/schedule-a-call';
import OnboardingThankyouResponse from './pages/Dashboard/Onboarding/onboarding-thankyou-response';
import AddHrProfile from './pages/Dashboard/Onboarding/add-hr-form';
import MailNotVerified from './pages/auth/MailNotVerified';

// import AddHrProfile from './pages/Dashboard/Onboarding/AddHrProfile';
// initiateSocket

/**
 * PROTECTED ROUTES (These routes require login to access. If the user is not logged in, they will be redirected to UNPROTECTED ROUTES.)
 *    1. IF (loginStatus === USER_LOGIN_STATUS.NORMAL_LOGIN) i.e. normal login
 *        THEN
 *          a. IF onBoardingSetup is FALSE means the user has not completed the company onboarding process 
 *              THEN Only limited routes for company setup will be shown, and not the complete dashboard.
 *          b. IF onBoardingSetup  is TRUE means the company onboarding process is completed 
 *              THEN The dashboard routes will be shown based on the USER'S PERMISSION.
 *    2. IF loginStatus is anything else (i.e., either the user is logging in for the first time or has requested to reset the password),
 *        THEN
 *          ONLY SHOW /auth/update-credentials ROUTE
 * 
 * UNPROTECTED ROUTES (These routes do not require login to access. If the user is already logged in, they will be redirected to PROTECTED ROUTES.)
 *    '/login'
 *    '/forget-password'
 *    '/reset-password/:key'
 *    '/sign-up'
 *    '/subscription'
 *    '/terms-and-condition'
 *    '/privacy-policy'
 */
export default function Router(props) {
  const { auth, company } = props
  // Getting settings context
  const settingsContext = useCompanySettingProvider()
  const TalentSetting = settingsContext?.setting?.talentSettingData?.companySetting

  const onBoardingSetup = company.companyData?.onBoarding?.onboarding_is_completed ? true : false;
  const isSuperAdmin = auth?.authData?.role == SUPER_ADMIN ? true : false
  const isPrimarySuperAdmin = auth?.authData?.is_primary
  const loginStatus = auth?.authData?.login_status
  const explore_welcome_initial_category_hr = company.companyData?.onBoarding?.explore_welcome_initial_category_hr

  const explore_welcome_initial_category_superadmin = company?.companyData?.onBoarding?.explore_welcome_initial_category_superadmin

  // const role = ROLE_HR
  const role = auth?.authData?.role 

  const Permission = auth?.authData?.permission

  // useEffect(() => {
  //   if (auth.authtoken)
  //     initiateSocket(auth.authtoken, auth.authData);
  // }, [auth.authtoken]);

  /**
   * Check permissions and render the component or navigate to a fallback path
   *
   * @param {Array} allowedPermissions Array of permissions to check
   * @param {*} component The component to render if permissions are granted
   * @param {Object} options Object containing optional parameters
   *    - navigateTo: The fallback path to navigate if permissions are not granted (default: "/login")
   *    - extraCheck: Flag indicating whether to perform an additional check (default: false)
   * @returns The rendered component or navigation element
   */
  const checkPermissionsAndRender = useCallback((allowedPermissions = [], component, { navigateTo = "/login", extraCheck = false } = {} ) => {
    // Check if the user has the required permissions
    const hasPermissions = Boolean(auth.authtoken) && Boolean(auth?.authData?.permission) && isPermissionsAllowed(auth?.authData?.permission, allowedPermissions)
    
    // Render the component if permissions are granted, otherwise navigate to fallback path
    return (hasPermissions || extraCheck) ? component : <Navigate to={navigateTo} />
  }, [auth]);

  let routeList = [];

  routeList.push({
    path: '/email-verification-success/:token',
    element: <EmailVerificationSuccess />
  });
  
  /**
   * PROTECTED ROUTES
   */
  if (loginStatus === USER_LOGIN_STATUS.NORMAL_LOGIN ) {

    !onBoardingSetup && role==ROLE_HR && !explore_welcome_initial_category_hr  && routeList.push(
      {path: "/",
      element:  <AuthLayout />,
      children:[
        { path: "/", element: <OnboardingWelcome auth={auth} /> },
        { path: "/onboarding-options", element: <OnboardingOptions auth={auth} /> },
        { path: "/schedule-a-call", element: <ScheduleACall auth={auth} company={company} /> },
        { path: "/watch-demo", element: <WatchDemo auth={auth} /> },
        { path: "/thankyou-for-response", element: <OnboardingThankyouResponse auth={auth}  /> },
      ]
     } );

     !onBoardingSetup && role==SUPER_ADMIN && !explore_welcome_initial_category_superadmin  && routeList.push(
      {path: "/",
      element:  <AuthLayout />,
      children:[
        { path: "/", element: <OnboardingWelcome auth={auth} /> },
        { path: "/onboarding-options", element: <OnboardingOptions auth={auth} /> },
        { path: "/schedule-a-call", element: <ScheduleACall auth={auth} /> },
        { path: "/watch-demo", element: <WatchDemo auth={auth} /> },      
        { path: "/thankyou-for-response", element: <OnboardingThankyouResponse auth={auth}  /> }

      ]
     } );

    !onBoardingSetup && role==ROLE_HR && explore_welcome_initial_category_hr && routeList.push({
      path: '/',
      element: Boolean(auth.authtoken) ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [     
        { path: "/", element: <Navigate to="/setup" replace /> },
        { path: "/setup", element: <CompanySetup auth={auth} /> },
        { path: "/setup/company-profile", element: <CompanyOnboardingProfile auth={auth} /> },
        { path: '/my-profile', element: <ViewTalent auth={auth} myProfile={true} /> },
        { path: '/add-hr-profile', element: <AddHrProfile auth={auth}  /> },
        { path: '/change-password', element: <UserChangePassword auth={auth} /> },
        { path: '/add-talent', element: <AddTalent auth={auth} /> },
        { path: '/talent/bulk-talent-upload', element: <BulkTalentUpload auth={auth} userStatus={ACTIVE_USER} /> },
        { path: "/schedule-a-call", element: <ScheduleACall auth={auth} /> },
        { path: "/watch-demo", element: <WatchDemo auth={auth} /> }, 
        { path: "/setup/onboarding-options", element: <OnboardingOptions auth={auth} /> },
        { path: "*", element: <Navigate to="/setup" replace /> },
      ]
    });

    !onBoardingSetup && role==SUPER_ADMIN && explore_welcome_initial_category_superadmin && routeList.push({
      path: '/',
      element: Boolean(auth.authtoken) ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [     
        //Dashboard
        { path: "/", element: <Navigate to="/setup" replace/> },
        { path: "/setup", element: <CompanySetup auth={auth} /> },
        { path: "/setup/onboarding-options", element: <OnboardingOptions auth={auth} /> },
        { path: "/setup/company-profile", element: <CompanyOnboardingProfile auth={auth} /> },
        { path: '/change-password', element: <UserChangePassword auth={auth} /> },
        { path: "/schedule-a-call", element: <ScheduleACall auth={auth} /> },
        { path: "/watch-demo", element: <WatchDemo auth={auth} /> }, 
        { path: "*", element: <Navigate to="/setup" replace /> },
      ]
    });


    onBoardingSetup && routeList.push(
      //All routes without any specific permissions will be here.
      {
        path: '/',
        element: Boolean(auth.authtoken) ? <DashboardLayout /> : <Navigate to="/login" />,
        children: [
          //Dashboard
          { path: "/", element: <Navigate to="/dashboard" replace /> },
          
          // { path: "/dashboard", element: <Dashboard auth={auth} /> },
        { path: "/dashboard", element: <HrDashboard auth={auth} /> },
          { path: "/birthday/:id", element: <Birthday auth={auth} /> },
          //Settings 
          { path: "/setting/holiday", element: <Holiday /> },
          //Settings 
          { path: "/setting/company-profile", element: <CompanyProfile auth={auth} /> },

          //User
          { path: '/my-profile', element: <ViewTalent auth={auth} myProfile={true} /> },
          { path: '/change-password', element: <UserChangePassword auth={auth} /> },


          { path: '/notification-detail', element: <NotificationDetail auth={auth} /> },
          { path: '/notification-setting', element: <NotificationSetting auth={auth} /> },

          //Attendance
          { path: '/attendance/attendance-log', element: <Attendance_attendanceLogs {...props} /> },
          { path: '/attendance/leave-log', element: <Attendance_leaveLogs {...props} auth={auth}/> },
          //Incharge 
          { path: '/freelancer-contract/incharge', element: <InchargeList auth={auth} myProfile={true} /> },
          { path: '/freelancer-contract/incharge-view/:id', element: <InchargeView auth={auth} myProfile={true} /> },

          // Personalised Support
          { path: "/schedule-a-call", element: <ScheduleACall auth={auth} /> },
          { path: "/watch-demo", element: <WatchDemo auth={auth} /> }, 
          // 404 Page Not Found
          { path: "*", element: <Navigate to="/dashboard" replace /> },
        ]
      },


    // //HR Dashboard
    // {
    //   path: '/hr-dashboard',
    //   element: Boolean(auth.authtoken) && Boolean(auth?.authData?.permission) && auth?.authData?.permission?.includes("SideBarTalents") ?
    //     <DashboardLayout /> : <Navigate to="/login" />,
    //   children: [
    //     { element: <Navigate to="/dashboard" replace /> },
    //     { path: '/hr-dashboard', element: <HrDashboard auth={auth} /> },
    //   ]
    // },


      //Talent
      {
        path: '/talent',
        element: checkPermissionsAndRender([PERMISSIONS.SideBarTalents, PERMISSIONS.OnlyViewTalentList, PERMISSIONS.ViewAddTalent, PERMISSIONS.ViewEditTalent, PERMISSIONS.ViewReleaseTalent], <DashboardLayout />, { extraCheck: TalentSetting?.enable_talent_directory }),
        children: [
          { element: <Navigate to="/dashboard" replace /> },
          {
            path: '/talent/add-talent',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewAddTalent],
              <AddTalent auth={auth} />
            )
          },
          {
            path: '/talent/release-talent',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewReleaseTalent],
              <ReleaseTalent auth={auth} />
            )
          },
          {
            path: '/talent/:id',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewAddTalent, PERMISSIONS.ViewEditTalent, PERMISSIONS.ViewReleaseTalent],
              <ViewTalent auth={auth} />
            )
          },
          {
            path: '/talent/talent-list',
            element: checkPermissionsAndRender(
              [PERMISSIONS.OnlyViewTalentList, PERMISSIONS.ViewAddTalent, PERMISSIONS.ViewEditTalent],
              <TalentList auth={auth} userStatus={ACTIVE_USER} />,
              { extraCheck: TalentSetting?.enable_talent_directory }
            )
          },
          {
            path: '/talent/bulk-talent-upload',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewAddTalent],
              <BulkTalentUpload auth={auth} userStatus={ACTIVE_USER} />
            )
          },
          {
            path: '/talent/released-talent-list',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewReleaseTalent],
              <TalentList auth={auth} userStatus={RELEASED_USER} />
            )
          },
          {
            path: '/talent/trashed-talent-list',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewReleaseTalent],
              <TalentList auth={auth} userStatus={INACTIVE_USER} />
            )
          }
        ]
      
      },
      //Freelancer 
      {
        path: '/freelancer-contract',
        element: checkPermissionsAndRender([], <DashboardLayout />),
        children: [
          { element: <Navigate to="/dashboard" replace /> },
          {
            path: '/freelancer-contract/add-freelancer',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewAddFreelancer],
              <AddFreelancer auth={auth} />
            )
          },
          {
            path: '/freelancer-contract/freelancer/:id',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewEditFreelancer],
              <ViewFreelancer auth={auth} />
            )
          },
          {
            path: '/freelancer-contract/freelancer-list',
            element: checkPermissionsAndRender(
              [PERMISSIONS.OnlyViewFreelancerList, PERMISSIONS.ViewAddFreelancer, PERMISSIONS.ViewEditFreelancer],
              <FreelancerList  {...props} />
            )
          },
          //Contracts 
          {
            path: '/freelancer-contract/contract-list',
            element: checkPermissionsAndRender(
              [PERMISSIONS.OnlyViewContractList, PERMISSIONS.ViewAddEditDeleteContract, PERMISSIONS.ViewTerminateContract],
              <ContractList auth={auth} />
            )
          },
          {
            path: '/freelancer-contract/add-contract',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewAddEditDeleteContract],
              <AddNewContract auth={auth} />
            )
          },
          {
            path: '/freelancer-contract/view-contract/:id',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewAddEditDeleteContract, PERMISSIONS.ViewTerminateContract],
              <ContractView  {...props} />
            )
          },
          {
            path: '/freelancer-contract/contract-termination/:id',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewTerminateContract],
              <ContractTerminatePage  {...props} />
            )
          },
          {
            path: '/freelancer-contract/terms-and-conditions',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewAddEditDeleteTnC],
              <TnCList  {...props} />
            )
          },
        ]
      },


      //Payroll 
      {
        path: '/payroll',
        element: checkPermissionsAndRender([], <DashboardLayout />),
        children: [
          { element: <Navigate to="/dashboard" replace /> },
          {
            path: '/payroll/payroll-lists',
            element: checkPermissionsAndRender(
              [PERMISSIONS.OnlyViewPayroll, PERMISSIONS.ViewEditFinalizePayroll, PERMISSIONS.ViewFinalizePayroll],
              <AttendanceList auth={auth} is_payroll={true} />
            )
          },
          {
            path: '/payroll/payroll-lists/:id',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewEditFinalizePayroll, PERMISSIONS.ViewFinalizePayroll],
              <AttendanceSheet auth={auth} is_payroll={true} />
            )
          },
        ]
      },
      //Payment 
      {
        path: '/payment',
        element: checkPermissionsAndRender([], <DashboardLayout />),
        children: [
          { element: <Navigate to="/dashboard" replace /> },
          {
            path: '/payment/salary-payment',
            element: checkPermissionsAndRender(
              [PERMISSIONS.SideBarSalaryPayment, PERMISSIONS.DownloadPaySlip],
              <Salary auth={auth} />
            )
          },
          { path: '/payment/payment-request', element: <PaymentRequest auth={auth} /> },
          {
            path: '/payment/talent-payment',
            element: checkPermissionsAndRender(
              [PERMISSIONS.SideBarTalentPayment],
              <TalentPayment auth={auth} />
            )
          },
          {
            path: '/payment/freelancer-payment',
            element: checkPermissionsAndRender(
              [PERMISSIONS.SideBarFreelancerPayment],
              <FreelancerPayment auth={auth} />
            )
          },
          {
            path: '/payment/payment-logs',
            element: checkPermissionsAndRender(
              [PERMISSIONS.SideBarPaymentLog],
              <PaymentLog auth={auth} />
            )
          },
          {
            path: '/payment/performance-payment',
            element: checkPermissionsAndRender(
              [PERMISSIONS.SideBarPerformancePayment],
              <PerformancePayment auth={auth} />
            )
          },
        ]
      },
      //Report
      {
        path: '/report',
        element: checkPermissionsAndRender([PERMISSIONS.ViewReport], <DashboardLayout />),
        children: [
          { element: <Navigate to="/dashboard" replace /> },
          { path: '/report', element: <Reports /> },
          { path: '/report/attendance-log-report', element: <Reports /> },
          { path: '/report/attendance-log-report/by-date', element: <ReportsResults report={REPORT_BY_DATE} /> },
          { path: '/report/attendance-log-report/by-talent', element: <ReportsResults report={REPORT_BY_TALENT} /> },
          { path: '/report/attendance-log-report/by-time', element: <ReportsResults report={REPORT_BY_TIME} /> },
          { path: '/report/payroll-report', element: <PayrollReport /> },
        ]
      },
      //Master
      {
        path: '/master',
        element: checkPermissionsAndRender([PERMISSIONS.OnlyViewMaster, PERMISSIONS.ViewAddEditDeleteMaster], <DashboardLayout />),
        children: [
          { element: <Navigate to="/dashboard" replace /> },
          { path: '/master/department', element: <Department /> },
          { path: '/master/designation', element: <Designation /> },
          { path: '/master/leave', element: <LeaveMaster /> },

        ]
      },
      //support
      {
        path: '/support',
        element: Boolean(auth.authtoken) && Boolean(auth?.authData?.permission) ?
          <DashboardLayout /> : <Navigate to="/login" />,
        children: [
          { element: <Navigate to="/dashboard" replace /> },
          { path: '/support/faqs', element: <FAQs /> },
          { path: '/support/tickets', element: <SupportTickets auth={auth} /> },
          { path: '/support/ticket/:id', element: <SupportTicketDetail auth={auth} /> },
          { path: "/support/onboarding-options", element: <OnboardingOptions auth={auth} fromSupport={true} /> },

        ]
      },


      //User Management
      {
        path: '/user-management',
        element: checkPermissionsAndRender([PERMISSIONS.OnlyViewUserManagement, PERMISSIONS.ViewAddEditCredentials, PERMISSIONS.AddSuperAdmin, PERMISSIONS.ChangeEmployeePassword], <DashboardLayout />),
        children: [
          { element: <Navigate to="/dashboard" replace /> },
          { path: '/user-management', element: <UserManagement /> },

        ]
      },
      //Attendance List
      {
        path: '/attendance',
        element: checkPermissionsAndRender([], <DashboardLayout />),
        children: [
          { element: <Navigate to="/dashboard" replace /> },
          {
            path: '/attendance/attendance-lists',
            element: checkPermissionsAndRender(
              [PERMISSIONS.OnlyViewAttendanceList, PERMISSIONS.ViewEditVerifyAttendanceList, PERMISSIONS.ViewVerifyAttendanceList],
              <AttendanceList auth={auth} is_payroll={false} />
            )
          },
          {
            path: '/attendance/attendance-lists/:id',
            element: checkPermissionsAndRender(
              [PERMISSIONS.ViewEditVerifyAttendanceList, PERMISSIONS.ViewVerifyAttendanceList],
              <AttendanceSheet auth={auth} is_payroll={false} />
            )
          },
          {
            path: '/attendance/regularize-request',
            element: checkPermissionsAndRender(
              [PERMISSIONS.VerifyRegularization],
              <RegularizationRequest auth={auth} />
            )
          },
        ]
      },
    )
    /**
     * SETTINGS ROUTE ACCORDING TO PERMISSION
     */
    const settingRoutes = []
    if (isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewAccountSettings, PERMISSIONS.ViewEditAccountSettings])) {
      settingRoutes.push({ path: "/setting/account", element: <AccountSetting auth={auth} /> })
    }
    if (isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewTalentDataSettings, PERMISSIONS.ViewEditTalentDataSettings])) {
      settingRoutes.push({ path: "/setting/talent-setting", element: <TalentSettings auth={auth} /> })
    }
    if (isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewAttendanceSettings, PERMISSIONS.ViewEditAttendanceSettings])) {
      settingRoutes.push({ path: "/setting/attendance-setting", element: <AttendanceSettings auth={auth} /> })
    }
    if (isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewLeaveSettings, PERMISSIONS.ViewEditLeaveSettings])) {
      settingRoutes.push({ path: "/setting/leave-setting", element: <LeaveSettings auth={auth} /> })
    }
    if (isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewPayrollSettings, PERMISSIONS.ViewEditPayrollSettings])) {
      settingRoutes.push({ path: "/setting/payroll-setting", element: <PayrollSettings auth={auth} /> })
    }
    if (isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewPaymentSettings, PERMISSIONS.ViewEditPaymentSettings])) {
      settingRoutes.push({ path: "/setting/payment-setting", element: <PaymentSetting auth={auth} /> })
    }
    if (isPermissionsAllowed(Permission, [PERMISSIONS.OnlyViewAdditionalSettings, PERMISSIONS.ViewEditAdditionalSettings])) {
      settingRoutes.push({ path: "/setting/additional-setting", element: <AdditionalSettings auth={auth} /> })
    }
    /**
     * SETTINGS ROUTE ONLY FOR PRIMARY SUPER ADMIN USER
     */
    if (isPrimarySuperAdmin) {
      settingRoutes.push(
        { path: "/setting/custom-user-role", element: <CustomUserRoleList auth={auth} /> },
        { path: "/setting/custom-user-role/add", element: <AddCustomUserRole auth={auth} /> },
        { path: "/setting/custom-user-role/:id", element: <AddCustomUserRole auth={auth} /> },
      )
    }
    if (settingRoutes.length > 0) {
      routeList.push({
        path: '/setting',
        element: checkPermissionsAndRender([], <DashboardLayout isSettingLayout={true} />),
        children: [
          //Dashboard
          { path: "/setting", element: <Navigate to={isSuperAdmin ? "/setting/account" : settingRoutes?.[0].path} replace /> },
          ...settingRoutes,
          // 404 Page Not Found
          { path: "*", element: <Navigate to="/" replace /> },
        ]
      })
    }
  } else {
    routeList.push(
      {
        path: '/',
        element: Boolean(auth.authtoken) ? <AuthLayout /> : <Navigate to="/login" />,
        children: [
          { path: "/", element: <Navigate to="/auth/update-credentials" replace /> },
          { path: '/auth/update-credentials', element: <UpdateCredentials /> },
        ]
      }
    )
  }

  
  /**
   * UNPROTECTED ROUTES
   */
  routeList.push({
    path: '/',
    element: !Boolean(auth.authtoken) ? <AuthLayout /> : <Navigate to="/" />,
    children: [
      //Dashboard
      { path: "/", element: <Navigate to="/login" replace /> },
      { path: '/login', element: <Login /> },
      { path: '/forget-password', element: <ChangePassword /> },
      { path: '/reset-password/:key', element: <ResetPassword /> },
      { path: '/sign-up', element: <SingnUp /> },
      { path: '/subscription', element: <Subscription /> },
      { path: '/mail-not-verified', element: <MailNotVerified /> },
       // Onboarding 
      //  { path: "/welcome", element: <OnboardingWelcome auth={auth} /> },
      //  { path: "/thankyou-for-onboarding", element: <OnboardingThankyouResponse auth={auth}  /> },
      //  { path: "/onboarding-options", element: <OnboardingOptions auth={auth} /> },
      //  { path: "/schedule-a-call", element: <ScheduleACall auth={auth} /> },
      //  { path: "/watch-demo", element: <WatchDemo auth={auth} /> },
     
      { path: '/terms-and-condition', element: <TermsAndCondition /> },
      { path: '/privacy-policy', element: <PrivacyPolicy /> },
      // 404 Page Not Found
      /**
       * This is required so after the logout it will redirect to the "/" route
       */
      { path: "*", element: <Navigate to="/" replace /> },
    ]
  })

  let element = useRoutes(routeList);
  return element;
}
