import React, { useState, useEffect } from 'react';
import { Grid, Stack} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { ETimePicker } from '../../BaseComponents/ETimePicker';
import { ETypography, EWordCount } from '../../BaseComponents/ETypography';
import { ECard } from '../../BaseComponents/ECard';
import { ETextField } from '../../BaseComponents/ETextField';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { RegularizeRemark } from '../../utils/validation';
import { AttendanceRegularization } from '../../action/AttendanceAction';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import moment from 'moment';

/**
 * [2022-11-29]
 * Author:-AanchaL Sahu
 * Description:-designed for regularization
 **/

export default function Regularizationform({ row, isOpen, setIsOpen, resetValues, company }) {
    const [openFrom, setOpenFrom] = useState(false);
    const [openTo, setOpenTo] = useState(false);
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authReducer);
    const CHARACTER_LIMIT = 300;
    const [time, setTime] = useState({
        checkInTime: row?.check_in_time ? row?.check_in_time : null,
        checkOutTime: row?.check_out_time ? row?.check_out_time : null,
        checkInMaxTime: null
    });

    
    const count = company?.attendance_regularization_count > row?.total_regularized_count ? company?.attendance_regularization_count - row?.total_regularized_count: company?.attendance_regularization_count
   
    // const regularize_request_check = count >=0 ? count: '0'  // using to set regularize_request_check ( // if count is negative then set it to '0')


   
    const Close = () => {
        setIsOpen({ ...isOpen, isModal: false, status: false })
    }


    const requestHr = company?.attendance_regularization_count === 0 && company?.attendance_regularization_request === true

    const formik = useFormik({
        initialValues: {
            checkin_time: row?.check_in_time ? row?.check_in_time : null,
            checkout_time: row?.check_out_time ? row?.check_out_time : null,
            is_checkin_time: row?.check_in_time ? true : false,
            is_checkout_time: row?.check_out_time ? true : false,
            employee_id: row?.employee_id ? row?.employee_id : '',
            attendance_id: row?.id ? row?.id : '',
            attendance_date: row?.date ? row?.date : '',
            updated_by: auth?.authData?.id ? auth?.authData?.id : '',
            regularize_remark: row?.regularize_remark ? row?.regularize_remark : '',
            regularize_request_check : requestHr ? true : false
        },
        validationSchema: RegularizeRemark,
        onSubmit: (data) => {
             // for sending the time along with same date of regularization
             const reg_Date = moment(data?.attendance_date)
             const new_checkin = moment(data?.checkin_time)
             const new_checkout = moment(data?.checkout_time)
             new_checkin.date = new_checkin.date(reg_Date.date()); //changing the date in checkin date
             new_checkout.date = new_checkout.date(reg_Date.date()); //changing the date in checkout date
             data = {
                 ...data,
                 checkin_time: moment(new_checkin).format('YYYY-MM-DD HH:mm'),
                 checkout_time: moment(new_checkout).format('YYYY-MM-DD HH:mm')
             }
            dispatch(AttendanceRegularization(auth?.authtoken, data, isOpen.data, Close));
        }
    });

    const { errors, touched, getFieldProps, handleSubmit, setFieldValue } = formik;
    const midnight = new Date(row?.date);
    midnight.setHours(23, 59, 0, 0);
    let CheckInMinDateFormat = new Date(row?.date);
    CheckInMinDateFormat.setHours(0, 0, 0, 0);

    const CheckOutMaxTime = new Date(row?.date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0) ? new Date() : midnight;
    useEffect(() => {
        const midnight = new Date(row?.date);
        midnight.setHours(23, 59, 0, 0);
        let CheckInMaxDateFormat = row.check_out_time ? new Date(row.check_out_time) : midnight;
        if (new Date(row?.date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0) && !row.check_out_time) {
            CheckInMaxDateFormat = new Date();
        }
        setTime({ ...time, checkInMaxTime: CheckInMaxDateFormat })
    }, []);

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12} md={12}  >
                            <ECard className="request-regularization-card mb-8px">
                            <ETypography className='bold-600 color-text-default-static '>AVAILABLE <span>{count >=0 ? '0' + count: '0'}</span>   </ETypography>
                            </ECard>
                            {/* <ECard className='apply-leave-card  width-100 align-center justify-content-space-between' >
                                <ETypography className='bold-600 color-text-default-static '>AVAILABLE </ETypography>
                                <ETypography className='font-size-32px theme-color-static bold-600' > {count >=0 ? count: '0'} </ETypography>
                            </ECard> */}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} >
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} xs={12}>
                                    <ELabelInputMainGrid label={'Check In'} isfullgrid={true} isModal={true}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <ETimePicker
                                                fullWidth
                                                inputFormat="hh:mm a"
                                                value={time.checkInTime ? time.checkInTime : null}
                                                minTime={CheckInMinDateFormat}
                                                maxTime={time.checkInMaxTime}
                                                inputProps={{ readOnly: true }}
                                                {...getFieldProps('checkin_time')}
                                                onChange={(value) => {
                                                    setTime({ ...time, checkInTime: value ? value : null })
                                                    setFieldValue('checkin_time', value ? value : null)
                                                }}
                                                open={openFrom}
                                                onOpen={() => setOpenFrom(true)}
                                                onClose={() => setOpenFrom(false)}
                                                renderInput={(params) =>
                                                    <ETextField fullWidth
                                                        {...params}
                                                        {...getFieldProps('checkin_time')}
                                                        onClick={(e) => setOpenFrom(true)}
                                                        error={Boolean(touched.checkin_time && errors.checkin_time)}
                                                        helperText={touched.checkin_time && errors.checkin_time}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                    </ELabelInputMainGrid>
                                </Grid>
                                <Grid item md={12} lg={12} xs={12}>
                                    <ELabelInputMainGrid label={'Check Out'} isfullgrid={true} isModal={true}>
                                        
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <ETimePicker
                                                disabled={new Date(row?.date).setHours(0, 0, 0, 0) != new Date().setHours(0, 0, 0, 0) ? false : new Date(row?.date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0) && !row?.check_out_time ? true : false}
                                                fullWidth
                                                inputFormat="hh:mm a"
                                                minTime={new Date(time.checkInTime) ? new Date(time.checkInTime) : CheckInMinDateFormat}
                                                maxTime={CheckOutMaxTime}
                                                value={time.checkOutTime}
                                                {...getFieldProps('checkout_time')}
                                                defaultValue={time.checkOutTime}
                                                inputProps={{ readOnly: true }}
                                                onChange={(value) => {
                                                    setFieldValue('checkout_time', value ? value : null)
                                                    setTime({ ...time, checkInMaxTime: value ? value : midnight })
                                                }
                                                }
                                                open={openTo}
                                                onOpen={() => setOpenTo(true)}
                                                onClose={() => setOpenTo(false)}
                                                renderInput={(params) =>
                                                    <ETextField
                                                        fullWidth
                                                        {...params}
                                                        {...getFieldProps('checkout_time')}
                                                        onClick={(e) => setOpenTo(true)}
                                                        error={Boolean(touched.checkout_time && errors.checkout_time)}
                                                        helperText={touched.checkout_time && errors.checkout_time}
                                                    />}
                                            />
                                        </LocalizationProvider>
                                    </ELabelInputMainGrid>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <ELabelInputMainGrid label={'Reason'}  isfullgrid={true} isModal={true}>
                                        <ETextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            id="outlined-name"
                                            name="Remark"
                                            {...getFieldProps('regularize_remark')}
                                            inputProps={{ maxLength: CHARACTER_LIMIT }}
                                            error={Boolean(touched.regularize_remark && errors.regularize_remark)}
                                            helperText={touched.regularize_remark && errors.regularize_remark}
                                        />
                                       <EWordCount>{`${formik?.values?.regularize_remark.length}/${CHARACTER_LIMIT}`}</EWordCount> 
                                    </ELabelInputMainGrid>
                                </Grid>
                                <Grid item lg={12} className='modal1-buttons-stick-bottom '>
                                    <Stack direction='row' spacing={2} >
                                        <EButtonOutlined size="large" onClick={() => setIsOpen({ ...isOpen, isModal: false, status: false })} variant='outlined' >
                                            Cancel
                                        </EButtonOutlined>
                                        <ELoadingButton size="large" type="submit" variant='contained'>{requestHr ? 'Request HR' : 'Regularize'}</ELoadingButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </>
    );
}

