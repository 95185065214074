import { Grid } from "@mui/material";
import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import moment from "moment";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { EDivider } from "../../BaseComponents/EDivider";
import Page from "../../BaseComponents/EPage";
import EScrollbar from "../../BaseComponents/EScrollbar";
import EChip from "../../BaseComponents/EChip";
import {
  EIcon,
  EIconSmall,
} from "../../BaseComponents/EIcon";
import {
  EListHead,
  ETable,
  ETableBody,
  ETableCell,
  ETableContainer,
  ETableRow,
} from "../../BaseComponents/ETable";
import {
  ETypography,
  ETypographyPageHeading,
} from "../../BaseComponents/ETypography";
import { EMPLOYEE_CARD_LIST } from "../../constants";
import {
  getRegularizationRequestList,
  approveRegularizationRequest,
} from "../../action/RegularizationRequestAction";

import checkCircle from "@iconify/icons-material-symbols/check-circle-outline-rounded";
import cancelCircle from "@iconify/icons-material-symbols/cancel-outline-rounded";
import monitor from "@iconify/icons-material-symbols/desktop-windows-outline-rounded";
import mobile from "@iconify/icons-material-symbols/phone-iphone-outline";
import BackIcon from "@iconify/icons-material-symbols/chevron-left-rounded";
import InfoIcon from "@iconify/icons-material-symbols/info-outline-rounded";
import {
  EButtonOutlined,
  EButtonOutlinedIcon,
  EIconButton,
  ELoadingButton,
} from "../../BaseComponents/EButtons";
import { useNavigate } from "react-router-dom";
import EChipGreen from "../../BaseComponents/EChipGreen";

import EModal from "../../BaseComponents/EModal";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import EHidden from "../../BaseComponents/EHidden";
import { EBoxPage } from "../../BaseComponents/EBox";
import RegularizationSearchfields from "../../PageComponents/Attendance/RegularizationReqSearchFields";
import { EmployeeListData } from "../../action/EmployeeAction";

const tableHead = (role) => {
  let heads = [];

    heads.push(
      { id: "Talent Name", label: "Talent Name", alignRight: "center" },
      { id: "Attendance Date", label: "Attendance Date", alignRight: "center" },
      { id: "Check In", label: "Check In Time", alignRight: "center" },
      { id: "Check Out", label: "Check Out Time", alignRight: "center" },
      { id: "Reason", label: "Reason", alignRight: "center",hideLabelCriteria: 'mdDown' },
      { id: "Status", label: "Status", alignRight: "center",hideLabelCriteria: 'mdDown' },
      { id: "Action", label: "Action", alignRight: "center" ,hideLabelCriteria: 'mdDown'}
    );
  return heads;
};

function RegularizationRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authReducer);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(EMPLOYEE_CARD_LIST);
  // searchParameters
  const [searchParam,setSearchParam] = useState({
    employee_id:'',
    year_month:null,
    status:'',
    sort:false,
    download:false,
    page:page,
    per_page:rowsPerPage
  })
  const employeeListData = useSelector(state => state.EmployeeReducer);
  const regularizeRequestList = useSelector(
    (state) => state.RegularizationRequestReducer
  );
  const [isApproved, SetIsApproved] = useState({
    open: false,
    data: null,
    title: "",
  });
  const [isRejected, SetIsRejected] = useState({
    open: false,
    data: null,
    title: "",
  });


  


const getEmployeeData = (searchData) => {
  callApi(searchData, 0, rowsPerPage);
  setPage(0);
}

const callApi = (fnParams, fnPage, fnRowPerPage) => {
dispatch(EmployeeListData(auth.authtoken));
}



  const TABLE_HEAD = tableHead(auth?.authData?.role);

  const approveHandler = (item) => {
    const data = {
      request_id: item.id,
      employee_id: item.employee_id,
      // approval_remark:'request approved',
      regularized_request_status: true,
    };
    dispatch(approveRegularizationRequest(auth.authtoken, data));
    SetIsApproved({ open: false, data: null });
  };
  const cancelHandler = (item) => {
    const data = {
      request_id: item.id,
      employee_id: item.employee_id,
      // approval_remark:'request denied',
      regularized_request_status: false,
    };
    dispatch(approveRegularizationRequest(auth.authtoken, data));
    SetIsRejected({ open: false, data: null });
  };

  useEffect(() => {
    if(auth?.authtoken){
      callApi(auth.authtoken)
    }
    dispatch(getRegularizationRequestList(auth.authtoken,searchParam));
  }, [auth?.authtoken,searchParam]);

  
  return (
    <Page title="Attendance Log">
      {/* Header BreadCrumbs */}
      <Grid container display="row"  className="align-center" justifyContent="space-between">
        <EHidden width="mdDown" >
        <EHeaderBreadcrumbs
          heading="Regularization Request"
          links={[
            { name: "Dashboard", href: "/" },
            { name: "Actions", href: "/attendance/attendance-log" },
            {
              name: "Regularization Request",
              href: "/",
            },
          ]}
        />
        </EHidden>
        <EHidden width="mdDown">
        <Grid item>
          
            <EButtonOutlined
              className="button-left-margin"
              variant="outlined"
              onClick={() => navigate(-1)}
              color="secondary"
            >
              {" "}
              Back{" "}
            </EButtonOutlined>
         
        </Grid>
        </EHidden>
      </Grid>

      {/* Main Heading */}
      <Grid
        container
        display="row"
        justifyContent="space-between"
        className="margin-top-20px"
      
      >
        <Grid item>
          <ETypographyPageHeading variant="h5">
            Regularization Request
          </ETypographyPageHeading>
        </Grid>
        <Grid item>
        <EHidden width="mdUp">
        <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => navigate(-1)}
              className="button-left-margin"
            >
              {" "}
              <EIcon icon={BackIcon} className="height-width-15px" />{" "}
            </EButtonOutlinedIcon>
          </EHidden>
        </Grid>
        <EHidden width="mdDown">
        <Grid lg={12} xs={12} item>
          <EDivider className="margin-top-05" />
        </Grid>
        </EHidden>
        
      </Grid>
      <EBoxPage>

        {/* Search Fields */}

        <RegularizationSearchfields 
        searchParam={searchParam} 
        setSearchParam={setSearchParam}
        employeeListData={employeeListData}
        auth={auth}
        />
 {/* Table */}
 <EScrollbar className="margin-top-05">
        <ETableContainer>
          <ETable>
            <EListHead
              headLabel={TABLE_HEAD}
              rowCount={10}
              onRequestSort={() => null}
            />
            <ETableBody>
              {regularizeRequestList?.regularizationListData?.data?.rows.map(
                (row, index) => {
                  const {
                    applied_date,
                    old_checkin,
                    old_checkout,
                    new_checkin,
                    new_checkout,
                    reason,
                    status,
                    checkin_is_mobile,
                    employee,
                  } = row;
                  return (
                    <ETableRow key={index + "row"}>
                      {/* Talent name */}
                      <ETableCell align="center" sx={{ py: 3 }}>
                        {employee.fullname}
                      </ETableCell>

                      {/* applied date */}
                      <ETableCell align="center" sx={{ py:3 }}>
                        {moment(applied_date).format("DD/MM/YYYY")}
                      </ETableCell>

                      {/* Checkin  */}
                      <ETableCell align="center" sx={{ py:3 }}>
                        <span >
                          {checkin_is_mobile == false && old_checkin ? (
                            <ETooltip title="Checked in from Desktop" arrow>
                              <EIconSmall icon={monitor} />
                            </ETooltip>
                          ) : checkin_is_mobile == true && old_checkin ? (
                            <ETooltip title="Checked in from Mobile" arrow>
                              <EIconSmall icon={mobile} />
                            </ETooltip>
                          ) : (
                            ""
                          )}
                          {old_checkin
                            ? moment(old_checkin).format("hh:mm A")
                            : "-"}{" "}
                          <br />
                        </span>
                        <span className="theme-main-text-color-bold">
                          {moment(new_checkin).format("hh:mm A")}
                        </span>
                      </ETableCell>

                      {/* Checkout */}
                      <ETableCell align="center" sx={{ py:3 }}>
                        <span>
                          {checkin_is_mobile == false && old_checkout ? (
                            <ETooltip title="Checked out from Desktop" arrow>
                              <EIconSmall icon={monitor} />
                            </ETooltip>
                          ) : checkin_is_mobile == true && old_checkout ? (
                            <ETooltip title="Checked out from Mobile" arrow>
                              <EIconSmall icon={mobile} />
                            </ETooltip>
                          ) : (
                            ""
                          )}
                          {old_checkout
                            ? moment(old_checkout).format("hh:mm A")
                            : "-"}{" "}
                          <br />
                        </span>
                        <span className="theme-main-text-color-bold">
                          {moment(new_checkout).format("hh:mm A")}
                        </span>
                      </ETableCell>

                      <EHidden width="mdDown">
 {/* Reason */}
 <ETableCell align="center" sx={{ py:3 }}>
                        <ETooltip title={reason} arrow>
                          <EIconButton sx={{ color: "grey.500" }}>
                            <EIconSmall
                              icon={InfoIcon}
                              width={23}
                              height={23}
                            />
                          </EIconButton>
                        </ETooltip>
                      </ETableCell>

                      {/* Status */}
                      <ETableCell align="center" sx={{ py:3 }}>
                        {status === "1" && <EChip label="Pending" />}
                        {status === "2" && (
                          <EChipGreen
                            label="Approved"
                            classname="active-green-chip"
                          />
                        )}
                        {status === "3" && <EChip label="Reject" />}
                      </ETableCell>

                      {/* Action */}

                      <ETableCell align="center" sx={{ py:3 }}>
                        {status === "1" ? (
                          <>
                            <EIcon
                              onClick={() =>
                                SetIsApproved({
                                  open: true,
                                  data: row,
                                  title: "",
                                })
                              }
                              className="hover  icon-green"
                              icon={checkCircle}
                            />
                            <EIcon
                              onClick={() =>
                                SetIsRejected({
                                  open: true,
                                  data: row,
                                  title: "",
                                })
                              }
                              className="hover icon-red"
                              icon={cancelCircle}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </ETableCell>
                      </EHidden>
                     
                    </ETableRow>
                  );
                }
              )}
            </ETableBody>
          </ETable>
          {regularizeRequestList?.regularizationListData?.data?.count == 0 ? (
            <SearchNotFound />
          ) : (
            ""
          )}
        </ETableContainer>
      </EScrollbar>
      </EBoxPage>
     
      {/* Approve modal */}
      {isApproved.open && (
        <EModal
          open={isApproved.open}
          close={() => SetIsApproved({ open: false, data: null })}
          headervalue="Confirmation"
        >
          <Grid container>
            <Grid item>
              <ETypography variant="h5" className="font-size-18px">
                {" "}
                Are you sure you want to approve the request?
                <span className="theme-main-text-color-bold font-style-italic px-6px">
                  {isApproved.title}
                </span>{" "}
                ?
              </ETypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              xl={12}
              className="modal1-buttons-stick-bottom pt-24px"
            >
              <EButtonOutlined
                size="large"
                variant="outlined"
                onClick={() => {
                  SetIsApproved({ open: false, data: null });
                }}
              >
                {" "}
                No{" "}
              </EButtonOutlined>
              <ELoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={() => approveHandler(isApproved.data)}
                className="button-left-margin"
              >
                {" "}
                Yes{" "}
              </ELoadingButton>
            </Grid>
          </Grid>
        </EModal>
      )}

      {/* Reject modal */}
      {isRejected.open && (
        <EModal
          open={isRejected.open}
          close={() => SetIsRejected({ open: false, data: null })}
          headervalue="Confirmation"
        >
          <Grid container>
            <Grid item>
              <ETypography variant="h5" className="font-size-18px">
                Are you sure you want to reject the request?
                <span className="theme-main-text-color-bold font-style-italic px-6px">
                  {isApproved.title}
                </span>{" "}
                ?
              </ETypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              xl={12}
              className="modal1-buttons-stick-bottom pt-24px"
            >
              <EButtonOutlined
                size="large"
                variant="outlined"
                onClick={() => {
                  SetIsRejected({ open: false, data: null });
                }}
              >
                {" "}
                No{" "}
              </EButtonOutlined>
              <ELoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={() => cancelHandler(isRejected.data)}
                className="button-left-margin"
              >
                {" "}
                Yes{" "}
              </ELoadingButton>
            </Grid>
          </Grid>
        </EModal>
      )}
    </Page>
  );
}

export { RegularizationRequest };
