import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, Field } from 'formik';
import moment from 'moment';
import { AddNewUserSettingValidation } from '../../utils/validation';
import { NewEmployeeAuthCreate } from '../../action/EmployeeAuthAction';
import { ACTIVE_USER, EMAIL, MOBILE_NO, OTHER, SUPER_ADMIN, WORK_MODE } from '../../constants';
import { FormControl, FormControlLabel, Grid, Stack } from '@mui/material';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ETextField } from '../../BaseComponents/ETextField';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { EFormHelperText, ETypography } from '../../BaseComponents/ETypography';
import { ECheckbox } from '../../BaseComponents/ECheckbox';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import { EButton, EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { EmployeeListData } from '../../action/EmployeeAction';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { EBox } from '../../BaseComponents/EBox';
import { ReturnBoolean } from '../../utils/CommonFunctions';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';

/**
 * [2022-22-11]
 * Created By: Aanchal Sahu
 * [2023-01-10]
 * Changes by: Aanchal Sahu
 * Description: 
 * 1. removed (const [isNext, setIsNext] = useState(0);) to optimize code
 * 2. made Select Username as a autocomplete from radio
 * 3. removed all console errors
 * 4. removed DepartmentListData && DesignationListData
 **/
function AddNewUserForm(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { close, isEdit, search, empAuth, auth, loadingButton, designation, dept } = props
    const employee = useSelector(state => state.EmployeeReducer)

    const { customRoleData = [] } = useSelector((state) => state.CustomUserRole)

    //filtering the super admin role in case of new allocate credential form
    const customRole= !isEdit && customRoleData ? customRoleData?.filter(item => item.value!=SUPER_ADMIN ):customRoleData;

    /**
     * AdminList is for selecting user as a Reporting Manager
     */
    const [AdminList, setAdminList] = useState(null);
    let find_default_role = useRef(null);
    let find_default_workmode = useRef(null);
    const [openDateDOJ, setOpenDateDOJ] = useState(false);

    const selectedWorkMode = isEdit && WORK_MODE.find(function (e) {
        return e.id == isEdit?.work_mode;
    });

    // INFO: getting company settings
    const settingsContext = useCompanySettingProvider()
    const LastPayrollDataToDate=settingsContext?.setting?.accountSettingData?.company_data?.lastPayroll?.to_date// fetching payroll data here from settings
    
    // Info: As per discussion with @Dharam Sir & @sunil Sir on 16th May 23, The only validation in the DOJ will be the last Payroll locked date (to_date+1) else it will be open.
    const minDojValidation= LastPayrollDataToDate? moment(LastPayrollDataToDate).add(1, 'day').toDate(): null;

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            isEdit: isEdit ? true : false,
            talent_name: isEdit ? { id: isEdit?.id, label: isEdit?.fullname, email: isEdit?.email, mobile: isEdit?.mobile } : null,
            username: isEdit?.username ? isEdit?.username : '',
            username_type: (isEdit ? (isEdit.username == isEdit?.mobile ? { id: MOBILE_NO, label: 'Mobile No.' }
                : isEdit.username == isEdit?.email ? { id: EMAIL, label: 'Email' } : { id: OTHER, label: 'Other' }) : null),
            is_active: isEdit?.is_active ? isEdit?.is_active : "1",
            role: null,
            reporting_manager: isEdit?.reportingManager ? { label: isEdit?.reportingManager?.fullname, id: isEdit?.reportingManager?.id } :
                isEdit?.is_primary && isEdit?.is_primary == 1 ? { label: isEdit.fullname, id: isEdit.id } : null,
            is_primary: isEdit?.is_primary && isEdit?.is_primary == 1 ? true : false,
            show_as_talent: isEdit == null || !isEdit ? true : isEdit?.show_as_talent == 1 ? true : false,
            doj: isEdit?.doj ? isEdit?.doj : null,
            department: isEdit?.departmentInfo ? { label: isEdit?.departmentInfo?.department_name, id: isEdit?.departmentInfo?.id } : null,
            designation: isEdit?.designationInfo ? { label: isEdit?.designationInfo?.designation_name, id: isEdit?.designationInfo?.id } : null,
            payroll_basic: isEdit?.employeePayrollInfo && isEdit?.employeePayrollInfo?.payroll_basic ? Number(isEdit?.employeePayrollInfo?.payroll_basic) : '',
            work_mode: isEdit?.work_mode ? selectedWorkMode : false,
            isNext: 0
        },
        validationSchema: AddNewUserSettingValidation(auth.authtoken, isEdit?.id ? isEdit?.id : ''),
        onSubmit: (data) => {
            data = {
                ...data, id: data?.talent_name?.id,
                updated_by: auth?.authData?.id,
                search: { ...search, role: search?.role_object?.value },
                department_id: data?.department ? data?.department?.id : null,
                designation_id: data?.designation ? data?.designation?.id : null,
                work_mode_id: data?.work_mode ? data?.work_mode?.id : null,
                show_as_talent: formik?.values?.role?.value == SUPER_ADMIN ? data?.show_as_talent : true
            }

            dispatch(NewEmployeeAuthCreate(auth.authtoken, data, navigate, close, auth))

        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, } = formik;


    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER, { is_active: true, form_list: true }))
        }
    }, [empAuth?.empAuthSuccess])

    //__INFO : __ this useeffect is for setting initial values in Edit User Credentials Modal
    useEffect(() => {
        if (isEdit) {
            const AdminList = employee && employee?.employeeData?.rows?.length > 0 && employee?.employeeData?.rows.filter(item => (item.id != isEdit.id && item.is_active == 1))
            setAdminList(AdminList);

            // This function is for finding out the default role object from ROLE_OPTIONS array.
            if (isEdit && find_default_role?.current == null) {
                const _role = isEdit?.custom_role || isEdit?.role
                find_default_role.current = _role && customRoleData?.find(element => element?.value == _role);
                setFieldValue('role', find_default_role?.current)
            }

            //This function is for finding out the default work_mode object from WORK_MODE array.
            if (isEdit && (find_default_workmode?.current == null && find_default_workmode?.current != 'undefined' && isEdit?.work_mode >= 0)) {
                find_default_workmode.current = isEdit?.work_mode ? WORK_MODE?.find(element => element?.id == isEdit?.work_mode) : null;
                setFieldValue('work_mode', find_default_workmode?.current);
            }
        } else {
            const AdminList = employee && employee?.employeeData?.rows?.length > 0 && employee?.employeeData?.rows.filter(item => (item.is_active == 1))
            setAdminList(AdminList);
        }
    }, [isEdit, employee])

    const HandleClose = () => {
        close()
    }

  
    //This method is called when the talend is selected
    const selectUser = (user) => {
        setFieldValue('talent_name', user ? user : null)
        //setting all the fields to null if talent is changed
        setFieldValue('username', '')
        setFieldValue('username_type', null)
        setFieldValue('role', null)
        setFieldValue('reporting_manager', null)

        // to by pass all the validations for following fields
        setFieldValue('doj', user?.doj)
        setFieldValue('designation', user?.designationInfo)
        setFieldValue('department', user?.departmentInfo)
        setFieldValue('payroll_basic', Number(user?.employeePayrollInfo?.payroll_basic))
        setFieldValue('work_mode', user?.employeePayrollInfo?.work_mode)
        const userWorkMode = WORK_MODE.find(function (e) {
            return e.id == user?.work_mode;
        });
        setFieldValue('work_mode', userWorkMode ? userWorkMode : null);
    }

    /**
     * EmployeeList is for selecting talent when Allocate Credentials
     */
    const EmployeeList = isEdit ? [{ id: isEdit?.id, label: isEdit?.fullname, email: isEdit?.email, mobile: isEdit?.mobile }] :
        employee && employee?.employeeData?.rows?.length > 0 ? employee?.employeeData?.rows?.filter(item => !item.is_active) : []

    // INFO: isSaveAndNext is tracking the validation errors of 1st page to block proceeding without filling them all.
    const isSaveAndNext = (errors?.email || errors?.role || errors?.username || errors?.username_type) ? false : true;


    return (<>
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Grid container spacing={2} >
                    {formik?.values?.isNext == 0 ?
                        <>
                            {
                                !!isEdit
                                    ?
                                    <>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <ELabelInputMainGrid label={'Talent Name'} isfullgrid={true} isModal={true} isNotForm={true}>
                                            <ETypography>{formik.values?.talent_name?.label}</ETypography>
                                        </ELabelInputMainGrid>
                                    </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <ELabelInputMainGrid label={'Username'} isfullgrid={true} isModal={true} isNotForm={true}>
                                                <ETypography>{formik.values?.username}</ETypography>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <ELabelInputMainGrid label={'Talent Name'} isfullgrid={true} isModal={true}>
                                                <EAutocomplete
                                                    name="Talent Name"
                                                    fullWidth
                                                    forcePopupIcon={formik?.values?.talent_name ? false : true}
                                                    disabled={isEdit ? true : false}
                                                    {...getFieldProps('talent_name')}
                                                    getOptionLabel={(option) => option.label || ''}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    options={EmployeeList && EmployeeList?.length > 0 ? EmployeeList : []}
                                                    renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
                                                        <EBox component="li" {...props} key={option.id}>
                                                            {option.label}
                                                        </EBox>
                                                    )}
                                                    onChange={(e, value) => { selectUser(value); setFieldValue('talent_name', value ? value : null); }}
                                                    renderInput={params => (
                                                        <ETextField
                                                            {...params}
                                                            name='talent_name'
                                                            placeholder="Select talent name"
                                                            fullWidth
                                                            error={Boolean(touched.talent_name && errors.talent_name)}
                                                            helperText={touched.talent_name && errors.talent_name}
                                                        />
                                                    )}
                                                />
                                            </ELabelInputMainGrid>
                                        </Grid>
                                    </>
                            }

                            {formik?.values?.talent_name &&
                                <>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <ELabelInputMainGrid label={'Role'} isfullgrid={true} isModal={true}>
                                            <EAutocomplete
                                                name="Role"
                                                fullWidth
                                                forcePopupIcon={formik?.values?.role ? false : true}
                                                disabled={isEdit?.role == SUPER_ADMIN && auth?.authData?.role === SUPER_ADMIN && isEdit?.is_primary == '1' ? true : false}
                                                {...getFieldProps('role')}
                                                getOptionLabel={(option) => option.label || ''}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                options={customRole}
                                                onChange={(e, value) => {
                                                    setFieldValue('role', value);
                                                    if (value == SUPER_ADMIN && !isEdit?.role) { setFieldValue('reporting_manager', formik.values.talent_name); }
                                                    else if (value != SUPER_ADMIN && isEdit?.role == SUPER_ADMIN) { setFieldValue('reporting_manager', null); }
                                                }}
                                                renderInput={params => (
                                                    <ETextField
                                                        {...params}
                                                        name='role'
                                                        placeholder="Select role"
                                                        fullWidth
                                                        error={Boolean(touched.role && errors.role)}
                                                        helperText={touched.role && errors.role}
                                                    />
                                                )}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    {formik?.values?.role?.value != SUPER_ADMIN &&
                                        <>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <ELabelInputMainGrid label={'Reporting Manager'} isfullgrid={true} isModal={true}>
                                                    <EAutocomplete
                                                        name="Reporting Manager"
                                                        fullWidth
                                                        {...getFieldProps('reporting_manager')}
                                                        forcePopupIcon={formik?.values?.reporting_manager ? false : true}
                                                        renderOption={(props, option) => (
                                                            <li {...props} key={option.id}>
                                                                {option.label}
                                                            </li>
                                                        )}
                                                        options={AdminList && AdminList?.length > 0 ? AdminList : []}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        onChange={(e, value) => {
                                                            setFieldValue('reporting_manager', value ? value : null)
                                                        }}
                                                        renderInput={params => (
                                                            <ETextField
                                                                {...params}
                                                                name='reporting_manager'
                                                                placeholder="Select reporting manager"
                                                                fullWidth
                                                                error={Boolean(touched.reporting_manager && errors.reporting_manager)}
                                                                helperText={touched.reporting_manager && errors.reporting_manager}
                                                            />
                                                        )}
                                                    />
                                                </ELabelInputMainGrid>
                                            </Grid>
                                        </>
                                    }

                                    {/* INFO: If the logged in user is primary SUPER ADMIN then when the user which we are updating is SUPER ADMIN then show below option */}
                                    {formik?.values?.role?.value == SUPER_ADMIN && auth?.authData?.is_primary == true &&
                                        isEdit?.is_primary != 1 &&
                                        <Grid xs={12} sm={12} md={12} lg={12} item >
                                            <ELabelInputMainGrid label={''} isfullgrid={true} disableColon={true} isModal={true} >
                                                <ECheckbox name="is_primary" className='pl0'
                                                    {...getFieldProps('is_primary')}
                                                    onChange={(e, value) => {
                                                        setFieldValue('is_primary', value);
                                                        if (value === true) {
                                                            setFieldValue('is_active', 1);
                                                        }
                                                    }
                                                    }/>
                                                Make user primary
                                            </ELabelInputMainGrid>
                                        </Grid>
                                    }
                                    {/* INFO: IF the logged in user is SUPER ADMIN then when the user which we are updating is SUPER ADMIN or we change the the updating user role to SUPER ADMIN then show below option */}
                                    {(isEdit?.role === SUPER_ADMIN || formik?.values?.role?.value == SUPER_ADMIN) && isEdit && auth?.authData?.role === SUPER_ADMIN && formik?.values?.role?.value == SUPER_ADMIN &&
                                        <Grid item xs={12} sm={12} md={12} lg={12}   className='pt-0'>
                                            <ELabelInputMainGrid label={''} disableColon={true} isfullgrid={true} isModal={true}>
                                                <ECheckbox name="show_as_talent" defaultChecked={isEdit == null || !isEdit ? true : ReturnBoolean(formik?.values?.show_as_talent)}
                                                    {...getFieldProps('show_as_talent')}
                                                    onChange={(e, value) => { setFieldValue('show_as_talent', value); }}
                                                    className='pl0'
                                                />
                                                Show in payroll
                                            </ELabelInputMainGrid>
                                        </Grid>
                                    }
                                    {formik.values.is_primary == false && isEdit &&
                                        <>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <ELabelInputMainGrid label={'Status'} isfullgrid={true} labelPT='0.5rem' isModal={true}>
                                                    <FormControl component="fieldset" error={Boolean(touched.is_active && errors.is_active)}>
                                                        <ERadioGroup row aria-label="is_active" name="is_active" {...getFieldProps('is_active')} >
                                                            <FormControlLabel value="1" control={
                                                                <ERadio />
                                                            } label='Enable' />
                                                            <FormControlLabel value="2" control={
                                                                <ERadio />
                                                            } label="Disable" />
                                                        </ERadioGroup>
                                                        {touched.is_active && <EFormHelperText> {errors.is_active}</EFormHelperText>}
                                                    </FormControl>
                                                </ELabelInputMainGrid>
                                            </Grid>
                                        </>
                                    }
                                </>
                            }
                        </>
                        :
                        <>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ELabelInputMainGrid label={'Date of Joining'} isfullgrid={true} isModal={true}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <EDatePicker
                                            disabled={isEdit?.doj != null ? true : false}
                                            name="doj"
                                            {...getFieldProps('doj')}
                                            minDate={minDojValidation}
                                            maxDate={new Date()}
                                            open={openDateDOJ}
                                            onOpen={() => setOpenDateDOJ(true)}
                                            onClose={() => setOpenDateDOJ(false)}
                                            inputFormat="dd/MM/yyyy"
                                            onChange={(selectedDate) => {
                                                setFieldValue('doj', selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null);
                                            }}
                                            fullWidth
                                            renderInput={(params) => (
                                                <Field
                                                    component={ETextField}
                                                    {...params}
                                                    onClick={(e) => { if (isEdit?.doj == null) { setOpenDateDOJ(true) } }}
                                                    fullWidth
                                                    name="doj"
                                                    error={Boolean(touched.doj && errors.doj)}
                                                    helperText={touched.doj && errors.doj}
                                                />
                                            )}

                                        />
                                    </LocalizationProvider>
                                </ELabelInputMainGrid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ELabelInputMainGrid label={'Department'}  isfullgrid={true} isModal={true}>
                                    <EAutocomplete
                                        name="department"
                                        disabled={isEdit?.departmentInfo != null ? true : false}
                                        forcePopupIcon={formik?.values?.department ? false : true}
                                        fullWidth
                                        options={
                                            dept?.departmentListData?.rows && dept?.departmentListData?.rows?.length > 0 ? dept?.departmentListData?.rows : []
                                        }
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...getFieldProps('department')}
                                        onChange={(e, value) => {
                                            setFieldValue('department', value ? value : null);
                                        }}
                                        renderInput={(params) => (
                                            <ETextField
                                                {...params}
                                                name="department"
                                                fullWidth
                                                placeholder="Select department"
                                                error={Boolean(touched.department && errors.department)}
                                                helperText={touched.department && errors.department}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ELabelInputMainGrid label={'Salary'} small_text='(CTC/Month)'  isfullgrid={true} isModal={true} >
                                    <ETextField
                                        fullWidth
                                        disabled={isEdit?.employeePayrollInfo != null && isEdit?.employeePayrollInfo?.payroll_basic ? true : false}
                                        {...getFieldProps('payroll_basic')}
                                        error={Boolean(touched.payroll_basic && errors.payroll_basic)}
                                        helperText={touched.payroll_basic && errors.payroll_basic}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ELabelInputMainGrid label={'Designation'}  isfullgrid={true} isModal={true}>
                                    <EAutocomplete
                                        disabled={isEdit?.designationInfo != null ? true : false}
                                        forcePopupIcon={formik?.values?.designation ? false : true}
                                        name="designation"
                                        fullWidth
                                        options={
                                            designation && designation?.designationListData?.rows?.length > 0
                                                ? designation?.designationListData?.rows
                                                : []
                                        }
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...getFieldProps('designation')}
                                        onChange={(e, value) => {
                                            setFieldValue('designation', value ? value : null);
                                        }}
                                        renderInput={(params) => (
                                            <ETextField
                                                {...params}
                                                name="designation"
                                                placeholder="Select designation"
                                                fullWidth
                                                error={Boolean(touched.designation && errors.designation)}
                                                helperText={touched.designation && errors.designation}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ELabelInputMainGrid label={'Work Mode'} isfullgrid={true} isModal={true}>
                                    <EAutocomplete
                                        name="work_mode"
                                        disabled={isEdit?.work_mode != null && isEdit?.work_mode != 0 ? true : false}
                                        fullWidth
                                        options={WORK_MODE}
                                        forcePopupIcon={formik?.values?.work_mode ? false : true}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={option => option.label || ""}
                                        {...getFieldProps('work_mode')}
                                        onChange={(e, value) => {
                                            setFieldValue("work_mode", value)
                                        }}
                                        renderInput={params => (
                                            <ETextField
                                                {...params}
                                                name='work_mode'
                                                placeholder='Select Work Mode'
                                                fullWidth
                                                error={Boolean(touched.work_mode && errors.work_mode)}
                                                helperText={touched.work_mode && errors.work_mode}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>

                        </>
                    }

                    <Grid item xs={12} xl={12} className='modal1-buttons-stick-bottom' >
                        <Stack direction="row" spacing={2} justifyContent='flex-end'>
                            {formik?.values?.show_as_talent && formik?.values?.isNext == 1 && //formik?.values?.isNext == 1 && 
                                <EButtonOutlined onClick={() => { setFieldValue("isNext", 0); }} variant="outlined" size="large"> Back </EButtonOutlined> //back button
                            }

                            <EButtonOutlined color="secondary" onClick={() =>  close()} variant="outlined" size="large"> Cancel </EButtonOutlined> {/* Cancel button */}

                            {(formik?.values?.talent_name && isEdit && formik?.values?.show_as_talent && formik?.values?.isNext == 0 && formik?.values?.role?.value == SUPER_ADMIN) ?
                                <EButton variant="contained" size="large" onClick={() => { isSaveAndNext && setFieldValue("isNext", 1); }}> Update & Next </EButton> // Save & Next button 
                                :
                                (formik?.values?.talent_name || isEdit) ?
                                    <ELoadingButton type="submit" variant="contained" size="large" // Update & create button
                                        loading={loadingButton}> {isEdit ? 'Update' : 'Create'}
                                    </ELoadingButton>
                                    : ''
                            }
                        </Stack>
                    </Grid>

                </Grid>
            </Form>
        </FormikProvider>
    </>
    );
}

export default AddNewUserForm;
