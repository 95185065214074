import { Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { HoldPaymentFormValidation } from "../../utils/validation";
import { CancelTalentPayment } from "../../action/PaymentAction";
import { Grid } from "@mui/material";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import { EFieldLabel, ETypography } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { ECard } from "../../BaseComponents/ECard";
import { numberWithCommas } from "../../utils/formatNumber";


const PaymentCancelModal = (props) => {    
    const { paymentData, setSalaryDueModal, auth, getPaymentFilterData } = props;

    const payAmount = Number(paymentData?.pay_amount) ? Number(paymentData?.pay_amount) : 0;
    const paidSalary = Number(paymentData?.transaction?.collect_amount) ? Number(paymentData?.transaction?.collect_amount) : 0;
    const balanceAmount = payAmount - paidSalary

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            remark: ''
        },
        validationSchema: HoldPaymentFormValidation,
        onSubmit: (data) => {
            data = {
                ...data,
                employee_id: paymentData.employee_id,
                payroll_id: paymentData.payroll_id,
                transaction_id: paymentData.id,
                created_by: auth?.authData?.id
            };
            dispatch(CancelTalentPayment(auth.authtoken, data, getPaymentFilterData));
            setSalaryDueModal({ modalData: null, modalCategory: null })
        }
    });
    const { errors, touched, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik} >
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
            {/* <Grid container spacing={2} sx={{ paddingTop: "10px" }} >
                asd
            </Grid> */}
            <Grid container spacing={2} sx={{ paddingTop: "10px" }} >
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <ECard className='modal-info-box width-100 ' >
                        {paymentData?.employeeData && <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>{`${paymentData?.employeeData?.fullname} - ${paymentData?.employeeData?.employee_code}`}</ETypography>}
                        {balanceAmount && <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>{`Balance Payable Amount : ${numberWithCommas(balanceAmount)}`}</ETypography>}
                    </ECard>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <FieldContainerCustom
                        labelwidth={50}
                    >
                        <EFieldLabel>Remark</EFieldLabel>
                        <ETextField
                            name='remark'
                            multiline
                            rows={3}
                            {...getFieldProps('remark')}
                            fullWidth
                            error={Boolean(touched.remark && errors.remark)}
                            helperText={touched.remark && errors.remark}
                        />
                    </FieldContainerCustom>
                </Grid>
            </Grid>
            <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                <EButtonOutlined size="large" variant="outlined"
                    onClick={() => setSalaryDueModal({ modalData: null, modalCategory: null })}
                > Cancel </EButtonOutlined>
                <ELoadingButton
                    size="large" variant="outlined" type="submit" >Decline</ELoadingButton>
            </Stack>
        </Form>
    </FormikProvider>
    );
};

export default PaymentCancelModal