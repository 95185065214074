import React, { useEffect } from "react";
import { FormControl, FormControlLabel, Grid, Stack } from "@mui/material";
import { useFormik, Form, FormikProvider } from 'formik';
import { UpdateContractPaymentDetailsValidation } from "../../../utils/validation";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useDispatch } from "react-redux";
import { CONTRACT_PAYMENT_DETAILS_UPDATE_API, GST_SLAB_OPTIONS, GST_TYPE_OPTIONS } from "../../../constants";
//actions
import { PaymentTermsCreateUpdate } from "../../../action/ContractAction";
//base components
import { EButtonOutlined, ELoadingButton } from "../../../BaseComponents/EButtons";
import { ELabelInputMainGrid } from "../../../BaseComponents/EGrid";
import { EInputAdornment, ETextField } from "../../../BaseComponents/ETextField";
import { EAutocomplete } from "../../../BaseComponents/EAutocomplete";
import { ERadio, ERadioGroup } from "../../../BaseComponents/ERadio";
import { EFormHelperText } from "../../../BaseComponents/ETypography";
import { AddGstPercentage } from "../../../utils/formatNumber";
import { EIcon } from "../../../BaseComponents/EIcon";
import CancelIcon from "@iconify/icons-material-symbols/cancel-outline-rounded";

const EditModalPaymentDetails = (props) => {
    const { auth, close,contract } = props;
    const dispatch = useDispatch();
    const contractDetails=contract?.contractData
    
    const formik = useFormik({
        initialValues: {
            contract_amount: contractDetails?.contract_amount ? contractDetails?.contract_amount : '',
            contract_with_gst: contractDetails?.total_amount ? Number(contractDetails?.total_amount): Number(contractDetails?.contract_amount) ? Number(contractDetails?.contract_amount) : '',
            gst: null,
            is_igst: null,
            isAdvance: contractDetails?.advance_applicable == false ? "false" : "true",
        },
        validationSchema: UpdateContractPaymentDetailsValidation,
        onSubmit: (data) => {
            data.gst = data?.gst?.id;
            data.payout_in_fix = data?.payout_in_fix == "false" ? false : true
            data.is_igst=data.is_igst.id
            data = { ...data, contract_id: contractDetails?.id, total_amount: data.contract_with_gst }
            dispatch(PaymentTermsCreateUpdate(auth?.authtoken, data, close, CONTRACT_PAYMENT_DETAILS_UPDATE_API))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;

    useEffect(() => {
        if (contractDetails) {
            if (contractDetails?.gst) {
                let find_gst = GST_SLAB_OPTIONS?.find(element => element.id == Number(contractDetails?.gst));
                setFieldValue('gst', { id: find_gst?.id, label: find_gst?.label })
            } else {
                setFieldValue('gst', null)
            }
            if (contractDetails?.is_igst!=null) {

                let find_gst = GST_TYPE_OPTIONS?.find(element => element.id == Number(contractDetails?.is_igst));
                setFieldValue('is_igst', { id: find_gst?.id, label: find_gst?.label })
            } else {
                setFieldValue('is_igst', null)
            }
        }
    }, [contractDetails]);

    const ContractAmountOnChange=(e)=>{
        setFieldValue('contract_amount', e?.target?.value? e?.target?.value:'')
        if (formik?.values?.gst && formik?.values?.gst?.id>=0 && e?.target && Number(e?.target?.value)) 
        { setFieldValue('contract_with_gst', AddGstPercentage(Number(e?.target?.value), Number(formik?.values?.gst?.id), "isNotRs")) }
        else { setFieldValue('contract_with_gst', '') }
    }
   
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <ELabelInputMainGrid label={'Amount'} >
                                    <ETextField
                                        fullWidth
                                        {...getFieldProps('contract_amount')}
                                        placeholder="0.00"
                                        onChange={(e) => { ContractAmountOnChange(e) }}
                                        InputProps={{
                                            startAdornment: (<EInputAdornment position="start"><span className="color-text-primary ">₹ </span></EInputAdornment>),
                                            endAdornment: (<EInputAdornment position="start" className={formik?.values?.contract_amount ? "cursor-pointer" : 'display-none'}
                                                onClick={() => { ContractAmountOnChange(null) }}
                                            >
                                                <EIcon icon={CancelIcon} /></EInputAdornment>),
                                        }}
                                        error={Boolean(touched.contract_amount && errors.contract_amount)}
                                        helperText={touched.contract_amount && errors.contract_amount}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ELabelInputMainGrid label={'GST Slab'}>
                                    <EAutocomplete
                                        name="gst"
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...getFieldProps('gst')}
                                        options={GST_SLAB_OPTIONS}
                                        getOptionLabel={(option) => option.label || ''}
                                        onChange={(e, value) => {
                                            setFieldValue('gst', value ? value : null);
                                            if (formik?.values?.contract_amount && value && value?.id) { 
                                            setFieldValue('contract_with_gst', AddGstPercentage(Number(formik?.values?.contract_amount), Number(value?.id), "isNotRs")) }
                                            else { setFieldValue('contract_with_gst', Number(formik?.values?.contract_amount)) }
                                        }}
                                        renderInput={(params) => (
                                            <ETextField
                                                {...params}
                                                name="gst"
                                                placeholder={contract?.contractLoading?'loading...':"Select GST Slab"}
                                                fullWidth
                                                error={Boolean(touched.gst && !contract?.contractLoading && errors.gst)}
                                                helperText={touched.gst && !contract?.contractLoading && errors.gst}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ELabelInputMainGrid label={'GST Type'} >
                                    <EAutocomplete
                                        disabled={true}
                                        name="is_igst"
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...getFieldProps('is_igst')}
                                        options={GST_TYPE_OPTIONS}
                                        getOptionLabel={(option) => option.label || ''}
                                        onChange={(e, value) => {
                                            setFieldValue('is_igst', value);
                                        }}
                                        renderInput={(params) => (
                                            <ETextField
                                                {...params}
                                                name="is_igst"
                                                placeholder={contract?.contractLoading?'loading...':"Select"}
                                                fullWidth
                                                error={Boolean(touched.is_igst && errors.is_igst)}
                                                helperText={touched.is_igst && errors.is_igst}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                          
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ELabelInputMainGrid label={'Total Amount'}>
                                    <ETextField
                                        fullWidth
                                        disabled
                                        {...getFieldProps('contract_with_gst')}
                                        InputProps={{
                                            startAdornment: (<EInputAdornment position="start"><span >₹ </span></EInputAdornment>)
                                        }}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ELabelInputMainGrid label={'Advance'} labelPT='0.5rem'>
                                    <FormControl
                                        component="fieldset"
                                        error={Boolean(touched.isAdvance && errors.isAdvance)}
                                    >
                                        <ERadioGroup
                                            row
                                            aria-label="isAdvance"
                                            name="isAdvance"
                                            {...getFieldProps('isAdvance')}
                                        >
                                            <FormControlLabel value="true" control={<ERadio />} label="Yes" />
                                            <FormControlLabel value="false" control={<ERadio />} label="No" />
                                        </ERadioGroup>
                                        {touched.isAdvance && <EFormHelperText> {errors.isAdvance}</EFormHelperText>}
                                    </FormControl>
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12} className="modal1-buttons-stick-bottom">
                                <Stack direction="row" spacing={2}>
                                    <EButtonOutlined
                                        size="large"
                                        variant="outlined"
                                        onClick={() => {
                                            close();
                                            resetForm();
                                        }}
                                    >
                                        {' '}
                                        Cancel{' '}
                                    </EButtonOutlined>
                                    <ELoadingButton
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    loading={contract?.contractLoading}
                                    >
                                        Update
                                    </ELoadingButton>

                                </Stack>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Form>
            </FormikProvider>
        </>
    )
};

export default EditModalPaymentDetails;
