
import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { ETable, ETableBody, ETableCell, ETableContainer, ETableRowSmall } from '../../BaseComponents/ETable';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { PAYMENT_CATEGORY, PAYMENT_CATEGORY_SALARY, PENDING_STATUS, COMPLETE_STATUS, ROW_SIZE_PER_PAGE, DEFAULT_ROWS_PERPAGE } from '../../constants';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import EScrollbar from '../../BaseComponents/EScrollbar';
import EListHeadSmall from '../../BaseComponents/ETable';
import SearchNotFound from '../SearchNotFound';
import { ECard } from '../../BaseComponents/ECard';
import { EDividerCard } from '../../BaseComponents/EDivider';
import { ETypographyCardHeading } from '../../BaseComponents/ETypography';
import EChip from '../../BaseComponents/EChip';
import { capitalizeCapitalString } from '../../utils/formatText';
import { numberWithCommas } from '../../utils/formatNumber';
import { EmployeeRequestPaymentList } from '../../action/PaymentAction';
import { EIcon } from '../../BaseComponents/EIcon';
import { ETooltip } from '../../BaseComponents/ETooltip';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';

const TABLE_HEAD = [
    { id: 'Contract Name', label: ' Contract Name', alignRight: false },
    { id: 'submitted On', label: 'Submitted On', alignRight: 'center' },
    { id: ' Amount', label: ' Amount', alignRight: true },
    { id: 'Transaction Date', label: 'Transaction Date', alignRight: 'center' },
    { id: 'Payment Mode', label: 'Payment Mode', alignRight: 'center' },
    { id: 'Status', label: 'Status', alignRight: 'center' },
];
/**
 * [2022-11-24]
 * Created by:- Aanchal Sahu
 * Description:- designed this component according to view profile
 **/
function FreelancerViewPayments(props) {
    const { auth, freelancer } = props;
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [SearchParameters, setSearchParameters] = useState({
        freelancer_id: freelancer?.id
    });
    const payment = useSelector((state) => state.PaymentReducer);

    let PAYMENT_CATEGORY_WITH_SALARY = [];

    if (PAYMENT_CATEGORY_WITH_SALARY?.length == 0) {
        PAYMENT_CATEGORY_WITH_SALARY.push(...PAYMENT_CATEGORY);
        PAYMENT_CATEGORY_WITH_SALARY.push({ id: PAYMENT_CATEGORY_SALARY, label: 'Salary' })
        // PAYMENT_CATEGORY_WITH_SALARY[3] = { id: PAYMENT_CATEGORY_SALARY, label: 'Salary' };
    }

    const handleChangePage = (event, newPage) => {
        callApi(SearchParameters, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(SearchParameters, 0, rowPerPageCount);
        setPage(0);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(EmployeeRequestPaymentList(auth.authtoken, 'request_freelancer', fnParams, fnPage, fnRowPerPage));
    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi(SearchParameters, page, rowsPerPage);
        }
    }, [auth?.authtoken])

    return (
        <>
            <ECard sx={{ padding: '20px', height: 'auto', textAlign: 'left', mx: "auto" }}>
                <Grid container display="row" justifyContent="space-between" alignItems='center'>
                    <Grid item>
                        <ETypographyCardHeading variant='h5'>Payment Details</ETypographyCardHeading>
                    </Grid>
                    <Grid xs={12} lg={12} item className='pb-1rem '>
                        <EDividerCard className='margin-top-05' />
                    </Grid>
                </Grid>
                <EScrollbar>
                    <ETableContainer >
                        <ETable>
                            <EListHeadSmall
                                headLabel={TABLE_HEAD}
                                onRequestSort={() => null}

                            />
                            {!payment?.PaySalaryListLoading && payment?.PaySalaryListData?.rows?.length > 0 ? (
                                <ETableBody>
                                    {payment?.PaySalaryListData?.rows?.map((row, index) => {
                                        const { transaction_status,transaction_date, ContractWorkFace, payment_medium, pay_amount, accountant_remark, transaction_status_date } = row;
                                        return (
                                            <ETableRowSmall
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                            >
                                                <ETableCell align='left' className='minWidth-160px'>
                                                    <span className='bold-700'>   {ContractWorkFace?.ContractWork?.contract_title ? ContractWorkFace?.ContractWork?.contract_title : '-'}</span>
                                                    <br />
                                                    {ContractWorkFace?.ContractWork?.contract_code ? '('+ContractWorkFace?.ContractWork?.contract_code +')': '-'}
                                                </ETableCell>
                                                <ETableCell align='center'>
                                                     {transaction_date  ?  moment(transaction_date).format('DD/MM/YYYY') : '-'} 
                                                </ETableCell>
                                                <ETableCell align="right" className='minWidth-150'>
                                                    {pay_amount? numberWithCommas(pay_amount):''}
                                                </ETableCell>
                                                <ETableCell align="center">
                                                    {transaction_status === COMPLETE_STATUS && transaction_status_date ? moment(transaction_status_date).format('DD/MM/YYYY') : '-'}
                                                </ETableCell>
                                                <ETableCell align="center">
                                                    {payment_medium ? capitalizeCapitalString(payment_medium) : '-'}
                                                </ETableCell>
                                                <ETableCell align="center" >
                                                    {transaction_status ? 
                                                    <span className='display-flex align-center' >
                                                    <EChip label={capitalizeCapitalString(transaction_status=='COMPLETE'?'PAID':transaction_status)} width={'100px'}> 
                                                    </EChip>
                                                    {transaction_status != PENDING_STATUS && accountant_remark &&
                                                        <ETooltip title={accountant_remark} arrow >
                                                            <EIcon icon={InfoIcon} className='grey-color' />
                                                        </ETooltip>}
                                                        </span>
                                                        : '-'}
                                                </ETableCell>

                                            </ETableRowSmall>
                                        );
                                    })}
                                </ETableBody>
                            ) : (<>
                                {payment?.PaySalaryListLoading ? (
                                    <ETableBody>
                                        <ETableRowSmall>
                                            <ETableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                                <CircularProgress color="primary" />
                                            </ETableCell>
                                        </ETableRowSmall>
                                    </ETableBody>
                                ) : (<>
                                    <ETableBody>
                                        <ETableRowSmall>
                                            <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                <SearchNotFound />
                                            </ETableCell>
                                        </ETableRowSmall>
                                    </ETableBody>
                                </>)
                                }
                            </>)}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {payment?.PaySalaryListData && payment?.PaySalaryListData?.count > 10 &&
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={payment?.PaySalaryListData?.count ? payment?.PaySalaryListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </ECard>
        </>
    );
}

export default FreelancerViewPayments