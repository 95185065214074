import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import Page from '../../BaseComponents/EPage';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { EButton, EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import { ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EDivider } from '../../BaseComponents/EDivider';
import { EBoxPage } from '../../BaseComponents/EBox';
import EHidden from '../../BaseComponents/EHidden';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { EIcon } from '../../BaseComponents/EIcon';
import { ECard } from '../../BaseComponents/ECard';
/**
 * [2022-11-29]
 * Created by:- Aanchal Sahu
 * Description:- designed this component & made it dynamic.
 **/

export default function Reports() {
  const navigate = useNavigate();

  return (
    <Page title={"Attendance Log Report"}>
      <Grid container display="row" justifyContent="space-between " className='align-center'>
        <Grid item>
          <EHeaderBreadcrumbs
            heading='Attendance Log Report'
            links={[
              { name: 'Dashboard', href: '/' },
              { name: 'Report', href: '/report' },
              { name: 'Attendance Log Report' }
            ]}
          />
        </Grid>
        <EHidden width="mdDown">
          <Grid item>
            <ETooltip title="Back" arrow>
              <EButtonOutlined variant="outlined" onClick={() => navigate(-1)}>
                Back
              </EButtonOutlined>
            </ETooltip>
          </Grid>
        </EHidden>
      </Grid>

      <Grid container display="row" justifyContent="space-between" className='pb-2rem margin-top-20px'>
        <Grid item>
          <ETypographyPageHeading variant='h5'>Attendance Log Report</ETypographyPageHeading>

        </Grid>
        <EHidden width="mdUp">
          <Grid item>
            <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
              <EIcon icon={ForwardArrow} className='height-width-15px'/> </EButtonOutlinedIcon>
          </Grid>
        </EHidden>
        <EHidden width="mdDown">
        <Grid lg={12} xs={12} item><EDivider className='margin-top-05' /></Grid>
        </EHidden>
       
      </Grid>
      <EBoxPage>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <BoxComponent url="/report/attendance-log-report/by-date" name="By Date" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <BoxComponent url={"/report/attendance-log-report/by-talent"} name="By Name" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <BoxComponent url={"/report/attendance-log-report/by-time"} name="By Time" />
          </Grid>
        </Grid>
      </EBoxPage>
    </Page>
  );
}

function BoxComponent(props) {
  const navigate = useNavigate();
  const { url, name } = props;
  return (
    <ECard className="report-card-design cursor-pointer height-310px p0"
      onClick={() => {
        if (url) {
          navigate(`${url}`);
        }
      }}>
      <img
        alt={name}
        src='/assets/images/report.svg'
        className="width-100 height-100per-50px"
      />
      <EButton
        className="border-radius-0088 width-100 height-50px"
        variant="contained"
      >
        {name}{' '}
      </EButton>
    </ECard>
  );
}
