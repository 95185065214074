import axios from 'axios';
import moment from 'moment';
import { EMP_ATTENDANCE_FAILURE, EMP_ATTENDANCE_SUCCESS, EMP_ATTENDANCE_LOADING, EMP_ATTENDANCE_EMPTY, SERVER_URL, REPORT_BY_TIME} from '../constants';
import { Logout } from './AuthAction';


/**
 * [2022-09-22]
 * Created by:- Aanchal Sahu
 * Description:- designed this action for attendance report by date and by talents
 **/
export function UserAttendanceReportLog(token, data, page=null,rowsPerPage=null) {
    let params = ``;
    let url = data?.report_type==REPORT_BY_TIME?`user-attendance-by-time`:`user-attendance-log`;
    const AndCondition=(params==``?'&':'')
    if (page !=null  && rowsPerPage != null ) {
        params += `page=${page}&per_page=${rowsPerPage}`;
    }
    
    if (data?.attendance_month && data?.attendance_month!=null && data?.report_type!=REPORT_BY_TIME) {//__CHECK_____
        params += AndCondition+`attendance_month=${moment(data?.attendance_month).format('YYYYMM')}`;
    }
    if (data?.attendance_date && data?.attendance_date!=null ) {
        params += AndCondition+`attendance_date=${data?.attendance_date}`;
    }
    if (data?.employee_id && data?.employee_id != null && data?.report_type!=REPORT_BY_TIME) {//__CHECK_____
        params += AndCondition+`employee_id=${data?.employee_id}`
    }
    if (data?.report_type==REPORT_BY_TIME && data?.attendance_time && data?.attendance_time != null ) {
        params += AndCondition+`attendance_time=${moment(data?.attendance_time).format('HH:mm')}`
        params += AndCondition+`sort=${data?.sort}`
        if(data?.start_date){params += AndCondition+`from_date=${moment(data?.start_date).format('YYYY-MM-DD')}`}
        if(data?.end_date){params += AndCondition+`to_date=${moment(data?.end_date).format('YYYY-MM-DD')}`}
    }

    return (dispatch) => {
        dispatch(attendanceListLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/report/${url}?${params}`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(attendanceListSuccess(res.data))
                }
            }).catch((error) => {

                if (error?.response) {
                    dispatch(attendanceListFailure(error.response.data));
                    if (error?.response?.status == 401) {
                      dispatch(Logout())
                    }
                }
            });
    };
}
export function attendanceListLoading() {
    return {
        type: EMP_ATTENDANCE_LOADING,
    };
}
export function attendanceListSuccess(payload) {
    return {
        type: EMP_ATTENDANCE_SUCCESS,
        payload
    };
}
export function attendanceListEmpty() {
    
    return {
        type: EMP_ATTENDANCE_EMPTY
    };
}

export function attendanceListFailure(payload) {
    return {
        type: EMP_ATTENDANCE_FAILURE,
        payload
    };
}