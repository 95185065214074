import React,{ useState }  from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { DownLoadFile } from "../../../action/AdminAction";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import FileDownloadIcon from "@iconify/icons-material-symbols/sim-card-download-outline-rounded";
import FilePDF from "@iconify/icons-material-symbols/picture-as-pdf-rounded";
import FileCSV from "@iconify/icons-material-symbols/file-present-rounded";
import {
  DOWNLOAD_CSV,
  DOWNLOAD_PDF,
  CONTRACT_LIST_DOWNLOAD_URL,
} from "../../../constants";
import EMenuPopover, {
  EMenuIcon,
  EMenuItem,
} from "../../../BaseComponents/EMenuPopover";
import { EIconButton } from "../../../BaseComponents/EButtons";
import SortByAlphaIcon from "@iconify/icons-material-symbols/sort-by-alpha";
import { EIcon, EIconInputAdornment } from "../../../BaseComponents/EIcon";
import {
  EInputAdornment,
  ETextFieldSearch,
} from "../../../BaseComponents/ETextField";
import { ETooltip } from "../../../BaseComponents/ETooltip";
import CancelIcon from "@iconify/icons-material-symbols/cancel-outline-rounded";
import { EDatePicker } from "../../../BaseComponents/EDatePicker";

import moment from "moment";

/**
 * [2022-12-12]
 * Author: Aanchal Sahu
 **/
function ContractSearchForm(props) {
  const dispatch = useDispatch();
  const { search, setSearch, auth } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [yearmonthValue, setyearmonthValue] = useState(
   ""
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const open = Boolean(anchorEl);

  const ReportDownload = (value) => {
    let url = CONTRACT_LIST_DOWNLOAD_URL;
    let file_name = "Contract_list";
    if (value != null) {
      url += `?download_format=${value}`;
    }
    if (search?.name && search?.name != null) {
      url += `&freelancee_name=${search?.name}`;
    }
    if (search?.role != null) {
      url += `&role=${search?.role}`;
    }
    if (search?.sort && search?.sort != null) {
      url += `&sort=${search?.sort}`;
    }
    dispatch(DownLoadFile(auth.authtoken, url, file_name));
    handleClose();
  };
  return (
    <>
      <Grid container className="mb-16px">
        <Grid item xs={10} sm={10} lg={10.5} md={10.5} xl={11}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={5} sm={5} md={4} lg={4} xl={3}>
              {/* Search Field */}
              <ETextFieldSearch
               fullWidth
                id="outlined-basic"
                placeholder="Search By Contract Name"
                variant="outlined"
                size="small"
                // className='width-230px'
                value={search?.name}
                onChange={(event) => {
                  setSearch({
                    ...search,
                    name: event.target.value ? event.target.value : "",
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <EInputAdornment
                      position="end"
                      sx={{
                        margin: "0px",
                        visibility: search?.name ? "visible" : "hidden",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        setSearch({ ...search, name: "" });
                      }}
                    >
                      <EIcon icon={CancelIcon} />
                    </EInputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={5} sm={5} md={4} lg={4} xl={3}>
              {/* Date Picker */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <EDatePicker
                  openTo="month"
                  views={["month"]}
                  inputFormat="MMM yyyy"
                  value={yearmonthValue?yearmonthValue:null}
                  onChange={(newValue) => {
                    setyearmonthValue(
                      newValue && newValue != "Invalid Date" ? newValue : null
                    );
                    setSearch({
                      ...search,
                      year_month: moment(newValue).format("YYYYMM"),
                    });
                  }}
                  renderInput={(params) => (
                    <div className="date-picker-div">
                      {/*this div contains text field and clear icon*/}
                      <ETextFieldSearch
                        size="small"
                        fullWidth
                        {...params}
                        onKeyDown={onKeyDown}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Select Month",
                        }}
                      />
                      {yearmonthValue ? (
                        <EInputAdornment
                          position="end"
                          onClick={() => {
                            setyearmonthValue(null);
                          }}
                          className="clear-icon"
                        >
                          <EIconInputAdornment
                            icon={CancelIcon}
                            color="primary.main"
                          />
                        </EInputAdornment>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        {/* Download Section */}

        <Grid item xs={2} sm={2} md={1.5} lg={1.5} xl={1}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* Download */}
            <ETooltip title={"Download"} arrow>
              <EIconButton onClick={handleClick}>
                <EIcon icon={FileDownloadIcon} className="height-width-22px" />
              </EIconButton>
            </ETooltip>
            <ETooltip title={"Sort"} arrow>
              {/* Sort */}
              <EIconButton
                onClick={() => {
                  setSearch({
                    ...search,
                    sort:
                      search.sort == null
                        ? "id ASC"
                        : search.sort == "id ASC"
                        ? "id DESC"
                        : "id ASC",
                  });
                }}
              >
                <EIcon icon={SortByAlphaIcon} className="height-width-22px" />
              </EIconButton>
            </ETooltip>
          </Grid>

          <EMenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            sx={{ width: 180, top: 40 }}
          >
            <EMenuItem
              sx={{ typography: "body2", py: 1, px: 2.5 }}
              onClick={() => ReportDownload(DOWNLOAD_PDF)}
            >
              <EMenuIcon icon={FilePDF} /> PDF
            </EMenuItem>
            <EMenuItem
              sx={{ typography: "body2", py: 1, px: 2.5 }}
              onClick={() => ReportDownload(DOWNLOAD_CSV)}
            >
              <EMenuIcon icon={FileCSV} /> CSV
            </EMenuItem>
          </EMenuPopover>
        </Grid>
      </Grid>
    </>
  );
}

export default ContractSearchForm;
