
import React, { useRef, useState } from 'react';
import {
  Grid,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline-rounded'
import { DeliverableCreateUpdateDelete } from '../../../action/ContractAction';
import { EButtonOutlined, EIconButton, ELoadingButton } from '../../../BaseComponents/EButtons';
import { EIcon } from '../../../BaseComponents/EIcon';
import EModal from '../../../BaseComponents/EModal';
import { ETypography } from '../../../BaseComponents/ETypography';
import AddDeliverableForm from './AddDeliverableForm';

export default function ContractViewActions(props) {
  const { row, tableName, isLocked,  auth } = props;
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isDeleteDeliverable, SetIsDeleteDeliverable] = useState(false);
  const [isEditDeliverable, SetIsEditDeliverable] = useState(false);
  const [isDeletePaymentTerm, SetIsDeletePaymentTerm] = useState(false);
  const [isEditPaymentTerm, SetIsEditPaymentTerm] = useState(false);

  const { work_title, id,contract_id } = row
  const work_detail_id = id;

  const Closedelete = () => {
    SetIsDeleteDeliverable(false);
    SetIsEditDeliverable(false);
  };
 
  const HandleDeleteDeliverable = () => {
    let data = { contract_detail_id: work_detail_id, contract_id: contract_id };
    dispatch(DeliverableCreateUpdateDelete(auth?.authtoken, data, 'delete'));
    Closedelete();
  };
  return (
    <>
      <Grid container sx={{ flexWrap: 'nowrap' }}>
        <Grid item> {/*className={isLocked != true ? 'blue-color' : 'grey-color'}*/}
          <EIconButton ref={ref} disabled={isLocked} className='pt-0 pr-6px pb-0 pl-0px'
            onClick={() => tableName == 'paymentTerm' ? SetIsEditPaymentTerm(true) : SetIsEditDeliverable(true)}>
            <EIcon icon={EditIcon}  />
          </EIconButton>
        </Grid>
        <Grid item>
          <EIconButton ref={ref} className='p0'
            onClick={() => tableName == 'paymentTerm' ? SetIsDeletePaymentTerm(true) : SetIsDeleteDeliverable(true)} disabled={isLocked}
            >
            <EIcon icon={DeleteIcon} sx={{ color: 'text.redColor' }}  />
          </EIconButton>
        </Grid>
      </Grid>

      {isDeleteDeliverable && (
        <EModal
          open={isDeleteDeliverable}
          close={() => SetIsDeleteDeliverable(false)}
          headervalue='Delete Deliverable: Confirmation'
        >
          <Grid container spacing={2} >
            <Grid item>
              <ETypography variant="h5" className="font-size-18px font-weight-400"> Are you sure you want to delete
                <span className="theme-main-text-color-bold">{work_title ? " " + work_title : 'this contract?'}  </span> ?</ETypography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
              <EButtonOutlined size="large" variant="outlined" onClick={() => { Closedelete() }} > No </EButtonOutlined>
              <ELoadingButton size="large" type="submit" variant="contained" onClick={HandleDeleteDeliverable} className='button-left-margin'> Yes </ELoadingButton>
            </Grid>
          </Grid>
        </EModal>
      )}

      {isEditDeliverable && (
        <EModal
          open={isEditDeliverable}
          close={() => { Closedelete(); }}
          headervalue="Update Deliverable"
        >
          <AddDeliverableForm
            close={() => { Closedelete(); }}
            auth={auth}
            row={row}
            isEdit={true}
          />
        </EModal>
      )}
    </>
  );
}
