import { Grid, LinearProgress, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ETypography } from "../../../BaseComponents/ETypography";
import { Link, useNavigate } from "react-router-dom";
import { obComplete } from "../../../action/OnBoardingAction";
import { EBox } from "../../../BaseComponents/EBox";
import Page from "../../../BaseComponents/EPage";
import { EButton } from "../../../BaseComponents/EButtons";
import { ECard } from "../../../BaseComponents/ECard";
import { onBoardingNavigate } from "../../../utils/CommonFunctions";
import { CompanyDetail } from "../../../action/CompanyAction";
import { useGreetings } from "../../../hooks/useGreetings";


const OnBoardingSuperAdminSetup = (props) => {
    const { auth } = props;
    const theme = useTheme()
    const company = useSelector((state) => state.CompanyReducer);
    const obData = company.companyData?.onBoarding;
    const {greetMsg} = useGreetings()


    // Initialising total variables
    // used for the logic of Linear progress
    const totalVariables = 6

    // For counting how much settings is completed
    // If only setting_talent_status & setting_attendance_status will be completed, count_ones variable value will be 2
   const count_ones = (
    obData?.setting_talent_status +
    obData?.setting_attendance_status +
    obData?.setting_leave_status +
    obData?.setting_payroll_status +
    obData?.setting_payment_status +
    obData?.setting_additional_status
  )
  
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Dispatch actions when all onboarding steps are completed
     useEffect(() => {
      if (obData && obData?.setting_account_status &&
        obData?.setting_payroll_status &&
        obData?.setting_attendance_status &&
        obData?.setting_additional_status &&
        obData?.setting_leave_status) 
        {
          dispatch(obComplete(auth.authtoken));
          dispatch(CompanyDetail(auth?.authtoken))
        }
      }, [company.companyData?.onBoarding]);



      // On click of "Start" button of company setting
      const handleButtonClick = () => {

        // fetching all settings from onBoarding Data
        const {
          setting_account_status,
          setting_talent_status,
          setting_attendance_status,
          setting_leave_status,
          setting_payroll_status,
          setting_payment_status,
          setting_additional_status,
        } = obData;
      

        // initialising status variable
        const status = {
          setting_account_status,
          setting_talent_status,
          setting_attendance_status,
          setting_leave_status,
          setting_payroll_status,
          setting_payment_status,
          setting_additional_status,
        };


        // calling a common function which Navigates to the appropriate onboarding setting page based on the given status object.
        onBoardingNavigate(status, navigate)
      
      };
  

  return (
    <Page title="Dashboard">

    {/* Welcome message */}
    <ETypography className="font-size-32px bold-300 mb-10px">
       {greetMsg? greetMsg + ", ":null}
      <span className="bold-700">
        {"  "}{auth?.authData?.fullname ? auth?.authData?.fullname + "!" : "!"}
      </span>
    </ETypography>

    {!obData?.onboarding_is_completed ?   <ETypography className="font-size-16px bold-500 mb-16px">
      Need help? &nbsp;  
      <span style={{color:theme.palette.text.blueColor, cursor:'pointer'}} onClick={()=>navigate('/setup/onboarding-options',{state:{fromSetup:true}})} >
        <u>Click Here</u>
      </span>
    </ETypography>:''}

    {/* Get Started */}

    <ECard sx={{background:theme.palette.background.Champagne}}>
      {/* Get started and invite HR */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        // alignItems="center"
        sx={{marginBottom:'16px'}}

      >
        <EBox>
          <ETypography sx={{fontSize:'24px', fontWeight:'600'}}>
            Get Started
          </ETypography>

          <ETypography sx={{fontSize:'16px', fontWeight:'400', color:theme.palette.text.greyColor}}>
            2 steps in less than 2 minutes.
          </ETypography>
        </EBox>

        <EBox>
          <EButton 
            disabled={true}
          >
            Invite HR
          </EButton>
        </EBox>

      </Grid>

      {/* company seetinga and profile */}
      <Grid container item md={12} lg={12} xl={12} xxl={12}           
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        >

          {/* Company Profile Grid */}
          <Grid item  md={6} lg={6} xl={6} xxl={6} sx={{ paddingRight: '8px'}}>
            <ECard sx={{width:'100%', minHeight:'340px'  }}>

              <EBox className="flex-column" sx={{justifyContent: "space-between", height:'170px'}}>
                <ETypography sx={{fontWeight:'600', fontSize:'18px', marginBottom:'16px'}}>Company profile</ETypography>
                <ETypography sx={{fontWeight:'400', fontSize:'14px', marginBottom:'100px'}}>For a seamless experience we kindly request you to share your company profile with us through Entera Payroll.</ETypography>
              </EBox>

              <EBox>

                <EBox className="onboarding-progress-bar">
                  <ETypography sx={{fontWeight:'400', fontSize:'12px'}}>{!obData?.company_profile_completed ? 'Yet to start' : '100% Completed'}</ETypography>
                  <LinearProgress variant="determinate" value={obData?.company_profile_completed ? 100 : 0} />
                </EBox>

                <EBox className="flex-row-horizontaRight" sx={{marginTop:'30px'}}>
                <EButton
                      className={
                        obData?.company_profile_completed
                          ? "onboarding-list-btn-verified align-center"
                          : "onboarding-list-btn"
                      }
                      to={!obData?.company_profile_completed ? "/setup/company-profile" : null}
                      component={Link}
                    >
                      {obData?.company_profile_completed ? "Completed" : "Start"}
                    </EButton>
                </EBox>  
              </EBox>              
            </ECard>
          </Grid>

          {/* Company setting Grid */}

          <Grid item  md={6} lg={6} xl={6} xxl={6} sx={{ paddingRight: '8px'}}>
            <ECard sx={{width:'100%', minHeight:'340px'  }}>

              <EBox className="flex-column" sx={{justifyContent: "space-between", height:'170px'}}>
                <ETypography sx={{fontWeight:'600', fontSize:'18px', marginBottom:'16px'}}>Company Setting</ETypography>
                <ETypography sx={{fontWeight:'400', fontSize:'14px', marginBottom:'100px'}}>To customize Entera Payroll based on your requirement, we request you to setup company settings.</ETypography>
              </EBox>

              <EBox>
                <EBox className="onboarding-progress-bar">
                  <ETypography sx={{fontWeight:'400', fontSize:'12px'}}>{!obData?.company_profile_completed ? 'Yet to start' : ((count_ones / totalVariables) * 100).toFixed(0) + '% completed'}</ETypography>
                  <LinearProgress variant="determinate" value={(count_ones / totalVariables) * 100} />
                </EBox>
                <EBox className="flex-row-horizontaRight" sx={{marginTop:'30px'}}>
                    <EButton
                      className= 'onboarding-list-btn'


                      disabled={! obData?.company_profile_completed}
                      onClick={handleButtonClick}
                      // to={'/setting/talent-setting'}
                      // component={Link}
                    >
                      {
                        obData?.company_profile_completed ? 'Start Now' : "Start"
                      }
                    </EButton>
                </EBox>  
              </EBox>              
            </ECard>
          </Grid>
      </Grid>

    </ECard>

  </Page>
  )
}

export default OnBoardingSuperAdminSetup