
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import base64 from 'base-64';
import Page from '../../BaseComponents/EPage';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import { ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EDivider } from '../../BaseComponents/EDivider';
import { ACTIVE_USER, NIL, RELEASED_USER, ROLE_ADMIN, ROLE_HR, SUPER_ADMIN, INACTIVE_USER, TALENT_SM, PERMISSIONS, USER_WITH_NO_LOGIN } from '../../constants';
import { ETab, ETabContext, ETabList, ETabPanel } from '../../BaseComponents/ETabs';
import TalentEditProfile from '../../PageComponents/Talents/TalentEditProfile';
import { useDispatch } from 'react-redux';
import { GetTalentDetail } from '../../action/TalentActions';
import TalentEditOfficialDetails from '../../PageComponents/Talents/TalentEditOfficialDetails';
import TalentEditPayments from '../../PageComponents/Talents/TalentEditPayments';
import TalentUserLoginView from '../../PageComponents/Talents/TalentUserLoginView';
import LeaveLog from '../../PageComponents/Attendance/LeaveLog';
import EModal from '../../BaseComponents/EModal';
import ReleaseTalentModal from '../../PageComponents/Talents/ReleaseTalentModal';
import TalentReleasedDetail from '../../PageComponents/Talents/TalentReleasedDetail';
import EHidden from '../../BaseComponents/EHidden';
import { EIcon } from '../../BaseComponents/EIcon';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import descriptionOutlineRounded from '@iconify/icons-material-symbols/description-outline-rounded';
import { LoadingGrid } from '../../BaseComponents/EGrid';
import { EBoxPage } from '../../BaseComponents/EBox';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { restoreReleasedTalent } from '../../action/TalentActions';
import { DesignationList } from '../../action/DesignationAction';
import { DepartmentList } from '../../action/DepartmentAction';
import { StateList } from '../../action/StateCityAction';
import RestoreIcon from '@iconify/icons-material-symbols/restore-page-outline-rounded';
import { useCheckPermission } from '../../hooks';

/**
 * [2022-11-23]
 * created By:-AanchaL Sahu
 * Description:- designed this page for my profile
**/

function ViewTalent(props) {
    const { auth, myProfile } = props;
    const {setting} = useCompanySettingProvider()

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();

    const employee = useSelector((state) => state.EmployeeReducer);
    const employeeForm = useSelector((state) => state.EmployeeFormReducer); //INFO: This re ducer is used by forms to update the form data
    const IsFormLoading=employeeForm?.employeeAddLoading

    // getting values from context setting API
    const companyAccountSetting = setting?.accountSettingData?.companySetting;
    const allowed_talent = companyAccountSetting?.company_data?.CompanyActivePlan?.allowed_talent;
    const active_employee_count = companyAccountSetting?.active_employee_count;
    
    const myprofile = myProfile ? true : false;

    //latter remove all payment related variables
    const payment = location?.state?.payment ? base64.decode(location?.state?.payment) : false;
    const employeeData = employee?.employeeProfileData ? employee?.employeeProfileData : {}

    const [showReleaseModal, setShowReleaseModal] = useState(false);
    const [tabvalue, setTabValue] = useState(payment  ? '3' : '1');

    // INFO: getting talent settings
    const settingsContext = useCompanySettingProvider()
    const TalentSetting = settingsContext?.setting?.talentSettingData?.companySetting ?? {}

    const viewReleaseTalent = useCheckPermission([PERMISSIONS.ViewReleaseTalent])
    const allowEdit = useCheckPermission([PERMISSIONS.ViewEditTalent])
    const allowReleaseTalent = viewReleaseTalent && Number(employeeData?.status) === ACTIVE_USER && !myprofile && !employeeData?.is_primary 
    const allowRestoreTalent = viewReleaseTalent && Number(employeeData?.status) === RELEASED_USER && !myprofile && !employeeData?.is_primary 
    
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

     // Handles onClick Restore Button  
     const onClickRestoreButton = (data) => {
       dispatch(restoreReleasedTalent(auth?.authtoken, employee?.employeeProfileData?.id,navigate))
    }


    useEffect(() => {
        if (myProfile && auth.authtoken) {
            dispatch(GetTalentDetail(auth.authtoken, auth.authData.id, navigate));
        }
        if (!myProfile && auth.authtoken && params.id) {
            dispatch(GetTalentDetail(auth.authtoken, base64.decode(params.id), navigate));
            dispatch(DesignationList(auth.authtoken))//INFO: This is called to get all the updated list
            dispatch(DepartmentList(auth.authtoken))//INFO: This is called to get the updated list
            dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions
        }
    }, [myProfile, params?.id, auth.authtoken]);

    // INFO: this useEffect is to set the initial tab to 6 when the talent is released.
    useEffect(() => {
        if (employeeData?.status==RELEASED_USER && !payment) {
            setTabValue('6')
        }else if(!payment){
            //This condition is to reset the tab value (if it's not payment) solving issue caused due to prefilled data of released user in reducer.
            setTabValue('1')
        }
    }, [employeeData?.status]);

    return (
        <Page title={myprofile ? 'My Profile' : 'View Talent'}>
            <Grid container display="row" justifyContent="space-between " className='align-center'>
                <Grid item>
                    {myprofile && <EHeaderBreadcrumbs
                        heading={myprofile ? 'My Profile' : 'View Talent'}
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'My Profile', href: '' },
                            { name: employeeData?.employee_code?employeeData?.employee_code : '' }
                        ]}
                    />}

                    {!myprofile && <EHeaderBreadcrumbs
                        heading={`${employeeData?.employee_code?employeeData?.employee_code:''}`}
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Talent', href: '' },
                            { name:  employeeData?.status==RELEASED_USER?'Released List': employeeData?.status== INACTIVE_USER ?'Archived List':'Talent List', href: '' },
                            { name: `${employeeData?.employee_code?employeeData?.employee_code:''}` }
                        ]}
                    />}
                </Grid>
                {/* INFO: This complonent will be hidden wgen screen size goes below md */}
                <EHidden width="mdDown">
                <Grid item>
                    {
                        allowReleaseTalent && 
                        <EButton variant="contained" onClick={() => navigate('/talent/release-talent',{state:employeeData})} className="mr-16px">  Release </EButton>
                    }
                    {
                        allowRestoreTalent &&
                        <EButton variant="contained" onClick={() => onClickRestoreButton()} className="mr-16px" disabled={active_employee_count >= allowed_talent}>  Restore </EButton>
                    }
                    <EButtonOutlined
                        variant="outlined"
                        onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
                className='margin-top-20px mb-8px'
            >
                <Grid item xs={9} sm={9} md={10} >
                    <ETypographyPageHeading variant='h5'>
                        {employeeData?.fullname ? employeeData?.fullname : NIL}
                        <span className='font-size-14px bold-400 ml-12px '>
                          {employeeData?.designationInfo?.designation_name &&   //INFO: if designation name exist
                                (myprofile && employeeData.is_primary && employeeData.show_as_talent ? true : //INFO: if user is primary & my profile is opened & show_as_talent==1
                                    myprofile && employeeData.is_primary && !employeeData.show_as_talent ? false : true) ? //INFO: if user is primary & my profile is opened & show_as_talent!=1
                                employeeData?.designationInfo?.designation_name : ''}
                        </span>
                    </ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item xs={3} sm={3} md={2} className='display-flex justify-content-flex-end'>
                        {
                            allowReleaseTalent &&
                            <EButtonIcon  onClick={() => navigate('/talent/release-talent',{state:employeeData})} variant="contained" >
                                <EIcon icon={descriptionOutlineRounded} className='height-width-15px'/>
                            </EButtonIcon> 
                        }
                        {
                            allowRestoreTalent &&
                            <EButtonIcon onClick={() => onClickRestoreButton()} variant="contained" >
                                <EIcon icon={RestoreIcon} className='height-width-15px'/>
                            </EButtonIcon> 
                        }
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                            <EIcon icon={ForwardArrow} className='height-width-15px'/> </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
            </Grid>
            <EHidden width="mdDown">
                <EDivider className='mt-8px' />
            </EHidden>

            <EBoxPage className='pt-0' >

                {/* {employeeData?.status != ACTIVE_USER && !employee?.employeeProfileLoading &&
                    <TalentStatusBox employeeData={employeeData} />
                } */}
                {!employee?.employeeProfileLoading?
                <Grid>

                    <ETabContext value={tabvalue}>
                        <ETabList onChange={handleChange} aria-label="lab API tabs example" allowScrollButtonsMobile variant="scrollable"
                        alignitems='left!important' >
                            <ETab label="Release Details" value="6" 
                            sx={{ display: (employeeData?.employeeReleaseInfo != null && employeeData?.status==RELEASED_USER && !employee?.employeeProfileLoading) ?"inline-flex":'none'}} />
                            <ETab label="Personal Profile" value="1" />
                            {/* display: "none" conditions are for the super admin  variant="scrollable"  */}
                            <ETab label="Official Details" value="2" 
                            sx={{ display: !payment && (employeeData?.role != SUPER_ADMIN || (employeeData?.role == SUPER_ADMIN && employeeData?.show_as_talent == true)) ? "inline-flex" : 'none' }} />
                            <ETab label="Payment Details" value="3" sx={{ display: employeeData?.role != SUPER_ADMIN || (employeeData?.role == SUPER_ADMIN && employeeData?.show_as_talent == true) ? "inline-flex" : 'none' }} />
                            {
                                employeeData?.is_active !== USER_WITH_NO_LOGIN && <ETab label="Login  Details" value="4" />
                            }
                            
                            <ETab label="Leave Log" value="5" sx={{ display: employeeData?.role != SUPER_ADMIN || (employeeData?.role == SUPER_ADMIN && employeeData?.show_as_talent == true) ? "inline-flex" : 'none' }} />
                        </ETabList>

                        <ETabPanel value="6" className='ETabPanel-css'>
                            <Grid container >
                                <Grid item xs={12} lg={12}>
                                    {employeeData &&
                                        Object.keys(employeeData).length > 0 && 
                                        <TalentReleasedDetail
                                            auth={auth}
                                            employee={employeeData}
                                            isMyprofile={myprofile}
                                        />
                                    }

                                </Grid>
                            </Grid>
                        </ETabPanel>

                        <ETabPanel value="1" className='ETabPanel-css'>
                            <Grid container >
                                <Grid item xs={12} lg={12}>
                                    {employeeData &&
                                        Object.keys(employeeData).length > 0 && 
                                        <TalentEditProfile
                                            auth={auth}
                                            employee={employeeData}
                                            isMyprofile={myprofile}
                                            loading={IsFormLoading}
                                            TalentSetting={TalentSetting}
                                            allowEdit={allowEdit}
                                        />
                                    }

                                </Grid>
                            </Grid>
                        </ETabPanel>
                        <ETabPanel value="2" className='ETabPanel-css'>
                            <Grid container >
                                <Grid item xs={12} lg={12}>
                                    {employeeData &&
                                        Object.keys(employeeData).length > 0 &&

                                        <TalentEditOfficialDetails
                                            employee={employeeData}
                                            auth={auth}
                                            isMyprofile={myprofile}
                                            allowEdit={allowEdit}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </ETabPanel>
                        <ETabPanel value="3" className='ETabPanel-css'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12}>
                                    <TalentEditPayments setting={setting} employee={employeeData} isMyprofile={myprofile} auth={auth} />
                                </Grid>
                            </Grid>
                        </ETabPanel>
                        <ETabPanel value="4" className='ETabPanel-css'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12}>
                                    <TalentUserLoginView employee={employeeData} auth={auth} isMyprofile={myprofile} allowEdit={allowEdit}/>
                                </Grid>
                            </Grid>
                        </ETabPanel>


                        <ETabPanel value="5" className='ETabPanel-css'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12}>
                                    <LeaveLog
                                        isMyProfile={myprofile}
                                        talent_id={employeeData?.id}
                                    />
                                    {/* <TalentLeaveLogs employee={employeeData} isMyprofile={myprofile} /> */}
                                </Grid>
                            </Grid>
                        </ETabPanel>
                    </ETabContext>
                </Grid>
                    : 
                    <LoadingGrid/>
                    }

                {showReleaseModal &&
                    <EModal open={showReleaseModal}
                        headervalue={`Release Talent - ${employeeData?.fullname}`}
                    >
                        <ReleaseTalentModal
                            auth={auth}
                            employeeData={employeeData}
                            setShowReleaseModal={setShowReleaseModal} />

                    </EModal>}
            </EBoxPage>
        </Page>
    );
}

export default ViewTalent
