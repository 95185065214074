

import { FormControlLabel, Grid, Link, Radio, RadioGroup } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect } from 'react';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { FieldContainerCustom } from '../../BaseComponents/EFieldContainer';
import { EFieldLabelBold, ETypography } from '../../BaseComponents/ETypography';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { numberWithCommas } from '../../utils/formatNumber';
import { EIcon } from '../../BaseComponents/EIcon';
import AttachFile from '@iconify/icons-material-symbols/attach-file';
import { APPROVED_STATUS, CANCELLED_STATUS, IMAGE_URL, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT, PENDING_STATUS, REMARK_LIMIT } from '../../constants';
import { ETextField } from '../../BaseComponents/ETextField';
import { ApproveRequestDetailFormValidation } from '../../utils/validation';
import { GetAdvanceBalanceAction, HRAccountantPaymentAction } from '../../action/PaymentAction';


const PaymentRequestRespond = (props) => {
    const { setModalState, auth, selectedData, getPaymentFilterData } = props;
    const dispatch = useDispatch();
    const payment = useSelector((state) => state.PaymentReducer);
    const formik = useFormik({
        initialValues: {
            status: selectedData.modalData?.transaction_status != 'PENDING' ? selectedData.modalData?.transaction_status : 'APPROVED',
            remark: '',
            previous_amount: ''
        },
        validationSchema: ApproveRequestDetailFormValidation,
        onSubmit: (data) => {
            data = { ...data, contract_id: selectedData.modalData?.id };
            dispatch(HRAccountantPaymentAction(auth?.authtoken, data, getPaymentFilterData, "request_status"));
            setModalState({ modalData: null, modalName: null })
        }
    });


    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(GetAdvanceBalanceAction(auth?.authtoken, auth?.authData?.id));
        }
    }, [auth?.authtoken]);


    useEffect(() => {
        if (auth?.authtoken && auth?.authtoken && payment.EmployeePreviousAdvanceLoading == false) {
            const reqamt = Number(payment?.EmployeePreviousAdvanceData?.advance_amount_given) - Number(payment?.EmployeePreviousAdvanceData?.advance_amount_return) ?
                Number(payment?.EmployeePreviousAdvanceData?.advance_amount_given) - Number(payment?.EmployeePreviousAdvanceData?.advance_amount_return) : 0;
            setFieldValue('previous_amount', reqamt);
        }
    }, [payment.EmployeePreviousAdvanceSuccess]);


    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;


    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Talent Name </EFieldLabelBold>
                            <ETypography>{selectedData.modalData?.employeeData?.fullname}</ETypography>
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Request Amount</EFieldLabelBold>
                            <ETypography>{numberWithCommas(selectedData.modalData?.pay_amount)}</ETypography>
                        </FieldContainerCustom>
                    </Grid>


                    {[PAYMENT_CATEGORY_ADVANCE].includes(selectedData.modalData?.transaction_category) &&

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Previous Amount</EFieldLabelBold>
                                <ETypography>{numberWithCommas(getFieldProps('previous_amount').value)}</ETypography>
                            </FieldContainerCustom>
                        </Grid>

                    }
                    {[PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_ADVANCE].includes(selectedData.modalData?.transaction_category) &&

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Reason</EFieldLabelBold>
                                <ETypography>{selectedData.modalData.remark}</ETypography>
                            </FieldContainerCustom>
                        </Grid>

                    }


                    {selectedData.modalData?.reimbursement_attachment ?
                        (<Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Attachment</EFieldLabelBold>
                                {selectedData.modalData?.reimbursement_attachment ? (
                                    <Link target={'_blank'} href={selectedData.modalData?.reimbursement_attachment}>
                                        <EIcon icon={AttachFile} />
                                    </Link>) : <></>}
                            </FieldContainerCustom>
                        </Grid>) : <></>
                    }

                    {selectedData.modalData?.transaction_status == PENDING_STATUS ? (
                        <><Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                                className="align-center"
                            >
                                <EFieldLabelBold>Status</EFieldLabelBold>
                                <RadioGroup row aria-label="status" name="status" {...getFieldProps('status')}>
                                    <FormControlLabel value="APPROVED" control={<Radio />} label="Approve" />
                                    <FormControlLabel value="CANCELLED" control={<Radio />} label="Reject" />
                                </RadioGroup>
                            </FieldContainerCustom>
                        </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <FieldContainerCustom
                                    labelwidth={50}


                                >
                                    <EFieldLabelBold className="align-center mt-16px">Remark</EFieldLabelBold>
                                    <ETextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        disabled={selectedData.modalData?.transaction_status != PENDING_STATUS ? true : false}
                                        {...getFieldProps('remark')}
                                        inputProps={{ maxLength: REMARK_LIMIT }}
                                        error={Boolean(touched.remark && errors.remark)}
                                        helperText={touched.remark && errors.remark}
                                    />
                                </FieldContainerCustom>
                            </Grid>
                        </>
                    ) : (<><Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Status</EFieldLabelBold>
                            <ETypography>{selectedData.modalData?.transaction_status == APPROVED_STATUS
                                ? 'Approved'
                                : selectedData.modalData?.transaction_status == CANCELLED_STATUS
                                    ? 'Reject'
                                    : 'Paid'}
                            </ETypography>
                        </FieldContainerCustom>
                    </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Remark</EFieldLabelBold>
                                <ETypography>{selectedData.modalData?.transaction_status_remark ? selectedData.modalData?.transaction_status_remark : '-'}</ETypography>
                            </FieldContainerCustom>
                        </Grid>

                    </>)}



                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                            <EButtonOutlined size="large" variant="outlined"
                                onClick={() => setModalState({ modalData: null, modalName: null })}
                            > Close </EButtonOutlined>

                            {selectedData.modalData?.transaction_status == PENDING_STATUS && (
                                <ELoadingButton
                                    size="large" variant="outlined" type="submit" >Submit</ELoadingButton>
                            )}

                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
};
export default PaymentRequestRespond;
