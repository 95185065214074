import React from 'react';
import { useDispatch } from 'react-redux';
import { AddDeliverableValidation } from '../../../utils/validation';
import { Grid, Stack} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { CHARACTER_LIMIT } from '../../../constants';
import { DeliverableCreateUpdateDelete } from '../../../action/ContractAction';
import { ELabelInputMainGrid } from '../../../BaseComponents/EGrid';
import { EButton, EButtonOutlined } from '../../../BaseComponents/EButtons';
import { ETextField } from '../../../BaseComponents/ETextField';

function AddDeliverableForm(props) {
  const dispatch = useDispatch();
  const { close, auth, contract, isEdit, row } = props;
  const contractDetails = contract?.contractData
  const formik = useFormik({
    initialValues: {
      work_detail: row?.work_detail ? row?.work_detail : "",
      work_title: row?.work_title ? row?.work_title : ""
    },
    validationSchema: AddDeliverableValidation,
    onSubmit: (data) => {
      if (!isEdit) { 
        data = { ...data, contract_id: contractDetails?.id, created_by: auth?.authData?.id, is_advance: false }
        dispatch(DeliverableCreateUpdateDelete(auth?.authtoken, data, "create"))
      }
      else if (isEdit) {
        data = { ...data, contract_detail_id: row?.id, updated_by: auth?.authData?.id, contract_id: row?.contract_id, is_advance: false }
        dispatch(DeliverableCreateUpdateDelete(auth?.authtoken, data, "update"))
      }
      close();
    }
  });
  const { errors, touched, handleSubmit, getFieldProps} = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} className='px-16px' >
            <Grid item xs={12} xl={12}>
              <ELabelInputMainGrid label={'Title'}  isfullgrid={true} isModal={true}>
                <ETextField
                  name="work_title"
                  {...getFieldProps('work_title')}
                  fullWidth
                  error={Boolean(touched.work_title && errors.work_title)}
                  helperText={touched.work_title && errors.work_title}
                />
              </ELabelInputMainGrid>
            </Grid>
            <Grid item xs={12} xl={12}>
              <ELabelInputMainGrid label={'Description'} isfullgrid={true} isModal={true}>
                <ETextField
                  multiline
                  rows={3}
                  name="work_detail"
                  inputProps={{ maxLength: CHARACTER_LIMIT }}
                  {...getFieldProps('work_detail')}
                  fullWidth
                  error={Boolean(touched.work_detail && errors.work_detail)}
                  helperText={touched.work_detail && errors.work_detail}
                />
                {`${formik.values?.work_detail?.length}/${CHARACTER_LIMIT}`}
              </ELabelInputMainGrid>
            </Grid>

            <Grid item xs={12} xl={12} className="modal1-buttons-stick-bottom">
              <Stack direction="row" spacing={2}>
                <EButtonOutlined onClick={() => close()}  variant="outlined"size="large" >  Cancel </EButtonOutlined>
                <EButton type="submit" variant="contained" size="large"> {row?'Update':'Submit'} </EButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}

export default AddDeliverableForm;
