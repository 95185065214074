import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from "../../BaseComponents/EButtons";
import { EDivider } from "../../BaseComponents/EDivider";
import { useFormik, Form, FormikProvider } from 'formik';
import Page from "../../BaseComponents/EPage";
import { ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { NewFreelancerValidation } from "../../utils/validation";
import { ACTIVE_USER } from "../../constants";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { EmployeeListData } from "../../action/EmployeeAction";
import { useDispatch, useSelector } from "react-redux";
import { ItemBox } from "../../BaseComponents/EBox";
import { CreateFreelancer } from "../../action/FreelancerAction";
import AddFreelancerBasicDetail from "../../PageComponents/FreelancerContracts/Add_freelancer_basic_details";
import AddFreelancerPersonalDetails from "../../PageComponents/FreelancerContracts/Add_freelancer_personal_details";
import AddFreelancerFormDocuments from "../../PageComponents/FreelancerContracts/Add_freelancer_addFormDocuments";
import AddFreelancerBankDetails from "../../PageComponents/FreelancerContracts/Add_freelancer_Bank_Details";
import moment from "moment";
import AddFreelancerProfilePicture from "../../PageComponents/FreelancerContracts/Add_Freelancer_Profile_Picture";
import EHidden from "../../BaseComponents/EHidden";
import { EIcon } from "../../BaseComponents/EIcon";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { StateList } from "../../action/StateCityAction";

const AddFreelancer = (props) => {
    const { auth } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const departmentList = useSelector(state => state.DepartmentReducer.departmentListData?.rows);
    const designationList = useSelector(state => state.DesignationReducer.designationListData?.rows);
    const stateList = useSelector(state => state.StateReducer);
    const freelancer = useSelector(state => state.FreelancerReducer);
    const setting = useSelector((state) => state.CompanySettingReducer);

    const formikFreelancer = useFormik({
        // validateOnChange: false,
        initialValues: {
            freelancer_detail_check: false,
            bank_detail_check: false,
            document_checked: false,
            freelancer_photo_check: false,

            // basicdetails
            freelancer_fname: '',
            freelancer_lname: '',
            freelancer_contact_no: '',
            freelancer_email: '',

            //personaldetails
            freelancer_gender: null,
            freelancer_dob: moment().subtract(18, 'years')._d,
            freelancer_photo: '',
            address_one: '',
            state: null,
            city: null,
            pincode: '',
            landmark: '',
            freelancer_blood_group_obj:null,
            freelancer_marital_status_obj:null,

            //Documents
            requiredDocuments: [],

            //adhaar card
            aadhar_no: '',
            aadhar_file_name: '',

            //pan card
            pan_no: '',
            pan_file_name: '',
            // 
            driving_lic_no: '',
            driving_lic_file: '',
            //
            passport_no: '',
            passport_file: '',

            // bank details
            ifsc_code: '',
            bank_name: '',
            branch_name: '',
            account_no: '',
            account_holder_name: ''
        },
        validationSchema: NewFreelancerValidation,
        onSubmit: (data) => {
        
            if (data && data.freelancer_gender && data.freelancer_gender.id) {
                data.freelancer_gender = data.freelancer_gender.id;
            }
            if (data && data.state && data.state.id) {
                data.state = data.state.id;
            }
            if (data && data.city && data.city.id) {
                data.city = data.city.id;
            }
            if (data && data.freelancer_blood_group_obj && data.freelancer_blood_group_obj.id) { data.freelancer_blood_group = data.freelancer_blood_group_obj.id; }
            if (data && data.freelancer_marital_status_obj && data.freelancer_marital_status_obj.id) { data.freelancer_marital_status = data.freelancer_marital_status_obj.id; }

            if (data) {
                data = { ...data, company_id: auth.authData?.company_id, created_by: auth.authData?.id };
                dispatch(CreateFreelancer(auth?.authtoken, data, "add", navigate));
            }
        }
    });

    const { handleSubmit, setFieldValue } = formikFreelancer;

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER, { is_active: true, form_list: true }))
            dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions
        }
    }, [auth.authtoken])

    // INFO: Here, we are storing the document ids which are required, the following if condition runs only one time
    if (setting?.companySettingData?.doccumentSetting && formikFreelancer?.values?.requiredDocuments?.length == 0) {
        const permissionArray = setting?.companySettingData?.doccumentSetting?.map((row, index) => {
            if (row?.is_required) { return row?.document_id }
            else { return null }
        })
        setFieldValue('requiredDocuments', permissionArray)
    }
    return (
        <Page title="Add Freelancer" >
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Add Freelancer'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Freelancer Contract', href: '' },
                            { name: 'Freelancer List', href: '/freelancer-contract/freelancer-list' },
                            {
                                name: 'Add Freelancer',
                            }
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
            >
                <Grid item>
                    <ETypographyPageHeading> Add Freelancer</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  
                            <EIcon icon={ForwardArrow} className='height-width-15px'/> 
                        </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
            </Grid>
            <EHidden width="mdDown">
                <EDivider className='mt-8px' />
            </EHidden>
            <FormikProvider value={formikFreelancer} >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            py={3}
                        >
                            <Grid item xs={10}>

                                <AddFreelancerBasicDetail
                                    formikFreelancer={formikFreelancer}
                                    departmentList={departmentList}
                                    designationList={designationList}
                                    stateList={stateList}
                                    auth={auth}
                                />
                                <Grid container spacing={{ sm: 0, md: 2 }}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                                        <AddFreelancerProfilePicture formikFreelancer={formikFreelancer} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                                        <AddFreelancerPersonalDetails formikFreelancer={formikFreelancer} />
                                    </Grid>
                                </Grid>

                                <AddFreelancerFormDocuments
                                    formikFreelancer={formikFreelancer} />

                                <AddFreelancerBankDetails
                                    formikFreelancer={formikFreelancer} />

                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center">
                                    <ItemBox><EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Cancel </EButtonOutlined></ItemBox>
                                    <ItemBox><ELoadingButton loading={freelancer?.freelanceLoading} variant="contained" type="submit" className="button-left-margin"> Create </ELoadingButton></ItemBox>
                                </Grid>

                            </Grid>

                        </Grid>


                    </LocalizationProvider>
                </Form>
            </FormikProvider>
        </Page>
    )

};

export default AddFreelancer;
