import axios from 'axios';
import moment from 'moment';
import { Success, Errors } from '../BaseComponents/EToast';
import {
    EMPLOYEE_LEAVE_LIST_FAILURE,
    EMPLOYEE_LEAVE_LIST_SUCCESS,
    EMPLOYEE_LEAVE_LIST_LOADING,

    EMPLOYEE_LEAVE_FAILURE,
    EMPLOYEE_LEAVE_LOADING,
    EMPLOYEE_LEAVE_SUCCESS, 
    SERVER_URL,
    EMPLOYEE_LEAVE_VIEW_LOADING,
    EMPLOYEE_LEAVE_VIEW_SUCCESS,
    EMPLOYEE_LEAVE_VIEW_FAILURE,
    PAGES,DEFAULT_ROWS_PERPAGE_4, DASHBOARD, DEFAULT_ROWS_PERPAGE, MY_LEAVE_TAB
} from '../constants';
import { Logout } from './AuthAction';

// [2022-11-12]
// Changes By: Aanchal Sahu
// Description:- Changes according to new api form and api
// [2023-05-24]
// New Changes By: Aanchal Sahu
// Description: employee_id, full_day_paid_count, full_day_unpaid_count, to_date_half_day_paid_count, to_date_half_day_unpaid_count, from_date_half_day_paid_count& from_date_half_day_unpaid_count have been removed.
export function EmployeeApplyLeave(token, Data, close=null) {

    const data = new FormData();
    data.append('leave_is_multiple', Data.leave_is_multiple);
    data.append('from_date', moment(Data.from_date).format('YYYY-MM-DD'));
    data.append('to_date', moment(Data.to_date).format('YYYY-MM-DD'));
    data.append('from_date_is_half', Data.from_date_is_half);
    data.append('to_date_is_half', Data.to_date_is_half);
    data.append('created_by', Data.created_by);
    data.append('reason', Data.reason); 
    if(Data.leave_head_id){data.append('leave_head_id', Data.leave_head_id);}
    
    return (dispatch) => {
        dispatch(empLeaveLoading());
        axios({
            method: 'post',
            url: `${SERVER_URL}/leave/apply-leave`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
            data: data
        })
            .then((res) => {
                if (res.status === 200) {
                    Success.fire({
                        text: "html",
                        html: `<span style="color:white">${res.data.message}</span>`,
                    })
                    dispatch(empLeaveSuccess(res.data))
                     
                    // since if tab==MY_LEAVE_TAB, useEffect will not be triggered in leave log else it will since we are setting tab = MY_LEAVE_TAB
                    if(Data.fromPage != DASHBOARD && Data?.search?.tab==MY_LEAVE_TAB){
                        dispatch(EmployeeLeaveBalance(token, Data.employee_id))// TO REFLECT AN UPDATED COUNT ON THE MY LEAVE SUMMARY
                    }
                    
                }
            }).catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                    dispatch(empLeaveFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                      }
                }
            }).finally(()=>{
                if(close){close();} //This function is for closing the modal
                
                if(Data.fromPage == DASHBOARD){
                    dispatch(AttendanceLeaveList(token, {employee_id: Data.employee_id,sortBy: 'DESC'}, PAGES, DEFAULT_ROWS_PERPAGE_4))
                }
                else if(Data?.search?.tab==MY_LEAVE_TAB){// since if tab==MY_LEAVE_TAB, useEffect will not be triggered in leave log else it will since we are setting tab = MY_LEAVE_TAB
                    const page=Data?.search?.page?Data?.search?.page: PAGES;
                    const rowsOfPage=Data?.search?.rowsPerPage? Data?.search?.rowsPerPage: DEFAULT_ROWS_PERPAGE;
                    const tab=Data?.search?.tab? Data?.search?.tab: MY_LEAVE_TAB;
                    dispatch(AttendanceLeaveList(token, {tab: tab, ...Data?.search}, page , rowsOfPage))
                }
            })
    };
}

/**
 * Reporting manager and final (HR) leave approval Action
 * Conditions: Here we are checking if the user is an employee/accountant or not;
 *             -> if employee/accountant: it'll be considered as a Reporting managers leave approval
 *             -> other roles: it'll be considered as final approval
 * 
 * [2022-03-22]
 * Author:-Santosh MAhule
 * Description:- Leave approval action 
 * [2022-09-06]
 * Author:- Aanchal Sahu
 * Description:- making approval_remark conditionally compulsory
 */

export function ApprovalLeave(token, Data, close=null) {
    let url='leave/'+(Data?.isRM ?'manager-approve-leave': 'hr-approve-leave')
    
    const data = new FormData();
    data.append('employee_id',Data.employee_id);
    data.append('leave_id',Data.leave_id);
    data.append('approval_status',Data.approval_status);
    data.append('approval_remark',Data.approval_remark);
   
    return (dispatch) => {
        dispatch(empLeaveLoading());
        axios({
            method: 'post',
            url: `${SERVER_URL}/${url}`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
            data: data
        })
            .then((res) => {
                if (res.status === 200) {
                    Success.fire({
                        text: "html",
                        html: `<span style="color:white">${res.data.message}</span>`,
                    })
                    dispatch(empLeaveSuccess(res.data))
                    dispatch(AttendanceLeaveList(token, Data.search, Data.search.page, Data.search.rowsPerPage))
                    if(close){close()}
                }
            }).catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                    dispatch(empLeaveFailure(error.response.data));
                    if(close){close()}
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                      }
                }
            });
    };
}


export function empLeaveLoading() {
    return {
        type: EMPLOYEE_LEAVE_LOADING,
    };
}
export function empLeaveSuccess(payload) {
    return {
        type: EMPLOYEE_LEAVE_SUCCESS,
        payload
    };
}
export function empLeaveFailure(payload) {
    return {
        type: EMPLOYEE_LEAVE_FAILURE,
        payload
    };
}
/**
 * [2022-03-29]
 * changes by: Aanchal Sahu
 * Description:- added approval status,page & per_page
 **/
export function AttendanceLeaveList(token, data,page=null,rowsPerPage=null) {    
    let params = '';
    const AndCondition=(params==''?'&':'')
    if (page!=null  && rowsPerPage!=null ) {
        params += `page=${page}&per_page=${rowsPerPage}`;
    }
    if (data?.employee_id && data?.employee_id != '') {
        params +=AndCondition+ `employee_id=${data?.employee_id}`
    }
    if (data?.fullname && data?.fullname != '') {
        params +=AndCondition+ `fullname=${data?.fullname}`
    }
    if (data?.approval_status) {
        params +=AndCondition+ `approval_status=${data?.approval_status}`
    }
    if (data?.year_month!=null) {
        params +=AndCondition+`year_month=${data?.year_month}`
    }
    if (data?.sortBy!=null) {
        params +=AndCondition +`sort=${data?.sortBy}`
    }
    if(data?.tab){
        params +=AndCondition + `tab=${data?.tab}`
    }
    return (dispatch) => {
        dispatch(empLeaveListLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/leave/employee-leave-list?${params}`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(empLeaveListSuccess(res.data))
                }
            }).catch((error) => {

                if (error?.response) {
                    dispatch(empLeaveListFailure(error.response.data));
                    if (error?.response?.status == 401) {
                      dispatch(Logout())
                    }
                    // above if block was commented before
                }
            });
    };
}

export function empLeaveListLoading() {
    return {
        type: EMPLOYEE_LEAVE_LIST_LOADING,
    };
}
export function empLeaveListSuccess(payload) {
    return {
        type: EMPLOYEE_LEAVE_LIST_SUCCESS,
        payload
    };
}

export function empLeaveListFailure(payload) {
    return {
        type: EMPLOYEE_LEAVE_LIST_FAILURE,
        payload
    };
}
/**
 * [2022-09-29]
 * Changes By: Aanchal Sahu
 * Description:- This API is used getting leave details
 **/
export function ViewLeave(token, id=null) {
    let  url=`view-leave?id=${id}`; 
  
    return (dispatch) => {
        dispatch(leaveViewLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/leave/${url}`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },

        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(leaveViewSuccess(res.data))
                }
            }).catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                    dispatch(leaveViewFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                      }
                }
            });
    };
}

// This Api will be called for getting leave balance; for ex: In leave summary
export function EmployeeLeaveBalance(token, id=null) {
    
    return (dispatch) => {
        dispatch(leaveViewLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/leave/employee-leave-balance?employee_id=${id}`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },

        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(leaveViewSuccess(res.data))
                }
            }).catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                    dispatch(leaveViewFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                      }
                }
            });
    };
}

export function leaveViewLoading() {
    return {
        type: EMPLOYEE_LEAVE_VIEW_LOADING,
    };
}
export function leaveViewSuccess(payload) {
    return {
        type: EMPLOYEE_LEAVE_VIEW_SUCCESS,
        payload
    };
}

export function leaveViewFailure(payload) {
    return {
        type: EMPLOYEE_LEAVE_VIEW_FAILURE,
        payload
    };
}


/**
 * [2023-03-31]
 * Changes By: Purva Sharma
 * Description:- This API is is used to delete a leave request
 **/

export function DeleteLeave(token, Data) {
    const data = new FormData();
    data.append('leave_head_id',Data.leave_head_id);

    return (dispatch) => {
        axios({
            method: 'post',
            url: `${SERVER_URL}/leave/delete-leave-request`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
           data
        })
            .then((res) => {
                if (res.status === 200) {
                    Success.fire({
                        text: "html",
                        html: `<span style="color:white">${res.data.message}</span>`,
                    })
                    dispatch(AttendanceLeaveList(token, Data.search, Data.page, Data.rowsPerPage))
                    dispatch(EmployeeLeaveBalance(token, Data.employee_id))// TO REFLECT AN UPDATED COUNT ON THE MY LEAVE SUMMARY
                }
            }).catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                }
            });
    };
}
