import PropTypes from 'prop-types';
import { Menu, MenuItem, MenuList, ListItemIcon } from '@mui/material';
import Domain from '@iconify/icons-mdi/domain';
// import WalletOutline from '@iconify/icons-mdi/wallet-outline'; //INFO: commenting this since it's of no use as of now
import SettingsIcon from '@iconify/icons-mdi/cog-outline';
import PasswordIcon from '@iconify/icons-mdi/password-check-outline';
import Beach from '@iconify/icons-mdi/beach';
import FAQIcon from '@iconify/icons-mdi/frequently-asked-questions';
import { Link } from 'react-router-dom';
import { EMenuIcon, EMenuItem } from '../BaseComponents/EMenuPopover';
import myProfile from '@iconify/icons-material-symbols/frame-person-rounded'
import { EButtonOutlined } from '../BaseComponents/EButtons';
import { useDispatch } from 'react-redux';
import { Logout } from '../action/AuthAction';
import { PERMISSIONS, ROLE_ADMIN, ROLE_HR, SUPER_ADMIN } from '../constants';
import { useCheckPermission } from '../hooks';


export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, auth, ...other } = props;
  // const authContext = useContext(AuthContext);
  const dispatch = useDispatch()

  const handleSignOut = async () => {
    onClose?.();
    dispatch(Logout())
  };
  // const HandleMyProfile = (id) => {
  //   onClose();
  //   navigate('/my-profile', { state: { id: `${base64.encode(auth?.authData?.id)}`, myprofile: `${base64.encode(true)}` } });
  // }

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          width: 250,
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 10,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >

      <MenuList
        disablePadding
      >
        <EMenuItem component={Link} to={`/my-profile`} >
          <ListItemIcon>
            <EMenuIcon icon={myProfile}></EMenuIcon>
          </ListItemIcon>
          My Profile
        </EMenuItem>

        <EMenuItem component={Link} to={`/change-password`} >
          <ListItemIcon>
            <EMenuIcon icon={PasswordIcon}></EMenuIcon>
          </ListItemIcon>
          Change Password
        </EMenuItem>

        <EMenuItem >
          <EButtonOutlined fullWidth variant='outlined' to="/login" onClick={handleSignOut}>Sign out</EButtonOutlined>
        </EMenuItem>
      </MenuList>
    </Menu>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};


export const SettingPopover = (props) => {
  const { anchorEl, onClose, auth, open, ...other } = props;
  // const authContext = useContext(AuthContext);

  const showCompanySetting = useCheckPermission([PERMISSIONS.OnlyViewAccountSettings, PERMISSIONS.ViewEditAccountSettings, PERMISSIONS.OnlyViewTalentDataSettings, PERMISSIONS.ViewEditTalentDataSettings, PERMISSIONS.OnlyViewAttendanceSettings, PERMISSIONS.ViewEditAttendanceSettings, PERMISSIONS.OnlyViewLeaveSettings, PERMISSIONS.ViewEditLeaveSettings, PERMISSIONS.OnlyViewPayrollSettings, PERMISSIONS.ViewEditPayrollSettings, PERMISSIONS.OnlyViewPaymentSettings, PERMISSIONS.ViewEditPaymentSettings, PERMISSIONS.OnlyViewPFSettings, PERMISSIONS.ViewEditPFSettings, PERMISSIONS.OnlyViewTDSSettings, PERMISSIONS.ViewEditTDSSettings, PERMISSIONS.OnlyViewFreelancerSettings, PERMISSIONS.ViewEditFreelancerSettings, PERMISSIONS.OnlyViewAdditionalSettings, PERMISSIONS.ViewEditAdditionalSettings])

  const showCompanyProfileSetting = useCheckPermission([PERMISSIONS.OnlyViewCompanyProfile, PERMISSIONS.ViewEditCompanyProfile])

  const handleSignOut = async () => {
    onClose?.();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          width: 250,
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {/* <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
        <Divider /> */}
      {showCompanySetting &&
        <MenuItem component={Link} to={'/setting'}>
          <ListItemIcon>
            <EMenuIcon icon={SettingsIcon} />
          </ListItemIcon>
          Company Settings
        </MenuItem>
      }

      <MenuItem component={Link} to={'/setting/holiday'}>
        <ListItemIcon>
          <EMenuIcon icon={Beach} />
        </ListItemIcon>
        Holidays
      </MenuItem>

      {showCompanyProfileSetting &&
        <MenuItem component={Link} to={'/setting/company-profile'}>
          <ListItemIcon>
            <EMenuIcon icon={Domain} />
          </ListItemIcon>
          Company Profile
        </MenuItem>
      }
      {/* {[SUPER_ADMIN].includes(auth?.authData?.role) &&
        <MenuItem component={Link} to={'/setting/account-setting'}>
          <ListItemIcon>
            <EMenuIcon icon={WalletOutline} />
          </ListItemIcon>
          Account Settings
        </MenuItem>
      } */}
      <MenuItem component={Link} to={'/support/faqs'} >
        <ListItemIcon>
          <EMenuIcon icon={FAQIcon} />
        </ListItemIcon>
        FAQ
      </MenuItem>
    </Menu>
  );
};

SettingPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  auth: PropTypes.object,
};