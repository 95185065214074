import { Grid, IconButton } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { ETypographyCardHeading } from "../../BaseComponents/ETypography";
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded';
import { EInputAdornment, ETextField } from "../../BaseComponents/ETextField";
import { EIcon } from "../../BaseComponents/EIcon";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import ifsc from 'ifsc-finder';
import Search from '@iconify/icons-mdi/search';
import { EIconButton } from "../../BaseComponents/EButtons";


const AddFreelancerBankDetails = (props) => {
    const { formikFreelancer } = props;

    function CheckIFSCCode() {
        const ifscCode = getFieldProps('ifsc_code').value.toUpperCase();
        ifscCode && ifsc.get(ifscCode).then(function (res) {
            if (typeof (res) == 'object') {
                setFieldValue("bank_name", res.BANK)
                setFieldValue("branch_name", res.BRANCH)
                setFieldValue("ifsc_code", ifscCode)
            } else {
                setFieldValue("bank_name", "")
                setFieldValue("branch_name", "")
            }
        })
    }
    const { errors, touched, getFieldProps, setFieldValue } = formikFreelancer;
    return (

        <ECard className={formikFreelancer?.values?.bank_detail_check ? 'card_design_1' : 'card_design_2'}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ETypographyCardHeading variant='h5'>
                    Bank Details
                </ETypographyCardHeading>
                <IconButton
                    onClick={() => setFieldValue('bank_detail_check', !getFieldProps('bank_detail_check').value)}>
                    <EIcon  icon={formikFreelancer?.values?.bank_detail_check ? minusBox : plusBox} />
                </IconButton>
            </Grid>

            {getFieldProps('bank_detail_check').value && <>
                <EDivider className="mb-16px" />
                <Grid container className='px-16px' spacing={2} >
                    <Grid item md={6} sm={6} xs={12}  >
                        <ELabelInputMainGrid label={'IFSC Code'}>
                            <ETextField
                                fullWidth
                                autoComplete="ifsc_code"
                                {...getFieldProps('ifsc_code')}
                                error={Boolean(touched.ifsc_code && errors.ifsc_code)}
                                helperText={touched.ifsc_code && errors.ifsc_code}
                                placeholder="Search IFSC code"
                                InputProps={{
                                    endAdornment: (
                                        <EInputAdornment position="end">
                                            <EIconButton
                                                aria-label="Toggle password visibility"
                                                onClick={() => CheckIFSCCode()}
                                                className='p0'
                                                size="large">
                                                <EIcon icon={Search} />
                                            </EIconButton>
                                        </EInputAdornment>
                                    ),
                                }}
                            />
                        </ELabelInputMainGrid>
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'Bank Name'}>
                            <ETextField
                                fullWidth
                                // disabled={true}
                                autoComplete="bank_name"
                                {...getFieldProps('bank_name')}
                                inputProps={
                                    { readOnly: false, }
                                }
                                error={Boolean(touched.bank_name && errors.bank_name)}
                                helperText={touched.bank_name && errors.bank_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>

                    <Grid item md={6} sm={6} xs={12}  >
                        <ELabelInputMainGrid label={'Branch Name'}>
                            <ETextField
                                fullWidth
                                autoComplete="branch_name"
                                {...getFieldProps('branch_name')}
                                // disabled={true}
                                inputProps={
                                    { readOnly: false, }
                                }
                                error={Boolean(touched.branch_name && errors.branch_name)}
                                helperText={touched.branch_name && errors.branch_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>

                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={"Cardholder's Name"}>
                            <ETextField
                                fullWidth
                                autoComplete="account_holder_name"
                                {...getFieldProps('account_holder_name')}
                                error={Boolean(touched.account_holder_name && errors.account_holder_name)}
                                helperText={touched.account_holder_name && errors.account_holder_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Account Number'}>
                            <ETextField
                                fullWidth
                                autoComplete="account_no"
                                {...getFieldProps('account_no')}
                                error={Boolean(touched.account_no && errors.account_no)}
                                helperText={touched.account_no && errors.account_no}
                            />

                        </ELabelInputMainGrid>
                    </Grid>
                </Grid>
            </>}
        </ECard>
    )

};

export default AddFreelancerBankDetails;