
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import base64 from 'base-64';
import Page from '../../BaseComponents/EPage';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import { ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EDivider } from '../../BaseComponents/EDivider';
import { NIL, PERMISSIONS } from '../../constants';
import { ETab, ETabContext, ETabList, ETabPanel } from '../../BaseComponents/ETabs';
import { EContainer } from '../../BaseComponents/EContainer';
import FreelancerEditProfile from '../../PageComponents/FreelancerContracts/FreelancerEditProfile';
import { useDispatch } from 'react-redux';
import FreelancerViewContractDetails from '../../PageComponents/FreelancerContracts/FreelancerViewContractDetails';
import { GetFreelancerDetails } from '../../action/FreelancerAction';
import FreelancerViewPayments from '../../PageComponents/FreelancerContracts/FreelancerViewPayments';
import { LoadingGrid } from '../../BaseComponents/EGrid';
import EHidden from '../../BaseComponents/EHidden';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { EIcon } from '../../BaseComponents/EIcon';
import { StateList } from '../../action/StateCityAction';
import { useCheckPermission } from '../../hooks';


/**
 * [2022-11-23]
 * created By:-AanchaL Sahu
 * Description:- designed this page for my profile
**/

function ViewFreelancer(props) {
    const { auth, myProfile } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const freelancer = useSelector((state) => state.FreelancerReducer);
    const myprofile = myProfile ? true : false;
    const freelancerData = freelancer?.freelanceData ? freelancer?.freelanceData : {}

    const [tabvalue, setTabValue] = useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (auth.authtoken && params.id) {
            dispatch(GetFreelancerDetails(auth.authtoken, base64.decode(params.id)))
            dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions
        }
    }, [params?.id, auth.authtoken]);

    const allowEdit = useCheckPermission([PERMISSIONS.ViewEditFreelancer])

    return (
        <Page title='View Freelancer'>
            <Grid container display="row" justifyContent="space-between " className='align-center'>
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading={freelancerData?.freelancer_code?freelancerData?.freelancer_code:''}
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Freelancer Contract', href: '' },
                            { name: 'Freelancer List', href: '' },
                            { name: freelancerData?.freelancer_code? freelancerData?.freelancer_code: ''}
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
                className='margin-top-20px mb-8px'
            >
                <Grid item>
                    <ETypographyPageHeading variant='h5'>
                        {freelancerData?.freelancer_name ? freelancerData?.freelancer_name : NIL}
                    </ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  
                            <EIcon icon={ForwardArrow} className='height-width-15px' /> 
                        </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
            </Grid>

            <EDivider className='mt-8px' />

            <EContainer maxWidth='lg' >
                <Grid>
                    <ETabContext value={tabvalue}>
                        <ETabList onChange={handleChange} aria-label="lab API tabs example" allowScrollButtonsMobile variant="scrollable"
                        alignitems='left!important'>
                            <ETab label="Personal Profile" value="1" />
                            <ETab label="Contract Details" value="2" />
                            <ETab label="Payments Details" value="3" />
                        </ETabList>

                        <ETabPanel value="1" className='ETabPanel-css'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12} >
                                    {freelancerData && !freelancer?.freelanceLoading &&
                                        Object.keys(freelancerData).length > 0 ?
                                        <FreelancerEditProfile
                                            auth={auth}
                                            freelancer={freelancerData}
                                            isMyprofile={myprofile}
                                            freelancerLoading={freelancer?.freelanceLoading}
                                            allowEdit={allowEdit}
                                        />
                                        :
                                        <LoadingGrid/>
                                    }

                                </Grid>
                            </Grid>
                        </ETabPanel>
                        <ETabPanel value="2" className='ETabPanel-css'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12}>
                                    {freelancerData && !freelancer?.freelanceLoading &&
                                        Object.keys(freelancerData).length > 0 ?
                                    
                                        <FreelancerViewContractDetails
                                            freelancer={freelancer} 
                                            auth={auth}
                                        />
                                        :
                                        <LoadingGrid/>
                                    }
                                </Grid>
                            </Grid>
                        </ETabPanel>
                        <ETabPanel value="3" className='ETabPanel-css'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12}>
                                    {freelancerData && !freelancer?.freelanceLoading ?
                                    <FreelancerViewPayments 
                                        freelancer={freelancerData} 
                                        isMyprofile={myprofile} 
                                        auth={auth} 
                                        />
                                        :
                                        <LoadingGrid/>
                                        }
                                    
                                </Grid>
                            </Grid>
                        </ETabPanel>
                    </ETabContext>
                </Grid>
            </EContainer>
        </Page>
    );
}

export default ViewFreelancer
