import { Grid, Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EBox } from "../../BaseComponents/EBox";
import EMenuPopover, { EMenuItem } from "../../BaseComponents/EMenuPopover";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { DownLoadFile } from "../../action/AdminAction";
import {
  ACTIVE_USER,
  DOWNLOAD_CSV,
  DOWNLOAD_PDF,
  TALENT_LIST_DOWNLOAD_URL,
} from "../../constants";
import { EInputAdornment, ETextFieldSearch } from "../../BaseComponents/ETextField";
import { useDispatch, useSelector } from "react-redux";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { EIcon, EIconInputAdornment } from "../../BaseComponents/EIcon";
//icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import viewList from "@iconify/icons-material-symbols/view-list-outline-rounded";
import FileDownloadIcon from "@iconify/icons-material-symbols/sim-card-download-outline-rounded";
import viewGrid from "@iconify/icons-material-symbols/grid-view-rounded";
import SortByAlphaIcon from "@iconify/icons-material-symbols/sort-by-alpha";
import { EIconButton } from "../../BaseComponents/EButtons";
import EHidden from "../../BaseComponents/EHidden";
import { onKeyDown } from "../../utils/CommonFunctions";
import CancelIcon from '@iconify/icons-material-symbols/cancel-outline-rounded';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { EDesktopDatePicker } from "../../BaseComponents/EDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesignationList } from "../../action/DesignationAction";



const TalentSearchBar = (props) => {
  
  const {
    setListToggle,
    listToggle,
    getEmployeeData,
    searchParam,
    setSearchParam,
    auth,
    userStatus, employeeListData, companyDetail
  } = props;

  /**
   * for Month Picker max and min month  
   */
  const minDate = new Date(companyDetail.companyData.created_at);
  const maxDate = new Date();

  const theme = useTheme();
  const dispatch = useDispatch();

  const designationList = useSelector(
    (state) => state.DesignationReducer.designationListData?.rows
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const DownloadReport = (value) => {
    let url =
      TALENT_LIST_DOWNLOAD_URL +
      `?download_format=${value}&user_status=${userStatus}`;
    let file_name = "Talent List";

    if (searchParam?.department != null && searchParam?.department != "") {
      url += `&department=${searchParam?.department}`;
    }

    if (searchParam?.name != null && searchParam?.name != "") {
      url += `&fullname=${searchParam?.name}`;
    }

    DownLoadFile(auth.authtoken, url, file_name);
    setAnchorEl(null);
    handleClose();
  };

  const setSearch = (key, value) => {
    const sParams = { ...searchParam, [key]: value };
    setSearchParam(sParams);
    getEmployeeData(sParams);
  };

  //Filtering Active Talents for Talent List on the basis of status and show as talent
  const ActiveTalents = employeeListData?.allEmployeeData?.rows?.filter((row) => {
    return row?.status == userStatus && row?.show_as_talent
  })

  // INFO: Calling API here to stop the calling this api repeatedly while visiting talent, released and archived list; now it'll be called only once for all pages.
  // This API is called since we have removed it from after login and check token actions
  useEffect(() => {
    if (auth.authtoken) {
        dispatch(DesignationList(auth.authtoken));
    }
  }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={11} sm={11} lg={9} md={9} xl={10} >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            {/* Search dropdown of name */}
            <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
            <EAutocomplete
              name="name"
              fullWidth
              forcePopupIcon={searchParam?.name ? false : true}
              options={
                ActiveTalents && ActiveTalents?.length > 0
                  ? ActiveTalents
                  : []
              }
              onChange={(e, value) => {
                setSearch("name", value?.label ? value?.label : "");
              }}
              renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
                <Box component="li" {...props} key={option.id}>
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <ETextFieldSearch
                  {...params}
                  placeholder="Select Name"
                  name="name"
                  size="small"
                />
              )}
            />
            </Grid>
          
            {/* Search dropdown of designation */}
            <EHidden width="smDown">
            <Grid item  xs={6} sm={4} md={4} lg={4} xl={3}>
              <EAutocomplete
                name="department_id"
                fullWidth
                forcePopupIcon={searchParam?.designation ? false : true}
                options={
                  designationList && designationList?.length > 0
                    ? designationList
                    : []
                }
                onChange={(e, value) => {
                  setSearch("designation", value?.id ? value?.id : "");
                }}
                renderInput={(params) => (
                  <ETextFieldSearch
                    {...params}
                    placeholder="Select Designation"
                    name="designation_id"
                    size="small"
                  />
                )}
              />
              </Grid>

            <Grid item  xs={6} sm={4} md={4} lg={4} xl={3}>
            {userStatus != ACTIVE_USER && 
            <LocalizationProvider dateAdapter={AdapterDateFns}>{/* Date Filter for Released and archived list*/}
              <EDesktopDatePicker
                openTo="month"
                views={['year', 'month']}
                autoComplete='off'
                inputFormat="MMM yyyy"
                value={searchParam?.year_month}
                minDate={minDate}
                maxDate={maxDate}

                onChange={(newValue) => {
                  setSearch('year_month', newValue ? newValue : null);
                }}
                renderInput={(params) => (
                  <div className='date-picker-div'>{/*this div contains text field and clear icon*/}
                    <ETextFieldSearch
                      size="small"
                      fullWidth
                      {...params}
                      onKeyDown={onKeyDown}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Select Month'
                      }}
                    />
                    {searchParam?.year_month &&
                      <EInputAdornment 
                       position='end'
                        onClick={() => setSearch('year_month', null)}
                        className='clear-icon'
                      >
                        <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                      </EInputAdornment>
                    }
                  </div>
                )}
              />
            </LocalizationProvider>}
            </Grid>
            </EHidden>
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1}  md={3}  lg={3} xl={2} >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <EBox className="display-flex align-center">
              {/* Download button */}
              <EHidden width="mdDown">
                <EIconButton onClick={handleClick}>
                  <ETooltip title={"Download"} arrow>
                    <EIcon
                      icon={FileDownloadIcon}
                      className="height-width-22px"
                    />
                  </ETooltip>
                </EIconButton>
              </EHidden>

              {/* Sort by Button */}
              <EIconButton onClick={() => setSearch("sort", !searchParam.sort)}>
                <ETooltip title={"Sort"} arrow>
                  <EIcon icon={SortByAlphaIcon} className="height-width-22px" />
                </ETooltip>
              </EIconButton>

              <EHidden width="mdDown">{/* Code from here will not show on mobile screen */}
                
                {/*Info:  Card View */}
                <EIconButton onClick={() => setListToggle(true)}>

                  <ETooltip title={"Card View"} arrow>
                    <EIcon
                      icon={viewGrid}
                      className="height-width-22px"
                      color={
                        listToggle
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.action.active}`
                      }
                    />
                  </ETooltip>

                </EIconButton>

                {/* List View */}
                <EIconButton onClick={() => setListToggle(false)}>
                  <ETooltip title={"List View"} arrow>
                    <EIcon
                      icon={viewList}
                      className="height-width-22px"
                      color={
                        listToggle
                          ? `${theme.palette.action.active}`
                          : `${theme.palette.primary.main}`
                      }
                    />
                  </ETooltip>
                </EIconButton>
              </EHidden>
            </EBox>
            <EMenuPopover
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              sx={{ width: 180, top: 40 }}
            >
              <EMenuItem
                sx={{ typography: "body2", py: 1, px: 2.5 }}
                onClick={() => DownloadReport(DOWNLOAD_PDF)}
              >
                <Box sx={{ mr: 2, width: 24, height: 24 }}>
                  <PictureAsPdfIcon />
                </Box>
                PDF
              </EMenuItem>
              <EMenuItem
                sx={{ typography: "body2", py: 1, px: 2.5 }}
                onClick={() => DownloadReport(DOWNLOAD_CSV)}
              >
                <Box sx={{ mr: 2, width: 24, height: 24 }}>
                  <InsertDriveFileIcon />
                </Box>
                CSV
              </EMenuItem>
            </EMenuPopover>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TalentSearchBar;