import { styled } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";//DatePicker

//this Date Picker solves the major issue or date picker icon visibility on smaller screen, previous 
export const EDatePicker = styled(DesktopDatePicker)(({ theme }) => ({

}));

//this Date Picker solves the major issue or date picker icon visibility on smaller screen
export const EDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({

}));