import { FormControlLabel, FormGroup, Switch, useTheme } from '@mui/material'
import React from 'react'
import { ETypography } from './ETypography'

/**
 * [2023-05-10]
 * Changes By: Purva Sharma
 * Description:- MUI Switch component that takes handleToggle function, check states, and label props as input
 **/
  
function ESwitch({ handleToggle, check, label, disabled = false }) {

  const theme = useTheme();
  // Return a FormGroup component that contains a FormControlLabel and a Switch component
  return (
    <FormGroup>
      <FormControlLabel
        label={<ETypography className='bold-600 font-size-14px'>{label}</ETypography>}
        control={
          <Switch
            checked={check ? true : false}
            onClick={() => handleToggle()}
            sx={{
              '& .MuiSwitch-track': {
                background: theme.palette.text.staticGrey, //This changes in bg of disabled switch
                opacity: 1,
              },
              '& .MuiButtonBase-root.Mui-checked+.MuiSwitch-track': { //This changes in bg of disabled switch
                  opacity: 1,
                  backgroundColor: theme.palette.primary.mainLight,
              },
            }}
            disabled={disabled}
          />
        }
      />
    </FormGroup>
  )
}

export default ESwitch


// export const ERadio = styled(Radio)(({ theme }) => ({
//   '&.Mui-disabled': {
//     'svg': {
//         color: theme.palette.input.Disabled //This handles changes in disabled radio buttons
//     },
// },
// }));
