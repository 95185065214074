import React, { useState } from "react";
import Page from "../../../BaseComponents/EPage";
import { AuthRootStyle } from "../../../BaseComponents/EContainer";
import { AuthNavbar } from "../../../layouts/auth-navbar";
import { EBox } from "../../../BaseComponents/EBox";
import { ECard } from "../../../BaseComponents/ECard";
import { EHelperText, ETypography } from "../../../BaseComponents/ETypography";
import { EDivider } from "../../../BaseComponents/EDivider";
import { ELabelInputMainGrid } from "../../../BaseComponents/EGrid";
import {
  ETextField,

} from "../../../BaseComponents/ETextField";
import { EDatePicker } from "../../../BaseComponents/EDatePicker";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  EButton,
  EButtonOutlined,
  EIconButton,
} from "../../../BaseComponents/EButtons";
import { Link, Stack } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { ScheduleACallFormValidation } from "../../../utils/validation";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ScheduleACallAction } from "../../../action/ScheduleCallAction";
import authReducer from "../../../reducers/AuthReducer";
import moment from "moment";
import { getScheduleCallSlot } from "../../../action/OnboardingSlotAction";
import { Logo } from "../../../layouts/logo";
import {useLocation} from "react-router-dom"

function ScheduleACall() {
  const navigateTo = useNavigate()
const location = useLocation()
  const dispatch= useDispatch()
  const auth = useSelector((state)=>state.authReducer)
  const slots = useSelector((state)=>state.ScheduleACallReducer) 
  const callSlotData = slots?.callSlotData?.data || []
  const [selectedSlot,setSelectedSlot] = useState('')
  const company = useSelector(state => state.CompanyReducer)



  const formikScheduleCall = useFormik({
    // validateOnChange: false,
    initialValues: {
      fullname: "",
      contact_number: "",
      email_id: "",
      scheduled_date: null, //for datepicker
      scheduled_date_time:null, //to pass to api
    },
    validationSchema: ScheduleACallFormValidation,
    onSubmit: (data) => {
     dispatch(ScheduleACallAction(auth.authtoken,data, navigateTo, company, auth?.authData?.role))
    },
  });

  const { touched, errors, handleSubmit, setFieldValue, getFieldProps } = formikScheduleCall;

    function disableWeekends(date) {
      return date.getDay() === 0 || date.getDay() === 6;
    }

    const onKeyDown = (e) => {
      e.preventDefault();
   };

   const handleSlotSelection = (item)=>{
    setFieldValue('scheduled_date_time', item.time)
    setSelectedSlot(item.time)
   }

   const handleCancel = ()=>{
    navigateTo(-1)
   }

  return (
    <Page title="Schedule A Call">
      <AuthRootStyle maxWidth="lg">
        {
     location?.state?.showNavbar ?
          <AuthNavbar/>
           : null
        }
        
        <EBox marginTop={"28px"} maxWidth={"600px"} marginX={"auto"}>
          <ECard sx={{ paddingX: "24px" }}>
            <ETypography className="font-size-20px bold-600">
              Schedule a Call
            </ETypography>
            <EDivider className="mb-24px" />

            <EBox className="px-16px">
              <FormikProvider value={formikScheduleCall}>
                <Form autoComplete={false} noValidate onSubmit={handleSubmit}>
                  {/* NAME */}
                  <ELabelInputMainGrid label="Name">
                    <ETextField
                      name="fullname"
                      fullWidth
                      className="mb-16px"
                      placeholder="Name"
                      {...getFieldProps("fullname")}
                      error={Boolean(
                        touched.fullname && errors.fullname
                      )}
                      helperText={
                        touched.fullname && errors.fullname
                      }
                    />
                  </ELabelInputMainGrid>

                  {/* Contact Number */}
                  <ELabelInputMainGrid label="Contact Number">
                    <ETextField
                      fullWidth
                      name="contact_number"
                      type="text"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength: 10 }}
                      className="mb-16px"
                      placeholder="Contact Number"
                      {...getFieldProps("contact_number")}
                      error={Boolean(
                        touched.contact_number && errors.contact_number
                      )}
                      helperText={
                        touched.contact_number && errors.contact_number
                      }
                    />
                  </ELabelInputMainGrid>

                  {/* Email ID */}
                  <ELabelInputMainGrid label="Email ID">
                    <ETextField
                      fullWidth
                      className="mb-16px"
                      name="email_id"
                      placeholder="Email ID"
                      {...getFieldProps("email_id")}
                      error={Boolean(touched.email_id && errors.email_id)}
                      helperText={touched.email_id && errors.email_id}
                    />
                  </ELabelInputMainGrid>

                  {/* Set a Date */}
                  <ELabelInputMainGrid label="Set a Date">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <EDatePicker
                        name="scheduled_date"
                        minDate={new Date()}
                        // maxDate={new Date(company?.companyData?.CompanyActivePlan?.plan_valid_to)}
                        inputFormat="dd/MM/yyyy"
                        {...getFieldProps('scheduled_date')}
                        onChange={(newValue) => {
                          dispatch(getScheduleCallSlot(auth.authtoken,newValue))
                          setFieldValue('scheduled_date', newValue ? newValue : '')
                        }}
                        shouldDisableDate={disableWeekends}
                        renderInput={(params) => (
                          <ETextField
                            {...params}
                            fullWidth
                            onKeyDown={onKeyDown} 

                            {...getFieldProps("scheduled_date")}
                            error={Boolean(
                              touched.scheduled_date && errors.scheduled_date
                            )}
                            helperText={touched.scheduled_date && errors.scheduled_date}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <EHelperText className="greyColor5-color mb-16px" >
                    *Schedule a call between Monday to Friday
                    </EHelperText>
                  </ELabelInputMainGrid>

                  <ELabelInputMainGrid fullWidth disableColon={callSlotData.length !==0 ? false : true} label={ callSlotData.length !==0  ? "Set a Time": null} >
                  <EBox sx={{display:'flex', flexWrap:'wrap'}}>
                
                  { 
                    callSlotData?.map((item,index)=>{
                      const formattedTime = moment(item.time).format('hh:mm A');
                      return (
                          <EButtonOutlined key={index} className={ item.time == selectedSlot && "theme-bg-static color-staticPrimaryLight"}  disabled={item.slot_status == 1 ? false : true} onClick={()=> handleSlotSelection(item)} variant="outlined" sx={{margin:'8px', color:'#D97904', fontSize:'16px', fontWeight:'400', height:'40px',padding:'11px 14px'}}> {formattedTime}</EButtonOutlined>
                      )
                    })
                  } 
                  </EBox> 
                  </ELabelInputMainGrid>
                 
                  {/* FORM ACTION */}
                  <Stack
                    direction="row"
                    spacing={2}
                    className="modal1-buttons-stick-bottom"
                    sx={{marginTop:'16px'}}
                  >
                    <EButtonOutlined onClick={()=>handleCancel()} variant="outlined" size="large">
                      {" "}
                      Cancel
                    </EButtonOutlined>
                    <EButton type="submit"  variant="contained" size="large">
                      {" "}
                      Submit{" "}
                    </EButton>
                  </Stack>
                </Form>
              </FormikProvider>
            </EBox>
          </ECard>
        </EBox>
      </AuthRootStyle>
    </Page>
  );
}

export default ScheduleACall;
