import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { EFieldLabelBold, ETypography } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid, Link } from "@mui/material";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import { numberWithCommas } from "../../utils/formatNumber";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { accountantPayValidation } from "../../utils/validation";
import { EIcon } from "../../BaseComponents/EIcon";
import { useDispatch } from "react-redux";
import { HRAccountantPaymentAction } from "../../action/PaymentAction";
import { APPROVED_STATUS, IMAGE_URL, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_MODE, PENDING_STATUS } from "../../constants";
import AttachFile from '@iconify/icons-material-symbols/attach-file';
import moment from "moment";



const PaymentActionModal = (props) => {
    const { selectedData, setModalState, auth, getPaymentFilterData } = props;
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            payment_medium: PAYMENT_MODE[0],
            payment_detail: '',
            accountant_remark: '',
        },
        validationSchema: accountantPayValidation,
        onSubmit: (data) => {
            data.payment_medium = data.payment_medium.id;
            data = { ...data, payment_id: selectedData.modalData?.id };
            dispatch(HRAccountantPaymentAction(auth?.authtoken, data, getPaymentFilterData, 'final-action'));
            setModalState({ modalData: null, modalCategory: null })
        }
    });




    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;


    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Talent Name </EFieldLabelBold>
                            <ETypography>{selectedData.modalData?.employeeData?.fullname}</ETypography>
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Bill Date</EFieldLabelBold>
                            <ETypography>{moment(selectedData.modalData?.transaction_date).format('DD/MM/YYYY')}</ETypography>
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Request Amount</EFieldLabelBold>
                            <ETypography>{numberWithCommas(selectedData.modalData?.pay_amount)}</ETypography>
                        </FieldContainerCustom>
                    </Grid>
                    {[PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_ADVANCE].includes(selectedData.modalData?.transaction_category) &&
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Reason</EFieldLabelBold>
                                <ETypography>{selectedData.modalData?.remark}</ETypography>
                            </FieldContainerCustom>
                        </Grid>
                    }



                    {selectedData.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT && <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Attachment</EFieldLabelBold>
                            {selectedData.modalData?.reimbursement_attachment ? (
                                <Link target={'_blank'} href={IMAGE_URL +
                                    '/' +
                                    auth?.authData?.company_id +
                                    '/reimbursement/' +
                                    selectedData.modalData?.employee_id +
                                    '/' +
                                    selectedData.modalData?.reimbursement_attachment}>
                                    <EIcon icon={AttachFile} />
                                </Link>) : <></>}
                        </FieldContainerCustom>
                    </Grid>}

                    {(selectedData.modalData?.transaction_status == APPROVED_STATUS || (selectedData.modalData?.transaction_status == PENDING_STATUS)) ? <>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={50}
                            >
                                <EFieldLabelBold>Payment Mode</EFieldLabelBold>
                                <EAutocomplete
                                    name="Payment Mode"
                                    fullWidth
                                    {...getFieldProps('payment_medium')}
                                    options={PAYMENT_MODE}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(e, value) => {
                                        setFieldValue('payment_medium', value ? value : null)
                                        // setFieldValue('isType', value?.id == 'CHEQUE' ? true : false)
                                        if (value?.id != 'CHEQUE') { setFieldValue('payment_detail', '') }
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='payment_medium'
                                            placeholder="Select"
                                            fullWidth
                                            error={Boolean(touched.payment_medium && errors.payment_medium)}
                                            helperText={touched.payment_medium && errors.payment_medium}
                                        />
                                    )}
                                />
                            </FieldContainerCustom>
                        </Grid>
                        {getFieldProps('payment_medium').value && getFieldProps('payment_medium').value?.id == 'CHEQUE' &&
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                >
                                    <EFieldLabelBold>Cheque Number</EFieldLabelBold>
                                    <ETextField
                                        name='payment_detail'
                                        {...getFieldProps('payment_detail')}
                                        rows={3}
                                        fullWidth
                                        error={Boolean(touched.payment_detail && errors.payment_detail)}
                                        helperText={touched.payment_detail && errors.payment_detail}
                                    />
                                </FieldContainerCustom>
                            </Grid>}
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={50}
                            >
                                <EFieldLabelBold>Remark</EFieldLabelBold>
                                <ETextField
                                    name='accountant_remark'
                                    multiline
                                    rows={3}
                                    {...getFieldProps('accountant_remark')}
                                    fullWidth
                                    error={Boolean(touched.accountant_remark && errors.accountant_remark)}
                                    helperText={touched.accountant_remark && errors.accountant_remark}
                                />
                            </FieldContainerCustom>
                        </Grid>
                    </> : <>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Payment Mode</EFieldLabelBold>
                                <ETypography>{selectedData.modalData?.payment_medium == 'CASH' ? 'Cash' : 'Cheque'}</ETypography>
                            </FieldContainerCustom>
                        </Grid>
                        {selectedData.modalData?.payment_medium === 'CHEQUE' && <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Cheque Number</EFieldLabelBold>
                                <ETypography>{selectedData.modalData?.payment_medium_ref}</ETypography>
                            </FieldContainerCustom>
                        </Grid>}
                        
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Remark</EFieldLabelBold>
                                <ETypography>{selectedData.modalData?.accountant_remark}</ETypography>
                            </FieldContainerCustom>
                        </Grid>
                    </>}

                </Grid>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalCategory: null })}
                    > Close </EButtonOutlined>

                    {(selectedData.modalData?.transaction_status == APPROVED_STATUS || (selectedData.modalData?.transaction_status == PENDING_STATUS)) && (
                        <ELoadingButton
                            size="large" variant="outlined" type="submit" >Pay</ELoadingButton>
                    )}


                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default PaymentActionModal