import {
    EMPLOYEE_LEAVE_LIST_FAILURE, 
    EMPLOYEE_LEAVE_LIST_SUCCESS, 
    EMPLOYEE_LEAVE_LIST_LOADING, 

    EMPLOYEE_LEAVE_FAILURE, 
    EMPLOYEE_LEAVE_LOADING, 
    EMPLOYEE_LEAVE_SUCCESS,

    EMPLOYEE_LEAVE_VIEW_LOADING,
    EMPLOYEE_LEAVE_VIEW_SUCCESS,
    EMPLOYEE_LEAVE_VIEW_FAILURE
} from '../constants';

const INITIAL_STATE = {
    empLeaveListData: {},
    empLeaveListSuccess: false,
    empLeaveListLoading: false,
    empLeaveListMessage: null,
    empLeaveData: {},
    empLeaveSuccess: false,
    empLeaveLoading: false,
    empLeaveMessage: null,

    empLeaveViewData: {},
    empLeaveViewSuccess: false,
    empLeaveViewLoading: false,
    empLeaveViewMessage: null,
};

const LeaveEmployeeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case EMPLOYEE_LEAVE_LOADING:
            return {
                ...state,
                empLeaveLoading: true,
                empLeaveData: {}
            };

        case EMPLOYEE_LEAVE_SUCCESS:
            return {
                ...state,
                empLeaveLoading: false,
                empLeaveSuccess: true,
                empLeaveData: action.payload.data,
                empLeaveMessage: action.payload.message,
            };

        case EMPLOYEE_LEAVE_FAILURE:
            return {
                ...state,
                empLeaveLoading: false,
                empLeaveSuccess: false,
                empLeaveMessage: action.payload.message,
            };

        // FOR LEAVE LIST 
        case EMPLOYEE_LEAVE_LIST_LOADING:
            return {
                ...state,
                empLeaveListLoading: true,
                empLeaveListData: {}
            };

        case EMPLOYEE_LEAVE_LIST_SUCCESS:
            return {
                ...state,
                empLeaveListLoading: false,
                empLeaveListSuccess: true,
                empLeaveListData: action.payload.data,
                empLeaveListMessage: action.payload.message,
            };

        case EMPLOYEE_LEAVE_LIST_FAILURE:
            return {
                ...state,
                empLeaveListLoading: false,
                empLeaveListSuccess: false,
                empLeaveListMessage: action.payload.message,
                empLeaveListData: {}
            };

            // FOR LEAVE View 

        case EMPLOYEE_LEAVE_VIEW_LOADING:
            return {
                ...state,
                empLeaveViewLoading: true,
                empLeaveViewData: {}
            };

        case EMPLOYEE_LEAVE_VIEW_SUCCESS:
            return {
                ...state,
                empLeaveViewLoading: false,
                empLeaveViewSuccess: true,
                empLeaveViewData: action.payload.data,
                empLeaveViewMessage: action.payload.message,
            };

        case EMPLOYEE_LEAVE_VIEW_FAILURE:
            return {
                ...state,
                empLeaveViewLoading: false,
                empLeaveViewSuccess: false,
                empLeaveViewMessage: action.payload.message,
                empLeaveViewData: {}
            };

        default:
            return state;
    }
};

export default LeaveEmployeeReducer;
