// material
import { useTheme } from "@mui/material/styles";
import { GlobalStyles as GlobalThemeStyles } from "@mui/material";
import React from "react";

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();
  return (
    <GlobalThemeStyles
      styles={{
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        html: {
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
          scrollPaddingTop: "80px !important",
          [theme.breakpoints.down('lg')]: {
            fontSize:'14px !important'
          },
        },
        body: {
          width: "100%",
          height: "100%",
        },
        "#root": {
          width: "100%",
          height: "100%",
          display: 'flex',
        },
        a: {
          textDecoration: "none !important",
          color: theme.palette.text.primary,
        },
        "& .text-decoration": {
          textDecoration: "underline",
          color: theme.palette.text.primary,
        },
        "& .text-decoration-underline": {
          textDecoration: "underline",
        },
        "& .text-align-end": {
          textAlign: 'end !important'
        },
        "& .margin-top-05": {
          marginTop: "0.5rem !important",
        },
        "& .mt-12px": {
          marginTop: "12px !important",
        },
        "& .mt-24px": {
          marginTop: "24px !important",
        },
        "& .mt-18px": {
          marginTop: "18px !important",
        },
        "& .mt-10px": {
          marginTop: "10px !important",
        },
        "& .mt-8px": {
          marginTop: "8px !important",
        },
        "& .mt-minus-6px": {
          marginTop: "-0.375rem !important",//-6px
        },
        "& .mt-minus-10px": {
          marginTop: "-10px !important",//-10px
        },
        "& .mb-16px": {
          marginBottom: "1rem !important",
        },
        "& .mb-24px": {
          marginBottom: "24px !important",
        },
        "& .mb-minus-10px": {
          marginBottom: "-0.625rem !important",//-10px
        },
        "& .mb-35px": {
          marginBottom: "35px !important",
        },
        "& .mb-60px": {
          marginBottom: "60px !important",
        },
        "& .mt-16px": {
          marginTop: "16px !important",
        },
        "& .mt-20px": {
          marginTop: "20px !important",
        },
        "& .mb-8px": {
          marginBottom: "0.5rem !important",
        },
        "& .mb-0px": {
          marginBottom: "0px !important",
        },
        "& .ml-auto": {
          marginLeft: "auto !important",
        },
        "& .ml-33per": {
          marginLeft: "33% !important",
        },
        "& .ml-12px": {
          marginLeft: "12px !important",
        },
        "& .pt-9px": {
          paddingTop: "9px !important",
        },
        "& .pt-24px": {
          paddingTop: "24px !important",
        },
        "& .pt-40px": {
          paddingTop: "40px !important",
        },
        "& .pt-18px": {
          paddingTop: "18px !important",
        },
        "& .pt-16px": {
          paddingTop: "1rem !important",//16px
        },
        "& .pt-8px": {
          paddingTop: "8px !important",
        },
        "& .pt-0": {
          paddingTop: "0px !important",
        },
        "& .pb-0": {
          paddingBottom: "0px !important",
        },
        "& .pb-24px": {
          paddingBottom: "24px !important",
        },
        "& .pb-8px": {
          paddingBottom: "8px !important",
        },
        "& .margin-top-20px": {
          marginTop: "20px !important",
        },
        "& .margin-left-1rem": {
          marginLeft: "1rem !important",
        },
        "& .margin-auto": {
          margin: "auto",
        },
        "& .margin-24px": {
          margin: "24px !important",
        },
        "& .margin-16px": {
          margin: "16px !important",
        },
        "& .px-16px": {
          paddingLeft: "16px",
          paddingRight: "16px",

        },
        "& .px-18px": {
          paddingLeft: "18px",
          paddingRight: "18px",
        },
        "& .px-6px": {
          paddingLeft: "6px",
          paddingRight: "6px",
        },
        "& .py-24px": {
          paddingTop: "24px !important",
          paddingBottom: "24px !important",
        },
        "& .py-18px": {
          paddingTop: "18px !important",
          paddingBottom: "18px !important",
        },
        "& .px-10px": {
          paddingLeft: "10px",
          paddingRight: "10px",
        },
        "& .py-2px": {
          paddingTop: "2px",
          paddingBottom: "2px",
        },
        "& .py-16px": {
          paddingTop: "16px",
          paddingBottom: "16px",
        },
        "& .py-19px": {
          paddingTop: "19px",
          paddingBottom: "19px",
        },
        '& .p-18px': {
          padding: '18px !important'
        },
        '& .p-24px': {
          padding: '24px !important'
        },
        '& .p-16px': {
          padding: '1rem !important'
        },
        '& .p-8px': {
          padding: '8px !important'
        },
        '& .p-10px': {
          padding: '0.6rem'
        },
        "& .pl-0px": {
          paddingLeft: "0px !important",
        },
        "& .pl-24px": {
          paddingLeft: "1.5rem !important",
        },
        "& .pl2rem": {
          paddingLeft: "2rem !important",
        },
        "& .pl-16px": {
          paddingLeft: "16px !important",
        },
        "& .m-left-07rem": {
          marginLeft: "0.7rem !important",
        },
        "& .ml-10px": {
          marginLeft: "10px !important",
        },
        "& .ml-50px": {
          marginLeft: "50px !important",
        },
        "& .m-auto": {
          margin: "auto !important",
        },
        "& .ml-32px": {
          marginLeft: "32px !important",
        },
        "& .m0px": {
          margin: "0px !important",
        },
        "& .ml-0px": {
          marginLeft: "0px !important",
        },
        "& .ml-16px": {
          marginLeft: "16px !important",
        },
        "& .mr-0px": {
          marginRight: "0px !important",
        },
        "& .mr-8px": {
          marginRight: "8px !important",
        },
        "& .mr-10px": {
          marginRight: "0.625rem !important",//10px
        },
        "& .mr-24px": {
          marginRight: "24px !important",
        },
        "& .mr-16px": {
          marginRight: "16px !important",
        },
        "& .input-mr-0px": {
          input: {
            marginRight: "0px !important",
          },
        },
        "& .ml-25px": {
          marginLeft: "25px !important",
        },
        "& .ml-4px": {
          marginLeft: "4px",
        },
        "& .mb-40px": {
          marginBottom: "40px !important",
        },
        "& .my-40px": {
          margin: "40px 0 !important",
        },
        "& .height-40px": {
          height: "40px",
        },
        "& .height-91lg-77sm": {
          height: "91px !important",
          [theme.breakpoints.down('lg')]: {
            height: "77px !important",
          },
        },
        "& .height-50px": {
          height: "50px",
        },

        "& .height-width-22px": {
          height: "22px",
          width: "22px",
        },
        "& .height-width-75px": {
          height: "75px",
          width: "75px",
        },
        "& .height-width-18px": {
          height: "18px",
          width: "18px",
        },
        "& .height-width-30px": {
          height: "30px !important",
          width: "30px !important",
        },
        "& .height-width-44px": {
          height: "44px",
          width: "44px",
        },
        "& .width-110px": {
          width: "110px",
        },
        "& .width-80per": {
          width: "80%",
        },
        "& .width-100per": {
          width: "100%",
        },
        "& .width-60per": {
          width: "60%",
        },
        "& .width-30per": {
          width: "30%",
        },
        "& .height-100per": {
          height: "100%",
        },
        "& .height-310px": {
          height: "310px",
        },
        "& .height-width-15px": {
          height: "15px !important",
          width: "15px !important",
        },
         "& .height-width-11px": {
          height: "11px !important",
          width: "11px !important",
        },
        "& .height-width-80px": {
          height: "80px !important",
          width: "80px !important",
        },
        "& .height-width-40px": {
          height: "40px !important",
          width: "40px !important",
        },
        "& .height-220px": {
          height: "13.75rem",// 220px
        },
        "& .height-240px": {
          height: "15rem !important",// 220px
        },
        "& .person-card-css": {
          maxWidth: "auto",//198px
          border: `0.5px solid ${theme.palette.border.border6}`,
          '&:hover':{
          border: `0.1px solid ${theme.palette.border.border8}`,

          }
        },
        '& .card-view-avatar': {
          width: '8.125rem !important', //130px
          height: "8.125rem !important", //130px
          mx: "auto",
          border: `0.5px solid ${theme.palette.border.border5}`
        },
        '& .navbar-avatar': {
          height: 35,
          width: 35,
          marginLeft: '0.5rem',//8px
          marginRight: '0.5rem',//8px
          border: `1px solid ${theme.palette.primary.main}`
        },
        "& .icon-separater-divider-29px": {
          height: "29px",
          borderColor: theme.palette.border.divider2,
          margin: "auto",
        },
        "& .color-active-accordian": {
          color: theme.palette.background.activeAccordianButton,
        },

        "& .line-height-24px": {
          lineHeight: "24px !important",
        },
        "& .line-height-17px": {
          lineHeight: "17px !important",
        },
        "& .line-height-33px": {
          lineHeight: "33px !important",
        },
        "& .line-height-0px": {
          lineHeight: "0px",
        },
        "& .font-family-mullish": {
          fontFamily:"Mulish !important", 
        },
        "& .font-size-14p5": {
          fontSize: "0.906rem",// 14.5px
        },
        "& .font-size-36px":{
          fontSize: "2.25rem !important",//36px
        },
        "& .font-size-20px": {
          fontSize: "1.25rem !important",//20px
        },
        "& .font-size-22px": {
          fontSize: "1.375rem !important",//20px
        },
        "& .font-size-24px": {
          fontSize: "1.5rem !important",//24px
        },
        "& .font-size-14px": {
          fontSize: "0.875rem !important",//14px
        },
        "& .font-size-12px": {
          fontSize: "0.75rem !important",//12px
        },
        "& .font-size-11px": {
          fontSize: "0.6875rem !important",//12px
        },
        "& .font-size-16px": {
          fontSize: "1rem  !important",
        },
        "& .font-size-35px": {
          fontSize: "2.188rem !important",//35px
        },
        "& .font-open-sans": {
          fontFamily: "Open Sans",
        },
        "& .text-transform-capitalize": {
          textTransform: "capitalize",
        },
        "& .text-capitalize": {
          textTransform: "capitalize",
        },
        "& .richTextEditor": {
          background: "none",
          paddingLeft: "10px",
          fontFamily: "Open Sans",

          "& button": {
            border: "none",
          },
        },
        "& .font-size-10px": {
          fontSize: "0.625rem !important",//10px
        },
        "& .copyrightText": {
          fontSize: "0.625rem !important",//10px
          lineHeight: "10px",
          color: theme.palette.text.primary + "!important",
          fontWeight: "300",
          paddingBottom: "5px",
        },
        "& .linkCompanyNameStyle": {
          color: theme.palette.text.primary + "!important",
          textDecoration: "none",
          fontWeight: "400",
          fontSize: "0.75rem",//"12px",
          lineHeight: "10px",
          paddingBottom: "4px",
        },
        "& .font-size-32px": {
          fontSize: "2rem !important",//32px
        },
        "& .font-size-18px": {
          fontSize: "1.125rem !important",//18px
        },
        "& .font-size-40px": {
          fontSize: "2.5rem !important",//40px
        },
        "& .font-size-18px-without-imp": {
          fontSize: "1.125rem",//18px
        },
        "& .font-size-19px": {
          fontSize: '1.1875rem'//19px
        },
        "& .font-size-32px-without-imp": {
          fontSize: "2rem",//32px
        },
        "& .font-weight-400": {
          fontWeight: "400",
        },
        "& .font-weight-400-with-imp": {
          fontWeight: "400 !important",
        },
        "& .font-weight-500-with-imp": {
          fontWeight: "500 !important",
        },
        "& .font-weight-300": {
          fontWeight: "300px",
        },
        "& .font-weight-600": {
          fontWeight: "600px",
        },
        '& .page-padding': {
          padding: '26px 40px 40px 40px',
        },
        '& .Accordian-color-flip': {
          '& .text-primary-when-active': {//this class name can be used inside accordian if the text color's functionality matches this one
            color: theme.palette.text.primary, //'#CCCCCC'
            fontSize: '0.875rem'//14px
          },
          '& .green-box-text-bg': {//this class name can be used inside accordian if the text color's functionality matches this one
            color: theme.palette.success.green, //'#CCCCCC'
            background: theme.palette.success.greenLight,
          },
          '& .red-box-text-bg': {//this class name can be used inside accordian if the text color's functionality matches this one
            color: theme.palette.error.red, //'#CCCCCC'
            background: theme.palette.error.redLight,
          },
          '&.Mui-expanded': {
            '& .text-primary-when-active': {
              color: theme.palette.primary.main
            },
            '& .green-box-text-bg': {//this class name can be used inside accordian if the text color's functionality matches this one
              color: theme.palette.success.greenLight, //'#CCCCCC'
              background: theme.palette.success.green
            },
            '& .red-box-text-bg': {//this class name can be used inside accordian if the text color's functionality matches this one
              color: theme.palette.error.redLight, //'#CCCCCC'
              background: theme.palette.error.red,
            },
            '& .grey-box-text-bg': {//this class name can be used inside accordian if the text color's functionality matches this one
              color: theme.palette.text.staticLightGrey, //'#CCCCCC'
              background: theme.palette.text.greyColor,
            },
          }
        },
        // '& .responsive-p-icon':{
        //   [theme.breakpoints.up('sm')]: {
        //     marginLeft: '10px',
        //     marginRight: '10px',
        //   },
        //   [theme.breakpoints.down('sm')]: {
        //     paddingLeft: '10px',
        //   },
        // },
        // '& .responsive-p-before':{

        //   [theme.breakpoints.up('sm')]: {
        //     paddingLeft: '50px',
        //   },
        //   [theme.breakpoints.down('sm')]: {
        //     paddingLeft: '10px',
        //   },
        // },
        // '& .responsive-p-after':{
        //   [theme.breakpoints.only('lg')]: {
        //     paddingLeft: '55px',
        //   },
        //   [theme.breakpoints.up('sm')]: {
        //     paddingLeft: '24px',
        //   },
        //   [theme.breakpoints.down('sm')]: {
        //     paddingLeft: '10px',
        //   },
        // },
        "& .responsive-display": {
          [theme.breakpoints.up("sm")]: {
            display: "block",
          },
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
        "& .font-style-italic": {
          fontStyle: "italic",
        },
        "& .color-text-primary": {
          color: theme.palette.text.primary + "!important",
        },
        "& .disabledBottonText": {
          color: theme.palette.text.disabledBottonText,
        },
        "& .check-css": {
          background: theme.palette.background.lightGreen,
          borderRadius: "100%",
        },
        "& .color-text-error-red": {
          color: theme.palette.text.redColor + "!important",
        },
        "& .disabled-text-color": {
          color: theme.palette.background.buttonDisable + "!important",
        },
        "& .color-text-default-static": {
          color: theme.palette.text.default + "!important",
        },
        "& .color-staticPrimaryLight": {
          color: theme.palette.text.staticPrimaryLight + "!important",
        },
        "& .color-staticBlue3": {
          color: theme.palette.text.staticBlue3 + "!important",
        },
        "& .color-staticGrey":{
          color:theme.palette.text.staticGrey  + "!important"
        },
        "& .color-infoText":{
          color: theme.palette.chip.infoText + "!important",
        },
        "& .color-errorText":{
          color: theme.palette.chip.errorText + "!important",
        },
        "& .color-secondaryText":{
          color: theme.palette.chip.secondaryText + "!important",
        },
        "& .p-21px": {
          padding: "21px !important",
        },
        "& .pr-2px": {
          paddingRight: '0.125rem !important', //2px
        },
        "& .pr-4per": {
          paddingRight: "4%",
        },
        "& .pr-0px": {
          paddingRight: "0px !important",
        },
        "& .ml11": {
          marginLeft: "1.1rem !important",
        },
        "& .active-green-chip": {
          backgroundColor: theme.palette.chip.successbg + "!important",
          color: theme.palette.chip.successText + "!important",
        },
        "& .active-grey-chip": {
          backgroundColor: theme.palette.chip.secondarybg + "!important",
          color: theme.palette.chip.secondaryText + "!important",
        },
        "& .active-blue-chip": {
          backgroundColor: theme.palette.chip.infobg + "!important",
          color: theme.palette.chip.infoText + "!important",
        },
        "& .active-red-chip": {
          backgroundColor: theme.palette.chip.errorbg + "!important",
          color: theme.palette.chip.errorText + "!important",
        },
        "& .active-warning-chip": {
          backgroundColor: theme.palette.chip.warningbg + "!important",
          color: theme.palette.chip.warningText + "!important",
        },
        "& .attendance-green-chip": {
          backgroundColor: theme.palette.chip.aGreenBg + "!important",
          color: theme.palette.chip.aGreenText + "!important",
        },
        "& .attendance-blue-chip": {
          backgroundColor: theme.palette.chip.aBlueBg + "!important",
          color: theme.palette.chip.aBlueText + "!important",
        },
        "& .attendance-red-chip": {
          backgroundColor: theme.palette.chip.aRedBg + "!important",
          color: theme.palette.chip.aRedText + "!important",
        },
        "& .attendance-pink-chip": {
          backgroundColor: theme.palette.chip.aPinkBg + "!important",
          color: theme.palette.chip.aPinkText + "!important",
        },
        "& .attendance-yellow-chip": {
          backgroundColor: theme.palette.chip.aYellowBg + "!important",
          color: theme.palette.chip.aYellowText + "!important",
        },
        '& .orange-chip': {
          backgroundColor: theme.palette.chip.orangebg + '!important',
          color: theme.palette.chip.orangeText + '!important',
        },
        '& .icon-green': {
          color: theme.palette.success.green + ' !important',
        },
        '& .icon-red': {
          color: '#D2000B !important',
        },
        "& .icon-light-red": {
          color: "#EFD0D2 !important",
        },
        "& .w-170px": {
          width: "170px !important",
        },
        "& .w-100per": {
          width: "100% !important",
        },
        "& .w-50per": {
          width: "50% !important",
        },
        "& .w-30per": {
          width: "30% !important",
        },
        "& .w-lg35per-md50per": {
          width: "35% !important",
          [theme.breakpoints.down("lg")]: {
            width: "50% !important",
          },
          [theme.breakpoints.down("sm")]: {
            width: "100% !important",
          }
        },
        "& .responsive-w-1rem-12rem": {
          width: { md: '1rem', lg: '12rem' },
        },
        "& .cursor-pointer": {
          cursor: "pointer",
        },
        "& .clearIconDesktopDatePicker": {
          position: "absolute",
          top: "1.2rem",
          margin: "auto",
          right: "2rem"
        },
        "& .button-left-margin": {
          marginLeft: "1rem !important",
        },
        "& .modal1-buttons-stick-bottom": {
          display: "flex", //perviously it was block
          position: "sticky",
          bottom: "0",
          background: theme.palette.background.modalbg,
          zIndex: "1",
          width: "100%",
          justifyContent: "flex-end",
        },
        '& .padding-modal-reduce-title':{
          '.MuiDialogTitle-root':{
            padding: '0px'
          },
        },
        '& .padding-modal-reduce-child':{
          '.MuiDialogContent-root':{
            padding: '24px !important'
          }
        },
        "& .leave-multiple-column-class": {
          height: "30px",
        },
        '& .height-16px': {
          height: '16px'
        },
        '& .height-32px': {
          height: '32px'
        },
        '& .height-120px': {
          height: '7.5rem' //120px
        },
        "& .height-100per-16px": {
          height: 'calc(100% - 1rem) !important'
        },
        "& .height-100per-50px": {
          height: 'calc(100% - 3.125rem) !important' //3.125rem=50px
        },
        "& .maxheight-80per": {
          maxHeight: '80vh !important' //3.125rem=50px
        },
        '& .pb-21px': {
          paddingBottom: "21px"
        },
        "& .pb-16px": {
          paddingBottom: "16px",
        },
        "& .pb-1rem": {
          paddingBottom: "1rem",
        },
        "& .minWidth-180": {
          minWidth: "11.25rem !important",//180px
        },
        "& .minWidth-80": {
          minWidth: "5rem !important",//80px
        },
        "& .minWidth-40px": {
          minWidth: "2.5rem !important",//80px
        },
        "& .minWidth-120": {
          minWidth: "7.5rem !important",//120px
        },
        "& .minWidth-130": {
          minWidth: "8.125rem !important",//130px
        },
        "& .minWidth-100": {
          minWidth: "6.25rem !important",//100px
        },
        "& .standard-searchfield-width": {
          width: "200px !important",
        },
        "& .minWidth-140": {
          minWidth: "8.75rem !important",//140px
        },
        "& .minWidth-150": {
          minWidth: "9.375rem !important",//150px
        },
        "& .minWidth-160px": {
          minWidth: "160px !important",
        },
        "& .minWidth-161px": {
          minWidth: "161px !important",
        },
        "& .minWidth-210": {
          minWidth: 210,
        },
        "& .minWidth-32px": {
          minWidth: "2rem",//32px
        },
        "& .maxWidth-128px": {
          maxWidth: "8rem",//32px
        },
        "& .maxWidth-300px": {
          maxWidth: "18.75rem",//300px
        },
        '& .checkin-out-button-dashboard': {
          minWidth: '80px !important',
          maxWidth: '110px !important',
          paddingLeft: 'auto',
        },
        '& .minHeight-40px': {
          minHeight: '40px !important'
        },
        "& .minHeight-70px": {
          minHeight: "70px !important",
        },
        '& .height-360px': {
          height: '22.5rem !important'
        },
        '& .h-30px': {
          height: '1.875rem !important'
        },
        // "& .width-webkit-fill-available": {
        //   width: "webkit-fill-available",
        // },
        '& .minHeight-262px': {
          minHeight: '16.375rem'
        },

        '& .width-20': {
          width: '1.25rem !important'//20px
        },
        '& .width-50px': {
          width: '50px !important'//50px
        },
        '& .width-32px': {
          width: '32px !important'
        },
        '& .width-30px-report': {
          width: 'auto !important',
          minWidth: '1.875rem !important',
        },

        '& .width-60px-additional-setting':{
          width: '3.75rem !important', // 60px
          padding:'0 1rem !important',
          // textAlign:'center !important'
        },
        '& .width-45px': {
          width: '45px !important'
        },
        "& .width-100": {
          width: "100% !important",
        },
        "& .width-20p": {
          width: "20% !important",
        },
        "& .width-100px": {
          width: "100px !important",
        },
        "& .width-228px": {
          width: "228px !important",
        },
        "& .mui-autocomplete-width-auto": {
          ".MuiAutocomplete-input": {
            width: "auto !important",
          },
        },
        '& .MuiAutocomplete-hasPopupIcon.css-iuha6l-MuiAutocomplete-root .MuiOutlinedInput-root': {
          paddingRight: '0 !important'
        },
        "& .width-150px": {
          width: "150px",
        },
        "& .width-160px": {
          width: "160px !important",
        },
        "& .width-130px": {
          width: "129px",
        },
        "& .width-80px":{
          width:'80px !important'
        },
        "& .font-weight-700": {
          fontWeight: "700",
        },
        "& .theme-main-text-color-bold": {
          color: theme.palette.primary.main + "!important",
          fontWeight: 700,
        },
        "& .theme-text1-color": {
          color: theme.palette.text.text1 + "!important",
        },
        "& .line-height-120perc": {
          lineHeight: "120% !important",
        },
        "& .line-height-140perc": {
          lineHeight: "140% !important",
        },
        "& .line-height-50perc": {
          lineHeight: "50% !important",
        },
        "& .small-text": {
          fontWeight: "400",
          fontSize: "0.875rem",//14px
          textTransform: "uppercase",
          marginLeft: "4px",
        },
        "& .small-text-capitalize": {
          fontWeight: "400 !important",
          fontSize: "0.875rem !important",//14px
          textTransform: "capitalize",
          marginLeft: "4px",
        },
        "& .bold-600": {
          fontWeight: "600 !important",
        },
        "& .bold-500": {
          fontWeight: "500 !important",
        },
        "& .bold-300": {
          fontWeight: "300 !important",
        },
        "& .bold-400": {
          fontWeight: "400 !important",
        },
        "& .bold-700": {
          fontWeight: "700 !important",
        },
        "& .m-none": {
          margin: "0 !important",
        },
        "& .theme-color-static": {
          color: theme.palette.primary.main + "!important",
        },
        "& .theme-bg-static": {
          backgroundColor: theme.palette.primary.main + "!important",
        },
        "& .red-bg-static": {
          backgroundColor: theme.palette.error.red + "!important",
        },
        "& .blue-color-static": {
          color: theme.palette.text.blueColor + "!important",
        },
        "& .grey-color-static": {
          color: theme.palette.text.greyColor + "!important",
        },
        "& .grey-color-greyColor2": {
          color: theme.palette.text.greyColor2 + "!important",
        },
        "& .green-color-static": {
          color: theme.palette.success.green + "!important",
        },
        "& .darkGreen-bgcolor": {
          backgroundColor: theme.palette.success.darkGreen + "!important",
        },
        "& .darkBlue-bgcolor": {
          backgroundColor: theme.palette.background.darkBlue + "!important",
        },
        "& .darkBlueStatic-bgcolor": {
          backgroundColor: theme.palette.background.darkBlueStatic + "!important",
        },
        "& .darkGrey-bgcolor": {
          backgroundColor: theme.palette.background.darkGrey + "!important",
        },
        "& .lightest2-bg": {
          backgroundColor: theme.palette.primary.lightest2+ "!important",
        },
        "& .greyColor3-bgcolor": {
          backgroundColor: theme.palette.text.greyColor3 + "!important",
        },
        "& .greyColor3-color": {
          color: theme.palette.text.greyColor3 + "!important",
        },
        '& .green-bgcolor-static': {
          backgroundColor: theme.palette.success.green + '!important',
        },
        "& .greenColor1-bg-static": {
          backgroundColor: theme.palette.neutral.greenColor1 + "!important",
        },
        '& .default-bgcolor-static': {
          backgroundColor: theme.palette.background.default + '!important',
        },
        '& .bg2-bgcolor-static': {
          backgroundColor: theme.palette.background.bg2 + '!important',
        },
        '& .bg2-bgcolor-dullLightOrange': {
          backgroundColor: theme.palette.background.dullLightOrange + '!important',
        },
        '& .bg-authDefault': {
          backgroundColor: theme.palette.background.authDefault + '!important',
        },
        "& .greyColor4-color": {
          color: theme.palette.text.greyColor4 + "!important",
        },
        "& .greyColor10-bg": {
          backgroundColor: theme.palette.text.greyColor10 + "!important",
        },
        "& .greyColor5-color": {
          color: theme.palette.text.greyColor5 + "!important",
        },
        '& .lightgrey-color-static': {
          color: ' rgba(64, 63, 63, 0.4) !important',
        },
        "& .background-bgColorLight1": {
          backgroundColor:
            theme.palette.background.bgColorLight1 + "!important",
        },
        "& .background-darkOrangeBrown": {
          backgroundColor:
            theme.palette.background.darkOrangeBrown + "!important",
        },
        "& .background-lightBrownYellow": {
          backgroundColor:
            theme.palette.background.lightBrownYellow + "!important",
        },
        "& .input-placeholder": {
          input: {
            "&::placeholder": {
              textTransform: "capitalize !important",
            },
          },
        },
        // Input type = 'file' button style
        '& .input-file-selector': {
          input: {
            color:theme.palette.text.staticOrange,
            paddingTop: '0px',
            marginTop: '0px',
            fontSize:'1rem',
            '&::file-selector-button': {
              backgroundColor: theme.palette.primary.main + '!important',
              color: theme.palette.text.staticPrimaryLight,
              fontSize:'0.875rem',//14px
              border: 'none',
              padding: '10px 16px',
              borderRadius: '0.5rem',//8px
              marginRight: '16px',
              marginBottom: '10px',
            }
          },
        },

        //Input type = 'file' text style
        "& .input-file-no-file-chosen":{
          input: {
            color:theme.palette.text.staticGrey,
           }

        },
        "& .display-in-lg-xl-md": {
          [theme.breakpoints.up("md")]: {
            display: "block",
          },
          // [theme.breakpoints.down("md")]: {
          //   display: "block",
          // },
        },
        "& .display-up-xl": {
          display: 'none',
          [theme.breakpoints.up("xl")]: {
            display: "grid",
          },
        },
        '& .swal2-container': {
          zIndex: 1201 + '!important',
          '.swal2-html-container': {
            overflow: 'hidden',
            color: theme.palette.primary.main + "!important",
          }
        },
        '&.swal2-close-center': {
          display: 'flex !important',
          borderRadius: '100% !important',
          border: `2px solid ${theme.palette.text.primary} !important`,
          color: theme.palette.text.primary + "!important",        
          fontSize: "2em !important",
          paddingBottom: "4px !important",
          marginTop: "2rem !important",
          marginRight: "2.5rem !important",
          width: "0.8em !important",
          height: "0.8em !important",
        },
        "& .swal2-popup-center": {
          padding: "0px !important",
          borderRadius: "16px !important",
          backgroundColor: theme.palette.background.default + "!important",
        },
        "& .swal-content-center": {
          color: "#078C03",
          fontSize: "1.875rem",//30px
          textTransform: "capitalize",
        },
        "& .swal-container-center": {
          boxShadow:
            "0px 17px 7px rgba(0, 0, 0, 0.01), 0px 10px 6px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
          // borderRadius: "12px",

          backgroundColor: theme.palette.background.default,
          "& .swal2-timer-progress-bar-container": {
            borderRadius: " 0% 0 100% 100% !important",
            left: "8px !important",
            right: "8px !important",
            bottom: "-2px !important",
            "& .swal2-timer-progress-bar": {
              backgroundColor: theme.palette.text.primary + "!important",
            },
          },
        },
        "& .width-200px": {
          width: "200px !important",
        },
        "& .width-230px": {
          width: "230px !important",
        },
        "& .position-relative": {
          position: "relative !important ",
        },
        "& .position-absolute": {
          position: "absolute !important ",
        },
        "& .width-50per": {
          width: "50% !important",
        },
        "& .width-50per-responsive": {
          width: "50% !important",
          [theme.breakpoints.down('sm')]: {
            width: "100% !important",
          },
        },
        "& .p0": {
          padding: "0 !important",
        },
        "& .p0_not_imp": {
          padding: "0",
        },
        '& .p15vh': {
          padding: '1.5vh',
        },
        '& .responsive-height1': {
          height: '360px',
          [theme.breakpoints.down('lg')]: {
            height: 'auto',
          },
          [theme.breakpoints.up('xl')]: {
            height: 'auto',
          },
        },
        '& .responsive-height-graph': { //this class is used for graph height
          height: '310px',
          [theme.breakpoints.only('md')]: {
            height: '305px',
          },
          [theme.breakpoints.down('md')]: {
            height: '70vh',
          },
          [theme.breakpoints.down('sm')]: {
            height: '40vh',
          },
          [theme.breakpoints.up('xl')]: {
            height: '305px',
          },
        },
        '& .responsive-height-donut': { //this class is used for donut
          textAlign: '-webkit-center !important',
          height: '188px',
          [theme.breakpoints.only('md')]: {
            height: '172px',
          },
          [theme.breakpoints.down('md')]: {
            height: '30vh',
          },
          [theme.breakpoints.up('xl')]: {
            height: '190px',
          },
        },
        '& .responsive-height-donut2': { //this class is used for donut
          textAlign: '-webkit-center !important',
          height: '150px',
          // [theme.breakpoints.only('md')]: {
          //   height:'172px',
          // },
          // [theme.breakpoints.down('md')]: {
          //   height:'18vh',
          // },
          // [theme.breakpoints.up('xl')]: {
          //   height:'190px',
          // },
        },
        '& .px0': {
          paddingLeft: '0 !important',
          paddingRight: '0 !important'
        },
        "& .py0": {
          paddingTop: "0 !important",
          paddingBottom: "0 !important",
        },
        "& .pl0": {
          paddingLeft: "0 !important",
        },
        "& .m-10px-0px": {
          margin: "10px 0px !important",
        },
        "& .pt40px": {
          paddingTop: "40px !important",
        },
        "& .pb50px": {
          paddingBottom: "50px !important",
        },
        "& .pt8px": {
          paddingTop: "8px !important",
        },
        "& .pt4px": {
          paddingTop: "0.25rem !important", //0.25rem
        },
        '& .pt12px': {
          paddingTop: '12px !important'
        },
        '& .pr-6px': {
          paddingRight: '0.375rem', //6px
        },
        '& .pr-16px': {
          paddingRight: '1rem !important', //16px
        },
        "& .mt5px": {
          marginTop: "5px !important",
        },
        "& .my-16px": {
          marginTop: "16px !important",
          marginBottom: "16px !important",
        },
        "& .border-none": {
          border: "none !important",
        },
        "& .border-top-none": {
          borderTop: "none !important",
        },
        "& .border-bottom-none": {
          borderBottom: "none !important",
        },
        "& .table-error-cell": {
           backgroundColor:theme.palette.table.emptyCellBg + '!important',
           borderRadius: '2px'
        },
        "& .borderRadius-4px": {
          borderRadius: "4px !important",
        },
        "& .borderRadius-8px": {
          borderRadius: "8px !important",
        },
        "& .borderRadius-3px": {
          borderRadius: "0.1875rem !important",
        },
        "& .borderRadius-none": {
          borderRadius: "none!important",
        },
        "& .borderRadius-12px": {
          borderRadius: "12px !important",
        },
        "& .borderRadius-20px": {
          borderRadius: "1.25rem !important", //20px
        },
        "& .border-color-light-grey": {
          borderColor: "rgba(64, 63, 63, 0.2) !important",
        },
        "& .bg-color-card": {
          backgroundColor: theme.palette.background.card,
        },
        "& .bg-transparent": {
          backgroundColor: "transparent !important",
        },
        '& .border-05px-border6': {
          border: ` 0.5px solid ${theme.palette.border.border6} !important`,
        },
        '& .border-color-border3': {
          borderColor: theme.palette.border.border3 + '!important',
        },
        '& .border-top-main-color': {
          borderTop: `2px solid ` + theme.palette.primary.main + '!important',
        },
        "& .border-bottom-border8-color": {
          borderTop: `1px solid ` + theme.palette.border.border8 + '!important',
          borderBottom: `1px solid ` + theme.palette.border.border8 + '!important',
        },
        '& .border-main-color': {
          border: `2px solid ` + theme.palette.primary.main + '!important',
        },
        // 2px solid #D97904
        '& .display-flex': {
          display: 'flex !important'
        },
        '& .display-grid': {
          display: 'grid !important'
        },
        '& .display-none': {
          display: 'none !important'
        },
        "& .width-10px": {
          width: "10px",
        },
        "& .line-height-168px": {
          lineHeight: "16.8px !important",
        },
        "& .table-cells-padding-vertical-align": {
          verticalAlign: "top !important",
        },
        "& .vertical-align-center": {
          verticalAlign: "center !important",
        },
        "& .flex-direction-column": {
          flexDirection: "column",
        },
        "& .justify-content-space-between": {
          justifyContent: "space-between",
        },
        "& .align-content-space-between": {
          alignContent: "space-between",
        },
        '& .justify-content-space-around': {
          justifyContent: 'space-around',
        },
        '& .justify-content-center': {
          justifyContent: 'center',
        },
        "& .justify-content-start": {
          justifyContent: "flex-start !important",
        },
        "& .align-items-start": {
          alignItems: "flex-start !important",
        },
        "& .align-center": {
          alignItems: "center !important",
        },
        "& .text-align": {
          textAlign: "center",
        },
        "& .text-align-justify": {
          textAlign: "justify",
        },
        "& .text-align-right": {
          textAlign: "right",
        },
        "& .text-align-left": {
          textAlign: "left",
        },
        '& .text-align-webkit-left': {
          textAlign: '-webkit-left'
        },
        '& .text-align-webkit-center': {
          textAlign: '-webkit-center'
        },
        '& .align-self-center': {
          alignSelf: 'center'
        },
        "& .card-name-text-style": {
          fontWeight: "400",
          fontSize: "1rem",//16px
          lineHeight: 1.2,
          textAlign: "center",
          width: 'inherit',
          paddingTop: '0.5rem'
        },
        "& .card-name-text-style-2": {
          fontWeight: "400",
          fontSize: "14px !important",
          lineHeight: 1.2,
          color: theme.palette.text.greyColor5 + '!important',
          textAlign: "center",
          width: 'inherit'
        },
        "& .card-hover-pointer": {
          "&:hover": {
            cursor: "pointer",
          },
        },
        "& .icons-on-hover": { // INFO: add this to row; this className is to make the action icons visible on row hover
          "& .table-icons": { // INFO: add this className is action icons
            display: 'none'
          },
          "&:hover": {
            "& .table-icons": {
              display: 'inline-flex'

            },
          },
        },
        "& .card-img-h100": {
          height: "100% !important",
        },
        "& .attendanceCardDarkBg": {
          background: `#414141`,
        },
        '& .Bg-card2': {
          background: theme.palette.background.card2
        },
        '& .Bg-drawerBg': {
          background: theme.palette.background.drawerBg
        },
        '& .Bg-card5': {
          background: theme.palette.background.card5 + ' !important'
        },
        '& .Bg-card3': {
          background: theme.palette.background.card3
        },
        '& .Bg-card4': {
          background: theme.palette.background.card4+ ' !important'
        },
        '& .Bg-card6': {
          background: theme.palette.background.card6+ ' !important'
        },
        '& .attendanceCardLightBg': {
          background: `#F3F2EF`
        },
        "& .list-avatar-css": {
          width: "3.125rem",//50px
          height: "3.125rem",//50px
          mx: "auto",
          border: `0.5px solid ${theme.palette.divider}`,
        },
        "& .ETabPanel-css": {
          padding: "0px",
          paddingTop: "1rem",
        },
        "& .card_design_1": {
          padding: "18px 24px 24px",
          height: "auto",
          textAlign: "left",
          mx: "auto",
          marginBottom: "16px !important",
        },
        "& .card_design_2": {
          padding: "18px 24px",
          height: "60px",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          marginBottom: "16px !important",
        },
        "& .text-dots": {
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden !important",
        },
        '& .word-break-all':{
          wordBreak: 'break-all'
        },
        "& .overflow-hidden": {
          overflow: "hidden !important",
        },
        "& .overflow-auto": {
          overflow: "auto !important",
        },
        "& .upload-field-design": {
          border: `1px solid ${theme.palette.input.primary}`,
          borderRadius: "4px",
          minHeight: "57px",
          backgroundColor: theme.palette.background.textFieldBg2,
          display: "flex",
          alignItems: "center",
          paddingLeft: "10px",
        },
        "& .align-flex-end": {
          alignItems: "flex-end",
        },
        "& .docs-responsive-display": {
          alignItems: "flex-end",
        },
        "& .justify-content-flex-end": {
          justifyContent: "flex-end",
        },
        "& .date-picker-div": {
          position: "relative",
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          '& .clear-icon ': {
            position: "absolute", top: '1.2rem', right: '2rem'
          }

        },
        "& .small-text-label": {
          fontSize: "0.75rem",//12px
          display: "block",
        },
        // INFO: this class is for the small test in forms and view page labels,
        // Ex- Salary(per month)
        "& .small-text-label-grid": {
          fontSize: "0.75rem",//12px
          display: "block",
          [theme.breakpoints.down('lg')]: {
            display: "inline",
          },
        },
        "& .table-font-size": {
          fontSize: "0.875rem",
        },

        // Disabling the table row (attendance regularization)
        "& .table-disabled-row-bg": {
          background: `${theme.palette.table.disabledRowBg} !important`,  //background color
          "& .MuiTableCell-root": {     // text-color 
            color: `rgba(171, 170, 170, 1) !important`,
          },

          "& .css-rhn1yc": { // svg i.e deskop / mobile
            color: `rgba(171, 170, 170, 1) !important`,
          },

          "& .theme-main-text-color-bold": { // highlighted text
            color: `rgba(171, 170, 170, 1) !important`,
          },

          "& .css-1ic7nbb": {   // svg i.e 
            color: `rgba(171, 170, 170, 1) !important`,
          },


          // EChip Disabled
          "& .attendance-green-chip": {
            background: "#D1D1D1 !important",
            color: 'rgba(111, 111, 111, 1) !important'
          }
          ,
          "& .attendance-red-chip": {
            background: "#D1D1D1 !important",
            color: 'rgba(111, 111, 111, 1) !important'
          },
          "& .attendance-pink-chip": {
            background: "#D1D1D1 !important",
            color: 'rgba(111, 111, 111, 1) !important'
          },
          "& .attendance-yellow-chip": {
            background: "#D1D1D1 !important",
            color: 'rgba(111, 111, 111, 1) !important'
          },


          "& .attendance-blue-chip": {
            background: "#D1D1D1 !important",
            color: 'rgba(111, 111, 111, 1) !important'
          }
        },
        '& .notification-chip':{
          backgroundColor:'white !important',
          border:` 0.5px solid ${theme.palette.text.staticBlue3} !important`,
          fontSize:'0.625rem !important', //10px
          height:'18px !important',
          padding:'2px 8px !important'
        },
        '& .EFormHelperText-span': {
          fontSize: '0.75rem',
          lineHeight: '1',
          fontWeight: 400
        },
        // ETimePicker : color of arrows svg while disabled
        '& .MuiPickersArrowSwitcher-root .Mui-disabled svg': {
          fill: theme.palette.input.inputIcon,
         },
        "& .request-regularization-card": {
          background: "#F4F4F4 !important",

          "& .color-text-default-static": {
            color: theme.palette.success.green + "!important",
            "& span": {
              color: "#403F3F !important",
              paddingLeft: '13px'
            },
          }

        },
        "& .attendance-regularization-form": {
          marginBottom: "16px !important",


        },
        "& .attendance-regularization-form-checkbox": {
          margin: "0 16px !important",

        },
        "& .apply-leave-card": {
          display: "flex !important",
          background: `${theme.palette.background.dullLightOrange} !important`,
          border: "0.5px solid #A0A0A0 !important",
          borderRadius: "5px",
          width: "fit-content",
          paddingRight: "4rem !important",
          padding: "16px  16px 16px 16px !important",
          marginBottom: "-8px",
          boxShadow: "none !important",
          height: "70px",
        },
        "& .modal-info-box": {
          // display: 'flex !important',
          background: `${theme.palette.background.dullLightOrange} !important`,
          border: "0.5px solid #A0A0A0 !important",
          borderRadius: "5px",
          height: "auto",
          lineHeight: "18px",
          width: "fit-content",
          paddingRight: "4rem !important",
          // color: '#403F3F'
        },

        "& .border-only-left": {
          borderRadius: "0 !important",
          zIndex: "1",
          paddingLeft: "1rem !important",
          margin: "0 !important",
          ".MuiAutocomplete-inputRoot": {
            borderBottom: "0px solid transparent !important",
            zIndex: "1",
            "& fieldset": {
              borderLeft: `0px solid ${theme.palette.input.primary} !important`,
            },
            "& input": {
              borderLeft: `1px solid ${theme.palette.text.primary} !important`,
            },
          },
        },
        "& .MuiInputBase-root-padding-right-0": {
          "& .MuiInputBase-root": {
            paddingRight: "0px !important",
          },
        },
        // Calendar disbaled button color
        "& .MuiCalendarPicker-root": {
          '& .Mui-disabled': {
            color: `${theme.palette.input.inputIcon}!important`
          }
        },
        "& .border-8px-solid-dark-grey": {
          border: `1px solid ${theme.palette.input.border1} !important`,
        },
        "& .borderColor-dark-grey": {
          borderColor: `${theme.palette.input.border1} !important`,
        },
        "& .borderColor-staticBorder7": {
          borderColor: `${theme.palette.border.staticBorder7} !important`,
          // borderColor: theme.palette.border.border6 + ' !important'
        },
        '& .borderBottom-none': {
          borderBottom: 'none !important',
          // '.MuiTableCell-root':{
          //   borderBottom: 'none !important',
          // }
        },
        "& .report-card-design": {
          display: "flex",
          flexDirection: "column",
          border: `1px solid ${theme.palette.border.staticBorder7} !important`,
          borderRadius: "12px",
          boxShadow: 'none',
          "& img": {
            padding: "20% 10%",
            height: "50%",
          },
          "& button": {
            background: theme.palette.background.button1 + "!important",
            color: theme.palette.text.button1 + "!important",
            borderTop: `1px solid ${theme.palette.border.staticBorder7} !important`,
            borderRadius: "0px 0px 12px 12px",

          },
        },
        "& .report-search-div": {
          padding: "16px 21px 21px 21px",
        },
        "& .modalShadow": {
          boxShadow: theme.customShadows.modalShadow,
        },
        "& .link-color": {
          color: theme.palette.link.active + "!important",
        },
        "& .shadow-none": {
          boxShadow: 'none !important',
        },
        "& .theme-color-border": {
          border: `1.5px solid ${theme.palette.primary.main} !important`,
        },
        "& .grayColor2-color-border": {
          border: `0.5px solid ${theme.palette.neutral.grayColor2} !important`,
        },
        "& .theme-color-border-1px": {
          border: `1px solid ${theme.palette.primary.main} !important`,
        },
        "& .date-chip-class": {
          boxShadow: theme.customShadows.shadow1,
          background: theme.palette.background.textFieldBg,
          padding: "10px 20px !important",
          width: "200px",
          border: "0.5px solid #A99C9C",
          fontWeight: "600",
          fontSize: "1rem",//16px
        },
        // '& .dataGridHead': {
        //     background: '#D9D9D9',
        // },

        // subscription Page Design
        "& .plan-card-container": {
          border: '1px solid #D9D9D9',
          backgroundColor: '#F3F3F3',
          borderRadius: '12px',
          '& .active-plan': {
            border: `2px solid ${theme.palette.neutral.yellowColor2}`,
            '& .price-box': {
              borderLeft: `2px solid ${theme.palette.neutral.yellowColor2}`,
            }
          },
          '& .non-active-plan': {
            border: `1px solid ${theme.palette.neutral.grayColor2}`,
            '& .price-box': {
              borderLeft: `2px solid ${theme.palette.neutral.grayColor2}`,
            }
          },

        },

        "& .attendance-sheet-helper": {
          "& ul": {
            padding: "1.5rem 0",
            display: 'flex',
            alignItems: 'center',
            listStyle: 'none',
            gap: '1.5rem',
            flexWrap: 'wrap',
            "& li": {
              // display:'flex',
              // fontSize: '16px',
              "& .abbrivationShort": {
                width: 40,
                height: 40,
                textAlign: "center",
                borderRadius: 4,
                fontSize: 14,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.text.default,
                fontWeight: "normal",
                [theme.breakpoints.down('lg')]: {
                  fontSize: 12,
                  height: 37,
                },
              },
              "& .abbrivationFull": {
                fontSize: 14,
                fontWeight: "normal",
                [theme.breakpoints.down('lg')]: {
                  fontSize: 12,
                },
              },
            },
          },
        },
        // ONBOARDING
        '& .onboarding-cta-link': {
          color: theme.palette.link.active,
          fontSize: '1rem',
          fontWeight: '500'
        },
        '& .onboarding-banner-heading': {
          color: theme.palette.text.primary,
          fontSize: '3rem !important',
          fontWeight: '600 !important',
          lineHeight: '140% !important',
          '& span': {
            fontStyle: 'italic'
          }
        },
        '& .onboarding-heading': {
          fontSize: '1.5rem', //24px
          fontWeight: '600',
          lineHeight: '140%',
          '& span': {
            color: theme.palette.primary.main
          }
        },
        '& .onboarding-modal':{
          border:'soild !important',
          "& .MuiDialog-paper":{
            height:'300px',
            width:'486px',
            padding:'0 26px 26px',
            '& .MuiDialogTitle-root':{
              padding:'0 !important'
            },
            '& .MuiDialogContent-root':{
              padding:'0 !important'
            },
            '& .cursor-pointer ':{
              paddingTop:'26px',
            }, 
          }
        },
       '& .onboarding-modal-head': {
          fontWeight: "600",
          fontSize: "1rem",//16px
          lineHeight: "140%",
        },
        '& .onboarding-modal-paragraph': {
          fontWeight: "400",
          fontSize: "1rem",//16px
          lineHeight: "140%",
          textAlign: 'justify'
        },

        '& .onboarding-paragraph': {
          fontSize: '0.875rem',//14px
          fontWeight: '400',
          lineHeight: '19px',
          color: theme.palette.text.primary,
          marginLeft: '0 !important',
        },
        '& .onboarding-list-btn': {
          display: 'block',
          width: '6.900rem', //106px
          textAlign: "center"

        },
        '& .onboarding-list-btn-verified': {
          // display:'block',
          width: '6.900rem', //106px
          background: theme.palette.chip.aGreenBgOnboarding + "!important",
          color: theme.palette.background.default2 + "!important"
        },
        '& .onboarding-list-btn-progress': {
          // display:'block',
          width: '6.900rem', //106px
          background: theme.palette.chip.warningbg + "!important",
          color: theme.palette.primary.main,
        },



        '& .onboarding-list-head': {
          fontSize: '1.125rem',//18px
          fontWeight: '600',
          lineHeight: '140%',
          color: theme.palette.text.primary,
          marginLeft: '0 !important',
        },
        '& .onboarding-list-num': {
          borderRadius: '50%',
          border: '1px solid',
          padding: '6px 13px',
          marginRight: "16px !important",
        },
        '& .onboarding-list-verified': {
          borderRadius: '50%',
          border: '1px solid',
          padding: '5px',
          marginRight: "16px !important",
          background: theme.palette.chip.aGreenBg,
          color: '#403F3F !important'

        },
        '& .onboarding-seting-list-bg': {
          borderRadius: '1%',
          background: theme.palette.background.drawerBg,


        },
        '& .onboarding-btn-icon': {
          color: ' #F5F5F5 !important',
        },
        '& .onboarding-progress-bar': {
          '& 	.MuiLinearProgress-determinate': {
            background:theme.palette.border.staticBorder7,
            borderRadius: '100px',
            height: '10px'
          },
          '& .MuiLinearProgress-bar': {
            borderRadius: '100px',
            background:theme.palette.info.blue,
            height:'10px'
          }
        },

        '& .signup-form-input':{
              height:'45px ',
              width:'350px ',
          '& .MuiOutlinedInput-root':{
                 minHeight:'45px !important',
                 width:'350px ',
          '& .MuiOutlinedInput-input':{
                padding:"0 16px !important",
            }
        
           
          }
        },

        "& .signup-form-checkbox": {
          padding: "0 10px 0 0px !important",
            "& .MuiSvgIcon-root":{
              height:'16px !important',
              width:'16px  !important',
            }
    
        },

        "& .border-radius-2px":{
          borderRadius:'2px !important',
          border:'1px dashed #808080'

        },
        '& .drag-and-drop-responsive-height': { //this class is used for drag and drop height
          height: '196px',
          width:'100%',
          [theme.breakpoints.down('md')]: {
            height: '100px',
          },
        },

        "& .signup-info-list":{
        "& li":{
          fontSize:'14px',
          fontWeight:'400'
        }
        },
        // HIDING RECAPTCHA BOX
          "& .grecaptcha-badge":{
            visibility:'hidden'
          },

          '& .drag-and-drop-file': {
            left:'50%',
            translate : '-50%',
            position:'absolute', 
            bottom:'40%'
          },

        '& .tooltip-error': {
          '& .MuiTooltip-tooltip': {
            display: 'block !important'
          }},
        "& .table-released-row-bg": {
          background: `${theme.palette.table.disabledRowBg} !important`,  //background color
        },

        // All flex combinations used frequently

        '& .flex-column': {
          display: 'flex !important',
          flexDirection:'column !important',
        },

        '& .flex-column-horizontalCenter': {
          display: 'flex !important',
          flexDirection:'column !important',
          alignItems:'center !important'
        },

        '& .flex-column-allCenter': {
          display: 'flex !important',
          flexDirection:'column !important',
          alignItems:'center !important',
          justifyContent:'center !important'
        },
        '& .flex-row-allCenter': {
          display: 'flex !important',
          flexDirection:'row !important',
          alignItems:'center !important',
          justifyContent:'center !important'
          // color:'red !important'
        },

        
        '& .flex-row-horizontaRight': {
          display: 'flex !important',
          flexDirection:'row !important',
          alignItems:'center !important',
          justifyContent:'flex-end !important'
        },

        '& .flex-row-spaceBetween': {
          display: 'flex !important',
          flexDirection:'row !important',
          alignItems:'center !important',
          justifyContent:'space-between !important'
        },

        '& .delete-confirmation-modal': {
          '.MuiDialogContent-root': {
            padding: '0 24px 24px !important'
          }
        },

        '& .company-setting-modal': {
          '.MuiDialogContent-root': {
            padding: '0 !important'
          }
        },

        '& .ELabelInput': {
          [theme.breakpoints.down('lg')]: {
            color:theme.palette.text.greyColor6 + '!important'
          },
          [theme.breakpoints.up('md')]:{
            color:theme.palette.text.primary + '!important'
          }
        
        },

        '& .button-selected-burnt-orange': {
          '.MuiDialogContent-root': {
            color:'red'
          }
        },
      }}
    />
  );
}
