import {Grid,  Stack } from "@mui/material";
import { ETextField } from "../../BaseComponents/ETextField";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import { EditFreelancerPersonalDetailValidation } from "../../utils/validation";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import moment from "moment";
import { BloodGroupOptions, FREELANCER_PERSONAL_DETAILS_UPDATE, GenderOptions, MaritalStatusOptions } from "../../constants";
import { UpdateBasicFreelancerDetailsAction } from "../../action/FreelancerAction";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { capitalizeCapitalString } from "../../utils/formatText";
import { CityList } from "../../action/StateCityAction";

const FreelancerEditModalPersonalDetail = (props) => {
    const { auth, freelancerData, closePersonalDetailModal ,freelancerLoading} = props;
    const dispatch = useDispatch();
    const initialGender = freelancerData?.freelancer_gender ? GenderOptions?.find(o => o.id === freelancerData?.freelancer_gender):null;
    const city = useSelector(state => state.CityReducer);
    const cityList = city?.cityListData?.rows;
    const stateList = useSelector(state => state.StateReducer.stateListData?.rows);

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            freelancer_detail_check: true,
            freelancer_dob: freelancerData?.freelancer_dob ? moment(freelancerData?.freelancer_dob).toDate() : moment().subtract(18, 'years')._d,
            freelancer_gender: initialGender ? initialGender : null,
            freelancer_id: freelancerData?.id,
            updated_by: auth?.authData?.id,
            marital_status_obj:freelancerData?.freelancer_marital_status ? {id:freelancerData?.freelancer_marital_status,label:capitalizeCapitalString(freelancerData?.freelancer_marital_status)} : null ,
            blood_group_obj: freelancerData?.freelancer_blood_group? {id: freelancerData?.freelancer_blood_group,label:capitalizeCapitalString(freelancerData?.freelancer_blood_group)}:null,
            address_one: freelancerData?.address_one ? freelancerData?.address_one : '',
            landmark: freelancerData?.landmark ? freelancerData?.landmark : '',
            freelancerState: freelancerData?.freelancerState ? { id: freelancerData?.freelancerState?.id, label: freelancerData?.freelancerState?.state_name } : null,
            freelancerCity: freelancerData?.freelancerCity ? { id: freelancerData?.freelancerCity?.id, label: freelancerData?.freelancerCity?.city_name } : null,
            pincode: freelancerData?.pincode ? freelancerData?.pincode : '',
        },
        validationSchema: EditFreelancerPersonalDetailValidation,
        onSubmit: (data) => {
            if (data) {
                data = { ...data, freelancer_gender: data.freelancer_gender?.id ? data.freelancer_gender?.id : '' }
                data = { ...data, company_id: auth.authData?.company_id }
                data.freelancer_marital_status= data?.marital_status_obj?.id;
                data.freelancer_blood_group= data?.blood_group_obj?.id;
                if (data && data.freelancerState && data.freelancerState.id) {
                    data = { ...data, state: data.freelancerState.id };
                }
                if (data && data.freelancerCity && data.freelancerCity.id) {
                    data = { ...data, city: data.freelancerCity.id };
                }
                dispatch(UpdateBasicFreelancerDetailsAction(auth.authtoken, data,FREELANCER_PERSONAL_DETAILS_UPDATE,null))
                // closePersonalDetailModal()
            }
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;


    return (

        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'Date of Birth'}>
                                <EDatePicker
                                    name="freelancer_dob"
                                    allowSameDateSelection={true}
                                    {...getFieldProps('freelancer_dob')}
                                    views={["day", 'month', 'year']}
                                    maxDate={moment().subtract(18, 'years')._d}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(selectedDate) => {
                                        setFieldValue("freelancer_dob", selectedDate ? selectedDate : null)
                                    }}
                                    inputProps={{ readOnly: true }}
                                    renderInput={(params) =>
                                        <Field
                                            component={ETextField}
                                            {...params}
                                            fullWidth
                                            name="freelancer_dob"
                                            error={Boolean(touched.freelancer_dob && errors.freelancer_dob)}
                                            helperText={touched.freelancer_dob && errors.freelancer_dob}
                                        />}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Gender'}>
                                <EAutocomplete
                                    name="freelancer_gender"
                                    fullWidth
                                    options={GenderOptions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('freelancer_gender')}
                                    onChange={(e, value) => {
                                        setFieldValue("freelancer_gender", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='freelancer_gender'
                                            fullWidth
                                            placeholder='Select Gender'
                                            error={Boolean(touched.freelancer_gender && errors.freelancer_gender)}
                                            helperText={touched.freelancer_gender && errors.freelancer_gender}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Marital Status'}>
                                <EAutocomplete
                                    name="marital_status_obj"
                                    fullWidth
                                    // forcePopupIcon={formikTalents?.values?.marital_status_obj ? false : true}
                                    options={MaritalStatusOptions && MaritalStatusOptions?.length > 0 ? MaritalStatusOptions : []}
                                    isOptionEqualToValue={(option, value) =>option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('marital_status_obj')}
                                    onChange={(e, value) => {
                                        setFieldValue("marital_status_obj", value)
                                    }}
                                  
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='marital_status_obj'
                                            fullWidth
                                            
                                            placeholder='Select Marital Status'
                                            error={Boolean(touched.marital_status_obj && errors.marital_status_obj)}
                                            helperText={touched.marital_status_obj && errors.marital_status_obj}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Blood Group'}>
                                <EAutocomplete
                                    name="blood_group_obj"
                                    fullWidth
                                    options={BloodGroupOptions && BloodGroupOptions?.length > 0 ? BloodGroupOptions : []}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    // forcePopupIcon={formikTalents?.values?.blood_group_obj ? false : true}
                                    getOptionLabel={(option) => option.label || ''}
                                    {...getFieldProps('blood_group_obj')}
                                    onChange={(e, value) => {
                                        setFieldValue('blood_group_obj', value);
                                    }}
                                    renderInput={(params) => (
                                        <ETextField
                                            {...params}
                                            name="blood_group_obj"
                                            placeholder="Select Blood Group"
                                            fullWidth
                                            error={Boolean(touched.blood_group_obj && errors.blood_group_obj)}
                                            helperText={touched.blood_group_obj && errors.blood_group_obj}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} >
                        <ELabelInputMainGrid label={'Address'} isfullgrid={true}>
                            <ETextField
                                fullWidth
                                name="address_one"
                                autoComplete="address_one"
                                placeholder="Address Line 1 "
                                {...getFieldProps('address_one')}
                                error={Boolean(touched.address_one && errors.address_one)}
                                helperText={touched.address_one && errors.address_one}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Landmark'} >
                                <ETextField
                                    fullWidth
                                    name='landmark'
                                    placeholder="Landmark "
                                    {...getFieldProps('landmark')}
                                    error={Boolean(touched.landmark && errors.landmark)}
                                    helperText={touched.landmark && errors.landmark}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'State'}>
                            <EAutocomplete
                                name="freelancerState"
                                fullWidth
                                options={stateList && stateList?.length > 0 ? stateList : []}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.label || ''}
                                {...getFieldProps('freelancerState')}
                                onChange={(e, value) => {
                                    if (value) {
                                        dispatch(CityList(auth.authtoken, value.id));
                                    }
                                    setFieldValue('freelancerState', value ? value : null);
                                    setFieldValue('freelancerCity', null);
                                }}
                                renderInput={(params) => (
                                    <ETextField
                                        {...params}
                                        name="freelancerState"
                                        placeholder="Select State"
                                        fullWidth
                                        error={Boolean(touched.freelancerState && errors.freelancerState)}
                                        helperText={touched.freelancerState && errors.freelancerState}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'City'}>
                            <EAutocomplete
                                name="freelancerCity"
                                fullWidth
                                options={cityList && cityList?.length > 0 ? cityList : []}

                                {...getFieldProps('freelancerCity')}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.label || ''}
                                onChange={(e, value) => {
                                    setFieldValue('freelancerCity', value ? value : null);
                                }}
                                noOptionsText={city?.cityListLoading?'Loading... please wait.':'No Options'}
                                renderInput={(params) => (
                                    <ETextField
                                        {...params}
                                        name="freelancerCity"
                                        placeholder="Select City"
                                        fullWidth
                                        error={Boolean(touched.freelancerCity && errors.freelancerCity)}
                                        helperText={touched.freelancerCity && errors.freelancerCity}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Pincode'}>
                            <ETextField
                                fullWidth
                                autoComplete="pincode"
                                placeholder="Pincode"
                                {...getFieldProps('pincode')}
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(touched.pincode && errors.pincode)}
                                helperText={touched.pincode && errors.pincode}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    </Grid>


                    <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end' className='modal1-buttons-stick-bottom'>
                        <EButtonOutlined size="large" variant="outlined"
                            onClick={() => closePersonalDetailModal()}
                        > Cancel </EButtonOutlined>
                        <ELoadingButton
                            loading={freelancerLoading} 
                            size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                    </Stack>
                </LocalizationProvider>
            </Form>
        </FormikProvider>

    )

};

export default FreelancerEditModalPersonalDetail;