import React, { useEffect, useRef, useState } from 'react';
import {
    TablePagination,
    Grid,
    ListItemIcon,
    ListItemText,
    IconButton,
    useTheme
} from '@mui/material';
import info from '@iconify/icons-material-symbols/info-outline'
import currencyRupeeIcon from '@iconify/icons-material-symbols/currency-rupee'
import backHandIcon from '@iconify/icons-material-symbols/back-hand'
import visibility from '@iconify/icons-material-symbols/visibility-outline'
import blockIcon from '@iconify/icons-material-symbols/block'
import Playlist from '@iconify/icons-material-symbols/playlist-add-check-rounded'
// import LabProfile from '@iconify/icons-material-symbols/lab-profile-rounded'
import Rupee from '@iconify/icons-material-symbols/currency-rupee'
import SimCardDownload from '@iconify/icons-material-symbols/sim-card-download-outline'

import moment from 'moment';
// import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import PendingSalaryViewActions from './PendingSalaryViewActions';
import { useSelector, useDispatch } from 'react-redux';
// import { SalaryDetailedList } from '../../../actions/PaymentAction';
// import PaymentMonthFilter from '../PaymentFilters';
import { DataGrid } from '@mui/x-data-grid';
import { numberWithCommas } from '../../utils/formatNumber';
import {
    DEFAULT_ROWS_PERPAGE, ROW_SIZE_PER_PAGE,
    CANCELLED_STATUS,
    COMPLETE_STATUS,
    PENDING_STATUS,
    PARTIAL_STATUS,
    HOLD_STATUS,
    PAID_STATUS,
    BULK,
    SALARY_SLIP_DOWNLOAD_URL,
    PERMISSIONS
} from '../../constants';

import { SalaryDetailedList } from '../../action/PaymentAction';
import SalaryPayrollDetailListSearchBar from './SalaryPayrollDetailListSearchBar';
import SearchNotFound from '../SearchNotFound';
import EChip from '../../BaseComponents/EChip';
import { ETooltip } from '../../BaseComponents/ETooltip';
import ActionMenu from './ActionMenu';
import { EMenuItem } from '../../BaseComponents/EMenuPopover';
import { EIcon } from '../../BaseComponents/EIcon';
import EModal from '../../BaseComponents/EModal';
import PaymentModal from './PaymentModal';
import PaymentHoldModal from './PaymentHoldModal';
import PaymentCancelModal from './PaymentCancelModal';
import { getPayrollDetail } from '../../action/PayrollAction';
import PaymentBulkModal from './PaymentBulkModal';
import PaymentTransactionList from './PaymentTransactionList';
import PaymentRowDetail from './PaymentRowDetail';
import { DownLoadFile } from '../../action/AdminAction';
import { sentenceCaseString } from '../../utils/formatText';
import { useCheckPermission } from '../../hooks';

function SalaryPayrollDetail(props) {

    const { setShowDetailPage, tabvalue, auth, showDetailPage } = props;
    const theme = useTheme();
    const dispatch = useDispatch();


    const initialBulkSelectedValue = {
        ids: [], total_amount: 0, payroll_id: showDetailPage?.payrollId, payment_ids: []
    };

    const paymentDetailedList = useSelector((state) => state.PaymentReducer.PaySalaryListData);
    const payrollData = useSelector((state) => state.PayrollReducer.payrollDetailData);

    const SelectedRowsIds = useRef([]);
    const SelectedRowsIdsAmount = useRef([]);
    const [state2, setState2] = useState(initialBulkSelectedValue);

    const [page, setPage] = useState(0);
    const [salaryDueModal, setSalaryDueModal] = useState({ modalData: null, modalCategory: false });
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const [searchParam, setSearchParam] = useState({
        payroll_id: showDetailPage?.payrollId,
        fullname: '',
        transaction_status: '',
        sort: true,
        year_month: null
    });

    const allowSalaryPayment = useCheckPermission([PERMISSIONS.SideBarSalaryPayment])
    const allowDownloadPaySlip = useCheckPermission([PERMISSIONS.DownloadPaySlip])


    const getFilterData = (searchData) => {
        callApi(searchData, 0, rowsPerPage);
        setPage(0);

        //  empty the bulk payment State
        SelectedRowsIds.current = [];
        SelectedRowsIdsAmount.current = [];
        setState2(initialBulkSelectedValue);
    }

    // call firstime when page opened 
    useEffect(() => {
        if (auth.authtoken) {
            let sParams = { ...searchParam };
            if (tabvalue == HOLD_STATUS || tabvalue == CANCELLED_STATUS) {
                // this for hold and canclled tab
                sParams.transaction_status = tabvalue
                sParams.payroll_id = '';
            } else {
                // this for paid and pending tab
                sParams.transaction_status = '';
                sParams.payroll_id = showDetailPage?.payrollId;
                dispatch(getPayrollDetail(auth.authtoken, showDetailPage?.payrollId))
            }
            setSearchParam(sParams)
            callApi(sParams, page, rowsPerPage);

        }
    }, [tabvalue])


    const handleChangePage = (event, newPage) => {
        callApi(searchParam, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(searchParam, 0, rowPerPageCount);
        setPage(0);

        //  empty the bulk payment State
        SelectedRowsIds.current = [];
        SelectedRowsIdsAmount.current = [];
        setState2(initialBulkSelectedValue);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(SalaryDetailedList(auth.authtoken, { ...fnParams, sort: !fnParams.sort ? ' id ASC ' : 'id DESC' }, fnPage, fnRowPerPage));
    }



    const actionEvent = (params1, params2) => {
        setSalaryDueModal({ modalData: params1, modalCategory: params2 });
    }

    const rows = gridRows(searchParam.transaction_status, paymentDetailedList?.rows, page, rowsPerPage);
    const columns = gridColumns(tabvalue, actionEvent, auth, allowSalaryPayment, allowDownloadPaySlip);


    const SetSelectedIds = (ids, num = null) => {

        SelectedRowsIds.current[page] = ids
        const reduceData = rows.reduce((acc, cur) => {
            if (ids.includes(cur.rowId)) {
                acc += cur.Action?.pay_amount && cur.Action?.transaction?.collect_amount ?
                    (Number(cur.Action?.pay_amount) - Number(cur.Action?.transaction?.collect_amount))
                    : cur.Action?.pay_amount ? Number(cur.Action?.pay_amount) : 0;
            }
            return acc;                                           // Return accumulator
        }, 0);

        const arrayMainIds = SelectedRowsIds.current.flat();
        SelectedRowsIdsAmount.current[page] = reduceData;
        const arrayMainIdsAmount = SelectedRowsIdsAmount.current.flat();
        const finalAmount = arrayMainIdsAmount.reduce((partialSum, a) => partialSum + a, 0);

        setState2({ ...state2, ids: SelectedRowsIdsAmount.current, total_amount: finalAmount, payment_ids: arrayMainIds });

    };


    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}  md={12} lg={12} xl={12} >
                    <SalaryPayrollDetailListSearchBar
                        setSearchParam={setSearchParam}
                        setBulkPaymentModal={setSalaryDueModal}
                        searchParam={searchParam}
                        tabvalue={tabvalue}
                        getFilterData={getFilterData}
                        bulkData={state2}
                        payrollData={payrollData}
                        setShowDetailPage={setShowDetailPage}
                    />
                </Grid>
                <Grid item sm={12} md={12} my={2}>

                    {/* // Datagrid */}
                    {paymentDetailedList && paymentDetailedList?.rows?.length == 0 ?
                        <div className="display-flex justifycenter">
                            <SearchNotFound />
                        </div>
                        :
                        <div style={{ width: '100%' }}>
                            {rows && rows.length > 0 && <DataGrid
                                rows={rows}
                                // getRowId={(row) => (row.EmployeeId+"-"+row.PayrollId)}
                                getRowId={(row) => row.rowId}
                                columns={columns}
                                pageSize={rowsPerPage}
                                disableSelectionOnClick
                                autoHeight
                                disableColumnMenu
                                keepNonExistentRowsSelected
                                checkboxSelection={tabvalue == PENDING_STATUS ? true : false}
                                isRowSelectable={(params) =>
                                    params?.row?.transaction_status == PARTIAL_STATUS || params?.row?.transaction_status == PENDING_STATUS
                                }
                                selectionModel={SelectedRowsIds.current[page]}
                                onSelectionModelChange={(ids) => {
                                    SetSelectedIds(ids);
                                }}
                                disableMultipleSelection
                                hideFooterPagination={true}
                                hideFooter={true}
                                disableColumnSelector={true}
                                sx={{
                                    '.css-x5d0om-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled ': {
                                        color: 'rgba(145, 158, 171, 0.8)',
                                        display: 'none'
                                    },
                                    '& .super-app-theme--header': {
                                        backgroundColor: theme.palette.background.tableHeader,
                                        borderBottom: '3px solid',
                                        borderBottomColor: theme.palette.primary.main
                                    },
                                    '& .MuiDataGrid-columnHeaderCheckbox': {
                                        backgroundColor: theme.palette.background.tableHeader,
                                        borderBottom: '3px solid',
                                        borderBottomColor: theme.palette.primary.main
                                    },
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                    },
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    '& .MuiDataGrid-virtualScroller':{
                                        overflow: 'auto!important',
                                        maxWidth:'1000px'
                                    }
                                    // overflow: 'auto!important',

                                }}
                            />}

                        </div>
                    }
                </Grid>
            </Grid>
            {/* //table pagination  */}
            {paymentDetailedList?.count && paymentDetailedList?.count > 0 &&
                paymentDetailedList?.count > DEFAULT_ROWS_PERPAGE &&

                <TablePagination
                    rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                    component="div"
                    count={paymentDetailedList?.count ? paymentDetailedList?.count : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }

            {/* //////  Document Detail Modal */}
            {salaryDueModal && salaryDueModal.modalCategory == PAID_STATUS &&
                <EModal open={(salaryDueModal.modalCategory == PAID_STATUS)}
                    headervalue={`Payment-${moment(payrollData?.month_date).format('MMMM YYYY')}`}
                >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                            <PaymentModal
                                paymentData={salaryDueModal.modalData}
                                setSalaryDueModal={setSalaryDueModal}
                                auth={auth}
                                getPaymentFilterData={() => getFilterData(searchParam)}
                            />
                        </Grid>
                    </Grid>
                </EModal>}

            {salaryDueModal && salaryDueModal.modalCategory == HOLD_STATUS &&
                <EModal open={(salaryDueModal.modalCategory == HOLD_STATUS)}
                    headervalue={`Hold Payment-${moment(payrollData?.month_date).format('MMMM YYYY')}`}
                >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                            <PaymentHoldModal
                                paymentData={salaryDueModal.modalData}
                                setSalaryDueModal={setSalaryDueModal}
                                auth={auth}
                                getPaymentFilterData={() => getFilterData(searchParam)}
                            />
                        </Grid>
                    </Grid>
                </EModal>}


            {salaryDueModal && salaryDueModal.modalCategory == CANCELLED_STATUS &&
                <EModal open={(salaryDueModal.modalCategory == CANCELLED_STATUS)}
                    headervalue={`Cancel Payment-${moment(payrollData?.month_date).format('MMMM YYYY')}`}
                >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                            <PaymentCancelModal
                                paymentData={salaryDueModal.modalData}
                                setSalaryDueModal={setSalaryDueModal}
                                auth={auth}
                                getPaymentFilterData={() => getFilterData(searchParam)}
                            />
                        </Grid>
                    </Grid>
                </EModal>}


            {salaryDueModal && salaryDueModal.modalCategory == BULK &&
                <EModal open={(salaryDueModal.modalCategory == BULK)}
                    headervalue={`BULK: Payment - ${moment(payrollData?.month_date).format('MMMM YYYY')}`}
                >
                    <PaymentBulkModal
                        paymentData={salaryDueModal.modalData}
                        setModalState={setSalaryDueModal}
                        isSalary = {true}
                        auth={auth}
                        getPaymentFilterData={() => getFilterData(searchParam)}
                    />
                </EModal>}


            {salaryDueModal && salaryDueModal?.modalData && salaryDueModal.modalCategory == 'TRANSACTIONLIST' &&
                <EModal open={(salaryDueModal.modalCategory == 'TRANSACTIONLIST')} newMaxWidth={'md'}
                    headervalue={`${salaryDueModal?.modalData?.employeeData?.fullname} - ${salaryDueModal?.modalData?.employeeData?.employee_code} - ${moment(salaryDueModal.modalData?.payrollData?.month_date).format('MMMM YYYY')}`}
                >
                    <PaymentTransactionList
                        paymentData={salaryDueModal.modalData}
                        setSalaryDueModal={setSalaryDueModal}
                        auth={auth}
                    />
                </EModal>}


            {salaryDueModal && salaryDueModal.modalCategory == 'ROWDETAILHOLD' &&
                <EModal open={(salaryDueModal.modalCategory == 'ROWDETAILHOLD')}
                    headervalue={`Payment On-Hold - ${moment(salaryDueModal.modalData?.payrollData?.month_date).format('MMMM YYYY')}`}
                >
                    <PaymentRowDetail
                        paymentData={salaryDueModal.modalData}
                        setSalaryDueModal={setSalaryDueModal}
                        tabvalue={tabvalue}
                    />
                </EModal>}

            {salaryDueModal && salaryDueModal.modalCategory == 'ROWDETAILCANCELLED' &&
                <EModal open={(salaryDueModal.modalCategory == 'ROWDETAILCANCELLED')}
                    headervalue={`Cancelled Payment - ${moment(salaryDueModal.modalData?.payrollData?.month_date).format('MMMM YYYY')}`}
                >
                    <PaymentRowDetail
                        paymentData={salaryDueModal.modalData}
                        setSalaryDueModal={setSalaryDueModal}
                        tabvalue={tabvalue}
                    />
                </EModal>}

        </>
    );
}

const gridRows = (tabvalue, paymentDetailedList, page, rowsPerPage) => {

    const rows = paymentDetailedList && paymentDetailedList.length > 0
        ? paymentDetailedList?.map((data, index) => {

            const { transaction_date, payroll_id, accountant_remark, pay_amount, id, transaction_status, payrollData, basic_salary, employeeData } = data


            let payamount = Number(data?.pay_amount) ? Number(data?.pay_amount) : 0;
            let paidSalary = Number(data?.transaction?.collect_amount) ? Number(data?.transaction?.collect_amount) : 0;

            let balanceAmount = payamount - paidSalary

            return ({
                id: page == 0 ? index + 1 : (index + 1) + (rowsPerPage * page),
                EmployeeId: employeeData?.id ? employeeData?.id : '-',
                PayrollId: payroll_id,
                accountantRemark: accountant_remark,
                rowId: id,
                PayrollMonth: payrollData?.month_date ? moment(payrollData?.month_date).format('MMM YYYY') : '-',
                EmployeeCode: employeeData?.employee_code ? employeeData?.employee_code : '-',
                TalentName: employeeData?.fullname ? employeeData?.fullname : '-',
                CTC: basic_salary ? numberWithCommas(basic_salary) : numberWithCommas(0),
                Payable_Salary: payamount ? numberWithCommas(payamount) : numberWithCommas(0),
                Paid_Salary: Number(paidSalary) ? numberWithCommas(paidSalary) : numberWithCommas(0),
                Balance_Amount: balanceAmount ? numberWithCommas(balanceAmount) : numberWithCommas(0),
                Last_Update: transaction_date ? moment(transaction_date).format('DD/MM/yyyy') : '-',
                // Amount: pay_amount ? numberWithCommas(pay_amount) : numberWithCommas(0),
                transaction_status: transaction_status ? transaction_status : '',
                Action: data,
                Salary_Slip: data,
            })
        })
        : [];
    return rows;
};

const gridColumns = (tabvalue, clickEventFun, auth, allowSalaryPayment, allowDownloadPaySlip) => {

    const DownloadReport = (employee_id = null, payroll_id = null) => {

        if (employee_id && payroll_id) {
            let url = SALARY_SLIP_DOWNLOAD_URL;
            url += `?download_format=PDF&payroll_id=${payroll_id}&employee_id=${employee_id}`;
            let file_name = 'Salary-Slip';
            DownLoadFile(auth.authtoken, url, file_name)
        }
    }
    let gridClm = [
        {
            field: 'id',
            headerName: 'SNo.',
            width: 80,
            editable: false,
            headerClassName: 'super-app-theme--header',
            sortable: false,
            hide: tabvalue == PENDING_STATUS ? true : false,
        },
        {
            field: 'TalentName',
            headerName: 'Talent Name',
            minWidth: 250,
            headerClassName: 'super-app-theme--header',
            flex: 2,
            editable: false,
            sortable: false,
        },
        {
            field: 'PayrollMonth',
            headerName: 'Month',
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            flex: 2,
            editable: false,
            sortable: false,
            hide: (tabvalue == HOLD_STATUS || tabvalue == CANCELLED_STATUS) ? false : true
        },

        {
            field: 'CTC',
            headerName: 'CTC',
            type: 'number',
            headerClassName: 'super-app-theme--header',
            width: 150,
            sortable: false,
            editable: false,
            hide: (tabvalue == HOLD_STATUS || tabvalue == CANCELLED_STATUS) ? true : false
        },
        {
            field: 'Payable_Salary',
            headerName: tabvalue === PENDING_STATUS ? 'Payable Salary' : 'Payable Amount',
            type: 'number',
            width: 150,
            headerClassName: 'super-app-theme--header',
            sortable: false,
            editable: false
        },
    ];

    
    gridClm.push({
        field: 'Balance_Amount',
        headerName: tabvalue == PENDING_STATUS ? 'Balance Amount' : tabvalue === CANCELLED_STATUS ? 'Cancelled Amount' : 'On-Hold Amount',
        type: 'number',
        width: 150,
        headerClassName: 'super-app-theme--header',
        sortable: false,
        editable: false,
        hide: (tabvalue == PENDING_STATUS || tabvalue == HOLD_STATUS || CANCELLED_STATUS) ? false : true
    });
 

    gridClm.push({
        field: 'Paid_Salary',
        headerName: tabvalue === PENDING_STATUS ? 'Paid Salary' : 'Paid Amount',
        type: 'number',
        width: 150,
        headerClassName: 'super-app-theme--header',
        sortable: false,
        editable: false,
        renderCell: (params, value) => {

            return (<>{params?.row?.Paid_Salary}
                {params?.row?.accountantRemark && (
                    <ETooltip title={params?.row?.accountantRemark} arrow>
                        <EIcon icon={info} />
                    </ETooltip>
                )}
            </>)
        }
    });

    gridClm.push({
        field: 'Last_Update',
        headerName: 'Updated On',
        minWidth: 150,
        headerClassName: 'super-app-theme--header',
        flex: 1,
        editable: false,
        sortable: false,
    },

        {
            field: 'transaction_status',
            headerName: 'Status',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            minWidth: 150,
            // hide: tabvalue == PAID_STATUS ? true : false,
            hide: (tabvalue == HOLD_STATUS || tabvalue == CANCELLED_STATUS) ? true : false,
            flex: 1,
            renderCell: (params, value) => {

                return (
                    <Grid className="display-flex align-items-center justify-content-center" id="talent">

                        <EChip
                            width="120px"
                            label={
                                params?.row?.transaction_status == COMPLETE_STATUS
                                    ? 'Paid'
                                    : params?.row?.transaction_status == PARTIAL_STATUS
                                        ? 'Partially Paid' : params?.row?.transaction_status == CANCELLED_STATUS ? 'Cancelled' : sentenceCaseString(params?.row?.transaction_status)}
                        />

                        {/* {tabvalue ? (
                            <EChip
                                label={
                                    tabvalue == PENDING_STATUS
                                        ? 'Pending'
                                        : tabvalue == PAID_STATUS
                                            ? 'Paid'
                                            : tabvalue == CANCELLED_STATUS
                                                ? 'Cancelled'
                                                : tabvalue == HOLD_STATUS
                                                    ? 'Hold'
                                                    : 'Partial'
                                }
                            />
                        ) : (
                            '-1'
                        )} */}



                        {/* {params?.row?.Status == COMPLETE_STATUS && params?.row?.Action?.accountant_remark && (
                            <ETooltip title={params?.row?.Action?.accountant_remark} arrow>
                                <span>i</span>
                            </ETooltip>
                        )} */}
                    </Grid>
                );
            },
            editable: false
        },
    );

    if (tabvalue === PENDING_STATUS) {
        gridClm.push({
            field: 'Action',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            sortable: false,
            minWidth: 80,
            renderCell: (params, value) => {

                return (
                    <>
                        {params?.row?.transaction_status !== COMPLETE_STATUS && params?.row?.transaction_status !== CANCELLED_STATUS && <ActionMenu>
                            {(allowSalaryPayment && [PARTIAL_STATUS, HOLD_STATUS, PENDING_STATUS].includes(params?.row?.transaction_status)) && 
                            <EMenuItem onClick={() => clickEventFun(params.value, PAID_STATUS)}>
                                <ListItemIcon>
                                    <EIcon icon={currencyRupeeIcon} />
                                </ListItemIcon>
                                <ListItemText primary="Pay Salary" />
                            </EMenuItem>}
                            {[PARTIAL_STATUS, PENDING_STATUS].includes(params?.row?.transaction_status) &&
                                <EMenuItem onClick={() => clickEventFun(params.value, HOLD_STATUS)}>
                                    <ListItemIcon>
                                        <EIcon icon={backHandIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Hold Salary" />
                                </EMenuItem>
                            }
                            {[PARTIAL_STATUS, PENDING_STATUS, HOLD_STATUS].includes(params?.row?.transaction_status) &&
                                <EMenuItem onClick={() => clickEventFun(params.value, CANCELLED_STATUS)}>
                                    <ListItemIcon>
                                        <EIcon icon={blockIcon} />
                                    </ListItemIcon>
                                    <ListItemText primary="Cancel Salary" />
                                </EMenuItem>
                            }

                        </ActionMenu>}
                    </>

                )
            }
        });
    }

    if (tabvalue === PAID_STATUS) {
        gridClm.push({
            field: 'Salary_Slip',
            headerClassName: 'super-app-theme--header',
            headerName: 'Salary Slip',
            sortable: false,
            width: 130,
            renderCell: (params, value) => {
                return <>
                    <IconButton onClick={() => clickEventFun(params.value, 'TRANSACTIONLIST')}>
                        <EIcon icon={Playlist} />
                    </IconButton>   
                    {/* Removed download payslip funtionality for cancelled status */}
                    {
                        (allowDownloadPaySlip && params?.row?.transaction_status != CANCELLED_STATUS) && (
                            <IconButton onClick={() => DownloadReport(params?.row?.EmployeeId, params?.row?.PayrollId)}>
                            <EIcon icon={SimCardDownload} />
                        </IconButton>
                        )
                    }
                </>
            }
        });
    }

    if (tabvalue === HOLD_STATUS) {
        gridClm.push({
            field: 'Action',
            headerName: 'Action',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 130,
            renderCell: (params, value) => {
                return <>
                <ETooltip title={'View'} arrow>
                    <IconButton onClick={() => clickEventFun(params.value, 'ROWDETAILHOLD')}>
                        <EIcon icon={visibility} />
                    </IconButton>
                </ETooltip>
                <ETooltip title={'Pay'} arrow>
                    <IconButton onClick={() => clickEventFun(params.value, PAID_STATUS)}>
                        <EIcon icon={Rupee} className={'theme-color-static'} />
                    </IconButton>
                </ETooltip>
                </>
            }
        });
    }

    if (tabvalue === CANCELLED_STATUS) {
        gridClm.push({
            field: 'Action',
            headerName: 'Action',
            headerClassName: 'super-app-theme--header',
            sortable: false,
            width: 130,
            renderCell: (params, value) => {
                return <>
                <ETooltip title={'View'} arrow>
                    <IconButton onClick={() => clickEventFun(params.value, 'ROWDETAILCANCELLED')}>
                        <EIcon icon={visibility} />
                    </IconButton>
                </ETooltip>
                </>
            }
        });
    }

    return gridClm;
};


export default SalaryPayrollDetail;