import { Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { ETextField } from "../../BaseComponents/ETextField";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import { updateTaletOfficialDetail } from "../../action/TalentActions";
import { EditOfficalDetailValidation } from "../../utils/validation";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { WORK_MODE } from "../../constants";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import moment from "moment";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";

const EditModalOfficialDetail = (props) => {
    const { auth, employeeData, closeModalEditOfficialDetail, isShiftAllowed, shiftList } = props;
    const departmentList = useSelector(state => state.DepartmentReducer?.departmentListData?.rows)
    const designationList = useSelector(state => state.DesignationReducer?.designationListData?.rows)

    // INFO: defaultShiftId is used to send shift_id as defaultShiftId when company have only one shift
    const defaultShiftId = shiftList?.filter(i => i.is_default_shift)?.[0]?.id || null

     // INFO: getting company settings
    const settingsContext = useCompanySettingProvider()
    const LastPayrollDataToDate=settingsContext?.setting?.accountSettingData?.companySetting?.company_data?.lastPayroll?.to_date;// fetching payroll data here from settings

    // Info: As per discussion with @Dharam Sir & @sunil Sir on 16th May 23, The only validation in the DOJ will be the last Payroll locked date (to_date+1) else it will be open.
    const minDojValidation= LastPayrollDataToDate? moment(LastPayrollDataToDate).add(1, 'day').toDate(): null;


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const initialWorkMode = employeeData.work_mode && WORK_MODE.find(function (e) {
        return e.id == employeeData.work_mode;
    });


    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            doj: employeeData?.doj ? new Date(employeeData?.doj) : new Date(),
            department: employeeData?.departmentInfo,
            designation: employeeData?.designationInfo,
            talent_id: employeeData?.id,
            work_mode: !initialWorkMode ? null : initialWorkMode,
            isShiftAllowed,
            shift_object: employeeData?.shiftInfo,
            payroll_basic: employeeData?.employeePayrollInfo?.payroll_basic ? Math.round(employeeData?.employeePayrollInfo?.payroll_basic) : '',
        },
        validationSchema: EditOfficalDetailValidation,
        onSubmit: (data) => {
            if (data) {
                data.shift_id = defaultShiftId
                if (data && data.work_mode && data.work_mode.id) { data.work_mode = data.work_mode.id; }
                if (data && data.department && data.department.id) { data.department = data.department.id; }
                if (data && data.designation && data.designation.id) { data.designation = data.designation.id; }
                if (data && data.shift_object && data.shift_object.id) { data.shift_id = data.shift_object.id; }
                data = { ...data, company_id: auth.authData?.company_id }
                dispatch(updateTaletOfficialDetail(auth.authtoken, data, navigate))
            }
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;


    return (

        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={"Date of Joining"}  >
                                <EDatePicker
                                    views={["day", 'month', 'year']}
                                    name="doj"
                                    {...getFieldProps('doj')}
                                    minDate={minDojValidation}
                                    maxDate={new Date()}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(selectedDate) => {
                                        setFieldValue("doj", selectedDate)
                                    }}

                                    renderInput={(params) =>
                                        <Field
                                            component={ETextField}
                                            {...params} fullWidth name="doj"
                                            error={Boolean(touched.doj && errors.doj)}
                                            helperText={touched.doj && errors.doj}
                                        />}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={"Department"} >
                                <EAutocomplete
                                    name="department"
                                    label="Department"
                                    fullWidth
                                    options={departmentList && departmentList?.length > 0 ? departmentList : []}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    {...getFieldProps('department')}
                                    onChange={(e, value) => {
                                        setFieldValue("department", value)
                                    }}
                                    getOptionLabel={option => option.label || ""}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='department'
                                            placeholder='Select'
                                            fullWidth
                                            error={Boolean(touched.department && errors.department)}
                                            helperText={touched.department && errors.department}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={"Salary"} small_text={"(CTC/Month)"}>
                                <ETextField
                                    fullWidth
                                    multiline
                                    name={'payroll_basic'}
                                    {...getFieldProps('payroll_basic')}
                                    error={Boolean(touched.payroll_basic && errors.payroll_basic)}
                                    helperText={touched.payroll_basic && errors.payroll_basic}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={"Designation"} >
                                <EAutocomplete
                                    name="designation"
                                    fullWidth
                                    options={designationList && designationList?.length > 0 ? designationList : []}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('designation')}
                                    onChange={(e, value) => {
                                        setFieldValue("designation", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='designation'
                                            placeholder='Select'
                                            fullWidth
                                            error={Boolean(touched.designation && errors.designation)}
                                            helperText={touched.designation && errors.designation}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={"Work Mode"}>
                                <EAutocomplete
                                    name="work_mode"
                                    fullWidth
                                    options={WORK_MODE}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('work_mode')}
                                    onChange={(e, value) => {
                                        setFieldValue("work_mode", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='work_mode'
                                            placeholder='Select'
                                            fullWidth
                                            error={Boolean(touched.work_mode && errors.work_mode)}
                                            helperText={touched.work_mode && errors.work_mode}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        {
                            isShiftAllowed &&
                            <Grid item md={6} sm={6} xs={12} >
                                <ELabelInputMainGrid label={'Shift Name'} >
                                    <EAutocomplete
                                        name="shift_object"
                                        fullWidth
                                        options={shiftList}

                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={option => option.shift_name || ""}
                                        {...getFieldProps('shift_object')}
                                        onChange={(e, value) => {
                                            setFieldValue("shift_object", value)
                                        }}
                                        renderInput={params => (
                                            <ETextField
                                                {...params}
                                                name='shift_object'
                                                placeholder=' Select Shift'
                                                fullWidth
                                                error={Boolean(touched.shift_object && errors.shift_object)}
                                                helperText={touched.shift_object && errors.shift_object}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                        }
                    </Grid>
                    <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                        <EButtonOutlined size="large" variant="outlined" onClick={() => closeModalEditOfficialDetail(false)} > Cancel </EButtonOutlined>
                        <ELoadingButton size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                    </Stack>
                </LocalizationProvider>
            </Form>
        </FormikProvider>

    )

};

export default EditModalOfficialDetail;