import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormControl, FormControlLabel, Grid } from "@mui/material";
import Page from "../../BaseComponents/EPage";
import { EButton, EButtonOutlined, EButtonOutlinedIcon, EIconButton, ELoadingButton} from "../../BaseComponents/EButtons";
import { ETypography, ETypographyPageHeading} from "../../BaseComponents/ETypography";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { ECardBasic, ECardDashboard } from "../../BaseComponents/ECard";
import { EBoxPage } from "../../BaseComponents/EBox";
import { ERadio, ERadioGroup } from "../../BaseComponents/ERadio";
import { ETextField } from "../../BaseComponents/ETextField";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { Stack } from "@mui/system";
import EHidden from "../../BaseComponents/EHidden";
import { EIcon } from "../../BaseComponents/EIcon";
import EModal from "../../BaseComponents/EModal";
import ForwardArrow from "@iconify/icons-material-symbols/chevron-left-rounded";
import EditIcon from "@iconify/icons-material-symbols/edit-outline-rounded";
import AddIcon from "@iconify/icons-material-symbols/add-box-outline-rounded";
import DeleteIcon from "@iconify/icons-material-symbols/delete-outline-rounded";
import {PERMISSIONS, SET_ADDITIONAL_SETTING_API, XL} from "../../constants";
import { LoadingGrid } from "../../BaseComponents/EGrid";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow} from "../../BaseComponents/ETable";
import { Form, FormikProvider, useFormik } from "formik";
import { AddNewDocumentValidation } from "../../utils/validation";
import ESwitch from "../../BaseComponents/ESwitch";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
import { SetAdditionalSetting } from "../../action/SettingAction";
import { useCheckPermission } from "../../hooks";

/**
 * [2023-03-31]
 * Changes By: Purva Sharma
 * Description:- The additonal setting added are
 *              - DPR Setting: this setting has direct impact on the checkout modal i.e if true show the modal else checkout.
 *              - Document Setting: this is setting is  just creating a deafult set of document required by the company impacts will be on all the add/edit form where documents are used.
 **/

function AdditionalSettings(props) {
  const { auth } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const validationForNull = useRef(true);
  const settingData = useSelector((state) => state.SettingReducer);
  const settingsContext = useCompanySettingProvider(); //INFO: getting the setting data from the context.
  const company = useSelector(state => state.CompanyReducer)

  const additionalSettings=settingsContext?.setting?.additionSettingData ?? {};

  const [duplicateDocument, setDuplicateDocument] = useState('')

  // State to handle modal, it takes data for manipulation
  const [addModal, setAddModal] = useState({
    openModal: false,
    addDoc: false,
    isCustomDoc: null,
    row: [],
  });

  // State to handle new custom document addition
  const [addData, setAddData] = useState({
    document_name: "",
  })

  // state to handle editing a existing document
  const [updateData, setUpdateData] = useState({
    document_name: '',
  })

  // state to handle delete document modal
  const [deleteModal, setDeleteModal] = useState({
    openModal: false,
    document_name: "",
    document_id: null
  })

  // Table head
  const TABLE_HEAD = [
    { id: "SNo.", label: "SNo.", alignRight: "left" },
    { id: "Document Name", label: "Document Name", alignRight: "left" },
  ];

  // State to handle toggle state
  const [defaultSetting, setDefaultSetting] = useState(null)

  // state to handle DPR setting
  const [dprSetting, setDprSetting] = useState(
    additionalSettings?.companySetting?.dprSetting?.dpr_submission_required !== null
      ? additionalSettings?.companySetting?.dprSetting?.dpr_submission_required
      : null);

  // state to handle custom document setting yse/no
  const [customDocSetting, setCustomDocSetting] = useState(false)


  // INFO: This useEffect is for setting data coming from API
  useEffect(() => {
    if ( settingData?.settingLoading == false
      && settingsContext?.setting?.settingLoading==false
      && Object.keys(additionalSettings).length!=0) {

      const data= additionalSettings.is_default_setting==true? additionalSettings?.companyDefaultSetting:
      additionalSettings?.is_default_setting==false? additionalSettings?.companySetting:{};

      if(data && Object.keys(data).length!=0){//This if condition is checking if we have an empty object from the backend or not.
        let JsonData = JSON.stringify(data?.documentSetting); //Converting to JSON data to remove state mutation
        setData(JSON.parse(JsonData));
        setDprSetting(data?.dprSetting?.dpr_submission_required !== null
          ? data?.dprSetting?.dpr_submission_required
          : null)
        setCustomDocSetting(
          data?.dprSetting?.enable_custom_document !== null
            ? data?.dprSetting?.enable_custom_document
            : null
          // settingData?.globalSettingData?.dprSetting?.enable_custom_document ? 'true' : 'false'
        )
        HandleChange();
      }
        if (additionalSettings?.is_default_setting !== defaultSetting) {
          setDefaultSetting(additionalSettings?.is_default_setting)
        }
    }
    // Commented by Aanchal while optimizing the code; added below code inside the above if condition so that it will get initialized after the data will come from API.
    // if (additionalSettings?.is_default_setting !== defaultSetting) {
    //   setDefaultSetting(additionalSettings?.is_default_setting)
    // }
  }, [auth.authtoken, settingData?.settingLoading]);

 




  // INFO: updating State on the basis of index and is_required values
  const HandleChange = (index = null, value = null) => {
    validationForNull.current = true; //initializing
    setData((prevState) => {
      const update = [...prevState];
      // Commenting the code as it is not required as per new settings
      // if (index != null && value != null) {
      //   update[index].is_required = Number(value);
      // }
      // //INFO: checking for any nulls in the array
      // update?.map((row, index) => {
      //   if (row.is_required == null) {
      //     validationForNull.current = false;
      //   }
      // });
      return update;
    });
  };

  // INFO: This is for handling the Submit action
  const HandleSubmit = (defaultSetting) => {
    if (validationForNull.current) {
      let formData = {
        dprRequired: defaultSetting ? additionalSettings?.companyDefaultSetting?.dprSetting?.dpr_submission_required : dprSetting,
        data:defaultSetting ? additionalSettings?.companyDefaultSetting?.documentSetting: data,
        enable_custom_document: defaultSetting ? additionalSettings?.companyDefaultSetting?.dprSetting?.enable_custom_document : customDocSetting,
        is_default_setting: defaultSetting
      };
      dispatch(SetAdditionalSetting(auth.authtoken, formData, SET_ADDITIONAL_SETTING_API, navigate));
    }
  };

  // Handle update/edit
  const AddUpdateHandler = (add, type) => {
    switch (type) {
      case "ADD_DOCUMENT":
        if (add && (data.findIndex((item) => item.document_name.toLowerCase() == addData.document_name.toLowerCase()) < 0)) {
          // Handle adding of a new document
          setData((data) => [
            ...data,
            {
              ...addData,
              document_id: null,
              company_id: auth?.authData?.company_id,
              is_custom_document: 1,
            },
          ]
          )

          setAddModal(() => ({
            ...addModal,
            openModal: !addModal.openModal,
          }))
        } else {
          setDuplicateDocument('Document name already exists')
        }
        break;

      case "UPDATE_DOCUMENT":
        if (!add && (data.findIndex((item) => item.document_name.toLowerCase() == updateData.document_name.toLowerCase())) < 0) {
          // Handles editing of a existing document
          data.find((item) => {
            if (item.document_name == addModal.row.document_name) {
              return item.document_name = updateData.document_name
            }
          })

          setAddModal(() => ({
            ...addModal,
            openModal: !addModal.openModal,
          }))
        } else {
          setDuplicateDocument('Document name already exists')
        }
        break;

      default:
        break;
    }
  };

  // Handle Delete
  const handleDelete = () => {
    let filterData = data.filter((item) => item.document_name !== deleteModal.document_name)
    setData(() => filterData)
    setDeleteModal({
      ...deleteModal,
      openModal: !deleteModal.openModal,
    })
    setAddData({ ...addData, document_name: '' })
  }

  // Handle setting toggle
  const handleDefaultSetting = () => {
    setDefaultSetting(() => !defaultSetting)
    if (!defaultSetting == true) {
      HandleSubmit(true)
    }
  }

  const formik = useFormik({
    initialValues: {
      document_name: addModal?.addDoc ? '' : updateData?.document_name,
    },
    validationSchema: AddNewDocumentValidation,
    onSubmit: (data) => {
      if (addModal?.addDoc) {
        AddUpdateHandler(addModal?.addDoc, "ADD_DOCUMENT");
      } else {
        AddUpdateHandler(addModal?.addDoc, "UPDATE_DOCUMENT");
      }
      // 
    },
  });

  const { errors, touched, handleSubmit, setFieldValue} = formik;

  const allowEdit = useCheckPermission([PERMISSIONS.ViewEditAdditionalSettings])
  if (allowEdit) {
    TABLE_HEAD.push({ id: "Action", label: "Action", alignRight: "right" })
  }

  return (
    <Page title="Additional Settings">
      <Grid container display="row" alignItems={'center'} justifyContent="space-between">
        <Grid item>
          <EHeaderBreadcrumbs
            heading="Additional Settings"
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Company Settings", href: "" },
              {
                name: "Additional Settings",
              },
            ]}
          />
        </Grid>
        <EHidden width="mdDown">
          <Grid item>
            <EButtonOutlined variant="outlined" onClick={() => navigate("/")}>
              {" "}
              Back{" "}
            </EButtonOutlined>
          </Grid>
        </EHidden>
      </Grid>

      <Grid container display="row" alignItems={'center'} justifyContent="space-between" className="mt-16px">
        <Grid
          item
          xs={12}
          sm={12}
          className="display-flex justify-content-space-between"
        >
          <ETypographyPageHeading> Additional Settings </ETypographyPageHeading>

          {/* If onBoarding is completed, show switch, else don't */}
          {
            company?.companyData?.onBoarding?.onboarding_is_completed 
            ?
            settingData?.settingLoading?<></>:
            <ESwitch check={defaultSetting} handleToggle={handleDefaultSetting} label={defaultSetting ? "Default" : "Custom"} disabled={!allowEdit} />
            :
            null
          }

          
          <EHidden width="mdUp">
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => navigate("/")}
              className="button-left-margin"
            >
              <EIcon icon={ForwardArrow} className="height-width-15px" />{" "}
            </EButtonOutlinedIcon>
          </EHidden>
        </Grid>

        <Grid item xs={12} sm={12} className="mt-16px">
        {settingData?.settingLoading? (
            <LoadingGrid size={XL} isPage={true} />
          ) : (
            <EBoxPage className="p0">
              {/* DPR Setting */}
              <ECardBasic>
                <Grid container>
                  <Grid
                    item
                    xl={8}
                    lg={8}
                    md={8}
                    sm={6}
                    xs={6}
                    className="align-center"
                  >
                    <span className="bold-600 font-size-18px">
                      Make DPR submission optional on checkout
                    </span>
                  </Grid>

                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={6}
                    className="py0 text-align-right"
                  >
                    <FormControl component="fieldset">
                      <ERadioGroup
                        row={true}
                        aria-label="paymentSetting"
                        name="paymentSetting"
                        value={dprSetting == true || dprSetting == false ? dprSetting : null}
                      >
                        <FormControlLabel
                          value={true}
                          disabled={(!allowEdit || defaultSetting) ? true : false}
                          control={
                            <ERadio
                              className="px-6px py0"
                              onChange={(e, value) => {
                                setDprSetting(true);
                              }}
                            />
                          }
                          label={<span className="font-size-16px">Yes</span>}
                        />
                        <FormControlLabel
                          value={false}
                          disabled={(!allowEdit || defaultSetting) ? true : false}
                          control={
                            <ERadio
                                className="px-6px py0"
                              onChange={(e, value) => {
                                setDprSetting(false);
                              }}
                            />
                          }
                          label={<span className="font-size-16px">No</span>}
                          className=" mr-0px"
                        />
                      </ERadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="pt-0"
                  >
                    <span className="greyColor4-color">
                      Entera record daily progress report at the time of
                      checkout. Would you like to make it optional for your
                      talents?
                    </span>
                  </Grid>
                </Grid>
              </ECardBasic>

              {/* Document Setting */}


              <ECardBasic className="mb-0px border-05px-border6 ">
                <Grid
                  container
                  spacing={1}
                  justifyContent="space-between"
                  marginBottom="16px"
                >
                  <Grid
                    item
                    xl={8}
                    lg={8}
                    md={8}
                    sm={6}
                    xs={6}
                    className="align-center"
                  >
                    <ETypography className="bold-600 ">
                      Customize Documents
                    </ETypography>
                    <ETypography className="font-size-14px greyColor4-color">
                      Add any document to the list based on your organization's
                      requirement.
                    </ETypography>
                  </Grid>

                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={6}
                    className="py0 text-align-right"
                  >
                    <FormControl component="fieldset">
                      <ERadioGroup
                        row={true}
                        aria-label="enable_custom_document"
                        name="enable_custom_document"
                        value={customDocSetting == true || customDocSetting == false ? customDocSetting : null}
                      >
                        <FormControlLabel
                          value={true}
                          disabled={(!allowEdit || defaultSetting) ? true : false}
                          control={
                            <ERadio
                                className="px-6px py0"
                              onChange={(e, value) => {
                                setCustomDocSetting(true);
                              }}
                            />
                          }
                          label={<span className="font-size-16px">Yes</span>}
                        />
                        <FormControlLabel
                          value={false}
                          disabled={(!allowEdit || defaultSetting) ? true : false}
                          control={
                            <ERadio
                                className="px-6px py0"
                              onChange={(e, value) => {
                                setCustomDocSetting(false);
                              }}
                            />
                          }
                          label={<span className="font-size-16px">No</span>}
                          className=" mr-0px"
                        />
                      </ERadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* {customDocSetting == 'true' ? ( */}
                <Grid>
                  <ECardDashboard
                    className={"Bg-card4 border-none shadow-none"}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <ETypography className="font-size-20px bold-600">
                          Document List
                        </ETypography>
                      </Grid>

                      {(allowEdit && customDocSetting == true) && (
                        <Grid item>
                          <EButtonOutlinedIcon
                            onClick={() => {
                              setDuplicateDocument('')
                              // setFieldValue('document_name','')
                              setAddModal(() => ({
                                ...addModal,
                                document_name: '',
                                openModal: !addModal.openModal,
                                addDoc: true,
                              }))
                            }

                            }
                          >
                            <EIcon
                              className="height-width-11px theme-color-static"
                              icon={AddIcon}
                            />
                            <ETypography className="ml-10px font-size-14px theme-color-static">
                              Add
                            </ETypography>
                          </EButtonOutlinedIcon>
                        </Grid>
                      )}

                    </Grid>

                    {/* DOCUMENT TABLE */}
                    <ETableContainer>
                      <ETable>
                        {/* Table Head */}
                        <EListHead headLabel={TABLE_HEAD} />

                        {/* Table Body */}
                        <ETableBody>
                          {data &&
                            data?.map((row, index) => {
                              return (
                                <ETableRow key={index + 1}>
                                  {/* SNo */}
                                  <ETableCell className="width-60px-additional-setting">{index + 1}</ETableCell>

                                  {/* Document Name */}
                                  <ETableCell className="pl-0px">{row.document_name}</ETableCell>


                                  {/* Action */}
                                  {
                                    allowEdit &&
                                    <ETableCell align="right">

                                      {row?.is_custom_document == "1" && (
                                        <>

                                          {/* EDIT Button */}
                                          <EIconButton disabled={defaultSetting ? true : false} onClick={() => {
                                            setAddModal(() => ({
                                              ...addModal,
                                              openModal: !addModal.openModal,
                                              addDoc: false,
                                              isCustomDoc:
                                                row?.is_custom_document == "1"
                                                  ? true
                                                  : false,
                                              row: row,
                                            }))
                                            setUpdateData(() => ({
                                              document_name: row?.document_name,

                                            }))
                                            setFieldValue("document_name", row?.document_name)


                                          }
                                          }>
                                            <EIcon
                                              className="cursor-pointer"

                                              icon={EditIcon}
                                            />
                                          </EIconButton>

                                          {/* Delete Button */}
                                          <EIconButton
                                            disabled={defaultSetting ? true : false}
                                            onClick={() => {
                                              setDeleteModal(() => ({
                                                ...deleteModal,
                                                openModal: !deleteModal.openModal,
                                                document_name: row?.document_name,
                                                document_id: row?.document_id
                                              }))
                                            }}>
                                            <EIcon
                                              icon={DeleteIcon}
                                              className={defaultSetting ? "cursor-pointer" : "icon-red cursor-pointer"}
                                            />
                                          </EIconButton>


                                        </>
                                      )}
                                    </ETableCell>
                                  }
                                </ETableRow>
                              );
                            })}
                        </ETableBody>
                      </ETable>
                    </ETableContainer>
                  </ECardDashboard>
                </Grid>
                {/* ):'' */}

                {/* } */}

                {/* Add/Update MODAL */}
                {addModal.openModal && (
                  <EModal
                    open={addModal.openModal}
                    headervalue={
                      addModal.addDoc ? "Add Document" : "Update Document"
                    }
                  >
                    <FormikProvider value={formik} id="previewBlock">
                      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        {/* <form onSubmit={(e)=> AddUpdateHandler({e, add: addModal.addDoc, type: addModal.addDoc ? 'ADD_DOCUMENT' :'UPDATE_DOCUMENT' })}> */}
                        {addModal.addDoc ? (
                          <Grid container alignItems={"center"}>

                            {/*Document Name  */}
                            <Grid item md={12} xs={12}>
                              <ELabelInputMainGrid
                                label={"Document Name"}
                                isModal={true}
                                alignlabel={'true'}
                                isfullgrid={true}
                              >
                                <ETextField
                                  fullWidth
                                  type="text"

                                  onChange={(e) => {
                                    setFieldValue("document_name",
                                      e.target.value);
                                    setAddData(() => ({
                                      ...addData,
                                      document_name: e.target.value
                                    }))
                                    setDuplicateDocument('')
                                  }}
                                  error={Boolean(touched.document_name && errors.document_name || duplicateDocument)}
                                  helperText={touched.document_name && errors.document_name || duplicateDocument}
                                  placeholder="Document Name" />

                              </ELabelInputMainGrid>


                            </Grid>
                          </Grid>
                        )
                          : (
                            <ELabelInputMainGrid
                              className="p0"
                              label="Document Name"
                              isModal={true}
                              isNotForm={!addModal.isCustomDoc ? true : false}
                              isfullgrid={true}
                              alignlabel={'true'}
                            >
                              {!addModal.isCustomDoc ? (
                                <ETypography>
                                  {addModal?.row?.document_name}
                                </ETypography>
                              ) : (
                                <ETextField
                                  onChange={(e) => {
                                    setFieldValue('document_name', e.target.value)
                                    setUpdateData(() => ({
                                      ...updateData, document_name: e.target.value
                                    }))
                                    setDuplicateDocument('')
                                  }}
                                  error={Boolean(touched.document_name && errors.document_name || duplicateDocument)}
                                  helperText={touched.document_name && errors.document_name || duplicateDocument}
                                  fullWidth
                                  value={updateData?.document_name}
                                  placeholder="Document Name" />
                              )}
                            </ELabelInputMainGrid>

                          )

                        }


                        <Stack
                          direction="row"
                          spacing={2}
                          marginTop={2}
                          justifyContent="flex-end"
                        >
                          <EButtonOutlined variant="outlined"
                            onClick={() => {
                              setAddModal({
                                ...addModal,
                                openModal: !addModal.openModal,
                              },

                              )
                              setDuplicateDocument('')

                            }


                            }
                          >
                            Cancel
                          </EButtonOutlined>
                          <EButton
                            // onClick={()=>setFieldValue("addDoc",null)}
                            type="submit"
                          >
                            {addModal?.addDoc ? "Add" : "Update"}
                          </EButton>
                        </Stack>
                        {/* </form> */}
                      </Form>
                    </FormikProvider>
                  </EModal>
                )}


                {/* Delet Modal */}
                {
                  deleteModal.openModal && (
                    <EModal
                      open={deleteModal.openModal}
                      headervalue="Delete: Confirmation">

                      <ETypography className="font-size-20px mb-16px">
                        Are you sure you want to delete <span className="bold-600 text-decoration-underline theme-color-static">
                          {deleteModal.document_name}
                        </span> ?
                      </ETypography>

                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <EButtonOutlined variant="outlined"
                          onClick={() =>
                            setDeleteModal({
                              ...deleteModal,
                              openModal: !deleteModal.openModal,
                            })
                          }
                        >
                          No
                        </EButtonOutlined>
                        <EButton
                          onClick={() =>
                            handleDelete()
                          }
                        >
                          Yes
                        </EButton>
                      </Stack>
                    </EModal>
                  )
                }
              </ECardBasic>

              <Stack
                direction="row"
                className="justify-content-flex-end mt-16px"
              >
                {(allowEdit && !defaultSetting) && <ELoadingButton
                    onClick={() => HandleSubmit(false)}
                    variant="contained"
                    size="large"
                    className="button-left-margin font-size-14px"
                  >
                    { company?.companyData?.onBoarding?.onboarding_is_completed ? "Save" : "Save & Next"}
                  </ELoadingButton>
                }
              </Stack>
            </EBoxPage>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}
export default AdditionalSettings;

// const RadioGrid = (props) => {
//   const { label, children } = props;
//   return (
//     <>
//       <Grid item xl={4} lg={6} md={6.5} sm={7} xs={5}>
//         {" "}
//         {label}{" "}
//       </Grid>
//       <Grid item xl={4} lg={5.6} md={4} sm={4.3} xs={6}>
//         {" "}
//         {children}{" "}
//       </Grid>
//       <Grid item xl={4} lg={0} md={0} sm={0} xs={0} />
//     </>
//   );
// };

  /**
 * [2023-03-31]
 * Changes By: Purva Sharma
 * if(is_default_setting == null){
 *         - Onboarding time
 *         - all initial values will be null
 *         - values === companySetting
 *         - 
 *}    else if(is_default_setting ==  true){
            - after setting is done
            - values === companyDefaultSetting
 }   else if(is_default_setting == false){
           - values === companySetting
 }
 *             
 **/

  
  // UseEffect to update modal value for validation
  // useEffect(()=>{
  //   setFieldValue("document_name",addModal?.row?.document_name)
  // },[addModal])