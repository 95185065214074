import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { EFieldLabelBold, ELabel } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid } from "@mui/material";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import { numberWithCommas } from "../../utils/formatNumber";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { BulkPaymentValidation } from "../../utils/validation";
import { useDispatch } from "react-redux";
import { HRAccountantPaymentAction } from "../../action/PaymentAction";
import { BULK_PAYMENT_URL, BULK_SALARY_PAYMENT_URL, PAYMENT_MODE } from "../../constants";



   

const PaymentBulkModal = (props) => {

    const { paymentData, setModalState, auth, getPaymentFilterData, isSalary } = props;
    const dispatch = useDispatch();


    const formik = useFormik({
        initialValues: {
            payment_id: paymentData?.payment_ids ? paymentData?.payment_ids : null,
            accountant_remark: '',
            payment_medium: PAYMENT_MODE[0],
            payment_detail: '',
        },
        validationSchema: BulkPaymentValidation,
        onSubmit: (data) => {

            /** IF Salary then url is changed */
            
            const url = isSalary ? BULK_SALARY_PAYMENT_URL : BULK_PAYMENT_URL;

            dispatch(HRAccountantPaymentAction(auth.authtoken, data, getPaymentFilterData, url));
            setModalState({ modalData: null, modalCategory: null })
        }
    });

    

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Number of Payments</EFieldLabelBold>
                            <ELabel>{paymentData?.payment_ids?.length}</ELabel>
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Total Amount</EFieldLabelBold>
                            <ELabel>{numberWithCommas(paymentData?.total_amount)}</ELabel>
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabelBold>Payment Mode</EFieldLabelBold>
                            <EAutocomplete
                                name="Payment Mode"
                                fullWidth
                                {...getFieldProps('payment_medium')}
                                options={PAYMENT_MODE}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(e, value) => {
                                    setFieldValue('payment_medium', value ? value : null)
                                    setFieldValue('isType', value?.id == 'CHEQUE' ? true : false)
                                    if (value?.id != 'CHEQUE') { setFieldValue('payment_detail', '') }
                                }}
                                renderInput={params => (
                                    <ETextField
                                        {...params}
                                        name='payment_medium'
                                        placeholder="Select Cash/Cheque"
                                        fullWidth
                                        error={Boolean(touched.payment_medium && errors.payment_medium)}
                                        helperText={touched.payment_medium && errors.payment_medium}
                                    />
                                )}
                            />
                        </FieldContainerCustom>
                    </Grid>

                    {getFieldProps('payment_medium').value && getFieldProps('payment_medium').value?.id == 'CHEQUE' &&
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={50}
                            >
                                <EFieldLabelBold>Cheque Number</EFieldLabelBold>
                                <ETextField
                                    name='payment_detail'
                                    {...getFieldProps('payment_detail')}
                                    rows={3}
                                    fullWidth
                                    error={Boolean(touched.payment_detail && errors.payment_detail)}
                                    helperText={touched.payment_detail && errors.payment_detail}
                                />
                            </FieldContainerCustom>
                        </Grid>}

                    
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabelBold>Remark</EFieldLabelBold>
                            <ETextField
                                name='accountant_remark'
                                multiline
                                rows={3}
                                {...getFieldProps('accountant_remark')}
                                fullWidth
                                error={Boolean(touched.accountant_remark && errors.accountant_remark)}
                                helperText={touched.accountant_remark && errors.accountant_remark}
                            />
                        </FieldContainerCustom>
                    </Grid>
                </Grid>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalCategory: null })}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton
                        size="large" variant="outlined" type="submit" >Pay</ELoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default PaymentBulkModal