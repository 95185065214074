import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Stack } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import PercentIcon from '@iconify/icons-material-symbols/percent-rounded';
import { AddPaymentTermValidation } from '../../../utils/validation';
import { PaymentCreateUpdateDelete } from '../../../action/ContractAction';
import { ELabelInputMainGrid } from '../../../BaseComponents/EGrid';
import { EIconInputAdornment } from '../../../BaseComponents/EIcon';
import RupeeIcon from '@iconify/icons-material-symbols/currency-rupee-rounded'
import { ETextField } from '../../../BaseComponents/ETextField';
import { EButton, EButtonOutlined } from '../../../BaseComponents/EButtons';
import { EAutocomplete } from '../../../BaseComponents/EAutocomplete';
import { ECheckbox } from '../../../BaseComponents/ECheckbox';
import { CHARACTER_LIMIT } from '../../../constants';
import EChip from '../../../BaseComponents/EChip';

function PhaseForm(props) {
  const dispatch = useDispatch();
  const {
    close,
    isEdit,
    deliverables,
    auth,
    group_payment_no,
    row,
    data
  } = props;
  let DeliverablesFilteredList = [];
  let SelectedDefaultList = [];
  let totalAmount = 0;
  let totalPercentage = 0;
 
  data?.WorkFace && data?.WorkFace?.map(a => {
    if (row.id != a.id) {
      totalAmount += Number(a?.face_price);
      totalPercentage += Number(a?.face_amount_percentage);

      a?.faceWorkDetail?.map(a => {
        DeliverablesFilteredList.push(a)
        totalAmount += Number(a.face_price) ? Number(a.face_price) : 0;
      });
    }
    else {
      a?.faceWorkDetail?.map(a => {
        SelectedDefaultList.push(a);
        totalAmount += Number(a.face_price) ? Number(a.face_price) : 0;

      });
    }
  });
  let defaultSelectedIds = SelectedDefaultList && SelectedDefaultList?.map(a => a.id);
  let defaultExcludedIds = DeliverablesFilteredList && DeliverablesFilteredList?.map(a => a.id);

  var DeliverableList =
    deliverables &&
    deliverables.reduce(function (filtered, option) {
      if (!filtered['selectedOpton']) {
        filtered['selectedOpton'] = [];
      }
      if (!filtered['options']) {
        filtered['options'] = [];
      }
      var someNewValue = { label: option.work_title, id: option.id };
      if (!option.group_payment_no) {
        filtered.options.push(someNewValue);
      }
      if (defaultSelectedIds && defaultSelectedIds.includes(option.id)) {
        filtered.selectedOpton.push(someNewValue);
      }
      if (SelectedDefaultList && defaultExcludedIds.includes(option.id)) {
        filtered.options.pop(defaultExcludedIds);
      }
      return filtered;
    }, {});

  totalPercentage = Number(100) - totalPercentage;
  totalAmount = data && data?.total_amount && Number(data?.total_amount) - totalAmount;
  const formik = useFormik({
    initialValues: {
      deliverable_value: data && DeliverableList?.selectedOpton ? DeliverableList.selectedOpton : [],
      amount: row?.face_price && row?.face_price > 0 ? row?.face_price : '',
      percentage: row?.face_amount_percentage && row?.face_amount_percentage > 0 ? row?.face_amount_percentage : '',
      eligiblePerc: row?.face_no == 0 && 50 < totalPercentage ? 50 : totalPercentage ? (parseFloat(totalPercentage).toFixed(2)) : '',
      eligiblePayment: row?.face_no == 0 && (Number(data?.total_amount) / 2) < Number(totalAmount) ? (Number(data?.total_amount) / 2) : totalAmount ? (parseFloat(totalAmount).toFixed(2)) : '',
      payment_sequence_id: isEdit ? parseInt(group_payment_no) : null,
      payout_in_fixed: data?.payout_in_fixed,
      remark: row?.remark ? row?.remark : '',
      is_advance: row?.face_no == 0 ? true : false
    },
    validationSchema: AddPaymentTermValidation,
    onSubmit: (data) => {
      data = { ...data, work_detail_id: data?.deliverable_value, contract_id: row.contract_id, face_id: row?.id, face_no: row?.face_no };
      dispatch(PaymentCreateUpdateDelete(auth.authtoken, data, 'create'));
      close();
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;
  // for calculating amount and percentage 
  const PercentageFunction = (per) => {
    if (per != null) {
      setFieldValue("percentage", per)
      let amountAmt = (Number(per) / 100) * Number(data?.total_amount);
      setFieldValue("amount", amountAmt ? Number(amountAmt).toFixed(2) : '')
    }
  }
  const AmountFunction = (amt) => {
    if (amt != null) {
      setFieldValue("amount", amt)
      let percentage = (Number(amt) / Number(data?.total_amount)) * 100;
      setFieldValue("percentage", percentage ? Number(percentage).toFixed(2) : '')
    }
  }
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} xl={12}
            //  sx={{display:data && data?.payout_in_fixed==true?'none':'block'}}
            >
              <ELabelInputMainGrid label={'Amount Percentage'} isfullgrid={true}>
                <ETextField
                  name="percentage"
                  disabled={data?.is_locked == true ? true : false}
                  {...getFieldProps('percentage')}
                  fullWidth
                  placeholder="0.00"
                  InputProps={{ endAdornment: <EIconInputAdornment icon={PercentIcon} className='grey-color' /> }}
                  onChange={(e, value) => { PercentageFunction(e.target.value) }}
                  error={Boolean(touched.percentage && errors.percentage)}
                  helperText={touched.percentage && errors.percentage}
                />

              </ELabelInputMainGrid>
            </Grid>
            <Grid item xs={12} sm={12} xl={12}>
              <ELabelInputMainGrid label={''} disableColon={true} childrenClassname='text-align'>
                OR
              </ELabelInputMainGrid>
            </Grid>
            <Grid item xs={12} sm={12} xl={12}  >
              <ELabelInputMainGrid label={'Amount'} isfullgrid={true}>
                <ETextField
                  name="amount"
                  disabled={data?.is_locked == true ? true : false}
                  {...getFieldProps('amount')}
                  fullWidth
                  placeholder="0.00"
                  onChange={(e, value) => { AmountFunction(e.target.value) }}
                  InputProps={{ startAdornment: <EIconInputAdornment icon={RupeeIcon} className='grey-color' /> }}
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                />
              </ELabelInputMainGrid>
            </Grid>
            <Grid item xs={12} xl={12} sx={{ display: row?.face_no == 0 ? 'none' : 'block' }}>
              <ELabelInputMainGrid label={'Deliverables'} isfullgrid={true}>
                <EAutocomplete
                  multiple
                  fullWidth
                  // limitTags={2}
                  disabled={data?.is_locked == true ? true : false}
                  id="multiple-limit-tags"
                  {...getFieldProps('deliverable_value')}
                  options={DeliverableList?.options ? DeliverableList?.options : []}
                  getOptionLabel={(deliverables) => deliverables?.label}
                  defaultValue={DeliverableList?.selectedOpton}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  // placeholder="Select"
                  // ChipProps={{
                  //   sx:{color:'black', background:'transparent'}
                  // }}
                  onChange={(e, value) => {
                    setFieldValue('deliverable_value', value);
                  }}
                  // renderTags={(tagValue, getTagProps) => {
                  //   console.log( tagValue)
                  //   return tagValue.map((option, index) => (
                  //     <span key={index}>{option.title}</span>
                  //   ));
                  // }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <ECheckbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <ETextField
                      {...params}
                      placeholder="Select"
                      error={Boolean(touched.deliverable_value && errors.deliverable_value)}
                      helperText={touched.deliverable_value && errors.deliverable_value}
                    />
                  )}
                />
              </ELabelInputMainGrid>
            </Grid>
            <Grid item xs={12} xl={12}>
              <ELabelInputMainGrid label={'Remark'} isfullgrid={true}>
                <ETextField
                  disabled={data?.is_locked == true ? true : false}
                  name="remark"
                  multiline
                  rows={3}
                  {...getFieldProps('remark')}
                  fullWidth
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
                <span className='greyColor4-color font-size-14px'>{`${formik?.values?.remark.length}/${CHARACTER_LIMIT }`}</span>
              </ELabelInputMainGrid>
            </Grid>

            <Grid item xs={12} xl={12} className="modal1-buttons-stick-bottom">
              <Stack direction="row" spacing={2}>
                <EButtonOutlined onClick={() => close()} variant="outlined" size="large">
                  {data?.is_locked != true ? 'Cancel' : 'Close'}
                </EButtonOutlined>
                {data?.is_locked != true &&
                  <EButton type="submit" variant="contained" size="large">
                  Update
                  </EButton>}
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}

export default PhaseForm;
