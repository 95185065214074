import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import moment from 'moment';
import monitor from '@iconify/icons-material-symbols/desktop-windows-outline-rounded'
import mobile from '@iconify/icons-material-symbols/phone-iphone-outline'
import { MANUAL_ATTENDANCE, SUPER_ADMIN } from '../../constants'
import { LeaveList } from '../../action/LeaveAction';
import { CheckinCheckoutAttendance, GetTodayAttendance } from '../../action/AttendanceAction';
import { EIcon } from '../../BaseComponents/EIcon';
import EModal from '../../BaseComponents/EModal';
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { ETypography } from '../../BaseComponents/ETypography';
import CheckIn from './CheckIn';
import ApplyLeaveForm from './ApplyLeaveForm';
import CheckOut from './CheckOut';
import CheckIcon from '@iconify/icons-material-symbols/check'
import EHidden from '../../BaseComponents/EHidden';

import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import ApplyLeaveIcon from '@iconify/icons-material-symbols/post-add-rounded';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';

function Attendance(props) {
    const navigate = useNavigate()
    const { status, LeaveSearch, leaveTabReset=null } = props
    const dispatch = useDispatch()
    const { setting } = useCompanySettingProvider()
    const additionalSettings = setting?.additionSettingData?.companySetting ?? {};
    const attendanceSettings = setting?.attendanceSettingData.companySetting ?? {};
    const auth = useSelector(state => state.authReducer);
    const attendanceDay = useSelector(state => state.AttendanceDayEmployeeReducer);
    const leaveComponent = useSelector(state => state.LeaveComponentReducer);

    // const companyData = useSelector(state => state.CompanyReducer?.companyData);

    const [ApplyLeave, setApplyLeave] = useState(false);
    const [WorkDoneModal, setWorkDoneModal] = useState(false);
    const [checkIn, SetcheckIn] = useState(false);

    useEffect(() => {
        if (auth?.authData?.id) {
            dispatch(LeaveList(auth.authtoken));
            dispatch(GetTodayAttendance(auth.authtoken));
        }
    }, [auth?.authtoken])


    // HandleCheckoutModal: handles checkout modal as per settings 
    const handleCheckoutModal = () => {
        if (additionalSettings?.dprSetting?.dpr_submission_required) {
            setWorkDoneModal(true)
        } else {
            dispatch(CheckinCheckoutAttendance(auth.authtoken, {
                remark: "",
                updated_by: auth?.authData?.id,
                employee_id: auth?.authData?.id,
                attendance_date: moment().format("YYYY-MM-DD"),
            }))
        }
    }

    return (<>

        <Grid item>
            {ApplyLeave &&
                <EModal open={ApplyLeave} close={() => setApplyLeave(false)} headervalue="Apply Leave">
                    <ApplyLeaveForm LeaveSearch={LeaveSearch} open={ApplyLeave} leaveComponent={leaveComponent?.leaveListData?.leaveData}
                        close={() => {setApplyLeave(false); if(leaveTabReset){leaveTabReset();}} } fromPage="Leave" auth={auth} />
                </EModal>
            }
            {WorkDoneModal &&
                <EModal open={WorkDoneModal} close={() => setWorkDoneModal(false)} headervalue={"Check Out - " + moment().format('DD/MM/yyyy, hh:mm A')}>
                    <CheckOut
                        close={() => setWorkDoneModal(false)}
                        auth={auth}
                        attendanceSettings={attendanceSettings}
                    />
                    {/* commenting setting={setting} as prop as it was not getting used inside  CheckOut component*/}
                </EModal>
            }

            {checkIn &&
                <EModal open={checkIn} close={() => SetcheckIn(false)} headervalue={"Check In - " + moment().format('DD/MM/yyyy, hh:mm A')}>
                    <CheckIn
                        close={() => SetcheckIn(false)}
                        auth={auth}
                        attendanceSettings={attendanceSettings}
                    />
                </EModal>
            }

            <Grid container spacing={1} >
                {status == 'attendance' && (auth?.authData?.role !== SUPER_ADMIN || (auth?.authData?.role === SUPER_ADMIN && auth?.authData?.show_as_talent == true)) ?
                    <Grid item className='pl0'>
                        <EButton
                            variant="contained"
                            onClick={() => SetcheckIn(true)}
                            disabled={attendanceSettings.allow_attendance_automate != true ? true :
                                (!auth?.authData?.show_as_talent ? true : (attendanceDay?.attendanceData && Object.keys(attendanceDay?.attendanceData).length > 0 ? true : false))
                            }

                        /* ----------code comment by ajit as per discussion with dharam sir-----------*/
                        /**
                          disabled={companyData.attendance_mode == MANUAL_ATTENDANCE ? true : 
                              (!auth?.authData?.show_as_talent ?  true : (attendanceDay?.attendanceData && Object.keys(attendanceDay?.attendanceData).length > 0 ? true : false))
                            }
                        */

                        //OLD
                        // disabled={(attendanceDay?.attendanceData  && Object.keys(attendanceDay?.attendanceData).length >= 0) ? true :
                        //     (attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_in_time) ? true : 
                        //     (companyData.attendance_mode == MANUAL_ATTENDANCE) ? true : false}

                        > Check In</EButton>
                    </Grid>
                    : <></>
                }
                {status == 'attendance' && (auth?.authData?.role !== SUPER_ADMIN || (auth?.authData?.role === SUPER_ADMIN && auth?.authData?.show_as_talent == true)) ?

                    <Grid item className='pl0'>
                        <EButton variant="contained" className='button-left-margin'
                            disabled={attendanceSettings.allow_attendance_automate != true ? true : (!attendanceDay?.attendanceData ? true :
                                (Object.keys(attendanceDay?.attendanceData).length > 0 && attendanceDay?.attendanceData?.check_in_time === null ? true :
                                    (Object.keys(attendanceDay?.attendanceData).length > 0 && attendanceDay?.attendanceData?.check_out_time !== null ? true : false)))}

                            /* ----------code comment by ajit as per discussion with dharam sir-----------*/
                            /**
                            disabled={ companyData.attendance_mode == MANUAL_ATTENDANCE ? true : (!attendanceDay?.attendanceData ? true : 
                            (Object.keys(attendanceDay?.attendanceData).length > 0 && attendanceDay?.attendanceData?.check_in_time === null ? true : 
                            (Object.keys(attendanceDay?.attendanceData).length > 0 && attendanceDay?.attendanceData?.check_out_time !== null ? true : false)))}
                            */

                            // disabled={
                            //     (attendanceDay?.attendanceData && Object.keys(attendanceDay?.attendanceData).length <= 0) ? true :
                            //         (attendanceDay?.attendanceData == null && attendanceDay?.attendanceData?.check_in_time == null) ? true :
                            //             (attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_out_time) ? true :
                            //                 (companyData.attendance_mode == MANUAL_ATTENDANCE)? true : false}

                            onClick={() => handleCheckoutModal()} > Check Out </EButton>
                    </Grid>
                    : <></>
                }
                {status == 'leave' && (auth?.authData?.role !== SUPER_ADMIN || (auth?.authData?.role === SUPER_ADMIN && auth?.authData?.show_as_talent == true)) ?
                    <Grid item>
                        <EHidden width="mdDown">
                            <ETooltip title={' Apply Leave'} arrow><EButton variant="contained" onClick={() => setApplyLeave(true)} > Apply Leave </EButton></ETooltip>
                        </EHidden>
                        <EHidden width="mdUp">
                            <EButtonIcon variant="contained" onClick={() => setApplyLeave(true)}>
                                <EIcon icon={ApplyLeaveIcon} className='height-width-15px' />
                            </EButtonIcon>

                            {/* <EButton variant="contained" onClick={() => setApplyLeave(true)} > Apply Leave </EButton> */}
                        </EHidden>
                    </Grid>
                    : <></>
                }
                {status != 'dashboard' &&
                    <Grid item className='pl0'>
                        <EHidden width="mdDown">
                            <EButtonOutlined className='button-left-margin' variant="outlined" onClick={() => navigate(-1)} color="secondary" > Back </EButtonOutlined>
                        </EHidden>
                        <EHidden width="mdUp">
                            <EButtonOutlinedIcon variant="outlined" onClick={() => navigate('/')} className='button-left-margin'>
                                <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                        </EHidden>
                    </Grid>}


            </Grid>
        </Grid>
        {/* ______________________for dashboard __________________ */} {/*--auth?.authData?.show_as_talent login should be replaced in the next version-- */}
        {status == 'dashboard' && (auth?.authData?.role !== SUPER_ADMIN || (auth?.authData?.role === SUPER_ADMIN && auth?.authData?.show_as_talent == true) || !auth?.authData?.show_as_talent) ? <>
            <Grid container spacing={1} className='justify-content-space-between '>
                <Grid item xs={6} sm={4} md={5} lg={5} xl={5} className=' justify-content-space-between '>
                    <EButton variant="contained" className='mr10px px0 checkin-out-button-dashboard' onClick={() => SetcheckIn(true)}
                        disabled={attendanceSettings.allow_attendance_automate != true ? true :
                            (!auth?.authData?.show_as_talent ? true :
                                (attendanceDay?.attendanceData && Object.keys(attendanceDay?.attendanceData).length > 0 ? true : false))
                        }
                        /* ----------code comment by ajit as per discussion with dharam sir-----------*/
                        /**
                            ----------Old code comment by ajit as per discussion with dharam sir-----------
                            disabled={companyData.attendance_mode == MANUAL_ATTENDANCE ? true :
                            (!auth?.authData?.show_as_talent ? true : 
                                (attendanceDay?.attendanceData && Object.keys(attendanceDay?.attendanceData).length > 0 ? true : false)) 
                        }*/
                        // disabled={
                        //     (attendanceDay?.attendanceData && Object.keys(attendanceDay?.attendanceData).length <= 0) ? true :
                        //         (attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_in_time) ? true :
                        //             (!auth?.authData?.show_as_talent) ? true :
                        //                 (companyData.attendance_mode == MANUAL_ATTENDANCE) ? true : false}
                        fullWidth> Check In </EButton>
                </Grid>


                <Grid item xs={6} sm={8} md={7} lg={7} xl={7} className='display-flex justify-content-space-between align-center' >
                    {attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_in_time ?
                        <>
                            {!attendanceDay?.checkin_is_mobile && attendanceDay?.attendanceData?.check_in_time ?
                                <ETooltip title={"Checked In from Desktop"} arrow><EIcon icon={monitor} className='responsive-display ' /></ETooltip>
                                : attendanceDay?.attendanceData?.checkin_is_mobile && attendanceDay?.attendanceData?.check_in_time ?
                                    <ETooltip title={"Checked In from Mobile"} arrow><EIcon icon={mobile} className='responsive-display ' /></ETooltip>
                                    : ''}

                            <ETypography sx={{ ETypography: { sm: 'body1', xs: 'body2', md: 'body2', lg: 'h6' } }} className='mr10px bold-font' >
                                {attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_in_time ?
                                    <span>
                                        <span className='bold-600'> {moment(attendanceDay?.attendanceData?.check_in_time).format('hh:mm A ')}
                                        </span>
                                        <span className='small-text-label font-weight-400'>Check in time</span>
                                    </span>
                                    : ''}
                            </ETypography>
                            <EIcon icon={CheckIcon} className='check-css responsive-display' />
                        </>
                        :
                        <>
                            <ETypography className='responsive-p-before' >
                                <span className='bold-600 disabledBottonText'> Pending</span>
                                <span className='small-text-label disabledBottonText'>Check in time</span>
                            </ETypography>
                        </>
                    }
                </Grid>
                <Grid item xs={6} sm={4} md={5} lg={5} xl={5} className=' justify-content-space-between'>
                    <EButton variant="contained" className='mr10px px0 checkin-out-button-dashboard'
                        disabled={attendanceSettings.allow_attendance_automate != true ? true : (!attendanceDay?.attendanceData ? true :
                            (Object.keys(attendanceDay?.attendanceData).length > 0 && attendanceDay?.attendanceData?.check_in_time === null ? true :
                                (Object.keys(attendanceDay?.attendanceData).length > 0 && attendanceDay?.attendanceData?.check_out_time !== null ? true : false)))}
                        /* ----------Old code comment by ajit as per discussion with dharam sir-----------*/
                        /**
                        disabled={ companyData.attendance_mode == MANUAL_ATTENDANCE ? true : (!attendanceDay?.attendanceData ? true : 
                            (Object.keys(attendanceDay?.attendanceData).length > 0 && attendanceDay?.attendanceData?.check_in_time === null ? true : 
                            (Object.keys(attendanceDay?.attendanceData).length > 0 && attendanceDay?.attendanceData?.check_out_time !== null ? true : false)))}
                        */

                        // disabled={(attendanceDay?.attendanceData && Object.keys(attendanceDay?.attendanceData).length <= 0) ? true :
                        //     (attendanceDay?.attendanceData == null && attendanceDay?.attendanceData?.check_in_time == null) ? true :
                        //         (attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_out_time) ? true :
                        //             !auth?.authData?.show_as_talent ? true :
                        //                 (companyData.attendance_mode == MANUAL_ATTENDANCE) ? true : false}
                        onClick={() => handleCheckoutModal()} fullWidth> Check Out </EButton>

                </Grid>
                <Grid item xs={6} sm={8} md={7} lg={7} xl={7} className='display-flex justify-content-space-between align-center' >
                    {attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_out_time ?
                        <>
                            {!attendanceDay?.attendanceData?.checkout_is_mobile && attendanceDay?.attendanceData?.check_out_time ?
                                <ETooltip title={"Checked Out from Desktop"} arrow><EIcon icon={monitor} className='responsive-display ' /></ETooltip> :
                                attendanceDay?.attendanceData?.checkout_is_mobile && attendanceDay?.attendanceData?.check_out_time ?
                                    <ETooltip title={"Checked Out from Mobile"} arrow><EIcon icon={mobile} className='responsive-display ' /></ETooltip> :
                                    ''}

                            <ETypography sx={{ ETypography: { sm: 'body1', xs: 'body2', md: 'body2', lg: 'h6' } }} className='mr10px bold-font'>
                                {attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_out_time ?
                                    <span>
                                        <span className='bold-600'> {moment(attendanceDay?.attendanceData?.check_out_time).format('hh:mm A')}
                                        </span>
                                        <span className='small-text-label font-weight-400'>Check out time</span>
                                    </span>
                                    :
                                    ''}
                            </ETypography>

                            <EIcon icon={CheckIcon} className='check-css responsive-display ' />
                        </>
                        :
                        <ETypography className='responsive-p-before' >
                            <span className='bold-600 disabledBottonText'> Pending</span>
                            <span className='small-text-label disabledBottonText'>Check out time</span>
                        </ETypography>
                    }
                </Grid>
            </Grid>
        </>
            : <></>}
    </>
    );
}

export default React.memo(Attendance)