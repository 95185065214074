
import { Grid, IconButton } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EmployeeListData } from "../../action/EmployeeAction";
import { EmployeeRequestPaymentList } from "../../action/PaymentAction";
import { EBoxPage } from "../../BaseComponents/EBox";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButton, EButtonOutlined } from "../../BaseComponents/EButtons";
import EChip from "../../BaseComponents/EChip";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import { EDivider } from "../../BaseComponents/EDivider";
import EModal from "../../BaseComponents/EModal";
import Page from "../../BaseComponents/EPage";
import EScrollbar from "../../BaseComponents/EScrollbar";
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline';
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline';
import AttachFile from '@iconify/icons-material-symbols/attach-file';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { ETypography, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import {
  ACCOUNTANT, ACTIVE_USER, APPROVED_STATUS, CANCELLED_STATUS, COMPLETE_STATUS, DEFAULT_ROWS_PERPAGE, PAYMENT_CATEGORY_ADVANCE,
  PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_REIMBURSEMENT, PENDING_STATUS, PERMISSIONS, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, ROW_SIZE_PER_PAGE, SUPER_ADMIN
} from "../../constants";
import PaymentRequestModal from "../../PageComponents/Payment/PaymentRequestModal";
import PaymentRequestSearchBar from "../../PageComponents/Payment/PaymentRequestSearchBar";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import { numberWithCommas } from "../../utils/formatNumber";
import { EIcon } from "../../BaseComponents/EIcon";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import PaymentRequestView from "../../PageComponents/Payment/PaymentRequestView";
import PaymentRequestRespond from "../../PageComponents/Payment/PaymentRequestRespond";
import PaymentRequestDelete from "../../PageComponents/Payment/PaymentRequestDelete";
import { ELinkMUI } from "../../BaseComponents/ELink";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
import { useCheckPermission } from "../../hooks";

function PaymentRequest(props) {
  const { auth } = props;
  const { setting } = useCompanySettingProvider()
  const paymentSettingData = setting?.paymentSettingData?.companySetting;
  const dispatch = useDispatch();

  const payment = useSelector((state) => state.PaymentReducer);
  const employee = useSelector((state) => state.EmployeeReducer);
  const company = useSelector(state => state.CompanyReducer);

  const enable_advance_salary = paymentSettingData?.enable_advance_salary;
  const enable_reimbursement = paymentSettingData?.enable_reimbursement;

  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
  const [page, setPage] = useState(0);
  const [modalState, setModalState] = useState({ modalData: null, modalName: null });
  // const [isRequestDetail, SetIsRequestDetail] = useState(false); //INFO: commenting this since it's of no use as of now
  const [SearchParameters, setSearchParameters] = useState({
    sort: true,
    yearMonth: null,
    employee_id: null,
    particular: null,
    start_date: null,
    end_date: null,
  });

  const [isAdminRole, setIsAdminRole] = useState(false)

  const allowVerifyPayment = useCheckPermission([PERMISSIONS.VerifyPayment])

  const getFilterData = (searchData) => {
    callApi(searchData, 0, rowsPerPage);
    setPage(0);
  }

  useEffect(() => {
    if (auth.authtoken) {
      callApi({ ...SearchParameters }, page, rowsPerPage);
      dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER));
      getSettingByRole(auth?.authData?.role)
    }
  }, [auth?.authtoken])


  const handleChangePage = (event, newPage) => {
    callApi(SearchParameters, newPage, rowsPerPage);
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
    setRowsPerPage(rowPerPageCount);
    callApi(SearchParameters, 0, rowPerPageCount);
    setPage(0);
  };

  const callApi = (fnParams, fnPage, fnRowPerPage) => {
    dispatch(EmployeeRequestPaymentList(auth.authtoken, 'request_employee', { ...fnParams, sort: !fnParams.sort ? ' ASC ' : ' DESC', withoutRoleCheck: allowVerifyPayment }, fnPage, fnRowPerPage));
  }


  const TABLE_HEAD = useMemo(() => getTableHead({ role: auth?.authData?.role, enable_reimbursement }), [auth?.authData?.role, enable_reimbursement]);

  // const TABLE_HEAD = 

  const getSettingByRole = (role) => {
    let ADMIN_ROLES = [SUPER_ADMIN, ROLE_ADMIN, ROLE_HR]
    let TALENT_ROLES = [ROLE_EMPLOYEE, ACCOUNTANT]
    if (ADMIN_ROLES.includes(role)) {
      return setIsAdminRole(() => true)
    } else if (TALENT_ROLES.includes(role)) {
      return setIsAdminRole(() => false)
    }
    return isAdminRole
  }



  return (
    <Page title="Payment Request" >
      <Grid container display="row" justifyContent="space-between " className='align-center' >
        <Grid item>
          <EHeaderBreadcrumbs
            heading='Payment Request'
            links={[
              { name: 'Dashboard', href: '/' },
              { name: 'Payment', href: '' },
              {
                name: 'Payment Request',
              }
            ]}
          /></Grid>
        <Grid item >
          {
            (isAdminRole || enable_advance_salary || enable_reimbursement) &&
            <EButton variant="contained"
              onClick={() => setModalState({ ...modalState, modalName: 'REQUEST' })}
            >  Payment Request </EButton>
          }

          <EButtonOutlined className='button-left-margin' variant="outlined"
            onClick={() => navigate(-1)} >  Back </EButtonOutlined>
        </Grid>
      </Grid>

      <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end '>
        <Grid item>
          <ETypographyPageHeading variant='h5'>Payment Request</ETypographyPageHeading>
        </Grid>
        <Grid lg={12} xs={12} item className='pt-8px'><EDivider /></Grid>
      </Grid>
      <EBoxPage>

        <PaymentRequestSearchBar
          setSearchParam={setSearchParameters}
          searchParam={SearchParameters}
          getFilterData={getFilterData}
          employeeList={employee}
          companyData={company}
          auth={auth}
          setting={setting}
        />

        <EScrollbar>
          <ETableContainer >
            <ETable>
              <EListHead
                headLabel={TABLE_HEAD}
                rowCount={payment?.PaySalaryListData?.rows?.length}
                onRequestSort={() => null}

              />
              {payment?.PaySalaryListData?.payrollListLoading &&
                <ETableBody>
                  <ETableRow>
                    <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                      <ECircularProgress color="primary" />
                    </ETableCell>
                  </ETableRow>
                </ETableBody>
              }
              {!payment?.PaySalaryListData?.payrollListLoading && payment?.PaySalaryListData?.rows?.length > 0 &&
                <ETableBody>

                  {payment?.PaySalaryListData?.rows?.map((row, index) => {
                    const {
                      transaction_category,
                      transaction_date,
                      transaction_status,
                      pay_amount,
                      reimbursement_attachment,
                      employee_id,
                      employeeData,
                      created_by
                    } = row;

                    return (

                      <ETableRow hover key={index} tabIndex={-1}>

                        {/* Date */}

                        <ETableCell align="left">
                          {moment(transaction_date).format('DD/MM/yyyy')}
                        </ETableCell>

                        {/* TALENT NAME */}
                        {(auth?.authData?.role == ROLE_HR || auth?.authData?.role == ROLE_ADMIN || auth?.authData?.role == SUPER_ADMIN) &&
                          <ETableCell style={{ minWidth: '250px' }} align="left">
                            <ETypography
                              variant="subtitle1"
                              noWrap
                            // className="cursor-pointer  text-capitalize blue-color"
                            >
                              {employeeData?.fullname ? employeeData?.fullname : '-'}
                              <br />
                              ({employeeData?.employee_code ? employeeData?.employee_code : '-'})
                            </ETypography>
                          </ETableCell>}

                        {/* PARTICULAR */}
                        <ETableCell align="left">
                          {transaction_category &&
                            transaction_category == PAYMENT_CATEGORY_ADVANCE
                            ? 'Advance'
                            : transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
                              ? 'Reimbursement'
                              : transaction_category == PAYMENT_CATEGORY_OTHER
                                ? 'Other'
                                : '-'}
                        </ETableCell>

                        {/* Request Amount */}
                        <ETableCell align="right">
                          {pay_amount ? numberWithCommas(pay_amount) : '-'}
                        </ETableCell>

                        {/* Attachment */}
                        {enable_reimbursement &&
                          <ETableCell align="center">
                            {reimbursement_attachment ? (
                              <ELinkMUI
                                variant="link"
                                href={reimbursement_attachment}
                                target={'_blank'}
                              >
                                <EIcon icon={AttachFile} style={{ height: '25px', width: '25px' }} />
                              </ELinkMUI>
                            ) : (
                              ''
                            )}
                          </ETableCell>}
                        {/* <ETableCell align="center" >
                          {remark && <ETooltip title={remark}>
                            <EIcon icon={InfoRoundedIcon} />
                          </ETooltip>}
                        </ETableCell> */}

                        {/* Status */}
                        <ETableCell align="left" >
                          <Grid className="display-flex align-items-center justify-content-center">
                            {transaction_status ? (
                              <EChip
                                label={
                                  transaction_status == PENDING_STATUS
                                    ? 'Pending'
                                    : transaction_status == APPROVED_STATUS
                                      ? 'Approved'
                                      : transaction_status == CANCELLED_STATUS
                                        ? 'Reject'
                                        : 'Paid'
                                }
                                width="100px"
                                className={
                                  transaction_status == PENDING_STATUS
                                    ? 'active-grey-chip'
                                    : transaction_status == APPROVED_STATUS
                                      ? 'active-blue-chip'
                                      : transaction_status == CANCELLED_STATUS
                                        ? 'active-red-chip'
                                        : 'active-green-chip'
                                }
                              />
                            ) : (
                              '-'
                            )}
                            {/* {transaction_status == APPROVED_STATUS && transaction_status_remark &&
                              <ETooltip title={transaction_status_remark}>
                                <EIcon icon={InfoRoundedIcon}/>
                              </ETooltip>} */}
                          </Grid>
                        </ETableCell>


                        {/*  Action  */}
                        <ETableCell>
                          {(auth?.authData?.id == employee_id && (auth?.authData?.role == ACCOUNTANT || auth?.authData?.role == ROLE_EMPLOYEE || auth?.authData?.role == ROLE_HR || auth?.authData?.role == ROLE_ADMIN)) &&
                            <ETooltip title={'View'} arrow>
                              <IconButton onClick={() => setModalState({ modalData: row, modalName: 'VIEW' })}>
                                <EIcon icon={VisibilityIcon} />
                              </IconButton>
                            </ETooltip>

                          }

                          {(auth?.authData?.role == SUPER_ADMIN || auth?.authData?.role == ROLE_ADMIN || auth?.authData?.role == ROLE_HR) &&
                            auth?.authData?.id != employee_id &&
                            <ETooltip title={'View'} arrow>
                              <IconButton onClick={() => setModalState({ modalData: row, modalName: 'RESPOND' })}>
                                <EIcon icon={VisibilityIcon} />
                              </IconButton>
                            </ETooltip>
                          }

                          {(transaction_status == PENDING_STATUS &&
                            (auth?.authData?.id == employee_id) || ((auth?.authData?.role == ROLE_HR || auth?.authData?.role == SUPER_ADMIN || auth?.authData?.role == ROLE_ADMIN) && auth?.authData?.id == created_by && transaction_status != COMPLETE_STATUS && transaction_status !== APPROVED_STATUS)) &&
                            <ETooltip title={'Delete'} arrow>
                              <IconButton onClick={() => setModalState({ modalData: row, modalName: 'DELETE' })}>
                                <EIcon icon={DeleteIcon} color={'red'} />
                              </IconButton>
                            </ETooltip>
                          }

                        </ETableCell>
                      </ETableRow>
                    );
                  })}
                </ETableBody>
              }

            </ETable>
          </ETableContainer>
        </EScrollbar>

        {!payment?.PaySalaryListData?.payrollListLoading && payment?.PaySalaryListData?.rows?.length == 0 && (
          <SearchNotFound />
        )}
        {!payment?.PaySalaryListData?.payrollListLoading && payment?.PaySalaryListData?.count > DEFAULT_ROWS_PERPAGE &&
          <ETablePagination
            rowsPerPageOptions={ROW_SIZE_PER_PAGE}
            component="div"
            count={payment?.PaySalaryListData?.count ? payment?.PaySalaryListData?.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </EBoxPage>



      {modalState.modalName &&
        <EModal open={modalState.modalName === 'REQUEST'}
          headervalue={`Payment Request`}
        >
          <PaymentRequestModal
            setModalState={setModalState}
            auth={auth}
            getPaymentFilterData={() => getFilterData(SearchParameters)}
            employeeList={employee}
            companyData={company}
            setting={setting}
          />
        </EModal>}


      {(modalState.modalName && modalState.modalData) &&
        <EModal open={modalState.modalName === 'VIEW'}
          headervalue={modalState.modalData?.transaction_category &&
            modalState.modalData?.transaction_category == PAYMENT_CATEGORY_ADVANCE
            ? 'Advance Request'
            : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
              ? 'Reimbursement Request'
              : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_OTHER
                ? 'Other Request'
                : '-'}
        >
          <PaymentRequestView
            selectedData={modalState}
            setModalState={setModalState}
          />

        </EModal>}

      {(modalState.modalName && modalState.modalData) &&
        <EModal open={modalState.modalName === 'DELETE'}
          headervalue={modalState.modalData?.transaction_category &&
            modalState.modalData?.transaction_category == PAYMENT_CATEGORY_ADVANCE
            ? 'Advance Request'
            : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
              ? 'Reimbursement Request'
              : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_OTHER
                ? 'Other Request'
                : '-'}
        >
          <PaymentRequestDelete
            selectedData={modalState}
            setModalState={setModalState}
            getPaymentFilterData={() => getFilterData(SearchParameters)}
            auth={auth}
          />

        </EModal>}


      {(modalState.modalName && modalState.modalData) &&
        <EModal open={modalState.modalName === 'RESPOND'}
          headervalue={modalState.modalData?.transaction_category &&
            modalState.modalData?.transaction_category == PAYMENT_CATEGORY_ADVANCE
            ? 'Advance Request'
            : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
              ? 'Reimbursement Request'
              : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_OTHER
                ? 'Other Request'
                : '-'}
        >
          <PaymentRequestRespond
            selectedData={modalState}
            setModalState={setModalState}
            getPaymentFilterData={() => getFilterData(SearchParameters)}
            auth={auth}
          />

        </EModal>}

    </Page>

  );
}

export default PaymentRequest;


const getTableHead = (props) => {
  const { enable_reimbursement, role } = props;
  let TABLE_HEAD = [
    { id: 'Date', label: 'Date', alignRight: false },
  ];
  if (role == ROLE_HR || role == ROLE_ADMIN || role == SUPER_ADMIN) {
    TABLE_HEAD.push({ id: ' Talent Name', label: ' Talent Name', alignRight: false });
  }
  TABLE_HEAD.push(
    { id: 'Particular', label: 'Particular', alignRight: false },
    { id: 'Request Amount', label: 'Request Amount', alignRight: true },
  );
  if (enable_reimbursement) {
    TABLE_HEAD.push({ id: 'Attachment', label: 'Attachment', alignRight: 'center' },)
  }
  TABLE_HEAD.push(
    { id: 'Status', label: 'Status', alignRight: 'center' },
    { id: 'Action', label: 'Action', alignRight: false }
  )
  return TABLE_HEAD;
}
