import { styled, Table, TableBody, TableCell, TableContainer} from "@mui/material";
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { Box, TableRow, TableHead, TableSortLabel } from '@mui/material';
import React from 'react';
import { ETooltip } from "./ETooltip";
import EHidden from "./EHidden";

export const ETable = styled(Table)(({ theme }) => ({

}));

export const ETableContainer = styled(TableContainer)(({ theme }) => ({

}));

export const ETableBody = styled(TableBody)(({ theme }) => ({

}));

export const ETableRow = styled(TableRow)(({ theme }) => ({

    '.MuiTableCell-root': {
        height: '60px',
        borderTop: '1px solid',
        borderBottom: '0',
        borderColor: theme.palette.border.border8,
    },
    '.MuiTableCell-head': {
        background: theme.palette.background.tableHeader + '!important',
        borderTop: '0',
        borderBottom:'1px solid',
        borderColor: theme.palette.primary.main,
        'span:hover':{
            color: theme.palette.text.primary
        }
    }
}));

export const ETypographyTable = styled('div')(({ theme }) => ({
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.5',
    alignItems: 'center',
    display: 'flex'
}));

export const ETableCell = styled(TableCell)(({ theme }) => ({
    lineHeight: '19.2px !important',
    padding: '8px', //previous value= '8px 16px'
}));

export const ETableHead = styled(TableHead)(({ theme }) => ({

    '.MuiTableCell-root': {
        textTransform: 'capitalize',
        fontSize: '1rem',
        fontWeight: 700,
        color: theme.palette.text.primary + '!important',
        // borderRadius: '3px 3px 0px 0px',

        // [theme.breakpoints.down('md')]: {
        //     fontSize: '80% !important',
        //   },
    }
}));


// --------------------------- NORMAL TABLE -------------------------------------------

EListHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    headLabel: PropTypes.array,
};

export function EListHead({
    order,
    orderBy,
    headLabel,
}) {
    // INFO : headCell.hideCriteria is the variable that is the input to EHidden i.e will help to hide/show a column according to screen sizes.
    // hideLabelCriteria
    return (
        <ETableHead>
            <ETableRow>
                {headLabel.map((headCell) => {

                    return (
                    <EHidden  key={headCell.id} width={headCell.hideLabelCriteria ? headCell.hideLabelCriteria : 'xsDown'}>
                        <ETableCell
                            sx={{ textTransform: headCell.notCapitalize ? 'inherit !important' : headCell.tooltip ? 'inherit !important' : 'capitalize' }}
                            key={headCell.id}
                            align={headCell.alignRight == true ? 'right' : headCell.alignRight == false ? 'left' : headCell.alignRight}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                             <EHidden width={headCell.hideLabelCriteria ? headCell.hideLabelCriteria : 'xsDown'}>
                             <ETooltip title={headCell.tooltip ? headCell.tooltip : ''} arrow >
                               <>
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box sx={{ ...visuallyHidden }}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                    </>
                            </ETooltip>
                            </EHidden>
                        </ETableCell>
                    </EHidden>
                    )})}
            </ETableRow>
        </ETableHead>
    );
}

// ---------------------- SMALL TABLE ------------------------------------------------

export const ETableRowSmall = styled(TableRow)(({ theme }) => ({
    '.MuiTableCell-root': {
        borderTop: '1px solid',
        borderBottom: '0',
        borderColor: theme.palette.border.border8,
        padding: '8px 16px',
        height: '50px',

    },

    '.MuiTableCell-head': {
        height: '50px',
        margin: 0,
        background: theme.palette.background.tableHeader + '!important',
    }
}));


export const ETableHeadSmall = styled(TableHead)(({ theme }) => ({
    '.MuiTableHead-root': {
        minHeight: '50px',
        color: theme.palette.text.primary + '!important',
        borderTop: '0',
        borderRadius: '3px 3px 0px 0px'
    },
    '.MuiTableCell-root': {
        textTransform: 'capitalize',
        fontSize: '1rem',
        fontWeight: 700,
        borderTop: '0',
        borderBottom:'1px solid',
        borderColor: theme.palette.primary.main  + '!important',
        color: theme.palette.text.primary + '!important',
        // borderRadius: '3px 3px 0px 0px'
    }
}));


EListHeadSmall.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    rowCount: PropTypes.number,
    headLabel: PropTypes.array,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func
};

export default function EListHeadSmall({
    order,
    orderBy,
    rowCount,
    headLabel,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    Theme
}) {
    // const createSortHandler = (property) => (event) => {
    //     onRequestSort(event, property);
    // };
    return (
        <ETableHeadSmall >
            <ETableRowSmall >
                {headLabel.map((headCell) => (
                    <ETableCell
                        key={headCell.id}
                        align={headCell.alignRight == true ? 'right' : headCell.alignRight == false ? 'left' : headCell.alignRight}
                        sx={{ textTransform: headCell.notCapitalize ? 'inherit !important' : 'capitalize' }}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {/* <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        > */}
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box sx={{ ...visuallyHidden }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        {/* </TableSortLabel> */}
                    </ETableCell>
                ))}
            </ETableRowSmall>
        </ETableHeadSmall>
    );
}

// ----------------------Xs SMALL TABLE below------------------------------------------------
export const ETableRowXS = styled(TableRow)(({ theme }) => ({

    '.MuiTableCell-root': {
        border: '1px solid' + '!important',
        borderColor: theme.palette.border.border1 + '!important',
        padding: '10px 10px',
        // borderRadius: '4px 4px 0px 0px'
    },
    '.MuiTableCell-head': {
        padding: '10px 20px',
        margin: 0,
        background: theme.palette.background.tableHeader + '!important',
        border: '1px solid',
        borderColor: theme.palette.border.border1,
    },
}));
export const ETableHeadXS = styled(TableHead)(({ theme }) => ({

    '.MuiTableCell-root': {
        textTransform: 'capitalize',
        fontSize: '1rem',
        fontWeight: 700,
        color: theme.palette.text.primary + '!important',
        // borderRadius: '3px 3px 0px 0px'
    }
}));
export function EListHeadExtraSmall({
    order,
    orderBy,
    rowCount,
    headLabel,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    Theme
}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <ETableHeadXS >
            <ETableRowXS >
                {headLabel.map((headCell) => (
                    <ETableCell
                        key={headCell.id}
                        align={headCell.alignRight == true ? 'right' : headCell.alignRight == false ? 'left' : headCell.alignRight}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box sx={{ ...visuallyHidden }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </ETableCell>
                ))}
            </ETableRowXS>
        </ETableHeadXS>
    );
}


export const ETableRowXSNewBorder = styled(TableRow)(({ theme }) => ({
    '.MuiTableCell-root': {
        // borderBottom: '1px solid' + '!important',
        borderColor: theme.palette.border.border1 + '!important',
        padding: '1px 1px',
    },
}));

export const ETableRowXSNoBorder = styled(TableRow)(({ theme }) => ({
    '.MuiTableCell-root': {
        borderBottom: '0',
        borderColor: theme.palette.border.border1 + '!important',
        padding: '1px 1px',
    },
}));



// ------No Header Difference color & Sorting-------
export const ETableRowSorting = styled(TableRow)(({ theme }) => ({
    '& .MuiTableCell-head': {
        background: theme.palette.background.card4,
        borderBottom: '1px solid',
        borderColor:theme.palette.primary.main+' !important',
    },
    '& .MuiTableCell-root': {
        borderBottom: '1px solid',
        borderColor: theme.palette.border.staticBorder7,
        padding: '10px 10px',
    },
}));
export function EListHeadSorting({
    order,
    orderBy,
    rowCount,
    headLabel,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    Theme
}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <ETableHeadSmall >
            <ETableRowSorting >
                {headLabel.map((headCell) => (
                    <EHidden  key={headCell.id} width={headCell.hideLabelCriteria ? headCell.hideLabelCriteria : 'xsDown'}>
                    <ETableCell
                        key={headCell.id}
                        align={headCell.alignRight == true ? 'right' : headCell.alignRight == false ? 'left' : headCell.alignRight}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box sx={{ ...visuallyHidden }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </ETableCell>
                    </EHidden>
                ))}
            </ETableRowSorting>
        </ETableHeadSmall>
    );
}