import { Grid, InputAdornment, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ETextField } from "../../BaseComponents/ETextField";
import { useDispatch } from "react-redux";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import { updateTalenEmergency } from "../../action/TalentActions";
import { EditFormEmergencyDetailsValidation } from "../../utils/validation";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";

const EditModalEmergencyDetail = (props) => {
    const { auth, employeeData, CloswModalEmergencyDetails, TalentSetting } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            emr_contact_detail_check: true,
            emr_person_name: employeeData?.employeeEmergencyInfo?.emr_person_name ? employeeData?.employeeEmergencyInfo?.emr_person_name : '',
            emr_contact_mobile: employeeData?.employeeEmergencyInfo?.emr_contact_mobile ? employeeData?.employeeEmergencyInfo?.emr_contact_mobile : '',
            emr_contact_relation: employeeData?.employeeEmergencyInfo?.emr_contact_relation ? employeeData?.employeeEmergencyInfo?.emr_contact_relation : '',
            talent_id: employeeData?.id,
        },
        validationSchema: EditFormEmergencyDetailsValidation(TalentSetting),//(new updated on - 24-4-23)
        onSubmit: (data) => {
            dispatch(updateTalenEmergency(auth.authtoken, data, navigate));
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (

        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>


                <Grid container spacing={2}>

                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={"Name"+(TalentSetting?.field_emer_name?'*':'')} isModal >
                            <ETextField
                                fullWidth
                                multiline
                                autoComplete="emr_person_name"
                                {...getFieldProps('emr_person_name')}
                                error={Boolean(touched.emr_person_name && errors.emr_person_name)}
                                helperText={touched.emr_person_name && errors.emr_person_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={"Relation"+(TalentSetting?.field_emer_relation?'*':'')} isModal >
                            <ETextField
                                fullWidth
                                multiline
                                autoComplete="emr_contact_relation"
                                {...getFieldProps('emr_contact_relation')}
                                error={Boolean(touched.emr_contact_relation && errors.emr_contact_relation)}
                                helperText={touched.emr_contact_relation && errors.emr_contact_relation}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={"Contact Number"+(TalentSetting?.field_emer_contact_no?'*':'')} isModal >
                            <ETextField
                                fullWidth
                                multiline
                                autoComplete="emr_contact_mobile"

                                {...getFieldProps('emr_contact_mobile')}
                                error={Boolean(touched.emr_contact_mobile && errors.emr_contact_mobile)}
                                helperText={touched.emr_contact_mobile && errors.emr_contact_mobile}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <span className="color-text-primary">+91</span>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                </Grid>

                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => CloswModalEmergencyDetails(false)}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                </Stack>

            </Form>
        </FormikProvider>

    )

};

export default EditModalEmergencyDetail;