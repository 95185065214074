
import React, { useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ADHAAR_DOC_ID, DRIVING_LICENCE_DOC_ID, FEMALE, MALE, NIL, PAN_DOC_ID, PASSPORT_DOC_ID, DELETE } from '../../constants';
import editFill from '@iconify/icons-material-symbols/edit-outline-rounded'
import FileIcon from '@iconify/icons-material-symbols/file-present-outline-rounded'
import { ACTIVE_USER } from '../../constants'
import { ECard } from '../../BaseComponents/ECard';
import { ETypography, ETypographyCardHeading } from '../../BaseComponents/ETypography';
import { EDividerCard } from '../../BaseComponents/EDivider';
import { ETable, ETableBody, ETableCell, ETableContainer, ETableRowSmall } from '../../BaseComponents/ETable';
import EScrollbar from '../../BaseComponents/EScrollbar'
import { EIcon } from '../../BaseComponents/EIcon';
import { EIconButton, EButtonProfile, EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons'
import { EAvatarProfile } from '../../BaseComponents/EAvatar';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import EModal from '../../BaseComponents/EModal'
import EListHeadSmall from '../../BaseComponents/ETable';
import EditModalBasicDetail from './EditModalBasicDetail';
import EditModalPersonalDetail from './EditModalPersonalDetail';
import moment from 'moment';
import EditModalDocumentDetail from './EditModalDocumentDetail';
import EditModalBankDetail from './EditModalBankDetail';
import EditModalEmergencyDetail from './EditModalEmergencyDetail';
import { ELinkMUI } from '../../BaseComponents/ELink';
import { capitalizeCapitalString } from '../../utils/formatText';
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline-rounded'
import { updateDocument } from "../../action/TalentActions";

/**
 * [2022-11-23]
 * Created by:- Aanchal Sahu
 * Description:- made this form for view profile
 **/

const tblHead = (allowAction) => {
    let arrayHead = [
        { id: 'Document Name', label: 'Document Name', alignRight: false },
        { id: 'Document Number', label: 'Document Number', alignRight: false },
        { id: 'File', label: 'File', alignRight: 'center' },
    ];
    if (allowAction) {
        arrayHead.push({ id: 'Action', label: 'Action', alignRight: 'center' });
    }
    return arrayHead;
};

function TalentEditProfile(props) {
    const { employee, isMyprofile, auth, loading, TalentSetting, allowEdit } = props;
    const theme = useTheme();
    const dispatch= useDispatch()
    const navigate= useNavigate()
    const [isBasicDetails, SetIsBasicDetails] = useState(false);
    const [isEditPersonalDetails, SetIsEditPersonalDetails] = useState(false);
    const [isEditDocumentsDetails, SetIsEditDocumentsDetails] = useState({ docModalIsOpen: false, docType: null, isDelete:false });
    const [isEditEmergencyDetails, SetIsEditEmergencyDetails] = useState(false);
    const [isEditBankDetails, SetIsEditBankDetails] = useState(false);
    const isActiveUser = Number(employee?.status) === ACTIVE_USER
    const TABLE_HEAD = useMemo(() =>  tblHead(isActiveUser && !isMyprofile && allowEdit), [])

    const getPhotoUrl = (url, gender) => {
        if (url && url != 'undefined') {
            return url;
        }
        else {
            const defaultImage = gender == FEMALE ? '/assets/images/female.png' : gender == MALE ? '/assets/images/male.png' : ''
            return defaultImage;
        }
    }

    const HandleDelete=(doc_id)=>{
        const data={talent_id: employee?.id, document_id: doc_id }
        dispatch(updateDocument(auth.authtoken, data, navigate, DELETE ))
    }

    return (
        <>
            <ECard className='card_design_1'>
                <Grid container>
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }} >

                        <Grid container display="row" justifyContent="space-between" alignItems='center' >
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>Basic Details</ETypographyCardHeading>
                            </Grid>

                            {allowEdit && isActiveUser && (!isMyprofile||( isMyprofile && auth?.authData?.is_primary && employee.is_primary && !employee.show_as_talent)) &&
                                <Grid item >
                                    <EIconButton onClick={() => SetIsBasicDetails(true)} sx={{ p: 0, m: 0, textAlign: 'right', color: 'dark.0' }}>
                                        <EIcon icon={editFill} width={23} height={23} />
                                    </EIconButton>
                                </Grid>
                            }

                            <Grid xs={12} lg={12} item>
                                <EDividerCard className='mt-8px' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={4} className='mt-24px'>
                        <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0, textAlign: 'center' }}>
                            <EButtonProfile aria-label="upload picture" component="span" id="ePhoto">
                                <EAvatarProfile alt={employee?.fullname} src={getPhotoUrl(employee?.employeeInfo?.personal_photo, employee?.employeeInfo?.personal_gender)} />
                            </EButtonProfile>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={8} xl={6.5} className='mt-24px'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} >
                                <ELabelInputMainGrid label={"Name"} isNotForm={true} isModal={true} isfullgrid={true}>
                                    {employee?.fullname ? employee?.fullname : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12}>
                                <ELabelInputMainGrid label={"Email ID"} isNotForm={true} isModal={true} isfullgrid={true}>
                                    {employee?.email ? employee?.email : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={12}>
                                <ELabelInputMainGrid label={"Contact Number"} isNotForm={true} isModal={true} isfullgrid={true}>
                                    {employee?.mobile ? '+91 ' + employee?.mobile : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Basic Detail Modal */}
                {isBasicDetails && <EModal open={isBasicDetails} newMaxWidth={'md'} close={() => SetIsBasicDetails(false)} headervalue="Update Basic Details">
                    <EditModalBasicDetail
                        auth={auth}
                        employeeData={employee}
                        closeBasicDetailModal={SetIsBasicDetails}
                        // getPhotoUrl={getPhotoUrl} //INFO: Since this prop was not used, commented as per now from both ends
                        loading={loading}
                        TalentSetting={TalentSetting}
                    />
                </EModal>}
            </ECard>
            {/* personal Detail */}
            <ECard className='card_design_1' sx={{ display: employee?.show_as_talent != true ? 'none' : 'block' }}>
                <Grid container spacing={2}  >
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }}>

                        <Grid container display="row" justifyContent="space-between" >
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>Personal Details</ETypographyCardHeading>
                            </Grid>

                            {allowEdit && isActiveUser && !isMyprofile &&
                                <Grid item >
                                    <EIconButton varient='link' onClick={() => SetIsEditPersonalDetails(true)} sx={{ p: 0, m: 0, textAlign: 'right', color: 'dark.0' }}>
                                        <EIcon icon={editFill} width={23} height={23} />
                                    </EIconButton>
                                </Grid>
                            }

                            <Grid xs={12} lg={12} item>
                                {employee?.employeeInfo?.personal_detail_check && <EDividerCard className='mt-8px' />}
                            </Grid>
                        </Grid>
                    </Grid>
                    {employee?.employeeInfo?.personal_detail_check && <>
                        <Grid item xs={6} >
                                <ELabelInputMainGrid label={"Date of Birth"}   isNotForm={true}>
                                {employee.employeeInfo?.personal_dob ? moment(employee.employeeInfo?.personal_dob).format('DD/MM/YYYY') : '-'}
                                </ELabelInputMainGrid>
                            </Grid>
                        <Grid item xs={6}>
                                <ELabelInputMainGrid label={"Gender"}   isNotForm={true}>
                                {employee?.employeeInfo?.personal_gender ? capitalizeCapitalString(employee?.employeeInfo?.personal_gender) : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={6} >
                                <ELabelInputMainGrid label={"Marital Status"}   isNotForm={true}>
                                {employee.employeeInfo?.personal_marital_status ? capitalizeCapitalString(employee?.employeeInfo?.personal_marital_status) : '-'}
                                </ELabelInputMainGrid>
                            </Grid>
                        <Grid item xs={6}>
                                <ELabelInputMainGrid label={"Blood Group"}   isNotForm={true}>
                                {employee?.employeeInfo?.personal_blood_group ? employee?.employeeInfo?.personal_blood_group : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                       
                            <Grid item xs={12}>
                                <ELabelInputMainGrid label={"Address"}  isfullgrid={true}  isNotForm={true}>
                                    {employee?.employeeInfo?.personal_address_one ? employee?.employeeInfo?.personal_address_one + ", " : ''} 
                                    {employee?.employeeInfo?.personal_address_two ? employee?.employeeInfo?.personal_address_two + ", "  : ''}
                                    {employee?.employeeInfo?.personal_district_id ?  employee?.employeeInfo?.employeeCity?.city_name + ", " : ''}
                                    {employee?.employeeInfo?.personal_state_id ?  employee?.employeeInfo?.employeeState?.state_name + ", " : ''}
                                    {employee?.employeeInfo?.personal_pin_code ? employee?.employeeInfo?.personal_pin_code : ''}
                                </ELabelInputMainGrid>
                            </Grid>
                    </>}
                </Grid>

                {/* Personal Detail Modal */}
                {isEditPersonalDetails && <EModal 
                    open={isEditPersonalDetails} 
                    newMaxWidth={'md'} 
                    close={() => SetIsEditPersonalDetails(false)} 
                    headervalue="Update Personal Details">

                    <EditModalPersonalDetail
                        auth={auth}
                        employeeData={employee}
                        closePersonalDetailModal={SetIsEditPersonalDetails}
                        getPhotoUrl={getPhotoUrl}
                        TalentSetting={TalentSetting}
                    />

                </EModal>}

            </ECard>

            <ECard className='card_design_1' sx={{ display: employee?.show_as_talent != true ? 'none' : 'block' }}>
                <Grid container  >
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }}>
                        <Grid container display="row" justifyContent="space-between" >
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>Documents</ETypographyCardHeading>
                            </Grid>
                            <Grid xs={12} lg={12} item>
                                <EDividerCard className='mt-8px' />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} xl={12} className='mt-16px'>
                        <EScrollbar >
                            <ETableContainer >
                                <ETable>
                                    <EListHeadSmall
                                        headLabel={TABLE_HEAD}
                                        rowCount={2}
                                        onRequestSort={() => null}
                                        authvar={'auth'}
                                        Theme={theme}
                                    />
                                    <ETableBody>
                                        {employee?.companyDocuments?.map((row,index) => {
                                            const {masterDocumentSetting, employeeDocuments, document_id, is_required}=row
                                            return (
                                                <ETableRowSmall hover key={index+'documents'}>
                                                    {/* Document Name */}
                                                    <ETableCell align="left" style={{ width: "30%" }}>{masterDocumentSetting?.document_name}</ETableCell>

                                                    {/* Document Number */}
                                                    <ETableCell align="left" style={{ width: "30%" }}>
                                                        {employeeDocuments?.document_id_no? employeeDocuments?.document_id_no:''}
                                                    </ETableCell>
                                                    {/* File */}
                                                    <ETableCell align="center" style={{ width: TABLE_HEAD.length == 4 ? { lg: '20%', sm: '5%' } : "5%" }}>
                                                        {employeeDocuments?.document_path &&
                                                            <EIconButton >
                                                                <ELinkMUI
                                                                    target="_blank"
                                                                    href={employeeDocuments?.document_path}>
                                                                    <EIcon icon={FileIcon} /></ELinkMUI>
                                                            </EIconButton>}
                                                    </ETableCell>
                                                    {/* Action */}
                                                    {TABLE_HEAD?.length === 4 && allowEdit && 
                                                        <ETableCell align="center" className='minWidth-120'>
                                                            <EIconButton onClick={() => SetIsEditDocumentsDetails({ docModalIsOpen: true, docType: document_id ,is_required,name:masterDocumentSetting?.document_name,document_id_no:employeeDocuments?.document_id_no,file_path:employeeDocuments?.document_path})}>
                                                                <EIcon icon={editFill} />
                                                            </EIconButton>
                                                            {employeeDocuments?.document_id_no || employeeDocuments?.document_path ?
                                                                <EIconButton className='p0' onClick={() => SetIsEditDocumentsDetails({ isDelete: true, docType: document_id,name:masterDocumentSetting?.document_name ,document_id_no:employeeDocuments?.document_id_no})}>
                                                                    <EIcon icon={DeleteIcon} className='color-text-error-red' />
                                                                </EIconButton> : ''}
                                                        </ETableCell>}

                                                </ETableRowSmall>
                                            )
                                        }

                                        )}
                                    </ETableBody>
                                </ETable>
                            </ETableContainer>
                        </EScrollbar>
                    </Grid>
                </Grid>

                {/* Document Detail Modal */}
                {isEditDocumentsDetails.docModalIsOpen && 
                <EModal open={isEditDocumentsDetails.docModalIsOpen} newMaxWidth={'md'} close={() => SetIsEditDocumentsDetails({ docModalIsOpen: false, docType: null })} 
                headervalue= {'Update '+(isEditDocumentsDetails?.name? isEditDocumentsDetails?.name:'')+' Details'}>

                    {isEditDocumentsDetails.docType &&
                        <EditModalDocumentDetail
                            auth={auth}
                            employeeData={employee}
                            closeDocumentDetailModal={()=>SetIsEditDocumentsDetails({ docModalIsOpen: false, docType: null })}
                            selectedDocument={isEditDocumentsDetails}
                            // TalentSetting={TalentSetting}
                        />
                    }

                </EModal>}

                {/* Document Detail Modal */}
                {isEditDocumentsDetails.isDelete &&
                    <EModal open={isEditDocumentsDetails.isDelete} close={() => SetIsEditDocumentsDetails({ isDelete: false, docType: null })}
                        headervalue={"Delete: Confirmation"}>
                        {isEditDocumentsDetails.docType &&
                            <Grid container>
                            <Grid item>
                                <ETypography variant="h5" className="font-size-18px">
                                    Are you sure you want to delete the
                                    <span className="theme-main-text-color-bold px-6px text-decoration-underline">
                                        {isEditDocumentsDetails?.name}
                                    </span>
                                    details?
                                </ETypography>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12} className="modal1-buttons-stick-bottom pt-16px">
                                <EButtonOutlined size="large" variant="outlined" 
                                onClick={() => { SetIsEditDocumentsDetails({ isDelete: false, docType: null })}} 
                                >  
                                    No
                                </EButtonOutlined>
                                <ELoadingButton
                                    size="large"
                                    variant="contained"
                                    onClick={() => HandleDelete(isEditDocumentsDetails?.docType )}
                                    className="button-left-margin"
                                > Yes
                                </ELoadingButton>
                            </Grid>
                        </Grid>
                        }
                    </EModal>}

            </ECard>

            {/* Bank Details */}
            <ECard className='card_design_1' sx={{ display: employee?.show_as_talent != true ? 'none' : 'block' }}>
                <Grid container spacing={2}  >
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }}>

                        <Grid container display="row" justifyContent="space-between">
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>Bank Details</ETypographyCardHeading>
                            </Grid>
                            {allowEdit && isActiveUser && !isMyprofile &&
                                <Grid item >
                                    <EIconButton varient='link' onClick={() => SetIsEditBankDetails(true)} sx={{ p: 0, m: 0, textAlign: 'right', color: 'dark.0' }}>
                                        <EIcon icon={editFill} width={23} height={23} />
                                    </EIconButton>
                                </Grid>
                            }
                            <Grid xs={12} lg={12} item>
                                {employee?.employeeBankInfo && <EDividerCard className='mt-8px' />}
                            </Grid>
                        </Grid>
                    </Grid>
                    {employee?.employeeBankInfo &&
                        <>
                        <Grid item xs={6} >
                                <ELabelInputMainGrid label={"Bank Name"}   isNotForm={true}>
                                {employee?.employeeBankInfo?.bank_name ? employee?.employeeBankInfo?.bank_name : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={6} >
                                <ELabelInputMainGrid label={"IFSC Code"}  isNotForm={true} >
                                {employee?.employeeBankInfo?.bank_ifsc_code ? employee?.employeeBankInfo?.bank_ifsc_code : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={6} >
                                <ELabelInputMainGrid label={"Branch Name"}  isNotForm={true} >
                                {employee?.employeeBankInfo?.bank_branch_name ? employee?.employeeBankInfo?.bank_branch_name : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={6} >
                                <ELabelInputMainGrid label={"Cardholder's Name"}  isNotForm={true} >
                                {employee?.employeeBankInfo?.bank_account_holder_name ? employee?.employeeBankInfo?.bank_account_holder_name : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={6} >
                                <ELabelInputMainGrid label={"Account Number"}  isNotForm={true} >
                                {employee?.employeeBankInfo?.bank_account_no ? employee?.employeeBankInfo?.bank_account_no : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                        </>
                    }
                </Grid>

                {/*  Bank Detail Modal */}
                {isEditBankDetails &&
                    <EModal open={isEditBankDetails} newMaxWidth={'md'} close={() => SetIsEditBankDetails(false)} headervalue="Update Bank Details">
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                                <EditModalBankDetail
                                    auth={auth}
                                    employeeData={employee}
                                    closeModalEditBankDetail={SetIsEditBankDetails}
                                    TalentSetting={TalentSetting}
                                />
                            </Grid>
                        </Grid>
                    </EModal>
                }

            </ECard>
            <ECard className='card_design_1' sx={{ display: employee?.show_as_talent != true ? 'none' : 'block' }}>
                <Grid container spacing={2}  >
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }}>

                        <Grid container display="row" justifyContent="space-between" >
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>Emergency Details</ETypographyCardHeading>
                            </Grid>

                            {allowEdit && isActiveUser && !isMyprofile &&
                                <Grid item >
                                    <EIconButton varient='link' onClick={() => SetIsEditEmergencyDetails(true)} sx={{ p: 0, m: 0, textAlign: 'right', color: 'dark.0' }}>
                                        <EIcon icon={editFill} width={23} height={23} />
                                    </EIconButton>
                                </Grid>
                            }


                            <Grid xs={12} lg={12} item>
                                {employee?.employeeEmergencyInfo && <EDividerCard className='mt-8px' />}
                            </Grid>
                        </Grid>
                    </Grid>
                    {employee?.employeeEmergencyInfo && <>
                        <Grid item xs={6} >
                                <ELabelInputMainGrid label={"Name"}   isNotForm={true}>
                                {employee?.employeeEmergencyInfo?.emr_person_name ? employee?.employeeEmergencyInfo?.emr_person_name : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={6} >
                                <ELabelInputMainGrid label={"Relation"}   isNotForm={true}>
                                {employee?.employeeEmergencyInfo?.emr_contact_relation ? employee?.employeeEmergencyInfo?.emr_contact_relation : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item xs={6} >
                                <ELabelInputMainGrid label={"Contact Number"}   isNotForm={true}>
                                {employee?.employeeEmergencyInfo?.emr_contact_mobile ? '+91 ' + employee?.employeeEmergencyInfo?.emr_contact_mobile : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                    </>}
                </Grid>
                {/*  Emergency Detail Modal */}
                {isEditEmergencyDetails && <EModal open={isEditEmergencyDetails} newMaxWidth={'md'}
                    close={() => SetIsEditEmergencyDetails(false)} headervalue="Update Emergency Details">

                    <EditModalEmergencyDetail
                        auth={auth}
                        employeeData={employee}
                        CloswModalEmergencyDetails={SetIsEditEmergencyDetails}
                        TalentSetting={TalentSetting}
                    />
                </EModal>}
            </ECard>
        </>
    );
}

export default TalentEditProfile
