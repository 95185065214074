// This page is for manual attendance preview data
import EModal from "../../BaseComponents/EModal";
import ModalAttendanceUpdate from "../../PageComponents/Attendance/ModalAttendanceUpdate";
import moment from "moment";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { EStickyTable, EStickyRow, EStickyCell } from "../../BaseComponents/EStickyTable";
import { ETypography } from '../../BaseComponents/ETypography';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { EButton, EButtonOutlined } from "../../BaseComponents/EButtons";
import { Stack } from "@mui/system";
import { ManualAttendanceDetails } from "../../action/PayrollAction";
import { useDispatch } from "react-redux";



const ManualAttendancePreview = (props) => {
    const { close, auth, is_payroll, ManualState, payroll_id } = props;

    const header_dates = ManualState ? Object.keys(Object.values(ManualState)[0]?.attendance_dates) : null;
    const final_header = header_dates ? ['Talent Name ', 'Designation', ...header_dates] : []

    const [manualAttData, setManualAttData] = useState(ManualState);

    const [selectedAttendance, setSelectedAttendance] = useState({ open: false, data: null });


    const dispatch = useDispatch()

    const updateManualAttendance = (data) => {
        const dateIndex = data?.component_id;
        const mainIndex = data?.index;
        const UpdatedAbbreviation = data?.abbreviation?.id;

        //New updated Date object
        const newDateObject = {
            abbreviation: UpdatedAbbreviation,
            attendance_remark: data?.attendance_remark,
            bgColor: UpdatedAbbreviation == 'P' ? '#C8DBBE' : '#A6A6A6',
            component_id: dateIndex
        }

        setManualAttData((prevState) => {
            const update = [...prevState];
            update[mainIndex].attendance_dates = {
                ...update[mainIndex].attendance_dates,
                [dateIndex]: newDateObject
            }
            return update;
            }
        )
        setSelectedAttendance({ open: false, data: null });
    }

    const onSubmitManualAttendance = () => {
        dispatch(ManualAttendanceDetails(auth.authtoken, is_payroll, manualAttData, payroll_id))
        close()
    }

    return (<>
        <EStickyTable
            stickyHeaderCount={2}
            leftStickyColumnCount={2}
            className={'tContainer'}
        >
            <EStickyRow key={'headRow'} className={'tHeadSheet'}>
                {
                    header_dates && final_header?.map((hData) => {
                        return (<EStickyCell
                            className={'tColSheet text-align'}
                            style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}
                            key={hData}
                        >
                            <ETypography >
                                {moment(hData).format('DD') != 'Invalid date' ? moment(hData).format('DD') : <span className="bold-600">{hData}</span>}<br />
                                {moment(hData).format('DD') != 'Invalid date' ? moment(hData).format('ddd') : ''}
                            </ETypography>
                        </EStickyCell>);
                    })
                }
            </EStickyRow>
            {
                manualAttData && Object.values(manualAttData)?.map((bDataRow, index) => {
                    return (
                        <EStickyRow key={"bodyRow" + index} className={'tBodySheet'}>
                            <EStickyCell className={'tColSheet'} key={index + "bodyCol" + 0}> {bDataRow?.employee_name}<br/>{'('+bDataRow?.employee_code+')'}</EStickyCell>
                            <EStickyCell className={'tColSheet'} key={index + "bodyCol" + 1}> {bDataRow?.designation} </EStickyCell>

                            {header_dates.map((bData, indexChild) => {
                                const { bgColor, abbreviation } = bDataRow.attendance_dates[bData]
                                let instyle = {
                                    fontWeight: '600'
                                };
                                if (bgColor) {
                                    instyle.backgroundColor = bgColor;
                                    instyle.color = '#403F3F';
                                }
                                if (abbreviation) { instyle.cursor = 'pointer'; }
                                return (

                                    <EStickyCell
                                        className={'tColSheet text-align justify-content-center'}
                                        key={index + "bodyCol" + indexChild + 2}
                                        style={instyle}
                                        onClick={() => {
                                            if (abbreviation) {
                                                setSelectedAttendance({
                                                    open: true, data: {
                                                        ...bDataRow.attendance_dates[bData],
                                                        value: bDataRow.attendance_dates[bData].abbreviation,
                                                        date: bData, index: index,
                                                        name: bDataRow?.employee_name + '(' + bDataRow?.employee_code + ')'
                                                    }
                                                })
                                            }
                                        }}
                                    >{bDataRow.attendance_dates[bData]?.attendance_remark ?
                                        <ETooltip title={bDataRow.attendance_dates[bData].attendance_remark} arrow>
                                            <span> {abbreviation}  </span>
                                        </ETooltip>
                                        :

                                        <span > {abbreviation} </span>
                                        }

                                    </EStickyCell>
                                );
                            })}
                        </EStickyRow>
                    )
                })
            }

        </EStickyTable>
        <Stack direction='row' spacing={2} paddingTop={1} justifyContent='flex-end'>

            <EButtonOutlined variant="outlined"
                onClick={() => close()} >
                Cancel
            </EButtonOutlined>
            <EButton onClick={() => onSubmitManualAttendance()}>
                Save
            </EButton>
        </Stack>

        {selectedAttendance.open &&
            <EModal open={selectedAttendance.open} headervalue={`Update Attendance - ${selectedAttendance?.data?.name}`}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={12} xl={12} >
                        {selectedAttendance.data && Object.keys(selectedAttendance.data).length > 0 &&
                            <ModalAttendanceUpdate
                                attUpdateParams={selectedAttendance.data}
                                auth={auth}
                                isPayroll={false}
                                payrollId={1}
                                isManual={true}
                                updateManualAttendance={updateManualAttendance}
                                close={()=>setSelectedAttendance({ open: false, data: null })}
                            />
                        }
                    </Grid>
                </Grid>
            </EModal>
        }
    </>
    )

};

export default ManualAttendancePreview;
