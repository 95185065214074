import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useSelector } from "react-redux";
import { ROLE_HR, ROLE_ADMIN, SUPER_ADMIN } from '../../constants'
import { DOWNLOAD_PDF, DOWNLOAD_CSV, ATTENDANCE_LOG_DOWNLOAD_URL, LEAVE_LOG_DOWNLOAD_URL } from '../../constants'
import FilePDF from '@iconify/icons-mdi/file-pdf-box';
import FileCSV from '@iconify/icons-mdi/file-excel';
import { EInputAdornment, ETextFieldSearch } from '../../BaseComponents/ETextField';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import EMenuPopover, { EMenuIcon, EMenuItem } from "../../BaseComponents/EMenuPopover";
import { DownLoadFile } from '../../action/AdminAction';
import { EIcon, EIconInputAdornment } from '../../BaseComponents/EIcon';
import { EIconButton } from '../../BaseComponents/EButtons';
import { ETooltip } from '../../BaseComponents/ETooltip';
import SortByAlphaIcon from '@iconify/icons-material-symbols/sort-by-alpha';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import CancelIcon from '@iconify/icons-material-symbols/cancel-outline-rounded';

/**
 * [2022-11-25]
 * changes by:-Aanchal Sahu
 * Description:-newly designed this component
**/

function AttendanceSearchfields(props) {
    const { tab, LeaveSearch, setLeaveSearch, setAttendanceSearch, AttendanceSearch, defaultNameFilter, isMyprofile, page } = props
    const [yearmonthValue, setyearmonthValue] = useState(tab && tab == "1" ? moment().format('YYYY-MM-DD') : null);
    const [TalentName, setTalentName] = useState('');
    const auth = useSelector(state => state.authReducer);
    const company = useSelector(state => state.CompanyReducer);
    const [anchorEl, setAnchorEl] = React.useState(null);


    // useEffect(() => {
    //     if (auth?.authtoken) {
    //         // dispatch(GetTalentDetail(auth?.authtoken, auth?.authData?.id))
    //     }
    // }, [auth?.authtoken])

    useEffect(() => {
        if (auth?.authtoken && auth?.authData) {
            if (defaultNameFilter) { setTalentName(defaultNameFilter) }
            else { setTalentName(auth?.authData?.fullname) }
        }
    }, [auth?.authtoken])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const onKeyDown = (e) => {
        e.preventDefault();
    };

    const open = Boolean(anchorEl);

    const DownloadReport = (value) => {

        let url = '';
        let searchData = {};
        let file_name = '';
        if (tab == 2) {
            url += LEAVE_LOG_DOWNLOAD_URL;
            searchData = LeaveSearch;
            file_name += 'Leave_log'
        } else {
            url += ATTENDANCE_LOG_DOWNLOAD_URL;
            searchData = AttendanceSearch;
            file_name += 'Attendance_log'
        }
        if (value != null) { url += `?download_format=${value}`; }
        if (searchData?.fullname != null) { url += `&fullname=${searchData?.fullname}`; }
        if (searchData?.yearMonth != null) {
            file_name += "_" + moment(yearmonthValue).format("MMM-YYYY");
            url += `&year_month=${searchData?.yearMonth}`;
        }
        if (searchData?.sortBy != null) { url += `&sort=${searchData?.sortBy}`; }
        DownLoadFile(auth.authtoken, url, file_name)
        setAnchorEl(null);
    }

    return (
        <>
            <Grid container className='mb-16px'>
                <Grid item xs={10} sm={10} lg={10.5} md={10.5} xl={10.5} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={5} sm={4} md={3.5} lg={3} xl={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <EDatePicker
                                    openTo="month"
                                    views={['month', 'year']}
                                    inputFormat="MMM yyyy"
                                    value={yearmonthValue}
                                    minDate={new Date(company?.companyData?.CompanyActivePlan?.plan_valid_from)}
                                    maxDate={tab == 2 ? new Date(company?.companyData?.CompanyActivePlan?.plan_valid_to) : new Date()}
                                    onChange={(newValue) => {
                                        if (tab == 2) { setLeaveSearch({ ...LeaveSearch, yearMonth: newValue && newValue != 'Invalid Date' ? moment(newValue).format('YYYYMM') : null }) }
                                        else { setAttendanceSearch({ ...AttendanceSearch, yearMonth: newValue && newValue != 'Invalid Date' ? moment(newValue).format('YYYYMM') : null }) }
                                        setyearmonthValue(newValue && newValue != 'Invalid Date' ? newValue : null)
                                    }}
                                    renderInput={(params) =>
                                        <div className='date-picker-div'>{/*this div contains text field and clear icon*/}
                                            <ETextFieldSearch
                                                size="small" 
                                                fullWidth
                                                {...params}
                                                onKeyDown={onKeyDown}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: 'Select Month'
                                                }}
                                            />
                                            {yearmonthValue &&
                                                <EInputAdornment
                                                    position='end'
                                                    onClick={() => {
                                                        if (tab == 2) { setLeaveSearch({ ...LeaveSearch, yearMonth: null }) }
                                                        else { setAttendanceSearch({ ...AttendanceSearch, yearMonth: null }) }
                                                        setyearmonthValue(null)
                                                    }}
                                                    className='clear-icon'
                                                >
                                                    <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                                                </EInputAdornment>
                                            }
                                        </div>
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        {/* // hide incase tab is leave and user is not reporting manager */}
                        <Grid item xs={5} sm={5} md={3.5} lg={3} xl={3}>
                            {(
                                // (page !== 'talent') || 
                                (page == 'attendance' && [ROLE_HR, ROLE_ADMIN, SUPER_ADMIN].includes(auth?.authData?.role)) ||
                                (page == 'leavelog' && !isMyprofile && (([ROLE_HR, ROLE_ADMIN, SUPER_ADMIN].includes(auth?.authData?.role)) || auth?.authData?.isReportingManager)
                                ))
                                &&
                                <ETextFieldSearch
                                    fullWidth
                                    id="outlined-basic"
                                    size='small'
                                    placeholder="Search By Talent"
                                    value={TalentName}
                                    onChange={(event) => {
                                        if (event.target.value?.length > 2 || event.target.value == '') {
                                            if (tab == 2) { setLeaveSearch({ ...LeaveSearch, fullname: event.target.value }) }
                                            else { setAttendanceSearch({ ...AttendanceSearch, fullname: event.target.value }) }
                                        }
                                        setTalentName(event.target.value)
                                    }}
                                    InputProps={{
                                        endAdornment: <EInputAdornment position="end" sx={{ visibility: tab == 1 && AttendanceSearch.fullname.length === 0 || tab == 2 && LeaveSearch.fullname.length === 0 ? 'hidden' : 'visible' }} className='cursor-pointer'  // visibility: TalentName ? 'visible' : 'hidden'
                                            onClick={() => {
                                                setTalentName('')
                                                if (tab == 2) { setLeaveSearch({ ...LeaveSearch, fullname: '' }) }
                                                else { setAttendanceSearch({ ...AttendanceSearch, fullname: '' }) }
                                            }
                                            }
                                        ><EIcon icon={CancelIcon} />
                                        </EInputAdornment>,
                                    }}
                                />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} sm={2} md={1.5} lg={1.5} xl={1.5} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <ETooltip title={'Download'} arrow>
                            <EIconButton size={"large"} onClick={handleClick} className='pr-16px'>
                                <EIcon icon={FileDownloadIcon} className='height-width-22px' />
                            </EIconButton>
                        </ETooltip>
                        <ETooltip title={'Sort by date'} arrow>
                            <EIconButton  className='pl0'
                                onClick={() => {
                                    if (tab == 2) { setLeaveSearch({ ...LeaveSearch, sortBy: LeaveSearch.sortBy == null ? 'from_date ASC' : LeaveSearch.sortBy == 'from_date ASC' ? 'from_date DESC' : 'from_date ASC' }) }
                                    else { setAttendanceSearch({ ...AttendanceSearch, sortBy: AttendanceSearch.sortBy == null ? 'from_date ASC' : AttendanceSearch.sortBy == 'from_date ASC' ? 'from_date DESC' : 'from_date ASC' }) }
                                }}
                            >
                                <EIcon icon={SortByAlphaIcon} className='height-width-22px' />
                            </EIconButton>
                        </ETooltip>
                    </Grid>
                </Grid>
            </Grid>
            <EMenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                sx={{ width: 180, top: 40 }}
            >
                <EMenuItem
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    onClick={() => DownloadReport(DOWNLOAD_PDF)}
                >
                    <EMenuIcon icon={FilePDF} />  PDF
                </EMenuItem>
                <EMenuItem
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    onClick={() => DownloadReport(DOWNLOAD_CSV)}
                >
                    <EMenuIcon icon={FileCSV} /> CSV
                </EMenuItem>
            </EMenuPopover>
        </>
    );
}

export default AttendanceSearchfields;