import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from 'formik';
import { ContractFromValidation } from "../../utils/validation";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { COMPLETED_STATUS, COMPLETE_STATUS, NIL, PENDING_STATUS, PERMISSIONS, TERMINATED_STATUS } from "../../constants";
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import AddIcon from '@iconify/icons-material-symbols/add-box-outline-rounded'
import base64 from 'base-64'
import { capitalizeCapitalString, OnlyFirstLetterCapitalString } from "../../utils/formatText";
import EditContractDetails from "../../PageComponents/FreelancerContracts/Contracts/EditModalContractDetails";
import EditModalFreelancerDetails from "../../PageComponents/FreelancerContracts/Contracts/EditModalFreelancerDetails";
import EditModalInchargeDetails from "../../PageComponents/FreelancerContracts/Contracts/EditModalInchargeDetails";
import AddDeliverableForm from "../../PageComponents/FreelancerContracts/Contracts/AddDeliverableForm";
import PhaseForm from "../../PageComponents/FreelancerContracts/Contracts/PhaseForm";
import { numberWithCommas } from "../../utils/formatNumber";
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
//actions
import ContractViewActions from "../../PageComponents/FreelancerContracts/Contracts/ContractViewActions";
import { ContractViewData, CreateContract, LockContractView, SaveContractPhase } from "../../action/ContractAction";
//base components
import Page from "../../BaseComponents/EPage";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EHelperText, ETypography, ETypographyCardHeading, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { EButton, EButtonOutlined, EButtonOutlinedIcon, EButtonRed, EButtonVariant3, EIconButton, ELoadingButton } from "../../BaseComponents/EButtons";
import { EDivider } from "../../BaseComponents/EDivider";
import { EBox, EBoxPage } from "../../BaseComponents/EBox";
import { ELabelInputMainGrid, LoadingGrid } from "../../BaseComponents/EGrid";
import { ECard, ECardBasic } from "../../BaseComponents/ECard";
import { EIcon } from "../../BaseComponents/EIcon";
import EListHeadSmall, { ETable, ETableBody, ETableCell, ETableContainer, ETableRowSmall } from "../../BaseComponents/ETable";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import EChip from "../../BaseComponents/EChip";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { ETextFieldSmall } from "../../BaseComponents/ETextField";
import EModal from "../../BaseComponents/EModal";
import TerminationInfo from "../../PageComponents/FreelancerContracts/Contracts/TerminationInfo";
import ContractViewTaCComponent from "../../PageComponents/FreelancerContracts/Contracts/ContractViewTaCComponent";
import { ETooltip } from "../../BaseComponents/ETooltip";
import EditModalPaymentDetails from "../../PageComponents/FreelancerContracts/Contracts/EditModalPaymentDetails";
import EHidden from "../../BaseComponents/EHidden";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import TerminateIcon from '@iconify/icons-mdi/file-document-delete-outline';//used mdi here due to file-document-delete-outline was unavailable in icons-material-symbols.
import { useCheckPermission } from "../../hooks";


const ContractView = (props) => {
    const params = useParams();
    const { auth } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tacValues, setTacValues] = useState({ tnc: [] });
    const contractDetails = useSelector((state) => state.ContractReducer);
    const [isAddNewPhase, SetIsAddNewPhase] = useState({ isOpen: false, data: [], isAdvanceRow: false, isViewOpen: false, release_name: '' });
    const [phaseValue, SetPhaseValue] = useState('');

    const [isUpdatePaymentDetails, SetIsUpdatePaymentDetails] = useState(false);
    const [IsLockContract, setIsLockContract] = useState({ open: false, button: 1 });

    const [isEdit, SetEdit] = useState({
        isEditContractDetails: false,
        isFreelancerDetails: false,
        isInchargeDetails: false,
        isNewDeliverable: false,
        isEditTnC: false,
    });


    const allowAddEditDelete = useCheckPermission([PERMISSIONS.ViewAddEditDeleteContract])

    const _allowTerminateContract = useCheckPermission([PERMISSIONS.ViewTerminateContract])
    const allowTerminateContract = _allowTerminateContract && contractDetails && contractDetails?.contractData?.status != TERMINATED_STATUS && contractDetails?.contractData?.status != COMPLETED_STATUS && contractDetails?.contractData?.is_locked
    
    const HandleEdit = (varName, action, data = null) => {
        SetEdit(
            {
                isEditContractDetails: varName == 'isEditContractDetails' ? action : false,
                isFreelancerDetails: varName == 'isFreelancerDetails' ? action : false,
                isInchargeDetails: varName == 'isInchargeDetails' ? action : false,
                isNewDeliverable: varName == 'isNewDeliverable' ? action : false,
                isEditTnC: varName == 'isEditTnC' ? action : false,
            }
        )
    }


    const isLockIndex =
        contractDetails?.contractData?.advance_applicable &&
            contractDetails?.contractData?.advance_applicable == false
            ? 0
            : 1;

    const formikAddContract = useFormik({
        validateOnChange: false,
        initialValues: {
            contract_title: '',
            contract_amount: '',
            contract_start_date: null,
            contract_end_date: null,
            freelancer: null,
            gst: null,
            isAdvance: "true",
            terms_cond: '',
            terms_cond_text: '',
            is_igst: null,
            created_by: auth?.authData?.id,
            contract_with_gst: '',
            list_terms: '',
            incharge: null,
            no_of_tnc_selected: 0,
            is_terms_conditions_list: false
        },
        validationSchema: ContractFromValidation,
        onSubmit: (data) => {
            data.freelancer = data?.freelancer?.id;
            data.incharge = data?.incharge?.id;
            data.created_by = auth?.authData?.id;
            data.gst = data?.gst?.id;
            if (data.is_igst != null) { data.is_igst = data?.is_igst?.id; }

            tacValues?.tnc?.length > 0 && tacValues?.tnc?.map((row, index) => {
                if (row?.is_default == true) {
                    if (index == 0) { return data.terms_cond += row?.id }
                    else { return data.terms_cond += ',' + row?.id }
                }
            })
            dispatch(CreateContract(auth.authtoken, data, navigate));
        }
    });
    const { handleSubmit } = formikAddContract;

    useEffect(() => {
        if (auth?.authtoken) {
            if (params.id && base64.decode(params.id)) {
                dispatch(ContractViewData(auth?.authtoken, base64.decode(params.id)));
            } else {
                navigate('/dashboard');
            }
        }
    }, []);
    const contractData = contractDetails?.contractData ? contractDetails?.contractData : null

    useEffect(() => {
        if (auth?.authtoken && contractDetails && contractDetails?.contractData?.WorkFace) {
            let x = contractDetails?.contractData?.advance_applicable == true ? contractDetails?.contractData?.WorkFace?.length - 1 : contractDetails?.contractData?.WorkFace?.length;
            SetPhaseValue(x ? x : '');
        } else {
            SetPhaseValue('');
        }
    }, [contractDetails]);

    const NewPhase = () => {
        if (phaseValue && phaseValue >= 1 && contractDetails?.contractData?.WorkDetail && contractDetails?.contractData?.WorkDetail?.length >= phaseValue) {
            dispatch(SaveContractPhase(auth?.authtoken, { face_count: phaseValue, contract_id: base64.decode(params.id) }));
        }
    };

    let isLock = true;
    var total = contractDetails?.contractData &&
        contractDetails?.contractData?.WorkFace &&
        contractDetails?.contractData?.WorkFace?.reduce(function (filtered, option) {
            if (!filtered['total']) {
                filtered['total'] = 0;
            }
            if (!option.group_payment_no) {
                filtered.total += Number(option?.face_price);
            }
            if (Number(option?.face_price) <= 0) {
                isLock = false;
            }
            return filtered;
        }, {});

    const PaymentAmount =
        contractDetails?.contractData?.WorkFace?.length > 0
            ? contractDetails?.contractData?.total_amount &&
            total?.total >= 0 &&
            (contractDetails?.contractData?.total_amount - total?.total).toFixed(2)
            : contractDetails?.contractData?.total_amount;
    const TABLE_HEAD = [
        { id: 'SNo.', label: 'SNo.', alignRight: false },
        { id: 'Title', label: 'Title', alignRight: false },
        { id: 'Description', label: 'Description', alignRight: false },
    ];
    const PAYMENT_TABLE_HEAD = [
        { id: 'SNo.', label: 'SNo.', alignRight: false },
        { id: 'Particular', label: 'Particular', alignRight: false },
        { id: 'Amount', label: 'Amount', alignRight: true },
        { id: 'Deliverables', label: 'Deliverables', alignRight: false },
        { id: 'Remark', label: 'Remark', alignRight: 'center' },
    ];
    if (contractDetails && contractDetails?.contractData?.is_locked) {
        TABLE_HEAD.push({ id: 'Submitted On', label: 'Submitted On', alignRight: 'center' }, { id: 'Status', label: 'Status', alignRight: 'center' })
        PAYMENT_TABLE_HEAD.push(
            { id: 'Particular Status', label: 'Particular Status', alignRight: 'center' },
            { id: 'Payment Status', label: 'Payment Status', alignRight: 'center' },
            // { id: 'Action', label: 'Action', alignRight: 'center' }
        )
    } else {
        if (allowAddEditDelete) {
            TABLE_HEAD.push({ id: 'Action', label: 'Action', alignRight: false })
            PAYMENT_TABLE_HEAD.push({ id: 'Action', label: 'Action', alignRight: 'center' })
        }
    }

    return (
        <Page title="View Contract" >
            <Grid container display="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading={contractDetails?.contractData?.contract_code ? contractDetails?.contractData?.contract_code : ''}
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Freelancer Contract', href: '/freelancer-contract/contract-list' },
                            { name: 'Contract List', href: '/freelancer-contract/contract-list' },
                            {
                                name: contractDetails?.contractData?.contract_code ?  contractDetails?.contractData?.contract_code : 'View Contract',
                            }
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item> { }
                        {
                            allowTerminateContract &&
                            <EButtonRed variant="contained" color="secondary" className='mr10px red-button-css'
                                onClick={() => navigate(`/freelancer-contract/contract-termination/${base64.encode(contractDetails?.contractData?.id)}`)}>
                                Terminate
                            </EButtonRed>
                        }

                        <EButtonOutlined variant="outlined" className='button-left-margin' onClick={() => navigate(-1)} >  Back </EButtonOutlined>

                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
            >
                <Grid item>
                    <ETypographyPageHeading> {contractDetails?.contractData?.contract_code ? 'Contract Number - '+contractDetails?.contractData?.contract_code : ''}</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        {
                            allowTerminateContract &&
                            <EButtonRed variant="contained" color="secondary" className='mr10px red-button-css p-8px minWidth-40px'
                                onClick={() => navigate(`/freelancer-contract/contract-termination/${base64.encode(contractDetails?.contractData?.id)}`)}>
                                <EIcon icon={TerminateIcon} className='height-width-22px p0' />
                            </EButtonRed>
                        }
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                            <EIcon icon={ForwardArrow}  className='height-width-15px' /> </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
            </Grid>
            <EHidden width="mdDown">
            <EDivider className='mt-8px' />
</EHidden>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <EBoxPage>
                        {contractDetails?.contractData?.status == TERMINATED_STATUS &&
                            <TerminationInfo contractData={contractData} />}

                        <ECard className='card_design_1 border-05px-border6' >
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                className={isEdit?.isEditContractDetails || contractDetails?.contractData?.is_locked || !allowAddEditDelete ? 'mb-8px ' : ''}
                            >
                                <ETypographyCardHeading variant='h5'>
                                    {isEdit?.isEditContractDetails ? 'Update ' : ''}Contract Details
                                </ETypographyCardHeading>
                                {allowAddEditDelete && !isEdit?.isEditContractDetails ?
                                    <EIconButton className='px0' onClick={() => HandleEdit('isEditContractDetails',true)} sx={{ display: contractDetails?.contractData?.is_locked == true ? 'none' : 'inline-flex' }}>
                                        <EIcon icon={EditIcon} /></EIconButton> : ''}
                            </Grid>

                            <EDivider className="mb-16px borderColor-staticBorder7" />
                            {!isEdit?.isEditContractDetails ?
                                <EBox >
                                    {!contractDetails?.contractLoading ?
                                        <Grid container spacing={2} className='px-16px'>
                                            <Grid item md={12} sm={12} xs={12}  >
                                                <ELabelInputMainGrid label={'Contract Name'} isfullgrid={true} isNotForm={true} alignlabel={'align-top'}>
                                                    {contractData?.contract_title ? contractData?.contract_title : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12} >
                                                <ELabelInputMainGrid label={'Tenure'} isNotForm={true} isfullgrid={true}>
                                                    {contractData?.contract_start_date ? moment(contractData?.contract_start_date).format('DD/MM/YYYY') : NIL}
                                                    {contractData?.contract_end_date ? ' to ' + moment(contractData?.contract_end_date).format('DD/MM/YYYY') : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                        </Grid>
                                        :
                                        <LoadingGrid />
                                    }
                                </EBox>
                                :
                                <EditContractDetails
                                    close={() => HandleEdit('isEditContractDetails',false)}
                                    contract={contractDetails}
                                    auth={auth}
                                />
                            }
                        </ECard>

                        <ECard className='card_design_1 border-05px-border6'>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                className={isEdit?.isFreelancerDetails || contractDetails?.contractData?.is_locked || !allowAddEditDelete ? 'mb-8px ' : ''}
                            >
                                <ETypographyCardHeading variant='h5'>
                                    {isEdit?.isFreelancerDetails ? 'Update ' : ''} Freelancer Details
                                </ETypographyCardHeading>
                                {allowAddEditDelete && !isEdit?.isFreelancerDetails ?
                                    <EIconButton className='px0' onClick={() => HandleEdit('isFreelancerDetails',true)} sx={{ display: contractDetails?.contractData?.is_locked == true ? 'none' : 'inline-flex' }} >
                                        <EIcon icon={EditIcon} />
                                    </EIconButton> : ''}
                            </Grid>
                            <EDivider className="mb-16px borderColor-staticBorder7" />
                            {isEdit?.isFreelancerDetails ?
                                <EditModalFreelancerDetails
                                    close={() => HandleEdit('isFreelancerDetails',false)}
                                    auth={auth}
                                    contract={contractDetails}
                                /> :
                                <EBox >
                                    {!contractDetails?.contractLoading ?
                                        <Grid container spacing={2} className='px-16px'>

                                            <Grid item md={6} sm={6} xs={12}  >
                                                <ELabelInputMainGrid label={'Freelancer Name'} isNotForm={true} alignlabel={'align-top'}>
                                                    {contractData?.Freelancer?.freelancer_name ? contractData?.Freelancer?.freelancer_name : NIL}
                                                    {contractData?.Freelancer?.freelancer_code ? ' (' + contractData?.Freelancer?.freelancer_code + ')' : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Contact Number'} isNotForm={true} alignlabel={'align-top'}>
                                                    {contractData?.Freelancer?.freelancer_contact_no ? "+91 "+contractData?.Freelancer?.freelancer_contact_no : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Email Id'} isNotForm={true}>{/*childrenClassname={'text-dots'}*/}
                                                    {contractData?.Freelancer?.freelancer_email ? contractData?.Freelancer?.freelancer_email : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                        </Grid>
                                        :
                                        <LoadingGrid />
                                    }


                                </EBox>
                            }
                        </ECard>

                        <ECard className='card_design_1 border-05px-border6'>

                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                className={isEdit?.isInchargeDetails || contractDetails?.contractData?.is_locked || !allowAddEditDelete ? 'mb-8px ' : ''}
                            >
                                <ETypographyCardHeading variant='h5'>
                                    {isEdit?.isInchargeDetails ? 'Update ' : ''}   Incharge Details
                                </ETypographyCardHeading>
                                {allowAddEditDelete && !isEdit?.isInchargeDetails ?
                                    <EIconButton className='px0' onClick={() => HandleEdit('isInchargeDetails',true)} sx={{ display: contractDetails?.contractData?.is_locked == true ? 'none' : 'inline-flex' }}>
                                        <EIcon icon={EditIcon} /></EIconButton> : ""}
                            </Grid>

                            <EDivider className="mb-16px borderColor-staticBorder7" />
                            {isEdit?.isInchargeDetails ?
                                <EditModalInchargeDetails
                                    close={() => HandleEdit('isInchargeDetails',false)}
                                    auth={auth}
                                    contract={contractDetails}
                                />
                                :
                                <EBox>
                                    {!contractDetails?.contractLoading ?
                                        <Grid container spacing={2} className='px-16px'>
                                            <Grid item md={6} sm={6} xs={12}  >
                                                <ELabelInputMainGrid label={'Incharge Name'} isNotForm={true} alignlabel={'align-top'}>
                                                    {contractData?.Incharge?.fullname ? contractData?.Incharge?.fullname : NIL}
                                                    {contractData?.Incharge?.employee_code ? ' (' + contractData?.Incharge?.employee_code + ')' : ''}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Contact Number'} isNotForm={true} alignlabel={'align-top'}>
                                                    {contractData?.Incharge?.mobile ? "+91 "+contractData?.Incharge?.mobile : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Email Id'} isNotForm={true}> {/*childrenClassname={'text-dots'}*/}
                                                    {contractData?.Incharge?.email ? contractData?.Incharge?.email : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                        </Grid>
                                        :
                                        <LoadingGrid />
                                    }
                                </EBox>
                            }
                        </ECard>

                        <ECard className='card_design_1 border-05px-border6'>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                className={contractDetails?.contractData?.is_locked || !allowAddEditDelete ? 'mb-8px ' : ''}
                            >
                                <ETypographyCardHeading variant='h5'>Deliverables</ETypographyCardHeading>
                                {allowAddEditDelete && !contractDetails?.contractData?.is_locked &&
                                    <EButtonVariant3 variant="link" className="font-size-14px"
                                        startIcon={<EIcon  icon={AddIcon} />}
                                        disabled={contractDetails?.contractData?.is_locked ? true : false}
                                        onClick={() => HandleEdit('isNewDeliverable',true)}
                                    >
                                        Add Deliverable
                                    </EButtonVariant3>}
                            </Grid>
                            <EDivider className="mb-16px borderColor-staticBorder7" />
                            <EBox>
                                <Grid container spacing={2} className='px-16px'>
                                    <Grid item md={12} sm={12} xs={12}  >
                                        <EScrollbar>
                                            <ETableContainer>
                                                <ETable>
                                                    <EListHeadSmall
                                                        headLabel={TABLE_HEAD}
                                                        rowCount={contractDetails?.contractData?.WorkDetail?.length?contractDetails?.contractData?.WorkDetail?.length:0}
                                                        onRequestSort={() => null}
                                                        authvar={auth}
                                                    />
                                                    {contractDetails?.contractLoading && (
                                                        <ETableBody>
                                                            <ETableRowSmall>
                                                                <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                                    <ECircularProgress color="primary" />
                                                                </ETableCell>
                                                            </ETableRowSmall>
                                                        </ETableBody>
                                                    )}
                                                    {contractDetails?.contractData?.WorkDetail &&
                                                        contractDetails?.contractData?.WorkDetail?.length > 0 && (
                                                            <ETableBody>
                                                                {contractDetails?.contractData?.WorkDetail?.map((row, index) => {
                                                                    const { work_detail,  work_title, updatedAt, status } = row;
                                                                    return (
                                                                        <ETableRowSmall hover key={index + 2} tabIndex={-1}
                                                                         className={contractDetails?.contractData?.WorkDetail?.length == index + 1?'table-border-none':''}>
                                                                            <ETableCell align="left" className="width-20">
                                                                                {index + 1}
                                                                            </ETableCell>
                                                                            <ETableCell align="left" className="w-30per">
                                                                                {work_title ? OnlyFirstLetterCapitalString(work_title) : '-'}
                                                                            </ETableCell>
                                                                            <ETableCell align="left" className=" text-align-justify minWidth-180" >
                                                                                {work_detail ? OnlyFirstLetterCapitalString(work_detail) : '-'}
                                                                            </ETableCell>
                                                                            {contractDetails && contractDetails?.contractData?.is_locked &&
                                                                                <ETableCell align="center" className="text-capitalize">
                                                                                    {updatedAt && status != PENDING_STATUS ?
                                                                                        moment(updatedAt).format('DD/MM/YYYY') : '-'}
                                                                                </ETableCell>}
                                                                            {
                                                                                allowAddEditDelete &&
                                                                                <ETableCell align="right" className="width-20">
                                                                                    {contractDetails && !contractDetails?.contractData?.is_locked ?
                                                                                        <>
                                                                                            <ContractViewActions
                                                                                                auth={auth}
                                                                                                tableName="deliverable"
                                                                                                row={row}
                                                                                                isActionEnable={
                                                                                                    contractDetails?.contractData?.paymentTerm?.length > 0
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                isDisable={
                                                                                                    contractDetails?.contractData?.WorkFace &&
                                                                                                        Object.keys(contractDetails?.contractData?.WorkFace)
                                                                                                            .length > isLockIndex
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                isLocked={contractDetails?.contractData?.is_locked}
                                                                                            />
                                                                                        </>
                                                                                        :
                                                                                        <EChip label={capitalizeCapitalString(status)} />}
                                                                                </ETableCell>
                                                                            }
                                                                        </ETableRowSmall>
                                                                    );
                                                                })}
                                                            </ETableBody>
                                                        )}
                                                    {!contractDetails?.contractLoading &&
                                                        contractDetails?.contractData?.WorkDetail?.length == 0 && (
                                                            <ETableBody>
                                                                <ETableRowSmall>
                                                                    <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                                        <SearchNotFound />
                                                                    </ETableCell>
                                                                </ETableRowSmall>
                                                            </ETableBody>
                                                        )}
                                                </ETable>
                                            </ETableContainer>
                                        </EScrollbar>
                                    </Grid>
                                </Grid>
                            </EBox>
                        </ECard>
                        {isEdit?.isNewDeliverable && (
                            <EModal
                                open={isEdit?.isNewDeliverable}
                                close={() => HandleEdit('isNewDeliverable',false)}
                                headervalue="Add Deliverable"
                            >
                                <AddDeliverableForm
                                    close={() => HandleEdit('isNewDeliverable',false)}
                                    auth={auth}
                                    contract={contractDetails}
                                />
                            </EModal>
                        )}

                        {isUpdatePaymentDetails && (
                            <EModal
                                open={isUpdatePaymentDetails}
                                close={() => SetIsUpdatePaymentDetails(false)}
                                headervalue="Update Payment Details"
                            >
                                <EditModalPaymentDetails
                                    close={() => SetIsUpdatePaymentDetails(false)}
                                    auth={auth}
                                    contract={contractDetails}
                                />
                            </EModal>
                        )}

                        <ECard className='card_design_1 border-05px-border6'>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                className={isUpdatePaymentDetails ||contractDetails?.contractData?.is_locked || !allowAddEditDelete ? 'mb-8px ' : ''}
                            >
                                <ETypographyCardHeading variant='h5'>Payment Details</ETypographyCardHeading>
                                {allowAddEditDelete && !isUpdatePaymentDetails ?
                                    <EIconButton className='px0' onClick={() => SetIsUpdatePaymentDetails(true)} sx={{ display: contractDetails?.contractData?.is_locked == true ? 'none' : 'inline-flex' }}>
                                        <EIcon icon={EditIcon} /></EIconButton> : ""}
                            </Grid>
                            <EDivider className="mb-16px borderColor-staticBorder7" />
                            <ECardBasic className="Bg-card4 border-none mr-16px ml-16px">
                                <EBox>
                                    {!contractDetails?.contractLoading ?
                                        <Grid container spacing={2} >
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={' Amount'} isNotForm={true}>
                                                    {contractData?.total_amount ? numberWithCommas(contractData?.total_amount) : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            {contractDetails?.contractData?.status == TERMINATED_STATUS?
                                                <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Advance'} isNotForm={true}>
                                                    {contractData?.advance_applicable && contractData?.advance_applicable == true ?
                                                        'Yes' : contractData?.advance_applicable == false ? 'No' : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            :""}
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'GST Slab'} isNotForm={true}>
                                                    {contractData?.gst ? contractData?.gst + '%' : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            {contractDetails?.contractData?.status == TERMINATED_STATUS?
                                                <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'No. of Release'} isNotForm={true}>
                                                    {contractData?.WorkDetail && contractData?.WorkDetail?.length>=0 ?
                                                    contractData?.WorkDetail?.length : NIL}

                                                </ELabelInputMainGrid>
                                            </Grid>
                                            :""}
                                            {contractDetails?.contractData?.status != TERMINATED_STATUS?
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Advance'} isNotForm={true}>
                                                    {contractData?.advance_applicable && contractData?.advance_applicable == true ?
                                                        'Yes' : contractData?.advance_applicable == false ? 'No' : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>:''}
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'GST Type'} isNotForm={true}>
                                                    {contractData?.is_igst && contractData?.is_igst == true ? 'IGST' : contractData?.is_igst != null && contractData?.is_igst != true ? 'SGST, CGST' : NIL}

                                                </ELabelInputMainGrid>
                                            </Grid>
                                            {contractDetails?.contractData?.status == TERMINATED_STATUS?
                                                <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Final Settlement'} isNotForm={true}>
                                                    {contractData?.termination_settlement_date && contractData?.termination_settlement_date?
                                                        moment(contractData?.termination_settlement_date).format('DD/MM/YYYY') : NIL}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            :""}

                                        </Grid>
                                        :
                                        <LoadingGrid />
                                    }
                                </EBox>
                            </ECardBasic>
                            <EBox>
                                <Grid container spacing={2} className='px-16px align-flex-end'>
                                {contractDetails?.contractData?.status != TERMINATED_STATUS?
                                    <Grid item md={12} sm={12} xs={12}  >
                                        <Grid container spacing={{xs:1, sm:1, md:5, lg:4, xl:6}}>
                                            <Grid item xs={5} sm={5} md={2} lg={2.5} xl={2.5}>
                                                <ETypography variant="h6" className="theme-color-static line-height-120perc text-dots">{/*  font-size-32px-without-imp font-size-14px */}
                                                    {contractDetails?.contractData?.total_amount
                                                        ? '₹' + numberWithCommas(contractDetails?.contractData?.total_amount, 'isRs')
                                                        : '-'}
                                                </ETypography>
                                                <span className="font-size-14px ">Total Amount</span>
                                            </Grid>
                                            <Grid item xs={7} sm={7} md={5} lg={4.5} xl={4} align='right' className="pr-6px ">
                                                <ETypography variant="h6" className="blue-color-static font-size-18px">
                                                    <span >{PaymentAmount && PaymentAmount >= 0
                                                        ? '₹' + numberWithCommas(PaymentAmount, 'isRs')
                                                        : '₹' + numberWithCommas(0, 'isRs')}{' '} </span>
                                                    <span className="font-size-14px theme-text1-color">Unassign Amount</span>
                                                </ETypography>
                                                <ETypography variant="h6" className="green-color-static font-size-18px">
                                                    {total?.total && PaymentAmount
                                                        ? '₹' + numberWithCommas(total?.total, 'isRs')
                                                        : '₹' + numberWithCommas(0, 'isRs')}{' '}
                                                    <span className="font-size-14px theme-text1-color" >Assigned Amount</span>{' '}
                                                </ETypography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5.5}>
                                                <ELabelInputMainGrid label={'No. of Release'} labelClassname={'font-size-16px'} labelPT='0.5rem'>
                                                    <EBox className="display-flex align-center">
                                                        <ETextFieldSmall
                                                            name="work_detail"
                                                            disabled={
                                                                !allowAddEditDelete ||
                                                                (contractDetails?.contractData?.is_locked == false &&
                                                                    contractDetails?.contractData?.WorkDetail &&
                                                                    Object.keys(contractDetails?.contractData?.WorkDetail).length >=
                                                                    isLockIndex
                                                                    ? false
                                                                    : true)
                                                            }
                                                            value={phaseValue}
                                                            fullWidth
                                                            onChange={(e,value) => {
                                                                SetPhaseValue(e?.target?.value ? e?.target?.value : '');
                                                            }}
                                                            error={
                                                                contractDetails?.contractData?.WorkDetail &&
                                                                    Object.keys(contractDetails?.contractData?.WorkDetail).length < 1
                                                                    ? false
                                                                    : phaseValue && phaseValue > 0
                                                                        && contractDetails?.contractData?.WorkDetail?.length >= Number(phaseValue) ? false
                                                                        : true
                                                            }
                                                        />
                                                        <EButton
                                                            size="large"
                                                            variant="contained"
                                                            disabled={
                                                                !allowAddEditDelete || (contractDetails?.contractData?.is_locked == false &&
                                                                    contractDetails?.contractData?.WorkDetail &&
                                                                    Object.keys(contractDetails?.contractData?.WorkDetail).length > 0
                                                                    ? false
                                                                    : true)
                                                            }
                                                            // disableElevation
                                                            className="button-left-margin height-40px"
                                                            onClick={() => {
                                                                if (phaseValue >= 1) {
                                                                    NewPhase();
                                                                }
                                                            }}
                                                        >
                                                            Save
                                                        </EButton>
                                                    </EBox>
                                                    {
                                                        allowAddEditDelete &&
                                                        <EHelperText className="color-text-error-red">
                                                            {!phaseValue && !contractDetails?.contractLoading ?
                                                                'Please enter No. of Release'
                                                                : !Number(phaseValue) || (phaseValue <= 0 && !contractDetails?.contractLoading)
                                                                    ? 'Please enter valid No. of Release'
                                                                    : contractDetails?.contractData?.WorkDetail?.length < Number(phaseValue) && !contractDetails?.contractLoading
                                                                        ? 'No. of Release should not be more than number of Deliverables' :
                                                                        ''}
                                                        </EHelperText>
                                                    }
                                                </ELabelInputMainGrid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    <>
                                    <TerminationPaymentDetails label='Total Amount' amt={contractData?.total_amount} bgColor='darkBlueStatic-bgcolor'/>
                                    <TerminationPaymentDetails label='Paid Amount' amt={contractData?.paid_amount} bgColor={'greenColor1-bg-static'}/>
                                    <TerminationPaymentDetails label='Final Settlement' amt={contractData?.final_settlement_amount} bgColor={'theme-bg-static'}/>
                                    <TerminationPaymentDetails label='Ineligible Amount' amt={contractData?.ineligible_amount} bgColor={'red-bg-static'}/>
                                    </>
                                }
                                    <Grid item md={12} sm={12} xs={12} >
                                        <EScrollbar>
                                            <ETableContainer>
                                                <ETable>
                                                    <EListHeadSmall
                                                        headLabel={PAYMENT_TABLE_HEAD}
                                                        rowCount={3}
                                                        onRequestSort={() => null}
                                                        authvar={auth}
                                                    />
                                                    {contractDetails?.contractLoading && (
                                                        <ETableBody>
                                                            <ETableRowSmall>
                                                                <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                                    <ECircularProgress color="primary" />
                                                                </ETableCell>
                                                            </ETableRowSmall>
                                                        </ETableBody>
                                                    )}
                                                    {contractDetails?.contractData &&
                                                        contractDetails?.contractData?.WorkFace &&
                                                        contractDetails?.contractData?.WorkFace?.length > 0 && (
                                                            <ETableBody>
                                                                {contractDetails?.contractData?.WorkFace?.map((row, index) => {
                                                                    const { face_no, face_price, faceWorkDetail, remark, face_status, facePayment } = row;
                                                                    let phase_no = contractDetails?.contractData?.advance_applicable ? Number(face_no) + 1 : face_no
                                                                    let release_name = face_no == 0 ? 'Advance Payment' :
                                                                        contractDetails?.contractData?.advance_applicable && (contractDetails?.contractData?.WorkFace.length == face_no + 1) && face_no > 0 ? 'Final Release' :
                                                                            (contractDetails?.contractData?.WorkFace.length == phase_no) && face_no > 0 ? 'Final Release' :
                                                                                contractDetails?.contractData?.advance_applicable && face_no > 0 ? 'Release ' + phase_no :
                                                                                    face_no > 0 ? 'Release ' + phase_no :
                                                                                        '-'
                                                                    return (
                                                                        <ETableRowSmall hover key={index + face_no} tabIndex={-1}
                                                                        className={contractDetails?.contractData?.WorkFace?.length == index + 1?'table-border-none':''}
                                                                        >
                                                                            <ETableCell align="left" className="width-20">
                                                                                {index + 1}
                                                                            </ETableCell>
                                                                            <ETableCell align="left" className="text-capitalize  w-170px">
                                                                                {release_name && face_no == 0?'Advance':release_name? release_name : '-'}
                                                                            </ETableCell>
                                                                            <ETableCell align="right" className="text-capitalize w-170px">
                                                                                {face_price
                                                                                    ? numberWithCommas(Number(face_price))
                                                                                    : numberWithCommas(0)}{' '}
                                                                            </ETableCell>
                                                                            <ETableCell align="left" >
                                                                                <Grid className=" inlineflex-centeraligned " >
                                                                                    {face_no == 0
                                                                                        ? remark
                                                                                        : faceWorkDetail?.length > 0
                                                                                            ? faceWorkDetail &&
                                                                                            faceWorkDetail?.map((row, index) => {
                                                                                                const { work_title, face_id } = row;

                                                                                                if (index < 2) {
                                                                                                    return (
                                                                                                        <span key={index+work_title} > {work_title? index+1+'. '+OnlyFirstLetterCapitalString(work_title):''}<br/></span>
                                                                                                    );
                                                                                                } else if (index == 2) {
                                                                                                    return (
                                                                                                        <ETypography
                                                                                                            key={index + face_id}
                                                                                                            className='font-size-12px'
                                                                                                        >
                                                                                                            + {faceWorkDetail?.length - 2} more
                                                                                                        </ETypography>
                                                                                                    );
                                                                                                }
                                                                                            })
                                                                                            : '-'}
                                                                                </Grid>
                                                                            </ETableCell>
                                                                            
                                                                            <ETableCell align="center" className="text-capitalize">
                                                                                        {remark ?
                                                                                            <ETooltip title={remark} arrow >
                                                                                                <EIconButton sx={{ color: 'grey.500' }}>
                                                                                                    <EIcon icon={InfoIcon} width={23} height={23}></EIcon>
                                                                                                </EIconButton>
                                                                                            </ETooltip>
                                                                                            : '-'}
                                                                                    </ETableCell>
                                                                            {contractDetails && contractDetails?.contractData?.is_locked &&
                                                                                (<>
                                                                                    <ETableCell align="center" className="text-capitalize">
                                                                                        <EChip
                                                                                            label={face_status == COMPLETED_STATUS ? capitalizeCapitalString(COMPLETED_STATUS) : capitalizeCapitalString(face_status)} />
                                                                                    </ETableCell>
                                                                                    <ETableCell align="center" className="text-capitalize">
                                                                                        <EChip
                                                                                            label={facePayment?.transaction_status == COMPLETE_STATUS ? 'Paid' : 
                                                                                            capitalizeCapitalString(!facePayment ? PENDING_STATUS : facePayment?.transaction_status)} />
                                                                                    </ETableCell>
                                                                                </>)}

                                                                            {allowAddEditDelete && !contractDetails?.contractData?.is_locked &&
                                                                                <ETableCell align="center" className="width-20">
                                                                                    <EIconButton
                                                                                        variant="link"
                                                                                        onClick={() =>
                                                                                            SetIsAddNewPhase({
                                                                                                data: row, isOpen: contractDetails?.contractData?.is_locked == false ? true : false,
                                                                                                isViewOpen: contractDetails?.contractData?.is_locked ? true : false, isAdvanceRow: index == 0 && isLockIndex ? true : false,
                                                                                                release_name: release_name
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        {contractDetails?.contractData?.is_locked == false ?
                                                                                            <EIcon icon={EditIcon} className={'blue-color'} width={24} height={24} /> :
                                                                                            <EIcon icon={EditIcon} width={23} height={23} />}
                                                                                    </EIconButton>
                                                                                </ETableCell>}
                                                                        </ETableRowSmall>
                                                                    );
                                                                })}
                                                            </ETableBody>
                                                        )}
                                                    {!contractDetails?.contractLoading &&
                                                        contractDetails?.contractData?.WorkFace?.length == 0 && (
                                                            <ETableBody>
                                                                <ETableRowSmall>
                                                                    <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                                        <SearchNotFound />
                                                                    </ETableCell>
                                                                </ETableRowSmall>
                                                            </ETableBody>
                                                        )}
                                                </ETable>
                                            </ETableContainer>
                                        </EScrollbar>
                                    </Grid>
                                </Grid>
                            </EBox>
                        </ECard>
                        {/*Info: Terms and conditions Component */}
                        <ContractViewTaCComponent contractDetails={contractDetails} auth={auth} id={base64.decode(params.id)} 
                         isEdit={isEdit} 
                         HandleEdit={HandleEdit}
                         allowEdit={allowAddEditDelete}
                         />

                        {contractDetails?.contractData?.is_locked == true &&
                            <Grid container className='font-size-14px blue-color-static'>
                                <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
                                    <span className="bold-600">Lock Date:</span>
                                    <span > {contractDetails?.contractData?.updated_at ? moment(contractDetails?.contractData?.updated_at).format('DD/MM/YYYY') : '-'} </span>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <span className="bold-600" >Locked By: </span>
                                    <span >{contractDetails?.contractData?.LockedBy ? contractDetails?.contractData?.LockedBy?.fullname + ' - ' + contractDetails?.contractData?.LockedBy?.employee_code : '-'}</span>
                                </Grid>
                            </Grid>}
                        <Grid container className="justify-content-flex-end">
                            <FormikProvider value={formikAddContract} >
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <EButton
                                        disabled={(!PaymentAmount || PaymentAmount == 0) &&
                                            phaseValue != 0 &&
                                            isLock == true &&
                                            contractDetails?.contractData?.is_locked == false
                                            ? false
                                            : true}
                                        sx={{ display: contractDetails?.contractData?.is_locked == false ? 'inline-flex' : 'none' }}
                                        variant="contained"
                                        onClick={() => setIsLockContract({ ...IsLockContract, open: true })}
                                    >
                                        Lock Contract
                                    </EButton>
                                </Form>
                            </FormikProvider>
                        </Grid>
                        {IsLockContract?.open && (
                            <EModal
                                open={IsLockContract?.open}
                                close={() => setIsLockContract({ ...IsLockContract, open: false })}
                                headervalue={'Lock Contract: Confirmation'}
                            >
                                <Grid container spacing={2} >
                                    <Grid item className="pt-0">
                                        <ETypography variant="h5" className="font-size-16px bold-400"> 
                                        Are you sure you want to lock contract {' '}
                                            <span className="theme-main-text-color-bold text-decoration-underline">{contractDetails?.contractData?.contract_title ? contractDetails?.contractData?.contract_title : 'this contract?'}  </span> {' '}?
                                           <br/>
                                            Please note that once the contract is locked, no amendments can be done.
                                        </ETypography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                                        <EButtonOutlined size="large" variant="outlined" onClick={() => setIsLockContract({ ...IsLockContract, open: false })} > No </EButtonOutlined>
                                        <ELoadingButton size="large" type="submit" variant="contained" onClick={() => {
                                            dispatch(LockContractView(auth?.authtoken, base64.decode(params.id)));
                                            setIsLockContract({ ...IsLockContract, open: false });
                                        }} className='button-left-margin'> Yes </ELoadingButton>
                                    </Grid>
                                </Grid>
                            </EModal>
                        )}

                        {isAddNewPhase?.isOpen && (
                            <EModal
                                open={isAddNewPhase?.isOpen}
                                close={() => SetIsAddNewPhase({ ...isAddNewPhase, isOpen: false })}
                                headervalue={isAddNewPhase.release_name}
                            >
                                <PhaseForm
                                    close={() => SetIsAddNewPhase({ ...isAddNewPhase, isOpen: false })}
                                    auth={auth}
                                    deliverables={contractDetails?.contractData?.WorkDetail}
                                    data={contractDetails?.contractData}
                                    //   contract_id={id}
                                    eligiblePayment={PaymentAmount}
                                    row={isAddNewPhase?.data}
                                />
                            </EModal>
                        )}
                    
            </EBoxPage>
            </LocalizationProvider>
        </Page>
    )

};

export default ContractView;

// INFO: This function is returning colorful grids for payment block in Terminated contract
const TerminationPaymentDetails = (props) => {
    const { label, amt, bgColor } = props
    return (
        <Grid item xl={3} lg={3} md={6} sm={6} xs={6} >
        <ECardBasic className={bgColor+' mb-0px borderRadius-4px border-none'} >
            <ETypography className="color-staticPrimaryLight ">
                <span className="font-size-20px bold-600">₹</span>
                <span className="font-size-24px bold-600">{amt && amt >= 0
                    ?  numberWithCommas(amt, 'isRs')
                    :  numberWithCommas(0, 'isRs')}
                </span>
                <br/>
                <span className="font-size-14px ">{label}</span>
            </ETypography>
        </ECardBasic>
        </Grid>
    )
}
