import { styled } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';



/**
 * [2023-04-14]
 * changes by: Purva Sharma
 * Description:-Added Signup prop , used in signup form i.e in password field
 **/

export  const ETooltip = styled(({ className,signup, ...props }) => (
    <Tooltip  {...props} classes={{ popper: className }} />
))(({ theme,signup }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        "&::before": {
            backgroundColor:signup ? theme.palette.background.signupTooltip : theme.palette.text.primary,
            boxShadow:signup ? theme.shadows[1] : '',
          }
      },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: signup ? theme.palette.background.signupTooltip : theme.palette.text.primary,
        color:signup ? theme.palette.text.default :  theme.palette.text.primaryOpposite,
        fontWeight: 400,
        boxShadow: theme.shadows[1],
        fontSize: 12,
        padding: 9,
        minWidth: '80px',
        maxWidth:signup ? '345px' : '300px',
        align:'center',
        borderRadius: 6,
        display:'flex',
        justifyContent: 'center'
    },
}));