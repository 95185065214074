
import {
    COMPANY_SETTING_LOADING, COMPANY_SETTING_FAILURE, COMPANY_SETTING_SUCCESS
} from '../constants';


const INITIAL_STATE = {
    companySettingLoading: false,
    companySettingData: {},
    companySettingSuccess: false,
    companySettingMessage: null,
};

const CompanySettingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COMPANY_SETTING_LOADING:
            return {
                companySettingLoading: true,
                companySettingData: {},
            };

        case COMPANY_SETTING_SUCCESS:
            return {
                companySettingLoading: false,
                companySettingSuccess: true,
                companySettingData: action.payload.data,
                companySettingMessage: action.payload.message,
            };

        case COMPANY_SETTING_FAILURE:
            return {
                companySettingLoading: false,
                companySettingSuccess: false,
                companySettingData: {},
                companySettingMessage: action.payload.message,
            };


        default:
            return state;
    }
};

export default CompanySettingReducer;
