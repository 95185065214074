import React from 'react';
import { Grid } from '@mui/material';
import { ECardBorderLess } from '../../../BaseComponents/ECard';
import { ELabelInputMainGrid } from '../../../BaseComponents/EGrid';
import { ETextFieldSmall } from '../../../BaseComponents/ETextField';
import { ReturnBoolean } from '../../../utils/CommonFunctions';
import { ECustomizedRadioWithTitle } from '../../../BaseComponents';
import { OPTIONS } from '../../../pages/Settings/AttendanceSettings';



export const IPSettings = ({ formik, disableFields }) => {
  const { values, errors, touched, getFieldProps, setFieldValue } = formik;
  return (
    <>
      <ECustomizedRadioWithTitle
        label="Check-in/Check-out with static IP address"
        extraLabel="Talent will only be able to mark attendance with static IP address of the organization. If not, they can check-in/check-out from anywhere."
        options={OPTIONS}
        value={values?.allow_ip_check}
        name="allow_ip_check"
        onChange={(value) => setFieldValue('allow_ip_check', value)}
        error={errors?.allow_ip_check || ""}
        disabled={disableFields}
      />
      {
        values?.allow_ip_check
        &&
        <ECardBorderLess sx={{ padding: "18px 24px", mt: 2 }}>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={6} xs={6} className="align-center">
              <ELabelInputMainGrid
                label={"IP Name"}
                alignlabel={'align-center'}
                labelPT={'0px'}
              >
                <ETextFieldSmall
                  fullWidth
                  size='small'
                  autoComplete="off"
                  placeholder="Connection Name"
                  {...getFieldProps("connection_name")}
                  value={values?.connection_name || ""}
                  error={Boolean(touched.connection_name && errors.connection_name)}
                  helperText={touched.connection_name && errors.connection_name} 
                  disabled={disableFields}
                />
              </ELabelInputMainGrid>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className="align-center">
              <ELabelInputMainGrid
                label={"IP Address"}
                alignlabel={'align-center'}
                labelPT={'0px'}
              >
                <ETextFieldSmall
                  fullWidth
                  size='small'
                  autoComplete="off"
                  placeholder="000.000.000.000"
                  {...getFieldProps("ip_address")}
                  value={values?.ip_address || ""}
                  error={Boolean(touched.ip_address && errors.ip_address)}
                  helperText={touched.ip_address && errors.ip_address}
                  disabled={disableFields}
                />
              </ELabelInputMainGrid>
            </Grid>
          </Grid>
        </ECardBorderLess>
      }
    </>
  );
};
