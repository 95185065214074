import Swal from 'sweetalert2';

// visit link https://sweetalert2.github.io/
export const Success = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    icon:'success',
    background:'#00AB55',
    padding:'0px 2px 0px 10px',
    showConfirmButton: false,
  showCloseButton: true,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  /**
 * [2022-03-25]
 * changes by: Purva Sharma
 * Description:- Removed the timer from modal
 **/
  export const SuccessCenter = Swal.mixin({
    width: 430,
    //custom class is those classes that are  written inside global css for this particular Swal popup
    customClass:{
      container:'swal-container-center',
      htmlContainer: 'swal2-html-container-center',
      closeButton:'swal2-close-center',
      popup:'swal2-popup-center',
      content:'swal-content-center',
    },
    showConfirmButton: false,
    showCloseButton: true,
    // timer: 6000,
    timerProgressBar: true,
    backdrop: `
    rgba(0, 0, 0, 0.57)
    `,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  export const Errors = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    icon:'error',
    iconColor:'white',
    background:'#FF4842',
    padding:'0px 2px 0px 10px',
    showConfirmButton: false,
    showCloseButton: true,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  export const Warnings = Swal.mixin({
    toast: true,
    position: 'top-end',
    icon:'warning',
    background:'text.warning',
    padding:'0px 2px 0px 10px',
    showConfirmButton: false,
    timer: 6000,
    iconColor:'white',
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  
  
