import { Grid } from "@mui/material";
import moment from "moment";
import { EBox } from "../../../BaseComponents/EBox";
import { ECard } from "../../../BaseComponents/ECard";
import { EDivider } from "../../../BaseComponents/EDivider";
import { ELabelInputMainGrid, LoadingGrid } from "../../../BaseComponents/EGrid";
import { ETypographyCardHeading } from "../../../BaseComponents/ETypography";
import { NIL } from "../../../constants";
import { capitalizeCapitalString } from "../../../utils/formatText";

export default function ViewInchargeCard(props) {
    const { contractDetails, contractData } = props
    return (
        <ECard className='card_design_1 theme-color-border modalShadow'>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="mb-8px"
            >
                <ETypographyCardHeading variant='h5'>Contract Information </ETypographyCardHeading>
                {/* <ETypographyCardHeading variant='h5'> {contractData?.contract_title ? contractData?.contract_title : NIL}{contractData?.contract_code ? ' - ' + contractData?.contract_code : ''} </ETypographyCardHeading> */}
            </Grid>

            <EDivider className="mb-16px" />

            <EBox >
                {!contractDetails?.contractLoading ?
                    <Grid container spacing={2} className='px-16px'>

                         {/* Contract Title */}
                         <Grid item md={12} sm={12} xs={12} >
                            <ELabelInputMainGrid label={'Contract Name'}  isfullgrid={true} isNotForm={true}>
                                {
                                    contractData?.contract_title ?  contractData?.contract_title : NIL
                                }
                            </ELabelInputMainGrid>
                        </Grid>


                         {/* Tenure */}
                         <Grid item md={6} sm={6} xs={12}  >
                            <ELabelInputMainGrid label={'Tenure'}  isNotForm={true}>
                                {contractData?.contract_start_date ? moment(contractData?.contract_start_date).format('DD/MM/YYYY') : ''}
                                {contractData?.contract_end_date ? ' to ' + moment(contractData?.contract_end_date).format('DD/MM/YYYY') : ''}
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Freelancer Name */}
                        <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Freelancer Name'}  isNotForm={true}>
                                {
                                    contractData?.Freelancer?.freelancer_name && contractData?.Freelancer?.freelancer_code  ? contractData?.Freelancer?.freelancer_name +` ( ${contractData?.Freelancer?.freelancer_code} ) `: NIL
                                }
                            </ELabelInputMainGrid>
                        </Grid>

                       

                          {/* Status */}
                          <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Status'} isNotForm={true}>
                                {contractData?.status ? capitalizeCapitalString(contractData?.status)  : NIL}
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Deliverables */}
                        <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Deliverables'}  isNotForm={true}>
                                {contractData?.completed_deliverables >= 0 ? contractData?.completed_deliverables : NIL}
/
                                <span className="theme-color-static bold-700">{contractData?.total_deliverables ?  contractData?.total_deliverables : ''}</span>
                            </ELabelInputMainGrid>
                        </Grid>
                    </Grid>
                    :
                    <LoadingGrid />
                }
            </EBox>
        </ECard>
    )
}