import { Avatar, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import { ACTIVE_USER, INACTIVE_USER, RELEASED_USER, ROW_SIZE_PER_PAGE } from "../../constants";
import base64 from 'base-64'
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import { getInitials, HandlePhoto } from "../../utils/CommonFunctions";
import EHidden from "../../BaseComponents/EHidden";
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline-rounded';
import { EIcon } from "../../BaseComponents/EIcon";
import moment from "moment";
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import RestoreIcon from '@iconify/icons-material-symbols/restore-page-outline-rounded';
import SearchNotFound from "../SearchNotFound";
import { useState } from "react";
import EModal from "../../BaseComponents/EModal";
import { ETypography } from "../../BaseComponents/ETypography";
import { EButtonOutlined, EIconButton, ELoadingButton } from "../../BaseComponents/EButtons";
import { useDispatch } from "react-redux";
import { restoreReleasedTalent } from "../../action/TalentActions";
import { ETooltip } from "../../BaseComponents/ETooltip";
import MoreIcon from '@iconify/icons-material-symbols/more-vert'
import EMenuPopover, { EMenuIcon, EMenuItem } from "../../BaseComponents/EMenuPopover";

// This is just for Talent List
const TABLE_HEAD = [
    { id: 'Picture', label: '', alignRight: 'center' },
    { id: 'Talent Code', label: 'Talent Code', alignRight: false, hideLabelCriteria: 'lgDown' },
    { id: 'Talent Name', label: 'Talent Name', alignRight: false },
    { id: 'Contact Detail', label: 'Contact Details', alignRight: false },
    { id: 'Department', label: 'Department', alignRight: false },
    { id: 'Designation', label: 'Designation', alignRight: false, hideLabelCriteria: 'lgDown' },
];

function TalentListGrid(props) {
    const { employeeListData, page, rowsPerPage, userStatus, onPageChange, onRowsPerPageChange, token, loading, authPermission, setting } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [isRestore, setIsRestore] = useState({ open: false, data: null })
    const [anchorEl, setAnchorEl] = useState(null);
    const [isTooltipId, setIsTooltipId] = useState(null)
    const pgCount = page;//to fix the pagination issues on 28 jan 01,23

    const companyAccountSetting = setting?.accountSettingData?.companySetting;
    const allowed_talent = companyAccountSetting?.company_data?.CompanyActivePlan?.allowed_talent;
    const active_employee_count = companyAccountSetting?.active_employee_count;

if(authPermission && TABLE_HEAD.length!=7){//(new updated on - 24-4-23, according to talent setting)
    TABLE_HEAD.push({ id: 'Action', label: 'Action', alignRight: 'center' })
}
    // Redirects to view page
    const ViewProfile = (id) => {
        navigate(`/talent/${base64.encode(id)}`)
    }

    // INFO: ARCHIVED_REALEASED_TABLE_HEAD is the Table head for Released & Archived list
    const ARCHIVED_REALEASED_TABLE_HEAD = TH_Function_Table_Head(userStatus);

    // INFO: This function is hit when we want restore a released talent
    const handleRestore = () => {
        dispatch(restoreReleasedTalent(token, isRestore?.data?.id, navigate));
        setIsRestore({ ...isRestore, open: false });
    }
    // Handles onClick Restore Button  
    const onClickRestoreButton = (data) => {
        setIsRestore({ open: true, data: data })
    }

    // Handles onClick of 3 dot menu Button  
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Handles close of 3 dot menu Button  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
   
    return (
        <Grid container my={0} >
            <Grid item xs={12}>
                <EScrollbar>
                    <ETableContainer>
                        {employeeListData?.rows?.length == 0 ?
                            <SearchNotFound />
                            : loading ?
                                <ECircularProgress color="primary" />
                                :
                                <ETable>
                                    {employeeListData?.rows && employeeListData?.rows?.length > 0 &&
                                        <>
                                            <EListHead
                                                headLabel={userStatus == ACTIVE_USER ? TABLE_HEAD : ARCHIVED_REALEASED_TABLE_HEAD}
                                                rowCount={employeeListData?.rows ? employeeListData?.rows?.length : 0}
                                                onRequestSort={() => null}
                                            />
                                            <ETableBody>
                                                {employeeListData?.rows && employeeListData?.rows?.map((row, index) => {
                                                    const { id, releasedBy, release_date, trash_date, status_ref_remark, fullname, email, mobile, designationInfo, departmentInfo, employee_code, employeeInfo, company_id } = row;
                                                    return (
                                                        <ETableRow
                                                            hover
                                                            key={index}
                                                            tabIndex={-1}
                                                        >
                                                            <ETableCell align="center" sx={{ padding: '16px', width: '20px' }}>
                                                                <Avatar title={fullname} alt={fullname}
                                                                    src={HandlePhoto(employeeInfo?.personal_photo, employeeInfo?.personal_gender)}
                                                                    key={index} className='cursor-pointer list-avatar-css'>
                                                                    {!employeeInfo?.personal_gender &&
                                                                        <span className="font-size-14px theme-color-static">{getInitials(fullname)}</span>}
                                                                </Avatar>
                                                            </ETableCell>

                                                            {userStatus == ACTIVE_USER ?
                                                                <>
                                                                    <EHidden width="lgDown">
                                                                        <ETableCell align="left" className="minWidth-140" >
                                                                            {employee_code}
                                                                        </ETableCell>
                                                                    </EHidden>
                                                                    <ETableCell align="left" className="minWidth-140">
                                                                        {fullname}
                                                                        <EHidden width="lgUp">
                                                                            <br />{employee_code}
                                                                        </EHidden>
                                                                    </ETableCell>
                                                                    <ETableCell align="left">
                                                                        {email}<br />{'+91-' + mobile}
                                                                    </ETableCell>
                                                                    <ETableCell align="left" >
                                                                        {departmentInfo?.department_name ? departmentInfo?.department_name : ''}
                                                                    </ETableCell>
                                                                    <EHidden width="lgDown">
                                                                        <ETableCell>
                                                                            {designationInfo?.designation_name ? designationInfo?.designation_name : ''}
                                                                        </ETableCell>
                                                                    </EHidden>
                                                                </> :
                                                                <>
                                                                    <ETableCell align="left">
                                                                        <span className="bold-600">{fullname}</span>
                                                                        <br />
                                                                        {designationInfo?.designation_name ? '(' + designationInfo?.designation_name + ')' : ''}
                                                                    </ETableCell>
                                                                    {userStatus != RELEASED_USER &&
                                                                        <ETableCell align="left">
                                                                            {trash_date ? moment(trash_date).format('DD/MM/YYYY') : '-'}
                                                                        </ETableCell>}
                                                                    <ETableCell align="left">
                                                                        {release_date ? moment(release_date).format('DD/MM/YYYY') : '-'}
                                                                    </ETableCell>
                                                                    <ETableCell align="left">
                                                                        {releasedBy ? releasedBy?.fullname : '-'}
                                                                    </ETableCell>
                                                                    {userStatus == RELEASED_USER &&
                                                                        <ETableCell align="center">
                                                                            <EHidden width="lgDown">
                                                                                <ETooltip title={status_ref_remark} arrow >
                                                                                    <EIcon icon={InfoIcon} />
                                                                                </ETooltip>
                                                                            </EHidden>
                                                                            <EHidden width="lgUp">
                                                                                <ETooltip title={status_ref_remark} arrow
                                                                                    onMouseOver={() => setIsTooltipId(row.id)}
                                                                                    onMouseLeave={() => setIsTooltipId(null)}
                                                                                    onClick={() => setIsTooltipId(row.id)}
                                                                                    open={isTooltipId == row.id}
                                                                                >
                                                                                    <EIcon icon={InfoIcon} />
                                                                                </ETooltip>
                                                                            </EHidden>
                                                                        </ETableCell>
                                                                    }
                                                                </>
                                                            }
                                                            {authPermission && 
                                                            <ETableCell align="center" className="minWidth-80">
                                                                <EHidden width="lgDown">
                                                                        {userStatus == RELEASED_USER &&
                                                                            <ETooltip title="Restore Profile" arrow>
                                                                                <span>
                                                                                    <EIconButton className=" p0_not_imp pr-6px "
                                                                                        onClick={() => onClickRestoreButton({ id: row.id, name: row.label })}
                                                                                        disabled={active_employee_count >= allowed_talent}//if allowed talent count is over
                                                                                    >
                                                                                        <EIcon icon={RestoreIcon} /> {/* Restore button for released list */}
                                                                                    </EIconButton>
                                                                                </span>
                                                                            </ETooltip>
                                                                        }
                                                                    <ETooltip title="View" arrow>
                                                                        <EIconButton className=" p0" onClick={() => ViewProfile(row.id)} >
                                                                            <EIcon icon={VisibilityIcon} />{/* View profile button for all three list */}
                                                                        </EIconButton>
                                                                    </ETooltip>
                                                                </EHidden>

                                                                <EHidden width="lgUp">
                                                                    {userStatus != RELEASED_USER ?
                                                                        <EIconButton className="p0" onClick={() => ViewProfile(row.id)}>
                                                                            <EIcon icon={VisibilityIcon} />{/* Only View profile button for small screen */}
                                                                        </EIconButton>
                                                                        : <>
                                                                            <EIconButton className="p0" onClick={handleClick} >
                                                                                <EIcon icon={MoreIcon} /> {/*More Icon button for small screen, released list */}
                                                                            </EIconButton>
                                                                            <EMenuPopover
                                                                                open={open}
                                                                                onClose={handleClose}
                                                                                anchorEl={anchorEl}
                                                                                sx={{ width: 150, top: 40, right: 40 }}
                                                                            >
                                                                                <EMenuItem sx={{ typography: 'body2', py: 1, px: 2.5 }} onClick={() => ViewProfile(row.id)}>
                                                                                    <EMenuIcon icon={VisibilityIcon} /> View
                                                                                </EMenuItem>
                                                                                <EMenuItem
                                                                                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                                                                                    onClick={() => onClickRestoreButton({ id: row.id, name: row.label })}
                                                                                    disabled={active_employee_count >= allowed_talent}//if allowed talent count is over
                                                                                >
                                                                                    <EMenuIcon icon={RestoreIcon} /> Restore
                                                                                </EMenuItem>
                                                                            </EMenuPopover>
                                                                        </>}
                                                                </EHidden>
                                                            </ETableCell>
                                                        }
                                                        </ETableRow>
                                                    );
                                                })}
                                            </ETableBody>
                                        </>
                                    }
                                </ETable>}
                    </ETableContainer>
                </EScrollbar>
            </Grid>
            {employeeListData?.rows && employeeListData?.rows?.length > 0 &&
                <Grid item xs={12}
                    justifyContent="flex-end"
                    alignItems="center"
                    className="display-flex"
                >
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={employeeListData?.count ? employeeListData?.count : 10}
                        rowsPerPage={rowsPerPage}
                        page={pgCount}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Grid>
            }

            {isRestore?.open && (
                <EModal
                    open={isRestore?.open}
                    close={() => setIsRestore({ ...isRestore, open: false })}
                    headervalue={'Restore Talent: Confirmation'}

                >
                    <Grid container spacing={2} >
                        
                            <ETypography className="pl-16px pt-8px">
                                Are you sure you want to restore{' '}
                                <span className="theme-main-text-color-bold text-decoration">
                                    {isRestore?.data?.name}
                                </span>{' '}
                                to Talent List?
                            </ETypography>
                        
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                            <EButtonOutlined size="large" variant="outlined"
                                onClick={() => setIsRestore({ ...isRestore, open: false })} > No </EButtonOutlined>
                            <ELoadingButton size="large" type="submit" variant="contained" onClick={() => {
                                handleRestore()
                            }} className='button-left-margin'> Yes </ELoadingButton>
                        </Grid>
                    </Grid>
                </EModal>
            )}


        </Grid>
    );
}

export default TalentListGrid;


const TH_Function_Table_Head = (userStatus) => {
    let tblHead = [
        { id: 'Picture', label: '', alignRight: 'center' },
        { id: 'Talent Name', label: 'Talent Name', alignRight: false }
    ];
    if (userStatus === INACTIVE_USER) {
        tblHead.push({ id: 'Archive Date', label: 'Archive Date', alignRight: false });
    }
    tblHead.push({ id: 'Release Date', label: 'Release Date', alignRight: false },
        { id: 'Released By', label: 'Released By', alignRight: false })

    if (userStatus === RELEASED_USER) {
        tblHead.push({ id: 'Reason', label: 'Reason', alignRight: 'center' });
    }
    tblHead.push({ id: 'Action', label: 'Action', alignRight: 'center' })

    return tblHead;
};

