
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { ETable, ETableBody, ETableCell, ETableContainer, ETableRowSmall } from '../../BaseComponents/ETable';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import {
    TALENT_PAYMENT_LOG_DOWNLOAD_URL, DOWNLOAD_PDF, DOWNLOAD_CSV, PAYMENT_CATEGORY_SALARY,
    PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_OTHER, PENDING_STATUS, COMPLETE_STATUS, APPROVED_STATUS, CANCELLED_STATUS, PAYMENT_CATEGORY_ALL, PAYMENT_CATEGORY
} from '../../constants';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import FilePDF from '@iconify/icons-material-symbols/picture-as-pdf-rounded';
import FileCSV from '@iconify/icons-material-symbols/file-present-rounded';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import { Icon } from '@iconify/react';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { EDesktopDatePicker } from '../../BaseComponents/EDatePicker';
import EListHeadSmall from '../../BaseComponents/ETable';
import SearchNotFound from '../SearchNotFound';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import { TalentPaymentList } from '../../action/TalentActions';
import { DownLoadFile } from "../../action/AdminAction";
import EMenuPopover, { EMenuIcon, EMenuItem } from '../../BaseComponents/EMenuPopover';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ECard } from '../../BaseComponents/ECard';
import { EDividerCard } from '../../BaseComponents/EDivider';
import { EIcon, EIconInputAdornment } from '../../BaseComponents/EIcon';
import { ETypographyCardHeading } from '../../BaseComponents/ETypography';
import { EIconButton } from '../../BaseComponents/EButtons';
import { EInputAdornment, ETextFieldSearch } from "../../BaseComponents/ETextField";
import EChip from '../../BaseComponents/EChip';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { onKeyDown } from '../../utils/CommonFunctions';
import CancelIcon from '@iconify/icons-material-symbols/cancel-outline-rounded';
import { getPaymentCategory } from '../../utils/getPaymentCategory';


const TABLE_HEAD = [
    { id: 'Date', label: 'Date', alignRight: false },
    { id: 'Payment Category', label: 'Payment Category', alignRight: 'center' },
    { id: 'Amount', label: 'Amount', alignRight: true },
    { id: 'Status', label: 'Status', alignRight: 'center' },
    { id: 'Remark', label: 'Remark', alignRight: 'center' },
];

/**
 * [2022-11-24]
 * Created by:- Aanchal Sahu
 * Description:- designed this component according to view profile
 **/
function TalentEditPayments(props) {
    const { auth, employee,setting } = props;
    const employeePayment = useSelector((state) => state.EmployeePaymentReducer);
    const Theme = useSelector((state) => state.ThemeReducer);
    const dispatch = useDispatch();
    const [PaymentFilter, setPaymentFilter] = React.useState('');
    const [PaymentCategoryFilter, setPaymentCategoryFilter] = React.useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

     // Setting the payment category as per settings
    //  const PAYMENT_CATEGORY_NEW = setting?.paymentSettingData?.enable_reimbursement ? PAYMENT_CATEGORY_ALL : PAYMENT_CATEGORY_ALL.filter((item)=>item.id !== PAYMENT_CATEGORY_REIMBURSEMENT )
  



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(() => {
        let PaymentCategoryFilterID = PaymentCategoryFilter ? PaymentCategoryFilter?.id : "";
        // resetForm();
        if (employee?.id) {
            dispatch(TalentPaymentList(auth.authtoken, employee?.id, PaymentFilter, PaymentCategoryFilterID, "request_self", page, rowsPerPage))
        }
    }, [PaymentFilter, PaymentCategoryFilter, page, rowsPerPage, employee]);

    useEffect(() => {
        if (employeePayment?.employeePaymentAddSuccess) {
            let PaymentCategoryFilterID = PaymentCategoryFilter ? PaymentCategoryFilter?.id : "";
            // resetForm();
            dispatch(TalentPaymentList(auth.authtoken, employee?.id, PaymentFilter, PaymentCategoryFilterID, "request_self", page, rowsPerPage))
        }
    }, [employeePayment?.employeePaymentAddSuccess]);

    function numberWithCommas(num) {

        let number = Number(num)
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR" }).format(number).replace(/^(\D+)/, '$1 ');
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const DownloadReport = (value) => {
        let url = TALENT_PAYMENT_LOG_DOWNLOAD_URL;
        let file_name = "Payment_log";
        if (value != null) { url += `?download_format=${value}&employee_id=${employee?.id}&page_name=request_self`; }
        if (PaymentCategoryFilter?.id != null) { url += `&payment_category=${PaymentCategoryFilter?.id}`; }
        if (PaymentFilter) {
            url += `&date=${PaymentFilter}`;
            file_name = file_name + "_" + moment(PaymentFilter).format('MMM-YYYY');
        }
        DownLoadFile(auth.authtoken, url, file_name);
        setAnchorEl(null);
    }

    return (
        <>
            <ECard sx={{ padding: '20px', height: 'auto', textAlign: 'left', mx: "auto" }}>
                <Grid container display="row" justifyContent="space-between" alignItems='center'>
                    <Grid item>
                        <ETypographyCardHeading variant='h5'>Payment Log</ETypographyCardHeading>
                    </Grid>
                    <Grid xs={12} lg={12} item className='pb-1rem '>
                        <EDividerCard className='margin-top-05' />
                    </Grid>
                    <Grid className='pb-1rem ' item xs={10} sm={11} md={11} lg={11} xl={11}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={5} md={4} lg={4} xl={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                    <EDesktopDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        maxDate={new Date()}
                                        value={PaymentFilter ? PaymentFilter : null}
                                        onChange={(newValue) => {
                                            setPaymentFilter(newValue ? moment(newValue).format('YYYY/MM/DD') : null);
                                        }}
                                        fullWidth
                                        placeholder="dd/MM/yyyy"
                                        animation={false}
                                        renderInput={(params) => (
                                            <div style={{ position: "relative", display: "inline-block" }} >
                                                <ETextFieldSearch size='small' {...params} fullWidth />
                                                {PaymentFilter &&
                                                    <EInputAdornment className='clearIconDesktopDatePicker'
                                                        onClick={() => setPaymentFilter(null)} color='primary.main'
                                                    >
                                                        <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                                                    </EInputAdornment>
                                                }
                                            </div>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} sm={5} md={4} lg={4} xl={3}>

                                <EAutocomplete
                                    name="Search By payment category"
                                    fullWidth
                                    // options={PAYMENT_CATEGORY_NEW?.length > 0 ? PAYMENT_CATEGORY_NEW : []}
                                    options={ 
                                        setting?.paymentSettingData?.enable_reimbursement && setting?.paymentSettingData?.enable_advance_salary ?
                                        getPaymentCategory('ENABLE_BOTH',PAYMENT_CATEGORY_ALL):
                                         setting?.paymentSettingData?.enable_reimbursement ?
                                        getPaymentCategory('ENABLE_REIMBURSEMENT',PAYMENT_CATEGORY_ALL)
                                   :   setting?.paymentSettingData?.enable_advance_salary ?
                                        getPaymentCategory('ENABLE_ADVANCE_SALARY',PAYMENT_CATEGORY_ALL) :  getPaymentCategory('DISABLE_BOTH',PAYMENT_CATEGORY_ALL)
                                   
                            }
                                    value={Object.keys(PaymentCategoryFilter).length > 0 ? PaymentCategoryFilter : null}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(e, value) => { setPaymentCategoryFilter(value ? { label: value?.label, id: value?.id } : {}); }}
                                    renderInput={params => (
                                        <ETextFieldSearch
                                            {...params}
                                            name='duration'
                                            placeholder="Search By Payment Category"
                                            onKeyDown={onKeyDown}
                                            fullWidth size='small'
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className='pb-1rem ' item  xs={1} sm={0.8} md={0.5} lg={0.5} xl={0.5}>
                        <ETooltip title={'Download'} arrow>
                            <EIconButton onClick={handleClick}>
                                <Icon icon={FileDownloadIcon} width={24} height={24} />
                            </EIconButton>
                        </ETooltip>
                        <EMenuPopover
                            open={open}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            sx={{ width: 180, top: 40 }}
                        >
                            <EMenuItem sx={{ typography: 'body2', py: 1, px: 2.5 }} onClick={() => DownloadReport(DOWNLOAD_PDF)} >
                                <EMenuIcon icon={FilePDF} /> PDF </EMenuItem>
                            <EMenuItem sx={{ typography: 'body2', py: 1, px: 2.5 }} onClick={() => DownloadReport(DOWNLOAD_CSV)} >
                                <EMenuIcon icon={FileCSV} /> CSV </EMenuItem>
                        </EMenuPopover>
                    </Grid>
                </Grid>
                <EScrollbar>
                    <ETableContainer >
                        <ETable>
                            <EListHeadSmall
                                headLabel={TABLE_HEAD}
                                onRequestSort={() => null}
                                Theme={Theme}
                            />
                            {employeePayment?.employeePaymentLoading ?
                                <ETableBody>
                                    <ETableRowSmall>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRowSmall>
                                </ETableBody> :
                                <ETableBody>
                                    {employeePayment?.employeePaymentData && employeePayment?.employeePaymentData?.rows?.map((row, index) => {
                                        const { transaction_category, transaction_status, pay_amount, transaction_date, accountant_remark, payrollData } = row;
                                        return (
                                            <ETableRowSmall
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                                role="checkbox"
                                            >
                                                <ETableCell align='left' className='width-20p'>{transaction_date ? moment(transaction_date).format('DD/MM/YYYY') : '-'}</ETableCell>
                                                <ETableCell align='center' sx={{ width: '200px !important' }}>
                                                    {transaction_category && transaction_category == PAYMENT_CATEGORY_ADVANCE
                                                        ? 'Advance'
                                                        : transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
                                                            ? 'Reimbursement'
                                                            : transaction_category == PAYMENT_CATEGORY_OTHER
                                                                ? 'Other'
                                                                : transaction_category == PAYMENT_CATEGORY_SALARY ? 'Salary' : '-'}
                                                    <span style={{ fontWeight: "bold" }}>{payrollData?.month_date ? '-' + moment(payrollData?.month_date).format('MMM yyyy') : ""}</span>
                                                </ETableCell>
                                                <ETableCell align="right" className='minWidth-140'>{numberWithCommas(pay_amount)}</ETableCell>
                                                <ETableCell align="center">
                                                    {transaction_status &&
                                                        <EChip
                                                            label={
                                                                transaction_status == PENDING_STATUS ? "Pending" :
                                                                    transaction_status == COMPLETE_STATUS ? "Paid" :
                                                                        transaction_status == APPROVED_STATUS ? "Approved" :
                                                                            transaction_status == CANCELLED_STATUS ? "Reject" :
                                                                                transaction_status
                                                            }
                                                        />
                                                    }
                                                </ETableCell>
                                                <ETableCell align="center" className='width-10px'>
                                                    {accountant_remark ?
                                                        <ETooltip title={accountant_remark} arrow>
                                                            <EIcon icon={InfoIcon} />
                                                        </ETooltip>
                                                        : '-'}
                                                </ETableCell>

                                            </ETableRowSmall>
                                        );
                                    })}
                                </ETableBody>
                            }
                            {employeePayment?.employeePaymentData?.count == 0 && !employeePayment.employeePaymentLoading && (
                                <ETableBody>
                                    <ETableRowSmall>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <SearchNotFound />
                                        </ETableCell>
                                    </ETableRowSmall>
                                </ETableBody>
                            )}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {employeePayment?.employeePaymentData && employeePayment?.employeePaymentData?.count > 10 &&
                    <ETablePagination
                        rowsPerPageOptions={[10, 25]}
                        component="div"
                        count={employeePayment?.employeePaymentData?.count ? employeePayment?.employeePaymentData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </ECard>
        </>
    );
}

export default TalentEditPayments