import { Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EHelperText } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Form, FormikProvider, useFormik } from "formik";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { Passport, aadharnumber, alphaNumbericRegex, drivingLicense, pannumber } from "../../utils/validation";//EditDocumentValidation,
import { updateDocument } from "../../action/TalentActions";
import { useDispatch } from "react-redux";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import * as Yup from 'yup';
import { ADHAAR_DOC_ID } from "../../constants";
import { DRIVING_LICENCE_DOC_ID } from "../../constants";
import { PAN_DOC_ID } from "../../constants";
import { PASSPORT_DOC_ID } from "../../constants";

const EditModalDocumentDetail = (props) => {
    const { auth, employeeData, closeDocumentDetailModal, selectedDocument } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();


    // Function to get validation regex of four default documents i.e ['aadhar card', 'pan card','driving license','passport'], for custom document it will return alphanumeric regex
    const getDefaultDocValidation = (document_id) =>{
        switch (document_id) {
            case ADHAAR_DOC_ID:
                return aadharnumber
            case PAN_DOC_ID:
                return pannumber
            case DRIVING_LICENCE_DOC_ID:
                return drivingLicense
            case PASSPORT_DOC_ID:
                return Passport
            default:
                return null
        }
    }
    
    const name = 'document_id_' + selectedDocument?.docType;//formik name of document number
    const file_name = 'file_' + selectedDocument?.docType;//formik name of document file

    // INFO: dynamic validation schema
    const validationSchema = Yup.object().shape({
        'is_required': Yup.boolean(),
        [name]: Yup.string().nullable().matches(getDefaultDocValidation(selectedDocument.docType), `Please enter valid ${selectedDocument?.name.toLowerCase()} number` )
            .when(["is_required"], (is_required, schema) => {
                if (selectedDocument?.is_required) {
                    var msg=selectedDocument.name + " is required"
                    return schema.required(msg)
                }
                else return schema
            })
        ,
        [file_name]: Yup.mixed().nullable()
            .test("type", "We only support jpeg and jpg format", function (value) {
                if (value != undefined && value) { return value && 
                    (value.type === "image/jpg" || value?.type === "image/jpeg" || value.type === "application/pdf"); }
                return true;
            })
            .test("fileSize", "File Size is too large", (value) => {
                const fileSize = value?.size / 1024 / 1024; //in mb
                if (fileSize > 2) { return false; }
                 return true;
            })
            .when("is_required", (is_required,schema) => {
                if (is_required) {
                    return schema.required("File is required")
                }
                else return schema
            })
            .when([name], (name, schema) => {
                if ((typeof name !== "undefined") || (name?.length > 0)) {
                    return schema.required("File is required")
                }
                return schema
            })
    });

    const formik = useFormik({

        initialValues: {
            // main values (new updated on - 27-4-23)
            document_id_no: '',
            file: null,
            talent_id: employeeData.id,
            document_id: selectedDocument.docType,
            is_required: selectedDocument?.is_required,
            [name]:selectedDocument.document_id_no?selectedDocument.document_id_no:''

        },
        validationSchema: validationSchema,
        onSubmit: (data) => {
            if (data) {
                data = { ...data, company_id: auth.authData?.company_id }

                if (data.file || data.document_id_no) {
                    dispatch(updateDocument(auth.authtoken, data, navigate))
                }

                closeDocumentDetailModal()
            }
        }
    });
    const { errors, handleSubmit, setFieldValue, getFieldProps } = formik;

    const SetFileFunction = (e, name) => {
        if (e.target.files[0]) {
            setFieldValue(name, e.target.files[0])
            setFieldValue("file", e.target.files[0])
        }
    }

    const setNumber = (value) => {
        setFieldValue("document_id_no", value)
    }

    return (

        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ alignItems: { sm: 'center', md: 'center', lg: 'flex-start' } }}>
                    <Grid item lg={6} md={12} sm={12} xs={12} >
                        <ELabelInputMainGrid label={'Document Number'}>
                            <ETextField
                                fullWidth
                                placeholder="Document Number"
                                inputProps={{ style: { textTransform: getFieldProps(name).value?'uppercase': "" } }}
                                onKeyUp={(e) => {
                                    setFieldValue(name, e?.target?.value?.toUpperCase())
                                    setNumber(e?.target?.value ? e?.target?.value?.toUpperCase() : '')
                                }}
                                {...getFieldProps(name)}
                                error={Boolean(errors[name])}
                                helperText={errors[name] && errors[name]}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12} sx={{position:'relative'}} className='docs-responsive-display'>
                        <ETextField fullWidth type="file"
                            inputProps={{
                                accept: 'image/jpg, image/jpeg, application/pdf'
                            }}
                            className={formik?.values?.file == null ? "input-file-no-file-chosen input-file-selector" : 'input-file-selector' }
                            onChange={(e) => { SetFileFunction(e, file_name) }}
                            error={Boolean(errors[file_name])}
                            helperText={errors[file_name] && errors[file_name]}
                        />
                        {
                           selectedDocument.file_path && <a href={selectedDocument.file_path} target="_blank"  className="ml-16px cursor-pointer font-size-14px link-color">{selectedDocument.name}</a>
                        }
                        <EHelperText className="greyColor4-color">* Allowed formats is pdf or jpeg/jpg</EHelperText>
                    </Grid>
                </Grid>

                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => closeDocumentDetailModal()}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton
                        size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    )

};

export default EditModalDocumentDetail;