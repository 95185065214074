import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { FormikProvider, useFormik, Form } from 'formik';
import { UpdateFreelancerValidation } from "../../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
//actions
import { ContractFreelancerInchargeUpdate } from "../../../action/ContractAction";
//base components
import { ELabelInputMainGrid } from "../../../BaseComponents/EGrid";
import { AllFreelancerList } from "../../../action/FreelancerAction";
import { EButtonOutlined, ELoadingButton } from "../../../BaseComponents/EButtons";
import { ETextField } from "../../../BaseComponents/ETextField";
import { EAutocomplete } from "../../../BaseComponents/EAutocomplete";

const EditModalFreelancerDetails = (props) => {
  const { close, auth, contract } = props;
  const contractDetails = contract?.contractData
  const dispatch = useDispatch();
  const freelancerList = useSelector(state => state.FreelancerReducer)
  const formik = useFormik({
    initialValues: {
      freelancer:contractDetails?.Freelancer?.freelancer_name && contractDetails?.Freelancer?.freelancer_code && contractDetails?.Freelancer?.id?
       { id: contractDetails?.Freelancer?.id, label: contractDetails?.Freelancer?.freelancer_name + ' (' + contractDetails?.Freelancer?.freelancer_code + ')' }:null
    },
    validationSchema: UpdateFreelancerValidation,
    onSubmit: (data) => {
      data = { ...data, contract_id: contractDetails.id }
      dispatch(ContractFreelancerInchargeUpdate(auth.authtoken, data, close))
    }
  });

  const FreelancertListData =
    freelancerList &&
    freelancerList?.allFreelancerData?.rows?.map((obj) => ({
      id: obj.id,
      label: `${obj.freelancer_name} (${obj.freelancer_code})`,
      email: obj.freelancer_email,
      mobile: obj.freelancer_contact_no
    }));
  const { errors, touched, handleSubmit,getFieldProps, setFieldValue, resetForm } = formik;

  //calling for the first time
  useEffect(() => {
    if (auth?.authtoken) {
     // dispatch(FreelancerListData(auth?.authtoken));//INFO: commenting all Freelancer List here since we below reason
     dispatch(AllFreelancerList(auth.authtoken));//INFO: calling all Freelancer List here since we have removed it from login and check token actions
    }
  }, [])

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={6} lg={7} xl={5}>
            <ELabelInputMainGrid label={'Freelancer Name'}>
              <EAutocomplete
                fullWidth
                {...getFieldProps('freelancer')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={
                  FreelancertListData && FreelancertListData?.length > 0 ? FreelancertListData : []
                }
                getOptionLabel={(option) => option.label || ''}
                onChange={(e, value) => {
                  setFieldValue('freelancer', value);
                }}
                renderInput={(params) => (
                  <ETextField
                    {...params}
                    error={Boolean(touched.freelancer && errors.freelancer)}
                    helperText={touched.freelancer && errors.freelancer}
                  />
                )}
              />
            </ELabelInputMainGrid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12} className="modal1-buttons-stick-bottom">
            <Stack direction="row" spacing={2}>
            <EButtonOutlined
                size="large"
                variant="outlined"
                onClick={() => {
                  close();
                  resetForm();
                }}
              >
                Cancel
              </EButtonOutlined>
              <ELoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={contract?.contractLoading}
              >
                Update
              </ELoadingButton>
              
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
};

export default EditModalFreelancerDetails;
