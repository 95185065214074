import axios from "axios";
import { Errors, Success } from "../BaseComponents/EToast";
import { SERVER_URL, SETTING_LOADING_START, SETTING_LOADING_STOP, SETTING_FAILURE, GET_LEAVE_SETTING_API, SETTING_LEAVE, GET_TALENT_SETTING_API, SETTING_TALENT, 
    GET_PAYROLL_SETTING_API, SETTING_PAYROLL, GET_ADDITIONAL_SETTING_API, SETTING_ADDITIONAL, GET_PAYMENT_SETTING_API, SETTING_PAYMENT } from "../constants";
import { ReturnBoolean, ReturnBoolean2 } from "../utils/CommonFunctions";
import { Logout } from './AuthAction';
import { CompanyDetail } from "./CompanyAction";


export function GetCompanySetting(token, api, type, stopLoading = true) {
    
    return async (dispatch) => {
        dispatch(settingLoadingStart())
        axios({
            method: 'get',
            url: `${SERVER_URL}/setting/${api}`,
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            if (res.status === 200) {
                dispatch(settingSuccess({ type: type, payload: res?.data?.data }));
            }
        }).catch((error) => {
                dispatch(settingFailure(error));
        }).finally(() => {
            if(stopLoading)  dispatch(settingLoadingStop())
        });
    };
}


export function settingLoadingStart() {
    return {
        type: SETTING_LOADING_START,
    };
}
export function settingLoadingStop() {
    return {
        type: SETTING_LOADING_STOP,
    };
}
export function settingSuccess(data) {
    return {
        type: data.type,
        payload: data.payload
    };
}
export function settingFailure(error) {
    return {
        type: SETTING_FAILURE,
        payload: error
    };
}

//INFO: This action is used for Leave Setting under Company Settings for saving the updated data.    
export function SetLeaveSetting(token, Data, url, navigate = () => null) {
    const data = new FormData();
    data.append('is_default_setting', Data.is_default_setting)
    data.append('leave_from_month', Data.leave_from_month ? Data.leave_from_month : '');
    data.append('leave_to_month', Data.leave_to_month ? Data.leave_to_month : '');
    data.append('leave_allowed_per_month', Data.leave_allowed_per_month >= 0 ? Number(Data.leave_allowed_per_month) : '');
    data.append('leave_allowed_per_tenure', Data.leave_allowed_per_tenure >= 0 ? Number(Data.leave_allowed_per_tenure) : '');
    data.append('leave_tenure_category', Data.leave_tenure_category ? Data.leave_tenure_category : '');
    data.append('enable_auto_leave_approval', Data.enable_auto_leave_approval);
    if (Data?.auto_leave_approval_timeframe?.id) { data.append('auto_leave_approval_timeframe', Data.enable_auto_leave_approval ? Data?.auto_leave_approval_timeframe?.id : ''); }
    if (Number(Data.auto_leave_approval_timeframe)) { data.append('auto_leave_approval_timeframe', Data.auto_leave_approval_timeframe); }
    data.append('enable_half_day_leave_request', ReturnBoolean(Data.enable_half_day_leave_request));
    data.append('enable_leave_application_reason', ReturnBoolean(Data.enable_leave_application_reason));
    data.append('enable_leave_cycle', Data.enable_leave_cycle);
    data.append('enable_multi_level_leave_approval', Data.enable_multi_level_leave_approval);
    data.append('enable_paid_leave_notice_period', ReturnBoolean2(Data.enable_paid_leave_notice_period) == true ? true : false);

    return async (dispatch, getState) => {
        const state = getState()
        const onBoarding = state?.CompanyReducer?.companyData?.onBoarding
        dispatch(settingLoadingStart());
        axios({
            method: 'post',
            url: `${SERVER_URL}/setting/${url}`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(CompanyDetail(token, navigate, onBoarding))
                    Success.fire({
                        text: 'html',
                        html: `<span style="color:white">${res.data.message}</span>`
                    });
                }
            })
            .catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: 'html',
                        html: `<span style="color:white">${error.response.data.message}</span>`
                    });
                    dispatch(settingFailure(error));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                }
            }).finally(() => dispatch(GetCompanySetting(token, GET_LEAVE_SETTING_API, SETTING_LEAVE)));
    };
}

//INFO: This action is used for Talent Setting under Company Settings for saving the updated data.     
export function SetTalentSetting(token, Data, url, navigate = () => null) {

    const data = new FormData();
    data.append('enable_talent_directory', Data.enable_talent_directory);
    data.append('talent_custom_field', Data.talent_custom_field);
    data.append('is_default_setting',Data.is_default_setting)
    // INFO: conditional value if due the API's requirement 
    // i.e. if talent_custom_field==true then custom values will be sent else false will be sent as checkbox's value.
    const is_checkboxes = ReturnBoolean(Data.talent_custom_field)
    data.append('field_dob', is_checkboxes && ReturnBoolean2(Data.field_dob) == true ? Data.field_dob : false);
    data.append('field_gender', is_checkboxes && ReturnBoolean2(Data.field_gender) == true ? Data.field_gender : false);
    data.append('field_marital_status', is_checkboxes && ReturnBoolean2(Data.field_marital_status) == true ? Data.field_marital_status : false);
    data.append('field_blood_group', is_checkboxes && ReturnBoolean2(Data.field_blood_group) == true ? Data.field_blood_group : false);
    data.append('field_address', is_checkboxes && ReturnBoolean2(Data.field_address) == true ? Data.field_address : false);
    data.append('field_profile_photo', is_checkboxes && ReturnBoolean2(Data.field_profile_photo) == true ? Data.field_profile_photo : false);
    data.append('field_ifsc_code', is_checkboxes && ReturnBoolean2(Data.field_ifsc_code) == true ? Data.field_ifsc_code : false);
    data.append('field_bank_name', is_checkboxes && ReturnBoolean2(Data.field_bank_name) == true ? Data.field_bank_name : false);
    data.append('field_branch_name', is_checkboxes && ReturnBoolean2(Data.field_branch_name) == true ? Data.field_branch_name : false);
    data.append('field_account_holder_name', is_checkboxes && ReturnBoolean2(Data.field_account_holder_name) == true ? Data.field_account_holder_name : false);
    data.append('field_account_number', is_checkboxes && ReturnBoolean2(Data.field_account_number) == true ? Data.field_account_number : false);
    data.append('field_emer_name', is_checkboxes && ReturnBoolean2(Data.field_emer_name) == true ? Data.field_emer_name : false);
    data.append('field_emer_relation', is_checkboxes && ReturnBoolean2(Data.field_emer_relation) == true ? Data.field_emer_relation : false);
    data.append('field_emer_contact_no', is_checkboxes && ReturnBoolean2(Data.field_emer_contact_no) == true ? Data.field_emer_contact_no : false);

    // INFO: conditional value if due the API's requirement  
    // i.e. if enable_talent_code_prefix==true then talent_code_prefix will be sent else null will be sent.
    data.append('enable_talent_code_prefix', Data.enable_talent_code_prefix);
    data.append('talent_code_prefix', Data.talent_code_prefix);

    let talentDoc = JSON.stringify(Data.talent_document)
    data.append('talent_document', talentDoc);

    return async (dispatch) => {
        dispatch(settingLoadingStart());
        axios({
            method: 'post',
            url: `${SERVER_URL}/setting/${url}`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(CompanyDetail(token, navigate))
                    Success.fire({
                        text: 'html',
                        html: `<span style="color:white">${res.data.message}</span>`
                    });
                }
            })
            .catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: 'html',
                        html: `<span style="color:white">${error.response.data.message}</span>`
                    });
                    dispatch(settingFailure(error));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                }
            }).finally(() => dispatch(GetCompanySetting(token, GET_TALENT_SETTING_API, SETTING_TALENT)));
    };
}

//INFO: This action is used for Talent Setting under Company Settings for saving the updated data.     
export function SetPayrolllSettings(token, Data, url, navigate = () => null) {

    const data = new FormData();
    data.append('payroll_is_auto', ReturnBoolean(Data.payroll_is_auto));
    data.append('is_default_setting',ReturnBoolean(Data.is_default_setting))
    if(Data.payroll_auto_generation_date){
        data.append('payroll_auto_generation_date', Data.payroll_auto_generation_date?.id)
    }

    return async (dispatch) => {
        dispatch(settingLoadingStart());
        axios({
            method: 'post',
            url: `${SERVER_URL}/setting/${url}`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(CompanyDetail(token, navigate))
                    Success.fire({
                        text: 'html',
                        html: `<span style="color:white">${res.data.message}</span>`
                    });
                }
            })
            .catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: 'html',
                        html: `<span style="color:white">${error.response.data.message}</span>`
                    });
                    dispatch(settingFailure(error));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                }
            })
            .finally(() => dispatch(GetCompanySetting(token, GET_PAYROLL_SETTING_API, SETTING_PAYROLL)));
        };
    }
//INFO: This action is used for Additional Setting under Company Settings for saving the updated data.     
export function SetAdditionalSetting(token, Data, url, navigate = () => null) {

    const data = new FormData();
        let additionalSetting = JSON.stringify(Data.data)
        data.append('dpr_submission_required', ReturnBoolean(Data.dprRequired));
        data.append('data', additionalSetting);
        data.append('enable_custom_document',Data.enable_custom_document);
        data.append('is_default_setting',ReturnBoolean(Data.is_default_setting))

    return async (dispatch, getState) => {
        const state = getState()
        const onBoarding = state?.CompanyReducer?.companyData?.onBoarding
        dispatch(settingLoadingStart());
        axios({
            method: 'post',
            url: `${SERVER_URL}/setting/${url}`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(CompanyDetail(token, navigate, onBoarding))
                    Success.fire({
                        text: 'html',
                        html: `<span style="color:white">${res.data.message}</span>`
                    });
                    //Calling talent setting API here so that the documents in the talent settings will get updated as well.
                    dispatch(GetCompanySetting(token, GET_TALENT_SETTING_API, SETTING_TALENT)); 
                }
            })
            .catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: 'html',
                        html: `<span style="color:white">${error.response.data.message}</span>`
                    });
                    dispatch(settingFailure(error));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                }
            }).finally(() => dispatch(GetCompanySetting(token, GET_ADDITIONAL_SETTING_API, SETTING_ADDITIONAL)));
    };
}


//INFO: This action is used for Payment Setting under Company Settings for saving the updated data.     
export function SetPaymentSetting(token, Data, url, navigate = () => null) {

    const data = new FormData();
    let reimbursement_reason = JSON.stringify(Data.reimbursementReason)
    data.append('enable_reimbursement', Data.enable_reimbursement)
    data.append('reimbursement_attachment_required',Data.reimbursement_attachment_required)
    data.append('enable_reimbursement_reason',Data.enable_reimbursement_reason)
    data.append('reimbursement_with_payroll',Data.reimbursement_with_payroll)
    data.append('enable_advance_salary',Data.enable_advance_salary)
    data.append('reimbursement_reason',reimbursement_reason)
    data.append('is_default_setting',ReturnBoolean(Data.is_default_setting))

    return async (dispatch) => {
        dispatch(settingLoadingStart());
        axios({
            method: 'post',
            url: `${SERVER_URL}/setting/${url}`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(CompanyDetail(token, navigate))
                    Success.fire({
                        text: 'html',
                        html: `<span style="color:white">${res.data.message}</span>`
                    });
                }
            })
            .catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: 'html',
                        html: `<span style="color:white">${error.response.data.message}</span>`
                    });
                    dispatch(settingFailure(error));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                }
            }).finally(() => dispatch(GetCompanySetting(token, GET_PAYMENT_SETTING_API, SETTING_PAYMENT)));
    };
}

/**
 * Saves a specific company setting, fetches the updated setting, and updates the company setting context data accordingly.
 * @param {string} token secret token
 * @param {any} data Data to be saved.
 * @param {string} setUrl POST API endpoint URL for saving the setting.
 * @param {string} getUrl GET API endpoint URL for fetching the setting.
 * @param {string} type Type of the setting.
 */
export function SetCompanySetting(token, data, setUrl, getUrl, type, navigate = () => null) {
    return async (dispatch, getState) => {
        const state = getState()
        const onBoarding = state?.CompanyReducer?.companyData?.onBoarding
        dispatch(settingLoadingStart());
        axios({
            method: 'post',
            url: `${SERVER_URL}/setting/${setUrl}`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(CompanyDetail(token, navigate, onBoarding))
                    Success.fire({
                        text: 'html',
                        html: `<span style="color:white">${res.data.message}</span>`
                    });
                }
            })
            .catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: 'html',
                        html: `<span style="color:white">${error.response.data.message}</span>`
                    });
                    dispatch(settingFailure(error));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                }
            })
            .finally(() => dispatch(GetCompanySetting(token, getUrl, type)))
    }
}
