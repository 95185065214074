import { Grid, Stack } from '@mui/material'
import React from 'react'
import { EIcon } from '../../../BaseComponents/EIcon'
import { ETypography } from '../../../BaseComponents/ETypography'
import Page from '../../../BaseComponents/EPage';
import { AuthNavbar } from '../../../layouts/auth-navbar'
import { AuthRootStyle } from '../../../BaseComponents/EContainer'
import { ECardDashboard } from '../../../BaseComponents/ECard'
import { EButton } from '../../../BaseComponents/EButtons'
import { useNavigate } from 'react-router';

function OnboardingWelcome({auth}) {
    const navigateTo = useNavigate()
    return (
        <Page title="Welcome">
            <Grid container justifyContent={'center'} alignItems={'center'}>
                {/* <AuthRootStyle maxWidth="lg"> */}
                    <AuthNavbar />
                    <ECardDashboard sx={{ maxWidth: '570px', margin: '28px auto' }}>
                        <Grid container direction="column" className="mb-16px ">
                            <Grid item  >
                                <Stack direction='row'>
                                    <ETypography className="mr-8px">
                                        <img alt="gift" src="/assets/images/onboarding-gift.png" />
                                    </ETypography>
                                    <ETypography className="onboarding-heading bold-400 font-size-24px ">
                                        Welcome to <span> Entera</span>,
                                    </ETypography>
                                </Stack>
                            </Grid>

                            <Grid item marginBottom={'34px'} padding={1}>
                                <ETypography className="onboarding-modal-head bold-600 font-size-16px mb-16px">Hi {auth?.authData?.fullname},</ETypography>

                                <div>
                                    <p className="onboarding-modal-paragraph mb-16px ">
                                        Thank you for choosing ENTERA as your next payroll superhero. We are
                                        happy to have you onboard!
                                    </p>
                                    <p className="onboarding-modal-paragraph ">
                                        We would suggest you to get started with our quick start guide to
                                        enable Entera get familiar with your workspace.
                                    </p>
                                </div>
                            </Grid>

                            <Stack direction='row' justifyContent={'flex-end'} spacing={2}>
                                <EButton onClick={()=>navigateTo('/onboarding-options')}>
                                    Get Started
                                </EButton>
                            </Stack>

                        </Grid>
                    </ECardDashboard>

                {/* </AuthRootStyle> */}
            </Grid>
        </Page>
    )
}

export default OnboardingWelcome