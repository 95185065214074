import { Grid } from "@mui/material";
import React, { useState } from "react";
import { EBox } from "../../BaseComponents/EBox";
import EMenuPopover, { EMenuItem } from "../../BaseComponents/EMenuPopover";
import SortByAlphaIcon from '@iconify/icons-material-symbols/sort-by-alpha';
import styled from "@emotion/styled";
import { EDivider } from "../../BaseComponents/EDivider";
import { DownLoadFile } from "../../action/AdminAction";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { DOWNLOAD_CSV, DOWNLOAD_PDF, HOLIDAY_LIST_DOWNLOAD_URL } from "../../constants";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { EInputAdornment, ETextFieldSearch } from "../../BaseComponents/ETextField";
import moment from "moment";
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import { EIcon, EIconInputAdornment } from "../../BaseComponents/EIcon";
import { EDesktopDatePicker } from "../../BaseComponents/EDatePicker";
import { EIconButton } from "../../BaseComponents/EButtons";
import { onKeyDown } from "../../utils/CommonFunctions";
import CancelIcon from '@iconify/icons-material-symbols/cancel-outline-rounded';
import { ETooltip } from "../../BaseComponents/ETooltip";

const GroupFields = styled(EBox)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    border: (theme) => `1px solid ${theme.palette.divider}`,
}));

const HolidaySearchBar = (props) => {
    const { searchParam, setSearchParam, getFilterData, auth } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const setSearch = (key, value) => {
        const sParams = { ...searchParam, [key]: value };
        setSearchParam(sParams);
        getFilterData(sParams)
    }

    const DownloadReport = (value) => {
        let url = '';
        let file_name = 'Holiday-list';
        url += HOLIDAY_LIST_DOWNLOAD_URL;

        if (value != null) { url += `?download_format=${value}`; }
        if (searchParam?.year_month != null) {
            file_name += '_' + moment(searchParam?.year_month).format('YYYYMM');
            url += `&month_year=${moment(searchParam?.year_month).format('YYYYMM')}`;
        }
        if (searchParam?.sort != null) {
            file_name += '_' + searchParam?.sort;
            url += `&sort=${searchParam?.sort}`;
        }
        DownLoadFile(auth.authtoken, url, file_name);
        setAnchorEl(null);
        handleClose();
    };

    return (

        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <EBox>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <EDesktopDatePicker
                        openTo="month"
                        views={['year', 'month']}
                        autoComplete='off'
                        inputFormat="MMM yyyy"
                        value={searchParam?.year_month}
                        minDate={new Date(moment().subtract(1, 'years').format('MMM YYYY'))}
                        maxDate={new Date(moment().add(1, 'years').format('MMM YYYY'))}
                        onChange={(newValue) => {
                            setSearch('year_month', newValue ? newValue : null);

                        }}
                        renderInput={(params) => (
                            <div style={{ position: "relative", display: "inline-block" }} >
                             <ETextFieldSearch
                                size="small"
                                fullWidth
                                {...params}
                                onKeyDown={onKeyDown}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: 'Select Month'
                                }}
                            />
                            {searchParam?.year_month &&
                              <EInputAdornment position='end' className='clearIconDesktopDatePicker'
                                  onClick={() => setSearch('year_month', null)} color='primary.main'
                                  >
                               <EIconInputAdornment icon={CancelIcon} color='primary.main'/>
                                </EInputAdornment>
                            }
                            </div>
                        )}
                    />
                </LocalizationProvider>
            </EBox>
            <GroupFields>
                <ETooltip arrow title="Download">
                <EIconButton size={"large"} onClick={handleClick}>
                    <EIcon icon={FileDownloadIcon} />
                </EIconButton>
                </ETooltip>
                

                <ETooltip arrow title="Date Sort">
                <EIconButton onClick={() => setSearch('sort', !searchParam.sort)}>
                    <EIcon icon={SortByAlphaIcon} />
                </EIconButton>
                </ETooltip>
                
            </GroupFields>

            {/* MenuList for Download */}

            <EMenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                sx={{ width: 180, top: 40 }}
            >
                <EMenuItem
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    onClick={() => DownloadReport(DOWNLOAD_PDF)}
                >
                    <EBox
                        sx={{ mr: 2, width: 24, height: 24 }}>
                        <PictureAsPdfIcon />
                    </EBox>
                    PDF
                </EMenuItem>
                <EMenuItem
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    onClick={() => DownloadReport(DOWNLOAD_CSV)}
                >
                    <EBox
                        sx={{ mr: 2, width: 24, height: 24 }} >
                        <InsertDriveFileIcon />
                    </EBox>
                    CSV
                </EMenuItem>
            </EMenuPopover>

        </Grid>

    );
};

export default HolidaySearchBar;