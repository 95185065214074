import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AllFreelancerList, FreelancerListData } from "../../action/FreelancerAction";
import { EBoxPage } from "../../BaseComponents/EBox";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon } from "../../BaseComponents/EButtons";
import { EDivider } from "../../BaseComponents/EDivider";
import { LoadingGrid } from "../../BaseComponents/EGrid";
import Page from "../../BaseComponents/EPage";
import { ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { EMPLOYEE_CARD_LIST, EMPLOYEE_GRID_LIST, PERMISSIONS } from "../../constants";
import FreelancerListCard from "../../PageComponents/FreelancerContracts/FreelancerListCard";
import FreelancerListGrid from "../../PageComponents/FreelancerContracts/FreelancerListGrid";
import FreelancerSearchBar from "../../PageComponents/FreelancerContracts/FreelancerSearchBar";
import AddBoxIcon from '@iconify/icons-material-symbols/add-box-outline-rounded';
import EHidden from "../../BaseComponents/EHidden";
import { EIcon } from "../../BaseComponents/EIcon";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { useCheckPermission } from "../../hooks";


const FreelancerList = (props) => {
    const { auth } = props;
    const navigate = useNavigate();
    const [listToggle, setListToggle] = useState(true);
    const [searchParam, setSearchParam] = useState({ name: '', sort: true });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(EMPLOYEE_CARD_LIST);
    const dispatch = useDispatch();
    const freelancer = useSelector((state) => state.FreelancerReducer);
    const companyDetail = useSelector((state) => state.CompanySettingReducer?.companySettingData);
    
    const allowAddFreelancer = useCheckPermission([PERMISSIONS.ViewAddFreelancer]) && companyDetail && Object.keys(companyDetail).length > 0
    const allowViewFreelancerDetail = useCheckPermission([PERMISSIONS.ViewEditFreelancer])

    const getEmployeeData = (searchData) => {
        callApi({ ...searchData, sort: searchData.sort }, 0, rowsPerPage);
        setPage(0);
    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi(searchParam, page, rowsPerPage);
            dispatch(AllFreelancerList(auth.authtoken));//INFO: calling all Freelancer List here since we have removed it from login and check token actions
        }
    }, [])

    const handleChangePageCard = (event, newPage) => {
        callApi(searchParam, (newPage - 1), rowsPerPage);
        setPage((newPage - 1));
    }

    const handleChangePage = (event, newPage) => {
        callApi(searchParam, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, EMPLOYEE_GRID_LIST);
        setRowsPerPage(rowPerPageCount);
        callApi(searchParam, 0, rowPerPageCount);
        setPage(0);
    };

    const listToggleMasterFn = (toggledData) => {
        const rowPerPageToggleCount = toggledData ? EMPLOYEE_CARD_LIST : EMPLOYEE_GRID_LIST;
        setListToggle(toggledData);
        setRowsPerPage(rowPerPageToggleCount)
        callApi(searchParam, 0, rowPerPageToggleCount);
        setPage(0);
    };
    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(FreelancerListData(auth.authtoken, { ...fnParams, sort: !fnParams.sort ? ' freelancer_name DESC' : 'freelancer_name ASC' }, fnPage, fnRowPerPage));
    }

    return (
        <Page title="Freelancer List" >
            <Grid container display="row" justifyContent="space-between" className="align-center">
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Freelancer List'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Freelancer Contract', href: '' },
                            {
                                name: 'Freelancer List',
                            }
                        ]}
                    />
                </Grid>
                {/* INFO: This complonent will be hidden wgen screen size goes below md */}
                <EHidden width="mdDown">
                    <Grid item>
                        {allowAddFreelancer &&
                            <EButton to={'/freelancer-contract/add-freelancer'}
                                component={Link} variant="contained"
                                // disabled={companyDetail?.activeFreelancer >= companyDetail?.CompanyActivePlan?.allowed_freelancer}
                            > Add Freelancer</EButton>
                        }
                        <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
                className='margin-top-20px '
            >
                <Grid item>
                    <ETypographyPageHeading variant='h5'>
                        Freelancer List
                    </ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        {allowAddFreelancer && //AddBoxIcon
                            <EButtonIcon to={'/freelancer-contract/add-freelancer'} component={Link} variant="contained" 
                             disabled={companyDetail?.activeFreelancer >= companyDetail?.CompanyActivePlan?.allowed_freelancer}
                            >
                                <EIcon icon={AddBoxIcon} className='height-width-15px'/>
                            </EButtonIcon>
                        }
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  <EIcon icon={ForwardArrow} className='height-width-15px'/> </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
            </Grid>
            <EHidden width="mdDown">
                <EDivider className='mt-8px' />
            </EHidden>
            <EBoxPage>
                <Grid container className='mb-16px'>
                    <FreelancerSearchBar
                        setListToggle={listToggleMasterFn}
                        listToggle={listToggle}
                        setSearchParam={setSearchParam}
                        searchParam={searchParam}
                        getEmployeeData={getEmployeeData}
                        auth={auth}
                        freelancer={freelancer}
                    />
                </Grid>
                {freelancer?.freelanceListLoading ?
                    <LoadingGrid />
                    : listToggle ? (
                        <Grid container>
                            <FreelancerListCard
                                freelanceListData={freelancer?.freelanceListData}
                                handleChangePage={handleChangePageCard}
                                page={page}
                                allowViewFreelancerDetail={allowViewFreelancerDetail}
                            />
                        </Grid>
                    ) : (
                        <Grid container my={0} >
                            <FreelancerListGrid
                                freelanceListData={freelancer?.freelanceListData}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                allowViewFreelancerDetail={allowViewFreelancerDetail}
                            />
                        </Grid>
                    )}
            </EBoxPage>
        </Page>
    )

};

export default FreelancerList;
