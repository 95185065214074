import { Grid, Input } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { EHelperText, ETypography } from "../../BaseComponents/ETypography";
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded';
import { useState } from "react";
import ImageEditing from "../ImageEditing";
import { EButtonProfile, EIconButton } from "../../BaseComponents/EButtons";
import { EAvatarProfile } from "../../BaseComponents/EAvatar";
import { EIcon } from "../../BaseComponents/EIcon";

const AddFreelancerProfilePicture = (props) => {
    const { formikFreelancer } = props;
    const [picture, setPicture] = useState({
        cropperOpen: false,
        img: null,
        zoom: 1,
        rotate: 0,
        croppedImg: "",
        croppedImgFileObject: '',
        newformphotostatus: 'initial'
    });
    const [editor, setEditor] = useState("");
    const handleProfilePhotoFileChange = (e) => {
        if (e && e.target.files[0]) {
            // let url = URL.createObjectURL(e.target.files[0]);
            setFieldValue("freelancer_photo", e.target.files[0])
            setPicture({
                ...picture,
                img: e.target.files[0],
                cropperOpen: true
            });
        }
    };

    const { errors, getFieldProps, setFieldValue } = formikFreelancer;

    return (

        <ECard p={20} className={!getFieldProps('freelancer_photo_check').value ? 'card_design_2 ' : 'card_design_1 height-100per-16px'}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ETypography variant='h5' className="font-size-20px">
                Profile Photo
                </ETypography>
                <EIconButton
                    onClick={() => setFieldValue('freelancer_photo_check', !getFieldProps('freelancer_photo_check').value)}>
                    <EIcon icon={getFieldProps('freelancer_photo_check').value ? minusBox : plusBox} />
                </EIconButton>
            </Grid>

            {getFieldProps('freelancer_photo_check').value && <>
                <EDivider />
                <Grid container className="pt-16px">
                    <Grid item xs={12} sm={12} className='text-align'>
                        {!picture.cropperOpen ? (
                            <label htmlFor="icon-button-file">
                                <div >
                                    <Input inputProps={{ accept: 'image/jpg, image/jpeg' }} id="icon-button-file" type="file" className="display-none"
                                        onChange={e => handleProfilePhotoFileChange(e)} placeholder="jgns" />
                                    <EButtonProfile aria-label="upload picture" component="span" id="ePhoto" >
                                        <EAvatarProfile alt="Profile Pic" src={picture.croppedImg} >
                                            <img src='/assets/images/default_profile_pic.svg' className="bg-color-card" alt="" />
                                        </EAvatarProfile>
                                    </EButtonProfile>
                                </div>
                            </label>
                        ) : (
                            <ImageEditing picture={picture} setPicture={setPicture} editor={editor} setEditor={setEditor} />
                        )}
                        <EHelperText>*jpeg or *jpg formats only</EHelperText>
                        <EHelperText style={{ color: 'red' }}> {errors.freelancer_photo ? errors.freelancer_photo : ''} </EHelperText>
                    </Grid>
                </Grid>
            </>}
        </ECard>
    )
};

export default AddFreelancerProfilePicture;