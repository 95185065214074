import { Dialog, DialogActions, DialogContent, DialogTitle, styled, useTheme } from "@mui/material";
import PropTypes from 'prop-types';
import { useState } from "react";
import { EDivider } from "./EDivider";

export const EDialog = styled(Dialog)(({ theme }) => ({
    borderRadius: '12px',
    '.MuiBackdrop-root': {
        background: '#D9D9D9 !important',
        opacity: '0.5 !important'
    },
    '.MuiPaper-root':{
        boxShadow: theme.customShadows.modalShadow,
    },
    '& .MuiDialogContent-root':{
        padding:'0px 24px 24px'//'0px 40px 24px',
        // '0px 24px 24px'

    },
    [theme.breakpoints.down("lg")]: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '400px'
        },
        '& .MuiDialog-paperWidthMd': {
            maxWidth: '500px'
        },
    },
    [theme.breakpoints.down("md")]: {
        '& .MuiDialogContent-root':{
            padding:'0px 24px 24px',
        },
    }
}));

EModal.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object,
    parentClassname: PropTypes.string,
};



export default function EModal(props) {
    const theme = useTheme();
    const { open, close, children, headervalue, footeractions, newMaxWidth, isfullscreen = false ,parentClassname=null} = props
    const [MaxWidth, SetMaxWidth] = useState(newMaxWidth ? newMaxWidth : 'sm');
    const Modalheaderstyle = {
        fontWeight: '600',
        fontSize: '1.25rem !important',
        lineHeight: '24px',
        color: theme.palette.text.primary,
        backgroundColor:theme.palette.background.modalbg,
        padding:'21px 24px 16px 24px'   
    };
const mainClassname=(parentClassname?parentClassname:'')+' '+ (MaxWidth ? ' margin-full-modal' : ' margin-not-full-modal')

    return (
        <div>
            <EDialog
                keepMounted
                open={open}
                maxWidth={MaxWidth}
                fullScreen={isfullscreen}
                fullWidth={true}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                className={mainClassname}//{MaxWidth ? 'margin-full-modal' : 'margin-not-full-modal'}//className="mt-12px"
            >
                <DialogTitle sx={Modalheaderstyle}>  {headervalue ?
                    <>{headervalue} 
                    <EDivider  sx={{marginTop:'6px'}} /></> : ''}

                </DialogTitle>

                <DialogContent style={{ overflowY: "visible", backgroundColor: theme.palette.background.modalbg}} >
                    {children}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end', padding: '16px 24px', display: footeractions ? 'block' : 'none' }} >
                    {footeractions}
                </DialogActions>
            </EDialog>
        </div>
    );
}

export  function EModalOnBoarding(props) {
    const theme = useTheme();
    const { open, close, children, headervalue, footeractions, newMaxWidth, isfullscreen = false ,parentClassname=null} = props
    const [MaxWidth, SetMaxWidth] = useState(newMaxWidth ? newMaxWidth : 'sm');
    const Modalheaderstyle = {
        fontWeight: '600',
        fontSize: '1.25rem !important',
        lineHeight: '24px',
        color: theme.palette.text.primary,
        backgroundColor:theme.palette.background.modalbg,
        padding:'21px 24px 16px 24px'   
    };
const mainClassname=(parentClassname?parentClassname:'')+' '+ (MaxWidth ? ' margin-full-modal' : ' margin-not-full-modal')

    return (
        <div>
            <EDialog
                keepMounted
                open={open}
                maxWidth={MaxWidth}
                fullScreen={isfullscreen}
                fullWidth={true}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                className={mainClassname}//{MaxWidth ? 'margin-full-modal' : 'margin-not-full-modal'}//className="mt-12px"
            >
                <DialogTitle sx={Modalheaderstyle}>  {headervalue ?
                    <>{headervalue} 
                    <EDivider  sx={{marginTop:'6px'}} />
                    </> : ''}

                </DialogTitle>
                {/* {children} */}

                <DialogContent style={{ overflowY: "visible", backgroundColor: theme.palette.background.modalbg, padding:'0 !important', margin:'0 !important'}}>
                    {children}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end', padding: '16px 24px', display: footeractions ? 'block' : 'none' }} >
                    {footeractions}
                </DialogActions>
            </EDialog>
        </div>
    );
}

