import {  Grid, IconButton } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { ETypographyCardHeading } from "../../BaseComponents/ETypography";
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded';
import moment from "moment";
import { ETextField } from "../../BaseComponents/ETextField";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { Field } from "formik";
import { BloodGroupOptions, GenderOptions, MaritalStatusOptions } from "../../constants";
import { EIcon } from "../../BaseComponents/EIcon";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";

const AddFreelancerPersonalDetails = (props) => {

    const { formikFreelancer } = props;
    const { errors, touched, getFieldProps, setFieldValue } = formikFreelancer;

    return (

        <ECard className={formikFreelancer?.values?.freelancer_detail_check ? 'card_design_1 height-100per-16px' : 'card_design_2 '}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ETypographyCardHeading variant='h5'>
                    Personal Details
                </ETypographyCardHeading>
                <IconButton
                    onClick={() => setFieldValue('freelancer_detail_check', !getFieldProps('freelancer_detail_check').value)}>
                    <EIcon icon={formikFreelancer?.values?.freelancer_detail_check ? minusBox : plusBox} />
                </IconButton>
            </Grid>

            {getFieldProps('freelancer_detail_check').value && <>
                <EDivider className="mb-16px" />
                <Grid container className='px-16px' alignItems={'center'}>
                    <Grid item xl={7} lg={12} md={12} sm={12} xs={12} >
                        <Grid container spacing={2} >
                        <Grid item lg={12} md={6} sm={6} xs={12} >
                                <ELabelInputMainGrid label={'Date of Birth'}>
                                    <EDatePicker
                                        name="freelancer_dob"
                                        defaultCalendarMonth={moment().subtract(18, 'years')._d} //To open the calender in (current-18) year
                                        {...getFieldProps('freelancer_dob')}
                                        maxDate={moment().subtract(18, 'years')._d}
                                        inputFormat="dd/MM/yyyy"
                                        views={["day", 'month', 'year']}
                                        onChange={(selectedDate) => {
                                            if (selectedDate) {
                                                setFieldValue('freelancer_dob', selectedDate);
                                            }
                                        }}
                                        inputProps={{ readOnly: true }}
                                        renderInput={(params) => (
                                            <Field
                                                component={ETextField}
                                                label="DOB"
                                                {...params}
                                                fullWidth
                                                name="freelancer_dob"
                                                error={Boolean(touched.freelancer_dob && errors.freelancer_dob)}
                                                helperText={touched.freelancer_dob && errors.freelancer_dob}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item lg={12} md={6} sm={6} xs={12} >
                                <ELabelInputMainGrid label={'Gender'}>
                                    <EAutocomplete
                                        name="freelancer_gender"
                                        fullWidth
                                        options={GenderOptions}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option.label || ''}
                                        {...getFieldProps('freelancer_gender')}
                                        onChange={(e, value) => {
                                            setFieldValue('freelancer_gender', value);
                                        }}
                                        renderInput={(params) => (
                                            <ETextField
                                                {...params}
                                                name="freelancer_gender"
                                                fullWidth
                                                placeholder="Select Gender"
                                                error={Boolean(touched.freelancer_gender && errors.freelancer_gender)}
                                                helperText={touched.freelancer_gender && errors.freelancer_gender}
                                            />
                                        )}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item lg={12} md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Marital Status'}>
                                <EAutocomplete
                                    name="freelancer_marital_status_obj"
                                    fullWidth
                                    forcePopupIcon={formikFreelancer?.values?.freelancer_marital_status_obj ? false : true}
                                    options={MaritalStatusOptions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('freelancer_marital_status_obj')}
                                    onChange={(e, value) => {
                                        setFieldValue("freelancer_marital_status_obj", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='freelancer_marital_status_obj'
                                            fullWidth
                                            placeholder='Select Marital Status'
                                            error={Boolean(touched.freelancer_marital_status_obj && errors.freelancer_marital_status_obj)}
                                            helperText={touched.freelancer_marital_status_obj && errors.freelancer_marital_status_obj}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item lg={12} md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Blood Group'}>
                                <EAutocomplete
                                    name="freelancer_blood_group_obj"
                                    fullWidth
                                    options={BloodGroupOptions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    forcePopupIcon={formikFreelancer?.values?.freelancer_blood_group_obj ? false : true}
                                    getOptionLabel={(option) => option.label || ''}
                                    {...getFieldProps('freelancer_blood_group_obj')}
                                    onChange={(e, value) => {
                                        setFieldValue('freelancer_blood_group_obj', value);
                                    }}
                                    renderInput={(params) => (
                                        <ETextField
                                            {...params}
                                            name="freelancer_blood_group_obj"
                                            placeholder="Select Blood Group"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            error={Boolean(touched.freelancer_blood_group_obj && errors.freelancer_blood_group_obj)}
                                            helperText={touched.freelancer_blood_group_obj && errors.freelancer_blood_group_obj}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid> 
                        </Grid>
                    </Grid>
                </Grid>
            </>}
        </ECard>
    )
};

export default AddFreelancerPersonalDetails;