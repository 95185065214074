import React,{useState} from "react";
import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AddNewDepartmentValidation } from "../../utils/validation";
import { EBox } from "../../BaseComponents/EBox";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { EInputAdornment, ETextField } from "../../BaseComponents/ETextField";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import {
  CreateDepartment,
  UpdateDepartment,
} from "../../action/DepartmentAction";
import CancelIcon from "@iconify/icons-material-symbols/cancel-outline-rounded";
import { EIcon } from "../../BaseComponents/EIcon";

function DepartmentForm(props) {
  const { auth, dept, close, rowData,buttonTitle, formikTalents } = props;
  const [status,setStatus] = useState(rowData?.status === undefined ? "1" : rowData?.status)
  const dispatch = useDispatch();
  
  const handleRadio = (e)=>{
    setStatus(()=>e.target.value)
    if (e.target.value == "1") {
      setFieldValue("status", true);
    } else {
      setFieldValue("status", false);
    }
  }

   // handleEditStatus : checks for status and returns value to update the designation row ie. (if status == '1' == true) (if status == '0' == false  )
   const handleEditStatus = (status) => {
    switch (status) {
      case 1:
        return true
      case 0:
        return false
      default:
        return status
    }
  }
  

  const formik = useFormik({
    initialValues: {
      department_name: rowData?.department_name ? rowData?.department_name : "",
      status: rowData?.status === undefined ? true : rowData?.status,
    },
    validationSchema: AddNewDepartmentValidation(dept),
    onSubmit: (data) => {
      if (rowData) {
        data = { ...data, departmentId: rowData?.id,status: handleEditStatus(data.status)};
        formikTalents?.setFieldValue("new_department", true)
        dispatch(UpdateDepartment(auth?.authtoken, data, close));
      } else {
        data = { ...data };
        formikTalents?.setFieldValue("new_department", true)
        dispatch(CreateDepartment(auth?.authtoken, data, close));
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  return (
    <EBox>
      <FormikProvider value={formik} id="previewBlock">
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {/* Department */}
          <ELabelInputMainGrid label={"Department"}>
            <ETextField
              fullWidth
              placeholder="example: Management,Accounts, etc."
              {...getFieldProps("department_name")}
              onChange={(e) => {
                setFieldValue("department_name", e.target.value);
              }}
              error={Boolean(touched.department_name && errors.department_name)}
              helperText={touched.department_name && errors.department_name}
              InputProps={{
                // startAdornment: <EInputAdornment position="start"></EInputAdornment>,
                endAdornment: (
                  <EInputAdornment
                    position="end"
                    sx={{ margin: "0px" }}
                    className="cursor-pointer"
                    onClick={() => {
                      // onSearch('')
                      setFieldValue("department_name", "");
                    }}
                  >
                    {values.department_name.length > 0 && (
                      <EIcon icon={CancelIcon} />
                    )}
                  </EInputAdornment>
                ),
              }}
            />
          </ELabelInputMainGrid>

          {/* Status */}
          <EBox className="mt-16px">
          <ELabelInputMainGrid label="Status">
            <RadioGroup
                onChange={handleRadio}
              row
              name="status"
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                checked={status == "1" ? true : false}
                label="Enable"
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Disable"
                checked={status == "0" ? true : false}
              />
            </RadioGroup>
          </ELabelInputMainGrid>
          </EBox>
          

          <Stack
            direction="row"
            spacing={2}
            paddingTop={2}
            className="modal1-buttons-stick-bottom"
          >
            <EButtonOutlined
              size="large"
              variant="outlined"
              onClick={() => close()}
            >
              {" "}
              Cancel{" "}
            </EButtonOutlined>
            <ELoadingButton
              loading={dept?.departmentLoading}
              size="large"
              variant="outlined"
              type="submit"
            >
              {rowData ? "Update" : buttonTitle ?'Add' : "Submit"}
            </ELoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </EBox>
  );
}

export default DepartmentForm;
