import { Grid, IconButton, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmployeeListData } from '../../action/EmployeeAction';
import { EmployeeRequestPaymentList } from '../../action/PaymentAction';
import { EBoxPage } from '../../BaseComponents/EBox';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { EButton, EButtonOutlined } from '../../BaseComponents/EButtons';
import EChip from '../../BaseComponents/EChip';
import { EDivider } from '../../BaseComponents/EDivider';
import { EIcon } from '../../BaseComponents/EIcon';
import InfoRoundedIcon from '@iconify/icons-material-symbols/info-outline';
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline';
import RupeeIcon from '@iconify/icons-material-symbols/currency-rupee';
import Page from '../../BaseComponents/EPage';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import {
    ACTIVE_USER, APPROVED_STATUS, CANCELLED_STATUS, COMPLETE_STATUS, DEFAULT_ROWS_PERPAGE,
    PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_REIMBURSEMENT, PENDING_STATUS, PERMISSIONS,
    ROW_SIZE_PER_PAGE, SUPER_ADMIN
} from '../../constants';
import PaymentRequestSearchBar from '../../PageComponents/Payment/PaymentRequestSearchBar';
import SearchNotFound from '../../PageComponents/SearchNotFound';
import { numberWithCommas } from '../../utils/formatNumber';
import { capitalizeCapitalString } from '../../utils/formatText';
import EModal from '../../BaseComponents/EModal';
import PaymentActionModal from '../../PageComponents/Payment/PaymentActionModal';
import PaymentDirectPayment from '../../PageComponents/Payment/PaymentDirectPayment';
import { useRef } from 'react';
import PaymentBulkModal from '../../PageComponents/Payment/PaymentBulkModal';
import { useCheckPermission } from '../../hooks';

// import SearchNotFound2 from '../../SearchNotFound2';
// import {
//   ACTIVE_USER,
//   APPROVED_STATUS,
//   CANCELLED_STATUS,
//   COMPLETE_STATUS,
//   DEFAULT_ROWS_PERPAGE,
//   PAYMENT_CATEGORY_ADVANCE,
//   PAYMENT_CATEGORY_OTHER,
//   PAYMENT_CATEGORY_REIMBURSEMENT,
//   PENDING_STATUS,
//   ROLE_HR,
//   ROW_SIZE_PER_PAGE,
//   SUPER_ADMIN
// } from '../../../constants';
// import { numberWithCommas } from '../../../utils/formatNumber';
// import ViewMoreOtherPayment from './ViewMoreOtherPayment';
// import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import moment from 'moment';
// import plusFill from '@iconify/icons-eva/plus-fill';
// import { Icon } from '@iconify/react';
// import { useNavigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { EmployeeRequestPaymentList } from '../../../actions/PaymentAction';
// import OtherPaymentSearch from './OtherPaymentSearch';
// import DirectPayment from './DirectPayment';
// import { EmployeeListData } from '../../../actions/EmployeeAction';
// import InfoIcon from '@mui/icons-material/Info';
// import { DataGrid } from '@mui/x-data-grid';
// import { capitalizeCapitalString } from '../../../utils/formatText';
// import BulkPayment from './BulkPayment';




function TalentPayment(props) {
    const { auth } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const initialBulkSelectedValue = {
        ids: [], total_amount: 0, payment_ids: []
    };
    //   const auth = useSelector((state) => state.authReducer);
    const payment = useSelector((state) => state.PaymentReducer);
    const employee = useSelector((state) => state.EmployeeReducer);
    const company = useSelector(state => state.CompanyReducer);
    const [modalState, setModalState] = useState({ modalData: null, modalCategory: null });


    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const [page, setPage] = useState(0);

    const [state2, setState2] = useState(initialBulkSelectedValue);
    // const SelectedRowData = useRef([]);
    const SelectedRowsIds = useRef([]);
    const SelectedRowsIdsAmount = useRef([]);

    const [SearchParameters, setSearchParameters] = useState({
        yearMonth: null,
        contract_no: '',
        sortBy: null,
        employee_id: null,
        particular: null,
        start_date: null,
        end_date: null
    });

    const allowPayment = useCheckPermission([PERMISSIONS.SideBarTalentPayment])

    const getFilterData = (searchData) => {
        callApi(searchData, 0, rowsPerPage);
        setPage(0);

        //  empty the bulk payment State
        SelectedRowsIds.current = [];
        SelectedRowsIdsAmount.current = [];
        setState2(initialBulkSelectedValue);
    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...SearchParameters }, page, rowsPerPage);
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER));
        }
    }, [auth?.authtoken])


    const handleChangePage = (event, newPage) => {
        callApi(SearchParameters, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(SearchParameters, 0, rowPerPageCount);
        setPage(0);

        SelectedRowsIds.current = [];
        SelectedRowsIdsAmount.current = [];
        setState2(initialBulkSelectedValue);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(EmployeeRequestPaymentList(auth.authtoken, 'request_talent', { ...fnParams, sort: !fnParams.sort ? ' DESC ' : ' ASC ', withoutRoleCheck: allowPayment }, fnPage, fnRowPerPage));
    }



    let rows =
        !payment?.PaySalaryListData?.PaySalaryListLoading &&
            payment?.PaySalaryListData?.rows?.length > 0
            ? payment?.PaySalaryListData?.rows?.map((data, index) => ({
                id: index + 1,
                rowId: data?.id,
                Date: data?.transaction_date ? moment(data?.transaction_date).format('DD/MM/yyyy') : '-',
                TalentName: {
                    fullname: data?.employeeData?.fullname ? data?.employeeData?.fullname : '-',
                    code: data?.employeeData?.employee_code ? data?.employeeData?.employee_code : ''
                },
                Particular: data?.transaction_category,
                Amount: data?.pay_amount ? numberWithCommas(data?.pay_amount) : '-',
                remark: data?.transaction_status_remark ? data?.transaction_status_remark : '-',
                paymentMode: !data?.payment_medium ? '' : capitalizeCapitalString(data?.payment_medium),
                Status: data?.transaction_status ? data?.transaction_status : '-',
                UpdatedOn: data?.transaction_status_date ? moment(data?.transaction_status_date).format('DD/MM/YYYY') : '-',
                Action: data
            }))
            : [];

    const columns = [
        // { field: 'id', headerName: 'SNo.', headerClassName: 'super-app-theme--header', width: 80, editable: false, sortable: false },
        {
            field: 'Date',
            headerName: 'Date',
            minWidth: 100,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            editable: false,
            sortable: false
        },
        {
            field: 'TalentName',
            headerName: 'Talent Name',
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            flex: 2,
            editable: false,
            sortable: false,
            renderCell: (params, value) => {
                return (
                    <ETypography
                        variant="subtitle1" noWrap className="cursor-pointer  text-capitalize blue-color"
                    >
                        {params?.row?.TalentName?.fullname ? params?.row?.TalentName?.fullname : '-'}
                    </ETypography>


                );
            }
        },
        {
            field: 'Particular',
            headerName: 'Particular',
            minWidth: 80,
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
            sortable: false,
            renderCell: (params, value) => {
                return (
                    <ETypography>
                        {params?.row?.Particular && params?.row?.Particular == PAYMENT_CATEGORY_ADVANCE
                            ? 'Advance'
                            : params?.row?.Particular == PAYMENT_CATEGORY_REIMBURSEMENT
                                ? 'Reimbursement'
                                : params?.row?.Particular == PAYMENT_CATEGORY_OTHER
                                    ? 'Other'
                                    : '-'}
                    </ETypography>
                );
            }
        },
        {
            field: 'Amount',
            headerClassName: 'super-app-theme--header',
            headerName: 'Amount',
            type: 'number',
            width: 100,
            sortable: false,
            editable: false
        },
        {
            field: 'UpdatedOn',
            headerName: 'Updated On',
            minWidth: 100,
            headerClassName: 'super-app-theme--header',
            flex: 1,
            sortable: false,
            editable: false
        },

        {
            field: 'Status',
            headerName: 'Status',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            minWidth: 130,
            flax: 1,
            renderCell: (params, value) => {
                return (
                    <Grid className="display-flex align-items-center">
                        {params?.row?.Status ? (
                            <EChip
                                label={
                                    params?.row?.Status == PENDING_STATUS
                                        ? 'Pending'
                                        : params?.row?.Status == APPROVED_STATUS
                                            ? 'Approved'
                                            : params?.row?.Status == CANCELLED_STATUS
                                                ? 'Reject'
                                                : 'Paid'
                                }
                                className={
                                    params?.row?.Status == PENDING_STATUS
                                        ? 'active-grey-chip'
                                        : params?.row?.Status == APPROVED_STATUS
                                            ? 'active-blue-chip'
                                            : params?.row?.Status == CANCELLED_STATUS
                                                ? 'active-red-chip'
                                                : 'active-green-chip'
                                }
                            />
                        ) : (
                            '-'
                        )}
                        {params?.row?.Status == COMPLETE_STATUS && params?.row?.Action?.accountant_remark && (
                            <ETooltip title={params?.row?.Action?.accountant_remark} arrow>
                                <EIcon icon={InfoRoundedIcon} />
                                {/* <span><InfoIcon className="grey-color" /></span> */}
                            </ETooltip>
                        )}
                    </Grid>
                );
            },
            editable: false
        },
        {
            field: 'Action',
            headerClassName: 'super-app-theme--header',
            headerName: 'Action',
            // description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 80,
            renderCell: (params, value) => {
                if (!allowPayment || (params.value?.transaction_status !== APPROVED_STATUS && params.value?.transaction_status !== COMPLETE_STATUS)) {
                    return <></>
                }
                return (
                    <ETooltip title={params.value?.transaction_status !== COMPLETE_STATUS ? 'Pay' : 'View'} arrow>
                        <IconButton onClick={() => setModalState({ modalData: params.value, modalCategory: 'PAYMENT' })}>
                            {params.value?.transaction_status !== COMPLETE_STATUS ? (
                                <EIcon icon={RupeeIcon} className="theme-color-static" />
                                ) : (
                                <EIcon icon={VisibilityIcon} />
                            )}
                        </IconButton>
                    </ETooltip>
                );
            }
        }
    ];

    const SetSelectedIds = (ids, num = null) => {

        SelectedRowsIds.current[page] = ids
        const reduceData = rows.reduce((acc, cur) => {
            if (ids.includes(cur.rowId)) {
                acc += cur.Action?.pay_amount && cur.Action?.transaction?.collect_amount ?
                    (Number(cur.Action?.pay_amount) - Number(cur.Action?.transaction?.collect_amount))
                    : cur.Action?.pay_amount ? Number(cur.Action?.pay_amount) : 0;
            }
            return acc;                                           // Return accumulator
        }, 0);

        const arrayMainIds = SelectedRowsIds.current.flat();
        SelectedRowsIdsAmount.current[page] = reduceData;
        const arrayMainIdsAmount = SelectedRowsIdsAmount.current.flat();
        const finalAmount = arrayMainIdsAmount.reduce((partialSum, a) => partialSum + a, 0);

        setState2({ ids: SelectedRowsIdsAmount.current, total_amount: finalAmount, payment_ids: arrayMainIds });

    };

    return (
        <Page title="Talent Payment">

            <Grid container display="row" justifyContent="space-between " className='align-center' >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Talent Payment'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Payment', href: '' },
                            {
                                name: 'Talent Payment',
                            }
                        ]}
                    /></Grid>
                <Grid item >
                    <EButton className='button-left-margin' disabled={(state2.payment_ids?.length == 0)} variant="outlined"
                        onClick={() => setModalState({ modalData: state2, modalCategory: 'BULKPAYMENT' })}
                    >  Bulk Payment </EButton> {' '}
                    {auth?.authData?.role == SUPER_ADMIN && <EButton variant="contained"
                        className='button-left-margin'
                        onClick={() => setModalState({ ...modalState, modalCategory: 'DIRECTPAYMENT' })}
                    >  Direct Payment </EButton>}


                    <EButtonOutlined className='button-left-margin' variant="outlined"
                        onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                </Grid>
            </Grid>

            <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>Talent Payment</ETypographyPageHeading>
                </Grid>
                <Grid lg={12} xs={12} item className='pt-8px'><EDivider /></Grid>
            </Grid>
            <EBoxPage>
                <PaymentRequestSearchBar
                    setSearchParam={setSearchParameters}
                    searchParam={SearchParameters}
                    getFilterData={getFilterData}
                    employeeList={employee}
                    companyData={company}
                    auth={auth}
                />

                {(payment?.PaySalaryListData?.count == 0 ||
                    Object.keys(payment?.PaySalaryListData).length == 0) &&
                    !payment?.PaySalaryListData?.payrollListLoading ? (
                    <div className="display-flex justifycenter">
                        <SearchNotFound />
                    </div>
                ) : (
                    <div style={{ width: '100%' }}>
                        {/* {SelectedRowsIds.current && */}
                        <DataGrid
                            rows={rows}
                            getRowId={(row) => row.rowId}
                            columns={columns}
                            pageSize={rowsPerPage}
                            disableSelectionOnClick
                            autoHeight
                            // disableColumnFilter 
                            disableColumnMenu
                            checkboxSelection
                            isRowSelectable={(params) =>
                                params?.row?.Action?.transaction_status == APPROVED_STATUS
                            }
                            selectionModel={SelectedRowsIds.current[page]}
                            onSelectionModelChange={(ids) => {
                                SetSelectedIds(ids);
                            }}
                            hideFooterPagination={true}
                            hideFooter={true}
                            disableColumnSelector={true}
                            sx={{
                                '.css-x5d0om-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled ': {
                                    color: 'rgba(145, 158, 171, 0.8)',
                                    display: 'none'
                                },
                                '& .super-app-theme--header': {
                                    backgroundColor: theme.palette.background.tableHeader,
                                    borderBottom: '3px solid',
                                    borderBottomColor: theme.palette.primary.main
                                },
                                '& .MuiDataGrid-columnHeaderCheckbox': {
                                    backgroundColor: theme.palette.background.tableHeader,
                                    borderBottom: '3px solid',
                                    borderBottomColor: theme.palette.primary.main
                                },
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none',
                                },
                            }}
                        />
                        {/* } */}

                    </div>
                )}
                {payment?.PaySalaryListData?.count > DEFAULT_ROWS_PERPAGE && (
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={payment?.PaySalaryListData?.count ? payment?.PaySalaryListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}


                {modalState.modalCategory && modalState.modalCategory === 'PAYMENT' &&
                    <EModal open={modalState.modalCategory === 'PAYMENT'}
                        headervalue={modalState.modalData?.transaction_category &&
                            modalState.modalData?.transaction_category == PAYMENT_CATEGORY_ADVANCE
                            ? 'Advance Request'
                            : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
                                ? 'Reimbursement Request'
                                : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_OTHER
                                    ? 'Other Request'
                                    : '-'}
                    >
                        <PaymentActionModal
                            selectedData={modalState}
                            setModalState={setModalState}
                            getPaymentFilterData={() => getFilterData(SearchParameters)}
                            auth={auth}
                        />

                    </EModal>}

                {modalState.modalCategory && modalState.modalCategory === 'DIRECTPAYMENT' &&
                    <EModal open={modalState.modalCategory === 'DIRECTPAYMENT'}
                        headervalue={'Direct Payment'}
                    >
                        <PaymentDirectPayment
                            selectedData={modalState}
                            setModalState={setModalState}
                            getPaymentFilterData={() => getFilterData(SearchParameters)}
                            auth={auth}
                            employeeList={employee}
                        />

                    </EModal>}


                {modalState.modalCategory && modalState.modalCategory === 'BULKPAYMENT' &&
                    <EModal open={modalState.modalCategory === 'BULKPAYMENT'}
                        headervalue={'Bulk Payment'}
                    >
                        <PaymentBulkModal
                            paymentData={modalState.modalData}
                            setModalState={setModalState}
                            getPaymentFilterData={() => getFilterData(SearchParameters)}
                            auth={auth}
                        />

                    </EModal>}

            </EBoxPage>
            {/* <Container maxWidth={false}>
       


        

      </Container>
      {isDirectPayment && (
        <Modal1
          open={isDirectPayment}
          close={() => SetIsDirectPayment(false)}
          headervalue="Direct Payments"
        >
          <DirectPayment close={() => SetIsDirectPayment(false)} auth={auth} employee={employee} />
        </Modal1>
      )}
      {isBulkPayment.isOpen && isBulkPayment.data && (
        <Modal1
          open={isBulkPayment.isOpen}
          close={() => setIsBulkPayment({ isOpen: false, data: null })}
          headervalue="Bulk Payment"
        >
          <BulkPayment
            open={isBulkPayment.isOpen}
            data={isBulkPayment.data}
            close={() => setIsBulkPayment({ isOpen: false, data: null })}
            auth={auth}
          />
        </Modal1>
      )} */}
        </Page>
    );
}

export default TalentPayment;
