import React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, Grid} from '@mui/material';
import { useDispatch } from 'react-redux';
import { COMPLETED_STATUS, PENDING_STATUS, REMARK_LIMIT } from '../../constants';
import { checkOutDpr } from '../../utils/validation';
import { DeliverableStatusUpdate } from '../../action/ContractAction';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { ETextField } from '../../BaseComponents/ETextField';

const ModalInchargeDeliverableDetails = (props) => {
  const dispatch = useDispatch();
  const { row, auth, close } = props;

  const formik = useFormik({
    initialValues: {
      remark: '',
    },
    validationSchema: checkOutDpr, //reusing checkout form validation here
    onSubmit: (data) => {
      data = { ...data, contract_detail_id: row?.id, contract_id: row?.contract_id, status: COMPLETED_STATUS };
      dispatch(DeliverableStatusUpdate(auth?.authtoken, data));
      close();
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} className='px-16px'>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ELabelInputMainGrid label={'Deliverable'}  isNotForm={true}  isfullgrid={true}>
              {row?.work_title ? row?.work_title : '-'}
            </ELabelInputMainGrid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ELabelInputMainGrid label={'Description'}  isNotForm={true}  isfullgrid={true}>
              {row?.work_detail ? row?.work_detail : '-'}
            </ELabelInputMainGrid>

          </Grid>
          {row?.status == PENDING_STATUS ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Remark'}   isfullgrid={true} >
                <ETextField
                  fullWidth
                  multiline
                  rows={3}
                  {...getFieldProps('remark')}
                  inputProps={{ maxLength: REMARK_LIMIT }}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
               <span className='greyColor4-color font-size-14px'> {`${formik?.values?.remark?.length}/${REMARK_LIMIT}`}</span>
              </ELabelInputMainGrid>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Remark'} >
              {row?.incharge_remark ? row?.incharge_remark : '-'}
              </ELabelInputMainGrid>
            </Grid>
          )}
          <Grid item xs={12} sm={12} lg={12} xl={12} className="modal1-buttons-stick-bottom">
            <Stack direction="row" spacing={2}>
              <EButtonOutlined
                size="large"
                variant="outlined"
                onClick={() => {
                  close();
                  resetForm();
                }}
              >
                {row?.status == PENDING_STATUS ? ' Cancel ' : 'Close'}

              </EButtonOutlined>
              {row?.status == PENDING_STATUS && (
                <ELoadingButton size="large" type="submit" variant="contained">
                  Submit
                </ELoadingButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};
export default ModalInchargeDeliverableDetails;
