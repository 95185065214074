
import { Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmployeeListData } from "../../action/EmployeeAction";
import { TalentPaymentLogList } from "../../action/PaymentAction";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import EScrollbar from "../../BaseComponents/EScrollbar";
import InfoRoundedIcon from '@iconify/icons-material-symbols/info-outline';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { EFieldLabelBold, ETypography } from "../../BaseComponents/ETypography";
import { ACTIVE_USER, DEFAULT_ROWS_PERPAGE, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_SALARY, ROW_SIZE_PER_PAGE } from "../../constants";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import { numberWithCommas } from "../../utils/formatNumber";
import { EIcon } from "../../BaseComponents/EIcon";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import { EPaper } from "../../BaseComponents/EPaper";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import PaymentLogSearchBar from "./PaymentLogSearchBar";
import { useTheme } from "@emotion/react";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";

function PaymentLogTalent(props) {
    const { auth, tabvalue } = props;
    const Theme = useTheme();
    const dispatch = useDispatch();
    const payment = useSelector((state) => state.PaymentReducer);
    const employee = useSelector((state) => state.EmployeeReducer);
    const company = useSelector(state => state.CompanyReducer);
    const {setting} = useCompanySettingProvider()

    let total_paid_amt = 0;

    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const [page, setPage] = useState(0);
    const [SearchParameters, setSearchParameters] = useState({
        sort: true,
        yearMonth: null,
        employee_id: null,
        particular: null,
        start_date: null,
        end_date: null,
    });

    const getFilterData = (searchData) => {
        callApi({ ...searchData, sort: !searchData.sort ? ' id ASC ' : 'id DESC' }, 0, rowsPerPage);
        setPage(0);
    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...SearchParameters }, page, rowsPerPage);
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER));
        }
    }, [auth?.authtoken])


    const handleChangePage = (event, newPage) => {
        callApi(SearchParameters, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(SearchParameters, 0, rowPerPageCount);
        setPage(0);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        total_paid_amt = 0;
        dispatch(TalentPaymentLogList(auth.authtoken, tabvalue, { ...fnParams, sort: !fnParams.sort ? ' id ASC ' : 'id DESC' }, fnPage, fnRowPerPage));
    }


    const TABLE_HEAD = [
        { id: 'SNo.', label: 'S.No.', alignRight: false },
        { id: 'Date', label: 'Date', alignRight: false },
        { id: 'TalentName', label: 'Talent Name', alignRight: false },
        { id: 'Particular', label: 'Particular', alignRight: false },
        { id: 'PaidAmount', label: 'Paid Amount', alignRight: true },
        { id: 'Remark', label: 'Remark', alignRight: true },
    ];



    return (

        <Grid>

            <PaymentLogSearchBar
                setSearchParam={setSearchParameters}
                searchParam={SearchParameters}
                getFilterData={getFilterData}
                employeeList={employee}
                freelancerList={null}
                companyData={company}
                auth={auth}
                setting={setting}
            />
            {payment?.PaySalaryListData?.summery &&
                <EPaper className="borderRadius-12px" sx={{ border: `1.5px solid ${Theme.palette.primary.main}`, mb: 2 }} >
                    <Grid container direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        py={3}  >
                        <Grid item md={3}  >
                            <FieldContainerCustom
                                labelwidth={50}
                            >
                                <EFieldLabelBold>Duration</EFieldLabelBold>
                                <ETypography className="w-100per"  >
                                    {payment?.PaySalaryListData?.summery?.start_date ? moment(payment?.PaySalaryListData?.summery?.start_date).format('DD/MM/YYYY') : ''}
                                    {payment?.PaySalaryListData?.summery?.end_date ? ' To ' + moment(payment?.PaySalaryListData?.summery?.end_date).format('DD/MM/YYYY') : ''}
                                </ETypography>
                            </FieldContainerCustom>
                        </Grid>
                        <Grid item md={3}  >
                            <FieldContainerCustom
                                labelwidth={50}
                            >
                                <EFieldLabelBold>Paid Amount</EFieldLabelBold>
                                <ETypography >
                                    {payment?.PaySalaryListData?.summery?.paid_amount ? numberWithCommas(payment?.PaySalaryListData?.summery?.paid_amount) : ''}
                                </ETypography>
                            </FieldContainerCustom>
                        </Grid>
                    </Grid>
                </EPaper>
            }

            <EScrollbar>
                <ETableContainer >
                    <ETable>
                        <EListHead
                            headLabel={TABLE_HEAD}
                            rowCount={payment?.PaySalaryListData?.rows?.length}
                            onRequestSort={() => null}
                        />
                        {payment?.PaySalaryListData?.payrollListLoading &&
                            <ETableBody>
                                <ETableRow>
                                    <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                        <ECircularProgress color="primary" />
                                    </ETableCell>
                                </ETableRow>
                            </ETableBody>
                        }
                        {!payment?.PaySalaryListData?.payrollListLoading && payment?.PaySalaryListData?.rows?.length > 0 &&
                            <ETableBody>

                                {payment?.PaySalaryListData?.rows?.map((row, index) => {
                                    const {
                                        transaction_category,
                                        transaction_date,
                                        accountant_remark,
                                        paid_amount,
                                        employeeData,
                                        payrollData
                                    } = row;
                                    total_paid_amt += Number(paid_amount);
                                    return (

                                        <ETableRow hover key={index} tabIndex={-1}>
                                            <ETableCell align="left">{(page * rowsPerPage) + (index + 1)}</ETableCell>
                                            <ETableCell align="left">
                                                {moment(transaction_date).format('DD/MM/yyyy')}
                                            </ETableCell>

                                            <ETableCell style={{ minWidth: '250px' }} align="left">
                                                <ETypography
                                                    variant="subtitle1"
                                                    noWrap
                                                >
                                                    {employeeData?.fullname ? employeeData?.fullname : '-'}
                                                </ETypography>
                                                {employeeData?.employee_code ? employeeData?.employee_code : ''}
                                            </ETableCell>
                                            <ETableCell align="left">
                                                {transaction_category &&
                                                    transaction_category == PAYMENT_CATEGORY_ADVANCE
                                                    ? 'Advance'
                                                    : transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
                                                        ? 'Reimbursement'
                                                        : transaction_category == PAYMENT_CATEGORY_SALARY
                                                            ? `Salary - ${moment(payrollData?.month_date).format('MMM YYYY')}`
                                                            : 'Other'}
                                            </ETableCell>
                                            <ETableCell align="right">
                                                {paid_amount ? numberWithCommas(paid_amount) : '-'}
                                            </ETableCell>
                                            <ETableCell align="right" >
                                            <div className="width-80per margin-auto"> 
                                                {accountant_remark && <ETooltip arrow title={accountant_remark}>
                                                    <EIcon icon={InfoRoundedIcon} />
                                                </ETooltip>}
                                                </div>
                                            </ETableCell>
                                        </ETableRow>
                                    );
                                })}
                                {payment?.PaySalaryListData?.summery?.count && payment?.PaySalaryListData?.summery?.count > 0 &&

                                    <ETableRow hover key={payment?.PaySalaryListData?.summery?.count + 1} >
                                        <ETableCell align="left" sx={{ width: '5px' }}></ETableCell>
                                        <ETableCell align="left"> </ETableCell>
                                        <ETableCell style={{ minWidth: '200px' }} align="left"> </ETableCell>
                                        <ETableCell align="left" style={{ minWidth: '150px' }}> </ETableCell>
                                        <ETableCell align="right" style={{ minWidth: '100px' }} className='bold-font'>
                                            <ETypography variant="h6" className="theme-main-text-color-bold">{total_paid_amt ? numberWithCommas(total_paid_amt) : '-'}</ETypography>
                                        </ETableCell>
                                        <ETableCell align="left" style={{ minWidth: '150px' }}></ETableCell>
                                    </ETableRow>
                                }


                            </ETableBody>
                        }
                    </ETable>
                </ETableContainer>
            </EScrollbar>

            {(payment?.PaySalaryListData?.summery?.count == 0 || Object.keys(payment?.PaySalaryListData).length == 0) &&
                !payment?.PaySalaryListData?.payrollListLoading && (
                    <SearchNotFound />
                )}
            {payment?.PaySalaryListData?.summery?.count > DEFAULT_ROWS_PERPAGE && (
                <ETablePagination
                    rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                    component="div"
                    count={payment?.PaySalaryListData?.summery?.count ? payment?.PaySalaryListData?.summery?.count : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </Grid>




    );
}

export default PaymentLogTalent;
