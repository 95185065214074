
import { Checkbox, styled } from "@mui/material";

export const ECheckbox = styled(Checkbox)(({ theme }) => ({
    // INFO: This code is written to change the checkbox svg color
    color: theme.palette.text.greyColor9,

    // INFO: This code is written to change the disabled checkbox svg color
    '&.Mui-disabled': {
        'svg': {
            color: theme.palette.input.Disabled
        }
    }
}));