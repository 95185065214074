import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { EStaticCalendar } from '../../BaseComponents/EStaticCalendar';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    '& .MuiPickersCalendar-root': {
      minWidth: 0,
    },
    '& .MuiPickersBasePicker-pickerView': {
      width: '100% !imporant',
    },
    '.PrivateDatePickerToolbar-penIcon' : {
      visibility: 'hidden'
    }
  },
}));



const Calendar = () => {
    const classes = useStyles();
    const [value, setValue] = useState(new Date())


  return (
    <>
    
    <LocalizationProvider dateAdapter={AdapterDateFns}>
                 <Grid item >
                    <EStaticCalendar 
                        sx={{
                          '& .PrivateDatePickerToolbar-penIcon': {display: 'none'},
                        }}
                        className={classes.datePicker}
                        showToolbar={false}
                        value={value} 
                        orientation='landscape' 
                        views={["day"]}  //we can not change month from dropdown
                        componentsProps={{
                            actionBar: {actions: []},
                        }} 
                        onChange={()=>{}}
                        readOnly = {true}
                        renderInput={(params) => <TextField {...params} fullWidth/>}
                         />
                </Grid>  
    </LocalizationProvider>
    </>
  )
}

export default Calendar