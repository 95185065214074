import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { FormControl, FormControlLabel, Grid } from '@mui/material';
import Page from '../../BaseComponents/EPage';
import { EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from '../../BaseComponents/EButtons';
import { ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ECardBasic, ECardDashboard } from '../../BaseComponents/ECard';
import { EBoxPage } from '../../BaseComponents/EBox';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { Stack } from '@mui/system';
import EHidden from '../../BaseComponents/EHidden';
import { EIcon } from '../../BaseComponents/EIcon';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { GET_PAYROLL_SETTING_API, PERMISSIONS, SET_PAYROLL_SETTING_API, XL } from '../../constants';
// import { SalarySlipContainer } from '../../BaseComponents/EContainer';// INFO: COMMENTED FOLLOWING CODE FOR NOW, CODE IS FOR SALARY SLIP
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingGrid } from '../../BaseComponents/EGrid';
import { ReturnBoolean } from '../../utils/CommonFunctions';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ETextField } from '../../BaseComponents/ETextField';
import { PayrollSettingValidation } from '../../utils/validation';
import ESwitch from '../../BaseComponents/ESwitch';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { SetPayrolllSettings } from '../../action/SettingAction';
import { useCheckPermission } from '../../hooks';



function PayrollSettings(props) {

    const { auth } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const user = useContext(UserContext);

    const settingsContext = useCompanySettingProvider();
    const company = useSelector(state => state.CompanyReducer)


    // const settingData=settingsContext?.setting?.leaveSetting? settingsContext?.setting?.leaveSetting: {}

    const settingData = settingsContext.setting.payrollSettingData;
    // const [data, setData] = useState([]) // INFO: COMMENTED FOLLOWING CODE FOR NOW, CODE IS FOR SALARY SLIP

    // State to handle toggle state
    const [defaultSetting, setDefaultSetting] = useState(settingData?.is_default_setting !== null ? settingData?.is_default_setting : null)

    // Default settings
    const companyDefaultSetting = {
        is_default_setting: true,
        salarySlip: '',
        payroll_is_auto: settingData?.companyDefaultSetting?.payroll_is_auto ? true : false,
        payroll_auto_generation_date: settingData?.companyDefaultSetting?.payroll_auto_generation_date ? settingData?.companyDefaultSetting?.payroll_auto_generation_date : null
    }


    // Company settings
    const companySetting = {
        is_default_setting: false,
        salarySlip: '',
        payroll_is_auto: settingData?.companySetting?.payroll_is_auto ? true : false,
        payroll_auto_generation_date: settingData?.companySetting?.payroll_auto_generation_date ? settingData?.companySetting?.payroll_auto_generation_date.toString() : null  //Using toString to convert the value to string to avoid EAutoComplete warning
    }
    const formik = useFormik({
        initialValues: defaultSetting ? companyDefaultSetting : companySetting,
        validationSchema: PayrollSettingValidation,
        onSubmit: (data) => {
            setDefaultSetting(false)
            let formdata = { ...data, is_default_setting: false }
            dispatch(SetPayrolllSettings(auth.authtoken, formdata, SET_PAYROLL_SETTING_API, navigate))
        }
    });
    const { errors, touched, setErrors, getFieldProps, setFieldValue, handleSubmit } = formik;

    const allowEdit = useCheckPermission([PERMISSIONS.ViewEditPayrollSettings])

    // INFO: This API for list
    // useEffect(() => {
    //     if (auth.authtoken) {
    //         dispatch(GetSetting(auth.authtoken, GET_PAYROLL_SETTING_API));
    //     }
    // }, [auth.authtoken]);

    // INFO: This useEffect is for setting initial data coming from API from API
    useEffect(() => {
        // if (settingData?.companySetting && settingData?.settingLoading == false) {
        setDefaultSetting(settingData?.is_default_setting !== null ? settingData?.is_default_setting : null)
        setFieldValue('payroll_is_auto', settingData?.companySetting?.payroll_is_auto)

        if (settingData?.companySetting?.payroll_auto_generation_date) {
            const date_Object = Date_Array.find((i) => {
                return i.id == settingData?.companySetting?.payroll_auto_generation_date
            });
            setFieldValue('payroll_auto_generation_date', date_Object)
        }

    }, [auth.authtoken, settingsContext?.setting?.settingLoading]);




    // Handle setting toggle
    const handleDefaultSetting = () => {
        setDefaultSetting(() => !defaultSetting)
        if (!defaultSetting == true) {
            dispatch(SetPayrolllSettings(auth.authtoken, companyDefaultSetting, SET_PAYROLL_SETTING_API, navigate))
        }
    }


    return (
        <Page title="Payroll Settings" >
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Payroll Settings'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Setting', href: '' },
                            {
                                name: 'Payroll Settings',
                            }
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined variant="outlined" onClick={() => navigate('/')} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid
                        container
                        display="row"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <Grid item xs={12} sm={12} className='display-flex justify-content-space-between'>
                            <ETypographyPageHeading> Payroll Settings </ETypographyPageHeading>

                            {/* If onBoarding is completed, show switch, else don't */}
                            {
                                company?.companyData?.onBoarding?.onboarding_is_completed 
                                ?
                                <ESwitch check={defaultSetting} handleToggle={handleDefaultSetting} label={defaultSetting ? "Default" : "Custom"} disabled={!allowEdit} />
                                :
                                null
                            }

                            <EHidden width="mdUp">
                                <EButtonOutlinedIcon variant="outlined" onClick={() => navigate('/')} className='button-left-margin'>
                                    <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                            </EHidden>
                        </Grid>

                        <Grid item xs={12} sm={12} >
                            {settingData?.globalSettingLoading ?
                                <LoadingGrid size={XL} isPage={true} />
                                :
                                <EBoxPage className='p0'>
                                    <ECardBasic className='mb-0px border-05px-border6 '>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                                <ETypography className='bold-600 '>
                                                    Autopilot Payroll Date
                                                </ETypography>
                                                <ETypography className=' greyColor4-color'>This feature allows you to auto disburse salary of your talents on the selected date.</ETypography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align='right'>
                                                <FormControl component="fieldset" {...getFieldProps('payroll_is_auto')} >
                                                    <ERadioGroup row={true} aria-label="payroll_is_auto" name="payroll_is_auto"
                                                        value={formik?.values?.payroll_is_auto != null ? ReturnBoolean(formik?.values?.payroll_is_auto) : null}>
                                                        <FormControlLabel value={true} control={
                                                            <ERadio className=' px-6px'
                                                                disabled={(!allowEdit || defaultSetting) ? true : false}
                                                                onChange={(e, value) => {
                                                                    setFieldValue('payroll_is_auto', true)
                                                                }}
                                                            />
                                                        } label={<span className='font-size-16px'>Yes</span>} />
                                                        <FormControlLabel value={false} control={
                                                            <ERadio className=' px-6px'
                                                                disabled={(!allowEdit || defaultSetting) ? true : false}
                                                                onChange={(e, value) => {
                                                                    setFieldValue('payroll_is_auto', false)
                                                                }}
                                                            />
                                                        } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                    </ERadioGroup>
                                                </FormControl>
                                            </Grid>

                                            {ReturnBoolean(formik?.values?.payroll_is_auto) ? <>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                    <ECardDashboard className={'Bg-card5 border-none shadow-none'} >
                                                        <EAutocomplete
                                                            name="Leave Type"
                                                            {...getFieldProps('payroll_auto_generation_date')}
                                                            options={Date_Array && Date_Array?.length > 0 ? Date_Array : []}
                                                            forcePopupIcon={formik?.values?.payroll_auto_generation_date && formik?.values?.payroll_auto_generation_date.length > 0 ? false : true}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            className='maxWidth-300px'
                                                            onChange={(e, value) => {
                                                                setFieldValue('payroll_auto_generation_date', value ? value : null)
                                                            }}
                                                            disabled={!allowEdit}
                                                            renderInput={params => (
                                                                <ETextField
                                                                    {...params}
                                                                    name='payroll_auto_generation_date'
                                                                    placeholder="Select Payroll Generation Date"
                                                                    error={Boolean(touched.payroll_auto_generation_date && errors.payroll_auto_generation_date)}
                                                                    helperText={touched.payroll_auto_generation_date && errors.payroll_auto_generation_date}
                                                                />
                                                            )}
                                                        />
                                                    </ECardDashboard>
                                                </Grid>
                                                <Grid item>

                                                    <span className='bold-600'>Note:</span> <br />
                                                    <ul className='ml-25px font-size-14px'>
                                                        <li>The payroll for the previous month will be automated on the selected date of the current month.
                                                            Eg. If you have selected "17th of every month", then payroll for January (previous month) will be automated on the 17th of February (current month).
                                                        </li>
                                                        <li>If the selected date is between the 29th to 31st, then for the month of February(with 28 or 29 days), the payroll will be automated on the last day of the month.
                                                        </li>
                                                    </ul>
                                                </Grid>

                                            </>
                                                : ''}

                                        </Grid>
                                    </ECardBasic>

                                    <Stack direction="row" className='justify-content-flex-end mt-16px'>
                                        {
                                           (allowEdit && !defaultSetting) && <ELoadingButton type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'> { company?.companyData?.onBoarding?.onboarding_is_completed ? "Save" : "Save & Next"} </ELoadingButton>
                                        }
                                    </Stack>
                                </EBoxPage>
                            }
                        </Grid>

                    </Grid>
                </Form>
            </FormikProvider>
        </Page>
    );
}
export default PayrollSettings

// INFO: The following array is hard coded for date Autocomplete dropdown
const Date_Array = [
    {
        id: 1,
        label: '1st of Every Month'
    },
    {
        id: 2,
        label: '2nd of Every Month'
    },
    {
        id: 3,
        label: '3rd of Every Month'
    },
    {
        id: 4,
        label: '4th of Every Month'
    },
    {
        id: 5,
        label: '5th of Every Month'
    },
    {
        id: 6,
        label: '6th of Every Month'
    },
    {
        id: 7,
        label: '7th of Every Month'
    },
    {
        id: 8,
        label: '8th of Every Month'
    },
    {
        id: 9,
        label: '9th of Every Month'
    },
    {
        id: 10,
        label: '10th of Every Month'
    },
    {
        id: 11,
        label: '11th of Every Month'
    },
    {
        id: 12,
        label: '12th of Every Month'
    },
    {
        id: 13,
        label: '13th of Every Month'
    },
    {
        id: 14,
        label: '14th of Every Month'
    },
    {
        id: 15,
        label: '15th of Every Month'
    },
    {
        id: 16,
        label: '16th of Every Month'
    },
    {
        id: 17,
        label: '17th of Every Month'
    },
    {
        id: 18,
        label: '18th of Every Month'
    },
    {
        id: 19,
        label: '19th of Every Month'
    },
    {
        id: 20,
        label: '20th of Every Month'
    },
    {
        id: 21,
        label: '21st of Every Month'
    },
    {
        id: 22,
        label: '22nd of Every Month'
    },
    {
        id: 23,
        label: '23rd of Every Month'
    },
    {
        id: 24,
        label: '24th of Every Month'
    },
    {
        id: 25,
        label: '25th of Every Month'
    },
    {
        id: 26,
        label: '26th of Every Month'
    },
    {
        id: 27,
        label: '27th of Every Month'
    },
    {
        id: 28,
        label: '28th of Every Month'
    },
    {
        id: 29,
        label: '29th of Every Month'
    },
    {
        id: 30,
        label: '30th of Every Month'
    },
    {
        id: 31,
        label: '31st of Every Month'
    }
]

// ***********______COMMENTED FOLLOWING CODE FOR NOW, FOLLOWING CODE IS FOR SALARY SLIP________******//

//  <ECardBasic className='mb-0px border-05px-border6 '>
//  <Grid container spacing={2}>
//      <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
//          <ETypography className='bold-600 '>
//          Autopilot Payroll Date
//          </ETypography>
//          <ETypography className=' greyColor4-color'>This feature allows you to auto disburse salary of your talents on the selected date.</ETypography>
//      </Grid>
//      <Grid item xs={12} sm={12} md={12} lg={12} xl={4} align='right'>
//      <FormControl component="fieldset" {...getFieldProps('payroll_is_auto')} >
//                  <ERadioGroup row={true} aria-label="payroll_is_auto" name="payroll_is_auto"
//                      value={formik?.values?.attendance_mode ? Number(formik?.values?.payroll_is_auto) : null}>
//                      <FormControlLabel value={true} control={
//                          <ERadio className=' px-6px'
//                              onChange={(e, value) => {
//                                  setFieldValue('payroll_is_auto', true)
//                              }}
//                          />
//                      } label={<span className='font-size-16px'>Yes</span>} />
//                      <FormControlLabel value={false} control={
//                          <ERadio className=' px-6px'
//                              onChange={(e, value) => {
//                                  setFieldValue('payroll_is_auto', false)
//                              }}
//                          />
//                      } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
//                  </ERadioGroup>
//              </FormControl>
//      </Grid>
//      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
//          <ECardDashboard className={'Bg-card5 border-none shadow-none'} >
//              <Grid container spacing={2}>
//                  {data &&
//                      data?.map((row, index) => {
//                          const { id, sample_image_path, is_active, is_selectable } = row;
//                          const src = '/assets/images/' + sample_image_path
//                          return (
//                              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>

//                                  <SalarySlipContainer key={id} elevation={5}>
//                                      <FormControlLabel
//                                          value={id}
//                                          className='m0px'
//                                          control={
//                                              <ERadio
//                                                  disabled={is_selectable ? false : true}
//                                                  checked={is_active ? true : false}
//                                                  onChange={(e, value) => {
//                                                      if (is_selectable) {
//                                                          HandleChange(index, id)
//                                                      }
//                                                  }}
//                                              />
//                                          }
//                                          label={<ETypography>{`Format ${id}`}</ETypography>}
//                                      />
//                                      <EBox
//                                          onClick={() => { if (is_selectable) { HandleChange(index, id) } }}
//                                          component="img"
//                                          sx={{ height: '100%', width: '100%' }}
//                                          src={src}
//                                      />
//                                  </SalarySlipContainer>
//                              </Grid>
//                          );
//                      })}
//              </Grid>
//          </ECardDashboard>
//      </Grid>
//  </Grid>
// </ECardBasic>