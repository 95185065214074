import React from 'react';
import { Box, Grid } from '@mui/material';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import FilePDF from '@iconify/icons-material-symbols/picture-as-pdf-rounded';
import FileCSV from '@iconify/icons-material-symbols/file-present-rounded';
import { DOWNLOAD_CSV, DOWNLOAD_PDF, TALENT_LIST_DOWNLOAD_URL, ACTIVE_USER } from '../../constants';
import EMenuPopover, { EMenuIcon, EMenuItem } from '../../BaseComponents/EMenuPopover';
import { EIconButton } from '../../BaseComponents/EButtons';
import SortByAlphaIcon from '@iconify/icons-material-symbols/sort-by-alpha';
import { EIcon } from '../../BaseComponents/EIcon';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ETextFieldSearch } from '../../BaseComponents/ETextField';
import { EBox } from '../../BaseComponents/EBox';
import { DownLoadFile } from '../../action/AdminAction';
import { useSelector } from 'react-redux';
import { ETooltip } from '../../BaseComponents/ETooltip';
/**
 * [2022-22-11]
 * Author: Aanchal Sahu
 **/
function UserSettingSearchForm(props) {
    const { search, setSearch, auth } = props
    const employeeListData = useSelector(state => state.EmployeeReducer);
    const { customRoleData = [] } = useSelector((state) => state.CustomUserRole)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const ReportDownload = (value) => {
        let url = TALENT_LIST_DOWNLOAD_URL;
        let file_name = 'User_list';
        if (value != null) { url += `?download_format=${value}&auth_list=true&user_status=${ACTIVE_USER}`; }
        if (search?.name && search?.name != null) { url += `&fullname=${search?.name}`; }
        if (search?.role != null) { url += `&role=${search?.role}`; }
        if (search?.sort != null) { url += `&sort=${search?.sort}`; }
        DownLoadFile(auth.authtoken, url, file_name)
        setAnchorEl(null);
    }

    //Filtering Active Talents for Talent List on the basis of status and show as talent
    const ActiveTalents = employeeListData?.allEmployeeData?.rows?.filter((row) => {
        return row?.status == ACTIVE_USER
    })

    return (
        <Grid container className='mb-16px'>
            <Grid item xs={11} sm={11} lg={9} md={9} xl={10} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={5} sm={4} md={4} lg={4} xl={3}>
                        <EAutocomplete
                            name="name"
                            fullWidth
                            forcePopupIcon={search?.name ? false : true}
                            options={
                                ActiveTalents && ActiveTalents?.length > 0
                                    ? ActiveTalents
                                    : []
                            }
                            onChange={(e, value) => {
                                setSearch({ ...search, name: (value?.label ? value?.label : "") })
                            }}
                            renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
                                <Box component="li" {...props} key={option.id}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <ETextFieldSearch
                                    {...params}
                                    placeholder="Select Name"
                                    name="name"
                                    size="small"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={5} sm={4} md={4} lg={4} xl={3}>
                        <EAutocomplete
                            name="Search by Role"
                            fullWidth
                            forcePopupIcon={search.role_object ? false : true}
                            options={customRoleData}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={search.role}
                            onChange={(e, value) => {
                                setSearch({ ...search, role_object: (value ? value : null) })
                            }}
                            renderInput={params => (
                                <ETextFieldSearch
                                    {...params}
                                    name='role'
                                    size='small'
                                    placeholder="Search by Role"

                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={2} >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <EBox className="display-flex align-center">
                    <ETooltip title={'Download'} arrow>
                        <EIconButton onClick={handleClick}>
                            <EIcon icon={FileDownloadIcon} className='height-width-22px' />
                        </EIconButton>
                        </ETooltip>
                        <ETooltip title={'Sort by name'} arrow>
                        <EIconButton
                            onClick={(e, value) => { setSearch({ ...search, sort: search.sort == null ? 'fullname ASC' : search.sort == 'fullname ASC' ? 'fullname DESC' : 'fullname ASC' }) }}
                        >
                            <EIcon icon={SortByAlphaIcon} className='height-width-22px' />
                        </EIconButton>
                        </ETooltip>
                    </EBox>
                </Grid>
            </Grid>
            <EMenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                sx={{ width: 180, top: 40 }}
            >
                <EMenuItem
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    onClick={() => ReportDownload(DOWNLOAD_PDF)}
                >
                    <EMenuIcon icon={FilePDF} />  PDF
                </EMenuItem>
                <EMenuItem
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    onClick={() => ReportDownload(DOWNLOAD_CSV)}
                >
                    <EMenuIcon icon={FileCSV} /> CSV
                </EMenuItem>
            </EMenuPopover>

        </Grid>
    );
}

export default UserSettingSearchForm