import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import base64 from 'base-64'
import {
  SERVER_URL,
  CONTRACT_LIST_LOADING,
  CONTRACT_LIST_SUCCESS,
  CONTRACT_LIST_FAILURE,
  CONTRACT_LOADING,
  CONTRACT_SUCCESS,
  CONTRACT_FAILURE,TAC_LIST_LOADING,
  TAC_LIST_SUCCESS,
  TAC_LIST_FAILURE,
  TAC_LOADING,
  TAC_SUCCESS,
  TAC_FAILURE,
  TALENT,
  CONTRACT_PAYMENT_DETAILS_UPDATE_API,
  CONTRACT_TNC_UPDATE_API
} from '../constants';
import { Logout } from './AuthAction';
import moment from 'moment';


export function ContractListData(token, data, page = null, pageSize = null) {
  let queryString = '';
  if (data?.name && data?.name != null) {
    queryString += `query=${data?.name}`;
  }

  if (page !== null  && pageSize !== null) {
    
    queryString +=(queryString?'&':'')+`page=${page}&per_page=${pageSize}`;
  }
  if (data?.freelancer_name != null) {
    queryString +=(queryString?'&':'')+ `freelancer_name=${data?.freelancer_name}`;
  }
  
  if (data?.sort) {
    queryString += (queryString?'&':'')+`sort=${data?.sort}`
  }

  if (data?.year_month) {
    queryString += (queryString?'&':'')+`year_month=${data?.year_month}`
  }
  if (data?.incharge_id!=null) {
    queryString +=(queryString?'&':'')+`incharge_id=${data?.incharge_id}`
  }

  return (dispatch) => {
    dispatch(contractListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/contract/contract-list?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(contractListSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(contractListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

//this action is used for creating the contract and terminating the contract, i'm sending the terminatiion url to isUrl variable
export function CreateContract(token, Data, navigate,isUrl=null) { 
    const data = new FormData();
    let URLValue=isUrl?isUrl:'contract/create-contract';
    if(!isUrl)
   { data.append('contract_title',Data.contract_title);
    data.append('contract_start_date',moment(Data.contract_start_date).format('yyyy-MM-DD'));
    data.append('contract_end_date',moment(Data.contract_end_date).format('yyyy-MM-DD'));
    data.append('contract_amount',Data.contract_amount);
    data.append('total_amount',Data.contract_with_gst);
    data.append('freelancer_id',Data.freelancer);
    data.append('incharge_id',Data.incharge);
    data.append('gst',Data.gst); 
    data.append('advance_applicable',Data.isAdvance); 
    data.append('created_by',Data.created_by); 
    
    if(Data.is_igst!=null){data.append('is_igst',Data.is_igst); }
    if(Data.terms_cond){data.append('terms_cond',Data.terms_cond); }
    if(Data.terms_cond_text){data.append('terms_cond_text',Data.terms_cond_text); }
   }
    else {
      data.append('contract_id',Data.contract_id);
      data.append('termination_by',Data.termination_by); 
      if(Data.termination_last_working_date){data.append('termination_last_working_date',Data.termination_last_working_date); }
      if(Data.termination_settlement_date){data.append('termination_settlement_date',Data.termination_settlement_date); }
      data.append('termination_remark',Data.termination_remark); 
    }
    
  return (dispatch) => {
    dispatch(contractLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/${URLValue}`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          });
         if(!isUrl) {navigate(`/freelancer-contract/view-contract/${base64.encode(res.data.data.id)}`)}//freelancer-contract/view-contract/:id
         else{ navigate(`/freelancer-contract/view-contract/${base64.encode(Data.contract_id)}`)}
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(contractFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function DeleteContract(token, Data,close=null) { 
  const data = new FormData();
  data.append('contract_id',Data.contract_id);
  
return (dispatch) => {
  dispatch(contractListLoading());
  axios({
    method: 'post',
    url: `${SERVER_URL}/contract/delete-contract`,
    headers: {
      "Authorization": 'Bearer ' + token,
    },
    data: data
  })
    .then((res) => {
      if (res.status === 200) {
        Success.fire({
          text: "html",
          html: `<span style="color:white">${res.data.message}</span>`,
        });
        if(close){close()}
        dispatch(ContractListData(token))
      }
    }).catch((error) => {
      if (error?.response) {
        Errors.fire({
          text: "html",
          html: `<span style="color:white">${error.response.data.message}</span>`,
        })
        if(close){close()}  
        // dispatch(contractFailure(error.response.data));
        dispatch(ContractListData(token))
        if (error?.response?.status == 401) {
          dispatch(Logout())
        }
      }
    });
};
}


export function contractListLoading() {
    return {
      type: CONTRACT_LIST_LOADING,
    };
  }
  export function contractListSuccess(payload) {
    return {
      type: CONTRACT_LIST_SUCCESS,
      payload: payload
    };
  }

  export function contractListFailure(payload) {
    return {
      type: CONTRACT_LIST_FAILURE,
      payload
    };
  }



  export function contractLoading() {
    return {
      type: CONTRACT_LOADING,
    };
  }
  export function contractSuccess(payload) {
    return {
      type: CONTRACT_SUCCESS,
      payload: payload
    };
  }

  export function contractFailure(payload) {
    return {
      type: CONTRACT_FAILURE,
      payload
    };
  }

export function ContractViewData(token, id) {
  let queryString = '';
  if (id != null) {
    queryString += `contract_id=${id}`;
  }

  return (dispatch) => {
    dispatch(contractLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/contract/view-contract-detail?${queryString}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(contractSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(contractFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function LockContractView(token, id) {
  const data = new FormData();
    data.append('contract_id',id);

  return (dispatch) => {
    dispatch(contractLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/contract/lock-contract`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(contractSuccess(res.data));
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(ContractViewData(token, id));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(contractFailure(error.response.data));
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(ContractViewData(token, id));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function SaveContractPhase(token,Data, id) {
    const data = new FormData();
    data.append('contract_id',Data.contract_id);
    data.append('face_count',Data.face_count);
  
    return (dispatch) => {
      dispatch(contractLoading());
      axios({
        method: 'post',
        url: `${SERVER_URL}/contract/set-face-count`,
        crossDomain: true,
        headers: {
          Authorization: 'Bearer ' + token
        },
        data
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch(contractSuccess(res.data));
            Success.fire({
              text: "html",
              html: `<span style="color:white">${res.data.message}</span>`,
            })
            dispatch(ContractViewData(token,Data.contract_id))
            
          }
        })
        .catch((error) => {
          if (error?.response) {
            dispatch(contractFailure(error.response.data));
            Errors.fire({
              text: "html",
              html: `<span style="color:white">${error.response.data.message}</span>`,
            })
            dispatch(ContractViewData(token,Data.contract_id))
            if (error?.response?.status == 401) {
              dispatch(Logout())
            }
          }
        });
    };
  }

//used in following contract view page
// 1. Update contract details 2. Update terms and conditions 3. Update payment details 
export function PaymentTermsCreateUpdate(token, Data,close=null,page=null) {
  const data = new FormData();
  let url=''
  data.append('contract_id',Data.contract_id);
 
  if (page == CONTRACT_PAYMENT_DETAILS_UPDATE_API) {
    url+=CONTRACT_PAYMENT_DETAILS_UPDATE_API
    data.append('contract_amount', Data.contract_amount);
    data.append('gst', Data.gst);
    if (Data.is_igst != null) {
      data.append('is_igst', Data.is_igst);
    }
    if (Data.total_amount != null) { data.append('total_amount', Data.total_amount); }
    data.append('advance_applicable', Data.isAdvance);
  }
  else if(page == CONTRACT_TNC_UPDATE_API){
    url+=CONTRACT_TNC_UPDATE_API
      data.append('terms_cond', Data.terms_cond);
      if (Data.terms_cond_text) { data.append('terms_cond_text', Data.terms_cond_text); }
  }
  else {
    url+='/update-contract'
    data.append('contract_title', Data.contract_title);
    data.append('contract_start_date', Data.contract_start_date);
    data.append('contract_end_date', Data.contract_end_date);
  }

  return (dispatch) => {
    dispatch(contractLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/contract${url}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(contractSuccess(res.data));
          if(close){close()}
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
          
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(contractFailure(error.response.data));
          if(close){close()}
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

// used in two places- to update freelancer and to update  incharge in contract view
export function ContractFreelancerInchargeUpdate(token, Data,close=null,user=null) {
  const data = new FormData();
  let url=`contract`;
  data.append('contract_id',Data?.contract_id);

  if(user==TALENT){
    url+=`/update-contract-incharge`;
    data.append('incharge_id',Data?.incharge?.id);}
  else{
    url+=`/update-contract-freelancer`;
    data.append('freelancer_id',Data?.freelancer?.id);
  }

  return (dispatch) => {
    dispatch(contractLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/${url}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(contractSuccess(res.data));
          if(close){close()}
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
          
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(contractFailure(error.response.data));
          if(close){close()}
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function DeliverableCreateUpdateDelete(token, Data, action) {
  const data = new FormData();

let url='contract/';
  if(action=="create"){
    url+='create-contract-detail';
    data.append('contract_id',Data?.contract_id);
    data.append('work_detail',Data?.work_detail);
    data.append('work_title',Data?.work_title);
    data.append('created_by',Data?.created_by);
  }
  else if(action=="delete"){
    url+='delete-contract-detail';
    data.append('contract_detail_id',Data?.contract_detail_id);
  }
  else if(action=="update"){
    url+='update-contract-detail';
    data.append('contract_detail_id',Data?.contract_detail_id);
    data.append('work_detail',Data?.work_detail);
    data.append('updated_by',Data?.updated_by);
    data.append('work_title',Data?.work_title);
  }

  return (dispatch) => {
    dispatch(contractLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/${url}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(contractSuccess(res.data));
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
          
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(contractFailure(error.response.data));
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function PaymentCreateUpdateDelete(token, Data, action) {
  const data = new FormData();

let url='contract/';
  if(action=="create"){
    url+='payment-terms';
    if(Data?.face_no!=0){
      let work_detail_id = JSON.stringify(Data?.work_detail_id)
      data.append('work_detail_id',work_detail_id);
    }
    data.append('contract_id',Data?.contract_id);
    data.append('amount',Data?.amount);
    data.append('amount_percent',Number(Data?.percentage));
    data.append('face_id',Data?.face_id);
    data.append('is_advance',Data?.is_advance);
    if(Data?.remark){data.append('remark',Data?.remark);}
  }
  else if(action=="delete"){
    url+='delete-payment-terms';
    data.append('payment_sequence_id',Data?.payment_sequence_id);
  }
  else if(action=="update"){
    url+='payment-terms';
    let work_detail_id = JSON.stringify(Data?.work_detail_id)
    data.append('contract_id',Data?.contract_id);
    data.append('work_detail_id',work_detail_id);
    data.append('is_advance',Data?.is_advance);

    if(Data.payment_sequence_id){
      data.append('payment_sequence_id',Data.payment_sequence_id);
    }
    
    data.append('amount',Data?.amount);
  }

  return (dispatch) => {
    dispatch(contractLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/${url}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(contractSuccess(res.data));
          // close!=null && close()
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
          
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(contractFailure(error.response.data));
          // close!=null && close()
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function tacListLoading() {
  return {
    type: TAC_LIST_LOADING,
  };
}
export function tacListSuccess(payload) {
  return {
    type: TAC_LIST_SUCCESS,
    payload: payload
  };
}

export function tacListFailure(payload) {
  return {
    type: TAC_LIST_FAILURE,
    payload
  };
}



export function tacLoading() {
  return {
    type: TAC_LOADING,
  };
}
export function tacSuccess(payload) {
  return {
    type: TAC_SUCCESS,
    payload: payload
  };
}

export function tacFailure(payload) {
  return {
    type: TAC_FAILURE,
    payload
  };
}

export function TandCListData(token, data=null, page = null, pageSize = null) {

  let queryString = '';
  if (page !== null  && pageSize !== null) {
    queryString += `page=${page}&per_page=${pageSize}`;
  }
  if (data?.name != null) {
    queryString += `&freelancer_name=${data?.name}`;
  }
  
  if (data?.sort!=null) {
    queryString +=`&sort=${data?.sort}`
  }

  return (dispatch) => {
    dispatch(tacListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/contract/tnc/tnc-list?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(tacListSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(tacListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function TaCCreateUpdateDelete(token, Data, action,close=null) {
  const data = new FormData();
let url='contract/tnc/';
  if(action=='create'){
    url+='create';
    data.append('terms_title',Data?.terms_title);
    data.append('status',Data?.status);
    data.append('created_by',Data?.created_by);
  }
  if(action=='update'){
    url+='update';
    data.append('terms_title',Data?.terms_title);
    data.append('status',Data?.status);
    data.append('updated_by',Data?.updated_by);
    data.append('tncid',Data?.tncid);
  }
  if(action=='delete'){
    url+='delete-tnc';
    data.append('tncid',Data?.tncid);
  }
  
  return (dispatch) => {
    dispatch(tacLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/${url}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(tacSuccess(res.data));
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          if(close!=null){close()}
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(tacFailure(error.response.data));
          if(close!=null){close()}
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function DeliverableStatusUpdate(token, Data) {
    const data = new FormData();

    let url='contract/update-contract-detail-status';
    data.append('status',Data?.status);
    data.append('contract_id',Data?.contract_id);
    data.append('contract_detail_id',Data?.contract_detail_id);
    data.append('remark',Data?.remark);

  return (dispatch) => {
    dispatch(contractLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/${url}`,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(contractSuccess(res.data));
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(contractFailure(error.response.data));
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(ContractViewData(token,Data.contract_id))
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}