import { Grid, InputAdornment, Stack } from "@mui/material";
import { ETextField } from "../../BaseComponents/ETextField";
import { useDispatch } from "react-redux";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import { EditFormBankDetailsValidation } from "../../utils/validation";
import { EButtonOutlined, EIconButton, ELoadingButton } from "../../BaseComponents/EButtons";
import Search from '@iconify/icons-mdi/search';
import { Icon } from '@iconify/react';
import ifsc from 'ifsc-finder';
import { UpdateBasicFreelancerDetailsAction } from "../../action/FreelancerAction";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { FREELANCER_BANK_DETAILS_UPDATE } from "../../constants";

const FreelancerEditModalBankDetails = (props) => {
    const { auth, freelancerData, closeModalEditBankDetail, freelancerLoading } = props;
    const dispatch = useDispatch();
    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            bank_detail_check: true,
            ifsc_code: freelancerData?.ifsc_code ? freelancerData?.ifsc_code : '',
            bank_name: freelancerData?.bank_name ? freelancerData?.bank_name : '',
            branch_name: freelancerData?.branch_name ? freelancerData?.branch_name : '',
            account_no: freelancerData?.account_no ? freelancerData?.account_no : '',
            account_holder_name: freelancerData?.account_holder_name ? freelancerData?.account_holder_name : '',
            freelancer_id: freelancerData?.id,
            updated_by: auth.authData?.id,
        },
        validationSchema: EditFormBankDetailsValidation,
        onSubmit: (data) => {
            data = { ...data, company_id: auth.authData?.company_id }
            dispatch(UpdateBasicFreelancerDetailsAction(auth.authtoken, data, FREELANCER_BANK_DETAILS_UPDATE, null))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    function CheckIFSCCode() {
        const ifscCode = getFieldProps('ifsc_code').value.toUpperCase();
        ifscCode && ifsc.get(ifscCode).then(function (res) {
            if (typeof (res) == 'object') {
                setFieldValue("bank_name", res.BANK)
                setFieldValue("branch_name", res.BRANCH)
                setFieldValue("ifsc_code", ifscCode)
            } else {
                setFieldValue("bank_name", "")
                setFieldValue("branch_name", "")
            }
        })
    }

    return (

        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                <Grid container spacing={2}>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'IFSC Code'}>
                            <ETextField
                                fullWidth
                                {...getFieldProps('ifsc_code')}
                                placeholder="Search IFSC Code"
                                error={Boolean(touched.ifsc_code && errors.ifsc_code)}
                                helperText={touched.ifsc_code && errors.ifsc_code}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EIconButton
                                                aria-label="Toggle password visibility"
                                                onClick={() => CheckIFSCCode()}
                                                size="large">
                                                <Icon icon={Search} />
                                            </EIconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Bank Name'}>
                            <ETextField
                                fullWidth
                                {...getFieldProps('bank_name')}
                                error={Boolean(touched.bank_name && errors.bank_name)}
                                helperText={touched.bank_name && errors.bank_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'Branch Name'}>
                            <ETextField
                                fullWidth
                                {...getFieldProps('branch_name')}
                                error={Boolean(touched.branch_name && errors.branch_name)}
                                helperText={touched.branch_name && errors.branch_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={"Cardholder's Name"}>
                            <ETextField
                                fullWidth
                                {...getFieldProps('account_holder_name')}
                                error={Boolean(touched.account_holder_name && errors.account_holder_name)}
                                helperText={touched.account_holder_name && errors.account_holder_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} >
                        <ELabelInputMainGrid label={'Account Number'}>
                            <ETextField
                                fullWidth
                                {...getFieldProps('account_no')}
                                error={Boolean(touched.account_no && errors.account_no)}
                                helperText={touched.account_no && errors.account_no}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                </Grid>

                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => closeModalEditBankDetail()}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton
                        loading={freelancerLoading}
                        size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                </Stack>

            </Form>
        </FormikProvider>
    )
};

export default FreelancerEditModalBankDetails;