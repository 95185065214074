import { Grid } from "@mui/material";
import moment from "moment";
import { EBox } from "../../../BaseComponents/EBox";
import { ECard } from "../../../BaseComponents/ECard";
import { EDivider } from "../../../BaseComponents/EDivider";
import { ELabelInputMainGrid, LoadingGrid } from "../../../BaseComponents/EGrid";
import { ETypographyCardHeading } from "../../../BaseComponents/ETypography";
import { CONTRACT_TERMINATED_BY_COMPANY, CONTRACT_TERMINATED_BY_FREELANCER, NIL } from "../../../constants";

export default function TerminationInfo(props) {
    const { contractDetails, contractData } = props

    return (
        <ECard className='card_design_1 theme-color-border shadow-none'>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="mb-8px"
            >
                <ETypographyCardHeading variant='h5'> Termination Details {contractData?.terminated_at?' - '+moment(contractData?.terminated_at).format('DD/MM/YYYY  HH:MM'):''} </ETypographyCardHeading>
            </Grid>

            <EDivider className="mb-16px borderColor-staticBorder7" />

            <EBox className="mb-16px" >
                {!contractDetails?.contractLoading ?
                    <Grid container spacing={2} className='px-16px'>
                    <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Last Working day'} isNotForm={true}>
                                {contractData?.termination_last_working_date ? moment(contractData?.termination_last_working_date).format('DD/MM/YYYY') : NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}  >
                            <ELabelInputMainGrid label={'Terminated by'} isNotForm={true} alignlabel={'align-top'}>
                            {contractData?.termination_by==CONTRACT_TERMINATED_BY_COMPANY ? contractData?.updatedBy?.fullname: 
                                contractData?.termination_by==CONTRACT_TERMINATED_BY_FREELANCER ? contractData?.Freelancer?.freelancer_name: NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}  >
                            <ELabelInputMainGrid label={'Initiated by'} isNotForm={true}>
                                {contractData?.termination_by==CONTRACT_TERMINATED_BY_COMPANY ? 'Company' : 
                                contractData?.termination_by==CONTRACT_TERMINATED_BY_FREELANCER ? 'Freelancer': NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} >
                            <ELabelInputMainGrid label={'Reason'} isNotForm={true} isfullgrid={true} >
                                {contractData?.termination_remark ? contractData?.termination_remark : NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                    </Grid>
                    :
                    <LoadingGrid />
                }
            </EBox>
        </ECard>
    )
}