import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import { LEAVE_FAILURE, LEAVE_LOADING, LEAVE_SUCCESS, LEAVE_LIST_FAILURE, LEAVE_LIST_SUCCESS, LEAVE_LIST_LOADING, SERVER_URL, LEAVE_MASTER_LOADING, LEAVE_MASTER_SUCCESS, LEAVE_MASTER_FAILURE, LEAVE_MASTER_LIST_LOADING, LEAVE_MASTER_LIST_SUCCESS, LEAVE_MASTER_LIST_FAILURE, ADD, UPDATE, PAGES, DEFAULT_ROWS_PERPAGE, DELETE } from '../constants';
import { Logout } from './AuthAction';

// export function CreateLeave(token, Data, navigate) {
//     // const data = new FormData();
//     // data.append('leave_name',Data.leave_name);
//     // data.append('status',Data.status);
//     // data.append('created_by',Data.created_by);  

//     return (dispatch) => {
//         dispatch(leaveLoading());
//         axios({
//             method: 'post',
//             url: `${SERVER_URL}/leave/create-leave`,
//             headers: {
//                 "Authorization": 'Bearer ' + token,
//             },
//             data: Data
//         })
//             .then((res) => {
//                 if (res.status === 200) {
//                     Success.fire({
//                         text: "html",
//                         html: `<span style="color:white">${res.data.message}</span>`,
//                     })
//                     dispatch(leaveSuccess(res.data))
//                     navigate('/setting/company/leave', { replace: true });
//                 }
//             }).catch((error) => {
//                 if (error?.response) {
//                     Errors.fire({
//                         text: "html",
//                         html: `<span style="color:white">${error.response.data.message}</span>`,
//                     })
//                     dispatch(leaveFailure(error.response.data));
//                     if (error?.response?.status == 401) {
//                         dispatch(Logout())
//                       }
//                 }
//             });
//     };
// }
// export function UpdateLeave(token, Data, navigate) {
//     const data = new FormData();
//     data.append('leave_name', Data.leave_name);
//     data.append('status', Data.status);
//     data.append('abbreviation', Data.abbreviation);
//     data.append('leave_type_id', Data.leave_type_id);
//     data.append('updated_by', Data.updated_by)
//     data.append('id', Data.leaveId)

//     return (dispatch) => {
//         dispatch(leaveLoading());
//         axios({
//             method: 'post',
//             url: `${SERVER_URL}/leave/update-leave`,
//             headers: {
//                 "Authorization": 'Bearer ' + token,
//             },
//             data
//         })
//             .then((res) => {
//                 if (res.status === 200) {
//                     Success.fire({
//                         text: "html",
//                         html: `<span style="color:white">${res.data.message}</span>`,
//                     })
//                     dispatch(leaveSuccess(res.data))
//                     navigate('/setting/company/leave', { replace: true });
//                 }
//             }).catch((error) => {
//                 if (error?.response) {
//                     Errors.fire({
//                         text: "html",
//                         html: `<span style="color:white">${error.response.data.message}</span>`,
//                     })
//                     dispatch(leaveFailure(error.response.data));
//                     if (error?.response?.status == 401) {
//                         dispatch(Logout())
//                       }
//                 }
//             });
//     };
// }

// export function ViewLeave(token, id) {

//     return (dispatch) => {
//         dispatch(leaveLoading());
//         axios({
//             method: 'get',
//             url: `${SERVER_URL}/leave/view-leave?id=${id}`,
//             headers: {
//                 "Authorization": 'Bearer ' + token,
//             },

//         })
//             .then((res) => {
//                 if (res.status === 200) {
//                     dispatch(leaveSuccess(res.data))
//                 }
//             }).catch((error) => {
//                 if (error?.response) {
//                     Errors.fire({
//                         text: "html",
//                         html: `<span style="color:white">${error.response.data.message}</span>`,
//                     })
//                     dispatch(leaveFailure(error.response.data));
//                     if (error?.response?.status == 401) {
//                         dispatch(Logout())
//                       }
//                 }
//             });
//     };
// }

export function leaveLoading() {
    return {
        type: LEAVE_LOADING,
    };
}
export function leaveSuccess(payload) {
    return {
        type: LEAVE_SUCCESS,
        payload
    };
}

export function leaveFailure(payload) {
    return {
        type: LEAVE_FAILURE,
        payload
    };
}


// export function LeaveList(token, Search = null, page = null, pageSize = null) {
//     let queryString = "";

//     if (page !== null && pageSize !== null) {
//         queryString += `&page=${page}&per_page=${pageSize}`;
//     }

//     if (Search && Search?.leave_type && Search?.leave_type !== null) {
//         queryString += `&leave_name=${Search?.leave_type}`;
//     }
//     if (Search && Search?.leave_type_id && Search?.leave_type_id !== null) {
//         queryString += `&leave_type_id=${Search?.leave_type_id}`;
//     }

//     return (dispatch) => {
//         dispatch(leaveListLoading());
//         axios({
//             method: 'get',
//             url: `${SERVER_URL}/leave/leave-list?${queryString}`,
//             crossDomain: true,
//             headers: {
//                 "Authorization": 'Bearer ' + token,
//             },
//         })
//             .then((res) => {
//                 if (res.status === 200) {
//                     dispatch(leaveListSuccess(res.data))
//                 }
//             }).catch((error) => {
//                 if (error?.response) {
//                     dispatch(leaveListFailure(error.response.data));
//                     if (error?.response?.status == 401) {
//                     }
//                 }
//             });
//     };
// }


export function leaveListLoading() {
    return {
        type: LEAVE_LIST_LOADING,
    };
}

export function leaveListSuccess(payload) {
    return {
        type: LEAVE_LIST_SUCCESS,
        payload
    };
}

export function leaveListFailure(payload) {
    return {
        type: LEAVE_LIST_FAILURE,
        payload
    };
}

// export function CreateLeaveCycle(token, Data, navigate) {
//     const data = new FormData();
//     data.append('leave_from_month', Data.from_month);
//     data.append('leave_to_month', Data.to_month);
//     data.append('updated_by', Data.updated_by);

//     return (dispatch) => {
//         dispatch(leaveLoading());
//         axios({
//             method: 'post',
//             url: `${SERVER_URL}/leave/create-leave-cycle`,
//             headers: {
//                 "Authorization": 'Bearer ' + token,
//             },
//             data
//         })
//             .then((res) => {
//                 if (res.status === 200) {
//                     Success.fire({
//                         text: "html",
//                         html: `<span style="color:white">${res.data.message}</span>`,
//                     })
//                     dispatch(leaveSuccess(res.data))
//                     dispatch(LeaveList(token, true))
//                     // navigate('/setting/company/leave', { replace: true });
//                 }
//             }).catch((error) => {
//                 if (error?.response) {
//                     Errors.fire({
//                         text: "html",
//                         html: `<span style="color:white">${error.response.data.message}</span>`,
//                     })
//                     dispatch(leaveFailure(error.response.data));
//                     if (error?.response?.status == 401) {
//                         dispatch(Logout())
//                       }
//                 }
//             });
//     };
// }

export function LeaveList(token, setting = false) {
    
    let qString = `setting=${setting}`

    return (dispatch) => {
        dispatch(leaveListLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/leave/leave-component-list?${qString}`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(leaveListSuccess(res.data))
                }
            }).catch((error) => {
                
                if (error?.response) {
                    dispatch(leaveListFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                      }
                }
            });
    };
}


/**
 * [2023-03-07]
 * changes by:-Aanchal Sahu
 * Description:- Commenting AddLeavesInGivenDuration due to no use
 */
// export function AddLeavesInGivenDuration(token, Data, navigate) {

//     return (dispatch) => {
//         dispatch(leaveLoading());
//         axios({
//             method: 'post',
//             url: `${SERVER_URL}/leave/update-day-company-component`,
//             headers: {
//                 "Authorization": 'Bearer ' + token,
//             },
//             data: Data
//         })
//             .then((res) => {
//                 if (res.status === 200) {
//                     Success.fire({
//                         text: "html",
//                         html: `<span style="color:white">${res.data.message}</span>`,
//                     })
//                     dispatch(leaveSuccess(res.data))
//                     dispatch(LeaveList(token, true))
//                     // navigate('/setting/company/leave', { replace: true });
//                 }
//             }).catch((error) => {
//                 if (error?.response) {
//                     Errors.fire({
//                         text: "html",
//                         html: `<span style="color:white">${error.response.data.message}</span>`,
//                     })
//                     dispatch(leaveFailure(error.response.data));
//                     if (error?.response?.status == 401) {
//                         dispatch(Logout())
//                       }
//                 }
//             });
//     };
// }


//____________________INFO: Leave Master APIs below_______________________

//INFO: This API is for add, update and delete of leave master__
export function CreateUpdateDelLeaveMaster(token, Data, Action, close = null) {

    const url = Action == ADD ? `create-leave-reason` : Action == DELETE ? `delete-leave-reason` : `update-leave-reason`;
    const data = new FormData();
    if (Action == UPDATE) { //This condition is id the Action is  UPDATE
        data.append('id', Data.id)
        data.append('updated_by', Data.updated_by);
        data.append('leave_reason', Data.leave_reason);
        data.append('status', Data.status);
    } else if(Action == ADD) {//This condition is id the Action is ADD
        data.append('created_by', Data.created_by);
        data.append('leave_reason', Data.leave_reason);
        data.append('status', Data.status);
    } else {
        data.append('id', Data.id) //This condition is id the Action==DELETE
    }

    return (dispatch) => {
        dispatch(leaveMasterLoading());
        axios({
            method: 'post',
            url: `${SERVER_URL}/leave/${url}`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
            data: Data
        })
            .then((res) => {
                if (res.status === 200) {
                    Success.fire({
                        text: "html",
                        html: `<span style="color:white">${res.data.message}</span>`,
                    })
                    dispatch(leaveMasterSuccess(res.data))
                    dispatch(LeaveMasterList(token, PAGES, DEFAULT_ROWS_PERPAGE))//Calling list api
                    if (close) { close() }
                }
            }).catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                    dispatch(leaveMasterFailure(error.response.data));
                    dispatch(LeaveMasterList(token, PAGES, DEFAULT_ROWS_PERPAGE))//Calling list api
                    if (close) { close() }
                    if (error?.response?.status == 401) {
                        // dispatch(Logout())
                    }
                }
            });
    };
}

//INFO: This API is for list of leave master__
export function LeaveMasterList(token, page = null, pageSize = null) {

    let queryString = "";

    if (page !== null && pageSize !== null) {
        queryString += `&page=${page}&per_page=${pageSize}`;
    }

    return (dispatch) => {
        dispatch(leaveMasterListLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/leave/list-leave-reasons?${queryString}`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(leaveMasterListSuccess(res.data))
                }
            }).catch((error) => {

                if (error?.response) {
                    dispatch(leaveMasterListFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        // dispatch(Logout())
                    }
                }
            });
    };
}

export function leaveMasterListLoading() {
    return {
        type: LEAVE_MASTER_LIST_LOADING,
    };
}
export function leaveMasterListSuccess(payload) {
    return {
        type: LEAVE_MASTER_LIST_SUCCESS,
        payload
    };
}

export function leaveMasterListFailure(payload) {
    return {
        type: LEAVE_MASTER_LIST_FAILURE,
        payload
    };
}

export function leaveMasterLoading() {
    return {
        type: LEAVE_MASTER_LOADING,
    };
}
export function leaveMasterSuccess(payload) {
    return {
        type: LEAVE_MASTER_SUCCESS,
        payload
    };
}

export function leaveMasterFailure(payload) {
    return {
        type: LEAVE_MASTER_FAILURE,
        payload
    };
}

