import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { FormikProvider, useFormik, Form } from 'formik';
import { UpdateInChargeValidation } from "../../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_USER, COMPLETE_STATUS, TALENT } from "../../../constants";
//actions
import { ContractFreelancerInchargeUpdate } from "../../../action/ContractAction";
//base components
import { ELabelInputMainGrid } from "../../../BaseComponents/EGrid";
import { EButtonOutlined, ELoadingButton } from "../../../BaseComponents/EButtons";
import { ETextField } from "../../../BaseComponents/ETextField";
import { EAutocomplete } from "../../../BaseComponents/EAutocomplete";
import { EmployeeAuthListData } from "../../../action/EmployeeAuthAction";

const EditModalInchargeDetails = (props) => {
    const { close, auth, contract } = props;
    const contractDetails = contract?.contractData
    const dispatch = useDispatch();
    const employee = useSelector((state) => state.EmployeeAuthReducer)
    const formik = useFormik({
        initialValues: {
            incharge: contractDetails?.Incharge?.id && contractDetails?.Incharge?.fullname && contractDetails?.Incharge?.employee_code?
            { id: contractDetails?.Incharge?.id, label: contractDetails?.Incharge?.fullname + ' (' + contractDetails?.Incharge?.employee_code + ')' }:null
        },
        validationSchema: UpdateInChargeValidation,
        onSubmit: (data) => {
            data = { ...data, contract_id: contractDetails.id, status: COMPLETE_STATUS }
            dispatch(ContractFreelancerInchargeUpdate(auth.authtoken, data, close, TALENT))
        }
    });
    
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm } =
        formik;

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(EmployeeAuthListData(auth?.authtoken, ACTIVE_USER, null, null, { is_active: true, form_list: true, auth_list: true }))
        }
    }, [])

    const EmployeeList =
        employee && employee?.empAuthListData?.rows?.map((obj) => ({
            id: obj.id,
            label: `${obj.fullname} (${obj.employee_code})`
        }));

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={6} lg={7} xl={6} >
                        <ELabelInputMainGrid label={'Incharge Name'}>
                            <EAutocomplete
                                fullWidth
                                {...getFieldProps('incharge')}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                options={
                                    EmployeeList && EmployeeList?.length > 0 ? EmployeeList : []
                                }
                                getOptionLabel={(option) => option.label || ''}
                                onChange={(e,value) => {
                                    setFieldValue('incharge', value);
                                }}
                                renderInput={(params) => (
                                    <ETextField
                                        {...params}
                                        error={Boolean(touched.incharge && errors.incharge)}
                                        helperText={touched.incharge && errors.incharge}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12} className="modal1-buttons-stick-bottom">
                        <Stack direction="row" spacing={2}>
                            <EButtonOutlined
                                size="large"
                                variant="outlined"
                                onClick={() => {
                                    close();
                                    resetForm();
                                }}
                            >
                                Cancel
                            </EButtonOutlined>
                            <ELoadingButton
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={contract?.contractLoading}
                            >
                                Update
                            </ELoadingButton>

                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    )
};

export default EditModalInchargeDetails;
