


import {  Grid  } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import {  ETypography } from '../../BaseComponents/ETypography';
import { useDispatch } from 'react-redux';
import { DeleteTalentSalaryAppraisal } from '../../action/TalentSalaryAppraisalAction';
import moment from 'moment';

const AppraisalDelete = (props) => {
    const { setModalState, auth, modalState, getPaymentFilterData } = props;
    const dispatch = useDispatch();

    const HandleDeleteRequest = () => {
        dispatch(DeleteTalentSalaryAppraisal(auth?.authtoken, modalState?.modalData?.id, modalState?.modalData?.employee_id, getPaymentFilterData));

        
        setModalState({ modalData: null, modalName: null })
    }
    return (

        <Grid container spacing={2} >
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <ETypography className='font-size-18px'>Are you sure you want to cancle appraisal of {modalState?.modalData?.Employee?.fullname}
                 {modalState?.modalData?.from_date?', scheduled for '+ moment(modalState?.modalData?.from_date).format('DD/MM/YYYY')+' ?':' ?'} </ETypography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalName: null })}
                    > Cancel
                    </EButtonOutlined>
                    <ELoadingButton
                        size="large" variant="outlined" type="submit" onClick={() => HandleDeleteRequest()} >Delete</ELoadingButton>
                </Stack>
            </Grid>
        </Grid>

    );
};
export default AppraisalDelete;
