import axios from 'axios';
import { SERVER_URL,TODAY_GRAPH_FAILURE, TODAY_GRAPH_SUCCESS, TODAY_GRAPH_LOADING} from '../constants';
import { Logout } from './AuthAction';

export function WorkingHourList(token) {

    return (dispatch) => {
        dispatch(graphDataLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/dashboard/user-average-working`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(graphDataSuccess(res.data))
                }

            }).catch((error) => {
                if (error?.response) {
                    dispatch(graphDataFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                    // Above if block was commented
                }
            });
    };
}

export function graphDataLoading() {
    return {
        type: TODAY_GRAPH_LOADING,
    };
}
export function graphDataSuccess(payload) {
    return {
        type: TODAY_GRAPH_SUCCESS,
        payload
    };
}

export function graphDataFailure(payload) {
    return {
        type: TODAY_GRAPH_FAILURE,
        payload
    };
}
