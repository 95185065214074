import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { ACTIVE_USER, REPORT_BY_DATE, REPORT_BY_TALENT, REPORT_BY_TIME, StartDateForReport } from '../../constants';
import {
  DOWNLOAD_PDF,
  DOWNLOAD_CSV,
} from '../../constants';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import FilePDF from '@iconify/icons-material-symbols/picture-as-pdf-rounded';
import FileCSV from '@iconify/icons-material-symbols/file-present-rounded';
import ResetIcon from '@iconify/icons-material-symbols/restart-alt-rounded';
import SortRounded from '@iconify/icons-material-symbols/sort-rounded';
import SortByAlpha from '@iconify/icons-material-symbols/sort-by-alpha';
import { useSelector, useDispatch } from 'react-redux';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import { EIcon, EIconInputAdornment } from '../../BaseComponents/EIcon';
import EMenuPopover, { EMenuIcon, EMenuItem } from '../../BaseComponents/EMenuPopover';
import { EmployeeListData } from '../../action/EmployeeAction';
import { EInputAdornment, ETextFieldSearch } from '../../BaseComponents/ETextField';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { EIconButton } from '../../BaseComponents/EButtons';
import { EBox } from '../../BaseComponents/EBox';
import { ETimePicker } from '../../BaseComponents/ETimePicker';
import { onKeyDown } from '../../utils/CommonFunctions';
import CancelIcon from '@iconify/icons-material-symbols/cancel-outline-rounded';
import { ETooltip } from '../../BaseComponents/ETooltip';

/**
 * [2022-11-29]
 * Created by:- Aanchal Sahu
 * Description:- designed this component
 **/
export default function ReportSearchBar(props) {
  const dispatch = useDispatch();
  const { setSearch, auth, resetPage, downloadSearchReport, reportPage, company } =
    props;
  const employee = useSelector((state) => state.EmployeeReducer);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Parameters, setParameters] = React.useState({
    start_date: reportPage == REPORT_BY_TIME ? new Date() : null,
    end_date: reportPage == REPORT_BY_TIME ? new Date() : null,
    time: reportPage == REPORT_BY_TIME ? new Date() : null,
    employee_id: null,
    employee_details: null,
    sort: false
  });

  const [errorByDate, setErrorByDate] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {   //___For calling Employee List Api For Drop Down___//
    if (auth?.authtoken && reportPage == REPORT_BY_TALENT) {
      dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER));
    }
  }, []);


  const downloadReport = (value) => {
    downloadSearchReport(value);
    setAnchorEl(null);
  }

  const EmployeeList =
    employee && employee?.employeeData?.rows?.map((obj, i) => ({
      id: obj.id,
      label: `${obj.fullname}`,
      name: obj.fullname,
      code: obj.employee_code
    }));

  useEffect(() => {
    if (auth?.authtoken && reportPage) {
      handleSearchFunction()
    }
  }, [Parameters.start_date, Parameters?.employee_id, Parameters.end_date, Parameters.time, Parameters.sort]);

  const handleSearchFunction = () => {
    let Param = {
      ...Parameters,
      date: reportPage == REPORT_BY_DATE ? Parameters.start_date : moment().startOf('month').toDate()
    }
    if (reportPage == REPORT_BY_DATE || reportPage == REPORT_BY_TIME) {
      if (Param.start_date && !Param.end_date && reportPage == REPORT_BY_DATE) {
        Param = {
          ...Param,
          end_date: new Date()
        };
        setErrorByDate(false);
      } else if (!Param.start_date && Param.end_date) {
        setErrorByDate(true);
      }

      if (Param.end_date && Param.start_date) {
        setSearch(Param);
        setParameters(Param);
        setErrorByDate(false);
      }else
      if (!Param.end_date || !Param.start_date) {
        Param = {
          ...Param,
          end_date: Param.end_date?Param.end_date:null,
          start_date:Param.start_date?Param.start_date:null
        };
        setSearch(Param);
      }
    }

    if (reportPage == REPORT_BY_TALENT) {
      if (Parameters?.employee_id) {
        Param = {
          ...Param,
          employee_id: Parameters.employee_id.id,
          employee_details: Parameters.employee_id
        };
        setSearch(Param);
        // setErrorByTalent(false);
      } else {
        Param = {
          ...Param,
          employee_id: auth?.authData?.id,
          employee_details: null
        };
        setSearch(Param);
      }
    }
  }

  const resetPageSearch = () => {
    setParameters({
      start_date: reportPage == REPORT_BY_TIME ? new Date() : null,
      end_date: reportPage == REPORT_BY_TIME ? new Date() : null,
      time: reportPage == REPORT_BY_TIME ? new Date() : null,
      employee_id: null,
      employee_details: null,

    });
    setErrorByDate(false);
    resetPage()
  }

  return (<>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container>

        <Grid item xs={8} sm={9} md={9} lg={9} xl={10} >

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="top"
            spacing={1}
          >
            {/* Search dropdown of name */}

            {reportPage == REPORT_BY_DATE || reportPage == REPORT_BY_TIME ?
              <>
                <Grid item xs={6} sm={5} md={4} lg={4} xl={3}>
                  <EDatePicker
                    inputFormat="dd/MM/yyyy"
                    value={Parameters.start_date}
                    minDate={StartDateForReport}
                    maxDate={Parameters.end_date ? new Date(Parameters.end_date) : new Date()}
                    clearable
                    onChange={(newValue) => {
                      setParameters({
                        ...Parameters,
                        start_date: newValue ? newValue : null
                      });

                    }}
                    renderInput={(params) => (
                      <div className='date-picker-div'>{/*this div contains text field and clear icon*/}
                        <ETextFieldSearch
                          size="small" fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'Start Date'
                          }}
                          onKeyDown={onKeyDown}
                          // className='width-200px'
                          error={errorByDate || (reportPage == REPORT_BY_TIME && !Parameters.start_date)}
                          helperText={errorByDate ? 'Please select start date.':reportPage == REPORT_BY_TIME && !Parameters.start_date ?'Please select start date.':'' } 
                        />
                        {Parameters.start_date &&
                          <EInputAdornment
                            position='end'
                            onClick={() => setParameters({
                              ...Parameters,
                              start_date: null
                            })}
                            className='clear-icon'
                          >
                            <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                          </EInputAdornment>
                        }
                      </div>
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={5} md={4} lg={4} xl={3}>
                  <EDatePicker
                    inputFormat="dd/MM/yyyy"
                    value={Parameters.end_date}
                    placeholder="End Date"
                    clearable
                    minDate={
                      Parameters.start_date
                        ? new Date(Parameters.start_date)
                        : new Date(company?.companyData?.CompanyActivePlan?.plan_valid_from)
                    }
                    maxDate={new Date()}
                    onChange={(newValue) => {
                      setParameters({
                        ...Parameters,
                        end_date: newValue ? newValue : null
                      });
                    }}
                    renderInput={(params) => (
                      <div className='date-picker-div'>{/*this div contains text field and clear icon*/}
                        <ETextFieldSearch
                          size="small"
                          fullWidth
                          onKeyDown={onKeyDown}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'End Date'
                          }}
                          error={reportPage == REPORT_BY_TIME && !Parameters.end_date}
                          helperText={reportPage == REPORT_BY_TIME && !Parameters.end_date ?'Please select end date.':''} 
                        />
                        {Parameters.end_date &&
                          <EInputAdornment
                            position='end'
                            onClick={() => setParameters({
                              ...Parameters,
                              end_date: null
                            })}
                            className='clear-icon'
                          >
                            <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                          </EInputAdornment>
                        }
                      </div>

                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={4} lg={4} xl={3}>
                  {/* new timepicker */}
                  {reportPage == REPORT_BY_TIME &&
                    <ETimePicker
                      value={Parameters.time}
                      ampm={false}
                      onChange={(newValue) => {
                        setParameters({
                          ...Parameters,
                          time: newValue ? newValue : null
                        });
                      }}
                      renderInput={(params) =>
                        <ETextFieldSearch fullWidth {...params} size="small" onKeyDown={onKeyDown} />}
                    />}
                </Grid>
              </>
              :
              <Grid item xs={12} sm={2} md={4} lg={4} xl={3}>
                <EAutocomplete
                  name="talent"
                  label="Talent Name"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={EmployeeList && EmployeeList?.length > 0 ? EmployeeList : []}
                  value={Parameters?.employee_id}
                  getOptionLabel={(option) => option.label || ''}
                  onChange={(e, value) => {
                    setParameters({ ...Parameters, employee_id: value ? value : null });
                  }}
                  renderInput={(params) => (
                    <ETextFieldSearch
                      {...params}
                      name="talent"
                      size="small" className='minWidth-180'
                      placeholder="Search By Name"
                      fullWidth
                    />
                  )}
                />
              </Grid>

            }
          </Grid>

        </Grid>
        <Grid item xs={4} sm={3} md={3} lg={3} xl={2} >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <EBox className="display-flex align-center">
               {/* RESET: dont show in BY_NAME */}
               {
                reportPage != REPORT_BY_TALENT && 
                <ETooltip title="Reset" arrow>
                  <EIconButton
                    onClick={(e, value) => {
                      resetPageSearch()
                    }}
                  >
                    <EIcon icon={ResetIcon} />
                  </EIconButton>
                </ETooltip>

              }
                {/* DOWNLOAD */}
                
                <ETooltip title="Download" arrow>
                <EIconButton size={"large"} onClick={handleClick}>
                  <EIcon icon={FileDownloadIcon} />
                </EIconButton>
                </ETooltip>

              {reportPage != REPORT_BY_TIME ? <>
                {/* SORT */}
                <ETooltip title="Sort By Name" arrow>
                <EIconButton size={"large"} onClick={() => {
                  setParameters({
                    ...Parameters,
                    sort: Parameters.sort ? false : true
                  })
                }}>
                  <EIcon icon={SortByAlpha} />
                  
                </EIconButton>
                </ETooltip>
                
              </> : <>
            
                {/* SORT */}
              <ETooltip title="Sort By Date" arrow>
                <EIconButton size={"large"} onClick={() => {
                  setParameters({
                    ...Parameters,
                    sort: Parameters.sort ? false : true
                  })
                }}>
                   <EIcon icon={SortRounded} />
                </EIconButton>
                </ETooltip>
                </>}
            </EBox>
          </Grid>
          <EMenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            sx={{ width: 180, top: 40 }}
          >
            <EMenuItem
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
              onClick={() => downloadReport(DOWNLOAD_PDF)}
            >
              <EMenuIcon icon={FilePDF} />  PDF
            </EMenuItem>
            <EMenuItem
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
              onClick={() => downloadReport(DOWNLOAD_CSV)}
            >
              <EMenuIcon icon={FileCSV} /> CSV
            </EMenuItem>
          </EMenuPopover>
        </Grid>

      </Grid>
    </LocalizationProvider>
  </>
  );
}