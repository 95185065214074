import React, { useEffect, useState } from 'react'
import { useFormik, Form, FormikProvider } from 'formik';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { Checkbox, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Stack } from '@mui/material';
import { FieldContainerCustom } from '../../BaseComponents/EFieldContainer';
import { EFieldLabel, ETypography } from '../../BaseComponents/ETypography';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ETextField } from '../../BaseComponents/ETextField';
import { TalentAppraisalValidation } from '../../utils/validation';
import currencyRupeeIcon from '@iconify/icons-material-symbols/currency-rupee'
import { EIcon } from '../../BaseComponents/EIcon';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import moment from 'moment';
import { GetTalentDetail } from '../../action/TalentActions';
import { useDispatch, useSelector } from 'react-redux';
import { CreateTalentSalaryAppraisal } from '../../action/TalentSalaryAppraisalAction';
import { DesignationList } from '../../action/DesignationAction';
import { DepartmentList } from '../../action/DepartmentAction';

// import { useNavigate } from 'react-router-dom';


// import { Stack, TextField, Grid, Checkbox, Autocomplete, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, InputAdornment } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import { CreateTalentSalaryAppraisal } from '../../../actions/TalentSalaryAppraisalAction';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// import { useSelector, useDispatch } from "react-redux";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
// import moment from 'moment';

const AppraisalModal = (props) => {

    const { setModalState, modalState, auth, getPaymentFilterData, employeeList, companyData } = props;

    const dispatch = useDispatch();
    const [talentProfile, setTalentProfile] = useState({});
    const department = useSelector((state) => state.DepartmentReducer.departmentListData?.rows);
    const designation = useSelector((state) => state.DesignationReducer.designationListData?.rows);

    const [scheduleMonthValidation, setScheduleMonthValidation] = useState({ minDate: new Date(), maxDate: new Date() });

    
    const formik = useFormik({
        initialValues: {
            talent: null,
            appraise_type: 'BOTH',
            current_designation: null,
            department: null,
            designation: null,
            current_ctc: '',
            appraisal_amount: '',
            final_salary: '',
            schedule_month: false,
            from_date: '',
            remark: '',
        },
        validationSchema: TalentAppraisalValidation,
        onSubmit: (data) => {
            data = { ...data, employeeId: data.talent.id, appraisal_id: modalState.modalName == 'EDIT' ? modalState.modalData?.id : '' }
            dispatch(CreateTalentSalaryAppraisal(auth.authtoken, data, getPaymentFilterData));
            
            setModalState({ modalData: null, modalName: null })
        }
    });
    // let maxDate = moment().add(3, 'months').format('YYYY-MM-DD');
    // let minDate = moment().add(1, 'days').format('YYYY-MM-DD');
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    useEffect(() => {
        if (modalState.modalName === 'EDIT') {
            // assign value to fields 
            if (modalState.modalData?.Employee) {
                setFieldValue('talent', { id: modalState.modalData?.Employee?.id, label: modalState.modalData?.Employee?.fullname });
            }
            if (modalState.modalData?.departmentInfo) {
                setFieldValue('department', modalState.modalData?.departmentInfo);
            }
            if (modalState.modalData?.designationInfo) {
                setFieldValue('designation', modalState.modalData?.designationInfo);
            }
            if(modalState.modalData?.appraisal_type === 'DESIGNATION'){
                setFieldValue('appraisal_amount', '');
            }else{
                setFieldValue('appraisal_amount', modalState.modalData?.appraisal_amount ? modalState.modalData?.appraisal_amount : '');
            }
            setFieldValue('appraise_type', modalState.modalData?.appraisal_type);            
            setFieldValue('final_salary', modalState.modalData?.amount_after_appraisal ? modalState.modalData?.amount_after_appraisal : '');
            setFieldValue('schedule_month', modalState.modalData?.is_schedule ? modalState.modalData?.is_schedule : false);
            setFieldValue('from_date', modalState.modalData?.from_date ? moment(modalState.modalData?.from_date).toDate() : new Date());
            setFieldValue('remark', modalState.modalData?.remark ? modalState.modalData?.remark : '');
            dispatch(GetTalentDetail(auth.authtoken, modalState.modalData?.employee_id))
        }
    }, [modalState.modalData])


    useEffect(() => {
        setTalentProfile(employeeList.employeeProfileData);
        setFieldValue('current_designation', employeeList?.employeeProfileData?.designationInfo)
        setFieldValue('current_ctc', employeeList.employeeProfileData?.employeePayrollInfo?.payroll_basic)
        // check last payroll is available or not 
        handleMinMaxDateValidation(formik.values.schedule_month);
    }, [employeeList.employeeProfileData])

    //INFO: This useEffect will be called only once to get the list values of department & designation since we have removed these APIs from after login & check-token action
    useEffect(() => {
        dispatch(DesignationList(auth.authtoken))//INFO: This is called to get all the updated designation list
        dispatch(DepartmentList(auth.authtoken))//INFO: This is called to get the updated department list
    }, [])

    const HandFinalSalary = (val) => {
        if (val.target.value) {
            const basic = formik.values.current_ctc;
            const finalSalry = (Number(basic) + Number(val.target.value))
            setFieldValue('final_salary', finalSalry.toFixed(2))
        } else {
            setFieldValue('final_salary', '')
        }
    }

    const getUserProfile = (value) => {
        setFieldValue('talent', value ? { id: value.id, label: value.fullname } : null);
        if (value) {
            dispatch(GetTalentDetail(auth.authtoken, value.id))
        } else {
            setTalentProfile({});
        }
    }
    const handleMinMaxDateValidation = (scheduleMonthCheckEd) => {
      
        if (employeeList.employeeProfileData?.latestPayroll) {
            if (scheduleMonthCheckEd) {
                const futureMindate = moment().add(1, 'days').toDate()
                const futureMaxMonth = moment(futureMindate).add(3, 'months').toDate()
                if(modalState.modalName == 'APPRAISAL'){
                    setFieldValue('from_date', futureMindate)
                }
                setScheduleMonthValidation({ minDate: futureMindate, maxDate: futureMaxMonth });
            } else {
                const lastPayrollDate = employeeList.employeeProfileData?.latestPayroll?.payrollData?.to_date;
                const lastAppraisalDate = employeeList.employeeProfileData?.lastAppraisal?.from_date;
                let maxDate = lastAppraisalDate ? moment.max([moment(lastPayrollDate), moment(lastAppraisalDate).add(1, 'days')]) : moment(lastPayrollDate);
                const pastMinDate = maxDate.toDate()
                const pastMaxMonth = new Date();
                if(modalState.modalName == 'APPRAISAL'){
                    setFieldValue('from_date', pastMaxMonth)
                }
                setScheduleMonthValidation({ minDate: pastMinDate, maxDate: pastMaxMonth });
            }
        }
    };

    const scheduleMonthCheck = (value) => {
        setFieldValue('schedule_month', value)
        handleMinMaxDateValidation(value);
    }

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <FieldContainerCustom
                        labelwidth={50}
                        className="align-center"
                    >
                        <EFieldLabel>Talent Name</EFieldLabel>
                        <EAutocomplete
                            name="talent"
                            fullWidth
                            readOnly={modalState.modalName === 'EDIT'}
                            // label="Talent Name"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            {...getFieldProps('talent')}
                            options={employeeList && employeeList?.employeeData?.count > 0 ? employeeList?.employeeData?.rows : []}
                            renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                          {option.label} ({option.employee_code})
                                        </li>
                                      )}
                            getOptionLabel={(option) => option.label || ''}
                            onChange={(e, value) => getUserProfile(value)}
                            renderInput={(params) => (
                                <ETextField
                                    {...params}
                                    name="talent"
                                    placeholder="Select"
                                    error={Boolean(touched.talent && errors.talent)}
                                    helperText={touched.talent && errors.talent}
                                />
                            )}
                        />
                    </FieldContainerCustom>
                </Grid>

                {modalState.modalName == 'APPRAISAL' && <>

                    {talentProfile &&
                        Object.keys(talentProfile).length > 0 &&
                        talentProfile.latestPayroll &&
                        formik.values.talent &&
                        employeeList.employeeProfileData?.lastAppraisal?.apply_status === 'PENDING' &&
                        <>
                            <Grid container justifyContent="center" my={3}>
                                <ETypography variant='4'>There is an pending appraisal </ETypography>
                            </Grid>
                        </>
                    }

                    {talentProfile &&
                        Object.keys(talentProfile).length > 0 &&
                        !talentProfile.latestPayroll && <>
                            <Grid container justifyContent="center" my={3}>
                                <ETypography variant='4'>No previous payroll found </ETypography>
                            </Grid>

                        </>}

                </>}


                {(talentProfile &&
                    Object.keys(talentProfile).length > 0 &&
                    talentProfile.latestPayroll &&
                    formik.values.talent &&
                    (employeeList.employeeProfileData?.lastAppraisal?.apply_status !== 'PENDING' || modalState.modalName === 'EDIT')) ? <>

                    <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                        <FieldContainerCustom
                            labelwidth={33}
                            className="align-center"
                        >
                            <EFieldLabel>Appraise for</EFieldLabel>
                            <RadioGroup
                                row aria-label="appraise_type"
                                name="appraise_type"
                                {...getFieldProps('appraise_type')}
                            // onChange={(e) => { (handleChangeStatus(e)) }}
                            >
                                <FormControlLabel value="DESIGNATION" control={<Radio />} label="Designation" />
                                <FormControlLabel value="SALARY" control={<Radio />} label="Salary" />
                                <FormControlLabel value="BOTH" control={<Radio />} label="Both" />
                            </RadioGroup>
                        </FieldContainerCustom>
                    </Grid>
                    {(getFieldProps('appraise_type').value === 'DESIGNATION'
                        || getFieldProps('appraise_type').value === 'BOTH') && <>
                            <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                    className="align-center"
                                >
                                    <EFieldLabel>Current Designation</EFieldLabel>
                                    <EAutocomplete
                                        name="current_designation"
                                        disabled
                                        fullWidth
                                        // options={DepartmentListData && DepartmentListData?.length > 0 ? DepartmentListData : []}
                                        options={designation ? designation : []}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={option => option.label || ""}
                                        {...getFieldProps('current_designation')}
                                        onChange={(e, value) => {
                                            setFieldValue("current_designation", value)
                                        }}
                                        renderInput={params => (
                                            <ETextField
                                                {...params}
                                                name='current_designation'
                                                fullWidth
                                                error={Boolean(touched.current_designation && errors.current_designation)}
                                                helperText={touched.current_designation && errors.current_designation}
                                            />
                                        )}
                                    />
                                </FieldContainerCustom>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                    className="align-center"
                                >
                                    <EFieldLabel>Update Department</EFieldLabel>
                                    <EAutocomplete
                                        name="department"
                                        fullWidth
                                        // options={DepartmentListData && DepartmentListData?.length > 0 ? DepartmentListData : []}
                                        options={department ? department : []}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={option => option.label || ""}
                                        {...getFieldProps('department')}
                                        onChange={(e, value) => {
                                            setFieldValue("department", value)
                                        }}
                                        renderInput={params => (
                                            <ETextField
                                                {...params}
                                                name='department'
                                                placeholder='Select Department'
                                                fullWidth
                                                error={Boolean(touched.department && errors.department)}
                                                helperText={touched.department && errors.department}
                                            />
                                        )}
                                    />
                                </FieldContainerCustom>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                    className="align-center"
                                >
                                    <EFieldLabel>Update Designation</EFieldLabel>
                                    <EAutocomplete
                                        name="designation"
                                        fullWidth
                                        options={designation ? designation : []}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={option => option.label || ""}
                                        {...getFieldProps('designation')}
                                        onChange={(e, value) => {
                                            setFieldValue("designation", value)
                                        }}
                                        renderInput={params => (
                                            <ETextField
                                                {...params}
                                                name='designation'
                                                placeholder='Select Designation'
                                                fullWidth
                                                error={Boolean(touched.designation && errors.designation)}
                                                helperText={touched.designation && errors.designation}
                                            />
                                        )}
                                    />
                                </FieldContainerCustom>
                            </Grid>

                        </>}
                    {(getFieldProps('appraise_type').value === 'SALARY'
                        || getFieldProps('appraise_type').value === 'BOTH') && <>
                            <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                    className="align-center"
                                >
                                    <EFieldLabel>Current CTC <br /><small>(Per Month)</small></EFieldLabel>
                                    <ETextField
                                        fullWidth
                                        autoComplete="current_ctc"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EIcon icon={currencyRupeeIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{ readOnly: true, }}
                                        {...getFieldProps('current_ctc')}
                                        error={Boolean(touched.current_ctc && errors.current_ctc)}
                                        helperText={touched.current_ctc && errors.current_ctc}
                                    />
                                </FieldContainerCustom>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                    className="align-center"
                                >
                                    <EFieldLabel>Increment Amount  <br /><small>(Per Month)</small></EFieldLabel>
                                    <ETextField
                                        fullWidth
                                        multiline
                                        autoComplete="appraisal_amount"

                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EIcon icon={currencyRupeeIcon} />
                                                </InputAdornment>
                                            )
                                        }}
                                        onKeyUp={(event) => {
                                            HandFinalSalary(event)
                                        }}
                                        {...getFieldProps('appraisal_amount')}
                                        error={Boolean(touched.appraisal_amount && errors.appraisal_amount)}
                                        helperText={touched.appraisal_amount && errors.appraisal_amount}
                                    />
                                </FieldContainerCustom>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                    className="align-center"
                                >
                                    <EFieldLabel>Updated CTC <br /><small>(Per Month)</small></EFieldLabel>
                                    <ETextField
                                        fullWidth
                                        autoComplete="final_salary"
                                        {...getFieldProps('final_salary')}
                                        inputProps={{ readOnly: true, }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EIcon icon={currencyRupeeIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(touched.final_salary && errors.final_salary)}
                                        helperText={touched.final_salary && errors.final_salary}
                                    />
                                </FieldContainerCustom>
                            </Grid>
                        </>
                    }


                    {modalState.modalName === 'APPRAISAL' &&
                        <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                            <FieldContainerCustom
                                labelwidth={33}
                                className="align-center"
                            >
                                <EFieldLabel>Schedule</EFieldLabel>
                                <Checkbox {...getFieldProps('schedule_month')} checked={formik?.values?.schedule_month ? true : false} onChange={(e, value) => { scheduleMonthCheck(value) }} />
                            </FieldContainerCustom>
                        </Grid>
                    }

                    <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                        <FieldContainerCustom
                            labelwidth={50}
                            className="align-center"
                        >
                            <EFieldLabel>Schedule On</EFieldLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <EDatePicker
                                
                                    views={['day']}
                                    {...getFieldProps('from_date')}
                                    inputFormat="dd/MM/yyyy"
                                    minDate={scheduleMonthValidation.minDate}
                                    maxDate={scheduleMonthValidation.maxDate}
                                    onChange={(newValue) => setFieldValue('from_date', newValue ? newValue : null)}

                                    renderInput={(params) => <ETextField
                                        fullWidth
                                        {...params}
                                        placeholder="dd/mm/yyy"
                                        error={Boolean(touched.from_date && errors.from_date)}
                                        helperText={touched.from_date && errors.from_date}
                                    />
                                    }
                                />
                            </LocalizationProvider>
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12} my={2}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabel className="mt-12px">Remark</EFieldLabel>
                            <ETextField
                                fullWidth
                                autoComplete="remark"
                                multiline
                                rows={3}
                                {...getFieldProps('remark')}
                                error={Boolean(touched.remark && errors.remark)}
                                helperText={touched.remark && errors.remark}

                            />
                        </FieldContainerCustom>
                    </Grid>



                    <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                        <EButtonOutlined size="large" variant="outlined"
                            onClick={() => setModalState({ modalData: null, modalName: null })}
                        > Close </EButtonOutlined>
                        <ELoadingButton
                            size="large" variant="outlined" type="submit" >Schedule</ELoadingButton>
                    </Stack>
                </> : <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalName: null })}
                    > Close </EButtonOutlined>
                </Stack>}





            </Form>
        </FormikProvider >
    )
}

export default AppraisalModal
