import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import base64 from 'base-64'
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import EModal from '../../BaseComponents/EModal';
import { EIcon, EIconSmall, EIconStaticColor } from '../../BaseComponents/EIcon';
import EScrollbar from '../../BaseComponents/EScrollbar';
import Page from '../../BaseComponents/EPage';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../BaseComponents/ETable';
import SearchNotFound from '../../PageComponents/SearchNotFound';
import { EIconButton } from '../../BaseComponents/EButtons';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import moment from 'moment';
import { DEFAULT_ROWS_PERPAGE_40, MANUAL_ATTENDANCE, PAGES, ROLE_ADMIN, ROLE_HR, ROW_SIZE_PER_PAGE_40, SUPER_ADMIN } from '../../constants';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import monitor from '@iconify/icons-material-symbols/desktop-windows-outline-rounded'
import mobile from '@iconify/icons-material-symbols/phone-iphone-outline'
import TimerIcon from '@iconify/icons-material-symbols/nest-clock-farsight-analog-outline-rounded'
import TimerIconRegularized from '@iconify/icons-material-symbols/nest-clock-farsight-analog-rounded'
import EChip from '../../BaseComponents/EChip';
import { HRAttendanceList } from '../../action/AttendanceAction';
import { GetCompanySetting } from '../../action/CompanySettingAction';
import AttendanceSearchfields from '../../PageComponents/Attendance/AttendanceSearchfields';
import { EBoxPage } from '../../BaseComponents/EBox';
import EMenuPopover, { EMenuIcon, EMenuItem } from '../../BaseComponents/EMenuPopover';
import Regularizationform from '../../PageComponents/Attendance/Regularizationform'
import { ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EDivider } from '../../BaseComponents/EDivider';
import Attendance from '../../PageComponents/Attendance/Attendance';
import EHidden from '../../BaseComponents/EHidden';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';


const tableHead = (role, companySetting) => {

    let heads = [];

    heads.push({ id: 'Date', label: 'Date', alignRight: 'center' },
        { id: 'Day', label: 'Day', alignRight: false });

    [ROLE_HR, SUPER_ADMIN, ROLE_ADMIN].includes(role) && heads.push({ id: 'TalentName', label: 'Talent Name', alignRight: false });
    heads.push({ id: 'CheckIn', label: 'Check In Time', alignRight: 'center' },
        { id: 'CheckOut', label: 'Check Out Time', alignRight: 'center' },
        { id: 'WorkingHour', label: 'Duration', alignRight: 'center', hideLabelCriteria: 'mdDown' },
        { id: 'Status', label: 'Status', alignRight: 'center' },
        { id: 'Attendance', label: 'DPR(i)', alignRight: 'center', tooltip: 'Daily Progress Report' },
    );

    // If regularization is allowed (required/requested) in company setting and regularization count !== 0 ? show regualrization setting : dont't show
    if (companySetting?.attendance_regularization_request) {
        heads.push({ id: 'action', label: 'Regularization', alignRight: 'center' })
    }

    return heads;
}

export default function Attendance_attendanceLogs() {
    const dispatch = useDispatch();
    let refArray = useRef([]);
    const location = useLocation();
    const defaultNameFilter = location?.state?.name ? base64.decode(location?.state?.name) : "";
    const auth = useSelector(state => state.authReducer);
    const attendance = useSelector(state => state.EmployeeMonthAttendanceReducer);

    const { setting } = useCompanySettingProvider();
    const [isOpen, setIsOpen] = useState({ row: null, isModal: false, data: {} });
    const [page, setPage] = useState(PAGES);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE_40);
    const [SearchParameters, setSearchParameters] = useState({ sortBy: null, yearMonth: moment().format('YYYYMM'), fullname: defaultNameFilter ? defaultNameFilter : auth?.authData?.fullname });

    // const companySetting = useSelector((state) => state.CompanySettingReducer);
    //Setting data
    // const companySettingg = setting?.accountSettingData?.companySetting;
    const companySetting = setting?.attendanceSettingData?.companySetting;
    // const allowed_talent = companyAccountSetting?.company_data?.CompanyActivePlan?.allowed_talent;
    // const active_employee_count = companyAccountSetting?.active_employee_count;
    // console.log(companySetting?.attendance_regularization_request);

    useEffect(() => {
        if (auth?.authData && auth?.authData?.id) {
            let data = {}
            if (SearchParameters?.yearMonth != null) {
                data = { ...data, year_month: SearchParameters?.yearMonth }
            } else {
                data = { ...data, year_month: moment().format('YYYYMM') }
            }
            if (SearchParameters?.sortBy != null) {
                data = { ...data, sortBy: SearchParameters?.sortBy }
            }
            if (auth?.authData && (auth?.authData?.role == ROLE_ADMIN || auth?.authData?.role == SUPER_ADMIN || auth?.authData?.role == ROLE_HR)) {
                if (SearchParameters?.fullname != null) {
                    data = { ...data, fullname: SearchParameters?.fullname };
                }
            } else {
                data = { ...data, fullname: defaultNameFilter ? defaultNameFilter : auth?.authData?.fullname };
            }
            if (auth?.authData && auth?.authData?.id) {
                setIsOpen({ ...isOpen, data: data });
                dispatch(HRAttendanceList(auth.authtoken, data, page, rowsPerPage));
            }
        }
    }, [SearchParameters, page, rowsPerPage])


    // Calling getCompanySetting to get regularization information
    useEffect(() => {
        dispatch(GetCompanySetting(auth?.authtoken))
    }, [])

    const handleSearchParameters = (newValue) => {
        setSearchParameters(newValue);
    };

    const TABLE_HEAD = tableHead(auth?.authData?.role, companySetting);

    const handleChangePage = (event, newPage) => {
        setPage(newPage, page, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0, page, rowsPerPage);
    };
    // this function reseives time in hh:mm:ss format and returns h hrs m mins s secs format time
    const DurationFormatter = (value) => {
        var a = value ? value.split(':') : ''; // split it at the colons
        var seconds = a && (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        let formattedTime = a && a[0] != '00' ? moment.utc(seconds * 1000).format("h[hrs] ") : '';
        formattedTime += a && a[1] != '00' ? moment.utc(seconds * 1000).format("m[mins] ") : '';
        // formattedTime += a && a[2] != '00' ? moment.utc(seconds * 1000).format("s[secs] ") : '';
        return formattedTime
    }

    // disable regularization 
    /*
    Disable the regularization:
    1.PAYROLL IS LOCKED
    2.REGULARIZATION REQUEST IS SEND TO HR AND IS PENDING (row disabled, setting: get approval from HR)
    3.IF TOTAL ATTENDANCE COUNT IS EQUAL TO TOTAL REGULARIZED COUNT (setting: self-regularization)
    */
    const disableRegularization = (status, row) => {
        switch (status) {
            case 'PAYROLL_SETTING':
                return row?.payroll_status == 'LOCKED'
            case 'DISABLE_ROW':
                return companySetting?.attendance_regularization_request && row?.regularized_request_status == '1'
            case 'COUNT_CHECK':
                return (companySetting?.attendance_regularization_count == row?.total_regularized_count) && companySetting?.attendance_regularization_count !== 0 && row?.total_regularized_count !== 0
            default:
                return false
        }
    }

    return (
        <Page title="Attendance Log">
            <Grid container display="row" justifyContent="space-between" >
                <EHeaderBreadcrumbs
                    heading='Attendance Log'
                    links={[
                        { name: 'Dashboard', href: '/' },
                        { name: 'Attendance', href: '/attendance/attendance-log' },
                        {
                            name: 'Attendance Log',
                            href: '/'
                        }
                    ]}
                />
                <EHidden width="mdDown">
                    <Grid item>
                        <Attendance status="attendance" />{/* for CHECKIN, CHECKOUT & BACK BUTTONS */}
                    </Grid>
                </EHidden>

            </Grid>

            <Grid container display="row" justifyContent="space-between" className=' margin-top-20px'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>Attendance Log</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        <Attendance status="attendance" />{/* for CHECKIN, CHECKOUT & BACK BUTTONS */}
                    </Grid>
                </EHidden>

                <EHidden width="mdDown">
                    <Grid lg={12} xs={12} item><EDivider className='margin-top-05' /></Grid>
                </EHidden>


            </Grid>

            <EBoxPage>
                <AttendanceSearchfields
                    tab='1'
                    page={'attendance'}
                    AttendanceSearch={SearchParameters}
                    setAttendanceSearch={handleSearchParameters}
                    defaultNameFilter={defaultNameFilter ? defaultNameFilter : null}
                />

                <EScrollbar>
                    <ETableContainer >
                        <ETable>

                            {attendance?.empMonthAttLoading && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={attendance?.empMonthAttData?.rows?.length}
                                    onRequestSort={() => null}
                                />

                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={9} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            </>
                            }
                            {!attendance?.empMonthAttLoading && attendance?.empMonthAttData?.rows?.length > 0 &&
                                <>
                                    <EListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={attendance?.empMonthAttData?.rows?.length}
                                        onRequestSort={() => null}


                                    />
                                    <ETableBody>

                                        {attendance?.empMonthAttData?.rows?.map((row, index) => {
                                            const { date, regularized_request_status, employee_code, checkout_is_mobile, regularize_remark, is_regularized, checkin_is_mobile,
                                                check_in_time, check_out_time, employee_id, total_working_hour,
                                                checkout_remark, fullname, attendance } = row;

                                            return (
                                                <ETableRow className={disableRegularization("DISABLE_ROW", row) && 'table-disabled-row-bg'}
                                                    hover key={index + fullname}>
                                                    <ETableCell align="left" className='text-transform-capitalize'>
                                                        {moment(date).format('DD/MM/YYYY')}</ETableCell>
                                                    <ETableCell align="left" className=' text-transform-capitalize'>
                                                        {moment(date).format('dddd')}</ETableCell>
                                                    {([ROLE_HR, SUPER_ADMIN, ROLE_ADMIN].includes(auth?.authData?.role)) ?
                                                        <ETableCell align="left" className='minWidth-130 text-transform-capitalize'>
                                                            {fullname}<br />
                                                            ({employee_code})
                                                        </ETableCell>
                                                        :
                                                        <></>}
                                                    <ETableCell align="center" className="minWidth-120 text-transform-capitalize">
                                                        {is_regularized == 1 && regularize_remark != null ?
                                                            <ETooltip title={regularize_remark} arrow>
                                                                <span> {checkin_is_mobile == '0' && check_in_time ?
                                                                    <EIconSmall icon={monitor} /> : checkin_is_mobile == '1' && check_in_time ?
                                                                        <EIconSmall icon={mobile} /> : ''}
                                                                    <span className='theme-main-text-color-bold'>
                                                                        {check_in_time ? moment(check_in_time).format('hh:mm A') : "-"}
                                                                    </span>
                                                                </span>
                                                            </ETooltip>
                                                            :
                                                            <>
                                                                {checkin_is_mobile == '0' && check_in_time ?
                                                                    <ETooltip title={"Checked In from Desktop"} arrow>
                                                                        <EIconSmall icon={monitor} /></ETooltip> : checkin_is_mobile == '1' && check_in_time ?
                                                                        <ETooltip title={"Checked In from Mobile"} arrow>
                                                                            <EIconSmall icon={mobile} /></ETooltip> : ''}
                                                                {check_in_time ? moment(check_in_time).format('hh:mm A') : "-"}
                                                            </>
                                                        }
                                                    </ETableCell>
                                                    <ETableCell align="center" className="minWidth-120">
                                                        {is_regularized == 1 && regularize_remark != null ?
                                                            <ETooltip title={regularize_remark} arrow>
                                                                <span> {checkout_is_mobile == '0' && check_out_time ?
                                                                    <EIconSmall icon={monitor} /> : checkout_is_mobile == '1' && check_out_time ?
                                                                        <EIconSmall icon={mobile} /> : ''}
                                                                    <span className='theme-main-text-color-bold'>  {check_out_time ? moment(check_out_time).format('hh:mm A') : "-"}
                                                                    </span>
                                                                </span>
                                                            </ETooltip>
                                                            :
                                                            <>
                                                                {checkout_is_mobile == '0' && check_out_time ?
                                                                    <ETooltip title={"Checked Out from Desktop"} arrow>
                                                                        <EIconSmall icon={monitor} /></ETooltip> : checkout_is_mobile == '1' && check_out_time ?
                                                                        <ETooltip title={"Checked Out from Mobile"} arrow>
                                                                            <EIconSmall icon={mobile} /></ETooltip> : ''}
                                                                {check_out_time ? moment(check_out_time).format('hh:mm A') : "-"}
                                                            </>
                                                        }

                                                    </ETableCell>
                                                    <EHidden width="mdDown">
                                                        <ETableCell align="center" className='minWidth-120 text-transform-capitalize'>
                                                            {total_working_hour ? DurationFormatter(total_working_hour) : '-'}
                                                        </ETableCell>
                                                    </EHidden>
                                                    <ETableCell align="center" className='width-20'>
                                                        {/* Condition checking if setting is automate or not
                                                        If yes then direct show the abbrivation if no then checking date is today and checkout is not done
                                                        else show the attendace
                                                         */}
asdas
                                                        {companySetting?.allow_attendance_automate ?
                                                            <EChip label={attendance} width='55px' /> :
                                                            (moment().isSame(date, 'day') && !check_out_time ? '-' : 
                                                            (attendance ? <EChip label={attendance} width='55px' /> : "-"))
                                                        }
                                                        {/* {companySetting?.allow_attendance_automate ?
                                                            <EChip label={attendance} width='55px' /> :  
                                                            (moment().isSame(date, 'day') && !check_out_time ? '-' : attendance ? 
                                                            <EChip label={attendance} width='55px' /> : "-")
                                                            } */}

                                                    </ETableCell>
                                                    <ETableCell align="center"  >
                                                        {checkout_remark ?
                                                            <ETooltip title={checkout_remark} arrow>
                                                                <EIconButton sx={{ color: 'grey.500' }}>
                                                                    <EIconSmall icon={InfoIcon} width={23} height={23} />
                                                                </EIconButton>
                                                            </ETooltip>
                                                            : <></>}
                                                    </ETableCell>
                                                    {/*  If regularization is allowed in company setting and regularization count !== 0 ? show regualrization setting : dont't show */}
                                                    {(companySetting?.attendance_regularization_required && 
                                                    (companySetting?.attendance_regularization_count !== 0 || 
                                                        companySetting?.attendance_regularization_request)) && (
                                                        <ETableCell align='center' className='width-20'>
                                                            {employee_id == auth.authData?.id &&
                                                                <EIconButton id={index} ref={(e) => { refArray.current.push(e); }} onClick={(e) => {
                                                                    setIsOpen({ ...isOpen, row: row, isModal: true });
                                                                }}
                                                                    disabled={disableRegularization("DISABLE_ROW", row) || disableRegularization('PAYROLL_SETTING', row) || disableRegularization('COUNT_CHECK', row) ? true : false}
                                                                // disabled ={(company?.companyData?.attendance_regularization_count > Number(total_regularized_count)) || ( is_regularized == 1 && regularized_request_status !== 1) && payroll_status != "LOCKED"  ? false : true }
                                                                >

                                                                    {is_regularized == 1 && regularized_request_status != 3 ?
                                                                        <EIconStaticColor icon={TimerIconRegularized} width={20} height={20} /> :
                                                                        <EIcon icon={TimerIcon} width={20} height={20} />
                                                                    }
                                                                </EIconButton>
                                                            }
                                                        </ETableCell>
                                                    )

                                                    }


                                                </ETableRow>
                                            );
                                        })}
                                    </ETableBody>
                                </>
                            }
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {attendance?.empMonthAttData?.count && attendance?.empMonthAttData?.count >= 40 ?
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE_40}
                        component="div"
                        count={attendance?.empMonthAttData?.count ? attendance?.empMonthAttData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : <></>}
            </EBoxPage>
            {attendance?.empMonthAttLoading == false && (attendance?.empMonthAttData?.rows?.length == 0) && (
                <SearchNotFound />
            )}


            {isOpen.isModal &&
                <EModal open={isOpen.isModal} close={() => setIsOpen({ ...isOpen, isModal: true })} headervalue={"Regularization" + (isOpen?.row?.date ? ' - ' + moment(isOpen?.row?.date).format('DD/MM/YYYY') : '')}>
                    <Regularizationform company={companySetting} row={isOpen.row} setIsOpen={setIsOpen} isOpen={isOpen} />
                </EModal>
            }
        </Page>

    )
}