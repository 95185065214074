import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import { EIconSideNavbar } from '../BaseComponents/EIcon';
import arrowIosForwardFill from '@iconify/icons-material-symbols/chevron-right-rounded';
import arrowIosDownwardFill from '@iconify/icons-material-symbols/keyboard-arrow-down-rounded';
import { THEMES } from '../constants';
import { useSelector } from 'react-redux';


/**
 * [2022-03-23]
 * changes by: Purva Sharma
 * Description:- added {isOnboardingCompleted} prop in <NavItem/> to add condition for background hover in sidebar nav buttons
 **/


const ListItemStyle = styled(({theme,isOnboardingCompleted, ...props}) => <ListItemButton disableGutters {...props} />)( //this is list item for parent menu
  ({ theme,isOnboardingCompleted }) => ({
    ...theme.typography.body2,
    fontSize: '0.875rem',//14px
    fontWeight: 400,
    height: 44,
    position: 'relative',
    textTransform: 'capitalize',
    paddingRight: theme.spacing(1),
    color: theme.palette.text.primary,
    marginTop: 2,
    borderRadius: '7px',
    '&.activeRootStyle': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.sideBar,
      fontWeight: 700,
      boxShadow: '0px 14px 6px rgba(48, 12, 3, 0.01), 0px 8px 5px rgba(48, 12, 3, 0.05), 0px 3px 3px rgba(48, 12, 3, 0.09), 0px 1px 2px rgba(48, 12, 3, 0.1), 0px 0px 0px rgba(48, 12, 3, 0.1)',
      svg: {
        color: theme.palette.text.sideBar,
      }
    },
    '&:hover , &.active': {
      // backgroundColor:theme.palette.primary.main,
      backgroundColor:isOnboardingCompleted && theme.palette.primary.main,
      color:isOnboardingCompleted && theme.palette.text.sideBar,
      fontWeight: 700,
      boxShadow: '0px 14px 6px rgba(48, 12, 3, 0.01), 0px 8px 5px rgba(48, 12, 3, 0.05), 0px 3px 3px rgba(48, 12, 3, 0.09), 0px 1px 2px rgba(48, 12, 3, 0.1), 0px 0px 0px rgba(48, 12, 3, 0.1)',
      svg: {
        color:isOnboardingCompleted && theme.palette.text.sideBar,
      }
    },
  }
  )
);

const ChildrenListItemStyle = styled(({ theme,isOnboardingCompleted, ...props }) => <ListItemButton disableGutters {...props} />)(//this is list item for child menu
  ({ theme,isOnboardingCompleted }) => ({
    ...theme.typography.body2,
    height: 44,
    position: 'relative',
    textTransform: 'capitalize',
    marginTop: 2,
    paddingRight: theme.spacing(1),
    borderRadius: '7px',
    fontSize: '0.875rem',//14px
    fontWeight: '400',
    '&:hover , &.active': {
      backgroundColor:isOnboardingCompleted && theme.palette.background.subMenuBg,
      color:isOnboardingCompleted && theme.palette.text.default,
      fontWeight: '700',
    },
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  // width: 13.5 +'!important',
  // height: 13.5+'!important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth:'44px'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active, isShow, currentTitle,setCurrentTitle }) {
  const { title, path, icon, info, children } = item;
  const isActiveRoot = active(path);
  const company = useSelector(state => state.CompanyReducer);
  const onBoardingSetup = company.companyData?.onBoarding?.onboarding_is_completed ? true : false;

  
  const [open, setOpen] = useState(isActiveRoot);
  const handleOpen = () => {
    setOpen((prev) => !prev);
    setCurrentTitle(title)
  };
  
  //this use effect is for closing previous dropdown when another opens
  useEffect(() => {
    if (currentTitle != title) {
      setOpen(false);
    }
  }, [currentTitle]);


  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          isOnboardingCompleted={onBoardingSetup}
          // id='activeRootStylefromSub'
          className={isActiveRoot && 'activeRootStyle'}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={EIconSideNavbar}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ minWidth:'44px',display:'flex' }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children?.map((item) => {
                const { title, path, show } = item;
                const isActiveSub = active(path);
                
                if (show) {
                  return show == "yes" &&
                    (
                      <ChildrenListItemStyle
                        key={title}
                        component={RouterLink}
                        to={path}
                        className={isActiveSub && 'activeSubStyle'}
                        isOnboardingCompleted={onBoardingSetup}
                      >
                        <ListItemIconStyle> </ListItemIconStyle>
                        <ListItemText disableTypography primary={title} />
                      </ChildrenListItemStyle>
                    );
                }
                else {
                  return (
                    <ChildrenListItemStyle
                      key={title}
                      component={RouterLink}
                      to={path}
                      className={isActiveSub && 'activeSubStyle'}
                      isOnboardingCompleted={onBoardingSetup}
                    >
                      <ListItemIconStyle>
                        <Box
                          component="span"
                        />
                      </ListItemIconStyle>
                      <ListItemText disableTypography primary={title} />
                    </ChildrenListItemStyle>
                  );
                }
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }
  return (
    <ListItemStyle
    isOnboardingCompleted={onBoardingSetup}
      component={RouterLink}
      to={path}
      className={isActiveRoot && 'activeRootStyle'}
      onClick={() => setCurrentTitle(title)}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ admin,auth, isShow = true, navConfig, setCurrentTitle, currentTitle, ...other }) {
  const { pathname } = useLocation();
 
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false); //this function checks for root nav item with react router function 'matchPath'
  return (
    <Box {...other}>
      {navConfig?.map((list, index) => {
        let { subheader, items } = list;
        if (auth?.authData) {
          items = items.filter((menu) => menu?.show)
        }
        return (
          <List key={subheader + index}  sx={{ px: 0}}>
            {items?.map((item) => (
              <NavItem key={item.title} item={item}
                active={match} isShow={isShow}
                setCurrentTitle={setCurrentTitle}
                currentTitle={currentTitle}
                 />
            )
            )}
          </List>
        );
      })}
    </Box>
  );
}
