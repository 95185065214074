import React, { useEffect, useState } from 'react';
import {
  Grid,
  Stack,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import base64 from 'base-64';
import moment from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import {  CONTRACT_TERMINATION_URL, NIL, CHARACTER_LIMIT } from '../../constants';
import Page from '../../BaseComponents/EPage';
import { TerminateContractValidation } from '../../utils/validation';
import { ContractViewData, CreateContract } from '../../action/ContractAction';
import { ELabelInputMainGrid, LoadingGrid } from '../../BaseComponents/EGrid';
import { EBox, EBoxPage } from '../../BaseComponents/EBox';
import { EDivider } from '../../BaseComponents/EDivider';
import { EIcon } from '../../BaseComponents/EIcon';
import { EFormHelperText, ETypography, ETypographyCardHeading, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { ECard } from '../../BaseComponents/ECard';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { EButton, EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { numberWithCommas } from '../../utils/formatNumber';
import { ETextField } from '../../BaseComponents/ETextField';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import EHidden from '../../BaseComponents/EHidden';
import BackIcon from '@iconify/icons-material-symbols/chevron-left-rounded'

/**
 * [2022-09-15]
 * Created by:- Aanchal Sahu
 * Description:- designed this component AND MADE IT DYNAMIC & fixed errors
 **/

function ContractTerminatePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const contractDetails = useSelector((state) => state.ContractReducer);
  const auth = useSelector((state) => state.authReducer);
  const id = params && params.id ? base64.decode(params.id) : '';
  const contractData = contractDetails?.contractData;
  var lastWorkingDateValue = moment().format('YYYY-MM-DD');
  const [openDate, setOpenDate] = useState(false);

  const formik = useFormik({
    initialValues: {
      contract_id: '',
      termination_by: '2',
      termination_last_working_date: null,
      termination_settlement_date: null,
      termination_remark: '',
      balance_amount:null
    },
    validationSchema: TerminateContractValidation,
    onSubmit: (data) => {  
      if (lastWorkingDateValue) {
        data.termination_last_working_date = lastWorkingDateValue;
      }
      data.contract_id = contractData.id;
      dispatch(CreateContract(auth.authtoken, data, navigate, CONTRACT_TERMINATION_URL));
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (auth?.authtoken && !contractDetails?.contractSuccess) {
      if (id) {
        dispatch(ContractViewData(auth?.authtoken, id));
      } else {
        navigate('/dashboard');
      }
    }
  }, []);
  
  return (
    <>
      <Page title="Terminate Contract" >
        <Grid container display="row" justifyContent="space-between" >
          <Grid item>
            <EHeaderBreadcrumbs
              heading={contractDetails?.contractData?.contract_code ? contractDetails?.contractData?.contract_code : ''}
              links={[
                { name: 'Dashboard', href: '/' },
                { name: 'Freelancer Contract', href: '/freelancer-contract/contract-list' },
                { name: 'Contract List', href: '/freelancer-contract/contract-list' },
                {
                  name: contractDetails?.contractData?.contract_code ? 'Terminate - ' + contractDetails?.contractData?.contract_code : 'Terminate Contract',
                }
              ]}
            />
          </Grid>
          <EHidden width="mdDown">
          <Grid item>
            <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} className='button-left-margin' >  Back </EButtonOutlined>
          </Grid> 
          </EHidden>
        </Grid>

        <Grid
          container
          display="row"
          justifyContent="space-between"
        >
          <Grid item>
            <ETypographyPageHeading> {contractDetails?.contractData?.contract_code ? 'Terminate Contract - ' + contractDetails?.contractData?.contract_code : ''}</ETypographyPageHeading>
          </Grid>
          <EHidden width="mdUp">
          <Grid item>
            <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin' >  <EIcon icon={BackIcon} className='height-width-15px'/> </EButtonOutlinedIcon>
          </Grid> 
          </EHidden>
        </Grid>
        <EHidden width="mdDown">
                <Grid lg={12} xs={12} item><EDivider className='margin-top-05' /></Grid>
          </EHidden>
        <FormikProvider value={formik} >
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <EBoxPage>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
               
               >
                <Grid item xs={12}>

                  {/* Termination Details */}
                  <ECard className='card_design_1 border-05px-border6'>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <ETypographyCardHeading variant='h5'>
                      Termination Details
                      </ETypographyCardHeading>
                      {/* <EIcon  onClick={() => SetIsEditContractDetails(true)} /> */}
                    </Grid>

                    <EDivider className="mb-16px" />
                  

                    <EBox >
                      {!contractDetails?.contractLoading ?
                        <Grid container spacing={2} className='px-16px'>

                          {/* Contract Name */}
                          <Grid item md={12} sm={12} xs={12}>
                            <ELabelInputMainGrid label={'Contract Name'} isfullgrid={true} isNotForm={true}>
                              {contractData?.contract_title ? contractData?.contract_title : NIL}
                            </ELabelInputMainGrid>
                          </Grid>


                          {/* Tenure */}
                          <Grid item md={12} sm={12} xs={12} >
                            <ELabelInputMainGrid label={'Tenure'} isfullgrid={true} isNotForm={true}>
                            {moment(contractData?.contract_start_date).format('DD/MM/YYYY') + ' to ' + moment(contractData?.contract_end_date).format('DD/MM/YYYY')}
                            </ELabelInputMainGrid>
                          </Grid>

                        

                          {/* Last Working Day */}
                          <Grid item md={6} sm={6} >
                            <ELabelInputMainGrid label={'Last Working Day'} isNotForm={true}>
                              {lastWorkingDateValue ? moment(lastWorkingDateValue).format('DD/MM/YYYY') : NIL}
                            </ELabelInputMainGrid>
                          </Grid>


                          {/* Initiated by  */}
                          <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Initiated by'} isNotForm={true} labelPT='0.5rem'>
                              <FormControl
                                component="fieldset"
                                error={Boolean(touched.termination_by && errors.termination_by)}
                              >
                                <ERadioGroup
                                  row
                                  sx={{flexWrap:'nowrap'}}
                                  aria-label="termination_by"
                                  name="termination_by"
                                  {...getFieldProps('termination_by')}
                                >
                                  <FormControlLabel value="2" control={<ERadio />} label="Company" />
                                  <FormControlLabel value="1" control={<ERadio />} label="Freelancer" />
                                </ERadioGroup>
                                {touched.termination_by && (
                                  <EFormHelperText> {errors.termination_by}</EFormHelperText>
                                )}
                              </FormControl>
                            </ELabelInputMainGrid>
                          </Grid>

                        </Grid>
                        :
                        <LoadingGrid />
                      }
                    </EBox>
                  </ECard>


                  {/* Payment Details */}

                  <ECard className='card_design_1 border-05px-border6'>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      className="mb-8px"
                    >
                      <ETypographyCardHeading variant='h5'>Payment Details</ETypographyCardHeading>
                    </Grid>
                    <EDivider className="mb-16px" />


                    <EBox className="mb-16p borderColor-staticBorder7" >
                      <Grid container className='background-bgColorLight1' padding={2} >
                        {/* Total Amount */}
                        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} className='align-self-center' >
                          <ETypography variant="h4" className="theme-color-static line-height-50perc bold-600">
                              <span className='font-size-18px'>₹</span> 
                              <span className='font-size-20px'> 
                               {contractDetails?.contractData?.total_amount?
                                numberWithCommas(contractDetails?.contractData?.total_amount,'isRs'): numberWithCommas(0,'isRs')}
                                </span> 
                          </ETypography>
                          <span className="font-size-14px">Total Amount</span>
                        </Grid>

                         {/* Divider Vertical */}
                        <Grid item xs={0.5} sm={0.5} md={0.2} lg={0.2} xl={0.2} className='display-in-lg-xl-md '>
                          <EDivider orientation="vertical" className='borderColor-dark-grey' />  
                        </Grid>

                         {/* Paid Amount */}
                        <Grid item xs={5.5} sm={5.5} md={2.8} lg={2.8} xl={2.8} className='align-self-center  pl-16px' >
                          <ETypography variant="h4" className="green-color-static line-height-50perc bold-600 ">
                          <span className='font-size-18px '>₹</span>
                              <span className='font-size-20px'>
                              {contractDetails?.contractData?.paid_amount
                              ? numberWithCommas(contractDetails?.contractData?.paid_amount,'isRs'): numberWithCommas(0,'isRs')}
                              </span> 
                          </ETypography>
                          <span className="font-size-14px " >Paid Amount</span>
                        </Grid>


                       {/* Divider Vertical */}
                       <EHidden width='mdDown'  >
                       <Grid item xs={0.2} sm={0.2} md={0.2} lg={0.3} xl={0.2}>
                          <EDivider orientation="vertical" className='borderColor-dark-grey mr-16px' />
                        </Grid>
                       </EHidden>

                         {/* Divider Vertical */}
                       <EHidden width='mdUp'>
                       <Grid item xs={12} sm={12} md={0.2} lg={12} xl={0.2} paddingY={2}>
                          <EDivider orientation="horizontal" className='borderColor-dark-grey' />
                        </Grid>
                       </EHidden>

                        {/*Final Settlement */}
                        <Grid item xs={6} sm={6} md={2.8} lg={2.7} xl={2.8} className='align-self-center ' >
                          <ETypography variant="h4" className="blue-color-static line-height-50perc bold-600">
                          <span className='font-size-18px'>₹</span> 
                              <span className='font-size-20px '>
                              {contractDetails?.contractData?.final_settlement_amount ? 
                              numberWithCommas(contractDetails?.contractData?.final_settlement_amount,'isRs'): numberWithCommas(0,'isRs')}
                              </span>
                          </ETypography>
                          <span className="font-size-14px ">Final Settlement</span>
                        </Grid>


                        {/* Divider Vertical */}
                        <Grid item xs={0.5} sm={0.5} md={0.2} lg={0.2} xl={0.2} className='display-in-lg-xl-md '>
                          <EDivider orientation="vertical" className='borderColor-dark-grey' />
                        </Grid>

                         {/* Ineligible Amount */}
                        <Grid item xs={5.5} sm={5.5} md={2.8} lg={2.8} xl={2.8} className='align-self-center pl-16px' >
                          <ETypography variant="h4" className="grey-color-static line-height-50perc bold-600">
                            <span className='font-size-18px'>₹</span> 
                               <span className='font-size-20px '>
                               {contractDetails?.contractData?.ineligible_amount?
                                numberWithCommas(contractDetails?.contractData?.ineligible_amount,'isRs'): numberWithCommas(0,'isRs')}
                                </span>
                          </ETypography>
                          <span className="font-size-14px ">Ineligible Amount</span>
                        </Grid>
                      </Grid>


                     {/* Final Settlement */}
                     <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='mt-16px'>
                          <ELabelInputMainGrid label={'Final Settlement'} isfullgrid={true} labelPT='0.5rem'>
                          <FormControl
                                component="fieldset"
                                // error={Boolean(touched.termination_by && errors.termination_by)}
                              >
                                <ERadioGroup
                                  row
                                  aria-label="balance_amount"
                                  name="balance_amount"
                                  {...getFieldProps('balance_amount')}
                                >
                                  <FormControlLabel value="2" control={<ERadio />} label="On Hold" />  
                                  <FormControlLabel value="1" control={<ERadio />} label="Release" />
                                </ERadioGroup>
                                {/* {touched.termination_by && (
                                  <EFormHelperText> {errors.termination_by}</EFormHelperText>
                                )} */}
                              </FormControl>
                          </ELabelInputMainGrid>
                        </Grid>
                        </Grid>


                      <Grid container spacing={2}>
                        {/* Hiding settlement date if final settlement is on hold */}
                        {
                          formik?.values.balance_amount !== "2" && (
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='mt-16px'>
                          <ELabelInputMainGrid label={'Settlement Date'} isfullgrid={true}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <EDatePicker
                                name="termination_settlement_date"
                                minDate={new Date()}
                                inputFormat="dd/MM/yyyy"
                                {...getFieldProps('termination_settlement_date')}
                                onChange={(newValue) => {
                                  setFieldValue('termination_settlement_date', newValue ? newValue : '');
                                }}
                                open={openDate}
                                onOpen={() => setOpenDate(true)}
                                onClose={() => setOpenDate(false)}
                                renderInput={(params) => (
                                  <ETextField
                                    {...params}
                                    name="termination_settlement_date"
                                    placeholder="dd/mm/yyy"
                                    onClick={(e) => setOpenDate(true)}
                                    error={Boolean(
                                      touched.termination_settlement_date &&
                                      errors.termination_settlement_date
                                    )}
                                    helperText={
                                      touched.termination_settlement_date &&
                                      errors.termination_settlement_date
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </ELabelInputMainGrid>
                        </Grid>
                          )
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                          <ELabelInputMainGrid label={'Reason'} isfullgrid={true} >
                            <ETextField
                              fullWidth
                              multiline
                              rows={4}
                              {...getFieldProps('termination_remark')}
                              error={Boolean(touched.termination_remark && errors.termination_remark)}
                              helperText={touched.termination_remark && errors.termination_remark}
                            />
                            <span className='greyColor4-color font-size-14px'>{`${formik.values?.termination_remark?.length}/${CHARACTER_LIMIT}`}</span>
                          </ELabelInputMainGrid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                          <Stack direction="row" spacing={2} className='justify-content-flex-end'>
                          <EButtonOutlined
                                onClick={() => navigate(`/freelancer-contract/view-contract/${base64.encode(id)}`)}
                                variant="outlined"
                                size="large"
                              >
                                Cancel
                              </EButtonOutlined>
                              <EButton type="submit" variant="contained" size="large">
                                Terminate
                              </EButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    </EBox>
                  </ECard>


                </Grid>
              </Grid>
              </EBoxPage>
            </LocalizationProvider>
          </Form>
        </FormikProvider>
      </Page>
    </>
  );
}

export default ContractTerminatePage;
