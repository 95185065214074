import React, { useCallback, useLayoutEffect, useState } from 'react'
import { CircularProgress, Grid, useTheme } from '@mui/material'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_ROWS_PERPAGE, PAGES } from '../../../constants'
import {
  PAYROLL_REPORT_DOWNLOAD_URL
} from '../../../constants'
import { ELabel, ETypography } from '../../../BaseComponents/ETypography'
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow, ETypographyTable } from '../../../BaseComponents/ETable'
import EScrollbar from '../../../BaseComponents/EScrollbar'
import { ECircularProgress } from '../../../BaseComponents/ECircularProgress'
import { ETablePagination } from '../../../BaseComponents/ETablePagination'
import { DownLoadFile } from '../../../action/AdminAction'
import SearchNotFound from '../../../PageComponents/SearchNotFound'
import { EBoxPage } from '../../../BaseComponents/EBox'
import { ECustomCard, EPageWithBreadCrumbs } from '../../../BaseComponents'
import { ELabelInputMainGrid } from '../../../BaseComponents/EGrid'
import { PayrollReportSearchBar } from '../../../PageComponents/Report/PayrollReportSearchBar'
import { getPayrollReport } from '../../../action/PayrollReportAction'
import { numberWithCommas } from '../../../utils/formatNumber'

const TABLE_HEAD = [
  { id: 'S.No.', label: 'S.No.', alignRight: 'center' },
  { id: 'Talent Name', label: 'Talent Name', alignRight: false },
  { id: 'Designation', label: 'Designation', alignRight: false },
  { id: 'Monthly CTC', label: 'Monthly CTC', alignRight: true },
  { id: 'P', label: 'P', alignRight: 'center' },
  { id: 'A', label: 'A', alignRight: 'center' },
  { id: 'H', label: 'H', alignRight: 'center' },
  { id: 'PL', label: 'PL', alignRight: 'center' },
  { id: 'HD', label: 'HD', alignRight: 'center' },
  { id: 'Paid Days', label: 'Paid Days', alignRight: 'center' },
  { id: 'Current Payable Salary', label: 'Current Payable Salary', alignRight: true },
]

const now = moment();
const startDate = moment().startOf('month');
let endDate = now.clone().subtract(1, 'day');

if (now.date() === 1) {
  endDate = startDate?.clone();
}

const initialFilter = {
  employee_id: null,
  employee_details: null,
  start_date: startDate,
  end_date: endDate,
  sort: true,
  page: PAGES,
  per_page: DEFAULT_ROWS_PERPAGE,
}


function PayrollReport() {

  const theme = useTheme()

  const dispatch = useDispatch()

  const { payrollReportData, payrollReportLoading, payrollReportSuccess } = useSelector((state) => state.PayrollReportReducer)
  const auth = useSelector((state) => state.authReducer)


  const [filter, setFilter] = useState(initialFilter)

  useLayoutEffect(() => {
    if (payrollReportLoading == false) {
      callApi({
        ...(filter?.employee_id && { employee_id: filter?.employee_id }),
        ...(filter?.start_date && { start_date: filter?.start_date }),
        ...(filter?.end_date && { end_date: filter?.end_date }),
        sort: filter?.sort,
        page: filter?.page,
        per_page: filter?.per_page,
      })
    }
  }, [filter.employee_id, filter.start_date, filter.end_date, filter.e, filter.sort, filter.page, filter.per_page])

  const callApi = useCallback((filters) => {
    dispatch(getPayrollReport(filters))
  }, [])

  const handleChangePage = (event, newPage) => {
    setFilter({
      ...filter,
      page: newPage,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setFilter({
      ...filter,
      page: 0,
      per_page: event.target.value,
    })
  }

  const resetPage = useCallback(() => {
    setFilter(initialFilter)
  }, [])

  const searchParamHandler = (params) => {
    setFilter({
      ...filter,
      ...(params?.start_date && { start_date: moment(params?.start_date).format('YYYY-MM-DD') }),
      ...(params?.end_date && { end_date: moment(params?.end_date).format('YYYY-MM-DD') }),
      sort: params.sort,
      employee_id: params?.employee_id,
      employee_details: params?.employee_details,
    })
  }

  const downloadSearchReport = (format) => {
    let url = PAYROLL_REPORT_DOWNLOAD_URL
    let file_name = 'Payroll_Report'
    let searchData = {};
    searchData = {
      ...(filter?.employee_id && { employee_id: filter?.employee_id }),
      ...(filter?.start_date && { start_date: filter?.start_date }),
      ...(filter?.end_date && { end_date: filter?.end_date }),
      sort: filter?.sort,
    };
    if (format != null) {
      url += `?download_format=${format}`
    }
    DownLoadFile(auth.authtoken, url, file_name, searchData)
  }

  return (
    <EPageWithBreadCrumbs
      title="Payroll Report"
      pageHeading="Payroll Report"
      breadcrumbsTitle="Payroll Report"
      breadcrumbsLinks={[
        { name: 'Dashboard', href: '/' },
        { name: 'Report', href: '' },
        { name: 'Payroll Report' }
      ]}
      loading={payrollReportLoading || Object.keys(payrollReportData)?.length == 0}
    >
      <EBoxPage>
        <PayrollReportSearchBar
          setSearch={searchParamHandler}
          auth={auth}
          resetPage={resetPage}
          downloadSearchReport={downloadSearchReport}
          filter={filter}
          withStartDate={true}
          startDate={filter.start_date}
          minStartDate={startDate}
          endDate={filter.end_date}
          maxEndDate={endDate}
          withEndDate={true}
          withEmployee={true}
          withReset={true}
          withNameSort={true}
        />
        <ECustomCard
          title={`${moment(filter.start_date).format('MMMM')} Payroll Summary`}
          hideEdit={true}
        >
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6} item >
              <ELabelInputMainGrid label={'Total Talents'} isModal={true} isNotForm={true}>
                {payrollReportData?.meta?.count || "0"}
              </ELabelInputMainGrid>
            </Grid>
            <Grid xs={12} sm={6} md={6} item >
              <ELabelInputMainGrid label={'Active Talents'} isModal={true} isNotForm={true}>
                {payrollReportData?.meta?.total_active || "0"}
              </ELabelInputMainGrid>
            </Grid>
            <Grid xs={12} sm={6} md={6} item >
              <ELabelInputMainGrid label={'Total Payable'} small_text={`(${moment(filter.start_date).format('DD/MM/YYYY')} to ${moment(filter.end_date).format('DD/MM/YYYY')})`} isModal={true} isNotForm={true}>
                {numberWithCommas(payrollReportData?.meta?.total_payable_ctc || 0)}
              </ELabelInputMainGrid>
            </Grid>
            <Grid xs={12} sm={6} md={6} item >
              <ELabelInputMainGrid label={'Released Talents'} isModal={true} isNotForm={true}>
                {payrollReportData?.meta?.total_released || "0"}
              </ELabelInputMainGrid>
            </Grid>
            <Grid xs={12} sm={12} md={12} item >
              <ELabel sx={{ color: theme.palette.text.greyColor4, fontSize: 12 }}>* Released Talents are highlighted in the table</ELabel>
            </Grid>
          </Grid>
        </ECustomCard>

        {payrollReportLoading && Object.keys(payrollReportData)?.length == 0 ?
          <ETableContainer>
            <ETable>
              <ETableBody>
                <ETableRow>
                  <ETableCell align="center" colSpan={10} sx={{ py: 3 }}>
                    <CircularProgress color="primary" />
                  </ETableCell>
                </ETableRow>
              </ETableBody>
            </ETable>
          </ETableContainer>
          :
          !payrollReportLoading && (payrollReportData?.rows?.length == 0 || Object.keys(payrollReportData)?.length == 0) ?
            <SearchNotFound />
            :
            <>
              <EScrollbar>
                <ETableContainer>
                  <ETable>
                    <EListHead
                      headLabel={TABLE_HEAD}
                      rowCount={payrollReportData?.rows?.length}
                      onRequestSort={() => null}
                    />
                    {payrollReportLoading && (
                      <ETableBody>
                        <ETableRow>
                          <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <ECircularProgress color="primary" />
                          </ETableCell>
                        </ETableRow>
                      </ETableBody>
                    )}
                    {!payrollReportLoading && payrollReportData?.rows?.length > 0 && (
                      <ETableBody>
                        {payrollReportData?.rows?.map((row, index) => {
                          const {
                            fullname,
                            status,
                            designation_name,
                            monthly_ctc,
                            employee_code,
                            total_present,
                            total_absent,
                            total_holiday,
                            total_paid_leave,
                            total_half_day,
                            paid_days,
                            current_payable_ctc,
                          } = row
                          return (
                            <ETableRow
                              key={index}
                              tabIndex={-1}
                              hover
                              className={`${Number(status) === 2 ? 'table-released-row-bg' : ''}`}
                            >
                              <ETableCell align="center">
                                {index + 1}
                              </ETableCell>
                              {/* Talent Name */}
                              <ETableCell align="left"
                                className={"min-width-150px max-width-300px"}> {/*table-cells-padding-vertical-align*/}
                                <ETypography className="dark-grey-color bold-font font-size-14px bold-600">
                                  {fullname}<br />
                                  <span className="bold-400">
                                    ({employee_code})
                                  </span>
                                </ETypography>
                              </ETableCell>
                              <ETableCell align="left" style={{ verticalAlign: 'top' }} className="width-160px pt-16px">
                                {designation_name || "-"}
                              </ETableCell>
                              <ETableCell align="right" style={{ verticalAlign: 'top' }} className="minWidth-161px pt-16px">
                                {numberWithCommas(monthly_ctc)}
                              </ETableCell>
                              <ETableCell align="center" style={{ verticalAlign: 'top' }} sx={{ minWidth: '4rem' }} className=" pt-16px">
                                {total_present?.replace(/\.0$/, "") || "-"}
                              </ETableCell>
                              <ETableCell align="center" style={{ verticalAlign: 'top' }} sx={{ minWidth: '4rem' }} className=" pt-16px">
                                {total_absent?.replace(/\.0$/, "") || "-"}
                              </ETableCell>
                              <ETableCell align="center" style={{ verticalAlign: 'top' }} sx={{ minWidth: '4rem' }} className=" pt-16px">
                                {total_holiday?.replace(/\.0$/, "") || "-"}
                              </ETableCell>
                              <ETableCell align="center" style={{ verticalAlign: 'top' }} sx={{ minWidth: '4rem' }} className=" pt-16px">
                                {total_paid_leave?.replace(/\.0$/, "") || "-"}
                              </ETableCell>
                              <ETableCell align="center" style={{ verticalAlign: 'top' }} sx={{ minWidth: '4rem' }} className=" pt-16px">
                                {total_half_day?.replace(/\.0$/, "") || "-"}
                              </ETableCell>
                              <ETableCell align="center" style={{ verticalAlign: 'top' }} sx={{ minWidth: '4rem' }} className=" pt-16px">
                                {paid_days?.replace(/\.0$/, "") || "-"}
                              </ETableCell>
                              <ETableCell align="right" style={{ verticalAlign: 'top' }} className="minWidth-161px pt-16px">
                                {numberWithCommas(current_payable_ctc) || "-"}
                              </ETableCell>
                            </ETableRow>
                          )
                        })}
                        <ETableRow>
                          <ETableCell colSpan={3}></ETableCell>
                          <ETableCell sx={{ fontWeight: 600 }} align='right' >{numberWithCommas(payrollReportData?.meta?.total_monthly_ctc)}</ETableCell>
                          <ETableCell sx={{ fontWeight: 600 }} align='center'>{payrollReportData?.meta?.total_present?.replace(/\.0$/, "")}</ETableCell>
                          <ETableCell sx={{ fontWeight: 600 }} align='center'>{payrollReportData?.meta?.total_absent?.replace(/\.0$/, "")}</ETableCell>
                          <ETableCell sx={{ fontWeight: 600 }} align='center'>{payrollReportData?.meta?.total_holiday?.replace(/\.0$/, "")}</ETableCell>
                          <ETableCell sx={{ fontWeight: 600 }} align='center'>{payrollReportData?.meta?.total_paid_leave?.replace(/\.0$/, "")}</ETableCell>
                          <ETableCell sx={{ fontWeight: 600 }} align='center'>{payrollReportData?.meta?.total_half_day?.replace(/\.0$/, "")}</ETableCell>
                          <ETableCell sx={{ fontWeight: 600 }} align='center'></ETableCell>
                          <ETableCell sx={{ fontWeight: 600 }} align='right'>{numberWithCommas(payrollReportData?.meta?.total_payable_ctc)}</ETableCell>
                        </ETableRow>
                      </ETableBody>
                    )}
                  </ETable>
                </ETableContainer>
                <ETablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={payrollReportData?.count ? payrollReportData?.count : 0}
                  rowsPerPage={Number(filter.per_page)}
                  page={Number(filter.page)}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </EScrollbar>
            </>
        }
      </EBoxPage>
    </EPageWithBreadCrumbs>
  )
}

export default PayrollReport
