import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';

import { AttendanceLeaveList, EmployeeLeaveBalance } from '../../action/EmployeeLeaveAction';
import LeaveSearchComponent from '../../PageComponents/Attendance/LeaveSearchComponent';
import SearchNotFound from '../../PageComponents/SearchNotFound';
import moment from 'moment';
import { capitalizeCapitalString } from '../../utils/formatText';
import ApplyLeaveForm from './ApplyLeaveForm';
import LeaveViewForm from '../../pages/Attendance/LeaveViewForm';
import LeaveApproveForm from '../../pages/Attendance/LeaveApproveForm';
import { DeleteLeave } from '../../action/EmployeeLeaveAction';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';

import { Grid, Stack } from '@mui/material';
import EModal from '../../BaseComponents/EModal';
import { EIcon } from '../../BaseComponents/EIcon';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../BaseComponents/ETable';
import { EButton, EButtonOutlined, EIconButton } from '../../BaseComponents/EButtons';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import {
    CalendarYear, DEFAULT_ROWS_PERPAGE, FinancialYear, HRPENDING, LEAVE_HISTORY_TAB,
    LEAVE_REQUEST_TAB, MY_LEAVE_TAB, PAGES, PENDING_STATUS, ROW_SIZE_PER_PAGE, TALENT_SM
} from '../../constants';
import EChip from '../../BaseComponents/EChip';
import { EBox } from '../../BaseComponents/EBox';
import { ETypography, ETypographyCardHeading } from '../../BaseComponents/ETypography';
import { EDividerCard } from '../../BaseComponents/EDivider';
import { ECard2 } from '../../BaseComponents/ECard';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import DeleteCircle from '@iconify/icons-material-symbols/delete-outline-rounded';
import ActionIcon from '@iconify/icons-material-symbols/chevron-right-rounded';
import editFill from "@iconify/icons-material-symbols/edit-outline-rounded";
import EyeIcon from '@iconify/icons-material-symbols/visibility-outline-rounded';

/**
 * INFO: This component is leave log page which is also reused in view talent( talent_id= talent's id) and my profile (isMyProfile= true & talent_id= talent's id). 
 * STATUS INFO: 1=> PENDING, 2=> PARTIALLY, 3=> APPROVED, 4 => REJECTED
 * 
 * @param {boolean} isMyProfile: coming from my profile page; since this is getting reused there in view profile-> leave log tab.
 * @param {number} talent_id : coming from My Profile & View Talent Profile component; talent_id will be set to employee_id and only his leaves will be shown.
 **/

function LeaveLog(props) {

    const { setSearchButtonBar, isMyProfile, talent_id, tabValue = MY_LEAVE_TAB } = props; //INFO: talent_id is for view-talent page
    const isFromOtherPage = talent_id ? true : false;

    const dispatch = useDispatch();

    const auth = useSelector(state => state.authReducer);
    const empleave = useSelector(state => state.LeaveEmployeeReducer);
    const leaveCount = useSelector(state => state.LeaveEmployeeReducer?.empLeaveViewData);

    // const [tabValue, setTabValue] = useState(MY_LEAVE_TAB); // stores tab value
    const [pageNo, setPageNo] = useState(PAGES); //stores page number
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE); // stores number of rows per page

    // INFO: Leave settings
    const settingsContext = useCompanySettingProvider(); //INFO: Collecting data from Company Setting Context 
    const leaveSettings = settingsContext?.setting?.leaveSetting?.companySetting ?? {}; //INFO: Collecting leave setting data from settingsContext

    const [SearchParameters, setSearchParameters] = useState({
        sortBy: null, yearMonth: null,
        employee_id: talent_id ? talent_id : null,
        talentFilterObject: null,
        statusFilterObject: null,
        approval_status: null
    });

    const handleSearchParameters = (newValue) => {
        setSearchParameters(newValue);
        resetPagination();
    };

    // Info: calling view leave log api for leave information of logged in user.
    useEffect(() => {
        if (auth?.authData.id && tabValue == MY_LEAVE_TAB) {
            dispatch(EmployeeLeaveBalance(auth?.authtoken, talent_id ? talent_id : auth?.authData?.id));
        }
    }, [auth?.authSuccess]);

    // Info: calling leave log api for leave information of logged in user; will be called on change of page, row per Page and search.
    useEffect(() => {
        let data = {}
        if (auth?.authData && auth?.authData?.id) {

            if (SearchParameters?.yearMonth != null) {
                data = { ...data, year_month: SearchParameters?.yearMonth }
            } else {
                data = { ...data, year_month: null }
            }
            if (SearchParameters?.sortBy != null) {
                data = { ...data, sortBy: SearchParameters?.sortBy }
            }
            if (SearchParameters?.approval_status) {
                data = { ...data, approval_status: SearchParameters?.approval_status };
            }
            if (SearchParameters?.employee_id != null || talent_id) {
                data = { ...data, employee_id: talent_id ? talent_id : SearchParameters?.employee_id ? SearchParameters?.employee_id : null };
            }

            if (!talent_id) { // condition for tab value
                data = { ...data, tab: tabValue };
            }
            if (auth?.authtoken) {
                dispatch(AttendanceLeaveList(auth.authtoken, data, pageNo, rowsPerPage));
            }
        }

        if (setSearchButtonBar) { setSearchButtonBar(data) }

    }, [SearchParameters, pageNo, rowsPerPage])

    // this will be called to reset pagination and search on tab change
    // useEffect(() => {
    //     if (auth?.authData && auth?.authData?.id) {
    //         resetSearch()
    //         resetPagination()
    //     }
    // }, [tabValue])

    // Get month Duration for My Leave summary
    const monthduration = useMemo(() => getmonthDuration({ leaveSettings }), [leaveSettings?.leave_from_month, leaveSettings?.leave_to_month]);


    // INFO: A reset function; to reset following details.
    // const resetSearch = () => {
    //     setSearchParameters({ sortBy: null, yearMonth: null, employee_id: null, talentFilterObject: null, approval_status: null, statusFilterObject: null });
    // }

    // Resetting page and rows per page
    const resetPagination = () => {
        setPageNo(PAGES);
        setRowsPerPage(DEFAULT_ROWS_PERPAGE)
    }
    // State for talent delete action modal
    const [deleteLeaveRequest, setDeleteLeaveRequest] = useState({
        modalOpen: false,
        id: null,
        leaveDetail: {}
    })

    // State that manages the view leave 
    const [isViewLeave, setIsViewLeave] = useState({
        open: false,
        data: {}
    })
    // State for talent delete action modal
    const [isEditLeave, setIsEditLeave] = useState({
        open: false,
        id: null,
        data: {}
    })

    const [isLeaveApprove, setIsLeaveApprove] = useState({ open: false, data: null });
    const TABLE_HEAD = useMemo(() => getTableHead({ auth, tabValue, isFromOtherPage }), [auth, tabValue, isFromOtherPage]);



    //INFO: TO handle page change
    const handleChangePage = (event, newPage) => {
        setPageNo(newPage, pageNo, rowsPerPage);
    };

    //INFO: To handle change in number of rows
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageNo(0, pageNo, rowsPerPage);
    };

    // This is handling delete leave action
    const handleDeleteLeave = (id) => {
        const data = {
            leave_head_id: id,
            page: pageNo,
            rowsPerPage,
            employee_id: auth?.authData?.id,
            search: { year_month: SearchParameters?.yearMonth, tab: tabValue, ...SearchParameters }
        }
        dispatch(DeleteLeave(auth?.authtoken, data))
        setDeleteLeaveRequest({
            ...deleteLeaveRequest, modalOpen: false
        })
    }

    return (
        <>
         {/* summary */}
        {tabValue == MY_LEAVE_TAB ?
                        <LeaveSummaryComponent isFromOtherPage={isFromOtherPage} monthduration={monthduration} leaveCount={leaveCount} isMyProfile={isMyProfile} />
                        : <></>}
            {/* Search Not Found Error */}
            <LeaveSearchComponent
                        // isFromOtherPage={isFromOtherPage}// no use as of now
                        childTab={tabValue}
                        LeaveSearch={SearchParameters}
                        setLeaveSearch={handleSearchParameters}
                        leaveSettings={leaveSettings}
                    />
            {!empleave?.empLeaveListLoading && empleave?.empLeaveListData?.count == 0 ?
                <SearchNotFound />
                :
                <>
                    <EScrollbar>
                        <ETableContainer >
                            <ETable>
                                {/* TABLE HEAD */}
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={empleave?.empLeaveListData?.rows?.length}
                                    onRequestSort={() => null}
                                />

                                {/* Loading */}
                                {empleave?.empLeaveListLoading && (
                                    <ETableBody>
                                        <ETableRow>
                                            <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                <ECircularProgress color="primary" />
                                            </ETableCell>
                                        </ETableRow>
                                    </ETableBody>
                                )}

                                {/* Table Body */}
                                {!empleave?.empLeaveListLoading && empleave?.empLeaveListData?.rows?.length > 0 &&
                                    (
                                        <ETableBody>
                                            {empleave?.empLeaveListData?.rows?.map((row, index) => {
                                                const { employee, from_date, applied_date, main_status,
                                                    employee_id, leave_is_multiple, to_date_is_half, from_date_is_half, color,
                                                    from_application, reason, id, to_date, updated_at
                                                } = row;
                                                const isReportingManager = employee?.reporting_manager_id == auth?.authData?.id ? true : false;
                                                return (
                                                    <ETableRow hover key={index} tabIndex={-1} style={{ background: from_application == 0 ? color : '' }}
                                                        className={empleave?.empLeaveListData?.rows?.length == index + 1 ? 'table-border-none' : ''}
                                                    >
                                                        {/* Applied Date */}
                                                        <ETableCell align="left">
                                                            {moment(applied_date).format('DD/MM/YYYY')}
                                                        </ETableCell>

                                                        {/* Talent Name */}
                                                        {!isFromOtherPage && tabValue != MY_LEAVE_TAB &&
                                                            <ETableCell align="left" style={{ minWidth: '150px' }}>
                                                                <span className='bold-600'>{employee?.fullname ? employee?.fullname : "-"}</span>
                                                                <br />
                                                                {employee?.employee_code ? '(' + employee?.employee_code + ')' : "-"}
                                                            </ETableCell>
                                                        }

                                                        {/* Leave Period */}
                                                        <ETableCell align="left" className="minWidth-150 pr-0px" >
                                                            {from_date && to_date &&
                                                                moment(from_date).format('DD/MM/YYYY') != moment(to_date).format('DD/MM/YYYY')
                                                                ? moment(from_date).format('DD/MM/YYYY') + ' to ' + moment(to_date).format('DD/MM/YYYY')
                                                                : (moment(from_date).format('DD/MM/YYYY') ==
                                                                    moment(to_date).format('DD/MM/YYYY')) && from_date && to_date
                                                                    ? moment(from_date).format('DD/MM/YYYY')
                                                                    : '-'}
                                                        </ETableCell>


                                                        {/* Updated On */}
                                                        {tabValue == MY_LEAVE_TAB &&
                                                            <ETableCell align="left" className="minWidth-100 pr-0px" >
                                                                {updated_at ? moment(updated_at).format('DD/MM/YYYY') : ''}
                                                            </ETableCell>
                                                        }

                                                        {/* Status */}
                                                        {tabValue != LEAVE_REQUEST_TAB &&
                                                            <ETableCell align='center' className="p0 minWidth-100">
                                                                {main_status ?
                                                                    <EChip width='6.9rem'
                                                                        label={main_status == HRPENDING ? 'HR Pending' : capitalizeCapitalString(main_status)}
                                                                    /> : ''}
                                                            </ETableCell>
                                                        }

                                                        {/* Actions */}
                                                        {!isFromOtherPage &&
                                                            <ETableCell align={tabValue == MY_LEAVE_TAB ? "left" : "center"} className='width-80px'>

                                                                {/* Respond Button: Will be only visible to the request tab
                                                                Condition: View button will only shown to RM until he has taken an action. */}
                                                                {tabValue == LEAVE_REQUEST_TAB &&
                                                                    (isReportingManager ? main_status == PENDING_STATUS : true) &&
                                                                    <ETooltip title={'Respond'} arrow>
                                                                        <span>
                                                                            <EIconButton color='primary' className='p0'
                                                                                disabled={
                                                                                    moment(new Date()).isAfter(from_date) &&
                                                                                        moment(new Date()).format('YYYY-MM-DD') != from_date
                                                                                        ? true : false
                                                                                }
                                                                                onClick={() => {
                                                                                    setIsLeaveApprove({
                                                                                        open: true,
                                                                                        data: {
                                                                                            id,
                                                                                            reporting_manager_id: employee?.reporting_manager_id,
                                                                                            employee_id,
                                                                                            fullname: employee?.fullname,
                                                                                            employee_code: employee?.employee_code
                                                                                        }
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <EIcon icon={ActionIcon} width={23} height={23} />{' '}
                                                                            </EIconButton></span>
                                                                    </ETooltip>
                                                                }

                                                                {/* View Button: will be visible to my leave tab (if it's not in pending status) & leave history tab
                                                                Condition: after RM's action and before HR's final action, view will show on request tab to RM.
                                                                */}
                                                                {(tabValue == LEAVE_HISTORY_TAB || (tabValue == MY_LEAVE_TAB ? main_status != PENDING_STATUS : false)
                                                                    || (isReportingManager && tabValue == LEAVE_REQUEST_TAB ? (main_status != PENDING_STATUS) : false)) &&
                                                                    <ETooltip title={'View'} arrow>
                                                                        <span>
                                                                            {/* View Button */}
                                                                            <EIconButton color='primary' className='p0'
                                                                                onClick={() => { setIsViewLeave({ open: true, data: { from_date, to_date, id } }); }}
                                                                            >
                                                                                <EIcon icon={EyeIcon} />
                                                                            </EIconButton></span>
                                                                    </ETooltip>
                                                                }

                                                                {/* Edit & Delete Buttons: will be visible if the main_status is PENDING */}
                                                                {tabValue == MY_LEAVE_TAB && main_status == PENDING_STATUS &&
                                                                    <>
                                                                        {/* Edit Button */}
                                                                        <EIconButton className='p0' onClick={() => setIsEditLeave({
                                                                            ...isEditLeave, open: true,
                                                                            data: { to_date, from_date, id, reason, leave_is_multiple, to_date_is_half, from_date_is_half },
                                                                            id: id
                                                                        })} >
                                                                            <EIcon className='mr-8px' icon={editFill} width={23} height={23} />
                                                                        </EIconButton>

                                                                        {/* Delete Button */}
                                                                        <EIconButton className='p0' onClick={() => setDeleteLeaveRequest({
                                                                            ...deleteLeaveRequest, modalOpen: true, leaveDetail: row, id: id
                                                                        })} >
                                                                            <EIcon className='icon-red' icon={DeleteCircle} width={23} height={23} />
                                                                        </EIconButton>
                                                                    </>}
                                                            </ETableCell>
                                                        }
                                                    </ETableRow>
                                                );
                                            })}
                                        </ETableBody>
                                    )}
                            </ETable>
                        </ETableContainer>
                    </EScrollbar>
                    {empleave?.empLeaveListData?.count && empleave?.empLeaveListData?.count >= DEFAULT_ROWS_PERPAGE ?
                        <ETablePagination
                            rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                            component="div"
                            count={empleave?.empLeaveListData?.count ? empleave?.empLeaveListData?.count : 0}
                            rowsPerPage={rowsPerPage}
                            page={pageNo}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        : <></>}
                </>
            }
            {/* Leave Approve Modal */}
            {isLeaveApprove?.open && (
                <EModal
                    open={isLeaveApprove?.open}
                    close={() => setIsLeaveApprove({ open: false, data: null })}
                    headervalue={'Leave Request ' + (isLeaveApprove?.data?.fullname && isLeaveApprove?.data?.employee_code ? ' - ' + isLeaveApprove?.data?.fullname + ' (' + isLeaveApprove?.data?.employee_code + ')' : '')}
                >
                    <LeaveApproveForm
                        auth={auth}
                        details={isLeaveApprove?.data}
                        close={() => setIsLeaveApprove({ open: false, data: null })}
                        SearchParameters={{
                            tab: tabValue,
                            page: pageNo,
                            rowsPerPage: rowsPerPage,
                            ...SearchParameters
                        }}

                    // leaveSettings={leaveSettings} // commented this because the half day setting is not applicable as of now
                    />
                </EModal>
            )}

            {/* Leave update Modal */}
            {isEditLeave.open && (
                <EModal
                    open={isEditLeave.open}
                    close={() => setIsEditLeave(false)}
                    headervalue={'Update Leave'}
                >
                    <ApplyLeaveForm open={isEditLeave.open}
                        LeaveSearch={{
                            tab: tabValue,
                            page: pageNo,
                            rowsPerPage: rowsPerPage,
                            ...SearchParameters
                        }}
                        close={() => setIsEditLeave(false)} fromPage="Leave" auth={auth} isEditData={isEditLeave?.data} />
                </EModal>
            )}

            {/* Leave View Modal */}
            {isViewLeave.open && (
                <EModal
                    open={isViewLeave.open}
                    close={() => setIsViewLeave({ open: false, data: null })}
                    headervalue={'View Leave Details'}
                >
                    <LeaveViewForm open={isViewLeave.open}//LeaveSearch={LeaveSearch}
                        close={() => setIsViewLeave({ open: false, data: null })} fromPage="Leave" auth={auth} details={isViewLeave?.data} />
                </EModal>
            )}

            {/* Delete Request Modal */}
            {deleteLeaveRequest && (
                <EModal open={deleteLeaveRequest.modalOpen} headervalue={'Leave: Delete Confirmation'}>
                    <ETypography>
                        Are you sure you want to delete leave   {"   "}
                        <span className='bold-400'>
                            {deleteLeaveRequest?.leaveDetail?.from_date !== deleteLeaveRequest?.leaveDetail?.to_date ?
                                <> from {returnColouredText(moment(deleteLeaveRequest?.leaveDetail?.from_date).format('DD/MM/YYYY'))}
                                    to {returnColouredText(moment(deleteLeaveRequest?.leaveDetail?.to_date).format('DD/MM/YYYY'))}
                                </>
                                : <> of {returnColouredText(moment(deleteLeaveRequest?.leaveDetail?.from_date).format('DD/MM/YYYY'))}</>
                            }
                        </span>
                        ?
                    </ETypography>
                    <Grid item xs={12} xl={12} paddingTop={'16px'}>
                        <Stack direction='row' spacing={2} className='modal1-buttons-stick-bottom '>
                            <span>
                                <EButtonOutlined variant='outlined' size='large' onClick={() => setDeleteLeaveRequest(!deleteLeaveRequest)}> No</EButtonOutlined>
                            </span>
                            <span>
                                <EButton onClick={() => handleDeleteLeave(deleteLeaveRequest.id)} variant='contained' size='large'> Yes </EButton>
                            </span>
                        </Stack>
                    </Grid>
                </EModal>
            )}

        </>
    )
}

export default React.memo(LeaveLog)


// **************************Child components below****************************** 

const getTableHead = (props) => {
    const { tabValue, isFromOtherPage } = props;
    let TABLE_HEAD = [{ id: 'Applied Date', label: 'Applied Date', alignRight: false }];

    // Since talent tab will only be shown in history & request tabs of leave log page.
    if (tabValue == LEAVE_REQUEST_TAB || tabValue == LEAVE_HISTORY_TAB) {
        TABLE_HEAD.push({ id: 'Talent Name', label: 'Talent Name', alignRight: false })
    }

    //No condition, since it will be shown everywhere.
    TABLE_HEAD.push({ id: 'Leave Period', label: 'Leave Period', alignRight: false })

    // Since this updated on will NOT be shown in the history & request tabs
    if (tabValue == MY_LEAVE_TAB) { TABLE_HEAD.push({ id: 'Updated On', label: 'Updated On', alignRight: false }) }

    //Since status will be shown in the my leave & history tab
    if (tabValue != LEAVE_REQUEST_TAB) { TABLE_HEAD.push({ id: 'Status', label: 'Status', alignRight: 'center' }) }

    // INFO: This condition is if this page was accessed through talent view page; we don't have to show action column
    if (!isFromOtherPage) { TABLE_HEAD.push({ id: 'Action', label: 'Action', alignRight: 'center' }) }

    return TABLE_HEAD;
}

// Returning string for the leave summary; example:  Financial Year(Apr 2023 to Mar 2024)
const getmonthDuration = (props) => {
    const { leaveSettings } = props;
    const { leave_to_month, leave_from_month } = leaveSettings;

    var monthDurationString = null;

    if (leave_from_month && leave_to_month) {
        let months = '';
        let monthCount = Math.floor((moment(leave_to_month)).diff((moment(leave_from_month)), 'months', true)) + 1

        if (monthCount == 1) { // means only one month is set as tenure
            months = moment(leave_from_month).format('MMMM');
        } else { // means at least two months is set as tenure
            months = moment(leave_from_month).format('MMM YYYY') + ' to ' + moment(leave_to_month).format('MMM YYYY');
        }

        monthDurationString = monthCount;
        //setting the month string conditionally
        if (leaveSettings?.leave_tenure_category === CalendarYear) {
            monthDurationString = ": Calendar Year (" + months + ")"
        } else if (leaveSettings?.leave_tenure_category === FinancialYear) {
            monthDurationString = ": Financial Year(" + months + ")"
        } else {
            monthDurationString = monthDurationString && months && ": " + monthDurationString + (monthCount === 1 ? " month" : " months") + "(" + months + ")"
        }
    }
    return monthDurationString;
}

//INFO: This return summary component
const LeaveSummaryComponent = (props) => {
    const { isFromOtherPage, monthduration, leaveCount, isMyProfile } = props

    return (
        <ECard2 sx={{ height: 'auto', textAlign: 'left', mx: "auto" }} className='mb-16px'>
            <ETypographyCardHeading variant='h5' >
                {isFromOtherPage != TALENT_SM ? 'My ' : ''} Leave Summary {monthduration ? monthduration : ''}
            </ETypographyCardHeading>
            <EDividerCard className='margin-top-05 mb-16px' />
            <Grid container spacing={2}>
                <LeaveCountGrid count={leaveCount?.total_allowed_leaves ? Number(leaveCount?.total_allowed_leaves).toFixed(1) : '0.0'}
                    largeText={'Total'} smallText={'Paid Leaves'} boxBgClassname='darkBlue-bgcolor' />

                <LeaveCountGrid count={leaveCount?.total_allowed_leaves > 0 && leaveCount?.leave_taken >= 0 && (leaveCount?.total_allowed_leaves - leaveCount?.leave_taken >= 0) ?
                    (leaveCount?.total_allowed_leaves - leaveCount?.leave_taken).toFixed(1) : '0.0'}
                    largeText={'Available'} smallText={'Paid Leaves'} boxBgClassname={'darkGreen-bgcolor'} />

                <LeaveCountGrid count={leaveCount?.leave_taken ? Number(leaveCount?.leave_taken).toFixed(1) : 0}
                    largeText={'Exhausted'} smallText={'Paid Leaves'} boxBgClassname='darkGrey-bgcolor' />

                <LeaveCountGrid count={leaveCount?.total_unpaid_leaves ? (Number(leaveCount?.total_unpaid_leaves)).toFixed(1) : 0}
                    largeText={!isMyProfile ? 'Unpaid' : 'Taken'} smallText={'Leaves'} boxBgClassname='greyColor3-bgcolor' />
            </Grid>
            {/* Commenting this section as per @dharam sir on 29th April,23 */}
            {/* {leaveSettings?.enable_half_day_leave_request ?  */}
            <ETypography className='font-size-12px mt-10px greyColor4-color'>*0.5 indicates half day</ETypography>

        </ECard2>
    )
};

// This function returns customized grid
const LeaveCountGrid = (props) => {
    const { count, largeText, smallText, boxBgClassname } = props
    return (
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <EBox className={'borderRadius-4px height-120px p-16px display-grid display-flex align-content-space-between ' + (boxBgClassname ? boxBgClassname : '')}>
                <ETypography className='color-staticPrimaryLight line-height-168px'>
                    <span className='bold-600 font-size-20px line-height-33px'>{largeText}</span>
                    <br />
                    <span className='font-weight-400 font-size-14px'>{smallText}</span>
                </ETypography>
                <ETypography className='bold-600 color-staticPrimaryLight font-size-20px line-height-168px'>{count} days
                </ETypography>
            </EBox>
        </Grid>
    )
}


// returns bold & primary text
const returnColouredText = (text) => {
    return text ? <span className='theme-color-static bold-600'> {text} </span> : '';
}