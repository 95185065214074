// import axios from "axios";
import moment from "moment";
import { FEMALE, MALE } from "../constants";

export const onKeyDown = (e) => {
    e.preventDefault();
};

// if input is 'true' , "true" , true , '' , null, this function will return true
// input = "false", 'false' or false , this function will return false
export const ReturnBoolean = (e) => {
    return e == 'false' || e == false ? false : true
};

// if e=( 'true' , "true" || true), this function will return true
// e = ("false", 'false' or false or '' or null), this function will return false
export const ReturnBoolean2 = (e) => {
  return e == 'true' || e == true ? true : false
};

//INFO: this function takes a name(string) as an input and returns the initials
//Example: Input : Aanchal Sahu, Output: AS
export function getInitials(name) {
  if(name){
    const splitname = name.split(' ')
    let finalResult = ''
    for (let i = 0; i < splitname.length; i++) {
        finalResult += splitname[i]?.length > 0 && finalResult?.length < 3 ? ' ' + splitname[i][0].toUpperCase() : ''
    }
    return finalResult
  }
  else {return name}
}

//This function checks if the photo exists on server or not
// export function imageExists(image_url) {
//     axios.get(image_url).then((response) => {
//         return true
//     }).catch((error) => {
//         return false
//     })
//     return false
// }

//This function checks for photo url and returns default images according to gender
export const HandlePhoto = (url, gender) => {
  
  // if (url && url !='undefined' && imageExists(url)) {
    if (url && url !='undefined') {
        return url;
    }
    else {
        const defaultImage = gender == FEMALE ? '/assets/images/female.png' : gender == MALE ? '/assets/images/male.png' : ' '
        return defaultImage;
    }
}


export const ValidateCreditCardNumber = (ccNum) => {

    // var ccNum = document.getElementById("cardNum").value;
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;
  
    if (visaRegEx.test(ccNum)) {
      isValid = true;
    } else if(mastercardRegEx.test(ccNum)) {
      isValid = true;
    } else if(amexpRegEx.test(ccNum)) {
      isValid = true;
    } else if(discovRegEx.test(ccNum)) {
      isValid = true;
    }
  
    return isValid;
  }



  export const timeDiffranceString = (from_Date, to_Date) => {

    var fromDate = moment(from_Date);
    var toDate = moment(to_Date);

    // var years = toDate.diff(fromDate, 'year');
    // fromDate.add(years, 'years');

    var months = toDate.diff(fromDate, 'months');
    fromDate.add(months, 'months');

    var days = toDate.diff(fromDate, 'days');

    return months + ' months ' + days + ' days';
  }

  
  export const timeDiffrancePercent = (from_Date, to_Date) => {

    var fromDate = moment(from_Date);
    var toDate = moment(to_Date);
    var today = moment();

    var days = toDate.diff(fromDate, 'days');
    var todayCount = today.diff(fromDate, 'days');

    return (todayCount * 100) / days;
  }

  export const getTenure = (startDate, endDate) => {
    if (startDate && endDate) {
      return moment(startDate).format('MMM YYYY') + ' to ' + moment(endDate).format('MMM YYYY');
    }
    return '-'
  }

/**
 * Returns number of days between dates.
 *
 * @param {string} startDate - The date string in "YYYY-MM-DD" format.
 * @param {string} endDate - The date string in "YYYY-MM-DD" format.
 * @returns {string} - Returns the positive difference of dates passed, null otherwise
 **/
export const getDayCount = (startDate, endDate) => {
  var difference = null
  if (startDate && endDate) { difference = moment(endDate).diff(moment(startDate), 'days'); }
  if (difference >= 0) { //for +ve values
    return difference + 1;
  } else if (difference < 0) {
    return (difference*(-1)) + 1; //For -ve values; turning into positive values;
  } else {
    return null;//for otherwise
  }
}

/**
 * Accepts dates and returns them in a formatted way; ex- startDate to endDate || startDate || endDate 
 *
 * @param {string} startDate - The date string in "YYYY-MM-DD" format.
 * @param {string} endDate - The date string in "YYYY-MM-DD" format.
 * @param {string} dateFormat - The date format according to moment in string format; default being 'MMM YYYY'
 * @returns {string} - Returns formatted version of dates passed, empty string otherwise
 **/
export const formatDatePeriod = (startDate, endDate, dateFormat = 'MMM YYYY') => {
  //checking if the date is equal
  var isEqual = startDate && endDate ? moment(new Date(startDate)).isSame(new Date(endDate)) : false; 

  if (startDate && endDate && !isEqual) {
    return moment(startDate).format(dateFormat) + ' to ' + moment(endDate).format(dateFormat);
  } else if (startDate) {
    return moment(startDate).format(dateFormat);
  } else if (endDate) {
    return moment(endDate).format(dateFormat);
  } else {
    return ''
  }
}

/**
 * Check if today is the birthday for a given date string.
 *
 * @param {string} dateString - The date string in "YYYY-MM-DD" format.
 * @returns {boolean} - Returns true if today is the birthday, false otherwise.
 */
export const isBirthdayToday = (dateString) => {
  if (!dateString) return false
  
  const today = new Date();
  const birthday = new Date(dateString);
  
  return (today.getMonth() === birthday.getMonth()) && (today.getDate() === birthday.getDate())
}

/**
 * Formats a time string in the format "HH:MM" or "HH:MM:SS" to "hh:mm A".
 *
 * @param {string} timeString - The time string to format. It should be in either "HH:MM" or "HH:MM:SS" format.
 * @param {boolean} returnDate - An optional parameter. If set to true, the function will return the date object without formatting it. Default is false.
 * @returns {string|Date} The formatted time string if withoutFormat is false, or the date object if returnDate is true.
 */
export const formatTime = (timeString, returnDate  = false) => {
  let date = timeString;
  if (typeof timeString === "string") {
    date = new Date(moment(new Date()).format('YYYY-MM-DD') + ' ' + timeString)
  }
  if (returnDate ) return date
  return moment(date).format('hh:mm A')
}

/**
 * Formats a time string in the format "HH:MM" to "HHhrs MMmins".
 *
 * @param {string} timeString - The time string to format.
 * @returns {string} The formatted time string.
 */
export const formatTimeString = (timeString) => {
  // Split the time string at the colon to get hours and minutes
  const [hours, minutes] = timeString?.replace('.', ':')?.split(":");

  // Format the time string using string interpolation
  return `${hours}hrs ${minutes}mins`;
}

/**
 * Navigates to the appropriate onboarding setting page based on the given status object.
 *
 * @param {Object} status - The status object containing the onboarding setting statuses.
 * @param {function} navigate - The navigation function to navigate to the desired page.
 */
export const onBoardingNavigate = (status, navigate) => {

  // Mapping of paths to status keys
  const pathToStatusMap = {
    '/setting/account-setting': 'setting_account_status',
    '/setting/talent-setting': 'setting_talent_status',
    '/setting/attendance-setting': 'setting_attendance_status',
    '/setting/leave-setting': 'setting_leave_status',
    '/setting/payroll-setting': 'setting_payroll_status',
    '/setting/payment-setting': 'setting_payment_status',
    '/setting/additional-setting': 'setting_additional_status',
  };

  // Iterate through the pathToStatusMap and navigate to the first incomplete setting page
  for (const [path, statusKey] of Object.entries(pathToStatusMap)) {
    if (status[statusKey] === 0) {
      navigate(path);
      break;
    }
  }
}


/**
 * This function checks if the given permissions are allowed for the logged-in user.
 * @param {Array<String>} permission Permission array
 * @param {Array<String>} check The permissions in array that you want to check
 * @returns {boolean} Returns true if the given permissions are allowed for the logged-in user; otherwise, returns false
 */
export const isPermissionsAllowed = (permission = [], check = []) => {
  if (check?.length === 0) return true
  return permission.filter(i => check.includes(i)).length > 0
}

