import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import moment from 'moment';
import monitor from '@iconify/icons-material-symbols/desktop-windows-outline-rounded';
import mobile from '@iconify/icons-material-symbols/phone-iphone-outline';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_ROWS_PERPAGE, PAGES, REPORT_BY_DATE, REPORT_BY_TALENT, REPORT_BY_TIME } from '../../constants';
import {
  ATTENDANCE_LOG_REPORT_DOWNLOAD_URL
} from '../../constants';
import { useNavigate } from 'react-router';
import Page from '../../BaseComponents/EPage';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { EButton, EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import { ECardBasic } from '../../BaseComponents/ECard';
import { ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow, ETypographyTable } from '../../BaseComponents/ETable';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import { EDivider } from '../../BaseComponents/EDivider';
import { EIcon } from '../../BaseComponents/EIcon';
import {  ETablePagination } from '../../BaseComponents/ETablePagination';
import { DownLoadFile } from '../../action/AdminAction';
import SearchNotFound from '../../PageComponents/SearchNotFound';
import ReportSearchBar from '../../PageComponents/Report/ReportSearchBar';
import DateMonthScroller from '../../PageComponents/Report/DateMonthScroller';
import { UserAttendanceReportLog } from '../../action/ReportAction';
import { EBoxPage } from '../../BaseComponents/EBox';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import EChip from '../../BaseComponents/EChip';
import ReportsResultsTime from './ReportResultsTime';
import EHidden from '../../BaseComponents/EHidden';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';

/**
 * [2022-12-01]
 * Created by:- Aanchal Sahu
 * Description:- designed this component according to the new theme
 **/

function ReportsResults(props) {
  const { report } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const attendance = useSelector((state) => state.EmployeeMonthAttendanceReducer);
  const company = useSelector((state) => state.CompanyReducer);
  const auth = useSelector((state) => state.authReducer);
  const Theme = useSelector((state) => state.ThemeReducer);
  const [SearchParameters, setSearchParameters] = useState({
    start_date: report == REPORT_BY_TIME ? new Date() : null,
    end_date: report == REPORT_BY_TIME ? new Date() : null,
    sort:report == REPORT_BY_TIME ? false : null,
    page: PAGES,
    rowsPerPage: 10,
    date: new Date(),
    report_type: report,
    employee_id: report != REPORT_BY_DATE ? auth?.authData?.id : null,
    employee_details: null,
    attendance_date: report == REPORT_BY_DATE ? new Date() : null,
    attendance_month: report != REPORT_BY_DATE ? new Date() : null,
    attendance_time: report == REPORT_BY_TIME ? moment().format() : null,
  });
  // INFO: totalNoOfPages is for card pagination total page count

  const TABLE_HEAD = [];
  TABLE_HEAD.push(
    { id: 'S.No.', label: 'S.No.', alignRight: 'center' },
  );
  if (report == REPORT_BY_DATE) {
    TABLE_HEAD.push(
      { id: 'Talent Name', label: 'Talent Name', alignRight: false }
    );
  } else {
    TABLE_HEAD.push({ id: 'Date', label: 'Date', alignRight: false });
  }

  TABLE_HEAD.push(
    { id: 'Login Time', label: 'Login Time', alignRight: 'center' },
    { id: 'Logout Time', label: 'Logout Time', alignRight: 'center' },
    { id: 'Check-in Time', label: 'Check In Time', alignRight: 'center' },
    { id: 'Check-out Time', label: 'Check Out Time', alignRight: 'center' },
    { id: 'Default Attendance', label: 'Default Attendance', alignRight: 'center' },
    { id: 'Remark', label: 'Remark', alignRight: 'center' },
    { id: 'Modified To', label: 'Modified To', alignRight: 'center' },
    { id: 'Date & Time of Modulation', label: 'Date & Time of Modulation', alignRight: 'center', notCapitalize: true },
  );

  const [page, setPage] = useState(PAGES);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);


  useEffect(() => {
    if (attendance?.empMonthAttLoading == false) {
      dispatch(UserAttendanceReportLog(auth.authtoken, SearchParameters, page, rowsPerPage));
    }
  }, [report, page, rowsPerPage, SearchParameters]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };



  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, DEFAULT_ROWS_PERPAGE));
    setPage(0);
  };

  const resetPage = () => {
    setRowsPerPage(DEFAULT_ROWS_PERPAGE);
    setPage(0);
    setSearchParameters({
      ...SearchParameters,
      start_date: report == REPORT_BY_TIME ? new Date() : null,
      end_date: report == REPORT_BY_TIME ? new Date() : null,
      date: new Date(),
      employee_id: report != REPORT_BY_DATE ? auth?.authData?.id : null,
      employee_details: null,
      attendance_date: report == REPORT_BY_DATE ? new Date() : null,
      attendance_month: report != REPORT_BY_DATE ? new Date() : null,
      attendance_time: report == REPORT_BY_TIME ? new Date() : null,
      sort: report == REPORT_BY_TIME ? false : null,
    });
  };

  const searchParamHandler = (params) => {
    let searchParams = {
      ...SearchParameters,
      ...params,
    }
    if (report == REPORT_BY_DATE) {
      searchParams.attendance_date = searchParams?.date? moment(searchParams?.date).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD');
      searchParams.attendance_month = null;
      searchParams.employee_details = null;
    }
    else if (report == REPORT_BY_TIME) {
      searchParams.attendance_date = null;
      searchParams.attendance_month = null;
      searchParams.employee_details = null;
      searchParams.attendance_time = params?.time;
      searchParams.sort = params?.sort;
      searchParams.start_date =params?.start_date? moment(params?.start_date).format('YYYY-MM-DD'):null;
      searchParams.end_date = params?.end_date? moment(params?.end_date).format('YYYY-MM-DD'):null;
    }
    if (report == REPORT_BY_TALENT) {
      searchParams.attendance_month = moment(searchParams?.date).format('YYYYMM');
      searchParams.attendance_date = null;
    }
    setSearchParameters(searchParams);
    setRowsPerPage(DEFAULT_ROWS_PERPAGE);
    setPage(0);
  };

  const getSelectedDateMonth = (paramDate) => {
    const searchParams = {
      ...SearchParameters,
      date: paramDate,
    }
    if (report == REPORT_BY_DATE) {
      searchParams.attendance_date = moment(paramDate).format('YYYY-MM-DD');
      searchParams.attendance_month = null;
      searchParams.employee_details = null;
    }
    if (report == REPORT_BY_TALENT) {
      searchParams.attendance_month = moment(paramDate).format('YYYYMM');
      searchParams.attendance_date = null
    }
    setSearchParameters(searchParams);
    setRowsPerPage(DEFAULT_ROWS_PERPAGE);
    setPage(0);
  };

  const downloadSearchReport = (format) => {
    let url = '';
    let file_name = '';
    url += ATTENDANCE_LOG_REPORT_DOWNLOAD_URL + `?`;
    file_name += 'Attendance_Log_Report';
    if (format != null) {
      url += `download_format=${format}&`;
    }

    if (report == REPORT_BY_DATE) {
      if (SearchParameters?.attendance_date) {
        url += `attendance_date=${moment(SearchParameters?.attendance_date).format('YYYY-MM-DD')}&report_title=Date Wise Report`;
        DownLoadFile(auth.authtoken, url, file_name);
      }
    }
    else if (report == REPORT_BY_TIME) {
      if (SearchParameters?.attendance_date) {
        url += `attendance_date=${moment(SearchParameters?.attendance_date).format('YYYY-MM-DD')}&report_title=Date Wise Report`;
        DownLoadFile(auth.authtoken, url, file_name);
      }
    }

    if (report == REPORT_BY_TALENT) {
      if (SearchParameters?.attendance_month &&
        SearchParameters?.employee_id
      ) {
        url += `attendance_month=${SearchParameters?.attendance_month}&employee_id=${SearchParameters?.employee_id}`;
        let title = SearchParameters?.employee_details?.name ? `${SearchParameters?.employee_details?.name + '- (' + SearchParameters?.employee_details?.code})`
          : auth?.authData?.fullname + ' - ' + auth?.authData?.employee_code
          ;
        url += `&report_title=${title}`;
        DownLoadFile(auth.authtoken, url, file_name);
      }
    }
  }

  return (
    <Page title="Attendance Log Report">
      <Grid container display="row" justifyContent="space-between">
        <Grid item>
          <EHeaderBreadcrumbs
            heading={report == REPORT_BY_TALENT ? 'By Name' : report == REPORT_BY_TIME ? 'By Time' : 'By Date'}
            links={[
              { name: 'Dashboard', href: '/' },
              { name: 'Report', href: '/report' },
              { name: 'Attendance Log Report', href: '/report/attendance-log-report' },
              { name: report == REPORT_BY_TALENT ? 'By Name' : report == REPORT_BY_TIME ? 'By Time' : 'By Date' }
            ]}
          />
        </Grid>
        <Grid item>
          <EHidden width="mdDown">
            <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} color="secondary">
              Back
            </EButtonOutlined>
          </EHidden>
        </Grid>
      </Grid>


      <Grid container display="row" justifyContent="space-between" className='pb-2rem margin-top-20px'>
        <Grid item xs={12} sm={12} className='display-flex justify-content-space-between'>
          <ETypographyPageHeading variant='h5'>Attendance Log Report - {report == REPORT_BY_TALENT ? 'By Name' : report == REPORT_BY_TIME ? 'By Time' : 'By Date'}</ETypographyPageHeading>
          <EHidden width="mdUp">
            <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
              <EIcon icon={ForwardArrow} className='height-width-22px' /> </EButtonOutlinedIcon>
          </EHidden>
        </Grid>
        <EHidden width="mdDown">
          <Grid lg={12} xs={12} item>
            <EDivider className='margin-top-05' />
          </Grid>
        </EHidden>
      </Grid>

      <EBoxPage>
        <ReportSearchBar
          reportPage={report}
          setSearch={searchParamHandler}
          auth={auth}
          resetPage={resetPage}
          downloadSearchReport={downloadSearchReport}
          company={company}
        />
        {report != REPORT_BY_TIME &&
          <ECardBasic sx={{ mb: 5, padding: '0px' }} className='theme-color-border mb-16px'>

            {report == REPORT_BY_TALENT && <Grid mt={2} >

              <ETypography variant='h6' align='center' >
                {SearchParameters?.employee_id != auth?.authData?.id && SearchParameters?.employee_details?.name ?
                  <>
                    {SearchParameters?.employee_details?.name ? SearchParameters?.employee_details?.name : ''}
                  </>
                  : 'My Attendance'}
              </ETypography>

            </Grid>}
            <DateMonthScroller
              isDate={report == REPORT_BY_TALENT ? false : true}
              {...SearchParameters}
              onClick={getSelectedDateMonth}
              company={company}

            />
          </ECardBasic>
        }
        {attendance?.empMonthAttLoading && Object.keys(attendance?.empMonthAttData)?.length == 0 ?
          <ETableContainer>
            <ETable>
              <ETableBody>
                <ETableRow>
                  <ETableCell align="center" colSpan={10} sx={{ py: 3 }}>
                    <CircularProgress color="primary" />
                  </ETableCell>
                </ETableRow>
              </ETableBody>
            </ETable>
          </ETableContainer>
        :
        !attendance?.empMonthAttLoading && (attendance?.empMonthAttData?.rows?.length == 0 || Object.keys(attendance?.empMonthAttData)?.length == 0) ?
          <SearchNotFound />
          :
          Object.keys(attendance?.empMonthAttData)?.length > 1 && report != REPORT_BY_TIME ?
            <>
              <EScrollbar>
                <ETableContainer>
                  <ETable>
                    <EListHead
                      headLabel={TABLE_HEAD}
                      rowCount={attendance?.empMonthAttData?.rows?.length}
                      onRequestSort={() => null}
                      authvar={auth}
                      Theme={Theme}
                    />
                    {attendance?.empMonthAttLoading && (
                      <ETableBody>
                        <ETableRow>
                          <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <ECircularProgress color="primary" />
                          </ETableCell>
                        </ETableRow>
                      </ETableBody>
                    )}
                    {!attendance?.empMonthAttLoading && attendance?.empMonthAttData?.rows?.length > 0 && (
                      <ETableBody>
                        {attendance?.empMonthAttData?.rows?.map((row, index) => {
                          const {
                            date,
                            fullname,
                            login_logout,
                            check_in_time,
                            check_out_time,
                            checkin_is_mobile,
                            checkout_is_mobile,
                            system_gen_abb,
                            system_remark,
                            modulated_abb,
                            employee_code,
                            updated_at,is_regularized, regularize_remark
                          } = row;



                          const isToday = moment(date).isSame(new Date(), "day");
                          let loginLogoutArray = [];

                          if (login_logout) {
                            loginLogoutArray = login_logout.split(',');
                          }
                          return (
                            <ETableRow hover key={index} tabIndex={-1} >
                              <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px' : ""}>
                                {index + 1}
                              </ETableCell>
                              {report == REPORT_BY_DATE ? (
                                <>
                                {/* Talent Name */}
                                  <ETableCell align="left"
                                    className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px minWidth-150 ' : "min-width-150px max-width-300px"}> {/*table-cells-padding-vertical-align*/}
                                    <ETypography className="dark-grey-color bold-font font-size-14px bold-600">
                                      {fullname}<br/>
                                      <span className="bold-400">
                                      ({employee_code})
                                      </span>
                                    </ETypography>
                                  </ETableCell>
                                </>
                              ) : (
                                <ETableCell align="left" style={{ verticalAlign: 'top' }} className="width-160px pt-16px">
                                  {moment(date).format('DD/MM/YYYY')}
                                </ETableCell>
                              )}

                              {/* Login Time */}
                              <ETableCell className={loginLogoutArray?.length > 1 ? "width-80px width-130px px0 pt8px pb-8px" : 'width-80px px0 '} align="center">
                                {loginLogoutArray?.length > 0 ?
                                  loginLogoutArray?.map((row, index) => {
                                    const arrayIn = row.split("=");
                                   
                                    return (
                                      <Grid
                                        container
                                        flexDirection={'column'}
                                        spacing={1}
                                        key={index + arrayIn[0].replace(/\s/g, "")}
                                      >
                                        <Grid item >
                                          <ETypography className={'align-center height-100per table-font-size '}>
                                          
                                            {arrayIn[0]
                                              ? moment(arrayIn[0]).format('HH:mm')
                                              : '-'}
                                          </ETypography>
                                        </Grid>
                                        {loginLogoutArray.length > index + 1 ? (
                                          <Grid item>
                                            <EDivider className="position-relative borderColor-staticBorder7 mb-8px" />
                                          </Grid>
                                        ) : (
                                          ''
                                        )}
                                      </Grid>
                                    );
                                  }) : "-"}
                              </ETableCell>

                              {/* Logout Time */}
                              <ETableCell className={loginLogoutArray?.length > 1 ? "width-130px px0 pt8px pb-8px" : 'width-130px px0 '} align="center">
                                {loginLogoutArray?.length > 0 ?
                                  loginLogoutArray?.map((row, index) => {
                                    const arrayOut = row.split("=");
                                    return (
                                      <Grid
                                        container
                                        flexDirection={'column'}
                                        spacing={1}
                                        key={index + arrayOut[1]}
                                        className={loginLogoutArray.length <= index + 1 ? '' : 'mb-8px'}
                                      >
                                        <Grid item>
                                          <ETypography className={'align-center width-80px height-100per table-font-size'}>
                                            {arrayOut[1] && arrayOut[1] != '-'
                                              ? moment(arrayOut[1]).format('HH:mm')
                                              : '-'}
                                          </ETypography>
                                        </Grid>
                                        {loginLogoutArray.length > index + 1 ? (
                                          <Grid item>
                                            <EDivider className="position-relative borderColor-staticBorder7 " />
                                          </Grid>
                                        ) : (
                                          ''
                                        )}
                                      </Grid>
                                    );
                                  }) : '-'}
                              </ETableCell>

                              {/* Check in Time */}
                              <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px' : ""}>
                              <ETypographyTable className={'justify-content-center align-center width-80px'+
                                          (is_regularized == 1 && regularize_remark != null ? ' '+' theme-main-text-color-bold' : '')}>
                                  {checkin_is_mobile == false ? (
                                    <ETooltip title={'Checked In from Desktop'} arrow>
                                      <EIcon icon={monitor} className="styled-icon" />
                                    </ETooltip>
                                  ) : checkin_is_mobile ? (
                                    <ETooltip title={'Checked In from Mobile'} arrow>
                                      <EIcon icon={mobile} className="styled-icon" />
                                    </ETooltip>
                                  ) : (
                                    ''
                                  )}
                                  {check_in_time ? moment(check_in_time).format('HH:mm') : '-'}                                
                                </ETypographyTable>
                              </ETableCell>

                                {/* Check out Time */}
                              <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px' : "width-130px  "}>
                                <ETypographyTable className={'width-80px justify-content-center align-center '+
                                          (is_regularized == 1 && regularize_remark != null ? ' '+' theme-main-text-color-bold' : '')}>
                                  {checkout_is_mobile == false ? (
                                    <ETooltip title={'Checked In from Desktop'} arrow>
                                      <EIcon icon={monitor} className="styled-icon" />
                                    </ETooltip>
                                  ) : checkout_is_mobile ? (
                                    <ETooltip title={'Checked Out from Mobile'} arrow>
                                      <EIcon icon={mobile} className="styled-icon" />
                                    </ETooltip>
                                  ) : (
                                    ''
                                  )}
                                  {check_out_time ? moment(check_out_time).format('HH:mm') : '-'}
                                </ETypographyTable>
                              </ETableCell>

                              {/* Default Attendance */}
                              <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px  ' : "width-130px  "}>
                                <ETypographyTable className='justify-content-center align-center'>
                                  {system_gen_abb && !isToday ? <EChip label={system_gen_abb} /> : '-'}</ETypographyTable>
                              </ETableCell>

                              {/* Remark */}
                              <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px  ' : "width-130px  "}>
                                {system_remark ?
                                  <ETooltip title={system_remark} arrow>
                                    <EIcon icon={InfoIcon} />
                                  </ETooltip>
                                  : '-'}
                              </ETableCell>
                              
                              {/* Modified To */}
                              <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px  ' : "width-130px  "}>
                                {modulated_abb && !isToday ? <EChip label={modulated_abb} /> : '-'}
                              </ETableCell>

                              {/* Date & Time of Modulation */}
                              <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-160px' : ""}>
                                <ETypography className='width-160px'>
                                {updated_at ? moment(updated_at).format('DD/MM/YYYY HH:mm') : '-'}
                                </ETypography> 
                              </ETableCell>
                            </ETableRow>
                          );
                        })}
                      </ETableBody>
                    )}

                  </ETable>
                </ETableContainer>
                <ETablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={attendance?.empMonthAttData?.count ? attendance?.empMonthAttData?.count : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </EScrollbar>
            </>
            :
            <ReportsResultsTime attendance={attendance} SearchParameters={SearchParameters}
              setSearchParameters={setSearchParameters}
              page={page} setPage={setPage}

            />
        }
      </EBoxPage>
    </Page>
  );
}
export default ReportsResults;
