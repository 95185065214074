import { Grid, Input, Stack } from "@mui/material";
import { EHelperText } from "../../BaseComponents/ETypography";
import { EInputAdornment, ETextField } from "../../BaseComponents/ETextField";
import { CityList } from "../../action/StateCityAction";
import { useDispatch } from "react-redux";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import { UpdateFreelancerBasicDetailsValidation } from "../../utils/validation";
import { useEffect, useState } from "react";
import { EButtonOutlined, EButtonProfile, ELoadingButton } from "../../BaseComponents/EButtons";
import ImageEditing from "../ImageEditing";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { EAvatarProfile } from "../../BaseComponents/EAvatar";
import { UpdateBasicFreelancerDetailsAction } from "../../action/FreelancerAction";
import { NIL } from "../../constants";

const FreelancerEditModalBasicDetail = (props) => {
    const { auth, freelancerData, closeBasicDetailModal, freelancerLoading } = props;
    const dispatch = useDispatch();
    const [editor, setEditor] = useState("");

    const [picture, setPicture] = useState({
        cropperOpen: false,
        img: null,
        zoom: 1,
        rotate: 0,
        croppedImg: "",
        croppedImgFileObject: '',
        defaultImgFileObject: '',
        photostatus: ''
    });
    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            freelancer_email: freelancerData?.freelancer_email,
            freelancer_contact_no: freelancerData?.freelancer_contact_no,
            freelancer_id: freelancerData?.id,
            updated_by: auth.authData?.id,
            freelancer_photo: null
        },
        validationSchema: UpdateFreelancerBasicDetailsValidation,
        onSubmit: (data) => {
            dispatch(UpdateBasicFreelancerDetailsAction(auth.authtoken, data, "basic-details", null))
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    useEffect(() => {
        if (auth?.authtoken && freelancerData.employeeInfo?.personal_state_id) {
            dispatch(CityList(auth.authtoken, freelancerData.employeeInfo?.personal_state_id))
        }
    }, [auth.authtoken, freelancerData.employeeInfo?.personal_state_id])

    const handleFileChange = (e) => {
        if (e && e.target.files[0]) {
            setFieldValue("freelancer_photo", e.target.files[0])
            setPicture({
                ...picture,
                img: e.target.files[0],
                cropperOpen: true,
                croppedImgFileObject: e.target.files[0],
            });
        }
    };
    return (

        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

                <Grid container alignItems='center' >
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} sx={{ p: 0, textAlign: 'center' }}>
                        {!picture.cropperOpen ? (
                            <label htmlFor="icon-button-file">
                                <div className="mb-16px" >
                                    <Input inputProps={{ accept: 'image/jpg, image/jpeg' }} id="icon-button-file" type="file" style={{ display: 'none', }} onChange={e => handleFileChange(e)} />
                                    <EButtonProfile aria-label="upload picture" component="span" id="ePhoto" >
                                        <EAvatarProfile title={freelancerData?.freelancer_fname} alt={freelancerData?.freelancer_fname}
                                            src={picture.croppedImg ? picture.croppedImg : freelancerData?.freelancer_photo ? freelancerData?.freelancer_photo : ''} />
                                    </EButtonProfile>
                                </div>
                            </label>
                        ) : (
                            <ImageEditing picture={picture} setPicture={setPicture} editor={editor} setEditor={setEditor} />
                        )}

                        <EHelperText>*jpeg or *jpg format only </EHelperText>
                        <EHelperText style={{ color: 'red' }}> {errors.freelancer_photo ? errors.freelancer_photo : ''} </EHelperText>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={8} xl={6} >
                    <Grid container spacing={2}>
                        <Grid item  xs={12} >
                            <ELabelInputMainGrid label={'Name'} isNotForm={true}>
                            {freelancerData?.freelancer_name ? freelancerData?.freelancer_name : NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item xs={12} >
                            <ELabelInputMainGrid label={'Email ID'}>
                                <ETextField
                                    fullWidth
                                    autoComplete="freelancer_email"
                                    {...getFieldProps('freelancer_email')}
                                    error={Boolean(touched.freelancer_email && errors.freelancer_email)}
                                    helperText={touched.freelancer_email && errors.freelancer_email}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item  xs={12}>
                            <ELabelInputMainGrid label={'Contact Number'}>
                                <ETextField
                                    fullWidth
                                    autoComplete="freelancer_contact_no"
                                    {...getFieldProps('freelancer_contact_no')}
                                    error={Boolean(touched.freelancer_contact_no && errors.freelancer_contact_no)}
                                    helperText={touched.freelancer_contact_no && errors.freelancer_contact_no}
                                    InputProps={{
                                        startAdornment: (<EInputAdornment position="start"><span className="color-text-primary">+91</span></EInputAdornment>),
                                    }}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                    </Grid>
                </Grid> 
                    </Grid>
                    <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end ' className='modal1-buttons-stick-bottom'>
                        <EButtonOutlined size="large" variant="outlined" onClick={() => closeBasicDetailModal()}
                        > Cancel </EButtonOutlined>
                        <ELoadingButton
                            loading={freelancerLoading}
                            size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                    </Stack>
            </Form>
        </FormikProvider>

    )

};

export default FreelancerEditModalBasicDetail;