import React, { useEffect, useMemo } from 'react'
import { Grid, Stack, useTheme } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IMaskInput, IMask } from 'react-imask';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment';

import { EInputAdornment, ETextFieldSmall } from '../../../../BaseComponents/ETextField';
import { EButtonOutlined, EIconButton, ELoadingButton } from '../../../../BaseComponents/EButtons';
import { ETypographyPageHeading } from '../../../../BaseComponents/ETypography';
import { ShiftInput } from './ShiftInput';
import { EDivider } from '../../../../BaseComponents/EDivider';
import { OFFICE_HOURS_TYPE } from '../../../../constants';
import { formatTime } from '../../../../utils/CommonFunctions';


const initialTempShift = {
  id: "",
  shift_name: "",
  check_in_before: "",
  check_in_time: "",
  check_out_time: "",
  buffer_time: "",
  min_full_day_hour: "",
  min_half_day_hour: "",
  is_default_shift: false,
}


export const ShiftForm = ({ formik, showForm, setShowForm, showTable, setShowTable }) => {

  const { values, errors, touched, getFieldProps, setFieldValue, setFieldError, setTouched } = formik;

  const theme = useTheme()
  // eslint-disable-next-line
  const editMode = useMemo(() => values?.tempShift?.id && values?.tempShift?.id !== "", [showForm])
  // eslint-disable-next-line
  const shiftsLength = useMemo(() => values?.shifts?.filter(i => i.destroyed !== true)?.length, [showForm])

  useEffect(() => {
    const is_default_shift = values?.tempShift?.is_default_shift || !(shiftsLength > 0)
    setFieldValue("tempShift.is_default_shift", is_default_shift);
    setFieldValue("tempShift.editMode", editMode);
    // eslint-disable-next-line
  }, [showForm])


  const checkValidation = async () => {
    let _touched = {
      shift_name: true,
      check_in_before: true,
      min_full_day_hour: true,
      min_half_day_hour: true,
    }
    if (values?.office_hours_type === OFFICE_HOURS_TYPE.TIME_BOUND) {
      _touched = {
        shift_name: true,
        check_in_time: true,
        check_out_time: true,
        buffer_time: true,
        min_half_day_hour: true,
      }
    }
    const _errors = await setTouched({ tempShift: _touched })
    return Object.keys(_errors?.tempShift || {}).length > 0
  }

  /**
   * Creates a new shift object with default values if necessary.
   * @param {object} tempShift - An object containing the temporary shift values.
   * @returns {object} A new or updated shift object.
   */
  const createNewShift = (tempShift) => {
    const is_default_shift = tempShift.is_default_shift || tempShift.shift_name === "";
    const isNew = tempShift?.new
    delete tempShift.editMode
    return {
      ...tempShift,
      id: tempShift.id || Date.now(),
      shift_name: tempShift.shift_name || "Default Shift",
      check_in_before: tempShift.check_in_before ? moment(tempShift.check_in_before).format('HH:mm') : "",
      check_in_time: tempShift.check_in_time ? moment(tempShift.check_in_time).format('HH:mm') : "",
      check_out_time: tempShift.check_out_time ? moment(tempShift.check_out_time).format('HH:mm') : "",
      is_default_shift,
      new: isNew,
    };
  };

  /**
   * Updates an existing shift or adds a new one to the array.
   * @param {array} shifts - An array of existing shifts.
   * @param {object} newShift - A new or updated shift object.
   * @returns {array} An array of updated shifts.
   */
  const updateShift = (shifts, newShift) => {
    const updatingShiftIndex = shifts.findIndex((shift) => shift.id === newShift.id)
    if (updatingShiftIndex === -1) {
      // If the shift is not found, add the new shift to the array
      return [...shifts, newShift]
    } else {
      // If the shift is found, update the shift at the given index
      return shifts.map((shift, index) => (index === updatingShiftIndex ? newShift : shift))
    }
  };

  /**
   * Handles the submission of the form for creating a new shift.
   */
  const handleShiftAdd = async () => {
    const isValidate = checkValidation()
    if (await isValidate) return
    const clonedShifts = cloneDeep(values?.shifts)
    const _shifts = [...clonedShifts] || []
    // Create a new shift object from the tempShift values
    const newShift = createNewShift(values?.tempShift || {})
    // Update the array of shifts with the new or updated shift object
    const updatedShifts = updateShift(_shifts, newShift)
    // Update the form field values and hide the form
    setFieldValue("shifts", updatedShifts)
    resetForm()
  };

  /**
   * Handles form reset and close the forms
   */
  const resetForm = () => {
    setFieldValue("tempShift", initialTempShift)
    setShowForm(false)
    setShowTable(true)
    let _touched = {
      shift_name: false,
      check_in_before: false,
      min_full_day_hour: false,
      min_half_day_hour: false,
    }
    if (values?.office_hours_type === OFFICE_HOURS_TYPE.TIME_BOUND) {
      _touched = {
        shift_name: false,
        check_in_time: false,
        check_out_time: false,
        buffer_time: false,
        min_half_day_hour: false,
      }
    }
    setTouched({ tempShift: _touched })
  }

  return (
    <Grid container mt={1}>
      {
        (!!editMode || !values?.tempShift?.is_default_shift) && showTable
        &&
        <>
          <Grid item xs={12}>
            <ETypographyPageHeading variant='h6' sx={{ fontSize: { lg: 16, xs: 14 } }} >
              {`${editMode ? 'Update' : 'Add'} Shift Details`}
            </ETypographyPageHeading>
            <EDivider className='mt-8px' />
          </Grid>
          <Grid item xs={12}>
            <ShiftInput
              title='Shift Name'
            >
              <ETextFieldSmall
                id="WorkingHours"
                fullWidth
                size='small'
                className="w-lg35per-md50per"
                name="tempShift.shift_name"
                placeholder='Shift Name'
                inputProps={{
                  'formik': { setFieldValue },
                }}
                disabled={values?.tempShift?.is_default_shift}
                {...getFieldProps('tempShift.shift_name')}
                error={Boolean(touched.tempShift?.shift_name && errors.tempShift?.shift_name)}
                helperText={touched.tempShift?.shift_name && errors.tempShift?.shift_name}
              />
            </ShiftInput>
          </Grid>
        </>
      }
      {
        values?.office_hours_type === OFFICE_HOURS_TYPE.HOURLY
          ?
          <>
            <Grid item xs={12}>
              <ShiftInput
                title='Check-in Before'
              >
                <MobileTimePicker
                  fullWidth
                  className="w-lg35per-md50per"
                  inputFormat="hh:mm a"
                  value={getFieldProps('tempShift.check_in_before')?.value ? formatTime(getFieldProps('tempShift.check_in_before')?.value, true) : ""}
                  onChange={(value) => setFieldValue('tempShift.check_in_before', value)}
                  inputProps={{ readOnly: true }}
                  disabled={values?.is_default_setting}
                  renderInput={(params) => (
                    <ETextFieldSmall
                      {...params}
                      fullWidth
                      size='small'
                      placeholder="Check-in Before"
                      InputProps={{
                        endAdornment: (
                          <EInputAdornment position="end">
                            <EIconButton
                              edge="end">
                              <AccessTimeIcon sx={{ color: theme.palette.input.Disabled }} />
                            </EIconButton>
                          </EInputAdornment>
                        )
                      }}
                      error={Boolean(touched.tempShift?.check_in_before && errors.tempShift?.check_in_before)}
                      helperText={touched.tempShift?.check_in_before && errors.tempShift?.check_in_before}
                      disabled={values?.is_default_setting}
                    />
                  )}
                />
              </ShiftInput>
            </Grid>
            <Grid item xs={12}>
              <ShiftInput
                title='Minimum Hours (Full Day)'
                subTitle='Talent has to work for minimum working hours for full day to be marked present for the day.'
              >
                <ETextFieldSmall
                  id="WorkingHours"
                  fullWidth
                  size='small'
                  className="w-lg35per-md50per"
                  inputProps={{
                    'formik': { setFieldValue }
                  }}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    endAdornment: <EInputAdornment position="end" sx={{ '& p': { color: values?.is_default_setting ? theme.palette.input.Disabled2 : theme.palette.primary.text } }}>hrs</EInputAdornment>
                  }}
                  {...getFieldProps('tempShift.min_full_day_hour')}
                  value={getFieldProps('tempShift.min_full_day_hour')?.value?.replace('.', ':')}
                  onChange={(event) => setFieldValue('tempShift.min_full_day_hour', event.target.value?.replace(':', '.'))}
                  error={Boolean(touched.tempShift?.min_full_day_hour && errors.tempShift?.min_full_day_hour)}
                  helperText={touched.tempShift?.min_full_day_hour && errors.tempShift?.min_full_day_hour}
                  disabled={values?.is_default_setting}
                />
              </ShiftInput>
            </Grid>
          </>
          :
          <>
            <Grid item xs={12}>
              <ShiftInput
                title='Check-in Time'
              >
                <MobileTimePicker
                  fullWidth
                  className="w-lg35per-md50per"
                  inputFormat="hh:mm a"
                  {...getFieldProps('tempShift.check_in_time')}
                  value={getFieldProps('tempShift.check_in_time')?.value || ""}
                  onChange={(value) => setFieldValue('tempShift.check_in_time', value)}
                  inputProps={{ readOnly: true }}
                  renderInput={(params) => (
                    <ETextFieldSmall
                      {...params}
                      fullWidth
                      size='small'
                      placeholder="Select Start Time"
                      InputProps={{
                        endAdornment: (
                          <EInputAdornment position="end">
                            <EIconButton
                              edge="end">
                              <AccessTimeIcon />
                            </EIconButton>
                          </EInputAdornment>
                        )
                      }}
                      error={Boolean(touched.tempShift?.check_in_time && errors.tempShift?.check_in_time)}
                      helperText={touched.tempShift?.check_in_time && errors.tempShift?.check_in_time}
                      disabled={values?.is_default_setting}
                    />
                  )}
                />
              </ShiftInput>
            </Grid>
            <Grid item xs={12}>
              <ShiftInput
                title='Check-out Time'
              >
                <MobileTimePicker
                  fullWidth
                  className="w-lg35per-md50per"
                  inputFormat="hh:mm a"
                  {...getFieldProps('tempShift.check_out_time')}
                  onChange={(value) => setFieldValue('tempShift.check_out_time', value)}
                  value={getFieldProps('tempShift.check_out_time')?.value || ""}
                  inputProps={{ readOnly: true }}
                  renderInput={(params) => (
                    <ETextFieldSmall
                      {...params}
                      fullWidth
                      size='small'
                      placeholder="Select Start Time"
                      InputProps={{
                        endAdornment: (
                          <EInputAdornment position="end">
                            <EIconButton
                              edge="end">
                              <AccessTimeIcon />
                            </EIconButton>
                          </EInputAdornment>
                        )
                      }}
                      error={Boolean(touched.tempShift?.check_out_time && errors.tempShift?.check_out_time)}
                      helperText={touched.tempShift?.check_out_time && errors.tempShift?.check_out_time}
                      disabled={values?.is_default_setting}
                    />
                  )}
                />
              </ShiftInput>
            </Grid>
            <Grid item xs={12}>
              <ShiftInput
                title='Buffer Time'
                subTitle='The leverage of few minutes given to the talent between check-in & check-out time, to fulfil the criteria for minimum working hours.'
              >
                <ETextFieldSmall
                  id="WorkingHours"
                  fullWidth
                  size='small'
                  className="w-lg35per-md50per"
                  placeholder='Buffer Time'
                  inputProps={{
                    'formik': { setFieldValue }
                  }}
                  InputProps={{
                    endAdornment: <EInputAdornment position="end" sx={{ '& p': { color: values?.is_default_setting ? theme.palette.input.Disabled2 : theme.palette.primary.text } }}>mins</EInputAdornment>
                  }}
                  {...getFieldProps('tempShift.buffer_time')}
                  error={Boolean(touched.tempShift?.buffer_time && errors.tempShift?.buffer_time)}
                  helperText={touched.tempShift?.buffer_time && errors.tempShift?.buffer_time}
                  disabled={values?.is_default_setting}
                />
              </ShiftInput>
            </Grid>
          </>
      }
      <Grid item xs={12}>
        <ShiftInput
          title='Minimum Hours (Half Day)'
          subTitle='Talent has to work for minimum working hours for half day to be marked present for the half day.'
        >
          <ETextFieldSmall
            id="MinHourForPresent"
            fullWidth
            size='small'
            className="w-lg35per-md50per"
            inputProps={{
              'formik': { setFieldValue }
            }}
            InputProps={{
              inputComponent: TextMaskCustom,
              endAdornment: <EInputAdornment position="end" sx={{ '& p': { color: values?.is_default_setting ? theme.palette.input.Disabled2 : theme.palette.primary.text } }}>hrs</EInputAdornment>
            }}
            {...getFieldProps('tempShift.min_half_day_hour')}
            value={getFieldProps('tempShift.min_half_day_hour')?.value?.replace('.', ':')}
            onChange={(event) => setFieldValue('tempShift.min_half_day_hour', event.target.value?.replace(':', '.'))}
            error={Boolean(touched.tempShift?.min_half_day_hour && errors.tempShift?.min_half_day_hour)}
            helperText={touched.tempShift?.min_half_day_hour && errors.tempShift?.min_half_day_hour}
            disabled={values?.is_default_setting}
          />
        </ShiftInput>
      </Grid>
      {
        !values?.is_default_setting
        &&
        <Grid item xs={12}>
          <Stack direction='row' spacing={2} justifyContent='flex-end'>
            <EButtonOutlined
              size="large"
              variant="outlined"
              disabled={shiftsLength === 0 || !showTable}
              onClick={resetForm}
            >
              Cancel
            </EButtonOutlined>
            <ELoadingButton
              size="large"
              variant="contained"
              type="button"
              onClick={handleShiftAdd}
            >
              {`${editMode && showTable ? 'Update' : 'Add'}`}
            </ELoadingButton>
          </Stack>
        </Grid>
      }
    </Grid>
  )
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="H:M"
      autofix={true}
      blocks={{
        H: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 24,
          maxLength: 2
        },
        M: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
          maxLength: 2
        }
      }}
      lazy={false} // make placeholder always visible
      placeholderChar="0" // defaults to '_'
      inputRef={ref}
      onAccept={(value) => {
        props.formik.setFieldValue(props.name, value);
        onChange({ target: { name: props.name, value } })
      }}
      overwrite
    />
  );
});
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired
};

