import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep'
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { ETypography, } from '../../BaseComponents/ETypography';
import { ECardBasic, ECardDashboard } from '../../BaseComponents/ECard';
import { GET_ATTENDANCE_SETTING_API, PERMISSIONS, SETTING_ATTENDANCE, SET_ATTENDANCE_SETTING_API } from '../../constants';
import { EBoxPage } from '../../BaseComponents/EBox';
import { Form, FormikProvider, useFormik } from 'formik';
import { AttendanceSettingValidation } from '../../utils/validation';
import { Stack } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ECollapsibleGrid, ECustomizedRadioWithTitle, EPageWithBreadCrumbs } from '../../BaseComponents';
import { AttendanceRegularizationSettings, IPSettings, WeeklyOffSettings } from '../../PageComponents/Settings/Attendance';
import { OfficeHoursSettings } from '../../PageComponents/Settings/Attendance/OfficeHoursSettings';
import EModal from '../../BaseComponents/EModal';
import { useBlocker } from '../../hooks/useBlocker';
import ESwitch from '../../BaseComponents/ESwitch';
import { formatTime } from '../../utils/CommonFunctions';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { SetCompanySetting } from '../../action/SettingAction';
import { useNavigate } from 'react-router-dom';
import { useCheckPermission } from '../../hooks';

export const OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
]

function AttendanceSettings({ auth }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  /**
   * fetch the company setting from the useCompanySettingProvider
   */
  const { setting } = useCompanySettingProvider()

  const attendanceSettingData = setting?.attendanceSettingData ? setting?.attendanceSettingData : {}
  const settingLoading = setting?.settingLoading ?? false
  const company = useSelector(state => state.CompanyReducer)

  /**
   * Setting the form initial values
   */
  const initialValues = useMemo(() => {
    let value = { ...attendanceSettingData.companySetting }
    if (!settingLoading && Object.keys(attendanceSettingData || {}).length > 0) {
      const data = attendanceSettingData.companySetting
      let default_day_name = ""
      if (data?.default_weekly_off_day !== "") {
        default_day_name = data?.default_weekly_off_day
        value.default_day_name = default_day_name
      }
      let weeklyOffDays = [];
      let weekly_off_object = data?.weekly_off_object;

      for (const key in weekly_off_object) {
        for (const inData of weekly_off_object[key]) {
          if (inData.is_check === 1 && inData.day_name !== default_day_name) {
            weeklyOffDays.push({
              id: inData.id,
              day_name: inData.day_name,
              week_no: inData.week_no,
              is_check: inData.is_check,
            })
          }
        }
      }
      value.tempWeeklyOff = weeklyOffDays
      value.showForm = true
      value.tempShift = {
        new: true,
        id: "",
        shift_name: "",
        check_in_before: "",
        check_in_time: "",
        check_out_time: "",
        buffer_time: "",
        min_full_day_hour: "",
        min_half_day_hour: "",
        is_default_shift: true,
      }
      if (data.allow_attendance_automate) {
        const isNew = data?.shifts?.filter(i => i.destroyed !== true)?.length === 0
        value.showForm = isNew
        value.showTable = false
        const _tempShift = cloneDeep(data?.shifts[0])
        if (data.is_default_setting) {
          value.tempShift = {
            ..._tempShift,
            check_in_before: formatTime(_tempShift.check_in_before, true),
            check_in_time: formatTime(_tempShift.check_in_time, true),
            check_out_time: formatTime(_tempShift.check_out_time, true),
          }
        } else {
          value.showTable = !isNew
          value.tempShift = {
            ...value.tempShift,
            new: isNew,

            id: "",
            shift_name: "",
            check_in_before: "",
            check_in_time: "",
            check_out_time: "",
            buffer_time: "",
            min_full_day_hour: "",
            min_half_day_hour: "",
            is_default_shift: isNew,
          }
        }
      }
    }
    return value
  }, [attendanceSettingData, settingLoading])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AttendanceSettingValidation,
    onSubmit: (data) => {
      const payload = cloneDeep(data)
      delete payload.tempShift
      delete payload.company_id
      delete payload.created_at
      delete payload.created_by
      delete payload.updated_at
      delete payload.updated_by
      delete payload.weekly_off
      delete payload.default_day_name
      delete payload.tempWeeklyOff
      delete payload.showForm
      delete payload.showTable
      payload.attendance_regularization_required = !!payload.attendance_regularization_required
      payload.attendance_regularization_request = !!payload.attendance_regularization_request
      dispatch(SetCompanySetting(auth.authtoken, payload, SET_ATTENDANCE_SETTING_API, GET_ATTENDANCE_SETTING_API, SETTING_ATTENDANCE, navigate));
    }
  })

  const { values, errors, setFieldValue, handleSubmit, dirty } = formik

  /**
   * useBlocker hook is used here to prevent the route change when the form is dirty (i.e. any value is changed in the form)
   */
  const [showModal, setShowModal, continueNavigate] = useBlocker(dirty && !values.is_default_setting)

  const allowEdit = useCheckPermission([PERMISSIONS.ViewEditAttendanceSettings])
  const disableFields = useMemo(() => (!allowEdit || values?.is_default_setting), [allowEdit, values?.is_default_setting])
  /**
   * Unsaved form value confirmation modal save button action
   */
  const confirmSave = () => {
    setShowModal(false)
    handleSubmit()
  }

  /**
   * handle default/custom setting checkbox changes
   * @param {Boolean} value checkbox value
   */
  const handleDefaultSetting = (value) => {
    if (value) {
      const data = { ...attendanceSettingData?.companyDefaultSetting, is_default_setting: true }
      delete data.tempShift
      dispatch(SetCompanySetting(auth.authtoken, data, SET_ATTENDANCE_SETTING_API, GET_ATTENDANCE_SETTING_API, SETTING_ATTENDANCE, navigate));
      return
    }
    setFieldValue("showForm", true)
    const _tempShift = cloneDeep(values?.shifts[0])
    setFieldValue('tempShift', {
      ..._tempShift,
      check_in_before: formatTime(_tempShift.check_in_before, true),
      check_in_time: formatTime(_tempShift.check_in_time, true),
      check_out_time: formatTime(_tempShift.check_out_time, true),
    })
  }

  return (
    <EPageWithBreadCrumbs
      title="Attendance Settings"
      pageHeading="Attendance Settings"
      breadcrumbsTitle="Attendance Settings"
      breadcrumbsLinks={[
        { name: 'Dashboard', href: '/' },
        { name: 'Company Settings', href: '' },
        { name: 'Attendance Settings' },
      ]}
      loading={settingLoading}
      hideDivider={true}
      headingRightComponent={
        <>
        {/* If onBoarding is completed, show switch, else don't */}
        {
          company?.companyData?.onBoarding?.onboarding_is_completed 
          ?
            settingLoading ?
            null
            :
            <ESwitch
              check={values?.is_default_setting}
              handleToggle={() => {
                setFieldValue('is_default_setting', !values?.is_default_setting);
                handleDefaultSetting(!values?.is_default_setting);
              }}
              label={values?.is_default_setting === true ? "Default" : 'Custom'}
              disabled={!allowEdit}
            />
          :
          null
        }

          
        </>
      }
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <EBoxPage sx={{ padding: '16px 0 !important' }}>
              <Grid container spacing={3}>
                {/* Attendance Setting */}
                <Grid item xs={12}>
                  <ECollapsibleGrid
                    label='Automate your attendance'
                    labelClassName="font-size-18px"
                    extraLabel="By enabling this setting, you allow talents to mark their attendance on Entera Payroll through an automated process. If not, HR/Admin/Super Admin can mark attendance for all the talents manually."
                    options={OPTIONS}
                    value={values?.allow_attendance_automate}
                    expanded={values?.allow_attendance_automate}
                    name="allow_attendance_automate"
                    onChange={(value) => setFieldValue('allow_attendance_automate', value)}
                    error={errors?.allow_attendance_automate || ""}
                    disabled={disableFields}
                  >
                    <Grid item xs={12} className='pt-16px'>
                      <ECardDashboard className={'Bg-card6 border-none shadow-none'} sx={{ padding: { lg: '18px 24px !important', md: '18px 24px !important' } }}>
                        <Grid container rowSpacing={4.5}>
                          <Grid item>
                            <IPSettings formik={formik} disableFields={disableFields} />
                          </Grid>
                          <Grid item>
                            <OfficeHoursSettings formik={formik} showForm={values.showForm} setShowForm={(value) => setFieldValue('showForm', value)} showTable={values.showTable} setShowTable={(value) => setFieldValue('showTable', value)} disableFields={disableFields} />
                          </Grid>
                          <Grid item>
                            <AttendanceRegularizationSettings formik={formik} disableFields={disableFields} />
                          </Grid>
                        </Grid>
                      </ECardDashboard>
                    </Grid>
                  </ECollapsibleGrid>
                </Grid>

                {/* Weekly Off Setting */}
                <Grid item xs={12}>
                  <ECollapsibleGrid
                    label='Weekly Off'
                    labelClassName="font-size-18px"
                    extraLabel="The setting will allow you to select the company-wide day off on weekly basis."
                    options={OPTIONS}
                    value={values?.enable_weekly_off}
                    expanded={values?.enable_weekly_off}
                    name="enable_weekly_off"
                    onChange={(value) => setFieldValue('enable_weekly_off', value)}
                    error={errors?.enable_weekly_off || ""}
                    disabled={disableFields}
                  >
                    <Grid item xs={12} className='pt-16px'>
                      <ECardDashboard className={'Bg-card6 border-none shadow-none'} sx={{ padding: { lg: '18px 24px !important', md: '18px 24px !important' } }}>
                        <Grid container rowSpacing={4.5}>
                          <Grid item xs={12} >
                            <WeeklyOffSettings formik={formik} disableFields={disableFields} />
                          </Grid>
                        </Grid>
                      </ECardDashboard>
                    </Grid>
                  </ECollapsibleGrid>
                </Grid>

                {/* Payslip Setting */}
                <Grid item xs={12}>
                  <ECardBasic className='mb-0px border-05px-border6 '>
                    <ECustomizedRadioWithTitle
                      label='Show Attendance on Payslip'
                      labelClassName="font-size-18px"
                      extraLabel="Do you wish to include the information of working days and non-working days on Payslip?"
                      options={OPTIONS}
                      value={values?.show_attendance_on_payslip}
                      name="show_attendance_on_payslip"
                      onChange={(value) => setFieldValue('show_attendance_on_payslip', value)}
                      error={errors?.show_attendance_on_payslip || ""}
                      disabled={disableFields}
                    />
                  </ECardBasic>
                </Grid>

                {/* Save Button */}
                {
                  (allowEdit && !values?.is_default_setting)
                  &&
                  <Grid item xs={12}>
                    <Stack direction="row" className='justify-content-flex-end mt-16px'>
                      <ELoadingButton type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'>
                      { company?.companyData?.onBoarding?.onboarding_is_completed ? "Save" : "Save & Next"}
                      </ELoadingButton>
                    </Stack>
                  </Grid>
                }
              </Grid>
            </EBoxPage>

            {/* Unsaved form value before change the route Confirmation Modal */}
            <EModal
              open={showModal}
              headervalue={"Confirmation"}
              parentClassname='delete-confirmation-modal'
            >
              <Grid container rowSpacing={2}>
                <Grid item>
                  <ETypography className="font-size-18px ">
                    Any unsaved changes will be lost. Would you like to save before leaving the page?
                  </ETypography>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                  <EButtonOutlined size="large" variant="outlined" onClick={continueNavigate}>Discard</EButtonOutlined>
                  <ELoadingButton size="large" type="button" variant="contained" onClick={confirmSave} className='button-left-margin'> Save</ELoadingButton>
                </Grid>
              </Grid>
            </EModal>
          </Form>
        </FormikProvider>
      </LocalizationProvider>
    </EPageWithBreadCrumbs>
  )
}
export default AttendanceSettings
