import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { EFieldLabelBold } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid } from "@mui/material";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { directPayValidation } from "../../utils/validation";
import { useDispatch } from "react-redux";
import { HRAccountantPaymentAction } from "../../action/PaymentAction";
import { PAYMENT_CATEGORY_OTHER, PAYMENT_MODE } from "../../constants";



const PaymentDirectPayment = (props) => {
    const { selectedData, setModalState, auth, getPaymentFilterData, employeeList } = props;
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            remark: '',
            payment_mode: PAYMENT_MODE[0],
            payment_detail: '',
            talent: null,
            amount: '',
            is_extra_payment: true,
        },
        validationSchema: directPayValidation,
        onSubmit: (data) => {
            data = { ...data, created_by: auth?.authData?.id, transaction_category: PAYMENT_CATEGORY_OTHER }
            dispatch(HRAccountantPaymentAction(auth?.authtoken, data, getPaymentFilterData, 'direct-payment'));

            // data.payment_medium = data.payment_medium.id;
            // data = { ...data, payment_id: selectedData.modalData?.id };
            // dispatch(HRAccountantPaymentAction(auth?.authtoken, data, getPaymentFilterData, 'final-action'));
            setModalState({ modalData: null, modalCategory: null })
        }
    });
    
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabelBold>Talent Name </EFieldLabelBold>
                            <EAutocomplete
                                name="Talent Name"
                                fullWidth
                                {...getFieldProps('talent')} 
                                options={
                                    employeeList.employeeData && employeeList.employeeData?.count > 0 ? employeeList.employeeData?.rows : []
                                }
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(e, value) => {
                                    setFieldValue('talent', value ? value : null);
                                }}

                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                      {option.label}
                                    </li>
                                  )}

                                renderInput={(params) => (
                                    <ETextField
                                        {...params}
                                        name="talent"
                                        placeholder="Select Talent"
                                        error={Boolean(touched.talent && errors.talent)}
                                        helperText={touched.talent && errors.talent}
                                    />
                                )}
                            />
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabelBold>Amount</EFieldLabelBold>
                            <ETextField
                                fullWidth
                                placeholder="Amount"
                                autoComplete="amount"
                                {...getFieldProps('amount')}
                                error={Boolean(touched.amount && errors.amount)}
                                helperText={touched.amount && errors.amount}
                            />
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabelBold>Payment Mode</EFieldLabelBold>
                            <EAutocomplete
                                name="payment_mode"
                                fullWidth
                                {...getFieldProps('payment_mode')}
                                options={PAYMENT_MODE}
                                onChange={(e, value) => {
                                    setFieldValue('payment_mode', value ? value : null);
                                }}
                                renderInput={(params) => (
                                    <ETextField
                                        {...params}
                                        name="payment_mode"
                                        placeholder="Payment Mode"
                                        fullWidth
                                        error={Boolean(touched.payment_mode && errors.payment_mode)}
                                        helperText={touched.payment_mode && errors.payment_mode}
                                    />
                                )}
                            />
                        </FieldContainerCustom>
                    </Grid>


                    {getFieldProps('payment_mode').value && getFieldProps('payment_mode').value?.id == 'CHEQUE' &&
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={50}
                            >
                                <EFieldLabelBold>Cheque Number</EFieldLabelBold>
                                <ETextField
                                    name='payment_detail'
                                    {...getFieldProps('payment_detail')}
                                    rows={3}
                                    fullWidth
                                    error={Boolean(touched.payment_detail && errors.payment_detail)}
                                    helperText={touched.payment_detail && errors.payment_detail}
                                />
                            </FieldContainerCustom>
                        </Grid>}
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabelBold>Remark</EFieldLabelBold>
                            <ETextField
                                name='remark'
                                placeholder="Remark"
                                multiline
                                rows={3}
                                {...getFieldProps('remark')}
                                fullWidth
                                error={Boolean(touched.remark && errors.remark)}
                                helperText={touched.remark && errors.remark}
                            />
                        </FieldContainerCustom>
                    </Grid>
                </Grid>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalCategory: null })}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton
                        size="large" variant="outlined" type="submit" >Pay</ELoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default PaymentDirectPayment