import { Stack } from "@mui/system";
import { EButtonOutlined } from "../../BaseComponents/EButtons";
import { CircularProgress, Grid } from "@mui/material";
import { TalentTransactionList } from "../../action/PaymentAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { numberWithCommas } from "../../utils/formatNumber";
import { EFieldLabelBold } from "../../BaseComponents/ETypography";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import SearchNotFound from "../SearchNotFound";
import moment from "moment";
import { ETooltip } from "../../BaseComponents/ETooltip";
import info from '@iconify/icons-material-symbols/info-outline'
import { EIcon } from "../../BaseComponents/EIcon";


const PaymentTransactionList = (props) => {

    const { paymentData, setSalaryDueModal, auth, getPaymentFilterData } = props;
    const paymentDetailedList = useSelector((state) => state.PaymentReducer);
    const dispatch = useDispatch();

    const payAmount = Number(paymentData?.pay_amount) ? Number(paymentData?.pay_amount) : 0;
    const paidSalary = Number(paymentData?.transaction?.collect_amount) ? Number(paymentData?.transaction?.collect_amount) : 0;
    const balanceAmount = payAmount - paidSalary

    useEffect(() => {
        const data = {
            employee_id: paymentData.employee_id,
            payroll_id: paymentData.payroll_id,
            transaction_id: paymentData.id,
            created_by: auth?.authData?.id
        };

        dispatch(TalentTransactionList(auth?.authtoken, data));
    }, [auth?.authtoken]);

    return (
        <>
            <Grid container display="row" justifyContent="space-between">
                <EFieldLabelBold className="theme-main-text-color-bold">Payable Salary: {payAmount ? numberWithCommas(payAmount) : '-'}</EFieldLabelBold>
                <EFieldLabelBold className="theme-main-text-color-bold">Balance Amount: {balanceAmount || balanceAmount == 0 ? numberWithCommas(balanceAmount) : '-'} </EFieldLabelBold>
            </Grid>
            <Grid container spacing={2} my={1} >
                <Grid item xs={12} sm={12} lg={12} xl={12}>


                    <ETableContainer>
                        <ETable>
                            <EListHead
                                headLabel={TABLE_HEAD}
                                rowCount={paymentDetailedList?.PayModalListData?.length}
                                onRequestSort={() => null}
                            />
                            {!paymentDetailedList?.PayModalListLoading &&
                                paymentDetailedList?.PayModalListData?.rows?.length > 0 ? (
                                <ETableBody>
                                    {paymentDetailedList?.PayModalListData?.rows?.map((row, index) => {
                                        const { created_at, payment_medium, payment_medium_ref, remark, collect_amount } =
                                            row;
                                        return (
                                            <ETableRow hover key={index} tabIndex={-1}>
                                                <ETableCell align="left" sx={{ width: '10px' }}>
                                                    {index + 1}
                                                </ETableCell>
                                                <ETableCell align="left" >
                                                    {created_at ? moment(created_at).format('DD/MM/yyyy') : '-'}
                                                </ETableCell>
                                                <ETableCell align="left" >
                                                    {payment_medium ? payment_medium : '-'}
                                                </ETableCell>
                                                <ETableCell align="right" sx={{ minWidth: '100px' }}>
                                                    {numberWithCommas(collect_amount)}
                                                </ETableCell>
                                                <ETableCell align="left">
                                                    {remark && (
                                                        <ETooltip title={remark} arrow>
                                                            <EIcon icon={info} />
                                                        </ETooltip>
                                                    )}
                                                </ETableCell>
                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            ) : (
                                <>
                                    {paymentDetailedList?.PayModalListLoading ? (
                                        <ETableBody>
                                            <ETableRow>
                                                <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                    <CircularProgress color="primary" />
                                                </ETableCell>
                                            </ETableRow>
                                        </ETableBody>
                                    ) : (
                                        <>
                                            <ETableBody>
                                                <ETableRow>
                                                    <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                        <SearchNotFound />
                                                    </ETableCell>
                                                </ETableRow>
                                            </ETableBody>
                                        </>
                                    )}
                                </>
                            )}
                        </ETable>
                    </ETableContainer>
                </Grid>
            </Grid>
            <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                <EButtonOutlined size="large" variant="outlined"
                    onClick={() => setSalaryDueModal({ modalData: null, modalCategory: null })}
                > Close </EButtonOutlined>

            </Stack>
        </>
    );
};
const TABLE_HEAD = [
    { id: 'SNo.', label: 'SNo.', alignRight: false },
    { id: 'Date', label: 'Transaction Date', alignRight: false },
    { id: 'PaymentMode', label: 'Payment Mode', alignRight: false },
    { id: 'Amount', label: 'Amount', alignRight: true },
    { id: 'Remark', label: 'Remark', alignRight: false },
    // { id: 'Payslip', label: 'Payslip', alignRight: true }
];
export default PaymentTransactionList