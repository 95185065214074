
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from "react-redux";
import moment from 'moment';
import editFill from '@iconify/icons-material-symbols/edit-outline-rounded'
import { ACTIVE_USER, NIL, WORK_MODE_HOME, WORK_MODE_HOME_LABEL, WORK_MODE_HYBRID, WORK_MODE_HYBRID_LABEL, WORK_MODE_OFFICE, WORK_MODE_OFFICE_LABEL } from '../../constants';
import { ECard } from '../../BaseComponents/ECard';
import { ETypographyCardHeading } from '../../BaseComponents/ETypography';
import { EIconButton } from '../../BaseComponents/EButtons';
import { EIcon } from '../../BaseComponents/EIcon';
import { EDividerCard } from '../../BaseComponents/EDivider';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { DesignationList } from '../../action/DesignationAction';
import { DepartmentList } from '../../action/DepartmentAction'
import EModal from '../../BaseComponents/EModal';
import EditModalOfficialDetail from './EditModalOfficialDetail';
import { numberWithCommas } from '../../utils/formatNumber';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';


function TalentEditOfficialDetails(props) {
    const { auth, employee, isMyprofile, allowEdit } = props;
    const dispatch = useDispatch();

    // INFO: getting company settings
    const settingsContext = useCompanySettingProvider()
    const attendanceSetting = settingsContext?.setting?.attendanceSettingData?.companySetting
    const shiftList = attendanceSetting?.shifts ?? []
    // Shift Name change is allowed only if shifts is more than one. Otherwise, it is not allowed.
    const isShiftAllowed = shiftList?.length > 1

    const [IsEditOfficialDetail, SetIsEditOfficialDetail] = useState(false);

    useEffect(() => {
        dispatch(DesignationList(auth.authtoken))
        dispatch(DepartmentList(auth.authtoken))
    }, [auth?.authSuccess]);

    return (
        <>
            <ECard sx={{ padding: '20px', height: 'auto', textAlign: 'left', mx: "auto" }}>
                <Grid container spacing={2}  >
                    <Grid item xs={12} sm={12} lg={12} xl={12}  sx={{ p: 0 }}>

                        <Grid container display="row" justifyContent="space-between" alignItems='center' className='pb-2rem '>
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>Joining Details</ETypographyCardHeading>
                            </Grid>

                            {allowEdit && employee?.status == ACTIVE_USER && !employee?.latestPayroll && !isMyprofile &&
                                <Grid item >
                                    <EIconButton varient='link' onClick={() => SetIsEditOfficialDetail(true)} sx={{ p: 0, m: 0, textAlign: 'right', color: 'dark.0' }}>
                                        <EIcon icon={editFill} width={23} height={23} />
                                    </EIconButton>
                                </Grid>
                            }

                            <Grid xs={12} lg={12} item>
                                <EDividerCard className='margin-top-05' />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Date of Joining */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ELabelInputMainGrid label={"Date of Joining"}   isNotForm={true}>
                            {employee?.doj ? moment(employee?.doj).format('DD/MM/YYYY') : NIL}
                        </ELabelInputMainGrid>
                    </Grid>

                    {/* Department */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ELabelInputMainGrid label={"Department"} alignlabel={'flex-start'}  isNotForm={true}>
                            {employee?.departmentInfo?.department_name ? employee?.departmentInfo?.department_name :NIL}
                        </ELabelInputMainGrid>
                    </Grid>

                    {/* Salary */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ELabelInputMainGrid label={"Salary"} small_text={"(CTC/Month)"} alignlabel={'flex-start'}  isNotForm={true}>
                            {employee?.employeePayrollInfo?.payroll_basic ? numberWithCommas(employee?.employeePayrollInfo?.payroll_basic) : NIL}
                        </ELabelInputMainGrid>
                    </Grid>

                    {/* Designation */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ELabelInputMainGrid label={"Designation"} alignlabel={'flex-start'}  isNotForm={true}>
                            {employee?.designationInfo?.designation_name ? employee?.designationInfo?.designation_name : NIL}
                        </ELabelInputMainGrid>
                    </Grid>
                    
                    {/* Work Mode */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ELabelInputMainGrid label={"Work Mode"}  isNotForm={true}>
                            {employee?.work_mode == WORK_MODE_OFFICE ? WORK_MODE_OFFICE_LABEL :
                                employee?.work_mode == WORK_MODE_HYBRID ? WORK_MODE_HYBRID_LABEL :
                                    employee?.work_mode == WORK_MODE_HOME ? WORK_MODE_HOME_LABEL :
                                    NIL}
                        </ELabelInputMainGrid>
                    </Grid>

                    {/* Shift Name */}
                     {
                        isShiftAllowed &&
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <ELabelInputMainGrid label={"Shift Name"} alignlabel={'flex-start'}  isNotForm={true}>
                                {employee?.shiftInfo?.shift_name ? employee?.shiftInfo?.shift_name : NIL}
                            </ELabelInputMainGrid>
                        </Grid>
                    }
                </Grid>
                {/*INFO: Basic Detail Modal */}
                {IsEditOfficialDetail && <EModal open={IsEditOfficialDetail} newMaxWidth={'md'} close={() => SetIsEditOfficialDetail(false)} headervalue="Official Detail">
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                            <EditModalOfficialDetail
                                auth={auth}
                                employeeData={employee}
                                closeModalEditOfficialDetail={SetIsEditOfficialDetail}
                                isShiftAllowed={isShiftAllowed}
                                shiftList={shiftList}
                            // getPhotoUrl={getPhotoUrl}
                            />
                        </Grid>
                    </Grid>
                </EModal>}

            </ECard>

        </>
    );
}

export default TalentEditOfficialDetails
