import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from 'formik';
import { ContractFromValidation } from "../../utils/validation";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ACTIVE_USER, CHARACTER_LIMIT, GST_SLAB_OPTIONS } from "../../constants";
//actions
import { EmployeeAuthListData } from "../../action/EmployeeAuthAction";
import { AllFreelancerList } from "../../action/FreelancerAction";
import { CreateContract, TandCListData } from "../../action/ContractAction";
//base components
import Page from "../../BaseComponents/EPage";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EFormHelperText, EHelperText, ETypography, ETypographyCardHeading, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from "../../BaseComponents/EButtons";
import { EDivider } from "../../BaseComponents/EDivider";
import { EBox, EBoxPage } from "../../BaseComponents/EBox";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { ETextField } from "../../BaseComponents/ETextField";
import { ECard } from "../../BaseComponents/ECard";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { ERadio, ERadioGroup } from "../../BaseComponents/ERadio";
import { ECheckbox } from "../../BaseComponents/ECheckbox";
import EHidden from "../../BaseComponents/EHidden";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { EIcon } from "../../BaseComponents/EIcon";

const AddNewContract = (props) => {
    const { auth } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const freelancerList = useSelector((state) => state.FreelancerReducer);
    const tacList = useSelector((state) => state.TandCReducer);
    const [tacValues, setTacValues] = useState({ tnc: [] });
    const employee = useSelector(state => state.EmployeeAuthReducer)
    const companyDetails = useSelector(state => state.CompanyReducer)

    const formikAddContract = useFormik({
        validateOnChange: false,
        initialValues: {
            contract_title: '',
            contract_amount: '',
            contract_start_date: null,
            contract_end_date: null,
            freelancer: null,
            gst: null,
            isAdvance: "true",
            terms_cond: '',
            terms_cond_text: '',
            is_igst: null,
            created_by: auth?.authData?.id,
            contract_with_gst: '',
            list_terms: '',
            incharge: null,
            no_of_tnc_selected: 0,
            is_terms_conditions_list: false
        },
        validationSchema: ContractFromValidation,
        onSubmit: (data) => {
            data.freelancer = data?.freelancer?.id;
            data.incharge = data?.incharge?.id;
            data.created_by = auth?.authData?.id;
            data.gst = data?.gst?.id;
            if (data.is_igst != null) { data.is_igst = data?.is_igst?.id; }

            tacValues?.tnc?.length > 0 && tacValues?.tnc?.map((row, index) => {
                if (row?.is_default == true) {
                    if (index == 0) { return data.terms_cond += row?.id }
                    else { return data.terms_cond += ',' + row?.id }
                }
            })
            dispatch(CreateContract(auth.authtoken, data, navigate));
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formikAddContract;

    useEffect(() => {
        if (auth?.authtoken) {
            // dispatch(FreelancerListData(auth?.authtoken));//INFO: commenting all Freelancer List here since we have called it in contract list
            dispatch(AllFreelancerList(auth.authtoken));//INFO: calling all Freelancer List here since we have removed it from login and check token actions
            dispatch(EmployeeAuthListData(auth?.authtoken, ACTIVE_USER, null, null, { is_active: true, form_list: true, auth_list: true }))
            dispatch(TandCListData(auth?.authtoken));
        }
    }, []);

    // this useeffect is for calling the terms and conditions values
    useEffect(() => {
        if (tacList && tacList?.tacListSuccess) {
            const tncValue = tacList &&
                tacList?.tacListData?.rows?.flatMap(item => item.status ?
                    [{
                        id: item.id,
                        is_default: item.is_default,
                        terms_title: item.terms_title,
                        status: item.status,
                    }] : []);
            setTacValues({ tnc: tncValue })
        }
        if (tacList?.tacListData?.rows?.length > 0) {
            setFieldValue('is_terms_conditions_list', true)
        }
    }, [tacList?.tacListSuccess]);

    //this use effect is managing the gst type according to the freelancer's address
    useEffect(() => {
        if (formikAddContract?.values?.freelancer?.state && companyDetails?.companyData?.state_id) {
            setFieldValue('is_igst', formikAddContract?.values?.freelancer?.state == companyDetails?.companyData?.state_id ? { id: false, label: "SGST, CGST" } : { id: true, label: "IGST" })
        }else{
            setFieldValue('is_igst', null)
        }
    }, [formikAddContract.values.freelancer]);

    //this use effect is managing to set value in amount with GST  input
    useEffect(() => {
        if (formikAddContract.values.contract_amount && formikAddContract.values.gst) {
            let gst_percentage = (Number(formikAddContract.values.gst.id) / 100) * Number(formikAddContract.values.contract_amount);
            gst_percentage += Number(formikAddContract.values.contract_amount);
            setFieldValue("contract_with_gst", gst_percentage ? gst_percentage : '')
        }
    }, [formikAddContract.values.gst, formikAddContract.values.contract_amount]);

    const EmployeeList =
        employee && employee?.empAuthListData?.rows?.map((obj) => ({
            id: obj.id,
            label: `${obj.fullname} (${obj.employee_code})`
        }));

    const FreelancertListData =
        freelancerList &&
        freelancerList?.freelanceListData?.rows?.map((obj) => ({
            id: obj.id,
            label: `${obj.freelancer_name} (${obj.freelancer_code})`,
            email: obj.freelancer_email,
            mobile: obj.freelancer_contact_no,
            state: obj.state
        }));

    const onKeyDown = (e) => {
        e.preventDefault();
    };

    const OnChangeTnc = (id, value) => {
        let newState = [...tacValues.tnc];
        newState[id].is_default = value;
        setTacValues({ tnc: newState })
    }

    return (
        <Page title="Add Contract" >
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Add Contract'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Freelancer Contract', href: '' },
                            { name: 'Contract List', href: '/freelancer-contract/contract-list' },
                            {
                                name: 'Add Contract',
                            }
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
            >
                <Grid item>
                    <ETypographyPageHeading> Add Contract</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  
                    <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                </EHidden>
            </Grid>
            <EHidden width="mdDown">
                <EDivider className='mt-8px' />
            </EHidden>
            <FormikProvider value={formikAddContract} >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                       
                    <EBoxPage>
                      <ECard className='card_design_1'>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        className="mb-8px"
                                    >
                                        <ETypographyCardHeading variant='h5'>  Contract Details </ETypographyCardHeading>
                                    </Grid>

                                    <EDivider className="mb-16px" />

                                    <EBox className="mb-16px" >
                                        <Grid container spacing={2} className='px-16px'>
                                            <Grid item md={12} sm={12} xs={12}  >
                                                <ELabelInputMainGrid label={'Contract Name'} isfullgrid={true}>
                                                    <ETextField
                                                        fullWidth
                                                        autoComplete="contract_title"
                                                        {...getFieldProps('contract_title')}
                                                        error={Boolean(touched.contract_title && errors.contract_title)}
                                                        helperText={touched.contract_title && errors.contract_title}
                                                    />
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Start Date'}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <EDatePicker
                                                            name="contract_start_date"
                                                            minDate={new Date()}
                                                            maxDate={new Date(moment().add(1, 'months').calendar())}
                                                            inputFormat="dd/MM/yyyy"
                                                            {...getFieldProps('contract_start_date')}
                                                            onChange={(newValue) => {
                                                                setFieldValue('contract_start_date', newValue ? newValue : '');
                                                            }}
                                                            renderInput={(params) => (
                                                                <ETextField
                                                                    {...params}
                                                                    fullWidth
                                                                    name="contract_start_date"
                                                                    onKeyDown={onKeyDown}      
                                                                    placeholder="dd/mm/yyy"
                                                                    error={Boolean(touched.contract_start_date && errors.contract_start_date)}
                                                                    helperText={touched.contract_start_date && errors.contract_start_date}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'End Date'}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <EDatePicker
                                                            name="contract_end_date"
                                                            minDate={formikAddContract?.values?.contract_start_date ? moment(formikAddContract?.values?.contract_start_date).add(1, 'days').toDate() : new Date()}
                                                            inputFormat="dd/MM/yyyy"
                                                            {...getFieldProps('contract_end_date')}
                                                            onChange={(newValue) => {
                                                                setFieldValue('contract_end_date', newValue ? newValue : '');
                                                            }}
                                                            renderInput={(params) => (
                                                                <ETextField
                                                                    {...params}
                                                                    fullWidth
                                                                    name="contract_end_date"
                                                                    onKeyDown={onKeyDown}
                                                                    placeholder="dd/mm/yyy"
                                                                    error={Boolean(touched.contract_end_date && errors.contract_end_date)}
                                                                    helperText={touched.contract_end_date && errors.contract_end_date}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Freelancer Name'}>
                                                    <EAutocomplete
                                                        name="freelancer"
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        {...getFieldProps('freelancer')}
                                                        options={
                                                            FreelancertListData && FreelancertListData?.length > 0
                                                                ? FreelancertListData
                                                                : []
                                                        }
                                                        getOptionLabel={(option) => option.label || ''}
                                                        onChange={(e, value) => {
                                                            setFieldValue('freelancer', value);
                                                        }}
                                                        renderInput={(params) => (
                                                            <ETextField
                                                                {...params}
                                                                name="freelancer"
                                                                placeholder="Select Freelancer Name"
                                                                fullWidth
                                                                InputLabelProps={{ shrink: true }}
                                                                error={Boolean(touched.freelancer && errors.freelancer)}
                                                                helperText={touched.freelancer && errors.freelancer}
                                                            />
                                                        )}
                                                    />
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12} >
                                                <ELabelInputMainGrid label={'Incharge'}>
                                                    <EAutocomplete
                                                        name="Incharge"
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        {...getFieldProps('incharge')}
                                                        options={
                                                            EmployeeList && EmployeeList?.length > 0
                                                                ? EmployeeList
                                                                : []
                                                        }
                                                        getOptionLabel={(option) => option.label || ''}
                                                        onChange={(e, value) => {
                                                            setFieldValue('incharge', value);
                                                        }}
                                                        renderInput={(params) => (
                                                            <ETextField
                                                                {...params}
                                                                name="incharge"
                                                                placeholder="Select Incharge"
                                                                fullWidth
                                                                error={Boolean(touched.incharge && errors.incharge)}
                                                                helperText={touched.incharge && errors.incharge}
                                                            />
                                                        )}
                                                    />
                                                </ELabelInputMainGrid>
                                            </Grid>
                                        </Grid>
                                    </EBox>
                                </ECard>

                                <ECard className='card_design_1'>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        className="mb-8px"
                                    >
                                        <ETypographyCardHeading variant='h5'> Payment Details</ETypographyCardHeading>
                                    </Grid>

                                    <EDivider className="mb-16px" />

                                    <EBox className="mb-16px" >
                                        <Grid container spacing={2} className='px-16px'>
                                            <Grid item md={6} sm={6} xs={12}  >
                                                <ELabelInputMainGrid label={'Amount'} >
                                                    <ETextField
                                                        fullWidth
                                                        placeholder="₹ 0.00"
                                                        autoComplete="contract_amount"
                                                        {...getFieldProps('contract_amount')}
                                                        error={Boolean(touched.contract_amount && errors.contract_amount)}
                                                        helperText={touched.contract_amount && errors.contract_amount}
                                                    />
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12}  >
                                                <ELabelInputMainGrid label={'GST Slab'} >
                                                    <EAutocomplete
                                                        name="gst"
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        {...getFieldProps('gst')}
                                                        options={GST_SLAB_OPTIONS ? GST_SLAB_OPTIONS : []}
                                                        getOptionLabel={(option) => option.label || ''}
                                                        onChange={(e, value) => {
                                                            setFieldValue('gst', value ? value : null);
                                                        }}
                                                        renderInput={(params) => (
                                                            <ETextField
                                                                {...params}
                                                                name="gst"
                                                                placeholder="Select GST Slab"
                                                                fullWidth
                                                                error={Boolean(touched.gst && errors.gst)}
                                                                helperText={touched.gst && errors.gst}
                                                            />
                                                        )}
                                                    />
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12}  >
                                                <ELabelInputMainGrid label={'GST Type'} >
                                                    <EAutocomplete
                                                        // disabled={formikAddContract.values.gst == null || formikAddContract?.values?.gst?.id != '0' ? false : true}
                                                        disabled
                                                        name="is_igst"
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        {...getFieldProps('is_igst')}
                                                        options={[
                                                            { id: false, label: "SGST, CGST" },
                                                            { id: true, label: "IGST" },
                                                        ]}
                                                        getOptionLabel={(option) => option.label || ''}
                                                        renderInput={(params) => (
                                                            <ETextField
                                                                {...params}
                                                                name="is_igst"
                                                                placeholder="Select GST Type"
                                                                fullWidth
                                                                error={Boolean(touched.is_igst && errors.is_igst)}
                                                                helperText={touched.is_igst && errors.is_igst}
                                                            />
                                                        )}
                                                    />
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12}  >
                                                <ELabelInputMainGrid label={'Total Amount'} >
                                                    <ETextField
                                                        fullWidth
                                                        disabled
                                                        {...getFieldProps('contract_with_gst')}
                                                    />
                                                </ELabelInputMainGrid>
                                            </Grid>
                                            <Grid item md={6} sm={6} xs={12}  >
                                                <ELabelInputMainGrid label={'Advance'} labelPT='0.5rem'>
                                                    <FormControl
                                                        component="fieldset"
                                                        error={Boolean(touched.isAdvance && errors.isAdvance)}
                                                    >
                                                        <ERadioGroup
                                                            row
                                                            aria-label="isAdvance"
                                                            name="isAdvance"
                                                            {...getFieldProps('isAdvance')}
                                                        >
                                                            <FormControlLabel value="true" control={<ERadio />} label="Yes" />
                                                            <FormControlLabel value="false" control={<ERadio />} label="No" />
                                                        </ERadioGroup>
                                                        {touched.isAdvance && <EFormHelperText> {errors.isAdvance}</EFormHelperText>}
                                                    </FormControl>
                                                </ELabelInputMainGrid>
                                            </Grid>
                                        </Grid>
                                    </EBox>
                                </ECard>


                                <ECard className='card_design_1'>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        className="mb-8px"
                                    >
                                        <ETypographyCardHeading variant='h5'> Terms & Conditions</ETypographyCardHeading>
                                    </Grid>

                                    <EDivider className="mb-16px" />
                                    <EBox className="mb-16px" >
                                        <Grid container spacing={2} className='px-16px'>
                                            <Grid item md={12} sm={12} xs={12}  >
                                                {tacValues?.tnc?.length > 0 && tacValues?.tnc?.map((row, index) => {
                                                    const { terms_title, is_default, } = row;
                                                    return (
                                                        <Grid item xs={12} md={12} sm={12} xl={12} key={index + terms_title} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                                            <ECheckbox
                                                                id={index.toString()}
                                                                name={terms_title}
                                                                checked={is_default  ? true : false}
                                                                onChange={(e, value) => {
                                                                    OnChangeTnc(e.target?.id, value);
                                                                    setFieldValue('no_of_tnc_selected', value == true ? formikAddContract?.values?.no_of_tnc_selected + 1 : formikAddContract?.values?.no_of_tnc_selected - 1)
                                                                }}
                                                                sx={{ padding: '0 8px 0 0' }}
                                                                className="mb-16px"
                                                            />
                                                            {terms_title ?
                                                                <ETypography component={'span'} className='font-size-14px' dangerouslySetInnerHTML={{ __html: terms_title }}></ETypography> : '-'}
                                                        </Grid>
                                                    );
                                                }

                                                )}
                                                {touched.isAdvance &&
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: errors.no_of_tnc_selected ? 'flex' : 'none' }} >
                                                        <EHelperText className="color-text-error-red">  {errors.no_of_tnc_selected}</EHelperText>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}  >
                                                <ELabelInputMainGrid label={'Additional Terms & Conditions '}  isfullgrid={true}>
                                                    <ETextField
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        inputProps={{ maxLength: CHARACTER_LIMIT }}
                                                        {...getFieldProps('terms_cond_text')}
                                                        error={Boolean(touched.terms_cond_text && errors.terms_cond_text)}
                                                        helperText={touched.terms_cond_text && errors.terms_cond_text}
                                                    />
                                                    {`${formikAddContract.values?.terms_cond_text?.length}/${CHARACTER_LIMIT}`}
                                                </ELabelInputMainGrid>
                                            </Grid>
                                        </Grid>
                                    </EBox>
                                </ECard>

                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center">
                                    <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Cancel </EButtonOutlined>
                                    <ELoadingButton variant="contained" type="submit" className="button-left-margin" >   Save & Next </ELoadingButton>
                                </Grid>
                        </EBoxPage>
                    </LocalizationProvider>
                </Form>
            </FormikProvider>
        </Page>
    )

};

export default AddNewContract;
