import { Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SalaryList } from "../../action/PaymentAction";
import EChip from "../../BaseComponents/EChip";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import { DEFAULT_ROWS_PERPAGE, PAID_STATUS, PENDING_STATUS } from "../../constants";
import SearchNotFound from "../SearchNotFound";
import SalaryPayrollListSearchBar from "./SalaryPayrollListSearchBar";

//SalaryList

const SalaryPayroll = (props) => {

    const { paymentList, auth, tabvalue, setShowDetailPage } = props;

    const dispatch = useDispatch();

    const [searchParam, setSearchParam] = useState({ list_type: tabvalue, month: '', year: '', sort: true });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);


    const getFilterData = (searchData) => {
        callApi(searchData, 0, rowsPerPage);
        setPage(0);
    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...searchParam, list_type: tabvalue }, page, rowsPerPage);
        }
    }, [tabvalue])


    const handleChangePage = (event, newPage) => {
        callApi(searchParam, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(searchParam, 0, rowPerPageCount);
        setPage(0);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(SalaryList(auth.authtoken, { ...fnParams, sort: !fnParams.sort ? ' month_year ASC ' : 'month_year DESC' }, fnPage, fnRowPerPage));
    }

    function numberWithCommas(num) {
        let number = Number(num)
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR" }).format(number).replace(/^(\D+)/, '$1 ');
    }

    const setSelectedRow = (row) => {
        setShowDetailPage({ isShow: true, payrollId: row.id })
    }

    const TABLE_HEAD = gridColumnsHead(tabvalue);


    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SalaryPayrollListSearchBar
                    setSearchParam={setSearchParam}
                    searchParam={searchParam}
                    getFilterData={getFilterData}
                 
                />
            </Grid>
            <Grid item md={12} my={2}>
                <EScrollbar>
                    <ETableContainer >
                        <ETable>
                            {paymentList?.payrollListData?.count > 0 && (
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={paymentList?.payrollListData?.rows?.length}
                                    onRequestSort={() => null}
                                />
                            )}

                            {paymentList?.payrollListData?.payrollListLoading && (
                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            )}
                            {paymentList?.payrollListData?.count > 0 && (
                                <ETableBody>
                                    {paymentList?.payrollListData?.rows?.map((row, index) => {
                                        const { payble_amount,  updated_at,  month_date, transaction_status, id, paid_employee_count, collect_amount, balance, other } = row;
                                        return (
                                            <ETableRow
                                                onClick={() => setSelectedRow(row)}
                                                sx={{ 'cursor': 'pointer' }}
                                                hover key={index} tabIndex={-1}>

                                                <ETableCell align="center" sx={{ padding: '10px' }}>
                                                    {moment(month_date).format('MMM yyyy')}
                                                </ETableCell>
                                                <ETableCell align="right" style={{ minWidth: '150px' }}>
                                                    {payble_amount ? numberWithCommas(payble_amount) : numberWithCommas(0)}{' '}
                                                </ETableCell>
                                                <ETableCell style={{ minWidth: '150px' }} align="right">
                                                    {collect_amount ? numberWithCommas(collect_amount) : numberWithCommas(0)}
                                                </ETableCell>

                                                {tabvalue === PENDING_STATUS && <>
                                                    <ETableCell align="right" style={{ minWidth: '150px' }}>
                                                        {balance ? numberWithCommas(balance) : numberWithCommas(0)}
                                                    </ETableCell>
                                                    <ETableCell align="right" style={{ minWidth: '150px' }}>
                                                        {other ? numberWithCommas(other) : numberWithCommas(0)}
                                                    </ETableCell>
                                                    <ETableCell align="left" style={{ minWidth: '120px' }}>
                                                        {transaction_status == 'PENDING' ? (
                                                            <EChip width="120px" label="Pending" />
                                                        ) : (
                                                            <EChip width="120px" label="Partially Paid" />
                                                        )}
                                                    </ETableCell>

                                                </>}

                                                {tabvalue === PAID_STATUS && <>
                                                    <ETableCell align="right" style={{ minWidth: '150px' }}>
                                                        {other ? numberWithCommas(other) : numberWithCommas(0) }
                                                    </ETableCell>
                                                    <ETableCell align="right" style={{ minWidth: '150px' }}>
                                                        {moment(updated_at).format('DD/MM/YYYY')}
                                                    </ETableCell>
                                                </>}


                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            )}
                            {paymentList?.payrollListData?.count == 0 &&
                                !paymentList?.payrollListData?.payrollListLoading && (
                                    <ETableBody>
                                        <ETableRow>
                                            <ETableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                                <SearchNotFound />
                                            </ETableCell>
                                        </ETableRow>
                                    </ETableBody>
                                )}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {paymentList?.payrollListData?.count > DEFAULT_ROWS_PERPAGE && (
                    <Grid container my={3}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">
                        <ETablePagination
                            rowsPerPageOptions={DEFAULT_ROWS_PERPAGE}
                            component="div"
                            count={paymentList?.payrollListData?.count ? paymentList?.payrollListData?.count : 10}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )

};



const gridColumnsHead = (tabvalue) => {
    let tblHead = [
        { id: 'Month', label: 'Month', alignRight: false },
        { id: 'Payable Amount', label: 'Payable Amount', alignRight: true },
        { id: 'Paid Amount', label: 'Paid Amount', alignRight: true },
    ];
    if (tabvalue === PENDING_STATUS) {
        tblHead.push({ id: 'BalanceAmount', label: 'Balance Amount', alignRight: true });
        tblHead.push({ id: 'OtherAmount', label: 'Other Amount', alignRight: true });
        tblHead.push({ id: 'Status', label: 'Status', alignRight: false });
    }
    if (tabvalue === PAID_STATUS) {
        tblHead.push({ id: 'Cancelled_Amount', label: 'Cancelled Amount', alignRight: true });
        tblHead.push({ id: 'Updated_On', label: 'Updated On', alignRight: true });
    }
    return tblHead;

};

export default SalaryPayroll;