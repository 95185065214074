import { Grid, IconButton } from "@mui/material";
import React from "react";
import { EBox } from "../../BaseComponents/EBox";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { ETextFieldSearch } from "../../BaseComponents/ETextField";

const rangeOfYears = (start = null, rangeTill = null) => {
    var max = !start ? new Date().getFullYear() : start;
    var min = max - (rangeTill ? rangeTill : 5);
    var years = []

    for (var i = max; i >= min; i--) {
        years.push({ label: i, id: i })
    }
    return years
}

const rangeOfmonth = new Array(12).fill(0).map((_, i) => {
    const monthName = new Date(`${i + 1}/1`).toLocaleDateString(undefined, { month: 'long' });
    return { label: monthName, id: monthName };
});



const SalaryPayrollListSearchBar = (props) => {

    const { searchParam, setSearchParam, getFilterData,  tabvalue } = props;

    const setSearch = (key, value) => {
        const sParams = { ...searchParam, [key]: value };
        setSearchParam(sParams);
        getFilterData(sParams)
    }
    return ( 
    <Grid container>
               <Grid item xs={11} sm={11} md={9}  lg={9} xl={10} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item xs={5} sm={4} md={4.5} lg={4.5} xl={3}>

                            <EAutocomplete
                                name="month"
                                fullWidth
                                options={rangeOfmonth}
                                getOptionLabel={(option) => option.label ? option.label : ""}
                                onChange={(e, value) => setSearch('month', value?.id ? value?.id : '')}
                                renderInput={params => (
                                    <ETextFieldSearch
                                        fullWidth
                                        {...params}
                                        placeholder="Search By Month"
                                        name='month'
                                        size='small'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={5} sm={4} md={4.5} lg={4.2} xl={3}>
                            <EAutocomplete
                                name="year"
                                fullWidth
                                options={rangeOfYears()}
                                getOptionLabel={(option) => option.label ? option.label.toString() : ""}
                                onChange={(e, value) => setSearch('year', value?.id ? value?.id : '')}
                                renderInput={params => (
                                    <ETextFieldSearch
                                        fullWidth
                                        {...params}
                                        placeholder="Search By Year"
                                        name='year'
                                        size='small'
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} sm={1}  md={3}  lg={3} xl={2} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <EBox className="display-flex align-center">
                        <ETooltip title={'Sort'} arrow>
                            <IconButton onClick={() => setSearch('sort', !searchParam.sort)}>
                                <SortByAlphaIcon />
                            </IconButton>
                            </ETooltip>
                        </EBox>

                    </Grid>
                </Grid>
            </Grid>
    );
};

export default SalaryPayrollListSearchBar;