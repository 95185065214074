  import axios from 'axios';
import {
  COMPANY_SETTING_LOADING,
  COMPANY_SETTING_FAILURE,
  COMPANY_SETTING_SUCCESS,
  SERVER_URL,
  COMPANY_WORKING_TIME_API,
  COMPANY_REGULARIZATION_API,
  COMPANY_LEAVE_CYCLE_API,
  COMPANY_WEEKOFFS_API,
  SALARY_SLIP_API,
  SET_ADDITIONAL_SETTING_API,
  SET_LEAVE_SETTING_API,
  SET_PAYROLL_SETTING_API,
  SET_ATTENDANCE_SETTING_API,
  GLOBAL_SETTING_LOADING, GLOBAL_SETTING_FAILURE, 
  GLOBAL_SETTING_SUCCESS,
  SET_PAYMENT_SETTING_API,
  SET_TALENT_SETTING_API,
  OFFICE_HOURS_TYPE
} from '../constants';
import { Logout } from './AuthAction';
import moment from 'moment';
import { CompanyDetail } from './CompanyAction';
import { Success, Errors } from '../BaseComponents/EToast';
import { ReturnBoolean, ReturnBoolean2 } from '../utils/CommonFunctions';

export function GetCompanySetting(token) {
  return async (dispatch) => {
    dispatch(companySettingLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/company/get-company-setting`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(companySettingSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(companySettingFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function UpdateCompanySettings(token, Data, navigate) {
  const data = new FormData();
  // data.append('updated_by', Data.updated_by);
  //company working hours
  data.append(
    'office_open_time',
    moment(Data.office_open_time).format('HH:mm') != 'Invalid date'
      ? moment(Data.office_open_time).format('HH:mm')
      : Data.office_open_time
  );
  data.append(
    'office_close_time',
    moment(Data.office_close_time).format('HH:mm') != 'Invalid date'
      ? moment(Data.office_close_time).format('HH:mm')
      : Data.office_close_time
  );
  data.append(
    'working_hours',
    moment(Data.working_hours).format('HH:mm') != 'Invalid date'
      ? moment(Data.working_hours).format('HH:mm')
      : Data.working_hours
  );
  data.append(
    'present_min_hour',
    moment(Data.present_min_hour).format('HH:mm') != 'Invalid date'
      ? moment(Data.present_min_hour).format('HH:mm')
      : Data.present_min_hour
  );
  data.append(
    'min_halfday_hour',
    moment(Data.min_halfday_hour).format('HH:mm') != 'Invalid date'
      ? moment(Data.min_halfday_hour).format('HH:mm')
      : Data.min_halfday_hour
  );
  //Attendance Regularization
  data.append('attendance_regularization_count', Data.attendance_regularization_count);

  //Leave policy
  data.append('leave_from_month', Data.leave_from_month);
  data.append('leave_to_month', Data.leave_to_month);
  data.append('leave_allowed_per_month', Data.leave_allowed_per_month);
  data.append('leave_allowed_per_tenure', Data.leave_allowed_per_tenure);
  data.append('leave_tenure_category', Data.leave_tenure_category);

  //salary_slip
  data.append('salary_slip_format', Data.salarySlip);
  // data.append('company_id', Data.id);
  if (Data.workingDaySetting && Object.keys(Data.workingDaySetting).length > 0) {
    let workingDaySetting = JSON.stringify(Data.workingDaySetting)
    data.append('workingDaySetting', workingDaySetting);
  }

  return async (dispatch) => {
    dispatch(companySettingLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/company/company-setting`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: 'html',
            html: `<span style="color:white">${res.data.message}</span>`
          });
          dispatch(GetCompanySetting(token, Data.id));
          // if(close){close();}
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(GetCompanySetting(token, Data.id));
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(companySettingFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}
/**
 * [2022-09-15]
 * changes by: Aanchal Sahu
 * Description:- used this action for salary slip format handling in company policy
 * [2022-11-10]
 * Changes By:- Aanchal Sahu
 * Description:- Changed whole leave component of company policy in api = COMPANY_LEAVE_CYCLE_API
 **/
export function UpdateSeparateCompanyPolicy(token, Data, ApiUrl) {



  const data = new FormData();


  if (ApiUrl == COMPANY_REGULARIZATION_API) {
    // data.append('updated_by', Data.updated_by);
    data.append('attendance_regularization_count', Data.attendance_regularization_count);
    data.append('attendance_regularization_request', Data.attendance_regularization_request);
    data.append('attendance_regularization_required', Data.attendance_regularization_required);
  }
  else if (ApiUrl == COMPANY_WORKING_TIME_API) {
    // data.append('updated_by', Data.updated_by);
    data.append(
      'office_open_time',
      moment(Data.office_open_time).format('HH:mm') != 'Invalid date'
        ? moment(Data.office_open_time).format('HH:mm')
        : Data.office_open_time
    );
    data.append(
      'office_close_time',
      moment(Data.office_close_time).format('HH:mm') != 'Invalid date'
        ? moment(Data.office_close_time).format('HH:mm')
        : Data.office_close_time
    );
    data.append(
      'working_hours',
      moment(Data.working_hours).format('HH:mm') != 'Invalid date'
        ? moment(Data.working_hours).format('HH:mm')
        : Data.working_hours
    );
    data.append(
      'present_min_hour',
      moment(Data.present_min_hour).format('HH:mm') != 'Invalid date'
        ? moment(Data.present_min_hour).format('HH:mm')
        : Data.present_min_hour
    );
    data.append(
      'min_halfday_hour',
      moment(Data.min_halfday_hour).format('HH:mm') != 'Invalid date'
        ? moment(Data.min_halfday_hour).format('HH:mm')
        : Data.min_halfday_hour
    );
  }
  else if (ApiUrl == COMPANY_LEAVE_CYCLE_API) {

    data.append('leave_from_month', Data.leave_from_month);
    data.append('leave_to_month', Data.leave_to_month);
    if (Data.leave_allowed_per_month && Data.leave_tenure_category != 3)  //Here !3 = not any month between 1 to 11 (i.e. 1=financial year and 2=calender year)
    { data.append('leave_allowed_per_month', Data.leave_allowed_per_month); }
    data.append('leave_allowed_per_tenure', Data.leave_allowed_per_tenure);
    data.append('leave_tenure_category', Data.leave_tenure_category);

  }
  else if (ApiUrl == COMPANY_WEEKOFFS_API) {

    if (Object.keys(Data.workingDaySetting).length > 0) {
      let workingDaySetting = JSON.stringify(Data.workingDaySetting)
      data.append('workingDaySetting', workingDaySetting);
    }
  }
  else if (ApiUrl == SALARY_SLIP_API) {
    data.append('salary_slip_format', Data.salarySlip);
  }

  return async (dispatch) => {
    dispatch(companySettingLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/company/${ApiUrl}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: 'html',
            html: `<span style="color:white">${res.data.message}</span>`
          });

          if (Data.id) {
            dispatch(GetCompanySetting(token, Data.id));
            dispatch(CompanyDetail(token, Data.id));
          }//to reflect the changes immediately of salary format
        }
        dispatch(GetCompanySetting(token, Data.id));
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(GetCompanySetting(token, Data.id));
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(companySettingFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function companySettingLoading() {
  return {
    type: COMPANY_SETTING_LOADING
  };
}
export function companySettingSuccess(payload) {
  return {
    type: COMPANY_SETTING_SUCCESS,
    payload
  };
}

export function companySettingFailure(payload) {
  return {
    type: COMPANY_SETTING_FAILURE,
    payload
  };
}

// INFO: This api is for account settings, additional settings, leave settings, ,attendance setting
export function GetSetting(token, API, next = () => null) {

  return async (dispatch) => {
    dispatch(globalSettingLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/setting/${API}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(globalSettingSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(globalSettingFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      }).finally(() => next())
  };
}

// INFO: This API is used to save date by Additional Setting, Leave Setting ,attendance setting, talent settings
export function SetSettingAction(token, Data, url, geturl) {

  const data = new FormData();

  if (url == SET_ADDITIONAL_SETTING_API) {
    let additionalSetting = JSON.stringify(Data.data)
    data.append('dpr_submission_required', ReturnBoolean(Data.dprRequired));
    data.append('data', additionalSetting);
    data.append('enable_custom_document',Data.enable_custom_document);
    data.append('is_default_setting',ReturnBoolean(Data.is_default_setting))
  }

  // We have new Api for payment settings
  // if (url == SET_PAYMENT_SETTING_API) {
  //   let reimbursement_reason = JSON.stringify(Data.reimbursementReasons)
  //   data.append('enable_reimbursement', Data.paymentSetting)
  //   data.append('reimbursement_attachment_required',Data.attachmentCompulsory)
  //   data.append('enable_reimbursement_reason',Data.reasonForReimbursement)
  //   data.append('reimbursement_with_payroll',Data.reimbursementWithPayroll)
  //   data.append('enable_advance_salary',Data.advanceSalaryRequest)
  //   data.append('reimbursement_reason',reimbursement_reason)
  //   data.append('is_default_setting',ReturnBoolean(Data.is_default_setting))
  // }

  if (url == SET_LEAVE_SETTING_API) {
    data.append('is_default_setting',ReturnBoolean(Data.is_default_setting))
    data.append('leave_from_month', Data.leave_from_month?Data.leave_from_month:'');
    data.append('leave_to_month', Data.leave_to_month? Data.leave_to_month:'');
    data.append('leave_allowed_per_month', Data.leave_allowed_per_month >=0? Number(Data.leave_allowed_per_month):'');
    data.append('leave_allowed_per_tenure', Data.leave_allowed_per_tenure >=0? Number(Data.leave_allowed_per_tenure):'');
    data.append('leave_tenure_category', Data.leave_tenure_category? Data.leave_tenure_category:'');
    data.append('enable_auto_leave_approval', Data.enable_auto_leave_approval);
    if(Data?.auto_leave_approval_timeframe?.id){data.append('auto_leave_approval_timeframe', Data.enable_auto_leave_approval? Data?.auto_leave_approval_timeframe?.id :'');}
    if(Number(Data.auto_leave_approval_timeframe)){data.append('auto_leave_approval_timeframe', Data.auto_leave_approval_timeframe);}
    data.append('enable_half_day_leave_request', ReturnBoolean(Data.enable_half_day_leave_request));
    data.append('enable_leave_application_reason', ReturnBoolean(Data.enable_leave_application_reason));
    data.append('enable_leave_cycle', Data.enable_leave_cycle);
    data.append('enable_multi_level_leave_approval', Data.enable_multi_level_leave_approval);
    // data.append('enable_weekly_off', Data.enable_weekly_off);
    data.append('enable_paid_leave_notice_period', ReturnBoolean2(Data.enable_paid_leave_notice_period)==true?true:false);

    if (Data.workingDaySetting && Object.keys(Data.workingDaySetting).length > 0) {
      let workingDaySetting = JSON.stringify(Data.workingDaySetting)
      data.append('working_day_setting', ReturnBoolean(Data.enable_weekly_off)==true? workingDaySetting:'');
    }
  }
  else if (url == SET_PAYROLL_SETTING_API) {
    // data.append('salary_slip_format', Data.salarySlip); // INFO: COMMENTED this CODE FOR NOW, CODE IS FOR SALARY SLIP
    data.append('payroll_is_auto', ReturnBoolean(Data.payroll_is_auto));
    data.append('is_default_setting',ReturnBoolean(Data.is_default_setting))
    if(Data.payroll_auto_generation_date){data.append('payroll_auto_generation_date', Data.payroll_auto_generation_date?.id);}
  }
  else if (url == SET_ATTENDANCE_SETTING_API) {
    data.append('attendance_mode', Number(Data?.attendance_mode));

    if (Number(Data?.attendance_mode) == 2) {

      data.append('ip_is_check', Data?.ip_is_check);
      if (Data?.ip_is_check && ReturnBoolean(Data?.ip_is_check)) {
        data.append('ip_address', Data?.ip_address);
        data.append('connection_name', Data?.connection_name);
      }

      data.append('office_open_time', moment(Data.office_open_time).format('HH:mm'));
      data.append('office_close_time', moment(Data.office_close_time).format('HH:mm'));
      data.append('working_hours', Data.working_hours);
      data.append('present_min_hour', Data.present_min_hour);
      data.append('min_halfday_hour', Data.min_halfday_hour);
      
      data.append('attendance_regularization_required', Data.attendance_regularization_required);

      if (ReturnBoolean(Data.attendance_regularization_required)) {
        data.append('attendance_regularization_request', Data.attendance_regularization_request);
        if (Data.attendance_regularization_request!=null && !ReturnBoolean(Data.attendance_regularization_request)) {
          data.append('attendance_regularization_count', Data.attendance_regularization_count);
        }
      }
    }
  }
  else if (url == SET_TALENT_SETTING_API) {
    data.append('enable_talent_directory', Data.enable_talent_directory);
    data.append('talent_custom_field', Data.talent_custom_field);
    data.append('is_default_setting',ReturnBoolean(Data.is_default_setting))
    // INFO: conditional value if due the API's requirement 
    // i.e. if talent_custom_field==true then custom values will be sent else false will be sent as checkbox's value.

    const is_checkboxes= ReturnBoolean(Data.talent_custom_field)
    data.append('field_dob', is_checkboxes && ReturnBoolean2( Data.field_dob)==true? Data.field_dob : false);
    data.append('field_gender', is_checkboxes && ReturnBoolean2( Data.field_gender)==true? Data.field_gender : false);
    data.append('field_marital_status', is_checkboxes && ReturnBoolean2( Data.field_marital_status)==true? Data.field_marital_status : false);
    data.append('field_blood_group', is_checkboxes && ReturnBoolean2( Data.field_blood_group)==true? Data.field_blood_group : false);
    data.append('field_address', is_checkboxes && ReturnBoolean2( Data.field_address)==true? Data.field_address : false);
    data.append('field_profile_photo', is_checkboxes && ReturnBoolean2( Data.field_profile_photo)==true? Data.field_profile_photo : false);
    data.append('field_ifsc_code', is_checkboxes && ReturnBoolean2( Data.field_ifsc_code)==true? Data.field_ifsc_code : false);
    data.append('field_bank_name',is_checkboxes && ReturnBoolean2( Data.field_bank_name)==true? Data.field_bank_name : false);
    data.append('field_branch_name', is_checkboxes && ReturnBoolean2( Data.field_branch_name)==true? Data.field_branch_name : false);
    data.append('field_account_holder_name', is_checkboxes && ReturnBoolean2( Data.field_account_holder_name)==true? Data.field_account_holder_name : false);
    data.append('field_account_number', is_checkboxes && ReturnBoolean2( Data.field_account_number)==true? Data.field_account_number : false);
    data.append('field_emer_name', is_checkboxes && ReturnBoolean2( Data.field_emer_name)==true? Data.field_emer_name : false);
    data.append('field_emer_relation', is_checkboxes && ReturnBoolean2( Data.field_emer_relation)==true? Data.field_emer_relation : false);
    data.append('field_emer_contact_no', is_checkboxes && ReturnBoolean2( Data.field_emer_contact_no)==true? Data.field_emer_contact_no : false);

    // INFO: conditional value if due the API's requirement  
    // i.e. if enable_talent_code_prefix==true then talent_code_prefix will be sent else null will be sent.
    data.append('enable_talent_code_prefix', Data.enable_talent_code_prefix);
    data.append('talent_code_prefix', Data.talent_code_prefix);

    let talentDoc = JSON.stringify(Data.talent_document)
    data.append('talent_document', talentDoc);
  }

  return async (dispatch) => {
    dispatch(globalSettingLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/setting/${url}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {  
         dispatch(GetCompanySetting(token)) // to reflect the changes in getCompanySetting reducer
         dispatch( GetSetting(token, geturl))//to reflect the changes immediately 
         dispatch(CompanyDetail(token))//to reflect the changes immediately in the dashboard
         Success.fire({
          text: 'html',
          html: `<span style="color:white">${res.data.message}</span>`
        });
        }
      })
      .catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: 'html',
            html: `<span style="color:white">${error.response.data.message}</span>`
          });
          dispatch(globalSettingFailure(error.response.data));
          if(error?.response?.status == 400 && url == SET_ADDITIONAL_SETTING_API){
            dispatch( GetSetting(token, geturl))//to reflect the changes if admin tries to delete a custom doc that exist in talent table i.e it cannot be deleted hence will throw a error and calls the get again to update the list
          }
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


// GLOBAL_SETTING_LOADING, GLOBAL_SETTING_FAILURE, GLOBAL_SETTING_SUCCESS
export function globalSettingLoading() {
  return {
    type: GLOBAL_SETTING_LOADING
  };
}
export function globalSettingSuccess(payload) {
  return {
    type: GLOBAL_SETTING_SUCCESS,
    payload
  };
}

export function globalSettingFailure(payload) {
  return {
    type: GLOBAL_SETTING_FAILURE,
    payload
  };
}