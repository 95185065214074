import { Stack } from "@mui/system";
import { EButtonOutlined } from "../../BaseComponents/EButtons";
import {  Grid } from "@mui/material";
import { numberWithCommas } from "../../utils/formatNumber";
import { EFieldLabelBold, ELabel, ETypography } from "../../BaseComponents/ETypography";
import { ECard } from "../../BaseComponents/ECard";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import { CANCELLED_STATUS } from "../../constants";


const PaymentRowDetail = (props) => {

    const { paymentData, setSalaryDueModal, } = props;

    const payAmount = Number(paymentData?.pay_amount) ? Number(paymentData?.pay_amount) : 0;
    const paidSalary = Number(paymentData?.transaction?.collect_amount) ? Number(paymentData?.transaction?.collect_amount) : 0;
    const balanceAmount = payAmount - paidSalary

    return (
        <>
            <Grid container spacing={2}  >
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <ECard className='modal-info-box width-100 ' >
                        {paymentData?.employeeData && <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>{`${paymentData?.employeeData?.fullname} - ${paymentData?.employeeData?.employee_code}`}</ETypography>}
                        <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>{paymentData?.transaction_status == CANCELLED_STATUS && `Balance `}{`Payable Amount: ${numberWithCommas(balanceAmount)}`}</ETypography>
                    </ECard>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <FieldContainerCustom
                        labelwidth={33}
                    >
                        <EFieldLabelBold>Remark</EFieldLabelBold>
                        <ELabel>{paymentData?.remark}</ELabel>
                    </FieldContainerCustom>
                </Grid>
            </Grid>
            <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                <EButtonOutlined size="large" variant="outlined"
                    onClick={() => setSalaryDueModal({ modalData: null, modalCategory: null })}
                > Close </EButtonOutlined>

            </Stack>
        </>
    );
};

export default PaymentRowDetail