import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    ArcElement,
    Legend,
  } from "chart.js";
  import { Doughnut } from "react-chartjs-2";
  import { ECard } from "../../BaseComponents/ECard";
  import { ETypography } from "../../BaseComponents/ETypography";
  import { EButton } from "../../BaseComponents/EButtons";
  import React, { useEffect } from "react";
  import { EmployeeLeaveBalance } from "../../action/EmployeeLeaveAction";
  import { useDispatch, useSelector } from "react-redux";
  import { DASHBOARD, THEMES } from "../../constants";
  import EModal from "../../BaseComponents/EModal";
  import ApplyLeaveForm from "../Attendance/ApplyLeaveForm";
  import { LeaveList } from "../../action/LeaveAction";
  import moment from "moment";
  import { Card, styled, useTheme } from "@mui/material";
  import ChartDataLabels from "chartjs-plugin-datalabels";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Legend,
    Tooltip,
    ArcElement
  );
  
  ChartJS.defaults.elements.arc.roundedCornersFor = {
    start: 0, //0th position of Label 1
    end: 2, //2nd position of Label 2
  };
  
  function LeaveChart(props) {
    const { auth, show_as_talent, loading, ApplyLeave, setApplyLeave } = props
    const dispatch = useDispatch()
    const leave = useSelector(state => state.LeaveEmployeeReducer);
    const leaveComponent = useSelector(state => state.LeaveComponentReducer);
    const total_leave = leave?.empLeaveViewData?.total_allowed_leaves ? Number(leave?.empLeaveViewData?.total_allowed_leaves) : 0
    const available_leave = leave?.empLeaveViewData?.leave_taken ? Number(leave?.empLeaveViewData?.leave_taken) : 0
    const theme = useTheme()
  
    useEffect(() => {
      if (auth?.authtoken) {
        dispatch(EmployeeLeaveBalance(auth?.authtoken, auth?.authData?.id));
        dispatch(LeaveList(auth.authtoken));
      }
    }, [auth?.authtoken])

  
    // Leave graph config
    const data = {
      labels: "",
      datasets: [
        {
          label: "",
          data: [((total_leave - available_leave)>=0?total_leave - available_leave:0), available_leave],
          backgroundColor: ['#C79EFD', theme?.palette?.text?.staticGrey2],
          borderWidth: 1,
          weight: 0.05,
          totalValue:total_leave,
        remainingLeave:total_leave>0 && total_leave-available_leave>=0? total_leave-available_leave: total_leave>0 && total_leave-available_leave<0 ?0:available_leave,
  
        },
      ],
    };

    // data,  if array is empty or does not exists, as we still want to display dognut chart with no data
    const emptyData = {
      labels: [],
      datasets: [
        {
          label: "No data",
          data: [100],
          backgroundColor: ["#E7E9F0"],
        },
      ],
    };
  
    const barOptions = {
      responsive: true,
      cutout: '80%',
      // borderRadius: (total_leave && available_leave && (available_leave - total_leave)) ? 20 : 0,
      borderColor: theme.palette.background.default,
      displayColor: false,
      centerText: {
        display: true,
        text: `90%`,
      },
      plugins: {
        LightTheme: theme?.mode != THEMES.DARK ? true : false, //conditionally applying, dark and light theme plugin, found this one solution for changing plugin conditionally
        DarkTheme: theme?.mode == THEMES.DARK ? true : false,
        legend: {
          display: false,
          position: "top", // lable position left/right/top/bottom
          labels: {
            boxWidth: 0, // lable box size
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    //chart options if data is not available
    const emptyOptions = {
      responsive: true,
      cutout: '80%',
      // borderColor: theme.palette.background.default,
      displayColor: false,
      centerText: {
        display: true,
        text: `90%`,
      },
      plugins: {
        LightTheme: theme?.mode != THEMES.DARK ? true : false, //conditionally applying, dark and light theme plugin, found this one solution for changing plugin conditionally
        DarkTheme: theme?.mode == THEMES.DARK ? true : false,
        legend: {
          position: "top", // lable position left/right/top/bottom
          labels: {
            boxWidth: 0, // lable box size
          },
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          formatter: () => "No Leaves", //formatting the data label, display the value to the side of chart.
  
          color: theme.palette.text.primary,
          font: {
            size: "17px",
            weight: "500",
          },
          anchor: "start",
          align: "start",
          offset: 50,
        },
      },
    };
  
  
    //for center text, dark and light theme
    const LightModeplugins =
      [
        {id: 'LightTheme', //for light theme
        beforeDraw(chart) {
          const { width, height, ctx } = chart;

          const leaveDetailHeadFontSize = height / 12;
          const leaveDetailHeadFontWeight = 800;

          const fontSize = height / 12; // adjust the font size based on the chart height for text "Available leaves"
          const leaveDetailHead = "Available Leaves";
          const leaveDetailLabelX = Math.round((width - ctx.measureText(leaveDetailHead).width) / 2);
          const leaveDetailLabelY = height / 1.8 - fontSize; // adjust the Y position based on the font size
          ctx.fillStyle = theme?.palette?.text?.default;
          ctx.font = `${leaveDetailHeadFontSize}px sans-serif`;
          ctx.fontWeight = leaveDetailHeadFontWeight;
          ctx.textBaseline = "bottom";
          ctx.fillText(leaveDetailHead, leaveDetailLabelX, leaveDetailLabelY);
        
          const leaveTaken = chart?.data?.datasets[0]?.remainingLeave;
          const filler = "days";
          const textNewX = Math.round((width - ctx.measureText(leaveTaken + filler).width) / 2);
          const textNewY = height / 1.8 + fontSize; // adjust the Y position based on the font size
          ctx.fillStyle = theme?.palette?.text?.default;
          ctx.fillText(leaveTaken + " " + filler, textNewX, textNewY);
        },
        },
        {id: 'DarkTheme',//for dark theme
        beforeDraw(chart) {
          const { width, height, ctx } = chart;
          ctx.restore()
          chart.height = 170
          const leaveDetailHeadFontSize = height / 12;
          const leaveDetailHeadFontWeight = 800;

          const fontSize = height / 12; // adjust the font size based on the chart height for text "Available leaves"
          const leaveDetailHead = "Available Leaves";
          const leaveDetailLabelX = Math.round((width - ctx.measureText(leaveDetailHead).width) / 2);
          const leaveDetailLabelY = height / 1.8 - fontSize; // adjust the Y position based on the font size
          ctx.fillStyle = theme?.palette?.text?.primaryStaticLight;
          ctx.font = `${leaveDetailHeadFontSize}px sans-serif`;
          ctx.fontWeight = leaveDetailHeadFontWeight;
          ctx.textBaseline = "bottom";
          ctx.fillText(leaveDetailHead, leaveDetailLabelX, leaveDetailLabelY);
        
          const leaveTaken = chart?.data?.datasets[0]?.remainingLeave;
          const filler = "days";
          const textNewX = Math.round((width - ctx.measureText(leaveTaken + filler).width) / 2);
          const textNewY = height / 1.8 + fontSize; // adjust the Y position based on the font size
          ctx.fillStyle = theme?.palette?.text?.primaryStaticLight;
          ctx.fillText(leaveTaken + " " + filler, textNewX, textNewY);
          ctx.save();
        },
        }
      ]
  
    return (
      <> 
      {ApplyLeave && (
        <EModal open={ApplyLeave} close={() => setApplyLeave(false)} headervalue="Apply Leave">
          <ApplyLeaveForm
            open={ApplyLeave}
            leaveComponent={leaveComponent?.leaveListData?.leaveData}
            close={() => setApplyLeave(false)}
            fromPage={DASHBOARD}
            auth={auth}
          />
        </EModal>
      )}


        <ECardHRBasic className='border-color-border3 Bg-card2 pt-0 mb-0px '>

            {
                total_leave > 0 ?  // INFO: this condition means that if the no. of leaves provided by the company is 0, then the chart will not be shown
                <div style={{maxHeight:'170px'}}>
                    <Doughnut data={data} options={barOptions} plugins={LightModeplugins}/>
                </div>
                :
                <div style={{maxHeight:'170px'}}>
                  <Doughnut data={emptyData} options={emptyOptions} plugins={[ChartDataLabels]}/>
                </div>
                
            }



            <ETypography
              variant="p"
              className="small-text-label"
              sx={{marginTop:'5px', color:theme.palette.text.greyColor7, fontSize:'12px', fontSize:'12px'}}
            >
              {leave?.empLeaveViewData?.leave_from_month && leave?.empLeaveViewData?.leave_to_month && total_leave>0 && 
  
                moment(leave?.empLeaveViewData?.leave_from_month).format('MMMM YYYY') +
                (leave?.empLeaveViewData?.leave_to_month == leave?.empLeaveViewData?.leave_to_month ?
                  (' - ' + moment(leave?.empLeaveViewData?.leave_to_month).format('MMMM YYYY')) : ''
                )
              }
            </ETypography>

            {/* <EButton fullWidth onClick={(e) => setApplyLeave(true)} className='mt-12px' disabled={show_as_talent && !loading ? false : true}>Apply Leave</EButton> */}

        </ECardHRBasic>
  
      </>
    );
  }
  
  export default LeaveChart 

 const ECardHRBasic = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.card7,
    border: `0.5px solid ${theme.palette.border.light} !important`,
    borderRadius: '12px',
    boxShadow: '0 0 0 0px ',
    height: 'auto',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    padding: '10px', 
    paddingBottom:'16px',
}));
  