import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Stack,
} from '@mui/material';
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentTermsCreateUpdate, TandCListData } from '../../../action/ContractAction';
import SearchNotFound from '../../SearchNotFound';
import { ECard } from '../../../BaseComponents/ECard';
import { EDivider } from '../../../BaseComponents/EDivider';
import { EButtonOutlined, EIconButton, ELoadingButton } from '../../../BaseComponents/EButtons';
import { EIcon } from '../../../BaseComponents/EIcon';
import { ELabelInputMainGrid, LoadingGrid } from '../../../BaseComponents/EGrid';
import { EBox } from '../../../BaseComponents/EBox';
import { EHelperText, ETypography, ETypographyCardHeading } from '../../../BaseComponents/ETypography';
import { ECheckbox } from '../../../BaseComponents/ECheckbox';
import { ETextField } from '../../../BaseComponents/ETextField';
import { CHARACTER_LIMIT, CONTRACT_TNC_UPDATE_API } from '../../../constants';

function ContractViewTaCComponent(props) {
  const dispatch = useDispatch();
  const { contractDetails, auth, id, isEdit, HandleEdit, allowEdit } = props
  const tacList = useSelector((state) => state.TandCReducer);
  const TaC = contractDetails?.contractData && contractDetails?.contractData?.terms_cond && contractDetails?.contractData?.terms_cond?.split(',').map(Number);
  const [isTncText, SetIsTncText] = useState('');
  const [tacValues, setTacValues] = useState({ tnc: [] });
  const tnc_count = useRef(0);
  const error_tnc_text = 'Please select atleast one term and condition'
  let afterLockedString = '';

  var TacList = tacList && tacList?.tacListData?.rows?.reduce(function (filtered, option) {
    if (!filtered['displayList']) {
      filtered['displayList'] = [];
    }
    if (!filtered['ActiveTacList']) {
      filtered['ActiveTacList'] = [];
    }
    if (TaC && TaC.includes(option.id)) {
      filtered.displayList.push(option);
    }
    if (option?.status && option?.status == true) {
      if (TaC && TaC.includes(option.id)) {
        option = { ...option, defaultselected: true }
      }
      else {
        option = { ...option, defaultselected: false }
      }
      filtered.ActiveTacList.push(option);
    }

    return filtered;
  }, {});

  useEffect(() => {
    if (auth?.authtoken) {
      if (id && !contractDetails?.contractData?.is_locked) {
        dispatch(TandCListData(auth?.authtoken));
      }
    }
  }, []);

  useEffect(() => {
    if (TacList && TacList?.ActiveTacList && isEdit?.isEditTnC == true && !contractDetails?.contractData?.is_locked) {
      SetIsTncText(contractDetails?.contractData?.terms_cond_text ? contractDetails?.contractData?.terms_cond_text : '')
      setTacValues({ tnc: TacList?.ActiveTacList })
      if (TacList?.displayList) { tnc_count.current = TacList?.displayList?.length }
    }
  }, [isEdit.isEditTnC]);

  const OnChangeTnc = (id, value) => {
    let newState = [...tacValues.tnc];
    newState[id].defaultselected = value;
    setTacValues({ tnc: newState })
  }
  const OnSave = () => {
    const data = {
      contract_id: contractDetails?.contractData?.id,
      terms_cond: '', terms_cond_text: isTncText ? isTncText : '',
    }

    tacValues?.tnc?.length > 0 && tacValues?.tnc?.map((row) => {
      if (row?.defaultselected == true) {
        if (data.terms_cond == '') { return data.terms_cond += row?.id }
        else { return data.terms_cond += ',' + row?.id }
      }
    })
    if (tacValues?.tnc?.length > 0 && !data.terms_cond) {
      tnc_count.current = 1
    }
    else {
      tnc_count.current = 0
      dispatch(PaymentTermsCreateUpdate(auth?.authtoken, data, null, CONTRACT_TNC_UPDATE_API))
      HandleEdit('isEditTnC',false)
    }
  }

  afterLockedString = contractDetails?.contractData?.terms_cond ? contractDetails?.contractData?.terms_cond : ''
  if (contractDetails?.contractData?.terms_cond_text && contractDetails?.contractData?.is_locked) {
    afterLockedString = afterLockedString && contractDetails?.contractData?.terms_cond.replace(/<ol>/g, '')
    afterLockedString += '<li>' + contractDetails?.contractData?.terms_cond_text + '</li>'
    afterLockedString = '<ol>' + afterLockedString + '</ol>'
  }

  return (
    <ECard className='card_design_1 border-05px-border6'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={isEdit?.isEditTnC || contractDetails?.contractData?.is_locked?'mb-8px':"mb-8px"}
      >
        <ETypographyCardHeading variant='h5'> {isEdit?.isEditTnC ? 'Update ' : ''}Terms & Conditions</ETypographyCardHeading>
        {allowEdit && !isEdit?.isEditTnC && contractDetails?.contractData?.is_locked != true ?
          <EIconButton onClick={() => HandleEdit('isEditTnC',true)} className='px0'
          // sx={{ display: !TaC?.length || TaC?.length <= 0 || contractDetails?.contractData?.is_locked == true ? 'none' : 'inline-flex' }}
          >
            <EIcon icon={EditIcon} className='p0' /> </EIconButton>
          : ''}
      </Grid>

      <EDivider className="mb-16px borderColor-staticBorder7" />
      {!isEdit?.isEditTnC ?
        <EBox >
          {!contractDetails?.contractLoading ?
            <Grid container spacing={2} className='px-16px'>
              <Grid item md={12} sm={12} xs={12}  >
                {TaC && TacList && !contractDetails?.contractData?.is_locked &&
                  TacList?.displayList?.length > 0 || (TacList?.displayList?.length > 0 && contractDetails?.contractData?.terms_cond_text) ? (
                  <Grid container className=' p0' >
                    {TacList?.displayList?.map((row, index) => {
                      const { terms_title, id } = row;
                      return (
                        <Grid item className='grey-color pb-8px' key={index+id+'listing'} xs={12} sx={{ display: 'inline-flex' }}>
                          {terms_title ? <><span className='pr-6px'>{index + 1 + '. '}</span>
                            <ETypography component={'span'} className='font-size-14px' dangerouslySetInnerHTML={{ __html: terms_title }}></ETypography></> : '-'}
                        </Grid>
                      );
                    })}
                    <Grid item className='grey-color p0' key={id+TacList?.displayList?.length + 2+'last'} xs={12} sm={12} lg={12} xl={12} sx={{ display: contractDetails?.contractData?.terms_cond_text ? 'inline-flex' : 'none' }}>
                      {contractDetails?.contractData?.terms_cond_text ? <>
                        <span className='pr-6px'>{TacList?.displayList?.length + 1 + '. '}</span>
                        <ETypography component={'span'} className='font-size-14px' dangerouslySetInnerHTML={{ __html: contractDetails?.contractData?.terms_cond_text }}></ETypography></> : '-'}
                    </Grid>
                  </Grid>
                ) :
                  !TacList?.displayList || TacList?.displayList?.length == 0 && contractDetails?.contractData?.terms_cond_text && !contractDetails?.contractData?.is_locked ?
                    <Grid item className='grey-color p0' xs={12} sm={12} lg={12} xl={12}
                      sx={{ display: contractDetails?.contractData?.terms_cond_text ? 'inline-flex' : 'none' }}>
                      {contractDetails?.contractData?.terms_cond_text ? <>
                        <span>{1 + '. '}&ensp;</span>
                        <ETypography component={'span'} dangerouslySetInnerHTML={{ __html: contractDetails?.contractData?.terms_cond_text }}></ETypography></> : '-'}
                    </Grid>
                    : contractDetails?.contractData?.is_locked && (contractDetails?.contractData?.terms_cond || contractDetails?.contractData?.terms_cond_text) ?
                      <div className='p-Left-1rem'>
                        <ETypography component={'span'} dangerouslySetInnerHTML={{ __html: afterLockedString }}></ETypography>
                      </div> :
                      <SearchNotFound />
                }
              </Grid>
            </Grid>
            :
            <LoadingGrid />
          }
        </EBox>
        :
        <EBox>
          <Grid container spacing={3}>
            {tacValues && tacValues?.tnc?.length > 0 && tacValues?.tnc?.map((row, index) => {
              const { id, terms_title, defaultselected } = row;
              return (
                <Grid item xs={12} md={12} sm={12} xl={12} key={id + terms_title+index} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <ECheckbox
                    id={index.toString()}
                    name={terms_title}
                    checked={defaultselected}
                    onChange={(e, value) => {
                      OnChangeTnc(e.target?.id, value); if (value) { tnc_count.current += 1 } else { tnc_count.current = tnc_count.current - 1 }
                    }}
                    sx={{ padding: '0 16px 0 0' }}

                  />
                  {terms_title ? <ETypography component={'span'} dangerouslySetInnerHTML={{ __html: terms_title }}></ETypography> : '-'}
                </Grid>
              );
            }

            )}
            {(tnc_count.current != 0 || tacList?.tacListData?.count == 0) ? '' :
              <Grid item
              // style={{ display: (tnc_count.current != 0 || tacList?.tacListData?.count == 0) ? 'none' : 'block' }}
              >
                <EHelperText className="color-text-error-red"> {error_tnc_text}</EHelperText>
              </Grid>}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Additional Terms & Conditions'} isfullgrid={true} alignlabel={'align-top'} >
                <ETextField
                  fullWidth
                  multiline
                  rows={3}
                  value={isTncText}
                  inputProps={{ maxLength: CHARACTER_LIMIT }}
                  onChange={(e, value) => { SetIsTncText(e.target.value ? e.target.value : '') }}
                />
                {`${isTncText.length}/${CHARACTER_LIMIT}`}
              </ELabelInputMainGrid>
            </Grid>
            <Grid item xs={12} xl={12} className="modal1-buttons-stick-bottom">
              <Stack direction="row">
                <EButtonOutlined
                  onClick={() => {HandleEdit('isEditTnC',false); tnc_count.current = 0 }}
                  variant="outlined"
                  size="large"
                >
                  Cancel
                </EButtonOutlined>
                <ELoadingButton onClick={() => OnSave()} variant="contained" size="large" className='button-left-margin font-size-14px'>
                  Save
                </ELoadingButton>

              </Stack>
            </Grid>
          </Grid>
        </EBox>
      }
    </ECard>
  )
}
export default ContractViewTaCComponent;
