import React from 'react'
import { EAutocomplete } from './EAutocomplete';
import { createFilterOptions } from '@mui/material';
import { ETextField } from './ETextField';
import { EButton } from './EButtons';
import AddIcon from '@iconify/icons-material-symbols/add-box-outline-rounded'
import EModal from './EModal';
import { EIcon } from './EIcon';

const filter = createFilterOptions();

/**
 * [2023-03-31]
 * Made By: Purva Sharma
 * Description:-  Dropdown with Add button , which on click opens Modal Form
 *             - reference: https://mui.com/material-ui/react-autocomplete/#creatable
 * 
 **/

function ECreatableAutoComplete({FieldName,setFieldValue, options,placeholder,error,helperText,form,modalHeader,value,setValue,open,toggleOpen}) {
    return (
      <>
        <EAutocomplete
       value={value}    
       onChange={(event, newValue) => {
         if (typeof newValue === "string") {
           // timeout to avoid instant validation of the dialog's form.
           setTimeout(() => {
             toggleOpen(true);
           });
         } else if (newValue && newValue.inputValue) {
           toggleOpen(true);
         } else {
           setValue(newValue);
           setFieldValue(FieldName,newValue)
         }
       }}
       filterOptions={(options, params) => {
         const filtered = filter(options, params);

         if (params.inputValue !== "") {
           filtered.push({
             inputValue: params.inputValue,
             label: <EButton sx={{minWidth:'100%'}}><EIcon icon={AddIcon} className='mr-8px onboarding-btn-icon' /> {modalHeader}</EButton>
           });
         }

         return filtered;
       }}
       id="free-solo-dialog-demo"
       options={options}
       getOptionLabel={(option) => {
         // e.g value selected with enter, right from the input
         if (typeof option === "string") {
           return option;
         }
         if (option.inputValue) {
           return option.inputValue;
         }
         return option.label;
       }}
       selectOnFocus
       renderOption={(props, option) => <li {...props}>{option.label}</li>}
    //    freeSolo
       renderInput={(params) => <ETextField {...params} placeholder={placeholder} error={error} helperText={helperText} />}/>
        <EModal headervalue={modalHeader} open={open} close={!toggleOpen}>
          {form}
        </EModal>
     </>
    )
}

export default ECreatableAutoComplete
  